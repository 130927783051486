import { Input, Transfer, Spin } from 'antd';
import React, { createRef } from 'react';
import reaction from 'reaction';
import Tree from 'components/Tree';

const { Search } = Input;

const isChecked = (selectedKeys, eventKey) => {
  return selectedKeys.indexOf(eventKey) !== -1;
};

const generateTree = (treeNodes = [], checkedKeys = []) =>
  treeNodes.map(({ children, ...props }) => ({
    ...props,
    disabled: checkedKeys.includes(props.key),
    children: generateTree(children, checkedKeys),
  }));

const RenderTransfer = ({ onChange, expandedKeys, targetKeys, dataSource, Transfer }) => {  

  return (
    <Transfer
      dataSource={dataSource}
      targetKeys={targetKeys}
      onChange={onChange}
      expandedKeys={expandedKeys}
    />
  )
}

export default class TreeTransfer extends React.Component {
  transferRef = createRef();
  state = {
    treeData: [],
    filterTreeData: [],
    fetching: true,
    // wrapperHeight: 290,
  };
  static defaultProps = {
    onRendered: () => {},
  };

  onSearchChange = (e) => {
    const keyword = e.target.value;
    let cloneData = JSON.parse(JSON.stringify(this.state.treeData));
    this.filterTree(cloneData, keyword);
    this.filterTree2(cloneData, keyword);
    this.setState({
      filterTreeData: cloneData,
    });
  };

  TreeTransferFn = ({ dataSource, targetKeys, expandedKeys, ...restProps }) => {
    const transferDataSource = [];
    function flatten(list = []) {
      list.forEach((item) => {
        transferDataSource.push(item);
        flatten(item.children);
      });
    }
    flatten(dataSource);
    return (
      <Transfer
        {...restProps}
        targetKeys={targetKeys}
        dataSource={transferDataSource}
        className="tree-transfer"
        showSelectAll={false}
        render={(item) => item.title}
        ref={this.transferRef}
      >
        {({ direction, onItemSelect, selectedKeys }) => {
          if (direction === 'left') {
            const checkedKeys = [...selectedKeys, ...targetKeys];
            const treeData = generateTree(dataSource, targetKeys);
            return (
              <div>
                <Search
                  style={{ marginBottom: 8 }}
                  placeholder="Search"
                  onChange={this.onSearchChange}
                />
                <Tree
                  // blockNode
                  checkable
                  checkStrictly
                  autoExpandParent
                  defaultExpandedKeys={expandedKeys}
                  // virtual={{
                  //   itemHeight: 28,
                  //   scrollHeight: 290,
                  // }}
                  // itemHeight={28}
                  height={290}
                  checkedKeys={checkedKeys}
                  // expandedKeys={expandedKeys}
                  // value={checkedKeys}
                  halfChecked={expandedKeys}
                  // onChange={(keys) => {
                  //   console.log('const onCheck =', keys);
                  //   onItemSelectAll(keys, keys.length > checkedKeys.length);
                  // }}
                  treeData={treeData}
                  // nodes={treeData}
                  onCheck={(
                    _,
                    {
                      node: {
                        props: { eventKey },
                      },
                    }
                  ) => {
                    onItemSelect(eventKey, !isChecked(checkedKeys, eventKey));
                  }}
                  onSelect={(
                    _,
                    {
                      node: {
                        props: { eventKey },
                      },
                    }
                  ) => {
                    onItemSelect(eventKey, !isChecked(checkedKeys, eventKey));
                  }}
                />
              </div>
            );
          }
        }}
      </Transfer>
    );
  };

  fixTree = (tree) => {
    tree.forEach((item) => {
      item.key = (item.key || item.id || item.code || item.value) + '';
      item.title = item.title || item.name;
      if (item.children) {
        this.fixTree(item.children);
      }
    });
  };

  getTreeData = () => {
    return reaction.common.getSupplyCategory().then((res) => {
      this.props.onRendered();
      this.fixTree(res.data);
      this.setState({
        treeData: res.data,
        filterTreeData: res.data,
        fetching: false,
      }, () => {
        this.onSearchChange({
          target: {
            value: '1',
          },
        });
        // 有bug，transfer不能自动更新，右侧不能显示左侧已经勾选的项目
        // 暂时没找到其他办法，让transfer主动更新，和bug原因，暂时用这种丑办法临时解决问题
        setTimeout(() => {
          this.onSearchChange({
            target: {
              value: '',
            },
          });
        }, 2200);
      });
      return res;
    });
  };

  filterTree = (tree, keyword) => {
    for (let i = 0; i < tree.length; i++) {
      let item = tree[i];
      if (
        (!item.children || item.children.length === 0) &&
        item.title.indexOf(keyword) === -1
      ) {
        tree.splice(i, 1);
        i--;
      } else if (item.children && item.title.indexOf(keyword) === -1) {
        item.hasChildren = true;
        this.filterTree(item.children, keyword);
      }
    }
  };

  filterTree2 = (tree) => {
    for (let i = 0; i < tree.length; i++) {
      let item = tree[i];
      if (item.children && item.children.length) {
        this.filterTree2(item.children);
      }
      if (item.hasChildren && (item.children.length === 0 || !item.children)) {
        tree.splice(i, 1);
        i--;
      }
    }
  };

  onChange = (targetKeys) => {
    // console.log('const transferChange =', targetKeys);
    this.setState({ targetKeys });
    this.props.onChange({ targetKeys });
  };

  componentDidMount() {
    this.getTreeData();
    let { expandParentKeys } = this.props;
    this.setState({
      expandedKeys: expandParentKeys || [],
    });
  }

  render() {
    const { filterTreeData, fetching } = this.state;
    let { expandParentKeys } = this.props;
    let targetKeys = this.props.value.targetKeys || [];
    const TreeTransferFn = this.TreeTransferFn;
    if (fetching) {
      return (
        <Spin spinning tip="加载中...">
          <div style={{ height: 300, width: 2 }}></div>
        </Spin>
      )
    }
    return (
      <RenderTransfer
        Transfer={TreeTransferFn}
        dataSource={filterTreeData}
        targetKeys={targetKeys}
        onChange={this.onChange}
        expandedKeys={expandParentKeys}
      />
    );
  }
}
