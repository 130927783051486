import React from 'react';
import dayjs from 'dayjs';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { Input, DatePicker, Button, Table, Select, Tag, Form } from 'antd';

import FormItem from 'components/FormItem';

import reaction from 'reaction';
import { formatStringSymbol, renderListControls } from 'utils';
import { withdrawStatus } from 'utils/constants';

import commonStyles from 'style/common.module.scss';

const { RangePicker } = DatePicker;
let dateFormat = 'YYYY/MM/DD';
let defaultDatetime = [];

let columns = [
  {
    title: '流水单号',
    dataIndex: 'serialNo',
  },
  {
    title: '公司名称',
    dataIndex: 'accountName',
  },
  {
    title: '结算银行',
    dataIndex: 'bankName',
  },
  {
    title: '账户',
    dataIndex: 'accountNo',
  },
  {
    title: '金额',
    dataIndex: 'money',
  },
  {
    title: '出金卡号',
    dataIndex: 'withdrawAccountNo',
  },
  {
    title: '处理时间',
    dataIndex: 'createTime',
    render: (text) => {
      if (!text) {
        return '';
      }
      return dayjs(text - 0).format('YYYY-MM-DD HH:mm:ss');
    },
  },
  {
    title: '到账时间',
    dataIndex: 'receiveTime',
    render: (text) => {
      if (!text) {
        return '';
      }
      return dayjs(text - 0).format('YYYY-MM-DD HH:mm:ss');
    },
  },
  {
    title: '状态',
    dataIndex: 'withdrawStatus',
    render: (val, record) => {
      let status = +val;
      if (status === withdrawStatus.start) {
        return <Tag color="#40a9ff">出金申请成功</Tag>;
      }
      if (status === withdrawStatus.progress) {
        return <Tag color="#ffc53d">银行处理中</Tag>;
      }

      if (status === withdrawStatus.success || record.receiveTime) {
        return <Tag color="#52c41a">出金到账</Tag>;
      }
      return <Tag color="#ff4d4f">出金失败</Tag>;
    },
  },
  {
    title: '平台',
    dataIndex: 'platform',
  },
];

/**
 * 出金记录
 */
@connect(({ accountList, me, common }) => ({ accountList, me, common }))
class ExpenditureRecords extends React.Component {
  state = {
    query: Immutable.Map({
      accountName: '',
      accountNo: '',
      minMoney: '',
      maxMoney: '',
      bankName: '',
      datetime: defaultDatetime,
      platformCode: '',
      page: 1,
      length: 10,
    }),
  };
  onQueryChange = (name, value) => {
    let { query } = this.state;
    query = query.set(name, value);
    this.setState({ query }, () => {});
  };
  doSearch = () => {
    let { query } = this.state;
    query = query.set('page', 1);
    this.setState({ query }, () => {
      this.getExpenditureRecordsList();
    });
  };

  getExpenditureRecordsList = () => {
    let query = this.state.query.toJS();
    // let {bizType, oppAccountName, serialNo, page, length} = query;
    query.minCreateTime = query.datetime[0]
      ? query.datetime[0].startOf('day').valueOf()
      : null;
    query.maxCreateTime = query.datetime[1]
      ? query.datetime[1].endOf('day').valueOf()
      : null;
    if (query.minCreateTime === null) {
      delete query.minCreateTime;
    }
    if (query.maxCreateTime === null) {
      delete query.maxCreateTime;
    }
    reaction.accountList.getExpenditureRecordsList(query);
  };

  handleTableChange = (pagination, filters, sorter) => {
    let { query } = this.state;
    query = query.set('page', pagination.current);
    query = query.set('length', pagination.pageSize);
    query = query.set('accountName', formatStringSymbol(query.get('accountName')))
    this.setState({ query }, () => {
      this.getExpenditureRecordsList();
    });
  };

  getTotalExpense() {
    reaction.accountList.getTotalExpense({
      fundsType: 2,
    });
  }

  clear = () => {
    let { query } = this.state;
    query = query.set('accountName', '');
    query = query.set('accountNo', '');
    query = query.set('minMoney', '');
    query = query.set('maxMoney', '');
    query = query.set('bankName', '');
    query = query.set('datetime', defaultDatetime);
    query = query.set('platformCode', '');

    this.setState({ query }, () => {
      // ......
    });
  };

  onPressEnter = () => {
    this.doSearch();
  };

  componentDidMount() {
    this.getExpenditureRecordsList();
    this.getTotalExpense();
  }
  render() {
    let { expenditureRecords, totalExpense } = this.props.accountList;
    let { platformDict } = this.props.common;
    let { query } = this.state;
    let platforms = [];
    for (let key in platformDict) {
      platforms.push({ name: platformDict[key], code: key });
    }

    return (
      <div>
        <Form layout="inline">
          {
            renderListControls([
              <FormItem label="公司名称">
                <Input
                  onPressEnter={this.onPressEnter}
                  value={query.toJS().accountName}
                  onChange={(e) => {
                    this.onQueryChange('accountName', e.target.value);
                  }}
                  placeholder="公司名称"
                />
              </FormItem>,
              <FormItem label="银行账号">
                <Input
                  onPressEnter={this.onPressEnter}
                  value={query.toJS().accountNo}
                  onChange={(e) => {
                    this.onQueryChange('accountNo', e.target.value);
                  }}
                  placeholder="银行账号"
                />
              </FormItem>,
              <FormItem label="金额">
                <div>
                  <Input
                    style={{ width: 'calc(50% - 15px)' }}
                    onPressEnter={this.onPressEnter}
                    value={query.toJS().minMoney}
                    onChange={(e) => {
                      this.onQueryChange('minMoney', e.target.value);
                    }}
                    placeholder="最小金额"
                  />
                  <span style={{ padding: '0 5px' }}>至</span>
                  <Input
                    style={{ width: 'calc(50% - 15px)' }}
                    onPressEnter={this.onPressEnter}
                    value={query.toJS().maxMoney}
                    onChange={(e) => {
                      this.onQueryChange('maxMoney', e.target.value);
                    }}
                    placeholder="最大金额"
                  />
                </div>
              </FormItem>,
              <FormItem label="结算银行">
                <Select
                  showSearch
                  placeholder="选择结算银行"
                  optionFilterProp="children"
                  value={query.toJS().bankName}
                  onChange={(value) => {
                    this.onQueryChange('bankName', value);
                  }}
                >
                  <Select.Option value="">全部</Select.Option>
                  <Select.Option value="CITIC">中信银行</Select.Option>
                  <Select.Option value="E_BUTLER">中信E管家</Select.Option>
                </Select>
              </FormItem>,
              <FormItem label="处理时间">
                <RangePicker
                  value={query.toJS().datetime}
                  onChange={(value) => {
                    this.onQueryChange('datetime', value);
                  }}
                  format={dateFormat}
                />
              </FormItem>,
              <FormItem label="平台类型">
                <Select
                  showSearch
                  placeholder="平台类型"
                  optionFilterProp="children"
                  value={query.toJS().platformCode}
                  onChange={(value) => {
                    this.onQueryChange('platformCode', value);
                  }}
                >
                  <Select.Option value="">全部</Select.Option>
                  {platforms.map((item) => {
                    return (
                      <Select.Option value={item.code} key={item.code}>
                        {item.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </FormItem>,
              {
                colProps: {
                  xxl: 12,
                  xl: 24,
                  lg: 24,
                  md: 24,
                  xs: 24,
                },
                children: (
                  <FormItem align="right">
                    <Button
                      type="primary"
                      onClick={this.doSearch}
                      icon="search"
                    >
                      查询
                    </Button>
                    <Button
                      style={{ margin: '0 0 0 5px' }}
                      type="primary"
                      onClick={this.clear}
                      icon="redo"
                    >
                      清空
                    </Button>
                  </FormItem>
                )
              }
            ])
          }
        </Form>
        <div style={{ padding: '20px 0 0 0' }}>
          <span>总计: 出金{totalExpense.total}元</span>
        </div>
        <div style={{ padding: '20px 0 0 0' }}>
          {expenditureRecords && (
            <Table
              className={commonStyles.row}
              rowKey={r => `${r.accountNo}__${r.cardNo}_${r.serialNo}_${r.withdrawAccountNo}`}
              columns={columns}
              dataSource={expenditureRecords.result}
              pagination={{
                current: expenditureRecords.pagerInfo.page,
                total: expenditureRecords.pagerInfo.total,
                pageSizeOptions: ['10', '20', '50'],
                showSizeChanger: true,
                showQuickJumper: true,
                showTotal: (total) => `共${total}条`,
              }}
              onChange={this.handleTableChange}
            />
          )}
        </div>
      </div>
    );
  }
}
export default ExpenditureRecords;
