import React from 'react';
import dayjs from 'dayjs';
import { connect } from 'react-redux';

import commonStyles from 'style/common.module.scss';
/**
 * 主页
 */
@connect(({ me, common }) => ({ me, common }))
class Wellcome extends React.Component {
  componentDidMount() {
    this.timer = setInterval(() => {
      this.forceUpdate();
    }, 500);
  }
  componentWillUnmount() {
    clearInterval(this.timer);
    this.timer = null;
  }
  render() {
    let { me, common } = this.props;
    if (!me.userInfo) return null;

    let lastLoginTime = +me.userInfo.lastLoginTime;
    let loginTimeTip =
      lastLoginTime > 0
        ? `欢迎登录！您上次登录时间为：${dayjs(lastLoginTime).format(
            'YYYY-MM-DD HH:mm:ss'
          )}。`
        : '欢迎首次登录后台管理系统。';
    var xml = (
      <div>
        <p>{dayjs().format('YYYY年MM月DD日')}</p>
        <p>当前时间：{dayjs().format('HH:mm:ss')}</p>
        <p className={commonStyles.row}>
          您好！{me.userInfo.userName}，{loginTimeTip}
        </p>
      </div>
    );
    return xml;
  }
}

export default Wellcome;
