import React from 'react';
import { connect } from 'react-redux';
import authAndRedirect from 'utils/authAndRedirect';
/**
 * 会员中心
 */
@connect(({ common, me, menu }) => ({ common, me, menu }))
class MemberCenter extends React.Component {
  componentDidMount() {
    authAndRedirect(this);
  }
  render() {
    return null;
  }
}

export default MemberCenter;
