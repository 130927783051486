import http from 'utils/http';
var modifyMemberInfo = function modifyMemberInfo(params) { return function () { return http.request('v1.bid.member.info.list', params, false); }; };
var disableVip = function disableVip(params) {
    return function () { return http.request('v1.bid.member.info.disable', params, false); };
};
var enableVip = function enableVip(params) { return function () { return http.request('v1.bid.member.info.enable', params, false); }; };
var addVipInfo = function addVipInfo(params) {
    return function () { return http.request('v1.bid.member.info.save', params); };
};
var renew = function renew(params) {
    return function () { return http.request('v1.bid.renewal.offline', params); };
};
var getCompanyNameForPhone = function getCompanyNameForPhone(params) { return function () { return http.get('v1.bid.member.info.primaryName', params, false); }; };
var getVipInfobyId = function getVipInfobyId(params) { return function () { return http.get('v1.bid.member.info.query', params, false); }; };
var renewalList = function renewalList(params) { return function () { return http.request('v1.bid.renewal.list', params); }; };
var getOneRenewabyById = function getOneRenewabyById(params) { return function () { return http.get('v1.bid.renewal.query', params, false); }; };
var resetRenewal = function resetRenewal(params) { return function () { return http.request('v1.bid.renewal.undo', params); }; };
var exportList = function exportList(params) { return function () { return http.downloadFile2('api/v1/bid/member/info/export', params); }; };
var exportRenewal = function exportRenewal(params) { return function () { return http.downloadFile2('api/v1/bid/renewal/export', params); }; };
export default { /** 获取招采会员列表  */ modifyMemberInfo: modifyMemberInfo, /** 禁用会员  */ disableVip: disableVip, /** 启用会员  */ enableVip: enableVip, /** 新增会员  */ addVipInfo: addVipInfo, /** 续费  */ renew: renew, /** 根据手机号获取企业名称  */ getCompanyNameForPhone: getCompanyNameForPhone, /** 根据ID获取会员信息  */ getVipInfobyId: getVipInfobyId, /** 获取缴费记录 */ renewalList: renewalList, /** 查找单条缴费记录 */ getOneRenewabyById: getOneRenewabyById, /** 撤销上次缴费记录 */ resetRenewal: resetRenewal, /** 导出列表数据 */ exportList: exportList, /** 导出历史记录 */ exportRenewal: exportRenewal };
