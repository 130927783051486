import React from 'react';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import Immutable from 'immutable';
import reaction from 'reaction';
import { Table, Button, Input, Select, DatePicker, Modal, Radio, message, Form, Upload, Icon, Checkbox, AutoComplete } from 'antd';
import { historyPush } from 'utils/history';
import { RoutePath } from 'utils/constants';
import { validHasCenpurCert, resolveUrlQuery, formatInviteSteelValue, formatStringSymbol, getUniqTableRowKey, fixFormValues, checkIsPurchase, checkIsSupplier, getFilePreviewUrl, getPreviewImageWidth } from 'utils';
import * as env from 'config/env';
import DatePickAndCheckboxFormItem from 'components/DatePickAndCheckboxFormItem';
import TreeTransfer from 'components/TreeTransfer';
// import TreeTransfer from 'components/TreeTransfer/index2';

import commonStyles from 'style/common.module.scss';
import { REJECT_REASON_MAX_LENGTH } from 'config';
import PageLoading from 'components/PageLoading';

const FormItem = Form.Item;
const RadioGroup = Radio.Group;
const AOption = AutoComplete.Option;
const { TextArea } = Input;
const Option = Select.Option;

@Form.create()
@connect(({ memberCenter, common, me, frontUser }) => ({ memberCenter, common, me, frontUser }))
class ComplementCompanyQualification extends React.Component {
  constructor() {
    super();
    this.state = {
      query: Immutable.Map({
        page: 1,
        length: 10
      }),
      showBigImg: false,
      showProxy: true,
      bigImgUrl: '',
      imgWidth: 520,
      businessLicenceUrl: [],
      organizationCodeUrl: [],
      taxRegistryUrl: [],
      accountLicenseUrl: [],
      province: [],
      city: [],
      district: [],
  
      proxy: [],
  
      foreignTradeRegistrationUrl: [],
      legalPersonIdentityCardFrontUrl: [],
      legalPersonIdentityCardBackUrl: [],
      agentIdentityCardFrontUrl: [],
      agentIdentityCardBackUrl: [],
  
      showRefuseModal: false,
      reason: '',
  
      loading: true,

      purchaserRenderPromise: () => {},
      supplyRenderPromise: () => {},
    };
  };

  getAreaDict = () => {
    return reaction.memberCenter.getAreaDict({}, false).then((res) => {
      if (res.code === 0) {
        this.setState({
          province: res.data.filter(item => item.type === '2')
        });
      }
    });
  };

  onProvinceChange = (v) => {
    this.setState({
      city: this.props.memberCenter.areaDict.filter(item => item.parentId === v)
    });
    this.props.form.setFieldsValue({
      'cert.baseInfo.districtCode': '',
      'baseInfo.districtCode': '',
      'cert.baseInfo.cityCode': '',
      'baseInfo.cityCode': ''
    });
  };
  onCityChange = (v) => {
    this.setState({
      district: this.props.memberCenter.areaDict.filter(item => item.parentId === v)
    });
    this.props.form.setFieldsValue({
      'cert.baseInfo.districtCode': '',
      'baseInfo.districtCode': ''
    });
  };
  onDistrictChange = (v) => {

  };

  getQualificationInfo = () => {
    let userId = resolveUrlQuery(this.props.location.search).userId;
    return reaction.memberCenter.getQualificationInfo({ userId }, false).then((res) => {
      if (res.code === 0) {
        if (res.data.cert && res.data.cert.proxyType === '3') {
          this.setState({
            showProxy: false
          });
        }
        this.setState({
          businessLicenceUrl: res.data.cert.attachment && res.data.cert.attachment.businessLicenceUrl && res.data.cert.attachment.businessLicenceUrl.url && [{ uid: 'businessLicenceUrl', name: '', status: 'done', thumbUrl: res.data.cert.attachment.businessLicenceUrl.url, fileKey: res.data.cert.attachment.businessLicenceUrl.fileKey }],
          organizationCodeUrl: res.data.cert.attachment && res.data.cert.attachment.organizationCodeUrl && res.data.cert.attachment.organizationCodeUrl.url && [{ uid: 'organizationCodeUrl', name: '', status: 'done', thumbUrl: res.data.cert.attachment.organizationCodeUrl.url, fileKey: res.data.cert.attachment.organizationCodeUrl.fileKey }],
          taxRegistryUrl: res.data.cert.attachment && res.data.cert.attachment.taxRegistryUrl && res.data.cert.attachment.taxRegistryUrl.url && [{ uid: 'taxRegistryUrl', name: '', status: 'done', thumbUrl: res.data.cert.attachment.taxRegistryUrl.url, fileKey: res.data.cert.attachment.taxRegistryUrl.fileKey }],
          accountLicenseUrl: res.data.cert.attachment && res.data.cert.attachment.accountLicenseUrl && res.data.cert.attachment.accountLicenseUrl.url && [{ uid: 'accountLicenseUrl', name: '', status: 'done', thumbUrl: res.data.cert.attachment.accountLicenseUrl.url, fileKey: res.data.cert.attachment.accountLicenseUrl.fileKey }],
          proxy: res.data.cert.proxy && res.data.cert.proxy.map((item, index) => { return { uid: 'proxy' + index, name: '', status: 'done', thumbUrl: item.url, fileKey: item.fileKey }; }).filter(item => item.thumbUrl && item.fileKey),
          foreignTradeRegistrationUrl: res.data.additionalCert && res.data.additionalCert.foreignTradeRegistrationUrl && res.data.additionalCert.foreignTradeRegistrationUrl.url && [{ uid: 'foreignTradeRegistrationUrl', name: '', status: 'done', thumbUrl: res.data.additionalCert.foreignTradeRegistrationUrl.url, fileKey: res.data.additionalCert.foreignTradeRegistrationUrl.fileKey }],
          legalPersonIdentityCardFrontUrl: res.data.auditAdditionalCert && res.data.auditAdditionalCert.legalPersonIdentityCardFrontUrl && res.data.auditAdditionalCert.legalPersonIdentityCardFrontUrl.url && [{ uid: 'legalPersonIdentityCardFrontUrl', name: '', status: 'done', thumbUrl: res.data.auditAdditionalCert.legalPersonIdentityCardFrontUrl.url, fileKey: res.data.auditAdditionalCert.legalPersonIdentityCardFrontUrl.fileKey }],
          legalPersonIdentityCardBackUrl: res.data.auditAdditionalCert && res.data.auditAdditionalCert.legalPersonIdentityCardBackUrl && res.data.auditAdditionalCert.legalPersonIdentityCardBackUrl.url && [{ uid: 'legalPersonIdentityCardBackUrl', name: '', status: 'done', thumbUrl: res.data.auditAdditionalCert.legalPersonIdentityCardBackUrl.url, fileKey: res.data.auditAdditionalCert.legalPersonIdentityCardBackUrl.fileKey }],
          agentIdentityCardFrontUrl: res.data.auditAdditionalCert && res.data.auditAdditionalCert.agentIdentityCardFrontUrl && res.data.auditAdditionalCert.agentIdentityCardFrontUrl.url && [{ uid: 'agentIdentityCardFrontUrl', name: '', status: 'done', thumbUrl: res.data.auditAdditionalCert.agentIdentityCardFrontUrl.url, fileKey: res.data.auditAdditionalCert.agentIdentityCardFrontUrl.fileKey }],
          agentIdentityCardBackUrl: res.data.auditAdditionalCert && res.data.auditAdditionalCert.agentIdentityCardBackUrl && res.data.auditAdditionalCert.agentIdentityCardBackUrl.url && [{ uid: 'agentIdentityCardBackUrl', name: '', status: 'done', thumbUrl: res.data.auditAdditionalCert.agentIdentityCardBackUrl.url, fileKey: res.data.auditAdditionalCert.agentIdentityCardBackUrl.fileKey }],
          city: this.props.memberCenter.areaDict.filter(item => item.parentId === res.data.cert.baseInfo.provinceCode),
          district: this.props.memberCenter.areaDict.filter(item => item.parentId === res.data.cert.baseInfo.cityCode)
        });
      }
      return res.data;
    });
  };

  handleSubmit = (e) => {

    e && e.preventDefault();
    let values = this.props.form.getFieldsValue();
    // 定制需求，公司名车必填
    if (!(values.cert.baseInfo.companyNameZh)) {
      this.props.form.setFields({
        'cert.baseInfo.companyNameZh': {
          value: '',
          errors: [new Error('请输入公司名称!')]
        }
      });
      return;
    }
    // this.props.form.validateFields((err, values) => {
    values.userId = resolveUrlQuery(this.props.location.search).userId;
    values.department = values.department || '';

    // values.cert.baseInfo.companyNameZh = values.cert.baseInfo.companyNameZh || null;
    // values.cert.baseInfo.companyNameEn = values.cert.baseInfo.companyNameEn || null;

    values.cert.baseInfo.companyNameZh = formatStringSymbol(values.cert.baseInfo.companyNameZh);
    values.cert.baseInfo.companyNameEn = formatStringSymbol(values.cert.baseInfo.companyNameEn);
    values.cert.baseInfo.usedCompanyName = formatStringSymbol(values.cert.baseInfo.usedCompanyName);
    values.cert.baseInfo.companyAbbreviation = formatStringSymbol(values.cert.baseInfo.companyAbbreviation);

    values.cert.contactInfo.qq = values.cert.contactInfo.qq || null;
    values.additionalCert.foreignTradeEnterpriseCode = values.additionalCert.foreignTradeEnterpriseCode || null;
    values.cert.contactInfo.contactPerson = values.cert.contactInfo.contactPerson || null;
    values.auditAdditionalCert.businessLicenseExpirationTime = values.auditAdditionalCert.businessLicenseExpirationTime.expirationTime ? dayjs(values.auditAdditionalCert.businessLicenseExpirationTime.expirationTime).valueOf() : null;
    values.auditAdditionalCert.registeredCapital = values.auditAdditionalCert.registeredCapital * 10000;
    values.cert.attachment.businessLicenceUrl = (values.cert.attachment.businessLicenceUrl && values.cert.attachment.businessLicenceUrl.fileList && values.cert.attachment.businessLicenceUrl.fileList[0]) ? { fileKey: values.cert.attachment.businessLicenceUrl.fileList[0].fileKey } : null;
    values.cert.attachment.organizationCodeUrl = (values.cert.attachment.organizationCodeUrl && values.cert.attachment.organizationCodeUrl.fileList && values.cert.attachment.organizationCodeUrl.fileList[0]) ? { fileKey: values.cert.attachment.organizationCodeUrl.fileList[0].fileKey } : null;
    values.cert.attachment.taxRegistryUrl = (values.cert.attachment.taxRegistryUrl && values.cert.attachment.taxRegistryUrl.fileList && values.cert.attachment.taxRegistryUrl.fileList[0]) ? { fileKey: values.cert.attachment.taxRegistryUrl.fileList[0].fileKey } : null;
    values.cert.attachment.accountLicenseUrl = (values.cert.attachment.accountLicenseUrl && values.cert.attachment.accountLicenseUrl.fileList && values.cert.attachment.accountLicenseUrl.fileList[0]) ? { fileKey: values.cert.attachment.accountLicenseUrl.fileList[0].fileKey } : null;
    values.cert.proxy = values.cert && values.cert.proxy && values.cert.proxy.fileList && values.cert.proxy.fileList.map(item => { return { fileKey: item.fileKey, url: item.url }; });
    values.additionalCert.foreignTradeRegistrationUrl = (values.additionalCert && values.additionalCert.foreignTradeRegistrationUrl && values.additionalCert.foreignTradeRegistrationUrl.fileList && values.additionalCert.foreignTradeRegistrationUrl.fileList[0]) ? { fileKey: values.additionalCert.foreignTradeRegistrationUrl.fileList[0].fileKey } : null;
    values.auditAdditionalCert.legalPersonIdentityCardFrontUrl = (values.auditAdditionalCert && values.auditAdditionalCert.legalPersonIdentityCardFrontUrl && values.auditAdditionalCert.legalPersonIdentityCardFrontUrl.fileList && values.auditAdditionalCert.legalPersonIdentityCardFrontUrl.fileList[0]) ? { fileKey: values.auditAdditionalCert.legalPersonIdentityCardFrontUrl.fileList[0].fileKey } : null;
    values.auditAdditionalCert.legalPersonIdentityCardBackUrl = (values.auditAdditionalCert && values.auditAdditionalCert.legalPersonIdentityCardBackUrl && values.auditAdditionalCert.legalPersonIdentityCardBackUrl.fileList && values.auditAdditionalCert.legalPersonIdentityCardBackUrl.fileList[0]) ? { fileKey: values.auditAdditionalCert.legalPersonIdentityCardBackUrl.fileList[0].fileKey } : null;
    values.auditAdditionalCert.agentIdentityCardFrontUrl = (values.auditAdditionalCert && values.auditAdditionalCert.agentIdentityCardFrontUrl && values.auditAdditionalCert.agentIdentityCardFrontUrl.fileList && values.auditAdditionalCert.agentIdentityCardFrontUrl.fileList[0]) ? { fileKey: values.auditAdditionalCert.agentIdentityCardFrontUrl.fileList[0].fileKey } : null;
    values.auditAdditionalCert.agentIdentityCardBackUrl = (values.auditAdditionalCert && values.auditAdditionalCert.agentIdentityCardBackUrl && values.auditAdditionalCert.agentIdentityCardBackUrl.fileList && values.auditAdditionalCert.agentIdentityCardBackUrl.fileList[0]) ? { fileKey: values.auditAdditionalCert.agentIdentityCardBackUrl.fileList[0].fileKey } : null;
    values.auditAdditionalCert.agentPhone = values.auditAdditionalCert.agentPhone || null;
    values.auditAdditionalCert.accountLicenseNo = values.auditAdditionalCert.accountLicenseNo || null;
    values.cert.baseInfo.registerTime = values.cert.baseInfo.registerTime ? dayjs(values.cert.baseInfo.registerTime).valueOf() : null; // 注册时间
    values.cert.baseInfo.invitedSteel = formatInviteSteelValue(values.cert.baseInfo.invitedSteel); // 避免和后台返回的列表冲突
    values.additionalCert.supplyCategory = values.additionalCert.supplyCategory ? values.additionalCert.supplyCategory.targetKeys || [] : []; // 供应品类
    values.additionalCert.purchaserCategory = values.additionalCert.purchaserCategory ? values.additionalCert.purchaserCategory.targetKeys || [] : []; // 供应品类
    values = fixFormValues(values);
    reaction.qualification.complementCompanyQualification(values).then((res) => {
      if (res.code === 0) {
        message.success('保存成功!');
        historyPush(`/${RoutePath.MEMBERCENTER}/${RoutePath.CHECK_QUALIFICATION_LIST}`);
      }
    });
    // });
  };

  pass = (e) => {
    e && e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        values.userId = resolveUrlQuery(this.props.location.search).userId;
        values.department = values.department || '';

        // values.cert.baseInfo.companyNameZh = values.cert.baseInfo.companyNameZh || null;
        // values.cert.baseInfo.companyNameEn = values.cert.baseInfo.companyNameEn || null;

        values.cert.baseInfo.companyNameZh = formatStringSymbol(values.cert.baseInfo.companyNameZh);
        values.cert.baseInfo.companyNameEn = formatStringSymbol(values.cert.baseInfo.companyNameEn);
        values.cert.baseInfo.usedCompanyName = formatStringSymbol(values.cert.baseInfo.usedCompanyName);
        values.cert.baseInfo.companyAbbreviation = formatStringSymbol(values.cert.baseInfo.companyAbbreviation);

        values.cert.contactInfo.qq = values.cert.contactInfo.qq || null;
        values.additionalCert.foreignTradeEnterpriseCode = values.additionalCert.foreignTradeEnterpriseCode || null;
        values.auditAdditionalCert.businessLicenseExpirationTime = values.auditAdditionalCert.businessLicenseExpirationTime.expirationTime ? dayjs(values.auditAdditionalCert.businessLicenseExpirationTime.expirationTime).valueOf() : null;
        values.auditAdditionalCert.registeredCapital = values.auditAdditionalCert.registeredCapital * 10000;
        values.cert.attachment.businessLicenceUrl = (values.cert.attachment.businessLicenceUrl && values.cert.attachment.businessLicenceUrl.fileList && values.cert.attachment.businessLicenceUrl.fileList[0]) ? { fileKey: values.cert.attachment.businessLicenceUrl.fileList[0].fileKey } : null;
        values.cert.attachment.organizationCodeUrl = (values.cert.attachment.organizationCodeUrl && values.cert.attachment.organizationCodeUrl.fileList && values.cert.attachment.organizationCodeUrl.fileList[0]) ? { fileKey: values.cert.attachment.organizationCodeUrl.fileList[0].fileKey } : null;
        values.cert.attachment.taxRegistryUrl = (values.cert.attachment.taxRegistryUrl && values.cert.attachment.taxRegistryUrl.fileList && values.cert.attachment.taxRegistryUrl.fileList[0]) ? { fileKey: values.cert.attachment.taxRegistryUrl.fileList[0].fileKey } : null;
        values.cert.attachment.accountLicenseUrl = (values.cert.attachment.accountLicenseUrl && values.cert.attachment.accountLicenseUrl.fileList && values.cert.attachment.accountLicenseUrl.fileList[0]) ? { fileKey: values.cert.attachment.accountLicenseUrl.fileList[0].fileKey } : null;
        values.cert.proxy = values.cert.proxy && values.cert.proxy.fileList && values.cert.proxy.fileList.map(item => { return { fileKey: item.fileKey, url: item.url }; });
        values.additionalCert.foreignTradeRegistrationUrl = (values.additionalCert.foreignTradeRegistrationUrl && values.additionalCert.foreignTradeRegistrationUrl && values.additionalCert.foreignTradeRegistrationUrl.fileList && values.additionalCert.foreignTradeRegistrationUrl.fileList[0]) ? { fileKey: values.additionalCert.foreignTradeRegistrationUrl.fileList[0].fileKey } : null;
        values.auditAdditionalCert.legalPersonIdentityCardFrontUrl = (values.auditAdditionalCert.legalPersonIdentityCardFrontUrl && values.auditAdditionalCert.legalPersonIdentityCardFrontUrl.fileList && values.auditAdditionalCert.legalPersonIdentityCardFrontUrl.fileList[0]) ? { fileKey: values.auditAdditionalCert.legalPersonIdentityCardFrontUrl.fileList[0].fileKey } : null;
        values.auditAdditionalCert.legalPersonIdentityCardBackUrl = (values.auditAdditionalCert.legalPersonIdentityCardBackUrl && values.auditAdditionalCert.legalPersonIdentityCardBackUrl.fileList && values.auditAdditionalCert.legalPersonIdentityCardBackUrl.fileList[0]) ? { fileKey: values.auditAdditionalCert.legalPersonIdentityCardBackUrl.fileList[0].fileKey } : null;
        values.auditAdditionalCert.agentIdentityCardFrontUrl = (values.auditAdditionalCert.agentIdentityCardFrontUrl && values.auditAdditionalCert.agentIdentityCardFrontUrl.fileList && values.auditAdditionalCert.agentIdentityCardFrontUrl.fileList[0]) ? { fileKey: values.auditAdditionalCert.agentIdentityCardFrontUrl.fileList[0].fileKey } : null;
        values.auditAdditionalCert.agentIdentityCardBackUrl = (values.auditAdditionalCert.agentIdentityCardBackUrl && values.auditAdditionalCert.agentIdentityCardBackUrl.fileList && values.auditAdditionalCert.agentIdentityCardBackUrl.fileList[0]) ? { fileKey: values.auditAdditionalCert.agentIdentityCardBackUrl.fileList[0].fileKey } : null;
        values.auditAdditionalCert.agentPhone = values.auditAdditionalCert.agentPhone || null;
        values.auditAdditionalCert.accountLicenseNo = values.auditAdditionalCert.accountLicenseNo || null;
        values.cert.baseInfo.registerTime = values.cert.baseInfo.registerTime ? dayjs(values.cert.baseInfo.registerTime).valueOf() : null; // 注册时间
        values.additionalCert.supplyCategory = values.additionalCert.supplyCategory ? values.additionalCert.supplyCategory.targetKeys || [] : []; // 供应品类
        values.additionalCert.purchaserCategory = values.additionalCert.purchaserCategory ? values.additionalCert.purchaserCategory.targetKeys || [] : []; // 供应品类
        values = fixFormValues(values);

        reaction.qualification.makeQualificationOk(values).then((res) => {
          if (res.code === 0) {
            message.success('审核成功');
            historyPush(`/${RoutePath.MEMBERCENTER}/${RoutePath.CHECK_QUALIFICATION_LIST}`);
          }
        });
      }
    });
  };

  refuse = (e) => {
    e && e.preventDefault();
    if (!this.state.reason) {
      message.warning('请输入审核意见再提交!');
      return;
    }
    let userId = resolveUrlQuery(this.props.location.search).userId;
    let reason = this.state.reason;
    reaction.qualification.refuseQualification({
      userId, reason
    }).then((res) => {
      if (res.code === 0) {
        message.success('审核拒绝成功');
        historyPush(`/${RoutePath.MEMBERCENTER}/${RoutePath.CHECK_QUALIFICATION_LIST}`);
      }
    });
  };

  showRefuseModal = () => {
    this.setState({
      showRefuseModal: true
    });
  };
  cancelRefuse = () => {
    this.setState({
      showRefuseModal: false,
      reason: ''
    });
  };

  handleTableChange = (pagination, filters, sorter) => {
    let { query } = this.state;
    query = query.set('page', pagination.current);
    query = query.set('length', pagination.pageSize);
    this.setState({ query }, () => {
      this.getModifyHistoryList();
    });
  };

  beforeUpload = (onlyOne, file, fileList) => {
    if (file.size > 10240000) {
      message.error('文件大小不能超过10MB!');
      fileList.splice(fileList.indexOf(file), 1);
      return false;
    }
    if (!(/^image\//.test(file.type))) {
      message.error('只能上传图片文件!');
      fileList.splice(fileList.indexOf(file), 1);
      return false;
    }
    return true;
  };

  fileValidator = (rule, value, callback) => {
    if (!value.fileList || !value.fileList[0]) {
      callback(false);
    } else {
      callback();
    }
  };

  closeBigImgModal = () => {
    this.setState({
      showBigImg: false
    });
  };
  onUploadChange = (onlyOne, data, key) => {
    if (data.file.response && data.file.response.code === 0) {
      if (onlyOne && data.fileList.length > 1) {
        data.fileList.shift();
      }

    } else if (data.file.response && data.file.response.code !== 0) {
      data.fileList.splice(data.fileList.indexOf(data.file), 1);
      message.error(data.file.response.msg || '上传失败!');
    }
    data.fileList = data.fileList.map((item) => {
      if (item.response) {
        item.fileKey = item.response.data.url;
      }
      return item;
    });
    this.setState({
      [key]: data.fileList
    });
  };

  onRemove = (key, { file, fileList }) => {

  };

  getModifyHistoryList = () => {
    let { page, length } = this.state.query.toJS();
    return reaction.memberCenter.getOperationHistory({
      userId: resolveUrlQuery(this.props.location.search).userId,
      page,
      length,
      operationType: resolveUrlQuery(this.props.location.search).operationType
    }, false);
  };

  back = () => {
    historyPush(`/${RoutePath.MEMBERCENTER}/${RoutePath.CHECK_QUALIFICATION_LIST}`, {
      tabType: 'toComplete'
    });
  };

  onPreview = (file) => {
    const url = getFilePreviewUrl(file);
    this.setState({
      showBigImg: true,
      bigImgUrl: url
    }, () => {
      getPreviewImageWidth(url)
        .then(imgWidth => {
          this.setState({
            imgWidth
          });
        })
    });
  };

  onProxyTypeChange = (e) => {
    if (e.target.value === '3') {
      this.setState({
        showProxy: false
      });
    } else {
      this.setState({
        showProxy: true
      });
    }
  };

  getCompanyCharacter = (v) => {
    reaction.memberCenter.getCompanyCharacter(v);
  };

  getBusinessCharacter = () => {
    return reaction.memberCenter.getBusinessCharacter({}, false);
  };

  validateIsUserExist = ({ username, certName, phone }) => {
    return reaction.memberCenter.validateIsUserExist({
      username, certName, phone
    });
  };

  handleDepartmentChange = (_, { props }) => {
    this.fetchDepartmentTeamList(props.id);
    this.setFieldsValue('team', '');
  }

  async fetchDepartmentTeamList (id) {
    if (!id) {
      return false;
    }
    return reaction.frontUser.getDepartmentTeamList({
      parentId: id,
    }, false);
  }

  hasSelectDepartment () {
    return !!this.props.form.getFieldValue('department');
  }

  setFieldsValue (name, value) {
    this.props.form.setFieldsValue({
      [name]: value,
    })
  }

  async purchaserRenderState(identity) {
    if (!this.validateHasPurchaserCategory(identity)) {
      return false;
    }
    return new Promise(resolve => {
      this.setState({
        purchaserRenderPromise: resolve,
      });
    })
  }
  async supplyCategoryRenderState(identity) {
    if (!this.validateHasSupplyCategory(identity)) {
      return false;
    }
    return new Promise(resolve => {
      this.setState({
        supplyRenderPromise: resolve,
      });
    })
  }

  validateHasPurchaserCategory(identity) {
    return checkIsPurchase(identity);
  }

  validateHasSupplyCategory(identity = []) {
    return checkIsSupplier(identity);
  };

  async componentDidMount () {
    this.setState({
      loading: true,
    });
    await this.getModifyHistoryList();
    await this.getAreaDict();
    await this.getBusinessCharacter();
    const certInfo = await this.getQualificationInfo() || {};
    await this.purchaserRenderState(certInfo.identity);
    await this.supplyCategoryRenderState(certInfo.identity);
    
    await this.fetchDepartmentTeamList(certInfo.departmentId);
    this.setState({
      loading: false,
    });
  }

  getColumns () {
    return [{
      width: '112px',
      title: '时间',
      dataIndex: 'updateTime',
      render: text => {
        if (!text) {
          return '';
        }
        return dayjs(text - 0).format('YYYY-MM-DD HH:mm:ss');
      }
    }, {
      width: 140,
      title: '操作人',
      dataIndex: 'operator'
    }, {
      title: '内容',
      dataIndex: 'updateContent',
      render: (text) => {
        return (<pre style={{ maxWidth: '1000px' }}>
          {text}
        </pre>);
      }
    }];
  }

  render () {
    const { getFieldDecorator } = this.props.form;
    const { departments = [], manufactorTypeList } = this.props.me;
    const { teamList = [], teamListLoading } = this.props.frontUser;
    const invitednterprises = this.props.common.invitednterprises;

    let { companyCharacter, businessCharacter, qualificationDetailInfo } = this.props.memberCenter;
    let {
      businessLicenceUrl,
      organizationCodeUrl,
      taxRegistryUrl,
      accountLicenseUrl,
      proxy,
      foreignTradeRegistrationUrl,
      legalPersonIdentityCardFrontUrl,
      legalPersonIdentityCardBackUrl,
      agentIdentityCardFrontUrl,
      agentIdentityCardBackUrl,
      showProxy,
      // 省市区信息
      province,
      city,
      district,
      loading,
    } = this.state;
    let formItemLayout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 20 },
    };

    const hasCenpurCert = validHasCenpurCert(qualificationDetailInfo.identity || []);

    const uploadButton = (
      <div>
        <Icon type={this.state.loading ? 'loading' : 'plus'} />
        <div className="ant-upload-text">点击上传</div>
      </div>
    );

    let modifyHistoryList = this.props.memberCenter.operationHistoryList;
    
    return (
      <PageLoading loading={loading}>
        <h2 className={commonStyles.row}>资质信息补全</h2>
        <div style={{ overflow: 'hidden' }}>
          <div style={{ float: 'left', width: 100 }}>账户信息</div>
          <div style={{ marginLeft: 100 }}>
            <div style={{ marginBottom: 30 }}>
              <label style={{ padding: '0 10px 0 0', width: '16.6666666%', textAlign: 'right', display: 'inline-block' }}>会员编号:</label>
              <Input disabled={true} value={qualificationDetailInfo.user && qualificationDetailInfo.user.userNo} style={{ width: 250 }} />
            </div>
            <div style={{ marginBottom: 30 }}>
              <label style={{ padding: '0 10px 0 0', width: '16.6666666%', textAlign: 'right', display: 'inline-block' }}>用户名:</label>
              <Input disabled={true} value={qualificationDetailInfo.user && qualificationDetailInfo.user.username} style={{ width: 250 }} />
            </div>
            <div style={{ marginBottom: 30 }}>
              <label style={{ padding: '0 10px 0 0', width: '16.6666666%', textAlign: 'right', display: 'inline-block' }}>安全手机:</label>
              <Input disabled={true} value={qualificationDetailInfo.user && qualificationDetailInfo.user.phone} style={{ width: 250 }} />
            </div>
            <Form>
              <FormItem {...formItemLayout} label="责任部门">
                {getFieldDecorator('department', {
                  rules: [{ required: false, message: '请选择责任部门' }],
                  initialValue: qualificationDetailInfo.department || ''
                })(
                  <Select style={{ width: 250 }} onChange={this.handleDepartmentChange}>
                    {departments.map((item) => {
                      return (
                        <Option
                          key={item.code}
                          value={item.code}
                          id={item.id}
                        >
                          {item.name}
                        </Option>
                      );
                    })}
                  </Select>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="小组">
                {getFieldDecorator('team', {
                  validateTrigger: ['onBlur', 'onChange'],
                  initialValue: qualificationDetailInfo.team || ''
                })(
                  <Select style={{ width: 250 }} disabled={!this.hasSelectDepartment()} loading={teamListLoading}>
                    {teamList.map((item) => {
                      return (
                        <Option
                          key={item.code}
                          value={item.code || ''}
                        >
                          {item.name}
                        </Option>
                      );
                    })}
                  </Select>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="业务员">
                {getFieldDecorator('salesman', {
                  validateTrigger: ['onBlur', 'onChange'],
                  rules: [{
                    required: false,
                    whitespace: true,
                    message: '请输入业务员'
                  }],
                  initialValue: qualificationDetailInfo.salesman || null
                })(
                  <Input maxLength={5} style={{ width: 250 }} placeholder="请输入业务员" />
                )}
              </FormItem>
            </Form>
          </div>
        </div>
        <div style={{ overflow: 'hidden' }}>
          <div style={{ float: 'left', width: 100 }}>基础信息</div>
          <div style={{ marginLeft: 100 }}>
            <Form>
              <FormItem {...formItemLayout} label="公司名称：">
                {getFieldDecorator('cert.baseInfo.companyNameZh', {
                  validateTrigger: ['onBlur', 'onChange'],
                  rules: [{
                    required: true,
                    validator: (rule, value, callback) => {
                      if (!value) {
                        callback(new Error('请输入公司名称!'));
                        return;
                      }
                      if (value && value !== qualificationDetailInfo.cert.baseInfo.companyNameZh) {
                        this.validateIsUserExist({ certName: formatStringSymbol(value.trim()) }).then(res => {
                          if (res.data) {
                            callback(new Error('公司名称已存在!'));
                          } else {
                            callback();
                          }
                        });
                      } else {
                        callback();
                      }
                    }
                  }],
                  initialValue: qualificationDetailInfo.cert.baseInfo.companyNameZh
                })(
                  <Input style={{ width: 250 }} maxLength={50} placeholder="请输入公司名称" />
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="公司英文名：">
                {getFieldDecorator('cert.baseInfo.companyNameEn', {
                  rules: [{
                    required: false,
                    message: '请输入公司英文名'
                  }],
                  initialValue: qualificationDetailInfo.cert.baseInfo.companyNameEn
                })(
                  <Input style={{ width: 250 }} maxLength={100} placeholder="请输入公司英文名" />
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="公司简称：">
                {getFieldDecorator('cert.baseInfo.companyAbbreviation', {
                  rules: [{
                    required: false,
                    message: '请输入公司简称'
                  }],
                  initialValue: qualificationDetailInfo.cert.baseInfo.companyAbbreviation
                })(
                  <Input style={{ width: 250 }} maxLength={100} placeholder="请输入公司简称" />
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="公司曾用名：">
                {getFieldDecorator('cert.baseInfo.usedCompanyName', {
                  rules: [{
                    required: false,
                    message: '请输入公司曾用名'
                  }],
                  initialValue: qualificationDetailInfo.cert.baseInfo.usedCompanyName
                })(
                  <Input style={{ width: 250 }} maxLength={100} placeholder="请输入公司曾用名" />
                )}
              </FormItem>
              <FormItem labelCol={{ span: 16 }} wrapperCol={{ span: 8 }} style={{ width: '25%', display: 'inline-block' }} label="所在地：">
                {getFieldDecorator('cert.baseInfo.provinceCode', {
                  rules: [{
                    required: true,
                    message: '请选择省'
                  }],
                  initialValue: qualificationDetailInfo.cert.baseInfo.provinceCode
                })(
                  <Select
                    showSearch
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    onChange={this.onProvinceChange}>
                    {province.map((item) => {
                      return (<Option key={item.id} value={item.id}>{item.name}</Option>);
                    })}
                  </Select>
                )}
              </FormItem>
              <FormItem labelCol={{ span: 1 }} wrapperCol={{ span: 23 }} style={{ width: '10%', display: 'inline-block', marginLeft: 5 }} label="">
                {getFieldDecorator('cert.baseInfo.cityCode', {
                  rules: [{
                    required: true,
                    message: '请选择市'
                  }],
                  initialValue: qualificationDetailInfo.cert.baseInfo.cityCode
                })(
                  <Select
                    showSearch
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    onChange={this.onCityChange}>
                    {city.map((item) => {
                      return (<Option key={item.id} value={item.id}>{item.name}</Option>);
                    })}
                  </Select>
                )}
              </FormItem>
              <FormItem labelCol={{ span: 1 }} wrapperCol={{ span: 23 }} style={{ width: '10%', display: 'inline-block' }} label="">
                {getFieldDecorator('cert.baseInfo.districtCode', {
                  rules: [{
                    required: true,
                    message: '请选择区/县'
                  }],
                  initialValue: qualificationDetailInfo.cert.baseInfo.districtCode
                })(
                  <Select
                    showSearch
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    onChange={this.onDistrictChange}>
                    {district.map((item) => {
                      return (<Option key={item.id} value={item.id}>{item.name}</Option>);
                    })}
                  </Select>
                )}
              </FormItem>
              <FormItem labelCol={{ span: 1 }} wrapperCol={{ span: 23 }} style={{ width: '20%', display: 'inline-block' }} label="">
                {getFieldDecorator('cert.baseInfo.address', {
                  rules: [{
                    required: true,
                    whitespace: true,
                    message: '请选择输入详细地址'
                  }],
                  initialValue: qualificationDetailInfo.cert.baseInfo.address
                })(
                  <Input style={{ width: 250 }} placeholder="详细街道地址" />
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="公司注册时间">
                {getFieldDecorator('cert.baseInfo.registerTime', {
                  rules: [{
                    required: true,
                    message: '请选择公司注册时间'
                  }],
                  initialValue: qualificationDetailInfo.cert.baseInfo.registerTime ? dayjs(qualificationDetailInfo.cert.baseInfo.registerTime) : null
                })(
                  <DatePicker style={{ width: 250 }} />
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="公司网站">
                {getFieldDecorator('cert.baseInfo.website', {
                  validateTrigger: ['onBlur', 'onChange'],
                  rules: [{
                    required: false,
                    whitespace: true,
                    message: '请输入公司网站'
                  }],
                  initialValue: qualificationDetailInfo.cert.baseInfo.website
                })(
                  <Input style={{ width: 250 }} placeholder="公司网站" />
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="受邀钢厂">
                {getFieldDecorator('cert.baseInfo.invitedSteel', {
                  validateTrigger: ['onBlur', 'onChange'],
                  initialValue: invitednterprises.length ? formatInviteSteelValue(qualificationDetailInfo.cert.baseInfo.invitedSteel, true) : '暂无数据',
                  rules: [{
                    required: true,
                    message: '请选择受邀钢厂',
                  }],
                })(
                  <Select style={{ width: 250 }} placeholder="请选择受邀钢厂" disabled={!invitednterprises.length}>
                    {invitednterprises.map((item) => {
                      return (<Option key={item.code || 'none'} value={item.code || 'none'}>{item.name}</Option>);
                    })}
                  </Select>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="认证目的">
                {getFieldDecorator('cert.baseInfo.authPurpose', {
                  validateTrigger: ['onBlur', 'onChange'],
                  rules: [{
                    whitespace: true,
                  }],
                  initialValue: qualificationDetailInfo.cert.baseInfo.authPurpose
                })(
                  <Input maxLength={50} style={{ width: 250 }} placeholder="比如参与交易、招投标等" />
                )}
              </FormItem>
            </Form>
          </div>
        </div>
        <div style={{ overflow: 'hidden' }}>
          <div style={{ float: 'left', width: 100 }}>联系方式</div>
          <div style={{ marginLeft: 100 }}>
            <Form>
              <FormItem {...formItemLayout} label="联系人：">
                {getFieldDecorator('cert.contactInfo.contactPerson', {
                  validateTrigger: ['onBlur', 'onChange'],
                  rules: [{
                    required: true,
                    whitespace: true,
                    message: '请输入用户名'
                  }],
                  initialValue: qualificationDetailInfo.cert.contactInfo.contactPerson
                })(
                  <Input style={{ width: 250 }} placeholder="输入联系人" />
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="联系电话：">
                {getFieldDecorator('cert.contactInfo.phone', {
                  validateTrigger: ['onBlur', 'onChange'],
                  rules: [{
                    required: true,
                    whitespace: true,
                    message: '请输入联系电话'
                  }, {
                    whitespace: true,
                    pattern: /^1\d{10}$/,
                    message: '请输入正确格式的联系电话'
                  }],
                  initialValue: qualificationDetailInfo.cert.contactInfo.phone
                })(
                  <Input style={{ width: 250 }} placeholder="输入联系电话" />
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="企业电话：">
                {getFieldDecorator('cert.baseInfo.enterprisePhone', {
                  validateTrigger: ['onBlur', 'onChange'],
                  rules: [{
                    required: false,
                    whitespace: true,
                    message: '请输入企业电话'
                  }],
                  initialValue: qualificationDetailInfo.cert.baseInfo.enterprisePhone
                })(
                  <Input style={{ width: 250 }} placeholder="输入企业电话" />
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="企业传真：">
                {getFieldDecorator('cert.baseInfo.corporateFax', {
                  validateTrigger: ['onBlur', 'onChange'],
                  rules: [{
                    required: false,
                    whitespace: true,
                    message: '请输入企业传真'
                  }],
                  initialValue: qualificationDetailInfo.cert.baseInfo.corporateFax
                })(
                  <Input style={{ width: 250 }} maxLength={20} placeholder="请输入企业传真" />
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="邮编：">
                {getFieldDecorator('cert.baseInfo.zipCode', {
                  validateTrigger: ['onBlur', 'onChange'],
                  rules: [{
                    required: false,
                    whitespace: true,
                    pattern: /^\d{6}$/,
                    message: '请输入邮编'
                  }],
                  initialValue: qualificationDetailInfo.cert.baseInfo.zipCode
                })(
                  <Input style={{ width: 250 }} placeholder="输入邮编" />
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="QQ：">
                {getFieldDecorator('cert.contactInfo.qq', {
                  rules: [{
                    required: false,
                    message: '请输入QQ'
                  }],
                  initialValue: qualificationDetailInfo.cert.contactInfo.qq || null
                })(
                  <Input style={{ width: 250 }} placeholder="输入QQ" />
                )}
              </FormItem>
            </Form>
          </div>
        </div>
        <div style={{ overflow: 'hidden' }}>
          <div style={{ float: 'left', width: 100 }}>证件附件</div>
          <div style={{ marginLeft: 100 }}>
            <Form>
              <FormItem {...formItemLayout} label="营业执照：">
                {getFieldDecorator('cert.attachment.businessLicenceUrl', {
                  rules: [{
                    required: true,
                    message: '请上传营业执照',
                    validator: this.fileValidator
                  }],
                  initialValue: {
                    fileList: businessLicenceUrl
                  }
                })(
                  <Upload
                    name="file"
                    accept="image/jpg,image/jpeg,image/png"
                    action={env.getUploadApi('/v1/cert/upload')}
                    fileList={businessLicenceUrl}
                    listType="picture-card"
                    beforeUpload={(file, fileList) => { return this.beforeUpload(true, file, fileList); }}
                    onChange={(data) => { this.onUploadChange(true, data, 'businessLicenceUrl'); }}
                    onPreview={(file) => { this.onPreview(file); }}
                    onRemove={(file, fileList) => { this.onRemove('businessLicenceUrl', file, fileList); }}>
                    {uploadButton}
                  </Upload>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="组织机构代码：">
                {getFieldDecorator('cert.attachment.organizationCodeUrl', {
                  rules: [{
                    required: false,
                    message: '请上传组织机构代码'
                  }],
                  initialValue: {
                    fileList: organizationCodeUrl
                  }
                })(
                  <Upload
                    name="file"
                    accept="image/jpg,image/jpeg,image/png"
                    fileList={organizationCodeUrl}
                    action={env.getUploadApi('/v1/cert/upload')}
                    listType="picture-card"
                    beforeUpload={(file, fileList) => { return this.beforeUpload(true, file, fileList); }}
                    onChange={(data) => { this.onUploadChange(true, data, 'organizationCodeUrl'); }}
                    onPreview={(file) => { this.onPreview(file); }}
                    onRemove={(file, fileList) => { this.onRemove('organizationCodeUrl', file, fileList); }}>
                    {uploadButton}
                  </Upload>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="税务登记证：">
                {getFieldDecorator('cert.attachment.taxRegistryUrl', {
                  rules: [{
                    required: false,
                    message: '请上传税务登记证'
                  }],
                  initialValue: {
                    fileList: taxRegistryUrl
                  }
                })(
                  <Upload
                    name="file"
                    accept="image/jpg,image/jpeg,image/png"
                    fileList={taxRegistryUrl}
                    action={env.getUploadApi('/v1/cert/upload')}
                    listType="picture-card"
                    beforeUpload={(file, fileList) => { return this.beforeUpload(true, file, fileList); }}
                    onChange={(data) => { this.onUploadChange(true, data, 'taxRegistryUrl'); }}
                    onPreview={(file) => { this.onPreview(file); }}
                    onRemove={(file, fileList) => { this.onRemove('taxRegistryUrl', file, fileList); }}>
                    {uploadButton}
                  </Upload>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="开户许可证：">
                {getFieldDecorator('cert.attachment.accountLicenseUrl', {
                  rules: [{
                    required: hasCenpurCert,
                    message: '请上传开户许可证'
                  }],
                  initialValue: {
                    fileList: accountLicenseUrl
                  }
                })(
                  <Upload
                    name="file"
                    accept="image/jpg,image/jpeg,image/png"
                    fileList={accountLicenseUrl}
                    action={env.getUploadApi('/v1/cert/upload')}
                    listType="picture-card"
                    beforeUpload={(file, fileList) => { return this.beforeUpload(true, file, fileList); }}
                    onChange={(file) => { this.onUploadChange(true, file, 'accountLicenseUrl'); }}
                    onPreview={(file) => { this.onPreview(file); }}
                    onRemove={(file, fileList) => { this.onRemove('accountLicenseUrl', file, fileList); }}>
                    {uploadButton}
                  </Upload>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="委托书：">
                {getFieldDecorator('cert.proxyType', {
                  rules: [{
                    required: true,
                    message: '请选择委托书类型'
                  }],
                  initialValue: qualificationDetailInfo.cert.proxyType
                })(
                  <RadioGroup onChange={this.onProxyTypeChange}>
                    <Radio value={'1'}>企业授权委托书</Radio>
                    <Radio value={'2'}>业务担保授权书</Radio>
                    <Radio value={'3'}>无委托书，自愿不进行交易</Radio>
                  </RadioGroup>
                )}
              </FormItem>
              {
                showProxy && <FormItem {...formItemLayout} label="委托书附件：">
                  {getFieldDecorator('cert.proxy', {
                    rules: [{
                      required: true,
                      message: '请上传委托书附件',
                      validator: (rule, value, callback) => {
                        if (!(value.fileList) || !value.fileList.length) {
                          callback('请上传委托书附件');
                        }
                        callback();
                      }
                    }],
                    initialValue: {
                      fileList: proxy
                    }
                  })(
                    <Upload
                      name="file"
                      accept="image/jpg,image/jpeg,image/png"
                      fileList={proxy}
                      action={env.getUploadApi('/v1/cert/upload')}
                      listType="picture-card"
                      beforeUpload={(file, fileList) => { return this.beforeUpload(true, file, fileList); }}
                      onChange={(file) => { this.onUploadChange(false, file, 'proxy'); }}
                      onPreview={(file) => { this.onPreview(file); }}
                      onRemove={(file, fileList) => { this.onRemove('proxy', file, fileList); }}>
                      {uploadButton}
                    </Upload>
                  )}
                </FormItem>
              }
              {
                this.validateHasSupplyCategory(qualificationDetailInfo.identity??[]) &&
                <FormItem {...formItemLayout} label="供应品类">
                  {
                    getFieldDecorator('additionalCert.supplyCategory', {
                      rules: [
                        {
                          required: true,
                          message: '请选择供应品类',
                          validator: (rule, value, callback) => {
                            if (
                              rule.required &&
                              value.targetKeys &&
                              !value.targetKeys.length
                            ) {
                              callback('请选择供应品类');
                            } else {
                              callback();
                            }
                          }
                        }
                      ],
                      initialValue: { targetKeys: qualificationDetailInfo.additionalCert?.supplyCategory??[] }
                    })(
                      <TreeTransfer
                        onRendered={this.state.supplyRenderPromise}
                        expandParentKeys={qualificationDetailInfo.additionalCert?.supplyFullCategory??[]}
                      />
                    )
                  }
                </FormItem>
              }
              {
                  this.validateHasSupplyCategory(qualificationDetailInfo.identity??[]) && (
                    <FormItem {...formItemLayout} label="厂家类型：">
                      {
                        getFieldDecorator('additionalCert.manufactorType', {
                          initialValue: qualificationDetailInfo.additionalCert?.manufactorType,
                          rules: [
                            {
                              required: true,
                              message: '请选择厂家类型',
                            }
                          ],
                        })(
                          <Select style={{ width: 250 }}>
                            {
                              manufactorTypeList.map(el => {
                                return (
                                  <Option key={el.code} value={el.code}>{el.name}</Option>
                                );
                              })
                            }
                          </Select>
                        )
                      }
                    </FormItem>
                  )
                }
              {
                this.validateHasPurchaserCategory(qualificationDetailInfo.identity??[]) &&
                <FormItem {...formItemLayout} label="采购品类">
                  {
                    getFieldDecorator('additionalCert.purchaserCategory', {
                      rules: [
                        {
                          required: true,
                          message: '请选择采购品类',
                          validator: (rule, value, callback) => {
                            if (
                              rule.required &&
                              value.targetKeys &&
                              !value.targetKeys.length
                            ) {
                              callback('请选择采购品类');
                            } else {
                              callback();
                            }
                          }
                        }
                      ],
                      initialValue: { targetKeys: qualificationDetailInfo.additionalCert?.purchaserCategory??[] }
                    })(
                      <TreeTransfer
                        onRendered={this.state.purchaserRenderPromise}
                        expandParentKeys={qualificationDetailInfo.additionalCert?.purchaserFullCategory??[]}
                      />
                    )
                  }
                </FormItem>
              }
            </Form>
          </div>
        </div>
        <div style={{ overflow: 'hidden' }}>
          <div style={{ float: 'left', width: 100 }}>其他信息</div>
          <div style={{ marginLeft: 100 }}>
            <Form>
              <FormItem {...formItemLayout} label="外贸资质：">
                {getFieldDecorator('additionalCert.hasForeignTradeCert', {
                  rules: [{
                    required: false,
                    message: '请选择是否具有外贸资质'
                  }],
                  initialValue: qualificationDetailInfo.additionalCert && qualificationDetailInfo.additionalCert.hasForeignTradeCert
                })(
                  <RadioGroup>
                    <Radio value={true}>具备</Radio>
                    <Radio value={false}>不具备</Radio>
                  </RadioGroup>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="对外贸易经营者备案登记表附件：">
                {getFieldDecorator('additionalCert.foreignTradeRegistrationUrl', {
                  rules: [{
                    required: false,
                    message: '请上传对外贸易经营者备案登记表附件'
                  }],
                  initialValue: {
                    fileList: foreignTradeRegistrationUrl
                  }
                })(
                  <Upload
                    name="file"
                    accept="image/jpg,image/jpeg,image/png"
                    fileList={foreignTradeRegistrationUrl}
                    action={env.getUploadApi('/v1/cert/upload')}
                    listType="picture-card"
                    beforeUpload={(file, fileList) => { return this.beforeUpload(true, file, fileList); }}
                    onChange={(file) => { this.onUploadChange(true, file, 'foreignTradeRegistrationUrl'); }}
                    onPreview={(file) => { this.onPreview(file); }}
                    onRemove={(file, fileList) => { this.onRemove('foreignTradeRegistrationUrl', file, fileList); }}
                  >
                    {uploadButton}
                  </Upload>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="进出口企业代码号：">
                {getFieldDecorator('additionalCert.foreignTradeEnterpriseCode', {
                  rules: [{
                    required: false,
                    message: '请输入进出口企业代码号'
                  }],
                  initialValue: qualificationDetailInfo.additionalCert && qualificationDetailInfo.additionalCert.foreignTradeEnterpriseCode
                })(
                  <Input style={{ width: 250 }} placeholder="请输入进出口企业代码号" />
                )}
              </FormItem>
            </Form>
          </div>
        </div>
        <div style={{ overflow: 'hidden' }}>
          <div style={{ float: 'left', width: 100 }}>审核补录信息</div>
          <div style={{ marginLeft: 100 }}>
            <Form>
              <FormItem {...formItemLayout} label="经营性质：">
                {getFieldDecorator('auditAdditionalCert.proprietaryDealer', {
                  rules: [{
                    required: true,
                    whitespace: true,
                    message: '请选择经营性质'
                  }],
                  initialValue: (qualificationDetailInfo.auditAdditionalCert && qualificationDetailInfo.auditAdditionalCert.proprietaryDealer) || 'not_proprietary_dealer'
                })(
                  <Select style={{ width: 250 }}>
                    {businessCharacter.map((item) => {
                      return (<Option key={item.code} value={item.code}>{item.name}</Option>);
                    })}
                  </Select>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="公司性质：">
                {getFieldDecorator('auditAdditionalCert.companyCharacter', {
                  validateTrigger: ['onBlur', 'onChange'],
                  rules: [{
                    required: true,
                    whitespace: true,
                    message: '请输入公司性质'
                  }],
                  initialValue: qualificationDetailInfo.auditAdditionalCert && qualificationDetailInfo.auditAdditionalCert.companyCharacter
                })(
                  <AutoComplete onChange={(v) => { this.getCompanyCharacter(v); }} style={{ width: 250 }} placeholder="请输入公司性质">
                    {
                      companyCharacter.map(item => {
                        return (<AOption key={item.code} value={item.name}>{item.name}</AOption>);
                      })
                    }
                  </AutoComplete>
                )}
              </FormItem>

              <FormItem {...formItemLayout} label="注册资本(万元)：">
                {getFieldDecorator('auditAdditionalCert.registeredCapital', {
                  validateTrigger: ['onBlur', 'onChange'],
                  rules: [{
                    required: true,
                    whitespace: true,
                    pattern: /^\d+(\.(?:\d+))?$/,
                    message: '请输入注册资本'
                  }],
                  initialValue: qualificationDetailInfo.auditAdditionalCert && qualificationDetailInfo.auditAdditionalCert.registeredCapital && qualificationDetailInfo.auditAdditionalCert.registeredCapital / 10000
                })(
                  <Input style={{ width: 250 }} placeholder="请输入注册资本" />
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="公司经营范围：">
                {getFieldDecorator('auditAdditionalCert.companyBusinessScope', {
                  validateTrigger: ['onBlur', 'onChange'],
                  rules: [{
                    required: true,
                    whitespace: true,
                    message: '请输入公司经营范围'
                  }],
                  initialValue: qualificationDetailInfo.auditAdditionalCert && qualificationDetailInfo.auditAdditionalCert.companyBusinessScope
                })(
                  <TextArea rows={4} maxLength={500} style={{ width: 250 }} placeholder="请输入公司经营范围" />
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="法人姓名：">
                {getFieldDecorator('auditAdditionalCert.legalPersonName', {
                  validateTrigger: ['onBlur', 'onChange'],
                  rules: [{
                    required: true,
                    whitespace: true,
                    message: '请输入法人姓名'
                  }],
                  initialValue: qualificationDetailInfo.auditAdditionalCert && qualificationDetailInfo.auditAdditionalCert.legalPersonName
                })(
                  <Input style={{ width: 250 }} placeholder="请输入法人姓名" />
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="法人身份证附件正面：">
                {getFieldDecorator('auditAdditionalCert.legalPersonIdentityCardFrontUrl', {
                  rules: [{
                    required: false,
                    message: '请上传法人身份证附件正面'
                  }],
                  initialValue: {
                    fileList: legalPersonIdentityCardFrontUrl
                  }
                })(
                  <Upload
                    name="file"
                    accept="image/jpg,image/jpeg,image/png"
                    fileList={legalPersonIdentityCardFrontUrl}
                    action={env.getUploadApi('/v1/cert/upload')}
                    listType="picture-card"
                    beforeUpload={(file, fileList) => { return this.beforeUpload(true, file, fileList); }}
                    onChange={(file) => { this.onUploadChange(true, file, 'legalPersonIdentityCardFrontUrl'); }}
                    onPreview={(file) => { this.onPreview(file); }}
                    onRemove={(file, fileList) => { this.onRemove('legalPersonIdentityCardFrontUrl', file, fileList); }}
                  >
                    {uploadButton}
                  </Upload>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="法人身份证附件背面：">
                {getFieldDecorator('auditAdditionalCert.legalPersonIdentityCardBackUrl', {
                  rules: [{
                    required: false,
                    message: '请上传法人身份证附件背面'
                  }],
                  initialValue: {
                    fileList: legalPersonIdentityCardBackUrl
                  }
                })(
                  <Upload
                    name="file"
                    accept="image/jpg,image/jpeg,image/png"
                    fileList={legalPersonIdentityCardBackUrl}
                    action={env.getUploadApi('/v1/cert/upload')}
                    listType="picture-card"
                    beforeUpload={(file, fileList) => { return this.beforeUpload(true, file, fileList); }}
                    onChange={(file) => { this.onUploadChange(true, file, 'legalPersonIdentityCardBackUrl'); }}
                    onPreview={(file) => { this.onPreview(file); }}
                    onRemove={(file, fileList) => { this.onRemove('legalPersonIdentityCardBackUrl', file, fileList); }}
                  >
                    {uploadButton}
                  </Upload>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="营业执照期限：">
                {getFieldDecorator('auditAdditionalCert.businessLicenseExpirationTime', {
                  rules: [{
                    required: true,
                    message: '请选择营业执照期限',
                    validator: (rule, value, callback) => {
                      if (!value.expirationTime) {
                        callback('请选择营业执照期限!');
                      } else {
                        callback();
                      }
                    }
                  }],
                  initialValue: { expirationTime: qualificationDetailInfo.auditAdditionalCert.businessLicenseExpirationTime ? dayjs(qualificationDetailInfo.auditAdditionalCert.businessLicenseExpirationTime) : null }
                })(
                  <DatePickAndCheckboxFormItem />
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="社会统一信用代码：">
                {getFieldDecorator('auditAdditionalCert.unifiedSocialCreditCode', {
                  validateTrigger: ['onBlur', 'onChange'],
                  rules: [{
                    required: true,
                    whitespace: true,
                    message: '请输入社会统一信用代码'
                  }],
                  initialValue: qualificationDetailInfo.auditAdditionalCert && qualificationDetailInfo.auditAdditionalCert.unifiedSocialCreditCode
                })(
                  <Input style={{ width: 250 }} placeholder="请输入社会统一信用代码" />
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="委托人姓名：">
                {getFieldDecorator('auditAdditionalCert.agentName', {
                  validateTrigger: ['onBlur', 'onChange'],
                  rules: [{
                    required: true,
                    whitespace: true,
                    message: '请输入委托人姓名'
                  }],
                  initialValue: qualificationDetailInfo.auditAdditionalCert && qualificationDetailInfo.auditAdditionalCert.agentName
                })(
                  <Input style={{ width: 250 }} placeholder="请输入委托人姓名" />
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="开户许可证核准号：">
                {getFieldDecorator('auditAdditionalCert.accountLicenseNo', {
                  rules: [{
                    required: hasCenpurCert,
                    message: '请输入开户许可证核准号'
                  }],
                  initialValue: qualificationDetailInfo.auditAdditionalCert && qualificationDetailInfo.auditAdditionalCert.accountLicenseNo
                })(
                  <Input style={{ width: 250 }} placeholder="请输入开户许可证核准号" />
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="开户行：">
                {getFieldDecorator('auditAdditionalCert.openBank', {
                  rules: [{
                    required: hasCenpurCert,
                    message: '请输入开户行'
                  }],
                  initialValue: qualificationDetailInfo.auditAdditionalCert && qualificationDetailInfo.auditAdditionalCert.openBank
                })(
                  <Input style={{ width: 250 }} placeholder="请输入开户行" />
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="户名：">
                {getFieldDecorator('auditAdditionalCert.accountName', {
                  rules: [{
                    required: hasCenpurCert,
                    message: '请输入户名'
                  }],
                  initialValue: qualificationDetailInfo.auditAdditionalCert && qualificationDetailInfo.auditAdditionalCert.accountName
                })(
                  <Input style={{ width: 250 }} placeholder="请输入户名" />
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="账号：">
                {getFieldDecorator('auditAdditionalCert.accountNo', {
                  rules: [{
                    required: hasCenpurCert,
                    pattern: /^\d{1,50}$/,
                    message: '请输入正确格式的账号'
                  }],
                  initialValue: qualificationDetailInfo.auditAdditionalCert && qualificationDetailInfo.auditAdditionalCert.accountNo
                })(
                  <Input style={{ width: 250 }} placeholder="请输入账号" />
                )}
              </FormItem>

              <FormItem {...formItemLayout} label="保证金：">
                {getFieldDecorator('auditAdditionalCert.bond', {
                  rules: [{
                    required: false,
                    pattern: /^\d+\.?(?:\d+)$/,
                    message: '请输入保证金'
                  }],
                  initialValue: qualificationDetailInfo.auditAdditionalCert && qualificationDetailInfo.auditAdditionalCert.bond
                })(
                  <Input style={{ width: 250 }} placeholder="请输入保证金" />
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="委托人电话：">
                {getFieldDecorator('auditAdditionalCert.agentPhone', {
                  rules: [{
                    required: false,
                    pattern: /^1[\d]{10}$/,
                    message: '请输入委托人电话'
                  }],
                  initialValue: qualificationDetailInfo.auditAdditionalCert && qualificationDetailInfo.auditAdditionalCert.agentPhone
                })(
                  <Input style={{ width: 250 }} placeholder="请输入委托人电话" />
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="委托人身份证正面：">
                {getFieldDecorator('auditAdditionalCert.agentIdentityCardFrontUrl', {
                  rules: [{
                    required: false,
                    message: '请上传委托人身份证正面'
                  }],
                  initialValue: {
                    fileList: agentIdentityCardFrontUrl
                  }
                })(
                  <Upload
                    name="file"
                    accept="image/jpg,image/jpeg,image/png"
                    fileList={agentIdentityCardFrontUrl}
                    action={env.getUploadApi('/v1/cert/upload')}
                    listType="picture-card"
                    beforeUpload={(file, fileList) => { return this.beforeUpload(true, file, fileList); }}
                    onChange={(file) => { this.onUploadChange(true, file, 'agentIdentityCardFrontUrl'); }}
                    onPreview={(file) => { this.onPreview(file); }}
                    onRemove={(file, fileList) => { this.onRemove('agentIdentityCardFrontUrl', file, fileList); }}
                  >
                    {uploadButton}
                  </Upload>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="委托人身份证背面：">
                {getFieldDecorator('auditAdditionalCert.agentIdentityCardBackUrl', {
                  rules: [{
                    required: false,
                    message: '请上传委托人身份证背面'
                  }],
                  initialValue: {
                    fileList: agentIdentityCardBackUrl
                  }
                })(
                  <Upload
                    name="file"
                    accept="image/jpg,image/jpeg,image/png"
                    fileList={agentIdentityCardBackUrl}
                    action={env.getUploadApi('/v1/cert/upload')}
                    listType="picture-card"
                    beforeUpload={(file, fileList) => { return this.beforeUpload(true, file, fileList); }}
                    onChange={(file) => { this.onUploadChange(true, file, 'agentIdentityCardBackUrl'); }}
                    onPreview={(file) => { this.onPreview(file); }}
                    onRemove={(file, fileList) => { this.onRemove('agentIdentityCardBackUrl', file, fileList); }}>
                    {uploadButton}
                  </Upload>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="是否开通资金账户：">
                {getFieldDecorator('addFundsAccount', {
                  rules: [{
                    required: true,
                    message: '请选择是否开通资金账户'
                  }],
                  initialValue: qualificationDetailInfo.addFundsAccount
                })(
                  <RadioGroup>
                    <Radio value={true}>开通</Radio>
                    <Radio value={false}>不开通</Radio>
                  </RadioGroup>
                )}
              </FormItem>
            </Form>
          </div>
        </div>
        <div style={{ padding: '10px 0 50px 150px' }}>
          <Button onClick={this.back} style={{ marginLeft: 10 }}>返回</Button>
          <Button onClick={this.handleSubmit} style={{ marginLeft: 10 }}>保存</Button>
          <Button onClick={this.showRefuseModal} style={{ marginLeft: 10 }}>审核拒绝</Button>
          <Button onClick={this.pass} style={{ marginLeft: 10 }}>审核通过</Button>
        </div>
        <div style={{ padding: '0 0 30px 0' }}>
          {modifyHistoryList && (
            <Table
              className={commonStyles.row}
              rowKey={getUniqTableRowKey('operator', modifyHistoryList.pagerInfo)}
              columns={this.getColumns()}
              pagination={{ current: modifyHistoryList.pagerInfo.page, total: modifyHistoryList.pagerInfo.total }}
              onChange={this.handleTableChange}
              dataSource={modifyHistoryList.result}
            />
          )}
        </div>

        <Modal
          title="查看大图"
          width={this.state.imgWidth + 48}
          visible={this.state.showBigImg}
          onOk={this.closeBigImgModal}
          onCancel={this.closeBigImgModal}>
          <img src={this.state.bigImgUrl} alt="" />
        </Modal>

        <Modal
          title="提示"
          width={400}
          visible={this.state.showRefuseModal}
          onOk={this.refuse}
          onCancel={this.cancelRefuse}
        >
          <h4>请备注拒绝原因:</h4>
          <TextArea
            value={this.state.reason}
            rows={5}
            onChange={(e) => { this.setState({ reason: e.target.value }); }}
            maxLength={REJECT_REASON_MAX_LENGTH}
            placeholder={`不限类型，${REJECT_REASON_MAX_LENGTH}字以内`}
          />
        </Modal>
      </PageLoading>
    );
  }
}

export default ComplementCompanyQualification;