import React from 'react';
import dayjs from 'dayjs';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { Button, Input, Table, Modal, message, Tabs, Popconfirm, Form } from 'antd';

import FormItem from 'components/FormItem';

import history from 'utils/history';
import reaction from 'reaction/index';
import { AuthCodes, RoutePath } from 'utils/constants';
import { getUniqTableRowKey, renderListControls } from 'utils';

import commonStyles from 'style/common.module.scss';

const { TabPane } = Tabs;

@connect(({ smsBlackList, me }) => ({ smsBlackList, me }))
class SmsBlackListManagement extends React.Component {
  state = {
    query: Immutable.Map({
      phone: '',
      page: 1,
      length: 10,
    }),
    accountQuery: Immutable.Map({
      username: '',
      userId: '',
      certSubject: '',
      phone: '',
      page: 1,
      length: 10,
    }),
    currentRow: null,
    newPhone: '',
    addModalVisible: false,
  };

  getSmsPhoneBlackList = () => {
    let query = this.state.query.toJS();
    reaction.smsBlackList.getSmsPhoneBlackList(query);
  };

  getSmsAccountBlackList = () => {
    let accountQuery = this.state.accountQuery.toJS();
    reaction.smsBlackList.getSmsAccountBlackList(accountQuery);
  };

  onQueryChange = (name, value) => {
    let { query } = this.state;
    query = query.set(name, value);
    this.setState({ query }, () => {});
  };

  onAccountQueryChange = (name, value) => {
    let { accountQuery } = this.state;
    accountQuery = accountQuery.set(name, value);
    this.setState({ accountQuery }, () => {});
  };

  handleTableChange = (pagination, filters, sorter) => {
    let { query } = this.state;
    query = query.set('page', pagination.current);
    query = query.set('length', pagination.pageSize);
    this.setState({ query }, () => {
      this.getSmsPhoneBlackList();
    });
  };

  handleAccountTableChange = (pagination, filters, sorter) => {
    let { accountQuery } = this.state;
    accountQuery = accountQuery.set('page', pagination.current);
    accountQuery = accountQuery.set('length', pagination.pageSize);
    this.setState({ accountQuery }, () => {
      this.getSmsAccountBlackList();
    });
  };

  search = () => {
    let { query } = this.state;
    query = query.set('page', 1);
    this.setState({ query }, () => {
      this.getSmsPhoneBlackList();
    });
  };

  searchAccountList = () => {
    let { accountQuery } = this.state;
    accountQuery = accountQuery.set('page', 1);
    this.setState({ accountQuery }, () => {
      this.getSmsAccountBlackList();
    });
  };

  showAddPhoneBlackListDialog = () => {
    this.setState({
      addModalVisible: true,
    });
  };

  hideAddPhoneBlackListDialog = () => {
    this.setState({
      newPhone: '',
      addModalVisible: false,
    });
  };

  goToAddAccountBlackList = () => {
    history.push({
      pathname: `/${RoutePath.OPERATION_CENTER}/${RoutePath.SMS_BLACK_LIST_MANAGEMENT}/${RoutePath.SMS_BLACK_LIST_ACCOUNT_MANAGEMENT}`,
    });
  };

  confirmDelete = (row) => {
    reaction.smsBlackList.deleteFromBlackList(row.phone).then((res) => {
      if (res.code === 0) {
        message.success('移出成功!');
        this.getSmsPhoneBlackList();
      }
    });
  };

  confirmDeleteAccountFromBlackList = (row) => {
    reaction.smsBlackList
      .confirmDeleteAccountFromBlackList(row.userId)
      .then((res) => {
        if (res.code === 0) {
          message.success('移出成功!');
          this.getSmsAccountBlackList();
        }
      });
  };

  handlePhoneChange = (v) => {
    this.setState({
      newPhone: v,
    });
  };

  addNewPhoneToBlackList = () => {
    if (!/^\d{11}$/.test(this.state.newPhone)) {
      message.error('请输入11位手机号码!');
      return;
    }
    reaction.smsBlackList
      .addNewPhoneToBlackList(this.state.newPhone)
      .then((res) => {
        if (res.code === 0) {
          message.success('新增成功!');
          this.hideAddPhoneBlackListDialog();
          this.getSmsPhoneBlackList();
        }
      });
  };

  handleTabsChange = () => {
    let { query, accountQuery } = this.state;
    query = query.set('phone', '');
    accountQuery = accountQuery.set('username', '');
    accountQuery = accountQuery.set('userId', '');
    accountQuery = accountQuery.set('certSubject', '');
    accountQuery = accountQuery.set('phone', '');
    this.setState({ query, accountQuery }, () => {});
  };

  componentDidMount() {
    this.getSmsPhoneBlackList();
    this.getSmsAccountBlackList();
  }

  render() {
    let { smsPhoneBlackList, smsAccountBlackList } = this.props.smsBlackList;
    let { addModalVisible, newPhone } = this.state;
    let { query, accountQuery } = this.state;
    let { roleAuthList } = this.props.me.roleInfo;
    let enableAddPhoneBlackList =
      roleAuthList.filter(
        (item) => item.authCode === AuthCodes.OPERATION_SMS_ADD_PHONE_BLACKLIST
      ).length >= 1;
    let enableAddAccountBlackList =
      roleAuthList.filter(
        (item) =>
          item.authCode === AuthCodes.OPERATION_SMS_ADD_ACCOUNT_BLACKLIST
      ).length >= 1;
    let columns = [
      {
        title: '手机号码',
        dataIndex: 'phone',
      },
      {
        title: '加入时间',
        dataIndex: 'createTime',
        render: (text) => {
          if (!text) {
            return '';
          }
          return dayjs(text - 0).format('YYYY-MM-DD HH:mm:ss');
        },
      },
      {
        title: '操作人',
        dataIndex: 'operator',
      },
      {
        title: '操作',
        key: 'handler',
        render: (row) => {
          return (
            <Popconfirm
              placement="top"
              title="确认移出？"
              onConfirm={() => {
                this.confirmDelete(row);
              }}
              okText="确定"
              cancelText="取消"
            >
              <Button type="primary">移出黑名单</Button>
            </Popconfirm>
          );
        },
      },
    ];
    let columns1 = [
      {
        title: '用户名',
        dataIndex: 'username',
      },
      {
        title: '用户ID',
        dataIndex: 'userId',
      },
      {
        title: '主体名称',
        dataIndex: 'certSubject',
      },
      {
        title: '账户类型',
        dataIndex: 'userType',
      },
      {
        title: '手机号码',
        dataIndex: 'phone',
      },
      {
        title: '加入时间',
        dataIndex: 'createTime',
        render: (text) => {
          if (!text) {
            return '';
          }
          return dayjs(text - 0).format('YYYY-MM-DD HH:mm:ss');
        },
      },
      {
        title: '操作人',
        dataIndex: 'operator',
      },
      {
        title: '操作',
        key: 'handler',
        render: (row) => {
          return (
            <Popconfirm
              placement="top"
              title="确认移出？"
              onConfirm={() => {
                this.confirmDeleteAccountFromBlackList(row);
              }}
              okText="确定"
              cancelText="取消"
            >
              <Button type="primary">移出黑名单</Button>
            </Popconfirm>
          );
        },
      },
    ];

    return (
      <div>
        <div style={{ padding: '10px 0 30px 0' }}>
          {enableAddAccountBlackList ? (
            <Button
              style={{ float: 'right' }}
              onClick={this.goToAddAccountBlackList}
              type="primary"
            >
              新增账户黑名单
            </Button>
          ) : (
            ''
          )}
          {enableAddPhoneBlackList ? (
            <Button
              style={{ float: 'right', marginRight: 10 }}
              onClick={this.showAddPhoneBlackListDialog}
              type="primary"
            >
              新增手机黑名单
            </Button>
          ) : (
            ''
          )}
        </div>
        <Tabs
          onChange={this.handleTabsChange}
          style={{ width: '100%' }}
          type="card"
        >
          <TabPane tab="短信手机黑名单" key="1">
            <div>
              <Form layout="inline">
                {
                  renderListControls([
                    <FormItem label="手机号码">
                      <Input
                        maxLength={11}
                        value={query.toJS().phone}
                        onChange={(e) => {
                          this.onQueryChange('phone', e.target.value);
                        }}
                        // style={{ width: '250px', marginLeft: '15px', marginTop: 10 }}
                        // addonBefore="手机号码"
                        defaultValue=""
                        placeholder="请输入手机号码"
                      />
                    </FormItem>,
                    {
                      colProps: {
                        // xxl: 
                      },
                      children: (
                        <FormItem push={20}>
                          <Button
                            type="primary"
                            // style={{ marginLeft: '15px' }}
                            onClick={this.search}
                            icon="search"
                          >
                            查询
                          </Button>
                        </FormItem>
                      )
                    }
                  ])
                }
              </Form>
              <div style={{ padding: '20px 0 0 0' }}>
                {
                  <Table
                    className={commonStyles.row}
                    rowKey={getUniqTableRowKey('phone', smsPhoneBlackList.pagerInfo)}
                    columns={columns}
                    dataSource={
                      smsPhoneBlackList ? smsPhoneBlackList.result : []
                    }
                    pagination={
                      smsPhoneBlackList
                        ? {
                            current: smsPhoneBlackList.pagerInfo.page,
                            total: smsPhoneBlackList.pagerInfo.total,
                            pageSizeOptions: ['10', '20', '50'],
                            showSizeChanger: true,
                            showQuickJumper: true,
                            showTotal: (total) => `共${total}条`,
                          }
                        : false
                    }
                    onChange={this.handleTableChange}
                  />
                }
              </div>
            </div>
          </TabPane>
          <TabPane tab="短信账户黑名单" key="2">
            <div>
              <Form
                layout="inline"
                onSubmit={e => {
                  e.preventDefault();
                  this.searchAccountList();
                }}
              >
                {
                  renderListControls([
                    <FormItem label="用户名">
                      <Input
                        maxLength={32}
                        value={accountQuery.toJS().username}
                        onChange={(e) => {
                          this.onAccountQueryChange('username', e.target.value);
                        }}
                        // style={{ width: '250px', marginLeft: '15px', marginTop: 10 }}
                        // addonBefore="用户名"
                        defaultValue=""
                        placeholder="请输入用户名"
                      />
                    </FormItem>,
                    <FormItem label="用户ID">
                      <Input
                        maxLength={32}
                        value={accountQuery.toJS().userId}
                        onChange={(e) => {
                          this.onAccountQueryChange('userId', e.target.value);
                        }}
                        // style={{ width: '250px', marginLeft: '15px', marginTop: 10 }}
                        // addonBefore="用户ID"
                        defaultValue=""
                        placeholder="请输入用户ID"
                      />
                    </FormItem>,
                    <FormItem label="主体名称">
                      <Input
                        maxLength={32}
                        value={accountQuery.toJS().certSubject}
                        onChange={(e) => {
                          this.onAccountQueryChange('certSubject', e.target.value);
                        }}
                        // style={{ width: '250px', marginLeft: '15px', marginTop: 10 }}
                        // addonBefore="主体名称"
                        defaultValue=""
                        placeholder="请输入主体名称"
                      />
                    </FormItem>,
                    <FormItem label="手机号码">
                      <Input
                        maxLength={11}
                        value={accountQuery.toJS().phone}
                        onChange={(e) => {
                          this.onAccountQueryChange('phone', e.target.value);
                        }}
                        // style={{ width: '250px', marginLeft: '15px', marginTop: 10 }}
                        // addonBefore="手机号码"
                        defaultValue=""
                        placeholder="请输入手机号码"
                      />
                    </FormItem>,
                    {
                      colProps: {
                        xxl: 24,
                        xl: 16,
                        md: 24,
                        lg: 24,
                      },
                      children: (
                        <FormItem align="right">
                          <Button
                            type="primary"
                            // style={{ marginLeft: '15px', marginTop: 10 }}
                            onClick={this.searchAccountList}
                            icon="search"
                          >
                            查询
                          </Button>
                        </FormItem>
                      )
                    },
                  ])
                }
              </Form>
              <div style={{ padding: '20px 0 0 0' }}>
                {
                  <Table
                    className={commonStyles.row}
                    rowKey={getUniqTableRowKey('userId', smsAccountBlackList.pagerInfo)}
                    columns={columns1}
                    dataSource={
                      smsAccountBlackList ? smsAccountBlackList.result : []
                    }
                    pagination={
                      smsAccountBlackList
                        ? {
                            current: smsAccountBlackList.pagerInfo.page,
                            total: smsAccountBlackList.pagerInfo.total,
                            pageSizeOptions: ['10', '20', '50'],
                            showSizeChanger: true,
                            showQuickJumper: true,
                            showTotal: (total) => `共${total}条`,
                          }
                        : false
                    }
                    onChange={this.handleAccountTableChange}
                  />
                }
              </div>
            </div>
          </TabPane>
        </Tabs>

        <Modal
          width={350}
          title="新增手机黑名单"
          visible={addModalVisible}
          onOk={this.addNewPhoneToBlackList}
          onCancel={this.hideAddPhoneBlackListDialog}
        >
          <p style={{ color: 'orange', padding: '0 0 20px 0' }}>
            注意：新增手机号码至本黑名单后，该手机将不再收到中联钢信关联系统的短信通知，请谨慎处理。
          </p>
          <Input
            maxLength={11}
            value={newPhone}
            onChange={(e) => {
              this.handlePhoneChange(e.target.value);
            }}
            placeholder="请输入手机号码"
          />
        </Modal>
      </div>
    );
  }
}

export default SmsBlackListManagement;
