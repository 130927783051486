import React from 'react';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import Immutable from 'immutable';
import reaction from 'reaction';
import {
  Table,
  Button,
  Input,
  Select,
  Modal,
  Radio,
  // message,
  Upload,
  Icon,
  Form,
  Tree,
} from 'antd';
import { historyPush } from 'utils/history';
import { RoutePath } from 'utils/constants';
import { validHasCenpurCert, resolveUrlQuery, formatInviteSteelText, checkIsSupplier, checkIsPurchase } from 'utils';
import MagnifyEnableImg from 'components/MagnifyEnableImg';

import unuploadImg from 'assets/unupload.png';
// import TreeTransfer from 'components/TreeTransfer';

import commonStyles from 'style/common.module.scss';

const FormItem = Form.Item;
const RadioGroup = Radio.Group;
const { TextArea } = Input;
const Option = Select.Option;

@Form.create()
@connect(({ memberCenter, common }) => ({ memberCenter, common }))
class ViewQualificationDetail extends React.Component {
  state = {
    query: Immutable.Map({
      page: 1,
      length: 10,
    }),
    province: [],
    city: [],
    district: [],
    bigImgUrl: '',
    showBigImg: false,
    treeData: [],
  };
  closeBigImgModal = () => {
    this.setState({
      showBigImg: false,
    });
  };
  onPreview = (e) => {
    this.setState({
      showBigImg: true,
      bigImgUrl: e.target.getAttribute('src'),
    });
  };
  getAreaDict = () => {
    reaction.memberCenter.getAreaDict().then((res) => {
      if (res.code === 0) {
        this.setState(
          {
            province: res.data.filter((item) => item.type === '2'),
          },
          () => {
            this.getQualificationInfo();
          }
        );
      }
    });
  };

  getQualificationInfo = () => {
    let userId = resolveUrlQuery(this.props.location.search).userId;
    reaction.memberCenter.getQualificationInfo({ userId }).then((res) => {
      if (res.code === 0) {
        this.setState({
          city: this.props.memberCenter.areaDict.filter(
            (item) => item.id === res.data.cert.baseInfo.cityCode
          ),
          district: this.props.memberCenter.areaDict.filter(
            (item) => item.id === res.data.cert.baseInfo.districtCode
          ),
        });
      }
    });
  };

  handleTableChange = (pagination, filters, sorter) => {
    let { query } = this.state;
    query = query.set('page', pagination.current);
    query = query.set('length', pagination.pageSize);
    this.setState({ query }, () => {
      this.getModifyHistoryList();
    });
  };

  getModifyHistoryList = () => {
    let { page, length } = this.state.query.toJS();
    reaction.memberCenter.getOperationHistory({
      userId: resolveUrlQuery(this.props.location.search).userId,
      page,
      length,
      operationType: resolveUrlQuery(this.props.location.search).operationType,
    });
  };

  back = () => {
    let backPath = resolveUrlQuery(this.props.location.search).backPath;
    if (backPath) {
      historyPush(`${decodeURIComponent(backPath)}`);
    } else {
      historyPush(`/${RoutePath.MEMBERCENTER}/${RoutePath.QUALIFICATION_LIST}`);
    }
  };

  getBusinessCharacter = () => {
    reaction.memberCenter.getBusinessCharacter();
  };

  getDepartmentDict = () => {
    reaction.common.getDepartmentDict();
  };
  fixTree = (tree) => {
    tree.forEach((item) => {
      item.key = (item.key || item.id || item.code || item.value) + '';
      item.title = item.title || item.name;
      if (item.children) {
        this.fixTree(item.children);
      }
    });
  };
  getTreeData = () => {
    return reaction.common.getSupplyCategory().then((res) => {
      this.fixTree(res.data);
      this.setState({
        treeData: res.data,
      });
      return res;
    });
  };
  componentDidMount() {
    this.getDepartmentDict();
    this.getTreeData();
    this.getModifyHistoryList();
    this.getAreaDict();
    this.getBusinessCharacter();
  }
  getColumns() {
    return [
      {
        width: 112,
        title: '时间',
        dataIndex: 'updateTime',
        render: (text) => {
          if (!text) {
            return '';
          }
          return dayjs(text - 0).format('YYYY-MM-DD HH:mm:ss');
        },
      },
      {
        width: 140,
        title: '操作人',
        dataIndex: 'operator',
      },
      {
        title: '内容',
        dataIndex: 'updateContent',
        render: (text) => {
          return <pre style={{ maxWidth: '1000px' }}>{text.trim()}</pre>;
        },
      },
    ];
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    let { departments, manufactorTypeList } = this.props.common;
    let qualificationDetailInfo = this.props.memberCenter
      .qualificationDetailInfo;
    let modifyHistoryList = this.props.memberCenter.operationHistoryList;
    let businessCharacter = this.props.memberCenter.businessCharacter;
    let { treeData } = this.state;
    const hasCenpurCert = validHasCenpurCert(
      qualificationDetailInfo.identity || []
    );
    let formItemLayout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 20 },
    };

    let { province, city, district } = this.state;
    return (
      <div>
        <h2 className={commonStyles.row}>企业资质信息详情</h2>
        <div style={{ overflow: 'hidden' }}>
          <div style={{ float: 'left', width: 100 }}>账户信息</div>
          <div style={{ marginLeft: 100 }}>
            <div style={{ marginBottom: 30 }}>
              <label
                style={{
                  padding: '0 10px 0 0',
                  width: '16.6666666%',
                  textAlign: 'right',
                  display: 'inline-block',
                }}
              >
                会员编号:
              </label>
              <span
                style={{
                  width: 250,
                  lineHeight: '32px',
                  display: 'inline-block',
                }}
              >
                {qualificationDetailInfo.user.userNo}
              </span>
            </div>
            <div style={{ marginBottom: 30 }}>
              <label
                style={{
                  padding: '0 10px 0 0',
                  width: '16.6666666%',
                  textAlign: 'right',
                  display: 'inline-block',
                }}
              >
                用户名:
              </label>
              <span
                style={{
                  width: 250,
                  lineHeight: '32px',
                  display: 'inline-block',
                }}
              >
                {qualificationDetailInfo.user.username}
              </span>
            </div>
            <div style={{ marginBottom: 30 }}>
              <label
                style={{
                  padding: '0 10px 0 0',
                  width: '16.6666666%',
                  textAlign: 'right',
                  display: 'inline-block',
                }}
              >
                安全手机:
              </label>
              <span
                style={{
                  width: 250,
                  lineHeight: '32px',
                  display: 'inline-block',
                }}
              >
                {qualificationDetailInfo.user.phone}
              </span>
            </div>
            <Form>
              <FormItem {...formItemLayout} label="责任部门">
                <Select
                  disabled={true}
                  value={qualificationDetailInfo.department || ''}
                  style={{ width: 250 }}
                >
                  {departments &&
                    departments.map((item) => {
                      return (
                        <Option key={item.code || ''} value={item.code}>
                          {item.name}
                        </Option>
                      );
                    })}
                </Select>
              </FormItem>
              <FormItem {...formItemLayout} label="小组">
                <Input
                  disabled
                  value={qualificationDetailInfo.team || ''}
                  maxLength={20}
                  style={{ width: 250 }}
                />
              </FormItem>
              <FormItem {...formItemLayout} label="业务员">
                <Input
                  disabled
                  value={qualificationDetailInfo.salesman || ''}
                  style={{ width: 250 }}
                  placeholder=""
                />
              </FormItem>
            </Form>
          </div>
        </div>
        <div style={{ overflow: 'hidden' }}>
          <div style={{ float: 'left', width: 100 }}>基础信息</div>
          <div style={{ marginLeft: 100 }}>
            <div style={{ marginBottom: 30 }}>
              <label
                style={{
                  padding: '0 10px 0 0',
                  width: '16.6666666%',
                  textAlign: 'right',
                  display: 'inline-block',
                }}
              >
                公司名称:
              </label>
              <span
                style={{
                  width: 250,
                  lineHeight: '32px',
                  display: 'inline-block',
                }}
              >
                {qualificationDetailInfo.cert.baseInfo.companyNameZh}
              </span>
            </div>
            <Form>
              <FormItem {...formItemLayout} label="公司英文名：">
                <span
                  style={{
                    width: 250,
                    lineHeight: '32px',
                    display: 'inline-block',
                  }}
                >
                  {qualificationDetailInfo.cert.baseInfo.companyNameEn}
                </span>
              </FormItem>
              <FormItem {...formItemLayout} label="公司简称：">
                <span
                  style={{
                    width: 250,
                    lineHeight: '32px',
                    display: 'inline-block',
                  }}
                >
                  {qualificationDetailInfo.cert.baseInfo.companyAbbreviation}
                </span>
              </FormItem>
              <FormItem {...formItemLayout} label="公司曾用名：">
                <span
                  style={{
                    width: 250,
                    lineHeight: '32px',
                    display: 'inline-block',
                  }}
                >
                  {qualificationDetailInfo.cert.baseInfo.usedCompanyName}
                </span>
              </FormItem>
              <FormItem
                labelCol={{ span: 16 }}
                wrapperCol={{ span: 8 }}
                style={{ width: '25%', display: 'inline-block' }}
                label="所在地："
              >
                {getFieldDecorator('.cert.baseInfo.provinceCode', {
                  rules: [
                    {
                      required: false,
                      message: '请选择省',
                    },
                  ],
                  initialValue:
                    qualificationDetailInfo.cert.baseInfo.provinceCode,
                })(
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    disabled={true}
                  >
                    {province.map((item) => {
                      return (
                        <Option key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      );
                    })}
                  </Select>
                )}
              </FormItem>
              <FormItem
                labelCol={{ span: 1 }}
                wrapperCol={{ span: 23 }}
                style={{ width: '10%', display: 'inline-block', marginLeft: 5 }}
                label=""
              >
                {getFieldDecorator('.cert.baseInfo.cityCode', {
                  rules: [
                    {
                      required: false,
                      message: '请选择市',
                    },
                  ],
                  initialValue: qualificationDetailInfo.cert.baseInfo.cityCode,
                })(
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    disabled={true}
                  >
                    {city.map((item) => {
                      return (
                        <Option key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      );
                    })}
                  </Select>
                )}
              </FormItem>
              <FormItem
                labelCol={{ span: 1 }}
                wrapperCol={{ span: 23 }}
                style={{ width: '10%', display: 'inline-block' }}
                label=""
              >
                {getFieldDecorator('.cert.baseInfo.districtCode', {
                  rules: [
                    {
                      required: false,
                      message: '请选择区/县',
                    },
                  ],
                  initialValue:
                    qualificationDetailInfo.cert.baseInfo.districtCode,
                })(
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    disabled={true}
                  >
                    {district.map((item) => {
                      return (
                        <Option key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      );
                    })}
                  </Select>
                )}
              </FormItem>
              <FormItem
                labelCol={{ span: 1 }}
                wrapperCol={{ span: 23 }}
                style={{ width: '20%', display: 'inline-block' }}
                label=""
              >
                <span
                  style={{
                    width: 450,
                    lineHeight: '32px',
                    display: 'inline-block',
                  }}
                >
                  {qualificationDetailInfo.cert.baseInfo.address}
                </span>
              </FormItem>
              <FormItem {...formItemLayout} label="注册时间">
                <span
                  style={{
                    width: 450,
                    lineHeight: '32px',
                    display: 'inline-block',
                  }}
                >
                  {qualificationDetailInfo.cert.baseInfo.registerTime
                    ? dayjs(
                        qualificationDetailInfo.cert.baseInfo.registerTime
                      ).format('YYYY-MM-DD HH:mm:ss')
                    : ''}
                </span>
              </FormItem>
              <FormItem {...formItemLayout} label="企业网站">
                <span
                  style={{
                    width: 450,
                    lineHeight: '32px',
                    display: 'inline-block',
                  }}
                >
                  {qualificationDetailInfo.cert.baseInfo.website}
                </span>
              </FormItem>
              <FormItem {...formItemLayout} label="受邀钢厂">
                <span
                  style={{
                    width: 450,
                    lineHeight: '32px',
                    display: 'inline-block',
                  }}
                >
                  {formatInviteSteelText(qualificationDetailInfo.cert.baseInfo.invitedSteel)}
                </span>
              </FormItem>
              <FormItem {...formItemLayout} label="认证目的">
                <div style={{ lineHeight: '32px', width: 450, marginTop: 4 }}>
                  {qualificationDetailInfo.cert.baseInfo.authPurpose}
                </div>
              </FormItem>
            </Form>
          </div>
        </div>
        <div style={{ overflow: 'hidden' }}>
          <div style={{ float: 'left', width: 100 }}>联系方式</div>
          <div style={{ marginLeft: 100 }}>
            <Form>
              <FormItem {...formItemLayout} label="联系人：">
                <span
                  style={{
                    width: 250,
                    lineHeight: '32px',
                    display: 'inline-block',
                  }}
                >
                  {qualificationDetailInfo.cert.contactInfo.contactPerson}
                </span>
              </FormItem>
              <FormItem {...formItemLayout} label="联系电话：">
                <span
                  style={{
                    width: 250,
                    lineHeight: '32px',
                    display: 'inline-block',
                  }}
                >
                  {qualificationDetailInfo.cert.contactInfo.phone}
                </span>
              </FormItem>
              <FormItem {...formItemLayout} label="企业电话：">
                <span
                  style={{
                    width: 250,
                    lineHeight: '32px',
                    display: 'inline-block',
                  }}
                >
                  {qualificationDetailInfo.cert.baseInfo.enterprisePhone}
                </span>
              </FormItem>
              <FormItem {...formItemLayout} label="企业传真：">
                <span
                  style={{
                    width: 250,
                    lineHeight: '32px',
                    display: 'inline-block',
                  }}
                >
                  {qualificationDetailInfo.cert.baseInfo.corporateFax}
                </span>
              </FormItem>
              <FormItem {...formItemLayout} label="邮编：">
                <span
                  style={{
                    width: 250,
                    lineHeight: '32px',
                    display: 'inline-block',
                  }}
                >
                  {qualificationDetailInfo.cert.baseInfo.zipCode}
                </span>
              </FormItem>
              <FormItem {...formItemLayout} label="QQ：">
                <span
                  style={{
                    width: 250,
                    lineHeight: '32px',
                    display: 'inline-block',
                  }}
                >
                  {qualificationDetailInfo.cert.contactInfo.qq}
                </span>
              </FormItem>
            </Form>
          </div>
        </div>
        <div style={{ overflow: 'hidden' }}>
          <div style={{ float: 'left', width: 100 }}>证件附件</div>
          <div style={{ marginLeft: 100 }}>
            <div
              style={{
                marginBottom: 30,
                display: 'inline-block',
                width: '33.3333%',
              }}
            >
              <label
                style={{
                  padding: '0 10px 0 0',
                  width: '45%',
                  textAlign: 'right',
                  display: 'inline-block',
                }}
              >
                营业执照:
              </label>
              {qualificationDetailInfo.cert?.attachment?.businessLicenceUrl?.url ? (
                <MagnifyEnableImg
                  imgUrl={
                    qualificationDetailInfo.cert.attachment.businessLicenceUrl?.url
                  }
                />
              ) : (
                <img
                  style={{ width: 80, height: 80 }}
                  src={unuploadImg}
                  alt=""
                />
              )}
            </div>
            <div
              style={{
                marginBottom: 30,
                display: 'inline-block',
                width: '33.3333%',
              }}
            >
              <label
                style={{
                  padding: '0 10px 0 0',
                  width: '45%',
                  textAlign: 'right',
                  display: 'inline-block',
                }}
              >
                组织机构代码:
              </label>
              {qualificationDetailInfo.cert?.attachment?.organizationCodeUrl
                .url ? (
                <MagnifyEnableImg
                  imgUrl={
                    qualificationDetailInfo.cert?.attachment?.organizationCodeUrl
                      .url
                  }
                />
              ) : (
                <img
                  style={{ width: 80, height: 80 }}
                  src={unuploadImg}
                  alt=""
                />
              )}
            </div>
            <div
              style={{
                marginBottom: 30,
                display: 'inline-block',
                width: '33.3333%',
              }}
            >
              <label
                style={{
                  padding: '0 10px 0 0',
                  width: '45%',
                  textAlign: 'right',
                  display: 'inline-block',
                }}
              >
                税务登记证:
              </label>
              {qualificationDetailInfo.cert?.attachment?.taxRegistryUrl?.url ? (
                <MagnifyEnableImg
                  imgUrl={
                    qualificationDetailInfo.cert?.attachment?.taxRegistryUrl?.url
                  }
                />
              ) : (
                <img
                  style={{ width: 80, height: 80 }}
                  src={unuploadImg}
                  alt=""
                />
              )}
            </div>
            {hasCenpurCert ? (
              <div style={{ marginBottom: 30 }}>
                <label
                  style={{
                    padding: '0 10px 0 0',
                    width: '16.6666666%',
                    textAlign: 'right',
                    display: 'inline-block',
                  }}
                >
                  开户许可证:
                </label>
                {qualificationDetailInfo.cert?.attachment?.accountLicenseUrl?.url ? (
                  <MagnifyEnableImg
                    imgUrl={
                      qualificationDetailInfo.cert?.attachment?.accountLicenseUrl?.url
                    }
                  />
                ) : (
                  <img
                    style={{ width: 80, height: 80 }}
                    src={unuploadImg}
                    alt=""
                  />
                )}
              </div>
            ) : null}
            <div style={{ marginBottom: 30 }}>
              <label
                style={{
                  padding: '0 10px 0 0',
                  width: '16.6666666%',
                  textAlign: 'right',
                  display: 'inline-block',
                }}
              >
                委托书:
              </label>
              <RadioGroup
                disabled={true}
                value={qualificationDetailInfo.cert?.proxyType}
              >
                <Radio value={'1'}>企业授权委托书</Radio>
                <Radio value={'2'}>业务担保授权书</Radio>
                <Radio value={'3'}>无委托书，自愿不进行交易</Radio>
              </RadioGroup>
            </div>
            {qualificationDetailInfo.cert?.proxyType !== '3' && (
              <div style={{ marginBottom: 30 }}>
                <label
                  style={{
                    width: '16.6666666%',
                    textAlign: 'right',
                    display: 'inline-block',
                  }}
                >
                  委托书附件:
                </label>
                {qualificationDetailInfo.cert?.proxy ? (
                  qualificationDetailInfo.cert.proxy.map((item) => {
                    return item.url ? (
                      <MagnifyEnableImg
                        style={{ marginLeft: 15 }}
                        imgUrl={item.url}
                      />
                    ) : (
                      ''
                    );
                  })
                ) : (
                  <img
                    style={{ width: 80, height: 80 }}
                    src={unuploadImg}
                    alt=""
                  />
                )}
              </div>
            )}
          </div>
        </div>
        
        <div style={{ overflow: 'hidden' }}>
          { checkIsSupplier(qualificationDetailInfo.identity) && (
            <div style={{ float: 'left', width: 100 }}>供应品类</div>
          )}
          {checkIsSupplier(qualificationDetailInfo.identity) && (
            <div style={{ marginLeft: 275 }}>
              <Tree
                checkable
                disabled
                checkedKeys={qualificationDetailInfo.additionalCert?.supplyCategory??[]}
                treeData={treeData}
              />
            </div>
          )}
          {
            checkIsSupplier(qualificationDetailInfo.identity) && (
              <div style={{ marginLeft: 100, padding: '15px 0' }}>
              <FormItem {...formItemLayout} label="厂家类型：">
                  <Select value={qualificationDetailInfo?.additionalCert?.manufactorType} disabled>
                    {
                      manufactorTypeList.map(el => {
                        return (
                          <Option key={el.code} value={el.code}>{el.name}</Option>
                        );
                      })
                    }
                  </Select>
                </FormItem>
              </div>
            )
          }
          {checkIsPurchase(qualificationDetailInfo?.identity) && (
            <div style={{ float: 'left', width: 100 }}>采购品类</div>
          )}
          { checkIsPurchase(qualificationDetailInfo?.identity) && (
            <div style={{ marginLeft: 275 }}>
              <Tree
                checkable
                disabled
                checkedKeys={qualificationDetailInfo.additionalCert?.purchaserCategory??[]}
                treeData={treeData}
              />
            </div>
          )}
        </div>
        <div style={{ overflow: 'hidden' }}>
          <div style={{ float: 'left', width: 100 }}>其他信息</div>
          <div style={{ marginLeft: 100 }}>
            <Form>
              <FormItem {...formItemLayout} label="外贸资质：">
                <RadioGroup disabled value={qualificationDetailInfo.additionalCert?.hasForeignTradeCert}>
                  <Radio value={true}>具备</Radio>
                  <Radio value={false}>不具备</Radio>
                </RadioGroup>
              </FormItem>
              {qualificationDetailInfo.additionalCert?.hasForeignTradeCert ? (
                <React.Fragment>
                  <div style={{ marginBottom: 30 }}>
                    <label
                      style={{
                        width: '16.6666666%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      对外贸易经营者备案登记表附件:
                    </label>
                    {qualificationDetailInfo.additionalCert?.foreignTradeRegistrationUrl?.url ? (
                      <MagnifyEnableImg
                        style={{ marginLeft: 15 }}
                        imgUrl={
                          qualificationDetailInfo.additionalCert?.foreignTradeRegistrationUrl?.url
                        }
                      />
                    ) : (
                      <img
                        style={{ width: 80, height: 80, marginLeft: 15 }}
                        src={unuploadImg}
                        alt=""
                      />
                    )}
                  </div>
                  <div style={{ marginBottom: 30 }}>
                    <label
                      style={{
                        width: '16.6666666%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      进出口企业代码号:
                    </label>
                    <span
                      style={{
                        width: 250,
                        lineHeight: '32px',
                        display: 'inline-block',
                      }}
                    >
                      {qualificationDetailInfo.additionalCert?.foreignTradeEnterpriseCode}
                    </span>
                  </div>
                </React.Fragment>
              ) : null}
            </Form>
          </div>
        </div>
        <div style={{ overflow: 'hidden' }}>
          <div style={{ float: 'left', width: 100 }}>审核补录信息</div>
          <div style={{ marginLeft: 100 }}>
            <Form>
              <FormItem {...formItemLayout} label="经营性质：">
                {getFieldDecorator('auditAdditionalCert.proprietaryDealer', {
                  rules: [
                    {
                      required: false,
                      message: '请选择经营性质',
                    },
                  ],
                  initialValue: qualificationDetailInfo.auditAdditionalCert?.proprietaryDealer,
                })(
                  <Select style={{ width: 250 }} disabled={true}>
                    {businessCharacter.map((item) => {
                      return <Option value={item.code}>{item.name}</Option>;
                    })}
                  </Select>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="公司性质：">
                {getFieldDecorator('auditAdditionalCert.companyCharacter', {
                  rules: [
                    {
                      required: false,
                      message: '请输入公司性质',
                    },
                  ],
                  initialValue: qualificationDetailInfo.auditAdditionalCert?.companyCharacter,
                })(
                  <span
                    style={{
                      width: 250,
                      lineHeight: '32px',
                      display: 'inline-block',
                    }}
                  >
                    {qualificationDetailInfo.auditAdditionalCert?.companyCharacter}
                  </span>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="公司经营范围：">
                {getFieldDecorator('auditAdditionalCert.companyBusinessScope', {
                  rules: [
                    {
                      required: false,
                      message: '请输入公司经营范围',
                    },
                  ],
                  initialValue:
                    qualificationDetailInfo.auditAdditionalCert &&
                    qualificationDetailInfo.auditAdditionalCert
                      .companyBusinessScope,
                })(
                  <TextArea
                    rows={4}
                    disabled={true}
                    style={{ width: 250 }}
                    placeholder=""
                  />
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="法人姓名：">
                <span
                  style={{
                    width: 250,
                    lineHeight: '32px',
                    display: 'inline-block',
                  }}
                >
                  {qualificationDetailInfo.auditAdditionalCert?.legalPersonName}
                </span>
              </FormItem>
              <div style={{ marginBottom: 30 }}>
                <label
                  style={{
                    width: '16.6666666%',
                    textAlign: 'right',
                    display: 'inline-block',
                  }}
                >
                  法人身份证附件:
                </label>
                {qualificationDetailInfo.auditAdditionalCert?.legalPersonIdentityCardFrontUrl?.url ? (
                  <MagnifyEnableImg
                    imgUrl={
                      qualificationDetailInfo.auditAdditionalCert?.legalPersonIdentityCardFrontUrl?.url
                    }
                  />
                ) : (
                  <img
                    style={{ width: 80, height: 80 }}
                    src={unuploadImg}
                    alt=""
                  />
                )}
                {qualificationDetailInfo.auditAdditionalCert?.legalPersonIdentityCardBackUrl?.url ? (
                  <MagnifyEnableImg
                    imgUrl={
                      qualificationDetailInfo.auditAdditionalCert?.legalPersonIdentityCardBackUrl?.url
                    }
                  />
                ) : (
                  <img
                    style={{ width: 80, height: 80, marginLeft: 10 }}
                    src={unuploadImg}
                    alt=""
                  />
                )}
              </div>
              <div style={{ marginBottom: 30 }}>
                <label
                  style={{
                    width: '16.6666666%',
                    textAlign: 'right',
                    display: 'inline-block',
                  }}
                >
                  营业执照期限:
                </label>
                <span>
                  {qualificationDetailInfo.auditAdditionalCert?.businessLicenseExpirationTime &&
                    dayjs(
                      qualificationDetailInfo.auditAdditionalCert?.businessLicenseExpirationTime
                    ).format('YYYY-MM-DD')}
                </span>
              </div>
              <FormItem {...formItemLayout} label="社会统一信用代码：">
                <span
                  style={{
                    width: 250,
                    lineHeight: '32px',
                    display: 'inline-block',
                  }}
                >
                  {qualificationDetailInfo.auditAdditionalCert?.unifiedSocialCreditCode}
                </span>
              </FormItem>
              <FormItem {...formItemLayout} label="委托人姓名：">
                <span
                  style={{
                    width: 250,
                    lineHeight: '32px',
                    display: 'inline-block',
                  }}
                >
                  {qualificationDetailInfo.auditAdditionalCert?.agentName}
                </span>
              </FormItem>
              {hasCenpurCert ? (
                <React.Fragment>
                  <FormItem {...formItemLayout} label="开户许可证核准号：">
                    <span
                      style={{
                        width: 250,
                        lineHeight: '32px',
                        display: 'inline-block',
                      }}
                    >
                      {qualificationDetailInfo.auditAdditionalCert?.accountLicenseNo}
                    </span>
                  </FormItem>
                  <FormItem {...formItemLayout} label="开户行：">
                    <span
                      style={{
                        width: 250,
                        lineHeight: '32px',
                        display: 'inline-block',
                      }}
                    >
                      {qualificationDetailInfo.auditAdditionalCert?.openBank}
                    </span>
                  </FormItem>
                  <FormItem {...formItemLayout} label="户名：">
                    <span
                      style={{
                        width: 250,
                        lineHeight: '32px',
                        display: 'inline-block',
                      }}
                    >
                      {qualificationDetailInfo.auditAdditionalCert?.accountName}
                    </span>
                  </FormItem>
                  <FormItem {...formItemLayout} label="账号：">
                    <span
                      style={{
                        width: 250,
                        lineHeight: '32px',
                        display: 'inline-block',
                      }}
                    >
                      {qualificationDetailInfo.auditAdditionalCert?.accountNo}
                    </span>
                  </FormItem>
                </React.Fragment>
              ) : null}
              <FormItem {...formItemLayout} label="保证金：">
                <span
                  style={{
                    width: 250,
                    lineHeight: '32px',
                    display: 'inline-block',
                  }}
                >
                  {qualificationDetailInfo.auditAdditionalCert?.bond}
                </span>
              </FormItem>
              <FormItem {...formItemLayout} label="委托人电话：">
                <span
                  style={{
                    width: 250,
                    lineHeight: '32px',
                    display: 'inline-block',
                  }}
                >
                  {qualificationDetailInfo.auditAdditionalCert?.agentPhone}
                </span>
              </FormItem>
              <div style={{ marginBottom: 30, overflow: 'hidden' }}>
                <label
                  style={{
                    width: '16.66666666%',
                    textAlign: 'right',
                    display: 'inline-block',
                    float: 'left',
                  }}
                >
                  委托人身份证附件：
                </label>
                {qualificationDetailInfo.auditAdditionalCert?.agentIdentityCardFrontUrl?.url ? (
                  <MagnifyEnableImg
                    imgUrl={
                      qualificationDetailInfo.auditAdditionalCert?.agentIdentityCardFrontUrl?.url
                    }
                  />
                ) : (
                  <img
                    style={{ width: 80, height: 80 }}
                    src={unuploadImg}
                    alt=""
                  />
                )}
                {qualificationDetailInfo.auditAdditionalCert?.agentIdentityCardBackUrl?.url ? (
                  <MagnifyEnableImg
                    imgUrl={
                      qualificationDetailInfo.auditAdditionalCert?.agentIdentityCardBackUrl?.url
                    }
                  />
                ) : (
                  <img
                    style={{ width: 80, height: 80, marginLeft: 10 }}
                    src={unuploadImg}
                    alt=""
                  />
                )}
              </div>
            </Form>
          </div>
        </div>
        <div style={{ padding: '10px 0 50px 150px' }}>
          <Button onClick={this.back} style={{ marginLeft: 10 }}>
            返回
          </Button>
        </div>

        <div style={{ padding: '0 0 30px 0' }}>
          {modifyHistoryList && (
            <Table
              className={commonStyles.row}
              rowKey="uid"
              columns={this.getColumns()}
              pagination={{
                current: modifyHistoryList.pagerInfo.page,
                total: modifyHistoryList.pagerInfo.total,
              }}
              onChange={this.handleTableChange}
              dataSource={modifyHistoryList.result}
            />
          )}
        </div>

        <Modal
          title="查看大图"
          width={this.state.imgWidth + 48}
          visible={this.state.showBigImg}
          onOk={this.closeBigImgModal}
          onCancel={this.closeBigImgModal}
        >
          <img src={this.state.bigImgUrl} alt="" />
        </Modal>
      </div>
    );
  }
}

export default ViewQualificationDetail;