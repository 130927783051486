import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import reaction from 'reaction/index';
import { RoutePath } from 'utils/constants';
import { Input, Select, Button, message, Form, DatePicker, } from 'antd';
import TableWrapper from 'components/TableWrapper/index';
import FormItem from 'components/FormItem';
// import input from 'components/AutoComplete/index';
import Immutable from 'immutable';
import http from 'utils/http';

import dayjs from 'dayjs';
import { transformParamsToUrl, renderListControls } from 'utils';

const location = window.location;
const { RangePicker } = DatePicker
@connect(({ accountList, me }) => ({ accountList, me }))
@withRouter
class FundsMemberAccountList extends React.Component {
  inputValueChange = (type, e) => {
    this.setState({
      [type]: e && e.target ? e.target.value : e
    }, () => {
      // ...
    });
  };

  onQueryChange = (name, value) => {
    let { query } = this.state;
    query = query.set(name, value);
    this.setState({ query });
  };

  onSettleBankChange = (value) => {
    let { query } = this.state;
    query = query.set('bankName', value);
    this.setState({ query });
    // ...
  };

  onPageChange = (page, pageSize) => {
    let { query } = this.state;
    query = query.set('page', page);
    query = query.set('length', pageSize);
    this.setState({ query }, () => {
      this.getAccountList();
    });
  };

  onPageSizeChange = (current, size) => {
    let { query } = this.state;
    query = query.set('page', 1);
    query = query.set('length', size);
    this.setState({ query }, () => {
      this.getAccountList();
    });
  };

  onTableChange = (pagination, filters, sorter) => {
    let { query } = this.state;
    query = query.set('page', pagination.current);
    query = query.set('length', pagination.pageSize);
    query = query.set('sortBy', sorter.field);
    sorter.order === 'ascend' ? query = query.set('asc', true) : query = query.set('asc', false);
    this.setState({ query }, () => {
      this.getAccountList();
    });
  };

  getAccountList = () => {
    let { query } = this.state;
    const params = {
      ...query.toJS()
    }
    const [createTimeStart, createTimeEnd] = params.createTime || []

    params.createTimeStart = Date.parse(createTimeStart) || ''
    params.createTimeEnd = Date.parse(createTimeEnd) || ''
    delete params.createTime

    reaction.accountList.getFundsAccountList(params);
  };

  updateAccountBalance = ({ accountNo, userId }) => {
    http.request('v1.funds.updateBalanceByAccountNo', { accountNo, userId })
      .then(res => {
        if (res && res.code === 0) {
          message.success('对账成功');
          this.getAccountList();
        }
      });
  }

  checkReceiptList = (record) => {
    this.props.history.push({
      pathname: '/fundsManagement/receiptList',
      search: transformParamsToUrl({
        userLoginName: record.userLoginName,
        accountNo: record.accountNo,
      })
    });
  };

  clear = () => {
    let { query } = this.state;
    query = query.set('phoneNo', '');
    query = query.set('accountName', '');
    query = query.set('accountNo', '');
    query = query.set('bankName', '');
    query = query.set('createTime', '');
    // query = query.set('minAvailableBalance', '');
    // query = query.set('maxAvailableBalance', '');
    // query = query.set('minFreezeMoney', '');
    // query = query.set('maxFreezeMoney', '');

    this.setState({ query });
  };

  exportAll = () => {
    reaction.accountList.exportAll();
  };

  getTotalAccountInfo = () => {
    reaction.accountList.getTotalAccountInfo();
  };

  submit = () => {
    let { query } = this.state;
    query = query.set('page', 1);
    this.setState({ query }, () => {
      this.getAccountList();
    });
  };

  showDetail = (record) => {
    // historyPush(`/${RoutePath.FUNDS}/${RoutePath.FUNDS_MEMBER_MANAGEMENT}/${RoutePath.FUNDS_MEMBER_ACCOUNT_DETAIL}`, {accountNo: record.accountNo});
    window.open(`//${location.host}/#/${RoutePath.FUNDS}/${RoutePath.FUNDS_MEMBER_MANAGEMENT}/${RoutePath.FUNDS_MEMBER_ACCOUNT_DETAIL}?accountNo=${record.accountNo}`);
  };

  onPressEnter = () => {
    this.submit();
  };

  state = {
    query: Immutable.Map({
      username: '', // 用户名
      accountName: '', // 公司名
      accountNo: '', // 银行账号
      bankName: '', // 结算银行
      createTime: null, // 开户时间
      minAvailableBalance: '', // 可用余额
      maxAvailableBalance: '', // 可用余额
      minFreezeMoney: '', // 冻结金额
      maxFreezeMoney: '', // 冻结金额
      sortBy: '', // 排序
      asc: false, // 排序
      page: 1,
      length: 10
    })
  };

  handleAvailableBalanceChange = (v) => {
    let { query } = this.state;
    query = query.set('minAvailableBalance', v[0]);
    query = query.set('maxAvailableBalance', v[1]);
    this.setState({ query });
  };

  handleFreezeMoneyChange = (v) => {
    let { query } = this.state;
    query = query.set('minFreezeMoney', v[0]);
    query = query.set('maxFreezeMoney', v[1]);
    this.setState({ query });
  };

  componentDidMount() {
    this.submit();
    this.getTotalAccountInfo();
  }

  render() {
    let _this = this;
    let { fundsAccountList, totalAccountInfo } = this.props.accountList;
    let { query } = this.state;
    const columns = [{
      title: '公司名',
      dataIndex: 'accountName',
      key: 'accountName',
    }, {
      title: '手机号',
      dataIndex: 'username',
      key: 'username',
    }, {
      title: '银行账号',
      dataIndex: 'accountNo',
      key: 'accountNo',
    }, {
      title: '结算银行',
      dataIndex: 'bankName',
      key: 'bankName',
    }, {
      title: '开户时间',
      dataIndex: 'createTime',
      key: 'createTime',
      sorter: true,
      render: (text) => {
        if (!text) {
          return '';
        }
        return dayjs(text - 0).format('YYYY-MM-DD HH:mm:ss');
      }
    }, {
      title: '结余总额(元)',
      dataIndex: 'balance',
      key: 'balance',
      sorter: true,
    }, {
      title: '可用余额(元)',
      dataIndex: 'availableBalance',
      key: 'availableBalance',
      sorter: true,
    }, {
      title: '冻结金额(元)',
      dataIndex: 'freezeMoney',
      key: 'freezeMoney',
      sorter: true,
    }, {
      title: '操作',
      dataIndex: 'operate',
      key: 'operate',
      render: function (text, record, index) {
        return (<div>
          <span style={{ display: 'inline-block', padding: '5px' }}>
            <Button type="primary" onClick={() => {
              _this.updateAccountBalance(record);
            }}>对账</Button>
          </span>
          <span style={{ display: 'inline-block', padding: '5px' }}>
            <Button type="primary" onClick={() => {
              _this.showDetail(record);
            }}>详情</Button>
          </span>
        </div>);
      }
    }];

    console.log('query.toJS()', query.toJS());

    return (
      <div>
        <Form layout="inline">
          {
            renderListControls([
              <FormItem label="公司名">
                <Input onPressEnter={this.onPressEnter} value={query.toJS().accountName} onChange={(e) => { this.onQueryChange('accountName', e.target.value); }} placeholder="公司名" />
              </FormItem>,
              <FormItem label="手机号">
                <Input onPressEnter={this.onPressEnter} value={query.toJS().phoneNo} onChange={(e) => { this.onQueryChange('phoneNo', e.target.value); }} placeholder="手机号" />
              </FormItem>,
              <FormItem label="银行账号">
                <Input onPressEnter={this.onPressEnter} value={query.toJS().accountNo} onChange={(e) => { this.onQueryChange('accountNo', e.target.value); }} placeholder="银行账号" />
              </FormItem>,
              <FormItem label="结算银行">
                <Select
                  showSearch
                  placeholder="选择结算银行"
                  optionFilterProp="children"
                  value={query.toJS().bankName}
                  onChange={this.onSettleBankChange}
                >
                  <Select.Option value="">全部</Select.Option>
                  <Select.Option value="CITIC">中信银行</Select.Option>
                  <Select.Option value="E_BUTLER">中信E管家</Select.Option>
                </Select>
              </FormItem>,
              <FormItem label="开户时间">
                <RangePicker value={query.toJS().createTime} onChange={(e) => { this.onQueryChange('createTime', e); }} />
              </FormItem>,
              {
                full: true,
                colProps: {
                  xxl: 24,
                  xl: 16,
                  md: 24,
                  lg: 24,
                },
                children: (
                  <FormItem align="right">
                    <Button icon="search" type="primary" onClick={this.submit}>查询</Button>
                    <Button icon="redo" style={{ margin: '0 0 0 5px' }} type="primary" onClick={this.clear}>清空</Button>
                  </FormItem>
                )
              }
            ])
          }
        </Form>

        <div style={{ padding: '20px', margin: '10px 0 0 5px', background: '#eee', position: 'relative' }}>
          <label style={{ fontWeight: 'bold' }}>总计</label>
          <span style={{ marginLeft: '15px' }}>账户数:  </span>
          <span>{totalAccountInfo.totalAccount}个</span>
          <span style={{ marginLeft: '15px' }}>余额总计:  </span>
          <span>{totalAccountInfo.totalBalance}元</span>
          <span style={{ marginLeft: '15px' }}>可用总额</span>
          <span>{totalAccountInfo.totalAvailableBalance}元</span>
          <span style={{ marginLeft: '15px' }}>冻结:  </span>
          <span>{totalAccountInfo.totalFreezeMoney}元</span>
          <Button icon="export" style={{ position: 'absolute', right: '10px', top: '17px' }} onClick={this.exportAll}>导出所有</Button>
        </div>

        <div style={{ padding: '20px 20px 0 5px' }}>
          <TableWrapper
            columns={columns}
            data={fundsAccountList}
            onTableChange={this.onTableChange}
            onChange={this.onPageChange}
            onShowSizeChange={this.onPageSizeChange}
            pageSize={query.toJS().length}
            rowKey={r => `${r.accountNo}_${r.userId}`}
          />
        </div>
      </div>
    );
  }
}

export default FundsMemberAccountList;