import React from 'react';
import { Route } from 'react-router-dom';
// import { getComponent } from 'reactlite';

import Blacklist from 'pages/Blacklist';
import BlacklistAdd from 'pages/Blacklist/Add';
import BlacklistModify from 'pages/Blacklist/Modify';
import FrontUser from 'pages/FrontUser';
import CreateMember from 'pages/FrontUser/createMember';
// import CreateRegisterAccount from 'pages/FrontUser/createMember/createRegisterAccount';
// import CreateAuthenticateAccount from 'pages/FrontUser/createMember/createAuthenticateAccount';

import FrontUserList from 'pages/FrontUser/list';
import FrontUserDetail from 'pages/FrontUser/Detail';
import FundsManagement from 'pages/FundsManagement';
import AddRuleVar from 'pages/FundsManagement/AddRuleVar';
import FreezeAndUnfreeze from 'pages/FundsManagement/FreezeAndUnfreeze';
import ManagementOfMemberFunds from 'pages/FundsManagement/ManagementOfMemberFunds';
import Detail from 'pages/FundsManagement/ManagementOfMemberFunds/Detail';
import FundsMemberAccountList from 'pages/FundsManagement/ManagementOfMemberFunds/FundsMemberAccountList';
// import ExpenditureCard from 'pages/FundsManagement/ManagementOfMemberFunds/ExpenditureCard';
import NewFundsAccountList from 'pages/FundsManagement/ManagementOfMemberFunds/NewFundsAccountList';
import ModifyRule from 'pages/FundsManagement/ModifyRule';
import AccountList from 'pages/FundsManagement/ReceiptOfCITICBank/AccountList/index';
import ReceiptListPrint from 'pages/FundsManagement/ReceiptOfCITICBank/index';
import PrintProgress from 'pages/FundsManagement/ReceiptOfCITICBank/PrintProgress';
import ReceiptList from 'pages/FundsManagement/ReceiptOfCITICBank/ReceiptList';
import ReceiptOfBank from 'pages/FundsManagement/ReceiptOfCITICBank/ReceiptOfBank';
import RuleConfig from 'pages/FundsManagement/RuleConfig';
import ExpenditureRecords from 'pages/FundsManagement/TransactionRecords/ExpenditureRecords';
import IncomeRecords from 'pages/FundsManagement/TransactionRecords/IncomeRecords';
import Index from 'pages/Index';
import Login from 'pages/Login';

import MemberCenter from 'pages/MemberCenter';
import ModifyCompany from 'pages/MemberCenter/ModifyCompany';
import CheckCompanyIdentity from 'pages/MemberCenter/CheckCompanyIdentity';
import CheckCompanyIdentityHistory from 'pages/MemberCenter/CheckCompanyIdentityHistory';
import CheckIdentity from 'pages/MemberCenter/CheckIdentityList';
import CheckPersonalIdentity from 'pages/MemberCenter/CheckPersonalIdentity';
import CheckPersonalIdentityHistory from 'pages/MemberCenter/CheckPersonalIdentityHistory';
// import SupplementQualification from 'pages/MemberCenter/SupplementQualification';
import CheckPersonalQualification from 'pages/MemberCenter/CheckPersonalQualificationDetail';
import CheckQualificationDetail from 'pages/MemberCenter/CheckQualificationDetail';
import CheckQualification from 'pages/MemberCenter/CheckQualificationList';
import ComplementCompanyQualification from 'pages/MemberCenter/ComplementCompanyQualification';
import ComplementPersonalQualification from 'pages/MemberCenter/ComplementPersonalQualification';
import IdentityList from 'pages/MemberCenter/IdentityList';
import ModifyPersonalQualification from 'pages/MemberCenter/ModifyPersonalQualification';
import ModifyQualification from 'pages/MemberCenter/ModifyQualification';
import QualificationList from 'pages/MemberCenter/QualificationList';
import CompanyQualificationPeriodManagement from 'pages/MemberCenter/CompanyQualificationPeriodManagement';
import EmailSet from 'pages/MemberCenter/CompanyQualificationPeriodManagement/EmailSet';
import ViewCompanyAuditDetail from 'pages/MemberCenter/ViewCompanyAuditDetail';
import ViewPersonalAuditDetail from 'pages/MemberCenter/ViewPersonalAuditDetail';
// import SupplementPersonalQualification from 'pages/MemberCenter/SupplementPersonalQualification';
import ViewIdentity from 'pages/MemberCenter/ViewIdentity';
import EditIdentity from 'pages/MemberCenter/EditIdentity';
import ViewPersonalQualificationDetail from 'pages/MemberCenter/ViewPersonalQualificationDetail';
import ViewQualificationDetail from 'pages/MemberCenter/ViewQualificationDetail';

import VipCenter from 'pages/VipCenter';
import ZcVipList from 'pages/VipCenter/ZcVipList';
import ZcVipAdd from 'pages/VipCenter/ZcVipList/Add';
import ZcVipDetail from 'pages/VipCenter/ZcVipList/Detail';

import OperationCenter from 'pages/OperationCenter';
import SmsManagement from 'pages/OperationCenter/SmsManagement';
import AddSms from 'pages/OperationCenter/SmsManagement/AddSms';
import ModifySms from 'pages/OperationCenter/SmsManagement/ModifySms';
import SmsHistory from 'pages/OperationCenter/SmsManagement/SmsHistory';
import ViewHistorySmsDetail from 'pages/OperationCenter/SmsManagement/ViewHistorySmsDetail';
import ViewSmsModel from 'pages/OperationCenter/SmsManagement/ViewSmsModel';
import UploadOfflineData from 'pages/OperationCenter/UploadOfflineData';
import SmsBlackListManagement from 'pages/OperationCenter/SmsBlackListManagement';
import SmsBlackListAccountManagement from 'pages/OperationCenter/SmsBlackListManagement/AccountList';
import Platform from 'pages/Platform';
import PlatformAdd from 'pages/Platform/Add';
import PlatformModify from 'pages/Platform/Modify';
import System from 'pages/System';
import SystemAuthManagement from 'pages/System/AuthManagement';
import SystemAddAuth from 'pages/System/AuthManagement/Add';
import SystemModifyAuth from 'pages/System/AuthManagement/Modify';
import AddDataDict from 'pages/System/DataDict/Add';
import EditDataDict from 'pages/System/DataDict/Edit';
import DataDict from 'pages/System/DataDict/List/index';
import ViewDataDict from 'pages/System/DataDict/View';
import SystemLogBackend from 'pages/System/Log/Backend';
// import SystemLog from 'pages/System/Log';
import SystemLogFrontend from 'pages/System/Log/Frontend';
import SystemLogFrontendDetail from 'pages/System/Log/Frontend/detail';
import SystemRole from 'pages/System/Role';
import SystemRoleAdd from 'pages/System/Role/Add';
import SystemRoleModify from 'pages/System/Role/Modify';
import SystemUser from 'pages/System/User';
import SystemUserAdd from 'pages/System/User/Add';
import SystemUserModify from 'pages/System/User/Modify';
import FrontMenuManagement from 'pages/System/BizMenuManagement';

import Screen from 'pages/Screen';
import { AuthCodes } from 'utils/constants';

import GroupSms from 'pages/OperationCenter/GroupSms/list';
import AddGroupSms from 'pages/OperationCenter/GroupSms/add';
import GroupSmsDetail from 'pages/OperationCenter/GroupSms/detail';

// todo 部分路由的auth_code尚未完善，需要后端定义完善后在contants文件内定义并在该文件内补充完善auth_code
// 做页面的访问权限验证时如果对应的路由有定义auth_code，则验证。没有定义auth_code则默认允许访问
const routesConfig = {
  breadcrumbName: '首页',
  path: '/',
  exact: true,
  indexRoute: {
    component: Index,
  },
  childRoutes: [
    {
      path: 'frontUser',
      auth_code: AuthCodes.MEMBER,
      breadcrumbName: '账户管理',
      indexRoute: {
        component: FrontUser, // component: FrontUser// getComponent: getComponent(FrontUser)
      },
      childRoutes: [
        {
          breadcrumbName: '列表',
          path: 'frontUserList',
          auth_code: AuthCodes.MEMBER_FRONT_USER_LIST,
          indexRoute: {
            component: FrontUserList,
          },
          childRoutes: [
            {
              breadcrumbName: '详情',
              path: 'detail',
              auth_code: AuthCodes.MEMBER_FRONT_USER_MANAGEMENT,
              component: FrontUserDetail, // component: FrontUserDetail// getComponent: getComponent(FrontUserDetail)
            },
          ],
        },
        {
          breadcrumbName: '添加注册会员',
          path: 'registerAccount',
          auth_code: AuthCodes.ADD_MEMBER_REGISTER,
          component: CreateMember,
        },
      ],
    },
    {
      path: 'permission',
      breadcrumbName: '系统访问权限',
      indexRoute: {
        component: Blacklist, // component: Blacklist// getComponent: getComponent(Blacklist)
      },
      childRoutes: [
        {
          breadcrumbName: '黑名单新增',
          path: 'add',
          component: BlacklistAdd, // component: BlacklistAdd// getComponent: getComponent(BlacklistAdd)
        },
        {
          breadcrumbName: '黑名单修改',
          path: 'modify',
          component: BlacklistModify, // component: BlacklistModify// getComponent: getComponent(BlacklistModify)
        },
      ],
    },
    {
      title: '系统管理',
      path: 'system',
      auth_code: AuthCodes.SYSTEM,
      indexRoute: {
        component: System, // component: System// getComponent: getComponent(System)
      },
      childRoutes: [
        {
          breadcrumbName: '平台管理',
          path: 'platform',
          auth_code: AuthCodes.PLATFORM_SYSTEM_LIST,
          indexRoute: {
            component: Platform, // component: Platform// getComponent: getComponent(Platform)
          },
          childRoutes: [
            {
              breadcrumbName: '业务系统新增',
              path: 'add',
              auth_code: AuthCodes.PLATFORM_SYSTEM_ADD,
              component: PlatformAdd, // component: PlatformAdd// getComponent: getComponent(PlatformAdd)
            },
            {
              breadcrumbName: '业务系统修改',
              path: 'modify',
              auth_code: AuthCodes.PLATFORM_SYSTEM_UPDATE,
              component: PlatformModify, // component: PlatformModify// getComponent: getComponent(PlatformModify)
            },
          ],
        },
        {
          breadcrumbName: '后台用户管理',
          path: 'user',
          auth_code: AuthCodes.SYSTEM_USER_LIST,
          indexRoute: {
            breadcrumbName: '用户列表',
            component: SystemUser, // component: SystemUser// getComponent: getComponent(SystemUser)
          },
          childRoutes: [
            {
              breadcrumbName: '用户新增',
              path: 'add',
              auth_code: AuthCodes.SYSTEM_USER_ADD,
              component: SystemUserAdd, // component: SystemUserAdd// getComponent: getComponent(SystemUserAdd)
            },
            {
              breadcrumbName: '用户修改',
              path: 'modify',
              auth_code: AuthCodes.SYSTEM_USER_UPDATE,
              component: SystemUserModify, // component: SystemUserModify// getComponent: getComponent(SystemUserModify)
            },
          ],
        },
        {
          breadcrumbName: '角色管理',
          path: 'role',
          auth_code: AuthCodes.SYSTEM_ROLE_LIST,
          indexRoute: {
            breadcrumbName: '角色列表',
            component: SystemRole, // component: SystemRole// getComponent: getComponent(SystemRole)
          },
          childRoutes: [
            {
              breadcrumbName: '角色新增',
              path: 'add',
              auth_code: AuthCodes.SYSTEM_ROLE_ADD,
              component: SystemRoleAdd, // component: SystemRoleAdd// getComponent: getComponent(SystemRoleAdd)
            },
            {
              breadcrumbName: '角色修改',
              path: 'modify',
              auth_code: AuthCodes.SYSTEM_ROLE_UPDATE,
              component: SystemRoleModify, // component: SystemRoleModify// getComponent: getComponent(SystemRoleModify)
            },
          ],
        },
        {
          breadcrumbName: '前台日志',
          path: 'frontend',
          auth_code: AuthCodes.SYSTEM_LOG_FRONTEND_LIST,
          indexRoute: {
            component: SystemLogFrontend, // component: SystemLogFrontend// getComponent: getComponent(SystemLogFrontend)
          },
          childRoutes: [
            {
              breadcrumbName: '前台日志详情',
              path: 'frontendDetail',
              component: SystemLogFrontendDetail, // component: SystemLogFrontendDetail// getComponent: getComponent(SystemLogFrontendDetail)
            },
          ],
        },
        {
          breadcrumbName: '后台日志',
          path: 'backend',
          auth_code: AuthCodes.SYSTEM_LOG_BACKEND_LIST,
          component: SystemLogBackend, // component: SystemLogBackend// getComponent: getComponent(SystemLogBackend)
        },
        {
          breadcrumbName: '权限管理列表',
          path: 'authManagement',
          auth_code: AuthCodes.SYSTEM_AUTH_LIST,
          indexRoute: {
            breadcrumbName: '授权管理',
            component: SystemAuthManagement, // getComponent: getComponent(SystemAuthManagement)
          },
          childRoutes: [
            {
              breadcrumbName: '修改',
              path: 'modify',
              auth_code: AuthCodes.SYSTEM_AUTH_UPDATE,
              component: SystemModifyAuth, // getComponent: getComponent(SystemModifyAuth)
            },
            {
              breadcrumbName: '增加',
              path: 'add',
              auth_code: AuthCodes.SYSTEM_AUTH_ADD,
              component: SystemAddAuth, // getComponent: getComponent(SystemAddAuth)
            },
          ],
        },
        {
          breadcrumbName: '数据字典',
          path: 'dataDict',
          auth_code: AuthCodes.SYSTEM_DATA_DICT,
          indexRoute: {
            breadcrumbName: '数据字典',
          },
          childRoutes: [
            {
              breadcrumbName: '列表',
              path: 'dataDictList',
              auth_code: AuthCodes.SYSTEM_DICT_TYPE_LIST,
              component: DataDict, // getComponent: getComponent(DataDict)
            },
            {
              breadcrumbName: '修改',
              path: 'modify',
              auth_code: AuthCodes.SYSTEM_DICT_UPDATE,
              component: EditDataDict, // getComponent: getComponent(EditDataDict)
            },
            {
              breadcrumbName: '增加',
              path: 'add',
              auth_code: AuthCodes.SYSTEM_DICT_TYPE_SAVE,
              component: AddDataDict, // getComponent: getComponent(AddDataDict)
            },
            {
              breadcrumbName: '查看',
              path: 'detail',
              auth_code: AuthCodes.SYSTEM_DICT_VIEW,
              component: ViewDataDict, // getComponent: getComponent(ViewDataDict)
            },
          ],
        },
        {
          breadcrumbName: '前台菜单管理',
          path: 'frontMenuManagement',
          auth_code: AuthCodes.SYSTEM_FRONT_USER_MENU,
          component: FrontMenuManagement,
        },
      ],
    },
    {
      title: '会员中心',
      path: 'memberCenter',
      indexRoute: {
        component: MemberCenter, // getComponent: getComponent(MemberCenter)
      },
      childRoutes: [
        {
          breadcrumbName: '修改企业名称',
          path: 'modifyCompany',
          auth_code: AuthCodes.MEMBER_CENTER_COMPANY_MODIFY,
          component: ModifyCompany,
          // indexRoute: {
          //   component: ModifyCompany,
          // },
        },
        {
          breadcrumbName: '资质列表',
          path: 'qualificationList',
          auth_code: AuthCodes.MEMBER_CENTER_QUALIFICATION_LIST,
          indexRoute: {
            component: QualificationList, // getComponent: getComponent(QualificationList)
          },
          childRoutes: [
            {
              breadcrumbName: '查看企业资质详情',
              path: 'qualificationDetail',
              auth_code:
                AuthCodes.MEMBER_CENTER_VIEW_COMPANY_QUALIFICATION_DETAIL,
              indexRoute: {
                component: ViewQualificationDetail, // getComponent: getComponent(ViewQualificationDetail)
              },
            },
            {
              breadcrumbName: '查看司机资质详情',
              path: 'viewPersonalQualificationDetail',
              auth_code:
                AuthCodes.MEMBER_CENTER_VIEW_PERSONAL_QUALIFICATION_DETAIL,
              indexRoute: {
                component: ViewPersonalQualificationDetail, // getComponent: getComponent(ViewPersonalQualificationDetail)
              },
            },
            {
              breadcrumbName: '企业资质修改',
              path: 'modifyQualification',
              auth_code:
                AuthCodes.MEMBER_CENTER_MODIFY_COMPANY_QUALIFICATION,
              indexRoute: {
                component: ModifyQualification, // getComponent: getComponent(ModifyQualification)
              },
            },
            {
              breadcrumbName: '司机资质修改',
              path: 'modifyPersonalQualification',
              auth_code:
                AuthCodes.MEMBER_CENTER_MODIFY_PERSONAL_QUALIFICATION,
              indexRoute: {
                component: ModifyPersonalQualification, // getComponent: getComponent(ModifyPersonalQualification)
              },
            },
          ],
        },
        {
          breadcrumbName: '资质审核列表',
          path: 'checkQualificationList',
          auth_code: AuthCodes.MEMBER_CENTER_CHECK_QUALIFICATION_LIST,
          indexRoute: {
            component: CheckQualification, // getComponent: getComponent(CheckQualification)
          },
          childRoutes: [
            {
              breadcrumbName: '企业资质审核',
              path: 'checkQualificationDetail',
              auth_code:
                AuthCodes.MEMBER_CENTER_AUDIT_COMPANY_QUALIFICATION,
              indexRoute: {
                component: CheckQualificationDetail, // getComponent: getComponent(CheckQualificationDetail)
              },
            },
            {
              breadcrumbName: '司机资质审核',
              path: 'checkPersonalQualification',
              auth_code:
                AuthCodes.MEMBER_CENTER_AUDIT_PERSONAL_QUALIFICATION,
              indexRoute: {
                component: CheckPersonalQualification, // getComponent: getComponent(CheckPersonalQualification)
              },
            },
            {
              breadcrumbName: '补全企业资质',
              path: 'complementCompanyQualification',
              auth_code:
                AuthCodes.MEMBER_CENTER_COMPLEMENT_COMPANY_QUALIFICATION,
              indexRoute: {
                component: ComplementCompanyQualification, // getComponent: getComponent(ComplementCompanyQualification)
              },
            },
            {
              breadcrumbName: '补全司机资质',
              path: 'complementPersonalQualification',
              auth_code:
                AuthCodes.MEMBER_CENTER_COMPLEMENT_PERSONAL_QUALIFICATION,
              indexRoute: {
                component: ComplementPersonalQualification, // getComponent: getComponent(ComplementPersonalQualification)
              },
            },
            {
              breadcrumbName: '查看司机资质审核详情',
              path: 'viewPersonalAuditDetail',
              auth_code: AuthCodes.MEMBER_CENTER_CHECK_QUALIFICATION_LIST,
              indexRoute: {
                component: ViewPersonalAuditDetail,
              },
            },
            {
              breadcrumbName: '查看企业资质审核详情',
              path: 'viewCompanyAuditDetail',
              auth_code: AuthCodes.MEMBER_CENTER_CHECK_QUALIFICATION_LIST,
              indexRoute: {
                component: ViewCompanyAuditDetail,
              },
            },
          ],
        },
        {
          breadcrumbName: '身份列表',
          path: 'identityList',
          auth_code: AuthCodes.MEMBER_CENTER_IDENTITY_LIST,
          indexRoute: {
            component: IdentityList, // getComponent: getComponent(IdentityList)
          },
          childRoutes: [
            {
              breadcrumbName: '查看身份',
              path: 'viewIdentity',
              auth_code: AuthCodes.MEMBER_CENTER_IDENTITY_DETAIL,
              indexRoute: {
                component: ViewIdentity, // getComponent: getComponent(ViewIdentity)
              },
            },
            {
              breadcrumbName: '身份管理',
              path: 'editIdentity',
              auth_code: AuthCodes.MEMBER_CENTER_IDENTITY_MANAGEMENT,
              indexRoute: {
                component: EditIdentity, // getComponent: getComponent(ViewIdentity)
              },
            },
          ],
        },
        {
          breadcrumbName: '身份审核列表',
          path: 'checkIdentity',
          auth_code: AuthCodes.MEMBER_CENTER_CHECK_IDENTITY_LIST,
          indexRoute: {
            component: CheckIdentity, // getComponent: getComponent(CheckIdentity)
          },
          childRoutes: [
            {
              breadcrumbName: '审核企业身份',
              path: 'checkCompanyIdentity',
              auth_code: AuthCodes.MEMBER_CENTER_CHECK_COMPANY_IDENTITY,
              indexRoute: {
                component: CheckCompanyIdentity, // getComponent: getComponent(CheckCompanyIdentity)
              },
            },
            {
              breadcrumbName: '审核司机身份',
              path: 'checkPersonalIdentity',
              auth_code: AuthCodes.MEMBER_CENTER_CHECK_PERSONAL_IDENTITY,
              indexRoute: {
                component: CheckPersonalIdentity, // getComponent: getComponent(CheckPersonalIdentity)
              },
            },
            {
              breadcrumbName: '企业身份审核历史',
              path: 'checkCompanyIdentityHistory',
              indexRoute: {
                component: CheckCompanyIdentityHistory, // getComponent: getComponent(CheckCompanyIdentityHistory)
              },
            },
            {
              breadcrumbName: '司机身份审核历史',
              path: 'checkPersonalIdentityHistory',
              indexRoute: {
                component: CheckPersonalIdentityHistory, // getComponent: getComponent(CheckPersonalIdentityHistory)
              },
            },
          ],
        },
        {
          breadcrumbName: '企业资质到期管理',
          path: 'companyQualificationPeriodManagement',
          auth_code:
            AuthCodes.MEMBER_CENTER_QUALIFICATION_PERIOD_MANAGEMENT,
          indexRoute: {
            component: CompanyQualificationPeriodManagement, // getComponent: getComponent(CompanyQualificationPeriodManagement)
          },
          childRoutes: [
            {
              breadcrumbName: '邮件通知设置',
              path: 'emailSet',
              auth_code: AuthCodes.MEMBER_CENTER_CERT_EMAIL_SET,
              indexRoute: {
                component: EmailSet, // getComponent: getComponent(EmailSet)
              },
            },
          ],
        },
      ],
    },
    {
      title: '招采会员',
      path: 'vipCenter',
      auth_code: AuthCodes.BID_MEMBER_MENU,
      indexRoute: {
        component: VipCenter, // getComponent: getComponent(VipCenter)
      },
      childRoutes: [
        {
          breadcrumbName: '会员列表',
          path: 'zcVipList',
          auth_code: AuthCodes.BID_MEMBER_LIST,
          indexRoute: {
            component: ZcVipList,
          },
          childRoutes: [
            {
              breadcrumbName: '新增会员',
              path: 'add',
              auth_code: AuthCodes.BID_MEMBER_LIST,
              indexRoute: {
                component: ZcVipAdd,
              },
            },
            {
              breadcrumbName: '查看会员详情',
              path: 'detail',
              auth_code: AuthCodes.BID_MEMBER_LIST,
              indexRoute: {
                component: ZcVipDetail,
              },
            },
          ],
        },
      ],
    },
    {
      title: '后台资金管理',
      path: 'fundsManagement',
      indexRoute: {
        component: FundsManagement, // getComponent: getComponent(FundsManagement)
      },
      childRoutes: [
        {
          breadcrumbName: '中信银行回单打印',
          path: 'receiptListPrint',
          auth_code: AuthCodes.FUNDS_RECEIPT,
          indexRoute: {
            component: ReceiptListPrint, // getComponent: getComponent(ReceiptListPrint)
          },
          childRoutes: [
            {
              breadcrumbName: '中信账户列表',
              path: 'accountList',
              auth_code: AuthCodes.FUNDS_ACCOUNT_LIST,
              component: AccountList, // getComponent: getComponent(AccountList)
            },
            {
              breadcrumbName: '交易回单列表',
              path: 'receiptList',
              component: ReceiptList, // getComponent: getComponent(ReceiptList)
            },
            {
              breadcrumbName: '交易回单打印流程',
              path: 'printProgress',
              component: PrintProgress, // getComponent: getComponent(PrintProgress)
            },
            {
              breadcrumbName: '银行回单',
              path: 'receiptOfBank',
              component: ReceiptOfBank, // getComponent: getComponent(ReceiptOfBank)
            },
          ],
        },
        {
          breadcrumbName: '会员资金管理',
          path: 'managementOfMemberFunds',
          indexRoute: {
            component: ManagementOfMemberFunds, // getComponent: getComponent(ManagementOfMemberFunds)
          },
          childRoutes: [
            {
              breadcrumbName: '会员资金账户列表',
              path: 'fundsMemberAccountList',
              auth_code: AuthCodes.FUNDS_MEMBER_ACCOUNT_LIST,
              component: FundsMemberAccountList, // getComponent: getComponent(FundsMemberAccountList)
            },
            {
              breadcrumbName: '会员资金明细',
              path: 'detail',
              auth_code: AuthCodes.FUNDS_MANAGEMENT_DETAIL,
              component: Detail, // getComponent: getComponent(Detail)
            },
            {
              breadcrumbName: '新增资金账户',
              path: 'newFundsAccountList',
              auth_code: AuthCodes.FUNDS_MANAGEMENT_CREATE_ACCOUNT,
              component: NewFundsAccountList, // getComponent: getComponent(NewFundsAccountList)
            },
          ],
        },
        {
          breadcrumbName: '出入金记录',
          path: 'transactionRecords',
          indexRoute: {
            component: ExpenditureRecords, // getComponent: getComponent(ExpenditureRecords)
          },
          childRoutes: [
            {
              breadcrumbName: '出金记录',
              path: 'expenditureRecords',
              auth_code: AuthCodes.FUNDS_RECORD_EXPENDITURE,
              component: ExpenditureRecords, // getComponent: getComponent(ExpenditureRecords)
            },
            {
              breadcrumbName: '入金记录',
              path: 'incomeRecords',
              auth_code: AuthCodes.FUNDS_RECORD_INCOME,
              component: IncomeRecords, // getComponent: getComponent(IncomeRecords)
            },
          ],
        },
        {
          breadcrumbName: '冻结解冻',
          path: 'freezeAndUnfreeze',
          auth_code: AuthCodes.FUNDS_FREEZE_AND_UNFREEZE,
          indexRoute: {
            component: FreezeAndUnfreeze, // getComponent: getComponent(FreezeAndUnfreeze)
          },
        },
        {
          breadcrumbName: '规则配置',
          path: 'ruleConfig',
          auth_code: AuthCodes.FUND_RULE_CONFIG_LIST,
          indexRoute: {
            component: RuleConfig, // getComponent: getComponent(RuleConfig)
          },
        },
        {
          breadcrumbName: '新增规则变量',
          path: 'addRuleVar',
          auth_code: AuthCodes.FUND_RULE_CONFIG_ADD,
          indexRoute: {
            component: AddRuleVar, // getComponent: getComponent(AddRuleVar)
          },
        },
        {
          breadcrumbName: '修改规则变量',
          path: 'modifyRule',
          auth_code: AuthCodes.FUND_RULE_CONFIG_UPDATE,
          indexRoute: {
            component: ModifyRule, // getComponent: getComponent(ModifyRule)
          },
        },
      ],
    },
    {
      title: '运营中心',
      path: 'operationCenter',
      indexRoute: {
        component: OperationCenter, // getComponent: getComponent(OperationCenter)
      },
      childRoutes: [
        {
          breadcrumbName: '短信模板列表',
          path: 'smsManagement',
          auth_code: AuthCodes.OPERATION_SMS_TEMPLATE_LIST,
          indexRoute: {
            component: SmsManagement, // getComponent: getComponent(SmsManagement)
          },
          childRoutes: [
            {
              breadcrumbName: '短信发送历史',
              path: 'smsHistory',
              auth_code: AuthCodes.OPERATION_SMS_RECORD_GET,
              indexRoute: {
                component: SmsHistory, // getComponent: getComponent(SmsHistory)
              },
            },
            {
              breadcrumbName: '新增短信模板',
              path: 'add',
              auth_code: AuthCodes.OPERATION_SMS_TEMPLATE_SAVE,
              indexRoute: {
                component: AddSms, // getComponent: getComponent(AddSms)
              },
            },
            {
              breadcrumbName: '查看短信模板',
              path: 'detail',
              auth_code: AuthCodes.OPERATION_SMS_TEMPLATE_GET,
              indexRoute: {
                component: ViewSmsModel, // getComponent: getComponent(ViewSmsModel)
              },
            },
            {
              breadcrumbName: '修改短信模板',
              path: 'modify',
              auth_code: AuthCodes.OPERATION_SMS_TEMPLATE_UPDATE,
              indexRoute: {
                component: ModifySms, // getComponent: getComponent(ModifySms)
              },
            },
            {
              breadcrumbName: '历史短信详情',
              path: 'historySmsDetail',
              auth_code: AuthCodes.OPERATION_SMS_RECORD_GET,
              indexRoute: {
                component: ViewHistorySmsDetail, // getComponent: getComponent(ViewHistorySmsDetail)
              },
            },
          ],
        },
        {
          breadcrumbName: '线下数据上传',
          path: 'uploadOfflineData',
          auth_code: AuthCodes.OPERATION_OFFLINE_DATA_UPLOAD,
          indexRoute: {
            component: UploadOfflineData,
          },
        },
        {
          breadcrumbName: '消息黑名单管理',
          path: 'smsBlackListManagement',
          auth_code: AuthCodes.OPERATION_SMS_BLACKLIST_MANAGEMENT,
          indexRoute: {
            component: SmsBlackListManagement,
          },
          childRoutes: [
            {
              breadcrumbName: '消息黑名单账户管理',
              path: 'smsBlackListAccountManagement',
              auth_code:
                AuthCodes.OPERATION_SMS_ACCOUNT_BLACKLIST_MANAGEMENT,
              indexRoute: {
                component: SmsBlackListAccountManagement,
              },
            },
          ],
        },
        {
          breadcrumbName: '大屏管理',
          path: 'screen',
          auth_code: AuthCodes.OPERATION_SCREEN,
          indexRoute: {
            component: Screen,
          },
        },
        {
          breadcrumbName: '短信群发',
          path: 'groupSms',
          auth_code: AuthCodes.OPERATION_GROUPSMS_LIST,
          indexRoute: {
            component: GroupSms,
          },
          childRoutes: [
            {
              breadcrumbName: '新增群发短信',
              path: 'add',
              auth_code: AuthCodes.OPERATION_GROUPSMS_CREATE,
              indexRoute: {
                component: AddGroupSms,
              },
            },
            {
              breadcrumbName: '群发消息详情',
              path: 'detail',
              auth_code: AuthCodes.OPERATION_GROUPSMS_VIEW,
              indexRoute: {
                component: GroupSmsDetail,
              },
            },
          ],
        },
      ],
    },
  ],
};

export default routesConfig;

const isArray = Array.isArray;
export const renderRoutes = ((config = routesConfig, parentPath = '') => {
  const configPath = config.path || '';
  const currentPath = (`${parentPath}/${configPath || ''}`).replace(/\/+/g, '/');
  let allRoutes = [];
  const exact = config.exact !== void 0 ? config.exact : true;

  if (isArray(config)) {
    return config.map((r, index) => {
      return renderRoutes(r, currentPath);
    });
  }

  if (config.childRoutes) {
    if (config.indexRoute) {
      allRoutes = allRoutes.concat(
        <Route key={currentPath} auth_code={config.auth_code} path={currentPath} component={config.indexRoute.component} exact={exact} />
      );
    }
    allRoutes = allRoutes.concat(
      ...renderRoutes(config.childRoutes, currentPath),
    );
  } else {
    allRoutes.push(
      <Route path={currentPath} auth_code={config.auth_code} component={config.component || config.indexRoute.component} key={currentPath} exact={exact} />
    );
  }

  return allRoutes.sort((a, b) => {
    return b.props.path.split('/').length - a.props.path.split('/').length;
  });
});