import React from 'react';
import dayjs from 'dayjs';
import reaction from 'reaction';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import {
  Table,
  Button,
  Input,
  DatePicker,
  Tabs,
  Form,
} from 'antd';

import { historyPush } from 'utils/history';
import { RoutePath } from 'utils/constants';
import FormItem from 'components/FormItem';
import ConditionFieldItem, { ConditionFieldGroup } from 'components/Condition/FieldItem';

import { resolveUrlQuery, getUniqTableRowKey, renderListControls } from 'utils';

import commonStyles from 'style/common.module.scss';

const TabPane = Tabs.TabPane;
const { RangePicker } = DatePicker;
let dateFormat = 'YYYY/MM/DD';
const location = window.location;

@connect(({ memberCenter }) => ({ memberCenter }))
class CheckIdentityList extends React.Component {
  state = {
    query: Immutable.Map({
      userNo: '',
      name: '',
      phone: '',
      toAudit: true,
      auditHistory: false,
      time: [],
      page: 1,
      length: 10,
    }),
  };

  getIdentifyNumber = () => {
    reaction.memberCenter.getIdentifyNumber();
  };

  getCheckIdentifyList = () => {
    let {
      userNo,
      name,
      phone,
      time,
      toAudit,
      auditHistory,
      page,
      length,
    } = this.state.query.toJS();
    let params = {
      userNo,
      name,
      phone,
      toAudit,
      auditHistory,
      page,
      length,
    };
    if (time[0]) {
      params.startTime = time[0].startOf('day').valueOf();
    }
    if (time[1]) {
      params.endTime = time[1].endOf('day').valueOf();
    }
    historyPush(
      `/${RoutePath.MEMBERCENTER}/${RoutePath.CHECK_IDENTIFY}`,
      params
    );
    reaction.memberCenter.getCheckIdentifyList(params);
  };

  goToCheck = (record) => {
    let query = {
      userId: record.userId,
      auditNo: record.auditNo,
      ...resolveUrlQuery(this.props.location.search),
    };
    let queryArr = [];
    for (let key in query) {
      if (query.hasOwnProperty(key)) {
        queryArr.push(key + '=' + query[key]);
      }
    }
    let queryStr = queryArr.join('&');
    if (record.certType === '1') {
      // historyPush(`/${RoutePath.MEMBERCENTER}/${RoutePath.CHECK_IDENTIFY}/${RoutePath.CHECK_COMPANY_IDENTIFY}`, query);
      window.open(
        `//${location.host}/#/${RoutePath.MEMBERCENTER}/${RoutePath.CHECK_IDENTIFY}/${RoutePath.CHECK_COMPANY_IDENTIFY}?${queryStr}`
      );
    } else if (record.certType === '2') {
      // historyPush(`/${RoutePath.MEMBERCENTER}/${RoutePath.CHECK_IDENTIFY}/${RoutePath.CHECK_PERSONAL_IDENTIFY}`, query);
      window.open(
        `//${location.host}/#/${RoutePath.MEMBERCENTER}/${RoutePath.CHECK_IDENTIFY}/${RoutePath.CHECK_PERSONAL_IDENTIFY}?${queryStr}`
      );
    }
  };

  goToView = (record) => {
    let query = {
      userId: record.userId,
      auditNo: record.auditNo,
      ...resolveUrlQuery(this.props.location.search),
    };
    let queryArr = [];
    for (let key in query) {
      if (query.hasOwnProperty(key)) {
        queryArr.push(key + '=' + query[key]);
      }
    }
    let queryStr = queryArr.join('&');
    // historyPush(`/${RoutePath.MEMBERCENTER}/${RoutePath.CHECK_IDENTIFY}/${RoutePath.CHECK_COMPANY_IDENTIFY_HISTORY}`, query);
    if (record.certType === '1') {
      window.open(
        `//${location.host}/#/${RoutePath.MEMBERCENTER}/${RoutePath.CHECK_IDENTIFY}/${RoutePath.CHECK_COMPANY_IDENTIFY_HISTORY}?${queryStr}`
      );
    } else {
      window.open(
        `//${location.host}/#/${RoutePath.MEMBERCENTER}/${RoutePath.CHECK_IDENTIFY}/${RoutePath.CHECK_PERSONAL_IDENTIFY_HISTORY}?${queryStr}`
      );
    }
  };

  onQueryChange = (name, value) => {
    let { query } = this.state;
    query = query.set(name, value);
    this.setState({ query }, () => {});
  };

  doSearch = (e) => {
    let { query } = this.state;
    query = query.set('page', 1);
    this.setState({ query }, () => {
      this.getCheckIdentifyList();
    });
    e && e.preventDefault();
  };

  handleTableChange = (pagination, filters, sorter) => {
    let { query } = this.state;
    query = query.set('page', pagination.current);
    query = query.set('length', pagination.pageSize);
    this.setState({ query }, () => {
      this.getCheckIdentifyList();
    });
  };

  clear = () => {
    let { query } = this.state;
    query = query.set('userNo', '');
    query = query.set('name', '');
    query = query.set('phone', '');
    query = query.set('time', []);

    this.setState({ query }, () => {
      historyPush(`/${RoutePath.MEMBERCENTER}/${RoutePath.CHECK_IDENTIFY}`);
    });
  };

  onTabChange = (key) => {
    let { query } = this.state;
    query = query.set('page', 1);
    if (key === 'auditHistory') {
      query = query.set('auditHistory', true);
      query = query.set('toAudit', false);
    } else {
      query = query.set('auditHistory', false);
      query = query.set('toAudit', true);
    }
    this.setState({ query }, () => {
      this.getCheckIdentifyList();
    });
  };

  onPressEnter = () => {
    this.doSearch();
  };

  initialStateFromQuery() {
    let { query } = this.state;
    let {
      userNo,
      name,
      phone,
      startTime,
      endTime,
      toAudit,
      auditHistory,
      page,
      length,
    } = resolveUrlQuery(this.props.location.search);
    userNo && (query = query.set('userNo', userNo));
    name && (query = query.set('name', name));
    phone && (query = query.set('phone', phone));
    toAudit && (query = query.set('toAudit', toAudit === 'true'));
    auditHistory &&
      (query = query.set('auditHistory', auditHistory === 'true'));
    page && (query = query.set('page', +page));
    length && (query = query.set('length', +length));
    startTime &&
      endTime &&
      (query = query.set('time', [dayjs(+startTime), dayjs(+endTime)]));

    this.setState({ query }, () => {
      // historyPush(`/${RoutePath.MEMBERCENTER}/${RoutePath.CHECK_IDENTIFY}`);
    });
  }
  componentDidMount() {
    this.initialStateFromQuery();
    this.getCheckIdentifyList();
    this.getIdentifyNumber();
  }

  getColumns(toAudit, auditHistory) {
    return [
      {
        title: '审核批次号',
        dataIndex: 'auditNo',
        ellipsis: true,
      },
      {
        title: '会员编号',
        dataIndex: 'userNo',
        // width: 120,
      },
      {
        title: '用户名',
        dataIndex: 'username',
        // width: 160,
      },
      {
        title: '安全手机号',
        dataIndex: 'phone',
        // width: 160,
      },
      {
        title: '主体名称',
        dataIndex: 'name',
        // width: 300,
        ellipsis: true,
      },
      {
        title: '申请身份',
        dataIndex: 'identity',
        render: (arr) => {
          return arr.join(',');
        },
      },
      {
        title: '申请时间',
        dataIndex: 'time',
        width: 200,
        render: (text) => {
          if (!text) {
            return '';
          }
          return dayjs(text - 0).format('YYYY-MM-DD HH:mm:ss');
        },
      },
      {
        title: toAudit ? '资质审核' : '审核结果',
        dataIndex: 'auditStatus',
        width: 120,
      },
      {
        title: '操作',
        key: 'handler',
        render: (text, record) => (
          <span>
            {toAudit && (
              <Button
                onClick={() => {
                  this.goToCheck(record);
                }}
              >
                审核
              </Button>
            )}
            {auditHistory && (
              <Button
                onClick={() => {
                  this.goToView(record);
                }}
              >
                查看
              </Button>
            )}
          </span>
        ),
      },
    ]
  }

  render() {
    let list = this.props.memberCenter.checkIdentifyList;
    let checkIdentifyNumber = this.props.memberCenter.checkIdentifyNumber;
    let queryValue = this.state.query.toJS();
    let { toAudit } = this.state.query.toJS();
    let { auditHistory } = this.state.query.toJS();
    return (
      <div>
        <div>
          <h2 className={commonStyles.row}>身份审核</h2>
          <p>
            待审核
            <span style={{ color: 'red' }}>
              {checkIdentifyNumber.toAuditIdentityNumber}
            </span>
            条，待资质审核
            <span style={{ color: 'red' }}>
              {checkIdentifyNumber.toAuditCertNumber}
            </span>
            条。
          </p>
        </div>
        <Form layout="inline" labelCol={{ xl: 7, sm: 12 }} wrapperCol={{ xl: 17, sm: 12 }}>
          {
            renderListControls([
              <FormItem label="会员编号">
                <Input
                  onPressEnter={this.onPressEnter}
                  value={queryValue.userNo}
                  onChange={(e) => {
                    this.onQueryChange('userNo', e.target.value);
                  }}
                  placeholder="会员编号"
                />
              </FormItem>,
              <FormItem label="主体名称">
                <Input
                  onPressEnter={this.onPressEnter}
                  value={queryValue.name}
                  onChange={(e) => {
                    this.onQueryChange('name', e.target.value);
                  }}
                  placeholder="主体名称"
                />
              </FormItem>,
              <FormItem label="手机号码">
                <Input
                  onPressEnter={this.onPressEnter}
                  value={queryValue.phone}
                  onChange={(e) => {
                    this.onQueryChange('phone', e.target.value);
                  }}
                  placeholder="手机号码"
                />
              </FormItem>,
              <FormItem label="申请时间">
                <RangePicker
                  value={queryValue.time}
                  format={dateFormat}
                  onChange={(value) => {
                    this.onQueryChange('time', value);
                  }}
                />
              </FormItem>,
              {
                full: true,
                colProps: {
                  lg: 24,
                  xl: 16,
                  xxl: 24,
                  md: 24,
                },
                children: (
                  <FormItem align="right">
                    <Button
                      type="primary"
                      onClick={this.doSearch}
                      icon="search"
                    >
                      查询
                    </Button>
                    <Button
                      style={{ margin: '0 0 0 5px' }}
                      type="primary"
                      onClick={this.clear}
                      icon="redo"
                    >
                      清空
                    </Button>
                  </FormItem>
                )
              }
            ], { justify: 'start' })
          }
        </Form>
        <div className={commonStyles.row}>
          <Tabs
            type="card"
            onChange={this.onTabChange}
            activeKey={toAudit ? 'toAudit' : 'auditHistory'}
          >
            <TabPane tab="待审核" key="toAudit">
              {list && (
                <Table
                  className={commonStyles.row}
                  rowKey={getUniqTableRowKey('userId', list.pagerInfo)}
                  columns={this.getColumns(toAudit, auditHistory)}
                  dataSource={list.result}
                  pagination={{
                    current: list.pagerInfo.page,
                    total: list.pagerInfo.total,
                    pageSizeOptions: ['10', '20', '50'],
                    showSizeChanger: true,
                    showQuickJumper: true,
                    showTotal: (total) => `共${total}条`,
                  }}
                  onChange={this.handleTableChange}
                />
              )}
            </TabPane>
            <TabPane tab="审核历史" key="auditHistory">
              {list && (
                <Table
                  className={commonStyles.row}
                  rowKey={getUniqTableRowKey('userId', list.pagerInfo)}
                  columns={this.getColumns(toAudit, auditHistory)}
                  dataSource={list.result}
                  pagination={{
                    current: list.pagerInfo.page,
                    total: list.pagerInfo.total,
                    pageSizeOptions: ['10', '20', '50'],
                    showSizeChanger: true,
                    showQuickJumper: true,
                    showTotal: (total) => `共${total}条`,
                  }}
                  onChange={this.handleTableChange}
                />
              )}
            </TabPane>
          </Tabs>
        </div>
      </div>
    );
  }
}

export default CheckIdentityList;
