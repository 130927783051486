import React from 'react';
import { connect } from 'react-redux';
import reaction from 'reaction/index';
import { AuthCodes } from 'utils/constants';
import { Input, Select, Button, Form } from 'antd';
import TableWrapper from 'components/TableWrapper/index';
import FormItem from 'components/FormItem';
import Immutable from 'immutable';
import { withRouter } from 'react-router-dom';
import { renderListControls } from 'utils';

@connect(({ accountList, me }) => ({ accountList, me }))
@withRouter
class AccounList extends React.Component {
  inputValueChange = (type, e) => {
    this.setState(
      {
        [type]: e && e.target ? e.target.value : e,
      },
      () => {
        // ...
      }
    );
  };

  onQueryChange = (name, value) => {
    let { query } = this.state;
    query = query.set(name, value);
    this.setState({ query }, () => {});
  };

  onSettleBankChange = (value) => {
    let { query } = this.state;
    query = query.set('settleBank', value);
    this.setState({ query });
    // ...
  };

  onPageChange = (page, pageSize) => {
    let { query } = this.state;
    query = query.set('page', page);
    query = query.set('length', pageSize);
    this.setState({ query }, () => {
      this.getAccountList();
    });
  };

  onPageSizeChange = (current, size) => {
    let { query } = this.state;
    query = query.set('page', 1);
    query = query.set('length', size);
    this.setState({ query }, () => {
      this.getAccountList();
    });
  };

  getAccountList = () => {
    reaction.accountList.getAccountList({ ...this.state.query.toJS() });
  };

  checkReceiptList = (record) => {
    this.props.history.push({
      pathname: '/fundsManagement/receiptListPrint/receiptList',
      search: `?userLoginName=${record.userLoginName}&accountNo=${record.accountNo}`,
    });
  };

  clear = () => {
    let { query } = this.state;

    query = query.set('userLoginName', '');
    query = query.set('accountName', '');
    query = query.set('accountNo', '');
    query = query.set('settleBank', '');
    query = query.set('minTotalMoney', '');
    query = query.set('maxTotalMoney', '');

    this.setState({ query }, () => {
      // ......
    });
  };

  submit = () => {
    let { query } = this.state;
    query = query.set('page', 1);
    this.setState({ query }, () => {
      this.getAccountList();
    });
  };

  onPressEnter = () => {
    this.submit();
  };

  state = {
    query: Immutable.Map({
      userLoginName: '',
      accountName: '',
      accountNo: '',
      settleBank: '',
      minTotalMoney: '',
      maxTotalMoney: '',
      page: 1,
      length: 10,
    }),
  };

  componentDidMount() {
    let { roleAuthList } = this.props.me.roleInfo;
    let listEnabled = roleAuthList.some((item) => {
      return item.authCode.indexOf(AuthCodes.FUNDS_ACCOUNT_LIST) !== -1;
    });
    listEnabled && this.getAccountList();
  }

  render() {
    let { list } = this.props.accountList;
    let { query } = this.state;

    let _this = this;
    const columns = [
      {
        title: '手机号',
        dataIndex: 'userLoginName',
        key: 'userLoginName',
      },
      {
        title: '主体名称',
        dataIndex: 'accountName',
        key: 'accountName',
      },
      {
        title: '银行账号',
        dataIndex: 'accountNo',
        key: 'accountNo',
      },
      {
        title: '结算银行',
        dataIndex: 'settleBank',
        key: 'settleBank',
      },
      {
        title: '操作',
        dataIndex: 'operate',
        key: 'operate',
        render: function(text, record, index) {
          return (
            <Button
              type="primary"
              onClick={() => {
                _this.checkReceiptList(record);
              }}
            >
              查看交易回单
            </Button>
          );
        },
      },
    ];

    return (
      <div>
        <Form>
          {
            renderListControls([
              <FormItem label="手机号">
                <Input
                  onPressEnter={this.onPressEnter}
                  value={query.toJS().userLoginName}
                  onChange={(e) => {
                    this.onQueryChange('userLoginName', e.target.value);
                  }}
                  placeholder="手机号"
                />
              </FormItem>,
              <FormItem label="主体名称">
                <Input
                  onPressEnter={this.onPressEnter}
                  value={query.toJS().accountName}
                  onChange={(e) => {
                    this.onQueryChange('accountName', e.target.value);
                  }}
                  placeholder="主体名称"
                />
              </FormItem>,
              <FormItem label="银行账号">
                <Input
                  onPressEnter={this.onPressEnter}
                  value={query.toJS().accountNo}
                  onChange={(e) => {
                    this.onQueryChange('accountNo', e.target.value);
                  }}
                  placeholder="银行账号"
                />
              </FormItem>,
              <FormItem label="结算银行">
                <Select
                  showSearch
                  placeholder="选择结算银行"
                  optionFilterProp="children"
                  value={query.toJS().settleBank}
                  onChange={this.onSettleBankChange}
                >
                  <Select.Option value="">全部</Select.Option>
                  <Select.Option value="CITIC">中信银行</Select.Option>
                  <Select.Option value="E_BUTLER">中信E管家</Select.Option>
                </Select>
              </FormItem>,
              {
                colProps: {
                  xxl: 24,
                  xl: 16,
                  lg: 24,
                  md: 24,
                },
                children: (
                  <FormItem align="right">
                    <Button
                      type="primary"
                      onClick={this.submit}
                      icon="search"
                    >
                      查询
                    </Button>
                    <Button
                      style={{ margin: '0 0 0 5px' }}
                      type="primary"
                      onClick={this.clear}
                      icon="redo"
                    >
                      清空
                    </Button>
                  </FormItem>
                )
              }
            ])
          }
        </Form>

        {/*<div style={{padding: '10px 0 30px 0', borderBottom: '1px solid #eee'}}>*/}
        {/*<span style={{width: 178, margin: '10px 0 0 5px', display: 'inline-block'}}>*/}
        {/*<label>总现金：</label><InputNumber min={0} max={99999999} step={0.1} value={this.state.minMoney}*/}
        {/*onChange={this.inputValueChange.bind(this, 'minMoney')}/>*/}
        {/*</span>*/}
        {/*<span style={{width: 177, margin: '10px 0 0 5px', display: 'inline-block'}}>*/}
        {/*<label>至：</label><InputNumber min={500} max={99999999} step={0.1} value={this.state.maxMoney}*/}
        {/*onChange={this.inputValueChange.bind(this, 'maxMoney')}/>*/}
        {/*</span>*/}

        {/*</div>*/}

        <div style={{ padding: '20px 20px 0 5px' }}>
          <TableWrapper
            columns={columns}
            data={list}
            onChange={this.onPageChange}
            onShowSizeChange={this.onPageSizeChange}
            pageSize={query.toJS().length}
            rowKey={(row) => {
              return `${query.get('page')}__${row.accountNo}`
            }}
          />
        </div>
      </div>
    );
  }
}

export default AccounList;
