import createUID from 'utils/createUID';
import http from 'utils/http';
var INITIAL_DATA = createUID();
var PLATFORM_DICT = createUID();
var ROLE_DICT = createUID();
var COMMON_DICT = createUID();
var AUTH_DICT = createUID();
var DEPARTMENT_DICT = createUID();
var CHARACTERDICT = createUID();
var SUPPLY_CATEGORY = createUID(); // const TEAMS = createUID();
var initialState = { userStatus: {}, roleDict: {}, platformDict: {}, authDict: [], eventType: {}, platforms: [], departments: [], characterDict: [], supplyCategoryTree: [],
    team: [],
    invitednterprises: [], manufactorTypeList: [] };
;
function reducer() { var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState; var action = arguments.length > 1 ? arguments[1] : undefined; switch (action.type) {
    case INITIAL_DATA: return Object.assign({}, state, action.data);
    case COMMON_DICT: return Object.assign({}, state, action.data);
    case PLATFORM_DICT: return Object.assign({}, state, { platformDict: action.data });
    case ROLE_DICT: return Object.assign({}, state, { roleDict: action.data });
    case AUTH_DICT: return Object.assign({}, state, { authDict: action.data });
    case DEPARTMENT_DICT: return Object.assign({}, state, { departments: action.data });
    case CHARACTERDICT: return Object.assign({}, state, { characterDict: action.data });
    case SUPPLY_CATEGORY: return Object.assign({}, state, { supplyCategoryTree: action.data });
    default: return state;
} }
;
Object.assign(reducer, { localInitialData: function localInitialData(data) { return { type: INITIAL_DATA, data: data }; }, getCommonDict: function getCommonDict() { return function (dispatch) { return http.request('v1.common.commonDict').then(function (data) { dispatch({ type: COMMON_DICT, data: data.data }); return data; }); }; }, getCharacterDict: function getCharacterDict(params) { return function (dispatch) { return http.request('v1.frontUser.userCharacter', params).then(function (data) { dispatch({ type: CHARACTERDICT, data: data.data }); return data; }); }; }, getPlatformDict: function getPlatformDict() { return function (dispatch) { return http.request('v1.common.platformDict').then(function (data) { dispatch({ type: PLATFORM_DICT, data: data.data }); return data; }); }; }, getRoleDict: function getRoleDict() { return function (dispatch) { return http.request('v1.common.roleDict', {}).then(function (data) { dispatch({ type: ROLE_DICT, data: data.data }); return data; }); }; }, getAuthDict: function getAuthDict() { return function (dispatch) { return http.request('v1.system.treeAuth', {}).then(function (data) { dispatch({ type: AUTH_DICT, data: data.data }); return data; }); }; }, getDepartmentDict: function getDepartmentDict() { return function (dispatch) { return http.request('v1.user.listDepartment', {}).then(function (data) { dispatch({ type: DEPARTMENT_DICT, data: data.data }); return data; }); }; }, getSupplyCategory: function getSupplyCategory() { return function (dispatch) { return http.request('v1.cert.treeUserSupplyCategory', {}, false).then(function (data) { dispatch({ type: SUPPLY_CATEGORY, data: data.data }); return data; }); }; } });
export default reducer;
