import React from 'react';
import { connect } from 'react-redux';
import reaction from 'reaction';
import Immutable from 'immutable';
import {
  Table,
  Button,
  Input,
  message,
  Popconfirm,
} from 'antd';

import commonStyles from 'style/common.module.scss';

@connect(({ smsBlackListAccountManagement }) => ({
  smsBlackListAccountManagement,
}))
class AccountList extends React.Component {
  state = {
    query: Immutable.Map({
      username: '',
      userId: '',
      certSubject: '',
      phone: '',
      page: 1,
      length: 10,
    }),
    userIdList: [],
  };

  getAccountList = () => {
    let {
      username,
      userId,
      certSubject,
      phone,
      page,
      length,
    } = this.state.query.toJS();
    let params = { username, userId, certSubject, phone, page, length };
    reaction.smsBlackListAccountManagement.getAccountList(params);
  };

  onQueryChange = (name, value) => {
    let { query } = this.state;
    query = query.set(name, value);
    this.setState({ query }, () => {});
  };

  doSearch = (e) => {
    let { query } = this.state;
    query = query.set('page', 1);
    this.setState({ query }, () => {
      this.getAccountList();
    });
    e && e.preventDefault();
  };

  handleTableChange = (pagination, filters, sorter) => {
    let { query } = this.state;
    query = query.set('page', pagination.current);
    query = query.set('length', pagination.pageSize);
    this.setState({ query }, () => {
      this.getAccountList();
    });
  };

  onPressEnter = () => {
    this.doSearch();
  };

  confirmAdd = (record) => {
    reaction.smsBlackListAccountManagement
      .confirmAddNewAccountToBlackList(record.userId)
      .then((res) => {
        if (res.code === 0) {
          message.success('添加到黑名单成功!');
          this.getAccountList();
        }
      });
  };

  confirmMultipleAddToBlackList = () => {
    if (this.state.userIdList.length === 0) {
      message.warning('请选择要加入黑名单的账户后再提交!');
      return;
    }
    reaction.smsBlackListAccountManagement
      .confirmMultipleAddToBlackList(this.state.userIdList)
      .then((res) => {
        if (res.code === 0) {
          message.success('添加到黑名单成功!');
          this.getAccountList();
        }
      });
  };

  componentDidMount() {
    this.getAccountList();
  }

  render() {
    let { list } = this.props.smsBlackListAccountManagement;
    let queryValue = this.state.query.toJS();
    let columns = [
      {
        title: '用户名',
        dataIndex: 'username',
      },
      {
        title: '用户ID',
        dataIndex: 'userId',
      },
      {
        title: '主体名称',
        dataIndex: 'certSubject',
      },
      {
        title: '账户类型',
        dataIndex: 'userType',
      },
      {
        title: '手机号码',
        dataIndex: 'phone',
      },
      {
        title: '是否已加入黑名单',
        dataIndex: 'inBlacklist',
        render: (text) => {
          if (text === '1' || text === true) {
            return '是';
          } else {
            return '否';
          }
        },
      },
      {
        title: '操作',
        render: (text, record) =>
          record.inBlacklist ? (
            ''
          ) : (
            <Popconfirm
              placement="top"
              title="确认加入黑名单？"
              onConfirm={() => {
                this.confirmAdd(record);
              }}
              okText="确定"
              cancelText="取消"
            >
              <Button type="primary">加入黑名单</Button>
            </Popconfirm>
          ),
      },
    ];
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({
          userIdList: selectedRowKeys,
        });
      },
      getCheckboxProps: (record) => ({
        disabled: false, // Column configuration not to be checked
        name: record.name,
      }),
    };
    return (
      <div style={{ padding: '0 0 50px 0' }}>
        <h4 style={{ padding: '10px 0 20px 0' }}>新增账户黑名单</h4>
        <Input
          style={{ width: 250, marginLeft: 15, marginTop: 10 }}
          onPressEnter={this.onPressEnter}
          value={queryValue.username}
          onChange={(e) => {
            this.onQueryChange('username', e.target.value);
          }}
          addonBefore="用户名"
          defaultValue=""
          placeholder="请输入用户名"
        />
        <Input
          style={{ width: 250, marginLeft: 15, marginTop: 10 }}
          onPressEnter={this.onPressEnter}
          value={queryValue.userId}
          onChange={(e) => {
            this.onQueryChange('userId', e.target.value);
          }}
          addonBefore="用户ID"
          defaultValue=""
          placeholder="请输入用户ID"
        />
        <Input
          style={{ width: 250, marginLeft: 15, marginTop: 10 }}
          onPressEnter={this.onPressEnter}
          value={queryValue.certSubject}
          onChange={(e) => {
            this.onQueryChange('certSubject', e.target.value);
          }}
          addonBefore="主体名称"
          defaultValue=""
          placeholder="请输入主体名称"
        />
        <Input
          style={{ width: 250, marginLeft: 15, marginTop: 10 }}
          onPressEnter={this.onPressEnter}
          value={queryValue.phone}
          onChange={(e) => {
            this.onQueryChange('phone', e.target.value);
          }}
          addonBefore="手机号码"
          defaultValue=""
          placeholder="请输入手机号码"
        />
        <Button
          style={{ margin: '0 0 0 15px', marginTop: 10 }}
          type="primary"
          onClick={this.doSearch}
        >
          查询
        </Button>
        {list && (
          <Table
            className={commonStyles.row}
            rowKey="userId"
            rowSelection={rowSelection}
            columns={columns}
            dataSource={list.result}
            pagination={{
              current: list.pagerInfo.page,
              total: list.pagerInfo.total,
              pageSizeOptions: ['10', '20', '50'],
              showSizeChanger: true,
              showQuickJumper: true,
              showTotal: (total) => `共${total}条`,
            }}
            onChange={this.handleTableChange}
          />
        )}
        <div>
          <Popconfirm
            placement="top"
            title="确认加入黑名单？"
            onConfirm={this.confirmMultipleAddToBlackList}
            okText="确定"
            cancelText="取消"
          >
            <Button type="primary">批量加入黑名单</Button>
          </Popconfirm>
        </div>
      </div>
    );
  }
}

export default AccountList;
