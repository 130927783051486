import createUID from 'utils/createUID';
import http from 'utils/http';
import { HttpCode } from 'utils/constants';

const GET_LIST = createUID();
const GET_DETAIL = createUID();
const DELETE = createUID();

const initialState = {
  list: {
    pagerInfo: { current: 1, total: 0 },
    result: []
  }
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_LIST:
      return Object.assign({}, state, { list: action.data });
    case GET_DETAIL:
      return Object.assign({}, state, { detail: action.data });
    case DELETE: {
      let result = state.list.result.filter((data) => data.uid !== action.data);
      return Object.assign({}, state, { list: { ...state.list, result } });
    }
    default:
      return state;
  }
}
Object.assign(reducer, {
  getReceiptList({ accountNo = '', datetime = 0, accountType, loanFlag = '', page = 1, length = 10 }) {
    return (dispatch) => {
      return http.request('v1.funds.listReceipt', {
        pagerCondition: { page, length },
        queryCondition: { accountNo, datetime, accountType, loanFlag }
      }).then((data) => {
        dispatch({ type: GET_LIST, data: data.data });
        return data;
      });
    };
  }
});

export default reducer;
