import React from 'react';
import { connect } from 'react-redux';
import reaction from 'reaction';
import dayjs from 'dayjs';
// import { historyPush } from 'utils/history';
import Immutable from 'immutable';
import {
  Table,
  Button,
  Input,
  Select,
  DatePicker,
  Form,
  Icon,
} from 'antd';
import locale from 'antd/lib/date-picker/locale/zh_CN';
import FormItem from 'components/FormItem';

import { renderListControls } from 'utils';
import { RoutePath } from 'utils/constants';
import { certTypeOptions, userActiveStatusOptions } from 'config';

import commonStyles from 'style/common.module.scss';

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY/MM/DD';
const Option = Select.Option;
const location = window.location;

const VisibleControl = ({ visible = false, children }) => {
  if (!visible) {
    return null;
  }
  return children;
}

@connect(({ memberCenter, common }) => ({ memberCenter, common }))
class QualificationList extends React.Component {
  state = {
    areaDict: [],
    provinceDict: [],
    cityDict: [],
    query: Immutable.Map({
      userNo: '',
      certSubject: '',
      phone: '',
      active: '',
      username: '',
      authTime: [],
      registerTime: [],
      updateTime: [],
      isInternalUser: false,
      proxyType: '',
      provinceCode: '',
      cityCode: '',
      auditor: '',
      certType: '',
      page: 1,
      length: 10,
    }),
    userIdList: [],
    showAll: false,
  };

  getCertAccountNumber = () => {
    reaction.memberCenter.getCertAccountNumber();
  };

  getQualificationList = () => {
    let {
      userNo,
      certSubject,
      phone,
      active,
      username,
      authTime,
      registerTime,
      updateTime,
      proxyType,
      isInternalUser,
      provinceCode,
      cityCode,
      auditor,
      certType,
      page,
      length,
    } = this.state.query.toJS();
    let params = {
      userNo,
      certSubject,
      phone,
      active,
      username,
      proxyType,
      isInternalUser,
      provinceCode,
      cityCode,
      page,
      length,
      auditor,
      certType,
    };
    if (authTime[0]) {
      params.startTime = authTime[0].startOf('day').valueOf();
    }
    if (authTime[1]) {
      params.endTime = authTime[1].endOf('day').valueOf();
    }
    if (registerTime[0]) {
      params.registerBeginTime = registerTime[0].startOf('day').valueOf();
    }
    if (registerTime[1]) {
      params.registerEndTime = registerTime[1].endOf('day').valueOf();
    }
    if (updateTime[0]) {
      params.updateBeginTime = updateTime[0].startOf('day').valueOf();
    }
    if (updateTime[1]) {
      params.updateEndTime = updateTime[1].endOf('day').valueOf();
    }
    if (!proxyType) {
      params.proxyType = null;
    }
    return reaction.memberCenter.getQualificationList(params);
  };

  goToView = (record) => {
    if (record.certType === '1') {
      window.open(
        `//${location.host}/#/${RoutePath.MEMBERCENTER}/${RoutePath.QUALIFICATION_LIST}/${RoutePath.QUALIFICATION_DETAIL}?userId=${record.userId}&operationType=update_enterprise_cert`
      );
      // historyPush(`/${RoutePath.MEMBERCENTER}/${RoutePath.QUALIFICATION_LIST}/${RoutePath.QUALIFICATION_DETAIL}`, {userId: record.userId, operationType: 'update_enterprise_cert'});
    } else {
      window.open(
        `//${location.host}/#/${RoutePath.MEMBERCENTER}/${RoutePath.QUALIFICATION_LIST}/${RoutePath.PERSONAL_QUALIFICATION_DETAIL}?userId=${record.userId}&operationType=update_individual_cert`
      );
      // historyPush(`/${RoutePath.MEMBERCENTER}/${RoutePath.QUALIFICATION_LIST}/${RoutePath.PERSONAL_QUALIFICATION_DETAIL}`, {userId: record.userId, operationType: 'update_individual_cert'});
    }
  };

  goToModify = (record) => {
    if (record.certType === '1') {
      window.open(
        `//${location.host}/#/${RoutePath.MEMBERCENTER}/${RoutePath.QUALIFICATION_LIST}/${RoutePath.MODIFY_QUALIFICATION}?userId=${record.userId}&operationType=update_enterprise_cert`
      );
      // historyPush(`/${RoutePath.MEMBERCENTER}/${RoutePath.QUALIFICATION_LIST}/${RoutePath.MODIFY_QUALIFICATION}`, {userId: record.userId, operationType: 'update_enterprise_cert'});
    } else {
      window.open(
        `//${location.host}/#/${RoutePath.MEMBERCENTER}/${RoutePath.QUALIFICATION_LIST}/${RoutePath.MODIFY_PERSONAL_QUALIFICATION}?userId=${record.userId}&operationType=update_individual_cert`
      );
      // historyPush(`/${RoutePath.MEMBERCENTER}/${RoutePath.QUALIFICATION_LIST}/${RoutePath.MODIFY_PERSONAL_QUALIFICATION}`, {userId: record.userId, operationType: 'update_individual_cert'});
    }
  };

  onQueryChange = (name, value) => {
    let { query } = this.state;
    query = query.set(name, value);

    if (name === 'provinceCode') {
      query = query.set('cityCode', '');
      this.setState({
        cityDict: this.state.areaDict
          .filter((item) => item.type === '3')
          .filter((item) => item.parentId === value),
        // query
      });
    }
    this.setState({ query }, () => { });
  };

  doSearch = (e) => {
    let { query } = this.state;
    query = query.set('page', 1);
    this.setState({ query }, () => {
      this.getQualificationList().then((res) => {
        if (res.code === 0) {
          this.setState({
            userIdList: [],
          });
        }
      });
    });
    e && e.preventDefault();
  };

  handleTableChange = (pagination, filters, sorter) => {
    let { query } = this.state;
    query = query.set('page', pagination.current);
    query = query.set('length', pagination.pageSize);
    this.setState({ query }, () => {
      this.getQualificationList();
    });
  };

  clear = () => {
    let { query } = this.state;
    query = query.set('userNo', '');
    query = query.set('username', '');
    query = query.set('phone', '');
    query = query.set('active', '');
    query = query.set('authTime', []);
    query = query.set('registerTime', []);
    query = query.set('updateTime', []);
    query = query.set('proxyType', '');
    query = query.set('certSubject', '');
    query = query.set('isInternalUser', false);
    query = query.set('provinceCode', '');
    query = query.set('cityCode', '');
    query = query.set('auditor', '');
    query = query.set('certType', '');

    this.setState(
      {
        query,
        cityDict: [],
      },
      () => {
        // ......
      }
    );
  };

  exportItems = () => {
    let {
      userNo,
      certSubject,
      phone,
      active,
      username,
      authTime,
      registerTime,
      updateTime,
      proxyType,
      isInternalUser,
      provinceCode,
      cityCode,
      page,
      length,
      auditor,
      certType,
    } = this.state.query.toJS();
    let params = {
      userNo,
      certSubject,
      phone,
      active,
      username,
      proxyType,
      isInternalUser,
      provinceCode,
      cityCode,
      page,
      length,
      auditor,
      certType,
    };
    if (authTime[0]) {
      params.startTime = authTime[0].startOf('day').valueOf();
    }
    if (authTime[1]) {
      params.endTime = authTime[1].endOf('day').valueOf();
    }
    if (registerTime[0]) {
      params.registerBeginTime = registerTime[0].startOf('day').valueOf();
    }
    if (registerTime[1]) {
      params.registerEndTime = registerTime[1].endOf('day').valueOf();
    }
    if (updateTime[0]) {
      params.updateBeginTime = updateTime[0].startOf('day').valueOf();
    }
    if (updateTime[1]) {
      params.updateEndTime = updateTime[1].endOf('day').valueOf();
    }
    if (!proxyType) {
      params.proxyType = null;
    }
    if (!this.state.userIdList || this.state.userIdList.length === 0) {
      params.exportAll = true;
    } else {
      params.exportAll = false;
    }
    params.userIdList = this.state.userIdList;

    reaction.memberCenter.exportQualificationItems(params).then((res) => {
      if (res.data && res.data.url) {
        this.download(res.data.url, '资质列表');
      }
      return res;
    });
  };

  onPressEnter = () => {
    this.doSearch();
  };

  download = (fileKey, fileName) => {
    location.href = `/api/v1/common/download?token=${uamsdk.getToken()}&fileKey=${fileKey}&fileName=${encodeURIComponent(
      fileName
    )}`;
  };

  getAreaDict = () => {
    reaction.memberCenter.getAreaDict().then((res) => {
      if (res.code === 0) {
        this.setState({
          areaDict: res.data,
          provinceDict: res.data.filter((item) => item.type === '2'),
        });
      }
    });
  };

  componentDidMount() {
    this.getQualificationList();
    this.getCertAccountNumber();
    this.getAreaDict();
  }

  getColumns() {
    return [
      {
        title: '会员编号',
        dataIndex: 'userNo',
      },
      {
        title: '安全手机号',
        dataIndex: 'phone',
      },
      {
        title: '主体性质',
        dataIndex: 'certType',
        render: (text) => {
          if (text === '1') {
            return '企业';
          } else if (text === '2') {
            return '司机';
          }
          return '';
        },
      },
      {
        title: '主体名称',
        dataIndex: 'certSubject',
      },
      {
        title: '审核人',
        dataIndex: 'auditor',
      },
      {
        title: '认证时间',
        dataIndex: 'certTime',
        render: (text) => {
          if (!text) {
            return '';
          }
          return dayjs(text - 0).format('YYYY-MM-DD HH:mm:ss');
        },
      },
      {
        title: '注册时间',
        dataIndex: 'registerTime',
        render: (text) => {
          if (!text) {
            return '';
          }
          return dayjs(text - 0).format('YYYY-MM-DD HH:mm:ss');
        },
      },
      {},
      {
        title: '更新时间',
        dataIndex: 'updateTime',
        render: (text) => {
          if (!text) {
            return '';
          }
          return dayjs(text - 0).format('YYYY-MM-DD HH:mm:ss');
        },
      },
      {
        title: '操作',
        render: (text, record) => (
          <span>
            <Button
              onClick={() => {
                this.goToView(record);
              }}
            >
              查看
            </Button>
            &nbsp;
            <Button
              disabled={record.certType === '2' && true}
              onClick={() => {
                this.goToModify(record);
              }}
            >
              修改
            </Button>
          </span>
        ),
      },
    ];
  }

  handleToggle = () => {
    this.setState(({ showAll }) => {
      return {
        showAll: !showAll,
      };
    })
  }

  render() {
    let list = this.props.memberCenter.qualificationList;
    let certNumber = this.props.memberCenter.certNumber;
    const { query, provinceDict, cityDict, showAll } = this.state;
    let queryValue = query.toJS();

    const rowSelection = {
      selectedRowKeys: this.state.userIdList,
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({
          userIdList: selectedRowKeys,
        });
      },
      getCheckboxProps: (record) => ({
        disabled: false, // Column configuration not to be checked
        name: record.name,
      }),
    };
    return (
      <div>
        <div>
          <h2 className={commonStyles.row}>资质列表</h2>
          <p>
            目前已有资质
            <span style={{ color: 'red' }}>{certNumber.total}</span>{' '}
            位，今日新认证
            <span style={{ color: 'red' }}>{certNumber.today}</span> 位。
          </p>
        </div>
        <Form layout="inline" className="form-inline">
          {
            renderListControls([
              {
                children: (
                  <FormItem label="会员编号">
                    <Input
                      // style={{ width: 165 }}
                      onPressEnter={this.onPressEnter}
                      value={queryValue.userNo}
                      onChange={(e) => {
                        this.onQueryChange('userNo', e.target.value);
                      }}
                      placeholder="会员编号"
                    />
                  </FormItem>
                ),
              },
              {

                children: (
                  <FormItem label="手机号码">
                    <Input
                      // style={{ width: 165 }}
                      onPressEnter={this.onPressEnter}
                      value={queryValue.phone}
                      onChange={(e) => {
                        this.onQueryChange('phone', e.target.value);
                      }}
                      placeholder="手机号码"
                    />
                  </FormItem>
                )
              },
              {
                children: (
                  <FormItem label="认证主体">
                    <Input
                      // style={{ width: 165 }}
                      onPressEnter={this.onPressEnter}
                      value={queryValue.certSubject}
                      onChange={(e) => {
                        this.onQueryChange('certSubject', e.target.value);
                      }}
                      placeholder="认证主体"
                    />
                  </FormItem>
                )
              },
              {
                children: (
                  <FormItem label="审核人">
                    <Input
                      onPressEnter={this.onPressEnter}
                      value={queryValue.auditor}
                      onChange={(e) => {
                        this.onQueryChange('auditor', e.target.value);
                      }}
                      placeholder="审核人"
                    />
                  </FormItem>
                )
              },
              {
                children: (
                  <FormItem label="主体性质">
                    <Select
                      showSearch
                      placeholder="主体性质"
                      optionFilterProp="children"
                      value={queryValue.certType}
                      onChange={(value) => {
                        this.onQueryChange('certType', value);
                      }}
                    >
                      {
                        certTypeOptions.map(item => {
                          return (
                            <Option value={item.value} key={item.label}>{item.label}</Option>
                          );
                        })
                      }
                    </Select>
                  </FormItem>
                )
              },
              {
                children: (
                  <FormItem
                    label="内部账户"
                  >
                    <Select
                      showSearch
                      placeholder="内部账户"
                      // 解决控制台报警告的问题，因为antd不支持Boolean类型的值
                      value={queryValue.isInternalUser === null ? null : +queryValue.isInternalUser}
                      onChange={(v) => {
                        this.onQueryChange('isInternalUser', v === null ? null : Boolean(v));
                      }}
                    >
                      <Select.Option value={null}>全部</Select.Option>
                      <Select.Option value={1}>是</Select.Option>
                      <Select.Option value={0}>否</Select.Option>
                    </Select>
                  </FormItem>
                )
              },
              {
                visible: showAll,
                children: (
                  <FormItem
                    label="状态"
                  >
                    <Select
                      showSearch
                      // style={{ width: '130px' }}
                      placeholder="选择状态"
                      optionFilterProp="children"
                      value={queryValue.active}
                      onChange={(value) => {
                        this.onQueryChange('active', value);
                      }}
                    >
                      {
                        userActiveStatusOptions.map(item => {
                          return (
                            <Option key={item.label} value={item.value}>{item.label}</Option>
                          );
                        })
                      }
                    </Select>
                  </FormItem>
                )
              },
              {
                children: (
                  <FormItem label="认证时间">
                    <RangePicker
                      value={queryValue.authTime}
                      format={dateFormat}
                      locale={locale}
                      onChange={(value) => {
                        this.onQueryChange('authTime', value);
                      }}
                    />
                  </FormItem>
                )
              },
              {
                visible: showAll,
                children: (
                  <FormItem
                    label="省份"
                  >
                    <Select
                      showSearch
                      placeholder="选择省份"
                      optionFilterProp="children"
                      value={queryValue.provinceCode}
                      onChange={(value) => {
                        this.onQueryChange('provinceCode', value);
                      }}
                    >
                      <Select.Option value="">全部</Select.Option>
                      {provinceDict.map((province) => (
                        <Select.Option value={province.id} key={province.id}>{province.name}</Select.Option>
                      ))}
                    </Select>
                  </FormItem>
                )
              },
              {
                visible: showAll,
                children: (
                  <FormItem
                    label="城市"
                  >
                    <Select
                      showSearch
                      placeholder="选择城市"
                      optionFilterProp="children"
                      value={queryValue.cityCode}
                      onChange={(value) => {
                        this.onQueryChange('cityCode', value);
                      }}
                    >
                      <Select.Option value="">全部</Select.Option>
                      {cityDict.map((city) => (
                        <Select.Option value={city.id}>{city.name}</Select.Option>
                      ))}
                    </Select>
                  </FormItem>
                )
              },
              {
                visible: showAll,
                children: (
                  <FormItem
                    label="注册时间"
                  >
                    <RangePicker
                      value={queryValue.registerTime}
                      format={dateFormat}
                      locale={locale}
                      onChange={(value) => {
                        this.onQueryChange('registerTime', value);
                      }}
                    />
                  </FormItem>
                )
              },
              {
                visible: showAll,
                children: (
                  <FormItem
                    label="更新时间"
                  >
                    <RangePicker
                      value={queryValue.updateTime}
                      format={dateFormat}
                      locale={locale}
                      onChange={(value) => {
                        this.onQueryChange('updateTime', value);
                      }}
                    />
                  </FormItem>
                )
              },
              {
                full: true,
                colProps: showAll ? {
                  xxl: 24,
                  xl: 24,
                  md: 24,
                  lg: 24,
                } : {
                  xxl: 6,
                  xl: 16,

                },
                children: (
                  <FormItem align="right">
                    <Button
                      type="primary"
                      onClick={this.doSearch}
                      icon="search"
                    >
                      查询
                    </Button>
                    <Button
                      style={{ margin: '0 0 0 5px' }}
                      type="primary"
                      onClick={this.clear}
                      icon="redo"
                    >
                      清空
                    </Button>
                    <Button
                      style={{ margin: '0 0 0 5px' }}
                      type="primary"
                      onClick={this.exportItems}
                      icon="export"
                    >
                      按筛选条件导出
                    </Button>
                    <Button type="link" onClick={this.handleToggle} style={{ marginRight: -15 }}>
                      {this.state.showAll ? '收起' : '展开'}<Icon type={this.state.showAll ? 'up' : 'down'} />
                    </Button>
                  </FormItem>
                )
              }
            ])
          }
        </Form>
        {list && (
          <Table
            className={commonStyles.row}
            rowKey="userId"
            rowSelection={rowSelection}
            columns={this.getColumns()}
            dataSource={list.result}
            pagination={{
              current: list.pagerInfo.page,
              total: list.pagerInfo.total,
              pageSizeOptions: ['10', '20', '50'],
              showSizeChanger: true,
              showQuickJumper: true,
              showTotal: (total) => `共${total}条`,
            }}
            onChange={this.handleTableChange}
          />
        )}
      </div>
    );
  }
}

export default QualificationList;
