import createUID from 'utils/createUID';
import http from 'utils/http';
import {HttpCode} from 'utils/constants';

const LIST = createUID();
const DETAIL = createUID();
// const CREATE = createUID();
const DELETE = createUID();

const initialState = {
  list: {
    pagerInfo: {page: 1, total: 0},
    result: []
  }
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case LIST:
      return Object.assign({}, state, {list: action.data});
    case DETAIL:
      return Object.assign({}, state, {detail: action.data});
    case DELETE: {
      let roleId = action.data;
      return Object.assign({}, state, {
        list: Object.assign({}, state.list, {
          result: state.list.result.filter((data) => {
            return data.roleId !== roleId;
          })
        })
      });
    }
    default:
      return state;
  }
}

Object.assign(reducer, {
  getList(page = 1, length = 10, params) {
    return (dispatch) => {
      return http.request('v1.system.listRole', { page, length, ...params })
        .then((data) => {
          dispatch({type: LIST, data: data.data});
          return data;
        });
    };
  },
  getDetail(roleId) {
    return (dispatch) => {
      return http.request('v1.system.queryRoleDetail', {roleId})
        .then((data) => {
          dispatch({type: DETAIL, data: data.data});
          return data;
        });
    };
  },
  create(roleName, description, authCodeList) {
    return (dispatch) => {
      return http.request('v1.system.addRole', {roleName, description, authCodeList})
        .then((data) => {
          //dispatch({type: CREATE, data: data.data});
          return data;
        });
    };
  },
  delete(roleId) {
    return (dispatch) => {
      return http.request('v1.system.deleteRole', {roleId})
        .then((data) => {
          if (data.code === HttpCode.OK) {
            dispatch({type: DELETE, data: roleId});
          }
          return data;
        });
    };
  },
  update(roleId, roleName, description, authCodeList) {
    return (dispatch) => {
      return http.request('v1.system.updateRole', {roleId, roleName, description, authCodeList})
        .then((data) => {
          //dispatch({type: DELETE, data: data.data});
          return data;
        });
    };
  },
});
export default reducer;