import React from 'react';
import { connect } from 'react-redux';
import {
  Form,
  Select,
  Input,
  Button,
  message,
  Row,
  Col,
} from 'antd';
import commonStyles from 'style/common.module.scss';
import { HttpCode, AuthCodes, RoutePath } from 'utils/constants';
import { historyPush } from 'utils/history';
import reaction from 'reaction';

const FormItem = Form.Item;
const Option = Select.Option;

@Form.create()
@connect(({ common, me, authManagement }) => ({ common, me, authManagement }))
class AuthManagementAdd extends React.Component {
  state = {};

  componentDidMount() {
    this.getAuthList();
  }

  getAuthList = () => {
    let { roleAuthList } = this.props.me.roleInfo;
    let listEnbaled = roleAuthList.some((item) => {
      return (item.authCode.indexOf(AuthCodes.SYSTEM_AUTH_LIST) !== -1);
    });
    listEnbaled && reaction.authManagement.getAuthList({});
  };

  handleSubmit(e) {
    const { list } = this.props.authManagement;
    e && e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let { parentId, authCode, requestPath, displayName, fullName } = values;
        let platformId = parentId
          ? list.result.filter((item) => item.authId === parentId)[0].platformId
          : null;
        // email 统一规范为小写
        reaction.authManagement
          .create(
            authCode,
            requestPath,
            displayName,
            fullName,
            parentId,
            platformId
          )
          .then((data) => {
            if (data.code === HttpCode.OK) {
              message.success('添加成功！');
              historyPush(`/${RoutePath.SYSTEM}/${RoutePath.AUTH}`);
            }
          });
      }
    });
  }

  resetForm() {
    this.props.form.resetFields();
  }

  historyBack() {
    historyPush(`/${RoutePath.SYSTEM}/${RoutePath.AUTH}`);
  }

  render() {
    const { menuDict } = this.props.common;
    const { getFieldDecorator } = this.props.form;
    const { list } = this.props.authManagement;
    return (
      <div>
        <h2 className={commonStyles.row}> 权限新增 </h2>
        <Form
          onSubmit={this.handleSubmit.bind(this)}
          className={commonStyles.row}
        >
          <FormItem
            label="父级菜单"
            labelCol={{ span: 8 }}
            wrapperCol={{ sm: 8, xs: 24 }}
          >
            {getFieldDecorator('parentId', {
              rules: [{ required: false }],
            })(
              <Select optionFilterProp="children" showSearch>
                {list.result &&
                  list.result.map((auth) => {
                    return <Option value={auth.authId}>{auth.fullName}</Option>;
                  })}
              </Select>
            )}
          </FormItem>
          <FormItem
            label="权限名"
            labelCol={{ span: 8 }}
            wrapperCol={{ sm: 8, xs: 24 }}
          >
            {getFieldDecorator('fullName', {
              validateTrigger: ['onBlur', 'onChange'],
              rules: [
                { required: true, whitespace: true, message: '请输入权限名!' },
                {
                  type: 'string',
                  pattern: /^\S.{0,30}\S$/,
                  message: '权限名长度为2到32位!',
                },
              ],
            })(<Input placeholder="权限名" />)}
          </FormItem>
          <FormItem
            label="授权码"
            labelCol={{ span: 8 }}
            wrapperCol={{ sm: 8, xs: 24 }}
          >
            {getFieldDecorator('authCode', {
              validateTrigger: ['onBlur', 'onChange'],
              rules: [
                { required: true, whitespace: true, message: '请输入授权码!' },
                {
                  type: 'string',
                  pattern: /^\S.{0,128}\S$/,
                  message: '授权码长度为2到128位!',
                },
              ],
            })(<Input placeholder="授权码" />)}
          </FormItem>
          <FormItem
            label="权限地址"
            labelCol={{ span: 8 }}
            wrapperCol={{ sm: 8, xs: 24 }}
          >
            {getFieldDecorator('requestPath', {
              validateTrigger: ['onBlur', 'onChange'],
              rules: [
                {
                  required: false,
                  whitespace: true,
                  message: '请输入权限地址!',
                },
                {
                  type: 'string',
                  pattern: /^\S.{0,126}\S$/,
                  message: '权限地址长度为2到128位!',
                },
              ],
            })(<Input placeholder="权限地址" />)}
          </FormItem>
          <FormItem
            label="展示名"
            labelCol={{ span: 8 }}
            wrapperCol={{ sm: 8, xs: 24 }}
          >
            {getFieldDecorator('displayName', {
              validateTrigger: ['onBlur', 'onChange'],
              rules: [
                { required: true, whitespace: true, message: '请输入展示名!' },
                {
                  type: 'string',
                  pattern: /^\S.{0,14}\S$/,
                  message: '展示名长度为2到16位!',
                },
              ],
            })(<Input placeholder="展示名" />)}
          </FormItem>
          <FormItem
            wrapperCol={{ sm: { span: 8, offset: 8 }, xs: { span: 8 } }}
          >
            <Row>
              <Col span="8">
                <Button onClick={this.resetForm.bind(this)}> 重置 </Button>
              </Col>
              <Col span="8">
                <Button type="primary" htmlType="submit">
                  确定
                </Button>
              </Col>
              <Col span="8">
                <Button onClick={this.historyBack.bind(this)}> 返回 </Button>
              </Col>
            </Row>
          </FormItem>
        </Form>
      </div>
    );
  }
}

export default AuthManagementAdd;
