import { certTypes, userActiveStatus } from './constant'; /**
 * 前台项目本地开发时用的平台代码
 */
export var localPlatformCode = 'local'; /**
* 其他平台后台管理链接
*/ // export const platformManagementList = [
//   {
//     platformName: 'CMS后台',
//     platformCode: 'cms',
//     platformLinkPath: '',
//   },
//   {
//     platformName: '物流后台',
//     platformCode: 'log',
//     platformLinkPath: '/admin',
//   },
//   {
//     platformName: '交易后台',
//     platformCode: 'trade',
//     platformLinkPath: '/admin',
//   },
//   {
//     platformName: '金融后台',
//     platformCode: 'fin',
//     platformLinkPath: '/admin',
//   },
//   {
//     platformName: '集采后台',
//     platformCode: 'jc|ore',
//     platformLinkPath: '/admin',
//   }
// ];
export var driverIdentityCode = 'logistics_driver'; /**
 * 拒绝或者驳回类文字原因最大字符限制
 */
export var REJECT_REASON_MAX_LENGTH = 100; /**
* 认证主体性质
*/
export var certTypeOptions = [{ label: '全部', value: '' }, { label: '司机', value: certTypes.person }, { label: '企业', value: certTypes.company }]; /**
* 用户禁用状态
*/
export var userActiveStatusOptions = [{ label: '全部', value: '' }, { label: '正常', value: userActiveStatus.enable }, { label: '禁用', value: userActiveStatus.disabled }]; /**
* 审核结果
*/
export var certAuditResultOptions = [{ label: '全部', value: '' }, { label: '审核通过', value: '审核通过' }, { label: '审核拒绝', value: '审核拒绝' }];
