import React from 'react';
import { connect } from 'react-redux';
import { Form, Select, Input, Button, message } from 'antd';
import createUID from 'utils/createUID';
import reaction from 'reaction';
import history from 'utils/history';
import { resolveUrlQuery } from 'utils';
import { HttpCode, RoutePath } from 'utils/constants';

import commonStyles from 'style/common.module.scss';

const FormItem = Form.Item;
// const Option = Select.Option;

/**
 * 平台修改
 */
@connect(({ platform }) => ({ platform }))
@Form.create()
class PlatformModify extends React.Component {
  componentDidMount() {
    // const {platformId, platformCode, platformName, visitPath, platformSecret, platformAbbreviation} = resolveUrlQuery(this.props.location.search);
    // reaction.platform.getDetail(platformId);
  }

  createPlatformSecret = () => {
    this.props.form.setFieldsValue({
      platformSecret: createUID(),
    });
  };
  handleSubmit = (e) => {
    e && e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { platformId } = resolveUrlQuery(this.props.location.search);
        let {
          platformCode,
          platformName,
          visitPath,
          platformSecret,
          platformAbbreviation,
        } = values;
        reaction.platform
          .update(
            platformId,
            platformCode,
            platformName,
            visitPath,
            platformSecret,
            platformAbbreviation
          )
          .then((data) => {
            if (data.code === HttpCode.OK) {
              message.success('修改成功！', 1.5, () => {
                history.push(`/${RoutePath.SYSTEM}/${RoutePath.PLATFORM}`);
              });
              reaction.common.getPlatformDict(); // 刷新平台字典
            }
          });
      }
    });
  };

  render() {
    const {
      platformCode,
      platformName,
      visitPath,
      platformSecret,
      platformAbbreviation,
    } = resolveUrlQuery(this.props.location.search);
    // const {detail} = this.props.platform;
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        <h2 className={commonStyles.row}>业务系统修改</h2>
        {
          <div>
            <Form onSubmit={this.handleSubmit} className={commonStyles.row}>
              <FormItem
                label="平台代码"
                labelCol={{ span: 8 }}
                wrapperCol={{ sm: 8, xs: 24 }}
              >
                {getFieldDecorator('platformCode', {
                  validateTrigger: ['onBlur', 'onChange'],
                  initialValue: platformCode,
                  rules: [
                    {
                      required: true,
                      whitespace: true,
                      message: '请输入业平台代码',
                    },
                    {
                      pattern: /^[a-zA-Z0-9-]{2,32}$/,
                      message: '业平台代码长度为2到32位!',
                    },
                  ],
                })(<Input placeholder="业务系统名称" />)}
              </FormItem>
              <FormItem
                label="业务系统"
                labelCol={{ span: 8 }}
                wrapperCol={{ sm: 8, xs: 24 }}
              >
                {getFieldDecorator('platformName', {
                  validateTrigger: ['onBlur', 'onChange'],
                  initialValue: platformName,
                  rules: [
                    {
                      required: true,
                      whitespace: true,
                      message: '请输入业务系统名称',
                    },
                    {
                      pattern: /^\S.{0,30}\S$/,
                      message: '业务系统名称长度为2到32位!',
                    },
                  ],
                })(<Input placeholder="业务系统名称" />)}
              </FormItem>
              <FormItem
                label="域名"
                labelCol={{ span: 8 }}
                wrapperCol={{ sm: 8, xs: 24 }}
              >
                {getFieldDecorator('visitPath', {
                  validateTrigger: ['onBlur', 'onChange'],
                  initialValue: visitPath,
                  rules: [
                    {
                      required: true,
                      whitespace: true,
                      message: '请输入业务系统域名!',
                    },
                    // {pattern:/^([^.]+\.)+[a-zA-Z]{1,5}[a-zA-Z]$/, message: '请输入正确的域名（如：mall.zlgx.com）!' }],
                    {
                      pattern: /[*]{0,100}/g,
                      message: '请输入正确的域名（如：mall.zlgx.com）!',
                    },
                  ],
                })(<Input placeholder="域名（如：mall.zlgx.com）" />)}
              </FormItem>
              <FormItem
                label="平台密钥"
                style={{ margin: 0 }}
                labelCol={{ span: 8 }}
                wrapperCol={{ sm: 8, xs: 24 }}
              >
                {getFieldDecorator('platformSecret', {
                  validateTrigger: ['onBlur', 'onChange'],
                  initialValue: platformSecret,
                  rules: [
                    {
                      required: true,
                      whitespace: true,
                      message: '请输入平台秘钥!',
                    },
                    // {pattern:/^([^.]+\.)+[a-zA-Z]{1,5}[a-zA-Z]$/, message: '请输入正确的域名（如：mall.zlgx.com）!' }],
                    {
                      pattern: /^[^\s]{2,100}$/,
                      message: '请输入正确的域名（如：mall.zlgx.com）!',
                    },
                  ],
                })(<Input placeholder="域名（如：mall.zlgx.com）" />)}
              </FormItem>
              <FormItem
                wrapperCol={{ sm: { span: 8, offset: 8 }, xs: { span: 8 } }}
              >
                <Button onClick={this.createPlatformSecret}>
                  生成平台秘钥
                </Button>
              </FormItem>
              <FormItem
                label="平台简称"
                labelCol={{ span: 8 }}
                wrapperCol={{ sm: 8, xs: 24 }}
              >
                {getFieldDecorator('platformAbbreviation', {
                  validateTrigger: ['onBlur', 'onChange'],
                  initialValue: platformAbbreviation,
                  rules: [
                    {
                      required: true,
                      whitespace: true,
                      message: '请输入平台简称!',
                    },
                    // {pattern:/^([^.]+\.)+[a-zA-Z]{1,5}[a-zA-Z]$/, message: '请输入正确的域名（如：mall.zlgx.com）!' }],
                    {
                      pattern: /^[^\s]{2,32}$/,
                      message: '请输入正确的平台简称!',
                    },
                  ],
                })(<Input placeholder="域名（如：mall.zlgx.com）" />)}
              </FormItem>
              <FormItem
                wrapperCol={{ sm: { span: 8, offset: 8 }, xs: { span: 8 } }}
              >
                <Button type="primary" htmlType="submit">
                  确定
                </Button>
              </FormItem>
            </Form>
          </div>
        }
      </div>
    );
  }
}

export default PlatformModify;
