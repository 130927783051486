import React from 'react';
import { connect } from 'react-redux';
import { RoutePath, AuthCodes } from 'utils/constants';
import reaction from 'reaction';
import { HttpCode } from 'utils/constants';
import { historyPush } from 'utils/history';
import { Table, Button, Input, Popconfirm, Form, message } from 'antd';

import commonStyles from 'style/common.module.scss';
import { withRouter } from 'react-router-dom';

/**
 * 黑名单管理
 */
@connect(({ blacklist, common, me }) => ({ blacklist, common, me }))
@withRouter
class Blacklist extends React.Component {
  state = {
    keywords: '',
    currentPage: 1,
  };
  componentDidMount() {
    let { roleAuthList } = this.props.me.roleInfo;
    let listEnbaled = roleAuthList.some((item) => {
      return item.authCode.indexOf(AuthCodes.PERMISSION_BLACKLIST_LIST) !== -1;
    });
    listEnbaled && reaction.blacklist.getList();
  }
  handleTableChange = (pagination, filters, sorter) => {
    this.setState({
      currentPage: pagination.current,
    });
    reaction.blacklist.getList(this.state.keywords, pagination.current);
  };
  goEdit = (record, e) => {
    historyPush(`${RoutePath.MODIFY}`, { uid: record.uid });
  };
  doSearch = (e) => {
    e.preventDefault();
    reaction.blacklist.getList(this.state.keywords);
  };
  onChange = (e) => {
    this.setState({ keywords: e.target.value });
  };
  doAdd = (e) => {
    historyPush(`${RoutePath.ADD}`);
  };
  doDelete = (record, e) => {
    const { list } = this.props.blacklist;
    const length = list.result.length;
    reaction.blacklist.delete(record.uid).then((data) => {
      if (data.code === HttpCode.OK) {
        message.success('删除成功！');
        if (length === 1 && this.state.currentPage > 1) {
          reaction.blacklist.getList(
            this.state.keywords,
            this.state.currentPage - 1
          );
        } else {
          reaction.blacklist.getList(
            this.state.keywords,
            this.state.currentPage
          );
        }
      }
    });
  };
  getColumns({ list, platformDict, editEnbaled, deleteEnbaled }) {
    return [
      {
        title: '序号',
        dataIndex: 'uid',
        render: (text, record, index) =>
          (list.pagerInfo.page - 1) * 10 + index + 1,
      },
      {
        title: '用户名',
        dataIndex: 'userName',
      },
      {
        title: '手机号',
        dataIndex: 'phone',
      },
      {
        title: '邮箱',
        dataIndex: 'email',
      },
      {
        title: '不可操作业务系统',
        dataIndex: 'forbidPlatformId',
        render: (text, record) => text.map((id) => platformDict[id]).join('，'),
      },
      {
        title: '操作',
        render: (text, record) => (
          <Button.Group>
            <Button
              icon="edit"
              onClick={this.goEdit.bind(this, record)}
              disabled={!editEnbaled}
            >
              修改
            </Button>
            {deleteEnbaled ? (
              <Popconfirm
                title="确定要删除？"
                onConfirm={this.doDelete.bind(this, record)}
                okText="确定"
                cancelText="取消"
              >
                <Button icon="delete" type="danger">
                  删除
                </Button>
              </Popconfirm>
            ) : (
              <Button icon="delete" type="danger" disabled>
                删除
              </Button>
            )}
          </Button.Group>
        ),
      },
    ];
  }
  render() {
    let { roleAuthList } = this.props.me.roleInfo;
    let addEnbaled = roleAuthList.some((item) => {
      return item.authCode.indexOf(AuthCodes.PERMISSION_BLACKLIST_ADD) !== -1;
    });
    let deleteEnbaled = roleAuthList.some((item) => {
      return item.authCode.indexOf(AuthCodes.PERMISSION_BLACKLIST_DELETE) !== -1;
    });
    let editEnbaled = roleAuthList.some((item) => {
     return item.authCode.indexOf(AuthCodes.PERMISSION_BLACKLIST_UPDATE) !== -1;
    });

    const { list } = this.props.blacklist;
    const { platformDict } = this.props.common;

    return (
      <div className="user">
        <h2 className={commonStyles.row}>黑名单管理</h2>
        <Form onSubmit={this.doSearch} className={commonStyles.row}>
          <Input.Group compact>
            <Input
              placeholder="通过用户名/手机号/邮箱查询"
              style={{ width: 300, float: 'left' }}
              onChange={this.onChange}
            />
            <Button icon="search" htmlType="submit">
              搜索
            </Button>
          </Input.Group>
        </Form>
        <Button icon="plus" onClick={this.doAdd} disabled={!addEnbaled}>
          新增
        </Button>
        {list && (
          <Table
            className={commonStyles.row}
            rowKey="userName"
            columns={this.getColumns({ platformDict, list, deleteEnbaled, editEnbaled })}
            dataSource={list.result}
            pagination={{
              current: list.pagerInfo.page,
              total: list.pagerInfo.total,
            }}
            onChange={this.handleTableChange}
          />
        )}
      </div>
    );
  }
}
export default Blacklist;
