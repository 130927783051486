import React from 'react';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import Immutable from 'immutable';
import reaction from 'reaction';
import { Table, Input, Modal, message, Form, Select } from 'antd';
import { historyPush } from 'utils/history';
import { RoutePath } from 'utils/constants';
import PersonEditor from 'modules/PersonEditor';
import { REJECT_REASON_MAX_LENGTH } from 'config';
import { parseDate, parseFileUrl, parseFileUrl2 } from 'utils';
import { resolveUrlQuery, getUniqTableRowKey } from 'utils';

import commonStyles from 'style/common.module.scss';

const FormItem = Form.Item;
const { Option } = Select;
const { TextArea } = Input;

// Review: 资质审核 - 司机 - 补全

@Form.create()
@connect(({ qualification, memberCenter, me, frontUser, common }) => ({
  qualification,
  memberCenter,
  me,
  frontUser,
  common,
}))
class ComplementPersonalQualification extends React.Component {
  state = {
    query: Immutable.Map({
      page: 1,
      length: 10,
    }),

    showRefuseModal: false,
    reason: '',

    initialValue: null,
  };

  getPersonalQualificationInfo = () => {
    let userId = resolveUrlQuery(this.props.location.search).userId;
    return reaction.qualification
      .getPersonalQualificationInfo({ userId })
      .then(({ code, data }) => {
        if (code !== 0) return {};

        const { additionalCert, attachment, baseInfo, identity = [], addFundsAccount } = data;
        const { identityCardFrontUrl, identityCardBackUrl } = attachment;
        const { idCardValidTime, idCardExpirationTime } = baseInfo;
        let initialValue = { additionalCert, attachment, baseInfo, identityList: identity || [], addFundsAccount };

        initialValue.identityCardFrontUrl = parseFileUrl(
          identityCardFrontUrl,
          'identityCardFrontUrl'
        );
        initialValue.identityCardBackUrl = parseFileUrl(
          identityCardBackUrl,
          'identityCardBackUrl'
        );

        initialValue.idCardTime = [
          parseDate(idCardValidTime),
          parseDate(idCardExpirationTime),
        ];

        if (additionalCert && additionalCert.vehicleInfo) {
          const {
            vehicleInfo: {
              runningRegisterDate,
              runningIssueDate,
              drivingLicenseValidTime,
              drivingLicenseExpirationTime,
              drivingLicenseUrl,
              drivingLicenseSubPage,
              runningLicenseUrl,
              runningLicenseSubPage,
              transportBusinessLicenseUrl,
              transportBusinessQualificationUrl,
              runningLicenseValidityUrl,
              semiDrivingLicenseValidityUrl,
              semiDrivingLicenseUrl,
              semiDrivingLicenseSubPageUrl,
              runningLicenseValidityData,
              semiDrivingLicenseValidityData
            },
          } = additionalCert;

          initialValue.runningRegisterDate = parseDate(runningRegisterDate);
          initialValue.runningIssueDate = parseDate(runningIssueDate);
          initialValue.runningLicenseValidityData = parseDate(runningLicenseValidityData);
          initialValue.semiDrivingLicenseValidityData = parseDate(semiDrivingLicenseValidityData);

          initialValue.drivingLicenseTime = [
            parseDate(drivingLicenseValidTime),
            parseDate(drivingLicenseExpirationTime),
          ];


          initialValue.drivingLicenseUrl = parseFileUrl(
            drivingLicenseUrl,
            'drivingLicenseUrl'
          );
          initialValue.drivingLicenseSubPage = parseFileUrl(
            drivingLicenseSubPage,
            'drivingLicenseSubPage'
          );
          initialValue.runningLicenseUrl = parseFileUrl(
            runningLicenseUrl,
            'runningLicenseUrl'
          );
          initialValue.runningLicenseSubPage = parseFileUrl(
            runningLicenseSubPage,
            'runningLicenseSubPage'
          );
          initialValue.transportBusinessLicenseUrl = parseFileUrl(
            transportBusinessLicenseUrl,
            'transportBusinessLicenseUrl'
          );
          initialValue.transportBusinessQualificationUrl = parseFileUrl(
            transportBusinessQualificationUrl,
            'transportBusinessQualificationUrl'
          );
          initialValue.runningLicenseValidityUrl = parseFileUrl2(
            runningLicenseValidityUrl,
            'runningLicenseValidityUrl'
          );
          initialValue.semiDrivingLicenseValidityUrl = parseFileUrl2(
            semiDrivingLicenseValidityUrl,
            'semiDrivingLicenseValidityUrl'
          );
          initialValue.semiDrivingLicenseUrl = parseFileUrl2(
            semiDrivingLicenseUrl,
            'semiDrivingLicenseUrl'
          );
          initialValue.semiDrivingLicenseSubPageUrl = parseFileUrl2(
            semiDrivingLicenseSubPageUrl,
            'semiDrivingLicenseSubPageUrl'
          );
        }

        this.setState({ initialValue });
        return data;
      });
  };

  getOperationHistory = () => {
    let { page, length } = this.state.query.toJS();
    return reaction.qualification.getPersonalQualificationOperationHistory({
      userId: resolveUrlQuery(this.props.location.search).userId,
      page,
      length,
      operationType: resolveUrlQuery(this.props.location.search).operationType,
    });
  };

  handleSubmit = (values) => {
    const info = this.props.qualification.personalQualificationDetailInfo;
    const params = { ...info, ...values };
    params.salesman = this.props.form.getFieldValue('salesman');
    params.department = this.props.form.getFieldValue('department');
    params.team = this.props.form.getFieldValue('team');
    reaction.qualification
      .supplementPersonalQualification(params)
      .then((res) => {
        if (res.code === 0) {
          message.success('保存成功');
          historyPush(
            `/${RoutePath.MEMBERCENTER}/${RoutePath.CHECK_QUALIFICATION_LIST}`
          );
        }
      });
  };

  pass = (values, validate) => {
    if (!validate) return;

    const info = this.props.qualification.personalQualificationDetailInfo;
    const params = { ...info, ...values };
    params.salesman = this.props.form.getFieldValue('salesman');
    params.department = this.props.form.getFieldValue('department');
    params.team = this.props.form.getFieldValue('team');
    reaction.qualification
      .makePersonalQualificationOkOfComplement(params)
      .then((res) => {
        if (res.code === 0) {
          message.success('审核成功');
          historyPush(
            `/${RoutePath.MEMBERCENTER}/${RoutePath.CHECK_QUALIFICATION_LIST}`
          );
        }
      });
  };

  refuse = (e) => {
    e && e.preventDefault();
    let userId = resolveUrlQuery(this.props.location.search).userId;
    let reason = this.state.reason;
    if (!reason) {
      message.warning('请输入审核意见再提交!');
      return;
    }
    reaction.qualification
      .refusePersonalQualification({ userId, reason })
      .then((res) => {
        if (res.code === 0) {
          message.success('审核拒绝成功');
          historyPush(
            `/${RoutePath.MEMBERCENTER}/${RoutePath.CHECK_QUALIFICATION_LIST}`
          );
        }
      });
  };

  showRefuseModal = () => {
    this.setState({
      showRefuseModal: true,
    });
  };
  cancelRefuse = () => {
    this.setState({
      showRefuseModal: false,
      reason: '',
    });
  };

  back = () => {
    historyPush(
      `/${RoutePath.MEMBERCENTER}/${RoutePath.CHECK_QUALIFICATION_LIST}`,
      {
        tabType: 'toComplete',
      }
    );
  };

  async componentDidMount() {
    await this.getOperationHistory();
    const certInfo = await this.getPersonalQualificationInfo() || {};
    certInfo.departmentId && this.fetchDepartmentTeamList(certInfo.departmentId);
  }

  handleDepartmentChange = (_, { props }) => {
    this.fetchDepartmentTeamList(props.id);
    this.setFieldsValue('team', '');
  }

  fetchDepartmentTeamList(id) {
    return reaction.frontUser.getDepartmentTeamList({
      parentId: id,
    });
  }

  hasSelectDepartment() {
    return !!this.props.form.getFieldValue('department');
  }

  setFieldsValue(name, value) {
    this.props.form.setFieldsValue({
      [name]: value,
    })
  }
  getColumns() {
    return [
      {
        width: 112,
        label: '时间',
        dataIndex: 'updateTime',
        render: (text) => {
          if (!text) {
            return '';
          }
          return dayjs(text - 0).format('YYYY-MM-DD HH:mm:ss');
        },
      },
      {
        width: 140,
        label: '操作人',
        dataIndex: 'operator',
      },
      {
        label: '修改内容',
        dataIndex: 'updateContent',
        render: (text) => {
          return <pre style={{ maxWidth: '1000px' }}>{text}</pre>;
        },
      },
    ];
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    const { departments = [] } = this.props.me;
    const { teamList = [], teamListLoading } = this.props.frontUser;

    let qualificationDetailInfo = this.props.qualification
      .personalQualificationDetailInfo;
    let formItemLayout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 20 },
    };
    const operates = [
      {
        text: '返回',
        func: this.back,
      },
      {
        text: '保存',
        validate: true,
        func: this.handleSubmit,
      },
      {
        text: '审核拒绝',
        func: this.showRefuseModal,
      },
      {
        text: '审核通过',
        validate: true,
        func: this.pass,
      }
    ];

    let list = this.props.qualification.personalQualificationOperationHistory;
    return (
      <div className="person-info">
        <h2 className={commonStyles.row}>资质信息补全</h2>
        <div style={{ overflow: 'hidden' }}>
          <div style={{ float: 'left', width: 100 }}>账户信息</div>
          <div style={{ marginLeft: 100 }}>
            <div style={{ marginBottom: 20 }}>
              <label
                className="form-item-label-item"
              >
                会员编号：
              </label>
              <Input
                disabled={true}
                value={qualificationDetailInfo.user.userNo}
                style={{ width: 250 }}
              />
            </div>
            <div style={{ marginBottom: 20 }}>
              <label
                className="form-item-label-item"
              >
                用户名：
              </label>
              <Input
                disabled={true}
                value={qualificationDetailInfo.user.username}
                style={{ width: 250 }}
              />
            </div>
            <div style={{ marginBottom: 20 }}>
              <label
                className="form-item-label-item"
              >
                安全手机：
              </label>
              <Input
                disabled={true}
                value={qualificationDetailInfo.user.phone}
                style={{ width: 250 }}
              />
            </div>
            <Form>
              <FormItem {...formItemLayout} label="责任部门">
                {getFieldDecorator('department', {
                  rules: [{ required: false, message: '请选择责任部门' }],
                  initialValue: qualificationDetailInfo.department || '',
                })(
                  <Select style={{ width: 250 }} onChange={this.handleDepartmentChange}>
                    {departments.map((item) => {
                      return (
                        <Option key={item.code} value={item.code || ''} id={item.id}>
                          {item.name}
                        </Option>
                      );
                    })}
                  </Select>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="小组">
                {getFieldDecorator('team', {
                  validateTrigger: ['onBlur', 'onChange'],
                  initialValue: qualificationDetailInfo.team || '',
                })(
                  <Select style={{ width: 250 }} disabled={!this.hasSelectDepartment()} loading={teamListLoading}>
                    {teamList.map((item) => {
                      return (
                        <Option key={item.code} value={item.code}>
                          {item.name}
                        </Option>
                      );
                    })}
                  </Select>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="业务员">
                {getFieldDecorator('salesman', {
                  validateTrigger: ['onBlur', 'onChange'],
                  rules: [
                    {
                      required: false,
                      whitespace: true,
                      message: '请输入业务员',
                    },
                  ],
                  initialValue: qualificationDetailInfo.salesman || '',
                })(
                  <Input
                    maxLength={5}
                    style={{ width: 250 }}
                    placeholder="请输入业务员"
                  />
                )}
              </FormItem>
            </Form>
          </div>
        </div>

        {
          this.state.initialValue ? (
            <React.Fragment>
              <PersonEditor
                initialValue={this.state.initialValue}
                isEdit={true}
                operates={operates}
                showFundsPicker
              />

              <div style={{ padding: '0 0 50px 0' }}>
                {list && (
                  <Table
                    className={commonStyles.row}
                    rowKey={getUniqTableRowKey('operator', list.pagerInfo)}
                    columns={this.getColumns()}
                    dataSource={list.result}
                  />
                )}
              </div>
            </React.Fragment>
          ) : null
        }

        <Modal
          title="提示"
          width={400}
          visible={this.state.showRefuseModal}
          onOk={this.refuse}
          onCancel={this.cancelRefuse}
        >
          <h4>请备注拒绝原因:</h4>
          <TextArea
            value={this.state.reason}
            rows={5}
            onChange={(e) => {
              this.setState({ reason: e.target.value });
            }}
            maxLength={REJECT_REASON_MAX_LENGTH}
            placeholder={`不限类型，${REJECT_REASON_MAX_LENGTH}字以内`}
          />
        </Modal>
      </div>
    );
  }
}

export default ComplementPersonalQualification;
