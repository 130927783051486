import createUID from 'utils/createUID';
import http from 'utils/http';

const LIST = createUID();
const DETAIL = createUID();
const UPDATE = createUID();
const CREATE = createUID();
const DELETE = createUID();

const initialState = {
  list: [],
  detail: {}
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case LIST:
      return Object.assign({}, state, {list: action.data});
    case DETAIL:
      return Object.assign({}, state, {detail: action.data});
    // case DELETE: {
    //   let platformId = action.data;
    //   return Object.assign({}, state, {
    //     list: Object.assign({}, state, {
    //       list: state.list.filter((data) => {
    //               return data.platformId !== platformId;
    //             })
    //       }
    //     )
    //   });
    // }
    default:
      return state;
  }
}

Object.assign(reducer, {
  getList() {
    return (dispatch) => {
      return http.request('v1.system.listPlatform')
        .then((data) => {
          dispatch({type: LIST, data: data.data});
          return data;
        });
    };
  },
  getDetail(platformId) {
    return (dispatch) => {
      return http.request('v1.system.queryPlatformDetail', {platformId})
        .then((data) => {
          dispatch({type: DETAIL, data: data.data});
          return data;
        });
    };
  },
  create(platformCode, platformName, visitPath, platformSecret, platformAbbreviation) {
    return (dispatch) => {
      return http.request('v1.system.addPlatform', {platformCode, platformName, visitPath, platformSecret, platformAbbreviation})
        .then((data) => {
          dispatch({type: CREATE, data: data.data});
          return data;
        });
    };
  },
  delete(platformId) {
    return (dispatch) => {
      return http.request('v1.system.deletePlatform', {platformId})
        .then((data) => {
          // dispatch({type: DELETE, data: platformId});
          return data;
        });
    };
  },
  update(platformId, platformCode, platformName, visitPath, platformSecret, platformAbbreviation) {
    return (dispatch) => {
      return http.request('v1.system.updatePlatform', {platformId, platformCode, platformName, visitPath, platformSecret, platformAbbreviation})
        .then((data) => {
          dispatch({type: UPDATE, data: data.data});
          return data;
        });
    };
  },
});
export default reducer;