import React from 'react';
import { Form, Input, Select } from 'antd';

const EditableContext = React.createContext();
const FormItem = Form.Item;
const Option = Select.Option;

const location = window.location;

@Form.create()
class EditableCell extends React.Component {
  state = {
  
  };
  
  componentDidMount() {
  
  }

  componentWillUnmount() {
  
  }
  
  save = () => {
    setTimeout(() => {
      const { record, handleSave } = this.props;
      this.props.form.validateFields((error, values) => {
        if (error) {
          return;
        }
        handleSave({ ...record, ...values });
      });
    }, 0);
  };
  
  render() {
    const {
      editable,
      dataIndex,
      title,
      record,
      type,
      options,
      selectedValues = [],
      required,
      pattern,
      message,
      ...restProps
    } = this.props;
    return (
      <td ref={node => (this.cell = node)} {...restProps}>
        {editable ? (
          <EditableContext.Consumer>
            {() => {
              return (
                <FormItem style={{ margin: 0 }}>
                  {this.props.form.getFieldDecorator(dataIndex, {
                    validateTrigger: ['onBlur', 'onChange'],
                    rules: [{
                      required,
                      pattern,
                      message,
                    }],
                    initialValue: record[dataIndex],
                  })(
                    type === 'inputSelect' ?
                      <Select
                        style={{width: '100%'}}
                        showSearch
                        placeholder="请选择"
                        onChange={ this.save }
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      >
                        {
                          options.map(item => {
                            return (<Option disabled={ selectedValues.indexOf(typeof item === 'string' ? item : item.id) !== -1 } key={ typeof item === 'string' ? item : item.id } value={ typeof item === 'string' ? item : item.id }>{ typeof item === 'string' ? item : (item.companyName || item.name) }</Option>);
                          })
                        }
                      </Select>
                        :
                      <Input
                        ref={node => (this.input = node)}
                        onPressEnter={ this.save }
                        onBlur={ this.save }
                      />
                  )}
                </FormItem>
              );
            }}
          </EditableContext.Consumer>
        ) : restProps.children}
      </td>
    );
  }
}

export default EditableCell;