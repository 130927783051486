import React from 'react';
import { connect } from 'react-redux';
import reaction from 'reaction';
import {
  Input,
  Card,
  Table,
  Button,
  Icon,
  Popconfirm,
  InputNumber,
  Form,
  Select,
  DatePicker,
  Modal,
  Checkbox,
  message,
} from 'antd';
import dayjs from 'dayjs';
import Immutable from 'immutable';

const FormItem = Form.Item;
const PREFFIX_KEY = 'key-';
const dateFormat = 'YYYY-MM-DD';
const ROW_PREFFIX = 'row-';

/**
 * 大屏
 */
@Form.create()
@connect(({ screen }) => ({ screen }))
class Screen extends React.Component {
  state = {
    priceList: [],
    priceItem: Immutable.Map({
      date: '',
      twistedSteel: '',
      structuralSteel: '',
      mediumPlate: '',
      rollingPlate: '',
      elevatedSteel: '',
    }),
    currentItem: '',
  };

  addItem = () => {
    const { priceList } = this.state;

    let maxDate = priceList.reduce(function(a, b) {
      return a.date > b.date ? a : b;
    }, priceList[0] || {});

    let nextDate = dayjs(maxDate.date).add(1, 'days');
    priceList.unshift({
      date: nextDate.format(dateFormat),
      key: PREFFIX_KEY + new Date().getTime(),
    });

    this.setState({ priceList }, () => {});
  };

  exportData = () => {
    reaction.screen.exportData();
  };

  isEditable = (key) => {
    return (
      key !== null && typeof key === 'string' && key.startsWith(PREFFIX_KEY)
    );
  };

  removeItem = (key) => {
    //删除正在编辑的价格
    if (this.isEditable(key)) {
      message.warning('删除当前未保存的价格');
      const { priceList } = this.state;
      this.setState(
        { priceList: priceList.filter((e) => e.key !== key) },
        () => {}
      );
      return;
    }

    //删除已保存的价格
    let { priceList } = this.state;
    this.setState(
      { priceList: priceList.filter((e) => e.key !== key) },
      () => {}
    );

    reaction.screen.removePriceTrendItem(key).then((res) => {
      if (res.code === 0) {
        message.success('删除成功');

        let { priceList } = this.state;
        //重新获取价格
        reaction.screen.loadPriceTrend().then((res) => {
          let { priceTrendList } = this.props.screen;
          if (res.code === 0) {
            const { length } = priceList.filter(
              (item) => !this.isEditable(item.key)
            );
            let item2Add = priceTrendList[length];
            item2Add.key = item2Add.id;
            priceList.push(item2Add);
            priceList.sort((a, b) => dayjs(b.date) - dayjs(a.date));
            this.setState({ priceList: priceList }, () => {});
          }
        });
      } else {
        message.error('删除失败');
      }
    });
    this.setState(
      { priceList: priceList.filter((e) => e.key !== key) },
      () => {}
    );
  };

  getCurrentItem = (key) => {
    const { priceList } = this.state;
    return priceList.find((item) => item.key === key);
  };

  getCurrentItemIndex = (key) => {
    const { priceList } = this.state;
    return priceList.findIndex((item) => item.key === key);
  };

  saveItem(item) {
    this.props.form.validateFieldsAndScroll([item], (err, values) => {
      if (!err) {
        const { date, ...data } = values[item];
        const requestParam = {
          date: date.format(dateFormat),
          ...data,
        };

        reaction.screen.addPriceTrendItem(requestParam).then((res) => {
          if (res.code === 0) {
            message.success('添加成功');
            const oldKey = item.replace(/row-/, '');
            const newKey = res.data;
            const { priceList } = this.state;
            let index = this.getCurrentItemIndex(oldKey);
            priceList[index] = { ...requestParam, key: newKey };
            priceList.sort((a, b) => dayjs(b.date) - dayjs(a.date));
            this.setState({ priceList: priceList });
          }
        });
      }
    });
  }

  loadPriceList = () => {
    reaction.screen.loadPriceTrend().then((res) => {
      let { priceTrendList } = this.props.screen;
      if (res.code === 0) {
        priceTrendList = priceTrendList.slice(0, 10);
        priceTrendList = priceTrendList.map((elem) => ({
          ...elem,
          key: elem.id,
        }));
        this.setState({ priceList: priceTrendList });
      }
    });
  };

  componentDidMount() {
    this.loadPriceList();
  }
  render() {
    const div_width = '90%';
    const col_width = 1 / 7;
    let { priceItem, priceList } = this.state;
    const { getFieldDecorator } = this.props.form;
    const columns = [
      {
        title: '日期',
        dataIndex: 'date',
        key: 'date',
        width: '15%',
        render: (text, record) =>
          this.isEditable(record.key) ? (
            <FormItem>
              
              {getFieldDecorator(`${ROW_PREFFIX}${record.key}.date`, {
                initialValue: dayjs(record.date, dateFormat),
                // validateTrigger: ['onBlur', 'onChange'],
                rules: [{ required: true, message: '请填写日期' }],
              })(<DatePicker size="large" />)}
            </FormItem>
          ) : (
            text
          ),
      },
      {
        title: '高线价格',
        dataIndex: 'elevatedSteel',
        key: 'elevatedSteel',
        render: (text, record) =>
          this.isEditable(record.key) ? (
            <FormItem>
              
              {getFieldDecorator(`${ROW_PREFFIX}${record.key}.elevatedSteel`, {
                initialValue: record.elevatedSteel,
                // validateTrigger: ['onBlur', 'onChange'],
                rules: [{ required: true, message: '请填写高线价格' }],
              })(
                <InputNumber
                  size="large"
                  min={0.01}
                  max={9999}
                  style={{ width: { col_width } }}
                  placeholder="高线价格"
                />
              )}
            </FormItem>
          ) : (
            text
          ),
      },
      {
        title: '中厚板价格',
        dataIndex: 'mediumPlate',
        key: 'mediumPlate',
        render: (text, record) =>
          this.isEditable(record.key) ? (
            <FormItem>
              
              {getFieldDecorator(`${ROW_PREFFIX}${record.key}.mediumPlate`, {
                initialValue: record.mediumPlate,
                // validateTrigger: ['onBlur', 'onChange'],
                rules: [{ required: true, message: '请填写中厚板价格' }],
              })(
                <InputNumber
                  size="large"
                  min={0.01}
                  max={9999}
                  style={{ width: { col_width } }}
                  placeholder="中厚板价格"
                />
              )}
            </FormItem>
          ) : (
            text
          ),
      },
      {
        title: '卷板价格',
        dataIndex: 'rollingPlate',
        key: 'rollingPlate',
        render: (text, record) =>
          this.isEditable(record.key) ? (
            <FormItem>
              {getFieldDecorator(`${ROW_PREFFIX}${record.key}.rollingPlate`, {
                initialValue: record.rollingPlate,
                // validateTrigger: ['onBlur', 'onChange'],
                rules: [{ required: true, message: '请填写卷板价格' }],
              })(
                <InputNumber
                  size="large"
                  min={0.01}
                  max={9999}
                  style={{ width: { col_width } }}
                  placeholder="卷板价格"
                />
              )}
            </FormItem>
          ) : (
            text
          ),
      },
      {
        title: '型钢价格',
        dataIndex: 'structuralSteel',
        key: 'structuralSteel',
        render: (text, record) =>
          this.isEditable(record.key) ? (
            <FormItem>
              {getFieldDecorator(
                `${ROW_PREFFIX}${record.key}.structuralSteel`,
                {
                  initialValue: record.structuralSteel,
                  // validateTrigger: ['onBlur', 'onChange'],
                  rules: [{ required: true, message: '请填写型钢价格' }],
                }
              )(
                <InputNumber
                  size="large"
                  min={0.01}
                  max={9999}
                  style={{ width: { col_width } }}
                  placeholder="型钢价格"
                />
              )}
            </FormItem>
          ) : (
            text
          ),
      },
      {
        title: '螺纹钢价格',
        dataIndex: 'twistedSteel',
        key: 'twistedSteel',
        render: (text, record) =>
          this.isEditable(record.key) ? (
            <FormItem>
              {getFieldDecorator(`${ROW_PREFFIX}${record.key}.twistedSteel`, {
                initialValue: record.twistedSteel,
                // validateTrigger: ['onBlur', 'onChange'],
                rules: [{ required: true, message: '请填写螺纹钢价格' }],
              })(
                <InputNumber
                  size="large"
                  min={0.01}
                  max={9999}
                  style={{ width: { col_width } }}
                  placeholder="螺纹钢价格"
                />
              )}
            </FormItem>
          ) : (
            text
          ),
      },
      {
        title: '操作',
        dataIndex: 'operation',
        key: 'operation',
        render: (text, record, i) => {
          return (
            <span>
              <Button
                type="danger"
                ghost="true"
                style={{ marginLeft: '1px' }}
                onClick={() => {
                  this.removeItem(record.key);
                }}
              >
                删除
              </Button>
              {this.isEditable(record.key) && (
                <Button
                  type="primary"
                  ghost="true"
                  style={{ marginLeft: '15px' }}
                  onClick={() => {
                    this.saveItem(`${ROW_PREFFIX}${record.key}`);
                  }}
                >
                  保存
                </Button>
              )}
            </span>
          );
        },
      },
    ];

    let data = this.state.priceList;

    return (
      <div style={{ width: `${div_width}` }}>
        <h2>价格趋势设定</h2>

        <div>
          <Card
            title="价格趋势"
            extra={
              <div>
                <Button type="primary" onClick={this.addItem} icon="plus">
                  新增价格
                </Button>
                <Button
                  style={{ marginLeft: 15 }}
                  type="primary"
                  onClick={this.exportData}
                  icon="export"
                >
                  导出数据
                </Button>
              </div>
            }
          >
            <Table
              columns={columns}
              dataSource={data}
              rowClassName={() => 'editable-row'}
              bordered
              pagination={false}
            />
          </Card>
        </div>
        {/* <iframe src='http://screen.zlgx.com/' style={{width:`100%`, height:600, hidden: 'true'}}/> */}
      </div>
    );
  }
}

export default Screen;
