import React from 'react';
import { connect } from 'react-redux';
import { Form, Select, Button, message, Input, Alert, Modal } from 'antd';
import reaction from 'reaction';
import { HttpCode } from 'utils/constants';
import { Link, withRouter } from 'react-router-dom';
import { resolveUrlQuery } from 'utils';

import commonStyles from 'style/common.module.scss';

const FormItem = Form.Item;
const Option = Select.Option;
/**
 * 后台用户修改
 */
@connect(({ backendUser, common }) => ({ backendUser, common }))
@Form.create()
@withRouter
class SystemUserModify extends React.Component {
  componentDidMount() {
    const { userId } = resolveUrlQuery(this.props.location.search);
    reaction.backendUser.getDetail(userId);
  }
  handleSubmit = (e) => {
    e && e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let { role } = values;
        const { userId } = resolveUrlQuery(this.props.location.search);
        Modal.confirm({
          title: '提示',
          content: '确定修改用户信息吗？',
          onOk: () => {
            reaction.backendUser.update(userId, role).then((data) => {
              if (data.code === HttpCode.OK) {
                message.success('修改成功！');
                reaction.me.initialData().then((data) => {
                  if (data.code === 0) {
                    this.handleGoBack();
                  }
                });
              }
            });
          },
        });
      }
    });
  };
  handleGoBack = () => {
    this.props.history.push('/system/user');
  };
  render() {
    const { roleDict } = this.props.common;
    const { detail } = this.props.backendUser;
    let { getFieldDecorator } = this.props.form;
    return (
      !!detail && (
        <div className={commonStyles.page}>
          <div className={commonStyles['page__header']}>
            <h2 className={commonStyles.row}>用户修改</h2>
            <Alert
              className={commonStyles['page__header--tips']}
              type="info"
              description={
                <>
                  注:若当前角色权限不足，可前往<Link to="/system/role">角色管理&gt;&gt;</Link>新增或修改角色权限。
                </>
              }
            />
          </div>
          <Form onSubmit={this.handleSubmit} className={commonStyles.row}>
            <FormItem
              label="姓名"
              labelCol={{ span: 8 }}
              wrapperCol={{ sm: 8, xs: 24 }}
            >
              {detail.realName}
            </FormItem>
            <FormItem
              label="用户名"
              labelCol={{ span: 8 }}
              wrapperCol={{ sm: 8, xs: 24 }}
            >
              {detail.username}
            </FormItem>
            <FormItem
              label="角色名称"
              labelCol={{ span: 8 }}
              wrapperCol={{ sm: 8, xs: 24 }}
            >
              {getFieldDecorator('role', {
                initialValue: detail.role
                  ? detail.role.map((item) => item + '')
                  : [],
                rules: [
                  {
                    required: true,
                    message: '请选择角色名称!',
                  },
                ],
              })(
                <Select mode="multiple" placeholder="角色名称" showSearch optionFilterProp="children">
                  {roleDict &&
                    Object.keys(roleDict).map((id) => {
                      return <Option key={`${id}`}>{roleDict[id]}</Option>;
                    })}
                </Select>
              )}
            </FormItem>
            <FormItem
              wrapperCol={{ sm: { span: 8, offset: 8 }, xs: { span: 8 } }}
            >
              <div className={commonStyles['page__btns']}>
                <Button
                  type="primary"
                  htmlType="submit"
                  className={commonStyles['page__btn']}
                >
                  确定
                </Button>
                <Button
                  type="ghost"
                  className={commonStyles['page__btns']}
                  onClick={this.handleGoBack}
                >
                  返回
                </Button>
              </div>
            </FormItem>
          </Form>
        </div>
      )
    );
  }
}

export default SystemUserModify;
