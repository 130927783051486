// import 'core-js';

import 'core-js/es/set';
import 'core-js/es/array/reduce';
import 'core-js/es/set';
import 'core-js/es/map';
import 'core-js/es/weak-set';
import 'core-js/es/weak-map';

import 'core-js/es/object/is';
import 'core-js/es/object/keys';
import 'core-js/es/object/assign';
import 'core-js/es/object/values';

import 'core-js/es/array/find';
import 'core-js/es/array/reduce';
import 'core-js/es/array/entries';
import 'core-js/es/array/includes';
import 'core-js/es/array/is-array';

import 'core-js/es/symbol';
import 'core-js/es/string/includes';

import { uamsdk } from 'utils';
import * as env from 'config/env';;

const location = window.location;
const prod_redirect_uri = `${location.protocol}//${location.hostname}:${location.port}/callback.html`;

// const redirect_uri = location.hostname.includes('localhost') ? 'http://localhost:3000/callback.html' : env.DEBUG ? prod_redirect_uri.replace('local.', 'uam.') : prod_redirect_uri;

uamsdk.init({
  apiUrl: 'uam-sso.zlgx.com/api/',
  loginUrl: `${location.protocol}${env.SSO_URL}#/login`,
  logoutUrl: `${location.protocol}${env.SSO_URL}#/logout`,
  client_id: 'uam_dashboard',
  redirect_uri: env.DEBUG && location.hostname.includes('localhost') ? 'http://localhost:3000/callback.html' : prod_redirect_uri,
  display: uamsdk.IFRAME,
  UAMMode: true
});
/**
 * Created by Administrator on 2017/1/18.
 */

// import 'es6-promise/auto';
// Promise.prototype.always = function(fn) {
//     function finFn(){
//         setTimeout(fn);
//     }
//     this.then(finFn, finFn);
//     return this;
// };
// require('core-js');
// function deleteProperty (Clazz) {
//     Clazz.prototype.onDestroy = () => {};
//     let autoBindPairs = Clazz.prototype.__reactAutoBindPairs;
//     for (let i = 0; i < autoBindPairs.length; i += 2 ) {
//         let key = autoBindPairs[i];
//         if(key === 'onDestroy'){
//             autoBindPairs[i+1] = () => {};
//         }
//     }
// }
// if(!Object.assign) {
//     Object.assign = require('object-assign');
// }

// antd rc-menu bugfix
// deleteProperty(require('rc-menu').default);
