import React from 'react';
import { DatePicker } from 'antd';
const {RangePicker} = DatePicker;

export default class TimeSelect extends React.Component {
  
  componentDidMount () {
    
  }
  render () {
    let { onChange, value } = this.props;
    return (<RangePicker value={ value } onChange={ onChange } />);
  }
}