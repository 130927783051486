import React from 'react';
import { Input, Button, Select, message } from 'antd';
import { historyPush } from 'utils/history';
import Immutable from 'immutable';
import { HttpCode } from 'utils/constants';
import reaction from 'reaction';
import { RoutePath } from 'utils/constants';

let Option = Select.Option;

/**
 * 新增规则变量
 */
export default class AddRuleVar extends React.Component {
  state = {
    query: Immutable.Map({
      code: '',
      name: '',
      valueType: '1',
      value: '',
    }),
  };
  submit = () => {
    let query = this.state.query.toJS();
    if (!query.code || !query.name || !query.valueType || !query.value) {
      message.error('请填写完整之后再提交!');
      return;
    }
    reaction.ruleConfig
      .addRule({
        ruleCode: query.code,
        ruleName: query.name,
        ruleValue: query.value,
        valueType: query.valueType,
      })
      .then((res) => {
        if (res.code === HttpCode.OK) {
          message.success('新增成功!');
          this.back();
        }
      });
  };

  onQueryChange = (key, value) => {
    let { query } = this.state;
    query = query.set(key, value);
    this.setState({ query }, () => {});
  };

  back = () => {
    historyPush(`/${RoutePath.FUNDS}/${RoutePath.RULE_CONFIG}`);
  };

  render() {
    let { query } = this.state;
    return (
      <div>
        <h2 style={{ padding: '20px' }}>新增规则变量</h2>
        <div style={{ padding: '10px 0 0 100px' }}>
          <label style={{ width: 80, display: 'inline-block' }}>code：</label>
          <Input
            style={{ width: 185, marginLeft: 10 }}
            value={query.toJS().code}
            onChange={(e) => {
              this.onQueryChange('code', e.target.value);
            }}
            placeholder="请输入code"
          />
        </div>
        <div style={{ padding: '10px 0 0 100px' }}>
          <label style={{ width: 80, display: 'inline-block' }}>
            规则名称：
          </label>
          <Input
            style={{ width: 185, marginLeft: 10 }}
            value={query.toJS().name}
            onChange={(e) => {
              this.onQueryChange('name', e.target.value);
            }}
            placeholder="请输入规则名称"
          />
        </div>
        <div style={{ padding: '10px 0 0 100px' }}>
          <label style={{ width: 80, display: 'inline-block' }}>值类型：</label>
          <Select
            value={query.toJS().valueType}
            style={{ width: 185, marginLeft: 10 }}
            onChange={(value) => {
              this.onQueryChange('valueType', value);
            }}
          >
            <Option key={''} value={'2'}>
              不限
            </Option>
            <Option key={'1'} value={'1'}>
              数字
            </Option>
          </Select>
        </div>
        <div style={{ padding: '10px 0 0 100px' }}>
          <label style={{ width: 80, display: 'inline-block' }}>值：</label>
          <Input
            style={{ width: 185, marginLeft: 10 }}
            value={query.toJS().value}
            onChange={(e) => {
              this.onQueryChange('value', e.target.value);
            }}
            placeholder="请输入值"
          />
        </div>
        <div style={{ padding: '30px 0 0 100px' }}>
          <Button onClick={this.submit}>保存</Button>
          <Button onClick={this.back} style={{ marginLeft: 10 }}>
            返回
          </Button>
        </div>
      </div>
    );
  }
}
