import http from 'utils/http';
import createUID from 'utils/createUID';

const PHONE_LIST = createUID();
const ACCOUNT_LIST = createUID();

const initialState = {
  smsPhoneBlackList: {
    pagerInfo: {
      page: 1,
      total: 0
    },
    result: []
  },
  smsAccountBlackList: {
    pagerInfo: {
      page: 1,
      total: 0
    },
    result: []
  }
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case PHONE_LIST:
      return Object.assign({}, state, { smsPhoneBlackList: action.data });
    case ACCOUNT_LIST:
      return Object.assign({}, state, { smsAccountBlackList: action.data });
    default:
      return state;
  }
}

Object.assign(reducer, {
  getSmsPhoneBlackList({ page, length, phone = null }) {
    return dispatch => {
      return http
        .request('v1.smsPhoneBlacklist.pagePhoneBlacklist', {
          pagerCondition: { page, length },
          queryCondition: {
            phone
          }
        })
        .then(data => {
          dispatch({ type: PHONE_LIST, data: data.data });
          return data;
        });
    };
  },
  getSmsAccountBlackList({ page, length, username, userId, certSubject, phone }) {
    return dispatch => {
      return http
        .request('v1.smsAccountBlacklist.pageAccountBlacklist', {
          pagerCondition: { page, length },
          queryCondition: { username, userId, certSubject, phone }
        })
        .then(data => {
          dispatch({ type: ACCOUNT_LIST, data: data.data });
          return data;
        });
    };
  },
  deleteFromBlackList (phone) {
    return dispatch => {
      return http
        .request('v1.smsPhoneBlacklist.removePhoneBlacklist', { phone }).then(data => {
          return data;
        });
    };
  },
  confirmDeleteAccountFromBlackList (userId) {
    return dispatch => {
      return http
        .request('v1.smsAccountBlacklist.removeAccountBlacklist', { userId }).then(data => {
          return data;
        });
    };
  },
  addNewPhoneToBlackList (phone) {
    return dispatch => {
      return http
        .request('v1.smsPhoneBlacklist.addPhoneBlacklist', { phone }).then(data => {
          return data;
        });
    };
  }
});

export default reducer;
