import React from 'react';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import Immutable from 'immutable';
import reaction from 'reaction';
import { Table, message, Form, Select, Input } from 'antd';
import { historyPush } from 'utils/history';
import { RoutePath } from 'utils/constants';
import PersonEditor from 'modules/PersonEditor';
import { parseDate, parseFileUrl, parseFileUrl2 } from 'utils';
import { resolveUrlQuery, getUniqTableRowKey } from 'utils';

import commonStyles from 'style/common.module.scss';

const FormItem = Form.Item;
const { Option } = Select;

// Review: 资质列表 - 司机 - 编辑

@Form.create()
@connect(({ qualification, memberCenter, me, common, frontUser }) => ({
  qualification,
  memberCenter,
  me,
  common,
  frontUser,
}))
class ModifyPersonalQualification extends React.Component {
  state = {
    query: Immutable.Map({
      page: 1,
      length: 10,
    }),
    initialValue: null,
  };

  getPersonalQualificationInfo = () => {
    let userId = resolveUrlQuery(this.props.location.search).userId;
    return reaction.qualification
      .getPersonalQualificationInfo({ userId })
      .then(({ code, data }) => {
        if (code !== 0) return;

        const { additionalCert, attachment, baseInfo, identity } = data;
        const { identityCardFrontUrl, identityCardBackUrl } = attachment;
        const { idCardValidTime, idCardExpirationTime } = baseInfo;
        let initialValue = { additionalCert, attachment, baseInfo, identityList: identity || [] };

        initialValue.idCardTime = [
          parseDate(idCardValidTime),
          parseDate(idCardExpirationTime),
        ];

        initialValue.identityCardFrontUrl = parseFileUrl(
          identityCardFrontUrl,
          'identityCardFrontUrl'
        );
        initialValue.identityCardBackUrl = parseFileUrl(
          identityCardBackUrl,
          'identityCardBackUrl'
        );

        if (additionalCert && additionalCert.vehicleInfo) {
          const {
            vehicleInfo: {
              runningRegisterDate,
              runningIssueDate,
              drivingLicenseValidTime,
              drivingLicenseExpirationTime,
              drivingLicenseUrl,
              drivingLicenseSubPage,
              runningLicenseUrl,
              runningLicenseSubPage,
              transportBusinessLicenseUrl,
              transportBusinessQualificationUrl,
              runningLicenseValidityUrl,
              semiDrivingLicenseValidityUrl,
              semiDrivingLicenseUrl,
              semiDrivingLicenseSubPageUrl,
              runningLicenseValidityData,
              semiDrivingLicenseValidityData
            },
          } = additionalCert;


          initialValue.runningRegisterDate = parseDate(runningRegisterDate);
          initialValue.runningIssueDate = parseDate(runningIssueDate);
          initialValue.runningLicenseValidityData = parseDate(runningLicenseValidityData);
          initialValue.semiDrivingLicenseValidityData = parseDate(semiDrivingLicenseValidityData);

          initialValue.drivingLicenseTime = [
            parseDate(drivingLicenseValidTime),
            parseDate(drivingLicenseExpirationTime),
          ];

          initialValue.drivingLicenseUrl = parseFileUrl(
            drivingLicenseUrl,
            'drivingLicenseUrl'
          );
          initialValue.drivingLicenseSubPage = parseFileUrl(
            drivingLicenseSubPage,
            'drivingLicenseSubPage'
          );
          initialValue.runningLicenseUrl = parseFileUrl(
            runningLicenseUrl,
            'runningLicenseUrl'
          );
          initialValue.runningLicenseSubPage = parseFileUrl(
            runningLicenseSubPage,
            'runningLicenseSubPage'
          );
          initialValue.transportBusinessLicenseUrl = parseFileUrl(
            transportBusinessLicenseUrl,
            'transportBusinessLicenseUrl'
          );
          initialValue.transportBusinessQualificationUrl = parseFileUrl(
            transportBusinessQualificationUrl,
            'transportBusinessQualificationUrl'
          );
          initialValue.runningLicenseValidityUrl = parseFileUrl2(
            runningLicenseValidityUrl,
            'runningLicenseValidityUrl'
          );
          initialValue.semiDrivingLicenseValidityUrl = parseFileUrl2(
            semiDrivingLicenseValidityUrl,
            'semiDrivingLicenseValidityUrl'
          );
          initialValue.semiDrivingLicenseUrl = parseFileUrl2(
            semiDrivingLicenseUrl,
            'semiDrivingLicenseUrl'
          );
          initialValue.semiDrivingLicenseSubPageUrl = parseFileUrl2(
            semiDrivingLicenseSubPageUrl,
            'semiDrivingLicenseSubPageUrl'
          );
        }

        this.setState({ initialValue });
        return data;
      });
  };

  handleTableChange = (pagination, filters, sorter) => {
    let { query } = this.state;
    query = query.set('page', pagination.current);
    query = query.set('length', pagination.pageSize);
    this.setState({ query }, () => {
      this.getOperationHistory();
    });
  };

  getOperationHistory = () => {
    let { page, length } = this.state.query.toJS();
    reaction.qualification.getPersonalQualificationOperationHistory({
      userId: resolveUrlQuery(this.props.location.search).userId,
      page,
      length,
      operationType: resolveUrlQuery(this.props.location.search).operationType,
    });
  };


  handleDepartmentChange = (_, { props }) => {
    this.fetchDepartmentTeamList(props.id);
    this.setFieldsValue('team', '');
  }

  fetchDepartmentTeamList(id) {
    reaction.frontUser.getDepartmentTeamList({
      parentId: id,
    });
  }

  hasSelectDepartment() {
    return !!this.props.form.getFieldValue('department');
  }

  setFieldsValue(name, value) {
    this.props.form.setFieldsValue({
      [name]: value,
    })
  }

  handleSubmit = (values, validate) => {
    if (!validate) return;

    const info = this.props.qualification.personalQualificationDetailInfo;
    const params = { ...info, ...values };
    params.salesman = this.props.form.getFieldValue('salesman');
    params.department = this.props.form.getFieldValue('department');
    params.team = this.props.form.getFieldValue('team');

    reaction.qualification.modifyPersonalQualification(params).then((res) => {
      if (res.code === 0) {
        message.success('修改成功');
        historyPush(
          `/${RoutePath.MEMBERCENTER}/${RoutePath.QUALIFICATION_LIST}`
        );
      }
    });
  };

  back = () => {
    historyPush(`/${RoutePath.MEMBERCENTER}/${RoutePath.QUALIFICATION_LIST}`);
  };

  async componentDidMount() {
    await this.getOperationHistory();
    const certInfo = await this.getPersonalQualificationInfo() || {};
    certInfo.departmentId && this.fetchDepartmentTeamList(certInfo.departmentId);
  }

  getColumns() {
    return [
      {
        width: 112,
        title: '时间',
        dataIndex: 'updateTime',
        render: (text) => {
          if (!text) {
            return '';
          }
          return dayjs(text - 0).format('YYYY-MM-DD HH:mm:ss');
        },
      },
      {
        title: '操作人',
        width: 140,
        dataIndex: 'operator',
      },
      {
        title: '修改内容',
        dataIndex: 'updateContent',
        render: (text) => {
          return <pre style={{ maxWidth: '1000px' }}>{text}</pre>;
        },
      },
    ];
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    let { personalQualificationDetailInfo: qualificationDetailInfo } = this.props.qualification;

    const { departments = [] } = this.props.me;
    const { teamList = [], teamListLoading } = this.props.frontUser;
    let formItemLayout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 20 },
    };
    const list = this.props.qualification.personalQualificationOperationHistory;
    const initialValue = this.state.initialValue;
    const operates = [
      {
        text: '保存',
        validate: true,
        func: this.handleSubmit,
      },
      {
        text: '返回',
        func: this.back,
      },
    ];

    return (
      <div>
        <h2 className={commonStyles.row}>司机资质编辑</h2>
        <div style={{ overflow: 'hidden' }}>
          <div style={{ float: 'left', width: 100 }}>账户信息</div>
          <div style={{ marginLeft: 100 }}>
            <div style={{ marginBottom: 30 }}>
              <label
                style={{
                  width: '16.6666666%',
                  textAlign: 'right',
                  display: 'inline-block',
                }}
              >
                会员编号：
              </label>
              {qualificationDetailInfo.user.userNo}
            </div>
            <div style={{ marginBottom: 30 }}>
              <label
                style={{
                  width: '16.6666666%',
                  textAlign: 'right',
                  display: 'inline-block',
                }}
              >
                用户名：
              </label>
              {qualificationDetailInfo.user.username}
            </div>
            <div style={{ marginBottom: 30 }}>
              <label
                style={{
                  width: '16.6666666%',
                  textAlign: 'right',
                  display: 'inline-block',
                }}
              >
                安全手机：
              </label>
              {qualificationDetailInfo.user.phone}
            </div>
            <Form>
              <FormItem {...formItemLayout} label="责任部门">
                {getFieldDecorator('department', {
                  rules: [{ required: false, message: '请选择责任部门' }],
                  initialValue: qualificationDetailInfo.department || '',
                })(
                  <Select style={{ width: 250 }} onChange={this.handleDepartmentChange}>
                    {departments &&
                      departments.map((item) => {
                        return (
                          <Option key={item.code} value={item.code || ''} id={item.id}>
                            {item.name}
                          </Option>
                        );
                      })}
                  </Select>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="小组">
                {getFieldDecorator('team', {
                  validateTrigger: ['onBlur', 'onChange'],
                  rules: [
                    {
                      required: false,
                      whitespace: true,
                      message: '请输入小组',
                    },
                  ],
                  initialValue: qualificationDetailInfo.team || '',
                })(
                  <Select style={{ width: 250 }} disabled={!this.hasSelectDepartment()} loading={teamListLoading}>
                    {teamList.map((item) => {
                      return (
                        <Option key={item.code} value={item.code || ''}>
                          {item.name}
                        </Option>
                      );
                    })}
                  </Select>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="业务员">
                {getFieldDecorator('salesman', {
                  validateTrigger: ['onBlur', 'onChange'],
                  rules: [
                    {
                      required: false,
                      whitespace: true,
                      message: '请输入业务员',
                    },
                  ],
                  initialValue: qualificationDetailInfo.salesman || '',
                })(
                  <Input
                    maxLength={5}
                    style={{ width: 250 }}
                    placeholder="请输入业务员"
                  />
                )}
              </FormItem>
            </Form>
          </div>
          {
            initialValue ? (
              <React.Fragment>
                <PersonEditor
                  initialValue={initialValue}
                  isEdit={true}
                  operates={operates}
                />
                <div style={{ padding: '0 0 50px 0' }}>
                  {list && (
                    <Table
                      className={commonStyles.row}
                      rowKey={getUniqTableRowKey('operator', list.pagerInfo)}
                      columns={this.getColumns()}
                      pagination={{
                        current: list.pagerInfo.page,
                        total: list.pagerInfo.total,
                      }}
                      onChange={this.handleTableChange}
                      dataSource={list.result}
                    />
                  )}
                </div>
              </React.Fragment>
            ) : null
          }
        </div>
      </div>
    );
  }
}

export default ModifyPersonalQualification;
