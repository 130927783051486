import React from 'react';
import { connect } from 'react-redux';
import reaction from 'reaction';
import { RoutePath, AuthCodes } from 'utils/constants';
import {
  Table,
  Button,
  Input,
  Form,
  Select,
  DatePicker,
  Modal,
  Checkbox,
  message,
  Row,
  Col,
  Icon
} from 'antd';
import dayjs from 'dayjs';
import Immutable from 'immutable';
import FormItem from 'components/FormItem';
import { PopConfirm } from 'components/Popconfirm';

import { REJECT_REASON_MAX_LENGTH } from 'config';
import { getUniqTableRowKey, getReallyTableRowKey, renderListControls } from 'utils';

import commonStyles from 'style/common.module.scss';

let dateFormat = 'YYYY/MM/DD';

const { RangePicker } = DatePicker;
const Option = Select.Option;
const location = window.location;

// const DepartmentName = memo(function resolveDepartmentName({ departmentCode, departments }) {
//   return departments.find(el => el.code === departmentCode)?.name;
// });

/**
 * 账户信息管理
 */
@connect(({ frontUser, me, common }) => ({ frontUser, me, common }))
class FrontUserList extends React.Component {
  state = {
    visibleModal: false,
    loading: false,
    currentUser: {},
    keywords: '',
    selectedRowKeys: [],
    query: Immutable.Map({
      userNo: '',
      username: '',
      phone: '',
      isActive: '',
      parentOrSelf: '',
      operator: '',
      signatureStatus: '',
      appDomain: '',
      department: '',
      salesman: '',
      userType: '',
      hasFundsAccount: '',
      registerPlatformCodeList: '',
      registerTime: [],
      updateTime: [],
      isInternalUser: '0',
      page: 1,
      length: 10,
    }),
    showAll: false,
  };

  componentDidMount() {
    this.getDepartmentDict();
    this.getUserAccountList();
    this.getRegisterAccountNumber();
  }
  getDepartmentDict = () => {
    reaction.common.getDepartmentDict();
  };
  getRegisterAccountNumber = () => {
    reaction.frontUser.getRegisterAccountNumber();
  };
  getUserAccountList = async () => {
    let { roleAuthList } = this.props.me.roleInfo;
    let {
      userNo,
      username,
      phone,
      isActive,
      parentOrSelf,
      operator,
      signatureStatus,
      appDomain,
      department,
      salesman,
      userType,
      hasFundsAccount,
      registerPlatformCodeList,
      registerTime,
      updateTime,
      isInternalUser,
      page,
      length,
    } = this.state.query.toJS();
    let listEnbaled = roleAuthList.some((item) => {
      return item.authCode.indexOf(AuthCodes.MEMBER_FRONT_USER_LIST) !== -1;
    });
    let params = {
      userNo,
      username,
      phone,
      registerPlatformIdList: registerPlatformCodeList
        ? [registerPlatformCodeList]
        : [],
      page,
      length,
      isInternalUser,
      isActive,
      parentOrSelf,
      operator,
      signatureStatus,
      appDomain,
      department,
      salesman,
      userType,
      hasFundsAccount,
    };
    // if (registerTime[0] && registerTime[1]) {
    //   params.registerTime = [registerTime[0].startOf('day').valueOf(), registerTime[1].endOf('day').valueOf()];
    // }
    if (registerTime[0]) {
      params.registerTimeStart = registerTime[0].startOf('day').valueOf();
    }
    if (registerTime[1]) {
      params.registerTimeEnd = registerTime[1].endOf('day').valueOf();
    }
    if (updateTime[0]) {
      params.updateBeginTime = updateTime[0].startOf('day').valueOf();
    }
    if (updateTime[1]) {
      params.updateEndTime = updateTime[1].endOf('day').valueOf();
    }
    if (isActive === '0') {
      params.isActive = false;
    } else if (isActive === '1') {
      params.isActive = true;
    } else {
      params.isActive = null;
    }
    if (isInternalUser === '0') {
      params.isInternalUser = false;
    } else if (isInternalUser === '1') {
      params.isInternalUser = true;
    } else {
      params.isInternalUser = null;
    }
    if (hasFundsAccount === '0') {
      params.hasFundsAccount = false;
    } else if (hasFundsAccount === '1') {
      params.hasFundsAccount = true;
    } else {
      params.hasFundsAccount = null;
    }

    if (signatureStatus === '') {
      params.signatureStatus = null;
    }
    if (listEnbaled) {
      this.setState({
        loading: true,
      });
      await reaction.frontUser.getList(params, false);
      this.setState({
        loading: false,
      });
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    let { query } = this.state;
    query = query.set('page', pagination.current);
    query = query.set('length', pagination.pageSize);
    this.setState({ query }, () => {
      this.getUserAccountList();
    });
    // reaction.frontUser.getList(this.state.keywords, pagination.current);
  };
  doSearch = (e) => {
    let { query } = this.state;
    query = query.set('page', 1);
    this.setState({ query }, () => {
      this.getUserAccountList();
    });
    e && e.preventDefault();
  };
  clear = () => {
    let { query } = this.state;
    query = query.set('userNo', '');
    query = query.set('username', '');
    query = query.set('phone', '');
    query = query.set('isActive', '');
    query = query.set('registerPlatformCodeList', '');
    query = query.set('registerTime', '');
    query = query.set('updateTime', '');
    query = query.set('isInternalUser', '0');
    query = query.set('parentOrSelf', '');
    query = query.set('operator', '');
    query = query.set('signatureStatus', '');
    query = query.set('appDomain', '');
    query = query.set('department', '');
    query = query.set('salesman', '');
    query = query.set('userType', '');
    query = query.set('hasFundsAccount', '');

    this.setState({ query });
  };
  exportUsers = () => {
    let {
      userNo,
      username,
      phone,
      isActive,
      parentOrSelf,
      operator,
      signatureStatus,
      appDomain,
      department,
      salesman,
      userType,
      hasFundsAccount,
      registerPlatformCodeList,
      registerTime,
      updateTime,
      isInternalUser,
      page,
      length,
    } = this.state.query.toJS();
    let params = {
      userNo,
      username,
      phone,
      registerPlatformIdList: registerPlatformCodeList
        ? [registerPlatformCodeList]
        : [],
      page,
      length,
      isInternalUser,
      isActive,
      parentOrSelf,
      operator,
      signatureStatus,
      appDomain,
      department,
      salesman,
      userType,
      hasFundsAccount,
    };

    if (registerTime[0]) {
      params.registerTimeStart = registerTime[0].startOf('day').valueOf();
    }
    if (registerTime[1]) {
      params.registerTimeEnd = registerTime[1].endOf('day').valueOf();
    }
    if (updateTime[0]) {
      params.updateBeginTime = updateTime[0].startOf('day').valueOf();
    }
    if (updateTime[1]) {
      params.updateEndTime = updateTime[1].endOf('day').valueOf();
    }
    if (isActive === '0') {
      params.isActive = false;
    } else if (isActive === '1') {
      params.isActive = true;
    } else {
      params.isActive = null;
    }
    if (isInternalUser === '0') {
      params.isInternalUser = false;
    } else if (isInternalUser === '1') {
      params.isInternalUser = true;
    } else {
      params.isInternalUser = null;
    }
    if (hasFundsAccount === '0') {
      params.hasFundsAccount = false;
    } else if (hasFundsAccount === '1') {
      params.hasFundsAccount = true;
    } else {
      params.hasFundsAccount = null;
    }
    if (signatureStatus === '') {
      params.signatureStatus = null;
    }
    reaction.frontUser.exportUsers(params).then((res) => {
      if (res.code === 0) {
        this.download(res.data.url, '用户列表');
      }
    });
  };
  download = (fileKey, fileName) => {
    // reaction.memberCenter.download(fileKey, fileName);
    location.href = `/api/v1/common/download?token=${uamsdk.getToken()}&fileKey=${fileKey}&fileName=${encodeURIComponent(
      fileName
    )}`;
  };
  onChange = (e) => {
    this.setState({ keywords: e.target.value });
  };

  onQueryChange = (name, value) => {
    let { query } = this.state;
    query = query.set(name, value);
    this.setState({ query }, () => {});
  };

  onDetail(userId) {
    window.open(`${location.href}/${RoutePath.DETAIL}?userId=${userId}`);
    // historyPush(`${RoutePath.DETAIL}`, {userId});
  }
  onSelectAllChange = (checked, data) => {
    const page = this.state.query.toJS().page;
    checked &&
      this.setState({
        selectedRowKeys: data.map(getUniqTableRowKey('userId', page)),
      });

    checked || this.clearSelectedRows();
  };

  onSelectedRowKeysChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };
  clearSelectedRows() {
    this.setState({ selectedRowKeys: [] });
  }
  setUserStatus = (userId, isActive, disableReason) => {
    reaction.frontUser
      .updateUserStatus({ userId, isActive, disableReason: isActive ? null : disableReason })
      .then((res) => {
        if (res.code === 0) {
          this.getUserAccountList();
        }
      });
  };

  resetPassword = () => {
    reaction.frontUser
      .resetPassword({ userId: this.state.currentUser.userId })
      .then((res) => {
        this.setState({ visibleModal: false });
        if (res.code === 0) {
          message.success('重置密码成功!');
          this.getUserAccountList();
        } else {
          message.error(res.msg || '服务器错误!');
        }
      });
  };

  showModal = (record) => {
    this.setState({
      visibleModal: true,
      currentUser: record,
    });
  };

  handleCancel = () => {
    this.setState({ visibleModal: false });
  };

  submit = () => {};


  updateSomeUsersStatus = async (isActive = true, disableReason = '') => {
    //TODO:下个版本添加禁用原因
    let ids = getReallyTableRowKey(this.state.selectedRowKeys);
    if (ids.length === 0) {
      return;
    };
    await reaction.frontUser.updateSomeUsersStatus({ userIdList: ids, isActive, disableReason });
    this.getUserAccountList();
    this.clearSelectedRows();
  }

  onPressEnter = (e) => {
    this.doSearch(e);
  };
  handleToggle = () => {
    this.setState(({ showAll }) => {
      return {
        showAll: !showAll,
      };
    })
  }

  getColumns(updateStatusEnable, updatePasswordEnable, departments) {
    return [
      {
        title: '编号',
        width: 100,
        dataIndex: 'userNo',
      },
      {
        title: '手机号码',
        width: 110,
        dataIndex: 'phone',
      },
      {
        title: '认证主体',
        width: 220,
        dataIndex: 'parentOrSelf',
        ellipsis: true,
        render: (text) => {
          if (!text) {
            return '-';
          }
          return text;
        },
      },
      {
        title: '注册时间',
        width: 150,
        dataIndex: 'registerTime',
        render: (text) => {
          if (!text) {
            return '';
          }
          return dayjs(text - 0).format('YYYY-MM-DD HH:mm:ss');
        },
      },
      {
        title: '账户类型',
        width: 80,
        dataIndex: 'userType',
      },
      {
        title: '电子签章',
        width: 80,
        dataIndex: 'signatureStatus',
      },
      {
        title: '内部账户',
        width: 80,
        dataIndex: 'isInternalUser',
        render: (text) => {
          if (text) {
            return '是';
          }
          return '否';
        },
      },
      {
        title: '资金账户',
        width: 80,
        // fixed: 'right',
        dataIndex: 'hasFundsAccount',
        render: (text) => {
          if (text) {
            return '已开通';
          }
          return '未开通';
        },
      },
      {
        title: '状态',
        width: 80,
        render(active, record) {
          return <span>{record.isActive ? '启用' : '禁用'}</span>;
        },
      },
      {
        title: '操作',
        width: 265,
        render: (text, record) => (
          <React.Fragment>
            <Button onClick={this.onDetail.bind(this, record.userId)}>
              管理
            </Button>
            &nbsp;
            <PopConfirm
              title={`确定${record.isActive ? '禁用' : '启用'}此账户吗？`}
              placement="topLeft"
              useContent={record.isActive}
              textAreaProps={{ maxLength: REJECT_REASON_MAX_LENGTH }}
              onOk={(disableReason) => {                
                this.setUserStatus(record.userId, !record.isActive, disableReason);
              }}
              destroyTooltipOnHide
              key={record.isActive}
            >
              <Button
                disabled={!updateStatusEnable}
              >
                {record.isActive ? '禁用' : '启用'}
              </Button>
            </PopConfirm>
            &nbsp;
            <Button
              disabled={!updatePasswordEnable}
              onClick={this.showModal.bind(this, record)}
            >
              重置密码
            </Button>
          </React.Fragment>
        ),
      },
    ];
  }

  render() {
    let { roleAuthList } = this.props.me.roleInfo;
    // let { signatureStatusDict } = this.props.me;
    let { platformDict } = this.props.common;
    let { departments = [] } = this.props.me;
    let appDomains = this.props.common.platforms;
    let platforms = [];
    for (let key in platformDict) {
      platforms.push({ name: platformDict[key], code: key });
    }
    // let detailEnbaled = roleAuthList.some((item) => {
    //   if (item.authCode.indexOf(AuthCodes.MEMBER_FRONT_USER_QUERY) !== -1) return true;
    // });
    let updateStatusEnable = roleAuthList.some((item) => {
      return item.authCode.indexOf(AuthCodes.USER_ACCOUNT_STATUS_UPDATE) !== -1;
    });
    let updatePasswordEnable = roleAuthList.some((item) => {
      return item.authCode.indexOf(AuthCodes.USER_PASSWORD_UPDATE) !== -1;
    });

    let { list, registerAccountNumberInfo } = this.props.frontUser;
    let { selectedRowKeys, query, visibleModal, currentUser, showAll } = this.state;
    let queryValue = query.toJS();
    let rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectedRowKeysChange,
    };
    const formItemLayout = {
      labelCol: {
        span: 8,
      },
      wrapperCol: {
        span: 16
      },
    };

    return (
      <div className="user">
        <div>
          <h2 className={commonStyles.row}>账户列表</h2>
          <p>
            目前已有会员
            <span style={{ color: 'red' }}>
              {registerAccountNumberInfo.totalUsers}
            </span>
            位,今日新注册
            <span style={{ color: 'red' }}>
              {registerAccountNumberInfo.todayUsers}
            </span>
            位.
          </p>
        </div>
        <Form>
          {
            renderListControls([
              <FormItem label="编号">
                <Input
                  onPressEnter={this.onPressEnter}
                  value={queryValue.userNo}
                  onChange={(e) => {
                    this.onQueryChange('userNo', e.target.value);
                  }}
                  placeholder="编号"
                />
              </FormItem>,
              <FormItem label="手机号码">
                <Input
                  onPressEnter={this.onPressEnter}
                  value={queryValue.phone}
                  onChange={(e) => {
                    this.onQueryChange('phone', e.target.value);
                  }}
                  placeholder="手机号码"
                />
              </FormItem>,
              <FormItem label="认证主体">
                <Input
                  onPressEnter={this.onPressEnter}
                  value={queryValue.parentOrSelf}
                  onChange={(e) => {
                    this.onQueryChange('parentOrSelf', e.target.value);
                  }}
                  placeholder="认证主体"
                />
              </FormItem>,
              {
                visible: showAll,
                children: (
                  <FormItem label="操作人">
                    <Input
                      onPressEnter={this.onPressEnter}
                      value={queryValue.operator}
                      onChange={(e) => {
                        this.onQueryChange('operator', e.target.value);
                      }}
                      placeholder="操作人"
                    />
                  </FormItem>
                )
              },
              {
                visible: showAll,
                children: (
                  <FormItem label="业务员">
                    <Input
                      maxLength={5}
                      onPressEnter={this.onPressEnter}
                      value={queryValue.salesman}
                      onChange={(e) => {
                        this.onQueryChange('salesman', e.target.value);
                      }}
                      placeholder="业务员"
                    />
                  </FormItem>
                )
              },
              <FormItem label="账户类型">
                <Select
                  showSearch
                  placeholder="请选择"
                  optionFilterProp="children"
                  value={queryValue.userType}
                  onChange={(value) => {
                    this.onQueryChange('userType', value);
                  }}
                >
                  <Select.Option value="">全部</Select.Option>
                  <Select.Option value="0">注册账户</Select.Option>
                  <Select.Option value="1">认证账户</Select.Option>
                  <Select.Option value="2">子账户</Select.Option>
                </Select>
              </FormItem>,
              <FormItem label="资金账户">
                <Select
                  showSearch
                  placeholder="请选择"
                  optionFilterProp="children"
                  value={queryValue.hasFundsAccount}
                  onChange={(value) => {
                    this.onQueryChange('hasFundsAccount', value);
                  }}
                >
                  <Select.Option value="">全部</Select.Option>
                  <Select.Option value="1">已开通</Select.Option>
                  <Select.Option value="0">未开通</Select.Option>
                </Select>
              </FormItem>,
              <FormItem label="电子签章">
                <Select
                  showSearch
                  placeholder="请选择"
                  optionFilterProp="children"
                  value={queryValue.signatureStatus}
                  onChange={(value) => {
                    this.onQueryChange('signatureStatus', value);
                  }}
                >
                  <Select.Option value="">全部</Select.Option>
                  <Select.Option value={0}>未制章</Select.Option>
                  <Select.Option value={1}>正常</Select.Option>
                  <Select.Option value={2}>即将过期</Select.Option>
                  <Select.Option value={3}>已过期</Select.Option>
                </Select>
              </FormItem>,
              {
                visible: showAll,
                children: (
                  <FormItem label="应用领域">
                    <Select
                      showSearch
                      placeholder="请选择"
                      optionFilterProp="children"
                      value={queryValue.appDomain}
                      onChange={(value) => {
                        this.onQueryChange('appDomain', value);
                      }}
                    >
                      <Option key="all" value="">
                        全部
                      </Option>
                      {appDomains.map((item) => {
                        return (
                          <Option key={item.appDomain} value={item.appDomain}>
                            {item.appDomainName}
                          </Option>
                        );
                      })}
                    </Select>
                  </FormItem>
                )
              },
              {
                visible: showAll,
                children: (
                  <FormItem label="注册应用">
                    <Select
                      showSearch
                      placeholder="注册应用"
                      optionFilterProp="children"
                      value={queryValue.registerPlatformCodeList}
                      onChange={(value) => {
                        this.onQueryChange('registerPlatformCodeList', value);
                      }}
                    >
                      <Select.Option key={''} value="">
                        全部
                      </Select.Option>
                      {platforms.map((item) => {
                        return (
                          <Select.Option key={item.code} value={item.code}>
                            {item.name}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </FormItem>
                )
              },
              {
                visible: showAll,
                children: (
                  <FormItem label="责任部门">
                    <Select
                      showSearch
                      placeholder="请选择责任部门"
                      optionFilterProp="children"
                      value={query.toJS().department}
                      onChange={(value) => {
                        this.onQueryChange('department', value);
                      }}
                    >
                      <Select.Option key="all" value="">
                        全部
                      </Select.Option>
                      {departments.map((item) => {
                        return (
                          <Select.Option key={item.code} value={item.code || ''} id={item.id}>
                            {item.name}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </FormItem>
                )
              },
              <FormItem label="内部账户">
                <Select
                  showSearch
                  placeholder="内部账户"
                  optionFilterProp="children"
                  value={query.toJS().isInternalUser}
                  onChange={(value) => {
                    this.onQueryChange('isInternalUser', value);
                  }}
                >
                  <Select.Option value="">全部</Select.Option>
                  <Select.Option value="1">是</Select.Option>
                  <Select.Option value="0">否</Select.Option>
                </Select>
              </FormItem>,
              {
                visible: showAll,
                children: (
                  <FormItem label="状态">
                    <Select
                      showSearch
                      placeholder="选择状态"
                      optionFilterProp="children"
                      value={queryValue.isActive}
                      onChange={(value) => {
                        this.onQueryChange('isActive', value);
                      }}
                    >
                      <Select.Option value="">全部</Select.Option>
                      <Select.Option value="1">启用</Select.Option>
                      <Select.Option value="0">禁用</Select.Option>
                    </Select>
                  </FormItem>
                )
              },
              <FormItem label="注册时间">
                <RangePicker
                  value={queryValue.registerTime}
                  format={dateFormat}
                  onChange={(value) => {
                    this.onQueryChange('registerTime', value);
                  }}
                />
              </FormItem>,
              {
                visible: showAll,
                children: (
                  <FormItem label="更新时间">
                    <RangePicker
                      value={queryValue.updateTime}
                      format={dateFormat}
                      onChange={(value) => {
                        this.onQueryChange('updateTime', value);
                      }}
                    />
                  </FormItem>
                )
              },
              {
                full: true,
                colProps: showAll ? {
                  xl: 24,
                  md: 24,
                  lg: 12,
                } : {
                  xxl: 24,
                  xl: 8,
                  md: 24,
                  lg: 24,
                },
                children: (
                  <FormItem align="right">
                    <Button
                      type="primary"
                      onClick={this.doSearch}
                      icon="search"
                    >
                      查询
                    </Button>
                    <Button
                      style={{ margin: '0 0 0 5px' }}
                      type="primary"
                      onClick={this.clear}
                      icon="redo"
                    >
                      清空
                    </Button>
                    <Button
                      style={{ margin: '0 0 0 5px' }}
                      type="primary"
                      onClick={this.exportUsers}
                      icon="export"
                    >
                      按筛选条件导出
                    </Button>
                    <Button type="link" style={{ marginRight: -15 }} onClick={this.handleToggle}>
                      {this.state.showAll ? '收起' : '展开'}<Icon type={showAll ? 'up' : 'down'} />
                    </Button>
                  </FormItem>
                )
              }
            ])
          }
        </Form>
        {list && (
          <Table
            scroll={{
              x: '100%',
            }}
            className={commonStyles.row}
            rowKey={getUniqTableRowKey('userId', queryValue)}
            loading={this.state.loading}
            rowSelection={rowSelection}
            columns={this.getColumns(updateStatusEnable, updatePasswordEnable, departments)}
            dataSource={list.result}
            footer={(data) => {
              return (
                <div>
                  <Checkbox
                    onChange={(item) => {
                      this.onSelectAllChange(item.target.checked, data);
                    }}
                  >
                    全选
                  </Checkbox>
                  <PopConfirm
                    title="确定批量禁用已选择的账户吗？"
                    placement="topLeft"
                    textAreaProps={{ maxLength: REJECT_REASON_MAX_LENGTH }}
                    onOk={(disableReason) => {
                      this.updateSomeUsersStatus(false, disableReason);
                    }}
                    disabled={!selectedRowKeys.length}
                    destroyTooltipOnHide
                  >
                    <Button disabled={!selectedRowKeys.length}>批量禁用</Button>
                  </PopConfirm>
                  &nbsp;
                  <PopConfirm
                    title="确定批量启用已选择的账户吗？"
                    placement="topLeft"
                    useContent={false}
                    onOk={() => {
                      this.updateSomeUsersStatus();
                    }}
                    disabled={!selectedRowKeys.length}
                  >
                    <Button disabled={!selectedRowKeys.length}>批量启用</Button>
                  </PopConfirm>
                </div>
              );
            }}
            pagination={list.pagerInfo && {
              current: list.pagerInfo.page,
              total: list.pagerInfo.total,
              pageSizeOptions: ['10', '20', '50'],
              showSizeChanger: true,
              showQuickJumper: true,
              showTotal: (total) => `共${total}条`,
            }}
            onChange={this.handleTableChange}
            expandedRowRender={(record) => {
              return (
                <Form>
                  <Row className={commonStyles.miniRow}>
                    <Col span={6}>
                      <FormItem
                        label="操作人"
                        {...formItemLayout}
                      >
                        {record.operator}
                      </FormItem>
                    </Col>
                    <Col span={6}>
                      <FormItem
                        label="业务员"
                        {...formItemLayout}
                      >
                        {record.salesman || '--'}
                      </FormItem>
                    </Col>
                    <Col span={6}>
                      <FormItem
                        label="应用领域"
                        {...formItemLayout}
                      >
                        {record.appDomain}
                      </FormItem>
                    </Col>
                    <Col span={6}>
                      <FormItem
                        label="注册应用"
                        {...formItemLayout}
                      >
                        {record.registerPlatformName}
                      </FormItem>
                    </Col>
                    <Col span={6}>
                      <FormItem
                        label="责任部门"
                        {...formItemLayout}
                      >
                        {record.department || '--'}
                      </FormItem>
                    </Col>
                    <Col span={6}>
                      <FormItem
                        label="更新时间"
                        {...formItemLayout}
                      >
                        {dayjs(record.updateTime - 0).format('YYYY-MM-DD HH:mm:ss')}
                      </FormItem>
                    </Col>
                    <Col span={6}>
                      <FormItem
                        label="姓名"
                        {...formItemLayout}
                      >
                        {record.subUserName || '--'}
                      </FormItem>
                    </Col>
                    
                  </Row>
                </Form>
              )
            }}
          />
        )}

        <Modal
          visible={visibleModal}
          title="重置密码"
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              取消
            </Button>,
            <Button key="submit" type="primary" onClick={this.resetPassword}>
              重置
            </Button>,
          ]}
        >
          <p>重置密码: {currentUser.username}</p>
          <p>
            注意: 重置密码之后，系统将随机生成新密码, 并发送短信至对方手机号码;
            原密码将失效，是否继续?
          </p>
        </Modal>
      </div>
    );
  }
}

export default FrontUserList;
