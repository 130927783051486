import React from 'react';
import {
  Form,
  Input,
  Button,
  Radio,
  message,
  Icon,
  Select,
  Checkbox,
  Upload,
  Modal,
  AutoComplete,
  DatePicker,
} from 'antd';
import reaction from 'reaction';
import dayjs from 'dayjs';
import { connect } from 'react-redux';
import * as env from 'config/env';
import { historyPush } from 'utils/history';
import { cenpurIdentityCodes, RoutePath } from 'utils/constants';
import DatePickAndCheckboxFormItem from 'components/DatePickAndCheckboxFormItem';
import PersonEditor from 'modules/PersonEditor';
import TreeTransfer from 'components/TreeTransfer';
import { ViewerContainer } from 'components/ImageViewer';

import { bidIdentityCodes } from 'utils/constants';
import { authorCertTemplate, identityCodes } from 'config/constant';
import { formatInviteSteelValue, formatStringSymbol, fixFormValues, getFilePreviewUrl } from 'utils';

let FormItem = Form.Item;
let RadioGroup = Radio.Group;
const CheckboxGroup = Checkbox.Group;
const Option = Select.Option;
const AOption = AutoComplete.Option;
const { TextArea } = Input;

function parseImageUrl(file) {
  if (!file || file.fileKey === '') {
    return null;
  }
  return file;
}

/**
 * 新增认证账户
 */
@Form.create()
@connect(({ memberCenter, me, common, frontUser }) => ({ memberCenter, me, common, frontUser }))
class createAuthenticateAccount extends React.Component {
  state = {
    uploading: false,
    canAddCert: true,
    userType: 'company',
    accountType: 'newAccount',
    tabType: 'createRegisterAccount',
    province: [],
    city: [],
    district: [],
    showBigImg: false,
    showProxy: true,
    bigImgUrl: '',
    imgWidth: 520,
    companyParams: {
      businessLicenceUrl: '',
      organizationCodeUrl: '',
      taxRegistryUrl: '',
      accountLicenseUrl: '',
      proxy: [], // 委托书url列表
      foreignTradeRegistrationUrl: '', // 对外贸易经营者备案登记表附件
      legalPersonIdentityCardUrl: '',
      agentIdentityCardUrl: '',
    },

    personalParams: {
      identityCardFrontUrl: '',
      identityCardBackUrl: '',
      runningLicenseUrl: '',
      drivingLicenseUrl: '',
    },
    oldAccountInfo: {
      canAddCert: true,
    },
    CheckedIdentityList: [],
    companyCharacter: [],
    appDomains: [],
    hasCenpurSupplier: false,
    hasCenpurPurchaser: false,
  };

  getAppDomains = () => {
    reaction.frontUser.getAppDomains().then((res) => {
      if (res.code === 0) {
        this.setState({
          appDomains: res.data,
        });
      }
    });
  };

  closeBigImgModal = () => {
    this.setState({
      showBigImg: false,
    });
  };
  onPreview = (file) => {
    let url = getFilePreviewUrl(file);
    this.setState({ showBigImg: true, bigImgUrl: url });
  };
  compareToNextPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && form.getFieldValue('confirmPassword')) {
      form.validateFields(['confirmPassword'], { force: true });
    }
    callback();
  };
  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue('password')) {
      callback('两次输入的密码不一致');
    } else {
      callback();
    }
  };
  compareToNextPassword1 = (rule, value, callback) => {
    const form = this.props.form;
    if (value && form.getFieldValue('user.confirmPassword')) {
      form.validateFields(['user.confirmPassword'], { force: true });
    }
    callback();
  };
  compareToFirstPassword1 = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue('user.password')) {
      callback('两次输入的密码不一致');
    } else {
      callback();
    }
  };
  clear = () => {
    ['username', 'phone', 'password', 'confirmPassword', 'email'].forEach(
      (key) =>
        this.props.form.setFieldsValue({
          [key]: '',
        })
    );
  };

  getFileData = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return (
      e && {
        fileKey: e.fileList[0].response && e.fileList[0].response.data.url,
      }
    );
  };

  getProxyFileData = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return (
      e &&
      e.fileList &&
      e.fileList
        .map((item) => {
          if (item.response) {
            return {
              fileKey: item.response.data.url,
            };
          }
          return null;
        })
        .filter(Boolean)
    );
  };

  handleSubmit = (e) => {
    e && e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        if (this.state.userType === 'company') {
          values.cert.attachment.businessLicenceUrl =
            values.cert.attachment.businessLicenceUrl.fileList[0].response.data.url;
          values.cert.attachment.organizationCodeUrl =
            values.cert.attachment.organizationCodeUrl.fileList[0].response.data.url;
          values.cert.attachment.taxRegistryUrl =
            values.cert.attachment.taxRegistryUrl.fileList[0].response.data.url;
          values.cert.attachment.openAccountLisenceUrl =
            values.cert.attachment.openAccountLisenceUrl.fileList[0].response.data.url;
          values.cert.proxy = values.cert.proxy.fileList.map(
            (item) => item.response.data.url
          );
          values.additionalCert.foreignTradeRegistrationUrl =
            values.additionalCert.foreignTradeRegistrationUrl.fileList[0].response.data.url;
          values.auditAdditionalCert.legalPersonIdentityCardFrontUrl =
            values.auditAdditionalCert.legalPersonIdentityCardFrontUrl.fileList[0].response.data.url;
          values.auditAdditionalCert.legalPersonIdentityCardBackUrl =
            values.auditAdditionalCert.legalPersonIdentityCardBackUrl.fileList[0].response.data.url;
          values.auditAdditionalCert.agentIdentityCardFrontUrl =
            values.auditAdditionalCert.agentIdentityCardFrontUrl.fileList[0].response.data.url;
          /** 因为在注册账户页面时，没有这个cert对象，所以需要做个判断 */
          if (values.cert && values.cert.baseInfo) {
            values.cert.baseInfo.registerTime = values.cert.baseInfo
              .registerTime
              ? dayjs(values.cert.baseInfo.registerTime).valueOf()
              : null; // 注册时间
          }

          values.additionalCert.supplyCategory = values.additionalCert
            .supplyCategory
            ? values.additionalCert.supplyCategory.targetKeys || []
            : []; // 供应品类
          values.additionalCert.purchaserCategory = values.additionalCert
            .purchaserCategory
            ? values.additionalCert.purchaserCategory.targetKeys || []
            : []; // 供应品类
        } else {
          values.attachment.identityCardFrontUrl =
            values.attachment.identityCardFrontUrl.fileList[0].response.data.url;
          values.attachment.identityCardBackUrl =
            values.attachment.identityCardBackUrl.fileList[0].response.data.url;
          values.additionalCert.vehicleInfo.runningLicenseUrl =
            values.additionalCert.vehicleInfo.runningLicenseUrl.fileList[0].response.data.url;
          values.additionalCert.vehicleInfo.drivingLicenseUrl =
            values.additionalCert.vehicleInfo.drivingLicenseUrl.fileList[0].response.data.url;
        }
        for (let key in values) {
          if (values[key] === '') {
            values[key] = null;
          }
        }
        if (values.cert && values.cert.baseInfo) {
          values.cert.baseInfo.invitedSteel = formatInviteSteelValue(values.cert.baseInfo.invitedSteel);
        }
        reaction.frontUser.createUser(values).then((res) => {
          if (res.code === 0) {
            message.success('新增用户成功!');
            this.clear();
            historyPush(`/${RoutePath.FRONT_USER}`);
          }
        });
      }
    });
  };

  onCheckedIdentityListChange = (values) => {
    const checkedValues = values || [];
    const checkedValueString = checkedValues.join(',');
    const checkedList = new Set(checkedValues);

    let hasTradeIdentity = checkedValueString.indexOf('trade_') !== -1;
    let hasCenpurIdentity = (checkedValueString.indexOf('cenpur_') !== -1 || checkedValueString.indexOf('bid_') !== -1);

    let tradeSupplier = 'trade_supplier';
    let tradeSteelFactory = 'trade_steel_factory';

    let hasTradeSupplier = checkedList.has(tradeSupplier);
    let hasTradeSteelFactory = checkedList.has(tradeSteelFactory);

    let hasCenpurSupplier = checkedList.has(cenpurIdentityCodes.supplier);
    let hasCenpurPurchaser = checkedList.has(cenpurIdentityCodes.purchaser);

    this.setState(({ checkedIdentityList }) => {
      const oldList = new Set(checkedIdentityList);

      const newState = {
        hasCenpurSupplier,
        hasCenpurPurchaser,
      };

      if (hasTradeSupplier && hasTradeSteelFactory) {
        if (oldList.has(tradeSupplier)) {
          checkedList.delete(tradeSupplier);
        } else if (oldList.has(tradeSteelFactory)) {
          checkedList.delete(tradeSteelFactory);
        }
      }

      if (hasCenpurPurchaser) {
        checkedList.add(bidIdentityCodes.purchaser);
      } else {
        checkedList.delete(bidIdentityCodes.purchaser);
      }

      if (hasCenpurSupplier) {
        checkedList.add(bidIdentityCodes.supplier);
      } else {
        checkedList.delete(bidIdentityCodes.supplier);
      }

      if (
        (hasTradeIdentity || hasCenpurIdentity) &&
        !checkedList.has('logistics_shipper')
      ) {
        checkedList.add('logistics_shipper');
      }
      if (
        (hasTradeIdentity || hasCenpurIdentity) &&
        !checkedList.has('wms_shipper')
      ) {
        checkedList.add('wms_shipper');
      }

      const lastValues = [...checkedList];
      this.setFieldsValue('identity', lastValues);
      return {
        ...newState,
        checkedIdentityList: lastValues,
      };
    });
  };

  setFieldsValue(name, value) {
    this.props.form.setFieldsValue({
      [name]: value,
    });
  }
  handleDepartmentChange = (_, { props }) => {
    reaction.frontUser.getDepartmentTeamList({
      parentId: props.id,
    });
    this.setFieldsValue('user.team', '');
  }

  hasSelectDepartment() {
    return !!this.props.form.getFieldValue('user.department');
  }

  onUserTypeChange = (e) => {
    this.setState({
      userType: e.target.value,
    });
  };
  onAccountTypeChange = (e) => {
    this.setState({
      accountType: e.target.value,
      oldAccountInfo: {
        canAddCert: true,
      },
    });
  };

  searchUserCertInfo = (v) => {
    if (/^[^\s]+$/.test(v)) {
      reaction.memberCenter.searchUserCertInfo(v).then((res) => {
        if (res.code === 0) {
          this.setState({
            oldAccountInfo: res.data,
          });
        }
      });
    }
  };

  onProxyTypeChange = (e) => {
    if (e.target.value === '3') {
      this.setState({
        showProxy: false,
      });
    } else {
      this.setState({
        showProxy: true,
      });
    }
  };

  onProvinceChange = (v) => {
    this.setState({
      city: this.props.memberCenter.areaDict.filter(
        (item) => item.parentId === v
      ),
    });
    this.props.form.setFieldsValue({
      'cert.baseInfo.districtCode': '',
      'baseInfo.districtCode': '',
      'cert.baseInfo.cityCode': '',
      'baseInfo.cityCode': '',
    });
  };
  onCityChange = (v) => {
    this.setState({
      district: this.props.memberCenter.areaDict.filter(
        (item) => item.parentId === v
      ),
    });
    this.props.form.setFieldsValue({
      'cert.baseInfo.districtCode': '',
      'baseInfo.districtCode': '',
    });
  };
  onDistrictChange = (v) => {};
  
  openAuthAccount = (type) => {
    if (this.state.uploading) {
      message.warning('图片上传中，请稍后提交');
      return;
    }
    this.props.form.validateFields((err, values) => {
      if (err) return ;
      values.cert.baseInfo.registerTime = values.cert.baseInfo.registerTime
        ? dayjs(values.cert.baseInfo.registerTime).valueOf()
        : null; // 注册时间
      values.additionalCert.supplyCategory = values.additionalCert
        .supplyCategory
        ? values.additionalCert.supplyCategory.targetKeys || []
        : []; // 供应品类
      values.additionalCert.purchaserCategory = values.additionalCert
        .purchaserCategory
        ? values.additionalCert.purchaserCategory.targetKeys || []
        : []; // 供应品类
      
      values.cert.baseInfo.companyNameZh = formatStringSymbol(values.cert.baseInfo.companyNameZh);
      values.cert.baseInfo.companyNameEn = formatStringSymbol(values.cert.baseInfo.companyNameEn);
      values.cert.baseInfo.usedCompanyName = formatStringSymbol(values.cert.baseInfo.usedCompanyName);
      values.cert.baseInfo.companyAbbreviation = formatStringSymbol(values.cert.baseInfo.companyAbbreviation);
      fixFormValues(values);
      values.auditAdditionalCert.businessLicenseExpirationTime = values
        .auditAdditionalCert.businessLicenseExpirationTime.expirationTime
        ? dayjs(
            values.auditAdditionalCert.businessLicenseExpirationTime
              .expirationTime
          ).valueOf()
        : null;
      values.auditAdditionalCert.registeredCapital =
        values.auditAdditionalCert.registeredCapital * 10000;
      values.cert.baseInfo.invitedSteel = formatInviteSteelValue(values.cert.baseInfo.invitedSteel); // 避免和后台返回的列表冲突
      if (this.state.accountType === 'newAccount') {
        reaction.memberCenter
          .createNewCompanyAuthAccount(values)
          .then((res) => {
            if (res.code === 0) {
              message.success('新增用户成功!');
              historyPush(`/${RoutePath.FRONT_USER}`);
            }
          });
      } else {
        if (
          !this.props.memberCenter.userCertInfo ||
          !this.props.memberCenter.userCertInfo.userId
        ) {
          message.warning('请检索已存在的账户后再提交!');
          return;
        }
        values.userId = this.props.memberCenter.userCertInfo.userId;
        reaction.memberCenter
          .createOldCompanyAuthAccount(values)
          .then((res) => {
            if (res.code === 0) {
              message.success('新增用户成功!');
              historyPush(`/${RoutePath.FRONT_USER}`);
            }
          });
      }
    });
  };

  handlePersonSubmit = (values, isValidate) => {
    const { form, memberCenter } = this.props;
    form.validateFields((err, baseValues) => {
      if (!err && isValidate) {
        let {
          attachment: { identityCardFrontUrl, identityCardBackUrl },
          additionalCert,
        } = values;

        if (values.additionalCert && values.additionalCert.vehicleInfo) {
          let {
            vehicleInfo: {
              drivingLicenseUrl,
              drivingLicenseSubPage,
              runningLicenseUrl,
              runningLicenseSubPage,
              transportBusinessLicenseUrl,
              transportBusinessQualificationUrl,
            },
          } = additionalCert;

          values.attachment.identityCardFrontUrl = parseImageUrl(
            identityCardFrontUrl
          );
          values.attachment.identityCardBackUrl = parseImageUrl(
            identityCardBackUrl
          );
          values.additionalCert.vehicleInfo.drivingLicenseUrl = parseImageUrl(
            drivingLicenseUrl
          );
          values.additionalCert.vehicleInfo.drivingLicenseSubPage = parseImageUrl(
            drivingLicenseSubPage
          );
          values.additionalCert.vehicleInfo.runningLicenseUrl = parseImageUrl(
            runningLicenseUrl
          );
          values.additionalCert.vehicleInfo.runningLicenseSubPage = parseImageUrl(
            runningLicenseSubPage
          );
          values.additionalCert.vehicleInfo.transportBusinessLicenseUrl = parseImageUrl(
            transportBusinessLicenseUrl
          );
          values.additionalCert.vehicleInfo.transportBusinessQualificationUrl = parseImageUrl(
            transportBusinessQualificationUrl
          );
        }

        let personValue = { ...baseValues, ...values };

        if (this.state.accountType === 'newAccount') {
          reaction.memberCenter
            .createNewPersonalAuthAccount(personValue)
            .then((res) => {
              if (res.code === 0) {
                message.success('新增用户成功!');
                historyPush(`/${RoutePath.FRONT_USER}`);
              }
            });
        } else {
          if (!memberCenter.userCertInfo || !memberCenter.userCertInfo.userId) {
            message.warning('请检索已存在的账户后再提交!');
            return;
          }
          personValue.userId = memberCenter.userCertInfo.userId;
          reaction.memberCenter
            .createOldPersonalAuthAccount(personValue)
            .then((res) => {
              if (res.code === 0) {
                message.success('新增用户成功!');
                historyPush(`/${RoutePath.FRONT_USER}`);
              }
            });
        }
      }
    });
  };

  beforeUpload = (onlyOne, file, fileList) => {
    if (file.size > 10240000) {
      message.error('文件大小不能超过10MB!');
      fileList.splice(fileList.indexOf(file), 1);
      return false;
    }
    if (!(/^image\//.test(file.type))) {
      message.error('只能上传图片文件!');
      fileList.splice(fileList.indexOf(file), 1);
      return false;
    }
    this.setState({
      uploading: true,
    });
    return true;
  };

  onUploadChange = (onlyOne, file, fileList) => {
    if (file.response && file.response.code === 0) {
      this.setState({
        uploading: false,
      });
      if (onlyOne && fileList.length > 1) {
        fileList.shift();
      }
    } else if (file.response && file.response.code !== 0) {
      fileList.splice(fileList.indexOf(file), 1);
      message.error(file.response.msg || '上传失败!');
      this.setState({
        uploading: false,
      });
    }
  };

  onRemove = (key, { file, fileList }) => {};

  getAreaDict = () => {
    reaction.memberCenter.getAreaDict().then((res) => {
      if (res.code === 0) {
        this.setState({
          province: res.data.filter((item) => item.type === '2'),
        });
      }
    });
  };

  getIdentityList = () => {
    reaction.memberCenter.getIdentifyDictList();
  };

  getCompanyCharacter = (v) => {
    reaction.memberCenter.getCompanyCharacter(v);
  };

  getBusinessCharacter = () => {
    reaction.memberCenter.getBusinessCharacter();
  };

  validateField = (field, { username, certName, phone }) => {
    this.validateIsUserExist({ username, certName, phone }).then((res) => {
      if (res.data) {
        this.props.form.setFields({
          [field]: {
            value: certName || phone || username,
            errors: [new Error('用户已经存在!')],
          },
        });
      }
      return res;
    });
  };

  validateIsUserExist = ({ username, certName, phone }) => {
    return reaction.memberCenter.validateIsUserExist({
      username,
      certName,
      phone,
    });
  };

  validateIsIdentityNoExist = ({ idCardNo }) => {
    return reaction.memberCenter.validateIsIdentityNoExist({
      idCardNo,
    });
  };

  componentDidMount() {
    this.getAreaDict();
    this.getIdentityList();
    this.getBusinessCharacter();
  }
  getPopupContainer = (triggerNode) => {
    const container = document.getElementById('createAuthenticateAccount');
    !container && console.log('warring, popupContainer is null', container);
    return container || document.body;
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    let { platforms, invitednterprises, manufactorTypeList } = this.props.common;    
    const { departments = [] } = this.props.me;
    const { teamList = [], teamListLoading } = this.props.frontUser;
    let {
      province,
      city,
      district,
      showProxy,
      hasCenpurSupplier,
      hasCenpurPurchaser,
      oldAccountInfo: userCertInfo,
    } = this.state;
    let identityDictList = this.props.memberCenter.identityDictList.map(
      (item) => {
        return {
          label: item.name,
          value: item.code,
          code: item.code,
          certType: item.certType,
          // 招采平台角色不可自行选择
          disabled:
            [
              bidIdentityCodes.purchaser,
              bidIdentityCodes.supplier,
              identityCodes.fin.agency,
              identityCodes.fin.borrower,
              identityCodes.fin.institution
            ].indexOf(
              item.code
            ) !== -1,
        };
      }
    );
    let businessCharacter = this.props.memberCenter.businessCharacter;

    let formItemLayout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 20 },
    };

    const hasCenpur = hasCenpurSupplier || hasCenpurPurchaser;

    let { companyCharacter } = this.props.memberCenter;

    return (
      <div id="createAuthenticateAccount" style={{ position: 'relative' }}>
        <div style={{ overflow: 'hidden' }}>
          <h3 style={{ float: 'left', width: 100 }}>账户信息</h3>
          <div style={{ marginLeft: 100 }}>
            <div style={{ display: 'inline-block', width: '16.666%' }}></div>
            <div
              style={{
                padding: '10px 0 30px 0',
                display: 'inline-block',
              }}
            >
              <RadioGroup
                onChange={this.onAccountTypeChange}
                value={this.state.accountType}
              >
                <Radio value={'newAccount'}>新账户</Radio>
                <Radio value={'oldAccount'}>已有账户</Radio>
              </RadioGroup>
            </div>
            {this.state.accountType === 'newAccount' && (
              <Form onSubmit={this.handleSubmit} className="login-form">
                <FormItem {...formItemLayout} label="用户名">
                  {getFieldDecorator('user.username', {
                    validateTrigger: ['onBlur', 'onChange'],
                    rules: [
                      {
                        required: true,
                        message:
                          '支持中文、字母、数字、"_"、"-"的组合，2至20个字符',
                        max: 10,
                        pattern: /^[-a-zA-Z_0-9\u4e00-\u9fa5]{2,20}$/,
                      },
                      {
                        required: false,
                        message: '不能为纯数字',
                        pattern: /[^\d]/,
                      },
                      {
                        required: true,
                        validator: (rule, value = '', callback) => {
                          this.validateIsUserExist({
                            username: value.trim(),
                          }).then((res) => {
                            if (res.data) {
                              callback(new Error('用户已存在!'));
                            } else {
                              callback();
                            }
                          });
                        },
                      },
                    ],
                  })(
                    <Input
                      maxLength={20}
                      style={{ width: 350 }}
                      placeholder="用户名/登录名"
                    />
                  )}
                </FormItem>
                <FormItem {...formItemLayout} label="手机号">
                  {getFieldDecorator('user.phone', {
                    validateTrigger: ['onBlur', 'onChange'],
                    rules: [
                      {
                        required: true,
                        max: 11,
                        pattern: /^1[0-9]{10}$/,
                        validator: (rule, value, callback) => {
                          if (!value || !rule.pattern.test(value)) {
                            callback('手机号不符合规则');
                            return;
                          }
                          this.validateIsUserExist({
                            phone: value,
                          }).then((res) => {
                            if (res.data) {
                              callback(new Error('手机号已存在!'));
                            } else {
                              callback();
                            }
                          });
                        },
                      },
                    ],
                  })(<Input style={{ width: 350 }} placeholder="手机号" />)}
                </FormItem>
                <FormItem
                  {...formItemLayout}
                  label="设置密码"
                  extra={
                    <span style={{ color: 'red', fontSize: 14 }}>
                      系统默认初始密码：Zlgx888，可修改。
                    </span>
                  }
                >
                  {getFieldDecorator('user.password', {
                    validateTrigger: ['onBlur', 'onChange'],
                    rules: [
                      {
                        required: true,
                        trigger: 'blur',
                        message:
                          '支持字母、数字的组合，6至20位字符，区分大小写',
                        max: 12,
                        pattern: /^(?:(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])).{6,12}$/,
                      },
                      { validator: this.compareToNextPassword1 },
                    ],
                    initialValue: 'Zlgx888',
                  })(
                    <Input
                      style={{ width: 350 }}
                      placeholder="支持字母、数字的组合，6至20位字符，区分大小写"
                    />
                  )}
                </FormItem>
                <FormItem {...formItemLayout} label="确认密码">
                  {getFieldDecorator('user.confirmPassword', {
                    validateTrigger: ['onBlur', 'onChange'],
                    rules: [
                      {
                        required: true,
                        trigger: 'blur',
                        message:
                          '支持字母、数字的组合，6至20位字符，区分大小写',
                        max: 12,
                        pattern: /^(?:(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])).{6,12}$/,
                      },
                      { validator: this.compareToFirstPassword1 },
                    ],
                    initialValue: 'Zlgx888',
                  })(
                    <Input
                      style={{ width: 350 }}
                      placeholder="支持字母、数字的组合，6至20位字符，区分大小写"
                    />
                  )}
                </FormItem>
                <FormItem {...formItemLayout} label="邮箱地址">
                  {getFieldDecorator('user.email', {
                    rules: [
                      {
                        required: false,
                        message: '请输入正确格式的邮箱',
                        max: 32,
                        pattern: /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+\.[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)?$/,
                      },
                    ],
                  })(
                    <Input
                      maxLength={32}
                      style={{ width: 350 }}
                      placeholder="邮箱"
                    />
                  )}
                </FormItem>
                <FormItem {...formItemLayout} label="领域">
                  {getFieldDecorator('user.appDomain', {
                    rules: [{ required: true, message: '请选择领域' }],
                    initialValue: 'common',
                  })(
                    <Select
                      getPopupContainer={this.getPopupContainer}
                      style={{ width: 350 }}
                    >
                      {platforms.map((item) => {
                        return (
                          <Option key={item.appDomain} value={item.appDomain}>
                            {item.appDomainName}
                          </Option>
                        );
                      })}
                    </Select>
                  )}
                </FormItem>

                <FormItem {...formItemLayout} label="责任部门">
                  {getFieldDecorator('user.department', {
                    rules: [{ required: false, message: '请选择责任部门' }],
                    initialValue: '',
                  })(
                    <Select
                      getPopupContainer={this.getPopupContainer}
                      style={{ width: 350 }}
                      placeholder="请选择责任部门"
                      onChange={this.handleDepartmentChange}
                    >
                      {departments.map((item) => {
                        return (
                          <Option key={item.code} value={item.code || ''} id={item.id}>
                            {item.name}
                          </Option>
                        );
                      })}
                    </Select>
                  )}
                </FormItem>
                <FormItem {...formItemLayout} label="小组">
                  {getFieldDecorator('user.team', {
                    validateTrigger: ['onBlur', 'onChange'],
                  })(
                    <Select style={{ width: 350 }} getPopupContainer={this.getPopupContainer} loading={teamListLoading} disabled={!this.hasSelectDepartment()}>
                      {teamList.map((item) => {
                        return (
                          <Option key={item.code} value={item.code || ''}>
                            {item.name}
                          </Option>
                        );
                      })}
                    </Select>
                  )}
                </FormItem>
                <FormItem {...formItemLayout} label="业务员">
                  {getFieldDecorator('user.salesman', {
                    rules: [
                      {
                        required: false,
                        message: '最长5个字符',
                        max: 5,
                      },
                    ],
                  })(
                    <Input
                      maxLength={5}
                      style={{ width: 350 }}
                      placeholder="请输入业务员"
                    />
                  )}
                </FormItem>

                <FormItem {...formItemLayout} label="内部账户">
                  {getFieldDecorator('user.isInternalUser', {
                    initialValue: false,
                  })(
                    <RadioGroup>
                      <Radio value={true}>是</Radio>
                      <Radio value={false}>否</Radio>
                    </RadioGroup>
                  )}
                </FormItem>
                <FormItem {...formItemLayout} label="用户状态">
                  {getFieldDecorator('user.isActive', {
                    initialValue: true,
                  })(
                    <RadioGroup>
                      <Radio value={true}>正常</Radio>
                      <Radio value={false}>禁用</Radio>
                    </RadioGroup>
                  )}
                </FormItem>
              </Form>
            )}
            {this.state.accountType === 'oldAccount' && (
              <div style={{ padding: '0 0 0 16.66%' }}>
                <div>
                  <Input
                    onBlur={(e) => {
                      this.searchUserCertInfo(e.target.value);
                    }}
                    style={{ width: 350 }}
                    addonBefore="检索"
                    placeholder="请输入手机号码/用户名检索"
                  />
                </div>
                <div style={{ padding: '10px 0 0 0' }}>
                  <span>会员编号：</span>
                  <span>{userCertInfo && userCertInfo.userNo}</span>
                </div>
                <div style={{ padding: '10px 0 0 0' }}>
                  <span>用户名：</span>
                  <span>{userCertInfo && userCertInfo.username}</span>
                </div>
                <div style={{ padding: '10px 0 0 0' }}>
                  <span>手机号码：</span>
                  <span>{userCertInfo && userCertInfo.phone}</span>
                </div>
                <div style={{ padding: '10px 0 0 0' }}>
                  <span>账户关系：</span>
                  <span>{userCertInfo && userCertInfo.relationship}</span>
                </div>
                <div style={{ padding: '10px 0 0 0' }}>
                  {userCertInfo && userCertInfo.canAddCert ? (
                    ''
                  ) : (
                    <span style={{ color: 'red' }}>
                      注意:已认证账户或子账户暂时不支持添加!
                    </span>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        <h3>认证信息</h3>
        <div style={{ overflow: 'hidden' }}>
          <div style={{ float: 'left', width: 100 }}></div>
          <div style={{ marginLeft: 100 }}>
            <div style={{ width: '16.666%', display: 'inline-block' }}></div>
            <div
              style={{
                padding: '10px 0 30px 0',
                display: 'inline-block',
              }}
            >
              <RadioGroup
                onChange={this.onUserTypeChange}
                disabled={!(userCertInfo && userCertInfo.canAddCert)}
                value={this.state.userType}
              >
                <Radio value={'company'}>企业</Radio>
                <Radio value={'personal'}>司机</Radio>
              </RadioGroup>
            </div>
          </div>
        </div>
        {this.state.userType === 'company' && (
          <div>
            <div style={{ overflow: 'hidden' }}>
              <div style={{ float: 'left', width: 100 }}>基础信息</div>
              <div style={{ marginLeft: 100 }}>
                <Form>
                  <FormItem {...formItemLayout} label="公司名称：">
                    {getFieldDecorator('cert.baseInfo.companyNameZh', {
                      validateTrigger: ['onBlur', 'onChange'],
                      rules: [
                        {
                          required: true,
                          whitespace: true,
                          validator: (rule, value = '', callback) => {
                            if (!value) {
                              callback('请输入公司名称');
                              return;
                            }
                            this.validateIsUserExist({
                              certName: formatStringSymbol(value.trim()),
                            }).then((res) => {
                              if (res.data) {
                                callback(new Error('公司名称已存在!'));
                              } else {
                                callback();
                              }
                            });
                          },
                        },
                      ],
                    })(
                      <Input
                        disabled={!(userCertInfo && userCertInfo.canAddCert)}
                        style={{ width: 350 }}
                        placeholder="请输入公司名称"
                      />
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="公司英文名：">
                    {getFieldDecorator('cert.baseInfo.companyNameEn', {
                      rules: [
                        {
                          required: false,
                          message: '请输入公司英文名',
                        },
                      ],
                    })(
                      <Input
                        disabled={!(userCertInfo && userCertInfo.canAddCert)}
                        style={{ width: 350 }}
                        placeholder="请输入公司英文名"
                      />
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="公司简称：">
                    {getFieldDecorator('cert.baseInfo.companyAbbreviation', {
                      rules: [
                        {
                          required: false,
                          message: '请输入公司简称',
                        },
                      ],
                    })(
                      <Input
                        disabled={!(userCertInfo && userCertInfo.canAddCert)}
                        style={{ width: 350 }}
                        placeholder="请输入公司简称"
                      />
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="公司曾用名：">
                    {getFieldDecorator('cert.baseInfo.usedCompanyName', {
                      rules: [
                        {
                          required: false,
                          message: '请输入公司曾用名',
                        },
                      ],
                    })(
                      <Input
                        disabled={!(userCertInfo && userCertInfo.canAddCert)}
                        style={{ width: 350 }}
                        placeholder="请输入公司曾用名"
                      />
                    )}
                  </FormItem>
                  <FormItem
                    labelCol={{ span: 16 }}
                    wrapperCol={{ span: 8 }}
                    style={{ width: '25%', display: 'inline-block' }}
                    label="所在地："
                  >
                    {getFieldDecorator('cert.baseInfo.provinceCode', {
                      rules: [
                        {
                          required: true,
                          message: '请选择省',
                        },
                      ],
                    })(
                      <Select
                        showSearch
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        disabled={!(userCertInfo && userCertInfo.canAddCert)}
                        onChange={this.onProvinceChange}
                        getPopupContainer={this.getPopupContainer}
                      >
                        {province.map((item) => {
                          return (
                            <Option value={item.id} key={item.id}>
                              {item.name}
                            </Option>
                          );
                        })}
                      </Select>
                    )}
                  </FormItem>
                  <FormItem
                    labelCol={{ span: 1 }}
                    wrapperCol={{ span: 23 }}
                    style={{
                      width: '10%',
                      display: 'inline-block',
                      marginLeft: 5,
                    }}
                    label=""
                  >
                    {getFieldDecorator('cert.baseInfo.cityCode', {
                      rules: [
                        {
                          required: true,
                          message: '请选择市',
                        },
                      ],
                    })(
                      <Select
                        showSearch
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        disabled={!(userCertInfo && userCertInfo.canAddCert)}
                        onChange={this.onCityChange}
                        getPopupContainer={this.getPopupContainer}
                      >
                        {city.map((item) => {
                          return (
                            <Option value={item.id} key={item.id}>
                              {item.name}
                            </Option>
                          );
                        })}
                      </Select>
                    )}
                  </FormItem>
                  <FormItem
                    labelCol={{ span: 1 }}
                    wrapperCol={{ span: 23 }}
                    style={{ width: '10%', display: 'inline-block' }}
                    label=""
                  >
                    {getFieldDecorator('cert.baseInfo.districtCode', {
                      rules: [
                        {
                          required: true,
                          message: '请选择区/县',
                        },
                      ],
                    })(
                      <Select
                        showSearch
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        disabled={!(userCertInfo && userCertInfo.canAddCert)}
                        onChange={this.onDistrictChange}
                        getPopupContainer={this.getPopupContainer}
                      >
                        {district.map((item) => {
                          return (
                            <Option value={item.id} key={item.id}>
                              {item.name}
                            </Option>
                          );
                        })}
                      </Select>
                    )}
                  </FormItem>
                  <FormItem
                    labelCol={{ span: 1 }}
                    wrapperCol={{ span: 23 }}
                    style={{ width: '20%', display: 'inline-block' }}
                    label=""
                  >
                    {getFieldDecorator('cert.baseInfo.address', {
                      validateTrigger: ['onBlur', 'onChange'],
                      rules: [
                        {
                          required: true,
                          whitespace: true,
                          message: '请选择输入详细地址',
                        },
                      ],
                    })(
                      <Input
                        disabled={!(userCertInfo && userCertInfo.canAddCert)}
                        style={{ width: 350 }}
                        placeholder="详细街道地址"
                      />
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="公司注册时间">
                    {getFieldDecorator('cert.baseInfo.registerTime', {
                      rules: [
                        {
                          required: true,
                          message: '请选择公司注册时间',
                        },
                      ],
                    })(
                      <DatePicker onChange={() => {}} style={{ width: 250 }} />
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="公司网站">
                    {getFieldDecorator('cert.baseInfo.website', {
                      validateTrigger: ['onBlur', 'onChange'],
                      rules: [
                        {
                          required: false,
                          whitespace: true,
                          message: '请输入公司网站',
                        },
                      ],
                    })(<Input style={{ width: 350 }} placeholder="公司网站" />)}
                  </FormItem>
                  <FormItem {...formItemLayout} label="受邀钢厂">
                    {getFieldDecorator('cert.baseInfo.invitedSteel', {
                      validateTrigger: ['onBlur', 'onChange'],
                      rules: [
                        {
                          required: true,
                          message: '请选择受邀钢厂',
                        },
                      ],
                    })(
                      <Select
                        getPopupContainer={this.getPopupContainer}
                        style={{ width: 350 }}
                        placeholder="请选择受邀钢厂"
                      >
                        {invitednterprises.map((item) => {
                          return (
                            <Option
                              key={item.code || 'none'}
                              value={item.code || 'none'}
                            >
                              {item.name}
                            </Option>
                          );
                        })}
                      </Select>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="认证目的">
                    {getFieldDecorator('cert.baseInfo.authPurpose', {
                      validateTrigger: ['onBlur', 'onChange'],
                      rules: [
                        {
                          required: false,
                          whitespace: true,
                          message: '请输入认证目的',
                        },
                      ],
                    })(
                      <Input
                        maxLength={50}
                        style={{ width: 350 }}
                        placeholder="比如参与交易、招投标等"
                      />
                    )}
                  </FormItem>
                </Form>
              </div>
            </div>
            <div style={{ overflow: 'hidden' }}>
              <div style={{ float: 'left', width: 100 }}>联系方式</div>
              <div style={{ marginLeft: 100 }}>
                <Form>
                  <FormItem {...formItemLayout} label="联系人：">
                    {getFieldDecorator('cert.contactInfo.contactPerson', {
                      validateTrigger: ['onBlur', 'onChange'],
                      rules: [
                        {
                          required: true,
                          whitespace: true,
                          message: '请输入联系人',
                        },
                      ],
                    })(
                      <Input
                        disabled={!(userCertInfo && userCertInfo.canAddCert)}
                        style={{ width: 350 }}
                        placeholder="输入联系人"
                      />
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="联系电话：">
                    {getFieldDecorator('cert.contactInfo.phone', {
                      validateTrigger: ['onBlur', 'onChange'],
                      rules: [
                        {
                          required: true,
                          whitespace: true,
                          pattern: /^1[\d]{10}$/,
                          message: '请输入联系电话',
                        },
                      ],
                    })(
                      <Input
                        disabled={!(userCertInfo && userCertInfo.canAddCert)}
                        maxLength={11}
                        style={{ width: 350 }}
                        placeholder="输入联系电话"
                      />
                    )}
                  </FormItem>

                  <FormItem {...formItemLayout} label="企业电话：">
                    {getFieldDecorator('cert.baseInfo.enterprisePhone', {
                      validateTrigger: ['onBlur', 'onChange'],
                      rules: [
                        {
                          required: false,
                          whitespace: true,
                          message: '请输入企业电话',
                        },
                      ],
                    })(
                      <Input
                        style={{ width: 350 }}
                        placeholder="输入企业电话"
                      />
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="企业传真：">
                    {getFieldDecorator('cert.baseInfo.corporateFax', {
                      validateTrigger: ['onBlur', 'onChange'],
                      rules: [
                        {
                          required: false,
                          whitespace: true,
                          message: '请输入企业传真',
                        },
                      ],
                    })(
                      <Input
                        style={{ width: 350 }}
                        maxLength={20}
                        placeholder="请输入企业传真"
                      />
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="邮编：">
                    {getFieldDecorator('cert.baseInfo.zipCode', {
                      validateTrigger: ['onBlur', 'onChange'],
                      rules: [
                        {
                          required: false,
                          whitespace: true,
                          pattern: /^\d{6}$/,
                          message: '请输入邮编',
                        },
                      ],
                    })(<Input style={{ width: 350 }} placeholder="输入邮编" />)}
                  </FormItem>
                  <FormItem {...formItemLayout} label="QQ：">
                    {getFieldDecorator('cert.contactInfo.qq', {
                      rules: [
                        {
                          required: false,
                          message: '请输入QQ',
                          pattern: /^\d{6,20}$/,
                        },
                      ],
                    })(
                      <Input
                        disabled={!(userCertInfo && userCertInfo.canAddCert)}
                        style={{ width: 350 }}
                        placeholder="输入QQ"
                      />
                    )}
                  </FormItem>
                </Form>
              </div>
            </div>
            <div style={{ overflow: 'hidden' }}>
              <div style={{ float: 'left', width: 100 }}>证件附件</div>
              <div style={{ marginLeft: 100 }}>
                <Form>
                  <FormItem {...formItemLayout} label="营业执照(三证合一)：">
                    {getFieldDecorator('cert.attachment.businessLicenceUrl', {
                      rules: [
                        {
                          required: true,
                          message: '请上传营业执照',
                        },
                      ],
                      initialValue: null,
                      getValueFromEvent: this.getFileData,
                    })(
                      <Upload
                        name="file"
                        disabled={!(userCertInfo && userCertInfo.canAddCert)}
                        accept="image/jpg,image/jpeg,image/png"
                        action={env.getUploadApi('/v1/cert/upload')}
                        beforeUpload={(file, fileList) => {
                          return this.beforeUpload(true, file, fileList);
                        }}
                        onChange={({ file, fileList }) => {
                          this.onUploadChange(true, file, fileList);
                        }}
                        onRemove={(file, fileList) => {
                          this.onRemove('businessLicenceUrl', file, fileList);
                        }}
                        onPreview={(file) => {
                          this.onPreview(file);
                        }}
                        listType="picture-card"
                      >
                        <div>
                          <Icon
                            type={this.state.loading ? 'loading' : 'plus'}
                          />
                          <div className="ant-upload-text">点击上传</div>
                        </div>
                      </Upload>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="组织机构代码：">
                    {getFieldDecorator('cert.attachment.organizationCodeUrl', {
                      rules: [
                        {
                          required: false,
                          message: '请上传组织机构代码',
                        },
                      ],
                      initialValue: null,
                      getValueFromEvent: this.getFileData,
                    })(
                      <Upload
                        name="file"
                        disabled={!(userCertInfo && userCertInfo.canAddCert)}
                        accept="image/jpg,image/jpeg,image/png"
                        action={env.getUploadApi('/v1/cert/upload')}
                        beforeUpload={(file, fileList) => {
                          return this.beforeUpload(true, file, fileList);
                        }}
                        onChange={({ file, fileList }) => {
                          this.onUploadChange(true, file, fileList);
                        }}
                        onRemove={(file, fileList) => {
                          this.onRemove('organizationCodeUrl', file, fileList);
                        }}
                        onPreview={(file) => {
                          this.onPreview(file);
                        }}
                        listType="picture-card"
                      >
                        <div>
                          <Icon
                            type={this.state.loading ? 'loading' : 'plus'}
                          />
                          <div className="ant-upload-text">点击上传</div>
                        </div>
                      </Upload>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="税务登记证：">
                    {getFieldDecorator('cert.attachment.taxRegistryUrl', {
                      rules: [
                        {
                          required: false,
                          message: '请上传税务登记证',
                        },
                      ],
                      initialValue: null,
                      getValueFromEvent: this.getFileData,
                    })(
                      <Upload
                        name="file"
                        disabled={!(userCertInfo && userCertInfo.canAddCert)}
                        accept="image/jpg,image/jpeg,image/png"
                        action={env.getUploadApi('/v1/cert/upload')}
                        beforeUpload={(file, fileList) => {
                          return this.beforeUpload(true, file, fileList);
                        }}
                        onChange={({ file, fileList }) => {
                          this.onUploadChange(true, file, fileList);
                        }}
                        onRemove={(file, fileList) => {
                          this.onRemove('taxRegistryUrl', file, fileList);
                        }}
                        onPreview={(file) => {
                          this.onPreview(file);
                        }}
                        listType="picture-card"
                      >
                        <div>
                          <Icon
                            type={this.state.loading ? 'loading' : 'plus'}
                          />
                          <div className="ant-upload-text">点击上传</div>
                        </div>
                      </Upload>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="开户许可证：">
                    {getFieldDecorator('cert.attachment.accountLicenseUrl', {
                      rules: [
                        {
                          required: hasCenpur,
                          message: '请上传开户许可证',
                        },
                      ],
                      initialValue: null,
                      getValueFromEvent: this.getFileData,
                    })(
                      <Upload
                        name="file"
                        disabled={!(userCertInfo && userCertInfo.canAddCert)}
                        accept="image/jpg,image/jpeg,image/png"
                        action={env.getUploadApi('/v1/cert/upload')}
                        beforeUpload={(file, fileList) => {
                          return this.beforeUpload(true, file, fileList);
                        }}
                        onChange={({ file, fileList }) => {
                          this.onUploadChange(true, file, fileList);
                        }}
                        onRemove={(file, fileList) => {
                          this.onRemove('accountLicenseUrl', file, fileList);
                        }}
                        onPreview={(file) => {
                          this.onPreview(file);
                        }}
                        listType="picture-card"
                      >
                        <div>
                          <Icon
                            type={this.state.loading ? 'loading' : 'plus'}
                          />
                          <div className="ant-upload-text">点击上传</div>
                        </div>
                      </Upload>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="委托书类型：">
                    {getFieldDecorator('cert.proxyType', {
                      rules: [
                        {
                          required: true,
                          message: '请选择委托书类型',
                        },
                      ],
                      initialValue: '',
                    })(
                      <RadioGroup
                        disabled={!(userCertInfo && userCertInfo.canAddCert)}
                        onChange={this.onProxyTypeChange}
                      >
                        <Radio value={'1'}>企业授权委托书</Radio>
                        <Radio value={'2'}>业务担保授权书</Radio>
                        <Radio value={'3'}>无委托书，自愿不进行交易</Radio>
                      </RadioGroup>
                    )}
                  </FormItem>

                  {showProxy && (
                    <FormItem
                      {...formItemLayout}
                      label={
                        <span>
                          委托书附件:
                          <a
                            href={authorCertTemplate}
                            style={{
                              fontSize: 12,
                              whiteSpace: 'nowrap',
                              marginLeft: 8,
                            }}
                          >
                            模板查看与下载
                          </a>
                        </span>
                      }
                    >
                      {getFieldDecorator('cert.proxy', {
                        rules: [
                          {
                            required: true,
                            message: '请上传委托书附件',
                          },
                        ],
                        initialValue: [],
                        getValueFromEvent: this.getProxyFileData,
                      })(
                        <Upload
                          name="file"
                          disabled={!(userCertInfo && userCertInfo.canAddCert)}
                          accept="image/jpg,image/jpeg,image/png"
                          action={env.getUploadApi('/v1/cert/upload')}
                          beforeUpload={(file, fileList) => {
                            return this.beforeUpload(false, file, fileList);
                          }}
                          onChange={({ file, fileList }) => {
                            this.onUploadChange(false, file, fileList);
                          }}
                          onRemove={(file, fileList) => {
                            this.onRemove('proxy', file, fileList);
                          }}
                          onPreview={(file) => {
                            this.onPreview(file);
                          }}
                          listType="picture-card"
                        >
                          <div>
                            <Icon
                              type={this.state.loading ? 'loading' : 'plus'}
                            />
                            <div className="ant-upload-text">点击上传</div>
                          </div>
                        </Upload>
                      )}
                    </FormItem>
                  )}
                  {hasCenpurSupplier && (
                    <FormItem {...formItemLayout} label="供应品类">
                      {getFieldDecorator('additionalCert.supplyCategory', {
                        rules: [
                          {
                            required: true,
                            message: '请选择供应品类',
                            validator: (rule, value, callback) => {
                              if (
                                rule.required &&
                                value.targetKeys &&
                                !value.targetKeys.length
                              ) {
                                callback('请选择供应品类');
                              } else {
                                callback();
                              }
                            },
                          },
                        ],
                        initialValue: { targetKeys: [] },
                      })(<TreeTransfer />)}
                    </FormItem>
                  )}
                  {
                    hasCenpurSupplier && (
                      <FormItem {...formItemLayout} label="厂家类型">
                        {
                          getFieldDecorator('additionalCert.manufactorType', {
                            initialValue: undefined,
                            rules: [
                              {
                                required: true,
                                message: '请选择厂家类型',
                              }
                            ],
                          })(
                            <Select
                              style={{ width: 350 }}
                              getPopupContainer={this.getPopupContainer}
                              placeholder="请选择厂家类型"
                            >
                              {manufactorTypeList.map((item) => {
                                return <Option value={item.code}>{item.name}</Option>;
                              })}
                            </Select>
                          )
                        }
                      </FormItem>
                    )
                  }
                  {hasCenpurPurchaser && (
                    <FormItem {...formItemLayout} label="采购品类">
                      {getFieldDecorator('additionalCert.purchaserCategory', {
                        rules: [
                          {
                            required: true,
                            message: '请选择采购品类',
                            validator: (rule, value, callback) => {
                              if (
                                rule.required &&
                                value.targetKeys &&
                                !value.targetKeys.length
                              ) {
                                callback('请选择采购品类');
                              } else {
                                callback();
                              }
                            },
                          },
                        ],
                        initialValue: { targetKeys: [] },
                      })(<TreeTransfer />)}
                    </FormItem>
                  )}
                </Form>
              </div>
            </div>
            <div style={{ overflow: 'hidden' }}>
              <div style={{ float: 'left', width: 100 }}>其他信息</div>
              <div style={{ marginLeft: 100 }}>
                <Form>
                  <FormItem {...formItemLayout} label="外贸资质：">
                    {getFieldDecorator('additionalCert.hasForeignTradeCert', {
                      rules: [
                        {
                          required: false,
                          message: '请选择是否具有外贸资质',
                        },
                      ],
                    })(
                      <RadioGroup
                        disabled={!(userCertInfo && userCertInfo.canAddCert)}
                      >
                        <Radio value={1}>具备</Radio>
                        <Radio value={2}>不具备</Radio>
                      </RadioGroup>
                    )}
                  </FormItem>
                  <FormItem
                    {...formItemLayout}
                    label="对外贸易经营者备案登记表附件："
                  >
                    {getFieldDecorator(
                      'additionalCert.foreignTradeRegistrationUrl',
                      {
                        rules: [
                          {
                            required: false,
                            message: '请上传对外贸易经营者备案登记表附件',
                          },
                        ],
                        initialValue: null,
                        getValueFromEvent: this.getFileData,
                      }
                    )(
                      <Upload
                        name="file"
                        disabled={!(userCertInfo && userCertInfo.canAddCert)}
                        accept="image/jpg,image/jpeg,image/png"
                        action={env.getUploadApi('/v1/cert/upload')}
                        beforeUpload={(file, fileList) => {
                          return this.beforeUpload(true, file, fileList);
                        }}
                        onChange={({ file, fileList }) => {
                          this.onUploadChange(true, file, fileList);
                        }}
                        onRemove={(file, fileList) => {
                          this.onRemove(
                            'foreignTradeRegistrationUrl',
                            file,
                            fileList
                          );
                        }}
                        onPreview={(file) => {
                          this.onPreview(file);
                        }}
                        listType="picture-card"
                      >
                        <div>
                          <Icon
                            type={this.state.loading ? 'loading' : 'plus'}
                          />
                          <div className="ant-upload-text">点击上传</div>
                        </div>
                      </Upload>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="进出口企业代码号：">
                    {getFieldDecorator(
                      'additionalCert.foreignTradeEnterpriseCode',
                      {
                        rules: [
                          {
                            required: false,
                            message: '请输入进出口企业代码号',
                          },
                        ],
                      }
                    )(
                      <Input
                        disabled={!(userCertInfo && userCertInfo.canAddCert)}
                        style={{ width: 350 }}
                        placeholder="请输入进出口企业代码号"
                      />
                    )}
                  </FormItem>
                </Form>
              </div>
            </div>
            <div style={{ overflow: 'hidden' }}>
              <div style={{ float: 'left', width: 100 }}>审核补录信息</div>
              <div style={{ marginLeft: 100 }}>
                <Form>
                  <FormItem {...formItemLayout} label="经营性质：">
                    {getFieldDecorator(
                      'auditAdditionalCert.proprietaryDealer',
                      {
                        rules: [
                          {
                            required: true,
                            whitespace: true,
                            message: '请选择经营性质',
                          },
                        ],
                        initialValue: 'not_proprietary_dealer',
                      }
                    )(
                      <Select
                        style={{ width: 350 }}
                        disabled={!(userCertInfo && userCertInfo.canAddCert)}
                        getPopupContainer={this.getPopupContainer}
                      >
                        {businessCharacter.map((item) => {
                          return <Option value={item.code}>{item.name}</Option>;
                        })}
                      </Select>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="公司性质：">
                    {getFieldDecorator('auditAdditionalCert.companyCharacter', {
                      validateTrigger: ['onBlur', 'onChange'],
                      rules: [
                        {
                          required: true,
                          whitespace: true,
                          message: '请输入公司性质',
                        },
                      ],
                    })(
                      <AutoComplete
                        disabled={!(userCertInfo && userCertInfo.canAddCert)}
                        onChange={(v) => {
                          this.getCompanyCharacter(v);
                        }}
                        style={{ width: 350 }}
                        placeholder="请输入公司性质"
                      >
                        {companyCharacter.map((item) => {
                          return (
                            <AOption key={item.code} value={item.name}>
                              {item.name}
                            </AOption>
                          );
                        })}
                      </AutoComplete>
                    )}
                  </FormItem>

                  <FormItem {...formItemLayout} label="注册资本(万元)：">
                    {getFieldDecorator(
                      'auditAdditionalCert.registeredCapital',
                      {
                        validateTrigger: ['onBlur', 'onChange'],
                        rules: [
                          {
                            required: true,
                            whitespace: true,
                            pattern: /^\d+(\.(?:\d+))?$/,
                            message: '请输入注册资本',
                          },
                        ],
                      }
                    )(
                      <Input
                        style={{ width: 350 }}
                        placeholder="请输入注册资本"
                      />
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="公司经营范围：">
                    {getFieldDecorator(
                      'auditAdditionalCert.companyBusinessScope',
                      {
                        validateTrigger: ['onBlur', 'onChange'],
                        rules: [
                          {
                            required: true,
                            whitespace: true,
                            message: '请输入公司经营范围',
                          },
                        ],
                      }
                    )(
                      <TextArea
                        maxLength={500}
                        rows={4}
                        disabled={!(userCertInfo && userCertInfo.canAddCert)}
                        style={{ width: 350 }}
                        placeholder="请输入公司经营范围"
                      />
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="法人姓名：">
                    {getFieldDecorator('auditAdditionalCert.legalPersonName', {
                      validateTrigger: ['onBlur', 'onChange'],
                      rules: [
                        {
                          required: true,
                          whitespace: true,
                          message: '请输入法人姓名',
                        },
                      ],
                    })(
                      <Input
                        disabled={!(userCertInfo && userCertInfo.canAddCert)}
                        style={{ width: 350 }}
                        placeholder="请输入法人姓名"
                      />
                    )}
                  </FormItem>

                  <FormItem {...formItemLayout} label="法人身份证附件正面：">
                    {getFieldDecorator(
                      'auditAdditionalCert.legalPersonIdentityCardFrontUrl',
                      {
                        rules: [
                          {
                            required: false,
                            message: '请上传法人身份证附件正面',
                          },
                        ],
                        initialValue: null,
                        getValueFromEvent: this.getFileData,
                      }
                    )(
                      <Upload
                        name="file"
                        disabled={!(userCertInfo && userCertInfo.canAddCert)}
                        accept="image/jpg,image/jpeg,image/png"
                        action={env.getUploadApi('/v1/cert/upload')}
                        beforeUpload={(file, fileList) => {
                          return this.beforeUpload(true, file, fileList);
                        }}
                        onChange={({ file, fileList }) => {
                          this.onUploadChange(true, file, fileList);
                        }}
                        onRemove={(file, fileList) => {
                          this.onRemove(
                            'legalPersonIdentityCardFrontUrl',
                            file,
                            fileList
                          );
                        }}
                        onPreview={(file) => {
                          this.onPreview(file);
                        }}
                        listType="picture-card"
                      >
                        <div>
                          <Icon
                            type={this.state.loading ? 'loading' : 'plus'}
                          />
                          <div className="ant-upload-text">点击上传</div>
                        </div>
                      </Upload>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="法人身份证附件背面：">
                    {getFieldDecorator(
                      'auditAdditionalCert.legalPersonIdentityCardBackUrl',
                      {
                        rules: [
                          {
                            required: false,
                            message: '请上传法人身份证附件背面',
                          },
                        ],
                        initialValue: null,
                        getValueFromEvent: this.getFileData,
                      }
                    )(
                      <Upload
                        name="file"
                        disabled={!(userCertInfo && userCertInfo.canAddCert)}
                        accept="image/jpg,image/jpeg,image/png"
                        action={env.getUploadApi('/v1/cert/upload')}
                        beforeUpload={(file, fileList) => {
                          return this.beforeUpload(true, file, fileList);
                        }}
                        onChange={({ file, fileList }) => {
                          this.onUploadChange(true, file, fileList);
                        }}
                        onRemove={(file, fileList) => {
                          this.onRemove(
                            'legalPersonIdentityCardBackUrl',
                            file,
                            fileList
                          );
                        }}
                        onPreview={(file) => {
                          this.onPreview(file);
                        }}
                        listType="picture-card"
                      >
                        <div>
                          <Icon
                            type={this.state.loading ? 'loading' : 'plus'}
                          />
                          <div className="ant-upload-text">点击上传</div>
                        </div>
                      </Upload>
                    )}
                  </FormItem>

                  <FormItem {...formItemLayout} label="营业执照期限：">
                    {getFieldDecorator(
                      'auditAdditionalCert.businessLicenseExpirationTime',
                      {
                        rules: [
                          {
                            required: true,
                            message: '请选择营业执照期限',
                            validator: (rule, value, callback) => {
                              if (!value.expirationTime) {
                                callback('请选择营业执照期限!');
                              } else {
                                callback();
                              }
                            },
                          },
                        ],
                        initialValue: { expirationTime: null },
                      }
                    )(
                      <DatePickAndCheckboxFormItem
                        disabled={!(userCertInfo && userCertInfo.canAddCert)}
                      />
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="社会统一信用代码：">
                    {getFieldDecorator(
                      'auditAdditionalCert.unifiedSocialCreditCode',
                      {
                        validateTrigger: ['onBlur', 'onChange'],
                        rules: [
                          {
                            required: true,
                            whitespace: true,
                            message: '请输入社会统一信用代码',
                          },
                        ],
                      }
                    )(
                      <Input
                        disabled={!(userCertInfo && userCertInfo.canAddCert)}
                        style={{ width: 350 }}
                        placeholder="请输入社会统一信用代码"
                      />
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="委托人姓名：">
                    {getFieldDecorator('auditAdditionalCert.agentName', {
                      validateTrigger: ['onBlur', 'onChange'],
                      rules: [
                        {
                          required: true,
                          whitespace: true,
                          message: '请输入委托人姓名',
                        },
                      ],
                    })(
                      <Input
                        disabled={!(userCertInfo && userCertInfo.canAddCert)}
                        style={{ width: 350 }}
                        placeholder="请输入委托人姓名"
                      />
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="开户许可证核准号：">
                    {getFieldDecorator('auditAdditionalCert.accountLicenseNo', {
                      rules: [
                        {
                          required: false,
                          message: '请输入开户许可证核准号',
                        },
                      ],
                    })(
                      <Input
                        disabled={!(userCertInfo && userCertInfo.canAddCert)}
                        style={{ width: 350 }}
                        placeholder="请输入开户许可证核准号"
                      />
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="开户行：">
                    {getFieldDecorator('auditAdditionalCert.openBank', {
                      rules: [
                        {
                          required: hasCenpur,
                          message: '请输入开户行',
                        },
                      ],
                    })(
                      <Input
                        style={{ width: 350 }}
                        placeholder="请输入开户行"
                      />
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="户名：">
                    {getFieldDecorator('auditAdditionalCert.accountName', {
                      rules: [
                        {
                          required: hasCenpur,
                          message: '请输入户名',
                        },
                      ],
                    })(
                      <Input style={{ width: 350 }} placeholder="请输入户名" />
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="账号：">
                    {getFieldDecorator('auditAdditionalCert.accountNo', {
                      rules: [
                        {
                          required: hasCenpur,
                          pattern: /^\d{1,50}$/,
                          message: '请输入正确格式的账号',
                        },
                      ],
                    })(
                      <Input style={{ width: 350 }} placeholder="请输入账号" />
                    )}
                  </FormItem>

                  <FormItem {...formItemLayout} label="保证金：">
                    {getFieldDecorator('auditAdditionalCert.bond', {
                      rules: [
                        {
                          required: false,
                          pattern: /^\d+\.?(?:\d+)$/,
                          message: '请输入保证金',
                        },
                      ],
                    })(
                      <Input
                        style={{ width: 350 }}
                        placeholder="请输入保证金"
                      />
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="委托人电话：">
                    {getFieldDecorator('auditAdditionalCert.agentPhone', {
                      rules: [
                        {
                          required: false,
                          pattern: /^1[\d]{10}$/,
                          message: '请输入委托人电话',
                        },
                      ],
                    })(
                      <Input
                        disabled={!(userCertInfo && userCertInfo.canAddCert)}
                        maxLength={11}
                        style={{ width: 350 }}
                        placeholder="请输入委托人电话"
                      />
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="委托人身份证附件正面：">
                    {getFieldDecorator(
                      'auditAdditionalCert.agentIdentityCardFrontUrl',
                      {
                        rules: [
                          {
                            required: false,
                            message: '请上传委托人身份证附件正面',
                          },
                        ],
                        initialValue: null,
                        getValueFromEvent: this.getFileData,
                      }
                    )(
                      <Upload
                        name="file"
                        disabled={!(userCertInfo && userCertInfo.canAddCert)}
                        accept="image/jpg,image/jpeg,image/png"
                        action={env.getUploadApi('/v1/cert/upload')}
                        beforeUpload={(file, fileList) => {
                          return this.beforeUpload(true, file, fileList);
                        }}
                        onChange={({ file, fileList }) => {
                          this.onUploadChange(true, file, fileList);
                        }}
                        onRemove={(file, fileList) => {
                          this.onRemove(
                            'agentIdentityCardFrontUrl',
                            file,
                            fileList
                          );
                        }}
                        onPreview={(file) => {
                          this.onPreview(file);
                        }}
                        listType="picture-card"
                      >
                        <div>
                          <Icon
                            type={this.state.loading ? 'loading' : 'plus'}
                          />
                          <div className="ant-upload-text">点击上传</div>
                        </div>
                      </Upload>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="委托人身份证附件背面：">
                    {getFieldDecorator(
                      'auditAdditionalCert.agentIdentityCardBackUrl',
                      {
                        rules: [
                          {
                            required: false,
                            message: '请上传委托人身份证附件背面',
                          },
                        ],
                        initialValue: null,
                        getValueFromEvent: this.getFileData,
                      }
                    )(
                      <Upload
                        name="file"
                        disabled={!(userCertInfo && userCertInfo.canAddCert)}
                        accept="image/jpg,image/jpeg,image/png"
                        action={env.getUploadApi('/v1/cert/upload')}
                        beforeUpload={(file, fileList) => {
                          return this.beforeUpload(true, file, fileList);
                        }}
                        onChange={({ file, fileList }) => {
                          this.onUploadChange(true, file, fileList);
                        }}
                        onRemove={(file, fileList) => {
                          this.onRemove(
                            'agentIdentityCardBackUrl',
                            file,
                            fileList
                          );
                        }}
                        onPreview={(file) => {
                          this.onPreview(file);
                        }}
                        listType="picture-card"
                      >
                        <div>
                          <Icon
                            type={this.state.loading ? 'loading' : 'plus'}
                          />
                          <div className="ant-upload-text">点击上传</div>
                        </div>
                      </Upload>
                    )}
                  </FormItem>

                  <FormItem {...formItemLayout} label="开通身份：">
                    {getFieldDecorator('identity', {
                      initialValue: this.state.CheckedIdentityList,
                      rules: [
                        {
                          required: true,
                          message: '至少选择一种身份',
                        },
                      ],
                    })(
                      <CheckboxGroup
                        disabled={!(userCertInfo && userCertInfo.canAddCert)}
                        onChange={this.onCheckedIdentityListChange}
                        options={identityDictList.filter(
                          (item) => item.certType === '1'
                        )}
                      />
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="是否开通资金账户：">
                    {getFieldDecorator('addFundsAccount', {
                      initialValue: false,
                      rules: [
                        {
                          required: true,
                          message: '请选择是否开通资金账户',
                        },
                      ],
                    })(
                      <RadioGroup
                        disabled={!(userCertInfo && userCertInfo.canAddCert)}
                        name="addFundsAccount"
                        options={[
                          { label: '开通', value: true },
                          { label: '不开通', value: false },
                        ]}
                      ></RadioGroup>
                    )}
                  </FormItem>
                </Form>
              </div>
            </div>
            <div style={{ padding: '0 0 50px 150px' }}>
              <Button
                type="primary"
                disabled={!(userCertInfo && userCertInfo.canAddCert)}
                onClick={() => {
                  this.openAuthAccount('company');
                }}
              >
                确认
              </Button>
            </div>
          </div>
        )}
        {this.state.userType === 'personal' && (
          <PersonEditor
            operates={[
              {
                text: '确认',
                validate: true,
                func: this.handlePersonSubmit,
              },
            ]}
            showFundsPicker
          />
        )}
        <ViewerContainer imageUrl={this.state.bigImgUrl} onClose={this.closeBigImgModal} visible={this.state.showBigImg} />
      </div>
    );
  }
}
export default createAuthenticateAccount;
