import React from 'react';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import Immutable from 'immutable';
import reaction from 'reaction';
import { Table, Button } from 'antd';
import { historyPush } from 'utils/history';
import { RoutePath } from 'utils/constants';
// import env from 'utils/env';
import { resolveUrlQuery, checkHasDriverIdentity } from 'utils';
import PersonDetail from 'modules/PersonDetail';

import commonStyles from 'style/common.module.scss';

// Review: 资质审核 - 司机 - 审核历史

@connect(({ qualification, memberCenter, me, common }) => ({
  qualification,
  memberCenter,
  me,
  common,
}))
class ViewPersonalAuditDetail extends React.Component {
  state = {
    query: Immutable.Map({
      page: 1,
      length: 10,
    }),
    info: null,
  };

  getPersonalAuditHistoryInfo = () => {
    let userId = resolveUrlQuery(this.props.location.search).userId;
    let certId = resolveUrlQuery(this.props.location.search).certId;
    reaction.qualification
      .getPersonalAuditHistoryInfo({ userId, certId })
      .then(({ code, data }) => {
        if (code !== 0) return;

        // 统一数据结构
        let identityCardFrontUrl = { url: data.idCardFront };
        let identityCardBackUrl = { url: data.idCardBack };
        let drivingLicenseUrl = { url: data.drivingLicense };
        let drivingLicenseSubPage = { url: data.drivingLicenseSubPage };
        let runningLicenseUrl = { url: data.runningLicense };
        let runningLicenseSubPage = { url: data.runningLicenseSubPage };
        let transportBusinessLicenseUrl = {
          url: data.transportBusinessLicenseUrl,
        };
        let transportBusinessQualificationUrl = {
          url: data.transportBusinessQualificationUrl,
        };

        this.setState({
          info: {
            ...data,
            identityCardFrontUrl,
            identityCardBackUrl,
            drivingLicenseUrl,
            drivingLicenseSubPage,
            runningLicenseUrl,
            runningLicenseSubPage,
            transportBusinessLicenseUrl,
            transportBusinessQualificationUrl,
          },
        });
      });
  };

  getOperationHistory = () => {
    let { page, length } = this.state.query.toJS();
    reaction.qualification.getPersonalQualificationOperationHistory({
      userId: resolveUrlQuery(this.props.location.search).userId,
      page,
      length,
      operationType: resolveUrlQuery(this.props.location.search).operationType,
    });
  };

  back = () => {
    historyPush(
      `/${RoutePath.MEMBERCENTER}/${RoutePath.CHECK_QUALIFICATION_LIST}`
    );
  };

  componentDidMount() {
    this.getOperationHistory();
    this.getPersonalAuditHistoryInfo();
  }

  render() {
    const { info } = this.state;
    let qualificationDetailInfo = this.props.qualification
      .personalAuditHistoryDetail;

    let columns = [
      {
        width: 112,
        title: '时间',
        dataIndex: 'updateTime',
        render: (text) => {
          if (!text) {
            return '';
          }
          return dayjs(text - 0).format('YYYY-MM-DD HH:mm:ss');
        },
      },
      {
        width: 140,
        title: '操作人',
        dataIndex: 'operator',
      },
      {
        title: '修改内容',
        dataIndex: 'updateContent',
        render: (text) => {
          return <pre style={{ maxWidth: '1000px' }}>{text}</pre>;
        },
      },
    ];
    let list = this.props.qualification.personalQualificationOperationHistory;
    const hasDriverIdentity = checkHasDriverIdentity(qualificationDetailInfo.identity)
    return (
      <div className="person-info">
        <h2 className={commonStyles.row}>审核历史</h2>
        <div style={{ overflow: 'hidden' }}>
          <div style={{ float: 'left', width: 100 }}>账户信息</div>
          <div style={{ marginLeft: 100 }}>
            <div style={{ marginBottom: 10 }}>
              <label
                className="form-item-label-item"
              >
                会员编号：
              </label>
              <span
                style={{
                  width: 250,
                  lineHeight: '32px',
                  display: 'inline-block',
                }}
              >
                {qualificationDetailInfo.userNo}
              </span>
            </div>
            <div style={{ marginBottom: 10 }}>
              <label
                className="form-item-label-item"
              >
                用户名：
              </label>
              <span
                style={{
                  width: 250,
                  lineHeight: '32px',
                  display: 'inline-block',
                }}
              >
                {qualificationDetailInfo.username}
              </span>
            </div>
            <div style={{ marginBottom: 10 }}>
              <label
                className="form-item-label-item"
              >
                安全手机：
              </label>
              <span
                style={{
                  width: 250,
                  lineHeight: '32px',
                  display: 'inline-block',
                }}
              >
                {qualificationDetailInfo.phone}
              </span>
            </div>
          </div>
        </div>

        {info && <PersonDetail info={info} hasDriverIdentity={hasDriverIdentity} />}

        <div style={{ overflow: 'hidden' }}>
          <div style={{ float: 'left', width: 100 }}>审核信息</div>
          <div style={{ marginLeft: 100 }}>
            <div style={{ marginBottom: 30 }}>
              <label
                className="form-item-label-item"
              >
                审核结果：
              </label>
              <span
                style={{
                  width: 250,
                  lineHeight: '32px',
                  display: 'inline-block',
                }}
              >
                {qualificationDetailInfo.auditResult}
              </span>
            </div>
            <div style={{ marginBottom: 30 }}>
              <label
                className="form-item-label-item"
              >
                审核意见：
              </label>
              <span
                style={{
                  width: 250,
                  lineHeight: '32px',
                  display: 'inline-block',
                }}
              >
                {qualificationDetailInfo.auditOpinion}
              </span>
            </div>
          </div>
        </div>

        <div style={{ padding: '10px 0 50px 150px' }}>
          <Button onClick={this.back} style={{ marginLeft: 10 }}>
            返回
          </Button>
        </div>

        <div style={{ padding: '0 0 50px 0' }}>
          {list && (
            <Table
              className={commonStyles.row}
              rowKey="updateTime"
              columns={columns}
              dataSource={list.result}
            />
          )}
        </div>
      </div>
    );
  }
}

export default ViewPersonalAuditDetail;
