import React from 'react';
import { connect } from 'react-redux';
import reaction from 'reaction';
import { RoutePath } from 'utils/constants';
import { historyPush } from 'utils/history';
import { resolveUrlQuery } from 'utils';
import { Button, Select } from 'antd';
import TimeSelect from 'components/TimeSelect';
import TableWrapper from 'components/TableWrapper';
import Immutable from 'immutable';
import { message } from 'antd';
import dayjs from 'dayjs';

const { Option } = Select;

@connect(({ receiptList, me }) => ({ receiptList, me }))
class ReceiptList extends React.Component {
  state = {
    query: Immutable.Map({
      datetime: [dayjs(), dayjs()],
      loanFlag: '',  //借贷标识
      userLoginName: resolveUrlQuery(this.props.location.search).userLoginName,
      accountNo: resolveUrlQuery(this.props.location.search).accountNo,
      page: 1,
      length: 10,
    }),
  };

  dateChange = (value, type) => {
    let { query } = this.state;
    query = query.set(type, value);
    this.setState({ query });
  };

  onPageChange = (page, length) => {
    let { query } = this.state;
    query = query.set('page', page);
    query = query.set('length', length);
    this.setState({ query }, () => {
      this.getReceiptList();
    });
  };

  onPageSizeChange = (current, size) => {
    let { query } = this.state;
    query = query.set('page', 1);
    query = query.set('length', size);
    this.setState({ query }, () => {
      this.getReceiptList();
    });
  };

  copyText = (text) => {
    // var text = this.refs.belongingAccount.innerText;
    let oInput = document.createElement('input');
    oInput.value = text;
    document.body.appendChild(oInput);
    oInput.select(); // 选择对象
    document.execCommand('Copy'); // 执行浏览器复制命令
    document.body.removeChild(oInput);
    message.success('复制成功!');
  };

  copyAccount = () => {
    let text = this.refs.belongingAccount.innerText;
    this.copyText(text);
  };

  copyVerifyCode = (record) => {
    this.copyText(record.verifyCode);
  };

  goToPrint = () => {
    let a = document.createElement('a');
    a.setAttribute('target', '_blank');
    a.setAttribute(
      'href',
      'https://corp.bank.ecitic.com/cotb/m000A0100.html'
    );
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  learnProgress = () => {
    historyPush(
      `/${RoutePath.FUNDS}/${RoutePath.RECEIPT_LIST_PRINT}/${RoutePath.PRINT_PROGRESS}`
    );
  };

  // componentWillMount () {
  //   // 不能直接点击菜单进入回单列表页,只能通过账户列表页的按钮[查看交易回单]进入
  //   // 点击【查看交易回单】会携带query参数
  //   // if (!resolveUrlQuery(this.props.location.search).account) {
  //   //   historyPush(`/${RoutePath.FUNDS}/${RoutePath.ACCOUNT_LIST}`);
  //   // }
  //   // // ...
  // }

  submit = () => {
    let { query } = this.state;
    query = query.set('page', 1);
    this.setState({ query }, () => {
      this.getReceiptList();
    });
  };

  getReceiptList = () => {
    let datetime = this.state.query.toJS().datetime;
    let params = {
      ...this.state.query.toJS(),
      accountType: '1',
    };
    if (datetime[0]) {
      params.datetime = [
        datetime[0].startOf('day').valueOf(),
        datetime[1].endOf('day').valueOf(),
      ];
    }
    reaction.receiptList.getReceiptList(params);
  };

  clear = () => {
    let { query } = this.state;
    query = query.set('datetime', [dayjs(), dayjs()]);
    query = query.set('loanFlag', '');
    this.setState({ query });
  };

  componentDidMount() {
    this.getReceiptList();
  }

  render() {
    let _this = this;
    const columns = [
      {
        title: '交易时间',
        dataIndex: 'transactionTime',
        key: 'transactionTime',
        render: (text) => {
          if (!text) {
            return '';
          }
          return dayjs(text - 0).format('YYYY-MM-DD HH:mm:ss');
        },
      },
      {
        title: '柜员号',
        dataIndex: 'tellerNo',
        key: 'tellerNo',
        render: (text) => {
          return text.split(':::')[0];
        },
      },
      {
        title: '对方账户名称',
        dataIndex: 'oppAccountName',
        key: 'oppAccountName',
      },
      {
        title: '对方账号',
        dataIndex: 'oppAccountNo',
        key: 'oppAccountNo',
      },
      {
        title: '借贷标识',
        dataIndex: 'loanFlag',
        key: 'loanFlag',
      },
      {
        title: '交易金额',
        dataIndex: 'transactionMoney',
        key: 'transactionMoney',
      },
      {
        title: '摘要',
        dataIndex: 'memo',
        key: 'memo',
      },
      {
        title: '打印校验码',
        dataIndex: 'verifyCode',
        key: 'verifyCode',
        render: function (text) {
          return <span ref="verifyCode">{text}</span>;
        },
      },
      {
        title: '操作',
        dataIndex: 'operate',
        key: 'operate',
        render(text, record) {
          return (
            <span>
              <Button
                type="primary"
                size="small"
                onClick={() => {
                  _this.copyVerifyCode(record);
                }}
              >
                复制打印校验码
              </Button>
              <Button
                style={{ marginLeft: '5px', marginTop: '5px' }}
                type="primary"
                size="small"
                onClick={() => {
                  _this.goToPrint(record);
                }}
              >
                去打印
              </Button>
            </span>
          );
        },
      },
    ];

    let { list } = this.props.receiptList;
    let { query } = this.state;
    // list && (list.result = list.result.map((item) => {
    //   let date = new Date(item.transactionTime);
    //   let seconds = date.getSeconds();
    //   if (!(/^\d{2}$/.test(seconds.toString()))) {
    //     seconds = '0' + seconds;
    //   }
    //   return {...item, transactionTime: `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${seconds}`};
    // }));

    const nativeQuery = query.toJS();

    return (
      <div>
        <div style={{ padding: '15px' }}>
          <a style={{ color: 'blue' }} onClick={this.learnProgress}>
            &gt;了解如何打印交易回单流程，请点击我.
          </a>
        </div>
        <div style={{ padding: '15px' }}>
          <span>会员名称: {nativeQuery.userLoginName}</span>
          <span style={{ margin: '0 0 0 30px' }}>
            附属账号: <span ref="belongingAccount">{nativeQuery.accountNo}</span>
          </span>
          <Button
            type="default"
            style={{ margin: '0 0 0 15px' }}
            onClick={this.copyAccount}
          >
            复制
          </Button>
        </div>
        <div style={{ padding: '15px' }}>
          <span>交易时间：</span>
          <TimeSelect
            value={nativeQuery.datetime}
            onChange={(val) => this.dateChange(val, 'datetime')}
          />
          <span>借贷标识：</span>
          <Select
            onChange={(val) => this.dateChange(val, 'loanFlag')}
            value={query.toJS().loanFlag} style={{ width: 120 }}>
            <Option value="">全部</Option>
            <Option value="借">借</Option>
            <Option value="贷">贷</Option>
          </Select>

          <Button
            style={{ margin: '0 0 0 20px' }}
            type="primary"
            onClick={this.submit}
            icon="search"
          >
            查询
          </Button>
          <Button
            style={{ margin: '0 0 0 5px' }}
            type="primary"
            onClick={this.clear}
            icon="redo"
          >
            清空
          </Button>
        </div>
        <div style={{ padding: '15px' }}>
          {list && (
            <TableWrapper
              columns={columns}
              data={list}
              onChange={this.onPageChange}
              onShowSizeChange={this.onPageSizeChange}
              pageSize={nativeQuery.length}
            />
          )}
        </div>
      </div>
    );
  }
}

export default ReceiptList;
