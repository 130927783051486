import React from 'react';
import { connect } from 'react-redux';
import { Form, Select, Input, Button, message } from 'antd';
import reaction from 'reaction';
import { HttpCode, RoutePath } from 'utils/constants';
import { historyPush } from 'utils/history';

import commonStyles from 'style/common.module.scss';

const FormItem = Form.Item;
const Option = Select.Option;
/**
 * 黑名单新增
 */
@connect(({ common }) => ({ common }))
@Form.create()
class BlackListUserAdd extends React.Component {
  handleSubmit = (e) => {
    e && e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let { userName, forbidPlatformId } = values;
        reaction.blacklist.create(userName, forbidPlatformId).then((data) => {
          if (data.code === HttpCode.OK) {
            message.success('添加成功！');
            historyPush(`/${RoutePath.PERMISSION}`);
          }
        });
      }
    });
  };
  render() {
    const { platformDict } = this.props.common;
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        <h2 className={commonStyles.row}>黑名单新增</h2>
        <Form onSubmit={this.handleSubmit} className={commonStyles.row}>
          <FormItem
            label="黑名单"
            labelCol={{ span: 8 }}
            wrapperCol={{ sm: 8, xs: 24 }}
          >
            {getFieldDecorator('userName', {
              validateTrigger: ['onBlur', 'onChange'],
              rules: [
                { required: true, message: '请输入用户名/手机号/邮箱!' },
                {
                  pattern: /^([0-9a-zA-Z]{4,30}|([a-zA-Z0-9_.-]+)@([\da-zA-Z.-]+)\.([a-zA-Z.]{2,6}))$/,
                  message: '请输入正确的用户名/手机号/邮箱!',
                },
              ],
            })(<Input placeholder="用户名/手机号/邮箱" />)}
          </FormItem>
          <FormItem
            label="不可操作业务系统"
            labelCol={{ span: 8 }}
            wrapperCol={{ sm: 8, xs: 24 }}
          >
            {getFieldDecorator('forbidPlatformId', {
              validateTrigger: ['onBlur', 'onChange'],
              rules: [{ required: true, message: '请选择不可操作业务系统!' }],
            })(
              <Select multiple placeholder="不可操作业务系统">
                {platformDict &&
                  Object.keys(platformDict).map((id) => {
                    return <Option key={`${id}`}>{platformDict[id]}</Option>;
                  })}
              </Select>
            )}
          </FormItem>
          <FormItem
            wrapperCol={{ sm: { span: 8, offset: 8 }, xs: { span: 8 } }}
          >
            <Button type="primary" htmlType="submit">
              确定
            </Button>
          </FormItem>
        </Form>
      </div>
    );
  }
}

export default BlackListUserAdd;
