import React from 'react';
import dayjs from 'dayjs';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import reaction from 'reaction/index';
import { withRouter } from 'react-router-dom';
import { Button, Input, Table, message, DatePicker, Select, Form } from 'antd';

import FormItem from 'components/FormItem';

// import history from 'utils/history';
import { renderListControls } from 'utils';
import { RoutePath } from 'utils/constants';

import commonStyles from 'style/common.module.scss';

const { RangePicker } = DatePicker;
const { Option } = Select;

@connect(({ smsManagement }) => ({ smsManagement }))
@withRouter
class SmsHistory extends React.Component {
  state = {
    query: Immutable.Map({
      phone: '',
      content: '',
      bizNo: '',
      date: '',
      startTime: '',
      endTime: '',
      smsPlatformCode: '',
      sendStatus: '',
      page: 1,
      length: 10,
    }),
    currentRow: null,
  };

  onQueryChange = (name, value) => {
    let { query } = this.state;
    query = query.set(name, value);
    this.setState({ query }, () => {});
  };

  goToView = (row) => {
    this.props.history.push({
      pathname: `/${RoutePath.OPERATION_CENTER}/${RoutePath.SMS_MANAGEMENT}/${RoutePath.HISTORY_SMS_DETAIL}`,
      search: `?id=${row.id}`,
    });
  };

  getSmsHistoryList = () => {
    let query = this.state.query.toJS();

    if (query.date[0]) {
      query.startTime = query.date[0].startOf('day').valueOf();
    }
    if (query.date[1]) {
      query.endTime = query.date[1].endOf('day').valueOf();
    }

    reaction.smsManagement.getSmsHistoryList(query);
  };

  handleTableChange = (pagination, filters, sorter) => {
    let { query } = this.state;
    query = query.set('page', pagination.current);
    query = query.set('length', pagination.pageSize);
    this.setState({ query }, () => {
      this.getSmsHistoryList();
    });
  };

  search = () => {
    let { query } = this.state;
    query = query.set('page', 1);
    this.setState({ query }, () => {
      this.getSmsHistoryList();
    });
  };

  onPressEnter = () => {
    this.search();
  };

  componentDidMount() {
    this.getSmsHistoryList();
  }

  render() {
    let { smsHistoryList } = this.props.smsManagement;
    let { query } = this.state;
    let columns = [
      // {
      //   title: '流水',
      //   dataIndex: 'serialNo',
      // },
      {
        title: '手机号',
        width: 200,
        dataIndex: 'phone',
        render(text) {
          return (
            <span
              style={{
                display: 'inline-block',
                width: 200,
                wordBreak: 'break-all',
              }}
            >
              {text}
            </span>
          );
        },
      },
      {
        title: '发送时间',
        dataIndex: 'sendTime',
        render: (text) => {
          if (!text) {
            return '';
          }
          return dayjs(text - 0).format('YYYY-MM-DD HH:mm:ss');
        },
      },
      {
        title: '短信内容',
        dataIndex: 'content',
      },
      {
        title: 'code',
        dataIndex: 'templateCode',
      },
      {
        title: '短信平台',
        width: 100,
        dataIndex: 'smsPlatform',
      },
      {
        title: '业务号',
        width: 80,
        dataIndex: 'bizNo',
      },
      {
        title: '是否成功',
        width: 100,
        dataIndex: 'sendStatus',
        render: (text) => {
          return text ? '成功' : '失败';
        },
      },
      {
        title: '操作',
        render: (row) => {
          return (
            <div>
              <Button
                type="primary"
                onClick={() => {
                  this.goToView(row);
                }}
              >
                查看
              </Button>
            </div>
          );
        },
      },
    ];

    return (
      <div>
        <h4 style={{ overflow: 'hidden' }}>
          <span>短信发送历史</span>
        </h4>
        <Form layout="inline">
          {
            renderListControls([
              <FormItem label="手机号码">
                <Input
                  value={query.toJS().phone}
                  onPressEnter={this.onPressEnter}
                  onChange={(e) => {
                    this.onQueryChange('phone', e.target.value);
                  }}
                  // style={{ width: '250px', marginLeft: '15px', marginTop: 10 }}
                  // addonBefore="手机号码"
                  defaultValue=""
                  placeholder="请输入手机号码"
                />
              </FormItem>,
              <FormItem label="短信内容">
                <Input
                  value={query.toJS().content}
                  onPressEnter={this.onPressEnter}
                  onChange={(e) => {
                    this.onQueryChange('content', e.target.value);
                  }}
                  // style={{ width: '250px', marginLeft: '15px', marginTop: 10 }}
                  // addonBefore="短信内容"
                  defaultValue=""
                  placeholder="请输入短信内容"
                />
              </FormItem>,
              <FormItem label="业务号">
                <Input
                  value={query.toJS().bizNo}
                  onPressEnter={this.onPressEnter}
                  onChange={(e) => {
                    this.onQueryChange('bizNo', e.target.value);
                  }}
                  // style={{ width: '250px', marginLeft: '15px', marginTop: 10 }}
                  // addonBefore="业务号"
                  defaultValue=""
                  placeholder="请输入业务号"
                />
              </FormItem>,
              <FormItem label="发送时间">
                <RangePicker
                  onChange={(date, dateString) => {
                    this.onQueryChange('date', date);
                  }}
                  // style={{ marginLeft: '15px', marginTop: 10 }}
                />
              </FormItem>,
              <FormItem label="短信平台">
                <Select
                  id="platform"
                  value={query.toJS().smsPlatformCode}
                  // style={{ width: 110 }}
                  onChange={(value) => {
                    this.onQueryChange('smsPlatformCode', value);
                  }}
                >
                  <Option value="">全部</Option>
                  <Option value="alisms">阿里云</Option>
                  <Option value="opwo">天下畅通</Option>
                </Select>
              </FormItem>,
              <FormItem label="是否成功">
                <Select
                  id="platform"
                  value={query.toJS().sendStatus}
                  // style={{ width: 110 }}
                  onChange={(value) => {
                    this.onQueryChange('sendStatus', value);
                  }}
                >
                  <Option value={''}>全部</Option>
                  <Option value={1}>发送成功</Option>
                  <Option value={0}>发送失败</Option>
                </Select>
              </FormItem>,
              {
                colProps: {
                  xxl: 12,
                  xl: 24,
                  lg: 24,
                  md: 24,
                },
                children: (
                  <FormItem align="right">
                    <Button
                      // style={{ marginLeft: '10px', marginTop: '10px' }}
                      type="primary"
                      onClick={this.search}
                      icon="search"
                    >
                      查询
                    </Button>
                  </FormItem>
                )
              }
            ])
          }
          {/* <span style={{ display: 'inline-block', margin: '10px 0 0 15px' }}>
            <label htmlFor="platform">短信平台：</label>
          </span> */}
          {/* <span style={{ display: 'inline-block', margin: '10px 0 0 15px' }}>
            <label htmlFor="platform">是否成功：</label>
          </span> */}
        </Form>
        <div style={{ padding: '20px 0 0 0' }}>
          {
            <Table
              className={commonStyles.row}
              rowKey="id"
              columns={columns}
              dataSource={smsHistoryList ? smsHistoryList.result : []}
              pagination={
                smsHistoryList
                  ? {
                      current: smsHistoryList.pagerInfo.page,
                      total: smsHistoryList.pagerInfo.total,
                      pageSizeOptions: ['10', '20', '50'],
                      showSizeChanger: true,
                      showQuickJumper: true,
                      showTotal: (total) => `共${total}条`,
                    }
                  : false
              }
              onChange={this.handleTableChange}
            />
          }
        </div>
      </div>
    );
  }
}

export default SmsHistory;
