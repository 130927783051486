import React from 'react';
import { connect } from 'react-redux';
import commonStyles from 'style/common.module.scss';
import reaction from 'reaction';
import { historyPush } from 'utils/history';
import { resolveUrlQuery } from 'utils';
import { RoutePath, AuthCodes } from 'utils/constants';
import dayjs from 'dayjs';
import Immutable from 'immutable';
import {
  Table,
  Button,
  Input,
  Select,
  DatePicker,
  Switch,
  Drawer,
  message,
  Upload,
  AutoComplete,
  Icon,
} from 'antd';
import locale from 'antd/lib/date-picker/locale/zh_CN';
import * as env from 'config/env';

const { Option } = Select;
const { TextArea, Search } = Input;
const AOption = AutoComplete.Option;
const dateFormat = 'YYYY-MM-DD HH:mm:ss';

function range(start, end) {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
}

function disabledDate(current) {
  // Can not select days before today and today
  return (
    current &&
    current <
      dayjs()
        .subtract(1, 'days')
        .endOf('day')
  );
}

function disabledDateTime(selectedMoment) {
  let date = selectedMoment.date();
  let hour = selectedMoment.hour();
  let minute = selectedMoment.minute();
  let second = selectedMoment.second();
  return {
    disabledHours: () => {
      if (dayjs().date() < date) {
        return [];
      }
      return range(0, 24).filter((item) => item < dayjs().hour());
    },
    disabledMinutes: () => {
      if (dayjs().date() < date) {
        return [];
      }
      if (dayjs().hour() < hour) {
        return [];
      }
      return range(0, 60).filter((item) => item < dayjs().minute());
    },
    disabledSeconds: () => {
      if (dayjs().date() < date) {
        return [];
      }
      if (dayjs().hour() < hour) {
        return [];
      }
      if (dayjs().minute() < minute) {
        return [];
      }
      return range(0, 60).filter((item) => item < dayjs().second());
    },
  };
}

@connect(({ smsManagement, groupSms, me }) => ({ smsManagement, groupSms, me }))
class AddGroupSms extends React.Component {
  state = {
    typeOfSend: [],
    fileList: [],
    smsDrawerVisible: false,
    numberDrawerVisible: false,
    smsContentEditable: false,
    selectedAccount: '',
    oneByOneValue: '',
    autoCompleteValue: '',
    groupSmsInfo: Immutable.Map({
      fireMode: 'fixed_time',
      topic: '',
      closed: false,
      messageContent: '',
      messageTemplate: '',
      fireExpression: dayjs(),
      phoneList: [],
    }),
    query: Immutable.Map({
      templateName: '',
      templateCode: '',
      drawerAddedNumberList: [],
      page: 1,
      length: 10,
    }),
  };

  componentDidMount() {
    this.getGroupSmsInfo();
    this.getTypeOfSend();
  }

  getTypeOfSend = () => {
    reaction.groupSms.getTypeOfSend().then((data) => {
      data.code === 0 && this.setState({ typeOfSend: data.data });
    });
  };

  getGroupSmsInfo = () => {
    let { groupId } = resolveUrlQuery(this.props.location.search);
    let { groupSmsInfo } = this.state;
    if (!groupId) return;
    return reaction.groupSms.getGroupSmsInfo(groupId).then((res) => {
      groupSmsInfo = groupSmsInfo.set('topic', res.data.topic);
      groupSmsInfo = groupSmsInfo.set('closed', res.data.closed);
      groupSmsInfo = groupSmsInfo.set('fireMode', res.data.fireMode);
      groupSmsInfo = groupSmsInfo.set(
        'fireExpression',
        dayjs(+res.data.fireExpression)
      );
      groupSmsInfo = groupSmsInfo.set(
        'messageContent',
        res.data.messageContent
      );
      groupSmsInfo = groupSmsInfo.set(
        'messageTemplate',
        res.data.messageTemplate
      );
      groupSmsInfo = groupSmsInfo.set('phoneList', res.data.phoneList);
      this.setState({ groupSmsInfo });
    });
  };
  onInfoChange = (name, value) => {
    let { groupSmsInfo } = this.state;
    groupSmsInfo = groupSmsInfo.set(name, value);
    this.setState({ groupSmsInfo });
  };
  onSmsDrawerClose = () => {
    this.hideSmsDrawer();
  };
  onNumberDrawerClose = () => {
    this.hideNumberDrawer();
  };
  clearNumDrawerInput = () => {
    this.setState({
      oneByOneValue: '',
      autoCompleteValue: '',
      selectedAccount: '',
    });
  };
  hideSmsDrawer = () => {
    this.setState({
      smsDrawerVisible: false,
    });
  };
  hideNumberDrawer = () => {
    this.setState({
      numberDrawerVisible: false,
    });
  };
  showSmsDrawer = () => {
    this.doSearch();
    this.setState({
      smsDrawerVisible: true,
    });
  };
  customSms = () => {
    let groupSmsInfo = this.state.groupSmsInfo;
    groupSmsInfo = groupSmsInfo.set('messageContent', '');
    this.setState({
      smsContentEditable: true,
      groupSmsInfo,
    });
  };
  showNumberDrawer = () => {
    this.doSearch();
    this.setState({
      numberDrawerVisible: true,
    });
  };

  getSmsModelList = () => {
    let query = this.state.query.toJS();
    reaction.smsManagement.getSmsModelList(query);
  };

  getAccountList = (v) => {
    reaction.groupSms.getCompanyAccountList(v);
  };

  handleSelectAccount = (data) => {
    this.setState({
      selectedAccount: data,
    });
  };

  chooseSmsModel = (smsModelRecord) => {
    let { groupSmsInfo } = this.state;
    groupSmsInfo = groupSmsInfo.set(
      'messageContent',
      smsModelRecord.templateContent
    );
    groupSmsInfo = groupSmsInfo.set(
      'messageTemplate',
      smsModelRecord.templateCode
    );
    this.setState({
      smsDrawerVisible: false,
      smsContentEditable: false,
      groupSmsInfo,
    });
  };

  handleNumberManagement = () => {
    this.showNumberDrawer();
  };

  handleTableChange = (pagination, filters, sorter) => {
    let { query } = this.state;
    query = query.set('page', pagination.current);
    query = query.set('length', pagination.pageSize);
    this.setState({ query }, () => {
      this.getSmsModelList();
    });
  };

  handleAdd1 = (v) => {
    if (!/^1\d{10}$/.test(v)) {
      message.warn('请输入正确的手机号码再添加');
      return;
    }
    this.searchAccountByPhone(v).then((res) => {
      if (res.code === 0) {
        res.data[0] &&
          this.addSingleNumber(
            res.data[0].phone,
            res.data[0].enterpriseUsername
          );
        this.setState({ oneByOneValue: '' });
      }
    });
  };

  searchAccountByPhone = (phone) => {
    return reaction.groupSms.searchAccountByPhone(phone);
  };

  addSingleNumber = (phone, username) => {
    if (!/^1\d{10}$/.test(phone)) {
      message.error('请输入正确的手机号');
      return;
    }
    let { query } = this.state;
    let { drawerAddedNumberList } = query.toJS();
    if (drawerAddedNumberList.length >= 2000) {
      message.warn('手机号已超出最大限制(2000)，不能继续添加');
      return;
    }
    if (drawerAddedNumberList.filter((item) => item.phone === phone).length) {
      message.warning('已经添加该号码，请勿重复添加');
      return;
    }
    drawerAddedNumberList.push({ phone, username });
    query = query.set('drawerAddedNumberList', drawerAddedNumberList);
    this.setState({ query, selectedAccount: '', autoCompleteValue: '' });
  };

  onQueryChange = (name, value) => {
    let { query } = this.state;
    query = query.set(name, value);
    this.setState({ query });
  };

  doSearch = () => {
    let { query } = this.state;
    query = query.set('page', 1);
    this.setState({ query }, () => {
      this.getSmsModelList();
    });
  };

  beforeUpload = (onlyOne, file, fileList) => {
    if (file.size > 10240000) {
      message.error('文件大小不能超过10MB!');
      return false;
    }
    return true;
  };

  onUploadChange = (data) => {
    if (data.file.response && data.file.response.code === 0) {
      let result = data.file.response.data;
      let { query } = this.state;
      let { drawerAddedNumberList } = query.toJS();
      let concatedResult = result.concat(drawerAddedNumberList);
      let phoneList = concatedResult.map((item) => item.phone);
      concatedResult = concatedResult.map((item, i) => {
        if (!/^1\d{10}$/.test(item.phone)) {
          return { ...item, invalid: true };
        }
        if (phoneList.indexOf(item.phone) !== i) {
          return { ...item, isRepeat: true };
        }
        return item;
      });
      query = query.set('drawerAddedNumberList', concatedResult);
      this.setState({ query });
    } else if (data.file.response && data.file.response.code !== 0) {
      data.fileList.splice(data.fileList.indexOf(data.file), 1);
      message.error(data.file.response.msg || '上传失败!');
    }
    this.setState({ fileList: data.fileList.slice(data.fileList.length - 1) });
  };

  /**
   * 清空选择的号码
   */
  clearNumbers = () => {
    let { query } = this.state;
    query = query.set('drawerAddedNumberList', []);
    this.setState({ query });
  };

  /**
   * 号码去重
   */
  numbersDeduplication = (callback) => {
    let { query } = this.state;
    let { drawerAddedNumberList } = query.toJS();
    let filteredList = [];
    drawerAddedNumberList.forEach((item) => {
      if (filteredList.some((innerItem) => item.phone === innerItem.phone)) {
        return;
      }
      filteredList.push(item);
    });
    query = query.set('drawerAddedNumberList', filteredList);
    this.setState({ query }, () => {
      callback instanceof Function && callback();
    });
  };

  /**
   * 去掉无效的号码
   */
  removeInvalidNumbers = (callback) => {
    let { query } = this.state;
    let { drawerAddedNumberList } = query.toJS();
    drawerAddedNumberList = drawerAddedNumberList.filter((item) => {
      return /^1\d{10}$/.test(item.phone);
    });
    query = query.set('drawerAddedNumberList', drawerAddedNumberList);
    this.setState({ query }, () => {
      callback instanceof Function && callback();
    });
  };

  handleAddMultipleNumber = () => {
    this.numbersDeduplication(() => {
      this.removeInvalidNumbers(() => {
        let { query, groupSmsInfo } = this.state;
        let { drawerAddedNumberList } = query.toJS();
        if (drawerAddedNumberList.length > 2000) {
          message.error('手机号已超出最大限制(2000)');
          return;
        }
        groupSmsInfo = groupSmsInfo.set('phoneList', drawerAddedNumberList);
        this.clearNumDrawerInput();
        this.setState({
          groupSmsInfo,
          numberDrawerVisible: false,
          fileList: [],
        });
      });
    });
  };

  submit = () => {
    let copyGroupId = resolveUrlQuery(this.props.location.search).groupId;
    let {
      topic,
      closed,
      fireMode,
      fireExpression,
      messageContent,
      messageTemplate,
      phoneList,
    } = this.state.groupSmsInfo.toJS();
    let params = {
      topic,
      closed,
      fireMode,
      fireExpression: fireExpression.valueOf(),
      messageContent,
      messageTemplate,
      phoneList,
      copyGroupId,
    };
    if (!params.topic) {
      message.warn('请输入主题后再提交!');
      return;
    }
    if (!params.messageContent) {
      message.warn('请输入或选择短信内容后再提交!');
      return;
    }
    if (!params.phoneList.length) {
      message.warn('请添加发送名单后再提交!');
      return;
    }
    if (
      params.fireMode === 'fixed_time' &&
      params.fireExpression <= Date.now()
    ) {
      message.warn('不能选择当前时间之前的时间作为发送时间!');
      return;
    }
    reaction.groupSms.createNewGroupSms(params).then((res) => {
      if (res.code === 0) {
        message.success('创建成功!');
        this.back();
      }
    });
  };

  delete = (data) => {
    let { query } = this.state;
    let { drawerAddedNumberList } = query.toJS();
    drawerAddedNumberList = drawerAddedNumberList.filter((item) => {
      return item.phone !== data.phone;
    });
    query = query.set('drawerAddedNumberList', drawerAddedNumberList);
    this.setState({ query });
  };

  back = () => {
    historyPush(`/${RoutePath.OPERATION_CENTER}/${RoutePath.GROUP_SMS}`);
  };

  render() {
    let { typeOfSend, smsContentEditable, fileList } = this.state;
    let info = this.state.groupSmsInfo.toJS();
    let queryValue = this.state.query.toJS();
    let { phoneList } = info;
    let { drawerAddedNumberList } = queryValue;
    let { list } = this.props.smsManagement;
    let { me } = this.props;
    let { enterpriseList } = this.props.groupSms;
    let selectedAccount = this.state.selectedAccount.split('/');
    let repeatParis = [];
    let invalidNum = 0;
    drawerAddedNumberList.forEach((item) => {
      if (item.isRepeat && repeatParis.indexOf(item.phone) === -1) {
        repeatParis.push(item.phone);
      }
      if (item.invalid) {
        invalidNum++;
      }
    });
    let columns = [
      {
        title: '模板名称',
        dataIndex: 'templateName',
      },
      {
        title: 'code',
        dataIndex: 'templateCode',
      },
      {
        title: '操作',
        render: (text, record) => (
          <div>
            <Button
              type="primary"
              onClick={() => {
                this.chooseSmsModel(record);
              }}
            >
              选择
            </Button>
          </div>
        ),
      },
    ];
    let columns1 = [
      {
        title: '号码',
        dataIndex: 'phone',
        render: (text, row) => {
          if (row.invalid) {
            return <span style={{ color: 'red' }}>{text}</span>;
          }
          if (row.isRepeat) {
            return <span style={{ color: 'orange' }}>{text}</span>;
          }
          return <span>{text}</span>;
        },
      },
      {
        title: '企业',
        dataIndex: 'username',
      },
      {
        title: '操作',
        render: (data) => {
          return (
            <Button
              type="primary"
              onClick={() => {
                this.delete(data);
              }}
            >
              删除
            </Button>
          );
        },
      },
    ];
    let columns2 = [
      {
        title: '号码',
        dataIndex: 'phone',
      },
      {
        title: '企业',
        dataIndex: 'username',
      },
    ];
    return (
      <div style={{ padding: '0 0 50px 0' }}>
        <h4 style={{ padding: '10px 0 20px 0' }}>
          <span>运营中心 / 新增群发</span>
        </h4>
        <div style={{ display: 'flex' }}>
          <div
            style={{
              width: '50%',
              boxSizing: 'border-box',
              padding: '0 20px 0',
            }}
          >
            <div style={{ padding: '20px 0 0' }}>
              <label style={{ display: 'inline-block', width: 70 }}>
                主题：
              </label>
              <Input
                style={{ width: 165 }}
                value={info.topic}
                onChange={(e) => {
                  this.onInfoChange('topic', e.target.value);
                }}
                placeholder="请输入主题"
              />
            </div>
            <div style={{ padding: '20px 0 0' }}>
              <label style={{ display: 'inline-block', width: 70 }}>
                状态:
              </label>
              <Switch
                checkedChildren="正常"
                unCheckedChildren="关闭"
                checked={!info.closed}
                onChange={(checked) => {
                  this.onInfoChange('closed', !checked);
                }}
              />
            </div>
            <div style={{ padding: '20px 0 0' }}>
              <label style={{ display: 'inline-block', width: 70 }}>
                发送方式：
              </label>
              <Select
                showSearch
                style={{ width: 120 }}
                placeholder="请选择"
                value={info.fireMode}
                onChange={(v) => {
                  this.onInfoChange('fireMode', v);
                }}
              >
                {typeOfSend &&
                  typeOfSend.map((item) => {
                    if (item.code === 'cyclic') return null;
                    return <Option value={item.code}>{item.name}</Option>;
                  })}
              </Select>
              {info.fireMode === 'fixed_time' && (
                <label>&nbsp;&nbsp;&nbsp;定于&nbsp;&nbsp;&nbsp;</label>
              )}
              {info.fireMode === 'fixed_time' && (
                <DatePicker
                  format={dateFormat}
                  locale={locale}
                  disabledDate={disabledDate}
                  disabledTime={disabledDateTime}
                  showTime
                  value={info.fireExpression}
                  onChange={(value) => {
                    this.onInfoChange('fireExpression', value);
                  }}
                />
              )}
              {info.fireMode === 'fixed_time' && (
                <span>&nbsp;&nbsp;&nbsp;发送</span>
              )}
            </div>
            <div style={{ padding: '20px 0 0', display: 'flex' }}>
              <label
                style={{ display: 'inline-block', width: 70, flexShrink: 0 }}
              >
                短信内容:
              </label>
              <TextArea
                style={{ flexGrow: 1 }}
                rows={6}
                disabled={!smsContentEditable}
                value={info.messageContent}
                onChange={(e) => {
                  this.onInfoChange('messageContent', e.target.value);
                }}
              />
              <div style={{ width: 140, padding: '0 10px 0', flexShrink: 0 }}>
                <Button type="primary" onClick={this.showSmsDrawer}>
                  选择短信模板
                </Button>
                {/* <Button type="primary" style={{marginTop: 8}} onClick={ this.customSms }>自定义短信</Button> */}
              </div>
            </div>
            <div>
              <p
                style={{ fontSize: 13, color: 'red', padding: '20px 0 0 70px' }}
              >
                注意: 暂不支持变量取值，请选择纯文本短信
              </p>
            </div>
          </div>
          <div
            style={{
              width: '50%',
              boxSizing: 'border-box',
              borderLeft: '1px dashed #dddddd',
              padding: '0 20px 0',
            }}
          >
            <div style={{ padding: '20px 0 0', display: 'flex' }}>
              <label
                style={{ display: 'inline-block', width: 70, flexShrink: 0 }}
              >
                发送名单:
              </label>
              <Button type="primary" onClick={this.handleNumberManagement}>
                号码管理
              </Button>
            </div>
            <div style={{ padding: '20px 0 0', display: 'flex' }}>
              <div
                style={{
                  marginLeft: 70,
                  maxHeight: 400,
                  overflow: 'auto',
                  width: '100%',
                }}
              >
                <Table
                  size="small"
                  columns={columns2}
                  dataSource={phoneList}
                  pagination={false}
                />
              </div>
            </div>
            <div style={{ padding: '20px 0 0', display: 'flex' }}>
              <p style={{ marginLeft: 70 }}>总计: {phoneList.length}个号码</p>
            </div>
          </div>
        </div>

        <div
          style={{
            padding: '20px 100px 20px 100px',
            overflow: 'hidden',
            borderTop: '1px dashed #dddddd',
          }}
        >
          <span>创建人: {me.userInfo.userName}</span>
          <Button
            style={{ float: 'right' }}
            type="primary"
            onClick={this.submit}
          >
            创建
          </Button>
          <Button
            style={{ float: 'right', marginRight: 20 }}
            onClick={this.back}
          >
            返回
          </Button>
        </div>

        <Drawer
          title="选择短信模板"
          width={720}
          onClose={this.onSmsDrawerClose}
          visible={this.state.smsDrawerVisible}
        >
          <span
            style={{
              width: 170,
              margin: '10px 0 0 5px',
              display: 'inline-block',
            }}
          >
            <label>名称：</label>
            <Input
              style={{ width: 125 }}
              onPressEnter={this.onPressEnter}
              value={queryValue.templateName}
              onChange={(e) => {
                this.onQueryChange('templateName', e.target.value);
              }}
              placeholder="请输入名称"
            />
          </span>
          <span
            style={{
              width: 170,
              margin: '10px 0 0 5px',
              display: 'inline-block',
            }}
          >
            <label>code：</label>
            <Input
              style={{ width: 125 }}
              onPressEnter={this.onPressEnter}
              value={queryValue.templateCode}
              onChange={(e) => {
                this.onQueryChange('templateCode', e.target.value);
              }}
              placeholder="请输入名称"
            />
          </span>
          <Button
            style={{ margin: '0 0 0 15px', marginTop: 10 }}
            type="primary"
            onClick={this.doSearch}
          >
            查询
          </Button>
          {list && (
            <Table
              className={commonStyles.row}
              rowKey="userId"
              columns={columns}
              dataSource={list.result}
              pagination={{
                current: list.pagerInfo.page,
                total: list.pagerInfo.total,
                pageSizeOptions: ['10', '20', '50'],
                showSizeChanger: true,
                showQuickJumper: true,
                showTotal: (total) => `共${total}条`,
              }}
              onChange={this.handleTableChange}
            />
          )}
        </Drawer>

        <Drawer
          title="号码管理"
          width={900}
          onClose={this.onNumberDrawerClose}
          visible={this.state.numberDrawerVisible}
        >
          <div style={{ display: 'flex' }}>
            <div style={{ width: '65%', padding: '0 20px 0 20px' }}>
              <p style={{ padding: '25px 0' }}>
                已选号码: {drawerAddedNumberList.length}个号码，重复
                {repeatParis.length}对，无效{invalidNum}个
              </p>
              <div style={{ maxHeight: 350, overflow: 'auto' }}>
                <Table
                  size="small"
                  rowKey="userId"
                  columns={columns1}
                  dataSource={drawerAddedNumberList}
                  pagination={false}
                />
              </div>
              <div style={{ padding: '15px 0' }}>
                <Button onClick={this.clearNumbers} size="small">
                  清空
                </Button>
                <Button
                  onClick={this.numbersDeduplication}
                  style={{ marginLeft: 10, color: 'orange' }}
                  type="warning"
                  size="small"
                >
                  去除重复
                </Button>
                <Button
                  onClick={this.removeInvalidNumbers}
                  style={{ marginLeft: 10 }}
                  type="danger"
                  size="small"
                >
                  去除无效
                </Button>
              </div>
              <div
                style={{ color: 'red', fontSize: 13, padding: '10px 0 0 0' }}
              >
                注意: 确认之后，系统会自动去重和去除无效
              </div>
            </div>
            <div style={{ width: '35%', padding: '0 20px 0 20px' }}>
              <h3 style={{ padding: '25px 0' }}>添加号码</h3>
              <div>
                <h4>方式一: 逐个添加手机号码</h4>
                <Search
                  value={this.state.oneByOneValue}
                  placeholder="请输入手机号码"
                  enterButton="添加"
                  onChange={(e) => {
                    this.setState({ oneByOneValue: e.target.value });
                  }}
                  onSearch={this.handleAdd1}
                />
              </div>
              <div style={{ padding: '25px 0 0 0' }}>
                <h4>方式二: 按账户搜索逐个添加</h4>
                <div>
                  <AutoComplete
                    style={{ width: 252 }}
                    value={this.state.autoCompleteValue}
                    onSelect={this.handleSelectAccount}
                    onChange={(v) => {
                      this.setState({ autoCompleteValue: v });
                      this.getAccountList(v);
                    }}
                    placeholder="联想输入匹配结果"
                  >
                    {enterpriseList &&
                      enterpriseList.map((item) => {
                        return (
                          <AOption
                            key={item.code}
                            value={item.phone + '/' + item.enterpriseUsername}
                          >
                            {item.enterpriseUsername + '-' + item.phone}
                          </AOption>
                        );
                      })}
                  </AutoComplete>
                </div>
                <div style={{ padding: '15px 0 0 0' }}>
                  <Input
                    style={{ width: 180 }}
                    disabled={true}
                    value={selectedAccount[0]}
                  />
                  <Button
                    style={{ marginLeft: 8 }}
                    type="primary"
                    onClick={() => {
                      this.addSingleNumber(
                        selectedAccount[0],
                        selectedAccount[1]
                      );
                    }}
                  >
                    添加
                  </Button>
                </div>
              </div>
              <div style={{ padding: '25px 0 0 0' }}>
                <h4>方式三: 批量导入</h4>
                <Upload
                  name="file"
                  accept=".csv, .xlsx, .xls"
                  action={env.getUploadApi(
                    '/v1/groupMessaging/parseExcelPhone'
                  )}
                  fileList={fileList}
                  beforeUpload={(file, fileList) => {
                    return this.beforeUpload(true, file, fileList);
                  }}
                  onChange={(data) => {
                    this.onUploadChange(data);
                  }}
                >
                  <Button>
                    <Icon type="upload" /> 上传文件
                  </Button>
                </Upload>
              </div>
            </div>
          </div>
          <div style={{ padding: '25px 0 0 0' }}>
            <Button onClick={this.hideNumberDrawer}>取消</Button>
            <Button
              onClick={this.handleAddMultipleNumber}
              style={{ marginLeft: 15 }}
              type="primary"
            >
              确认
            </Button>
          </div>
        </Drawer>
      </div>
    );
  }
}

export default AddGroupSms;
