import React from 'react';
import { connect } from 'react-redux';
import reaction from 'reaction';
import { historyPush } from 'utils/history';
import { RoutePath } from 'utils/constants';
import dayjs from 'dayjs';
import Immutable from 'immutable';
import FormItem from 'components/FormItem';
import { Table, Button, Input, Select, DatePicker, Form } from 'antd';
import locale from 'antd/lib/date-picker/locale/zh_CN';
import { renderListControls } from 'utils';

import commonStyles from 'style/common.module.scss';

let dateFormat = 'YYYY/MM/DD';
const { RangePicker } = DatePicker;
const location = window.location;
const { Option } = Select;

window.dayjs = dayjs;

const getOutdateOptions = () => {
  const date = dayjs();
  return [
    {
      label: '全部',
      value: '',
      key: 'all'
    },
    {
      label: '两月内',
      value: dayjs.duration(2, 'month').asMilliseconds(),
    },
    {
      label: '一月内',
      value: dayjs.duration(1, 'month').asMilliseconds(),
    },
    {
      label: '一周内',
      value: dayjs.duration(1, 'week').asMilliseconds(),
    },
    {
      label: '已到期',
      value: -1,
    }
  ];
}

const outDateOptions = getOutdateOptions();

@connect(({ memberCenter }) => ({ memberCenter }))
class CompanyQualificationPeriodManagement extends React.Component {
  state = {
    query: Immutable.Map({
      userNo: '',
      certSubject: '',
      phone: '',
      username: '',
      licenseExpiration: [],
      expirationLeft: '',
      page: 1,
      length: 10
    }),
    userIdList: []
  };

  getQualificationList = () => {
    let { userNo, certSubject, phone, username, licenseExpiration, expirationLeft, page, length } = this.state.query.toJS();
    let params = {
      userNo,
      certSubject,
      phone,
      username,
      expirationLeft,
      page,
      length
    };
    if (licenseExpiration[0]) {
      params.minLicenseExpiration = licenseExpiration[0].startOf('day').valueOf();
    }
    if (licenseExpiration[1]) {
      params.maxLicenseExpiration = licenseExpiration[1].endOf('day').valueOf();
    }
    if (!expirationLeft) {
      params.expirationLeft = null;
    }
    return reaction.memberCenter.getAlmostTimeoutQualificationList(params);
  };

  goToView = (record) => {
    historyPush(`/${RoutePath.MEMBERCENTER}/${RoutePath.QUALIFICATION_LIST}/${RoutePath.QUALIFICATION_DETAIL}`, { userId: record.userId, operationType: 'update_enterprise_cert', backPath: encodeURIComponent('/memberCenter/companyQualificationPeriodManagement') });
  };

  goToModify = (record) => {
    historyPush(`/${RoutePath.MEMBERCENTER}/${RoutePath.QUALIFICATION_LIST}/${RoutePath.MODIFY_QUALIFICATION}`, { userId: record.userId, operationType: 'update_enterprise_cert', backPath: encodeURIComponent('/memberCenter/companyQualificationPeriodManagement') });
  };

  onQueryChange = (name, value) => {
    let { query } = this.state;
    query = query.set(name, value);
    this.setState({ query });
  };

  doSearch = (e) => {
    let { query } = this.state;
    query = query.set('page', 1);
    this.setState({ query }, () => {
      this.getQualificationList().then((res) => {
        if (res.code === 0) {
          this.setState({
            userIdList: []
          });
        }
      });
    });
    e && e.preventDefault();
  };

  handleTableChange = (pagination, filters, sorter) => {
    let { query } = this.state;
    query = query.set('page', pagination.current);
    query = query.set('length', pagination.pageSize);
    this.setState({ query }, () => {
      this.getQualificationList();
    });
  };

  clear = () => {
    let { query } = this.state;
    query = query.set('userNo', '');
    query = query.set('username', '');
    query = query.set('phone', '');
    query = query.set('licenseExpiration', []);
    query = query.set('expirationLeft', '');
    query = query.set('certSubject', '');

    this.setState({ query });
  };

  exportItems = () => {
    let { userNo, certSubject, phone, username, licenseExpiration, expirationLeft, page, length } = this.state.query.toJS();
    let params = {
      userNo,
      certSubject,
      phone,
      username,
      expirationLeft,
      page,
      length
    };
    if (licenseExpiration[0]) {
      params.minLicenseExpiration = licenseExpiration[0].startOf('day').valueOf();
    }
    if (licenseExpiration[1]) {
      params.maxLicenseExpiration = licenseExpiration[1].endOf('day').valueOf();
    }
    if (!(this.state.userIdList) || this.state.userIdList.length === 0) {
      params.exportAll = true;
    } else {
      params.exportAll = false;
    }
    params.userIdList = this.state.userIdList;
    reaction.memberCenter.exportTimeoutQualificationItems(params).then(res => {
      if (res.code === 0) {
        this.download(res.data.url, '资质到期列表');
      }
      return res;
    });
  };

  onPressEnter = () => {
    this.doSearch();
  };

  download = (fileKey, fileName) => {
    location.href = `/api/v1/common/download?token=${uamsdk.getToken()}&fileKey=${fileKey}&fileName=${encodeURIComponent(fileName)}`;
  };

  goToSetEmail = () => {
    historyPush(`/${RoutePath.MEMBERCENTER}/${RoutePath.COMPANY_QUALIFICATION_PERIOD_MANAGEMENT}/${RoutePath.EMAIL_SET}`);
  };

  componentDidMount () {
    this.getQualificationList();
  }

  getColumns() {
    return  [{
      title: '序号',
      render: (text, record, index) => {
        return (this.state.query.toJS().page - 1) * this.state.query.toJS().length + index + 1;
      }
    }, {
      title: '会员编号',
      dataIndex: 'userNo'
    }, {
      title: '用户名',
      dataIndex: 'username'
    }, {
      title: '安全手机',
      dataIndex: 'phone'
    }, {
      title: '主体名称',
      dataIndex: 'certSubject'
    }, {
      title: '到期时间',
      dataIndex: 'licenseExpiration',
      render: (text) => {
        if (!text) {
          return '';
        }
        return dayjs(text - 0).format('YYYY-MM-DD HH:mm:ss');
      }
    }, {
      title: '最后期限',
      dataIndex: 'expirationLeft'
    }, {
      title: '操作',
      render: (text, record) => (
        <span>
          <Button onClick={() => { this.goToView(record); }}>查看</Button>&nbsp;
          <Button onClick={() => { this.goToModify(record); }}>修改</Button>
        </span>
      ),
    }];
  }

  render () {
    let list = this.props.memberCenter.timeoutQualificationList;
    let queryValue = this.state.query.toJS();
    const rowSelection = {
      selectedRowKeys: this.state.userIdList,
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({
          userIdList: selectedRowKeys
        });
      },
      getCheckboxProps: record => ({
        disabled: false, // Column configuration not to be checked
        name: record.name
      }),
    };
        
    return (
      <div>
        <div style={{ overflow: 'hidden' }}>
          <h2 style={{ float: 'left' }} className={commonStyles.row}>企业资质到期资质列表</h2>
          <Button type="primary" style={{ float: 'right' }} onClick={this.goToSetEmail}>邮件通知设置</Button>
        </div>
        <Form>
          {
            renderListControls([
              <FormItem label="会员编号">
                <Input onPressEnter={this.onPressEnter} value={queryValue.userNo} onChange={(e) => { this.onQueryChange('userNo', e.target.value); }} placeholder="会员编号" />
              </FormItem>,
              <FormItem label="主体名称">
                <Input onPressEnter={this.onPressEnter} value={queryValue.certSubject} onChange={(e) => { this.onQueryChange('certSubject', e.target.value); }} placeholder="主体名称" />
              </FormItem>,
              <FormItem label="手机号码">
                <Input onPressEnter={this.onPressEnter} value={queryValue.phone} onChange={(e) => { this.onQueryChange('phone', e.target.value); }} placeholder="手机号码" />
              </FormItem>,
              <FormItem label="用户名">
                <Input onPressEnter={this.onPressEnter} value={queryValue.username} onChange={(e) => { this.onQueryChange('username', e.target.value); }} placeholder="用户名" />
              </FormItem>,
              <FormItem label="到期时间">
                <RangePicker
                  value={queryValue.licenseExpiration}
                  format={dateFormat}
                  locale={locale}
                  onChange={(value) => { this.onQueryChange('licenseExpiration', value); }}
                />
              </FormItem>,
              <FormItem label="最后期限">
                <Select
                  showSearch
                  placeholder="选择状态"
                  optionFilterProp="children"
                  value={queryValue.expirationLeft}
                  onChange={value => { this.onQueryChange('expirationLeft', value); }}
                >
                  {outDateOptions.map(item => {
                    return <Option value={item.value} key={item.value || item.key} title={item.label}>{item.label}</Option>;
                  })}
                </Select>
              </FormItem>,
              {
                full: true,
                colProps: {
                  xxl: 12,
                  xl: 24,
                  md: 24,
                  lg: 24,
                  sm: 24,
                },
                children: (
                  <FormItem align="right">
                    <Button icon="search" type="primary" onClick={this.doSearch}>查询</Button>
                    <Button icon="redo" style={{ margin: '0 0 0 5px' }} type="primary" onClick={this.clear}>清空</Button>
                    <Button icon="export" style={{ margin: '0 0 0 5px' }} type="primary" onClick={this.exportItems}>按筛选条件导出</Button>
                  </FormItem>
                )
              }
            ])
          }
        </Form>
        {
          list && <Table className={commonStyles.row}
            rowKey="userId"
            rowSelection={rowSelection}
            columns={this.getColumns()}
            dataSource={list.result}
            pagination={{ current: list.pagerInfo.page, total: list.pagerInfo.total, pageSizeOptions: ['10', '20', '50'], showSizeChanger: true, showQuickJumper: true, showTotal: (total) => `共${total}条` }}
            onChange={this.handleTableChange} />
        }
      </div>);
  }
}

export default CompanyQualificationPeriodManagement;