import _objectWithoutProperties from "/home/gitlab-runner/builds/bcbe11de/0/commonservice/common-uam-html5/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties";
import createUID from 'utils/createUID';
import http from 'utils/http';
var FRONTEND_LIST = createUID();
var BACKEND_LIST = createUID();
var initialState = { list: { info: { page: 1, total: 0 }, result: [] } };
function reducer() { var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState; var action = arguments.length > 1 ? arguments[1] : undefined; switch (action.type) {
    case FRONTEND_LIST: return Object.assign({}, state, { frontendList: action.data });
    case BACKEND_LIST: return Object.assign({}, state, { backendList: action.data });
    default: return state;
} }
Object.assign(reducer, { getFrontList: function getFrontList(_ref) { var _ref$userId = _ref.userId, userId = _ref$userId === void 0 ? '' : _ref$userId, logTimeBegin = _ref.logTimeBegin, logTimeEnd = _ref.logTimeEnd, eventType = _ref.eventType, platformCode = _ref.platformCode, phone = _ref.phone, _ref$page = _ref.page, page = _ref$page === void 0 ? 1 : _ref$page, _ref$length = _ref.length, length = _ref$length === void 0 ? 10 : _ref$length; return function (dispatch) { return http.request('v1.system.listSystemLog', { queryCondition: { userId: userId, logTimeBegin: logTimeBegin, logTimeEnd: logTimeEnd, eventType: eventType, platformCode: platformCode, phone: phone }, pagerCondition: { page: page, length: length } }).then(function (data) { dispatch({ type: FRONTEND_LIST, data: data.data }); return data; }); }; }, getBackendList: function getBackendList(_ref2) { var _ref2$page = _ref2.page, page = _ref2$page === void 0 ? 1 : _ref2$page, _ref2$length = _ref2.length, length = _ref2$length === void 0 ? 10 : _ref2$length, params = _objectWithoutProperties(_ref2, ["page", "length"]); return function (dispatch) { return http.request('v1.system.listBackLog', { queryCondition: params, pagerCondition: { page: page, length: length } }).then(function (data) { dispatch({ type: BACKEND_LIST, data: data.data }); return data; }); }; } });
export default reducer;
