import createUID from 'utils/createUID';
import http from 'utils/http';
import {HttpCode} from 'utils/constants';
import reaction from 'reaction';

const GET_RULE_LIST = createUID();

let initialState = {
  ruleList: []
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_RULE_LIST:
      return Object.assign({}, state, {ruleList: action.data});
    default:
      return state;
  }
}

Object.assign(reducer, {
  getRuleList (configName) {
    return (dispatch) => {
      return http.request('v1.funds.listConfig', {ruleName: configName})
        .then((data) => {
          if (data.code === HttpCode.OK) {
            dispatch({type: GET_RULE_LIST, data: data.data});
          }
          return data;
        });
    };
  },
  addRule (params) {
    return (dispatch) => {
      return http.request('v1.funds.addConfig', {...params});
    };
  },
  modifyRule ({id, ruleCode, ruleName, valueType, ruleValue}) {
    return (dispatch) => {
      return http.request('v1.funds.updateConfig', {id, ruleCode, ruleName, valueType, ruleValue});
    };
  }
});
export default reducer;
