import createUID from 'utils/createUID';
import http from 'utils/http';
import {HttpCode} from 'utils/constants';

const GET_LIST = createUID();
const GET_DETAIL = createUID();
const DELETE = createUID();

const initialState = {
  list: {
    pagerInfo: {page: 1, total: 0},
    result: []
  }
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_LIST:
      return Object.assign({}, state, {list: action.data});
    case GET_DETAIL:
      return Object.assign({}, state, {detail: action.data});
    case DELETE: {
      let result = state.list.result.filter((data) => data.uid !== action.data);
      return Object.assign({}, state, {list: {...state.list, result}});
    }
    default:
      return state;
  }
}

Object.assign(reducer, {
  getList(userName = '', page = 1, length = 10) {
    return (dispatch) => {
      return http.request('back.blacklist.list', {userName, page, length})
        .then((data) => {
          dispatch({type: GET_LIST, data: data.data});
          return data;
        });
    };
  },
  getDetail(uid) {
    return (dispatch) => {
      return http.request('back.blacklist.detail', {uid})
        .then((data) => {
          dispatch({type: GET_DETAIL, data: data.data});
          return data;
        });
    };
  },
  create(userName, forbidPlatformId) {
    return (dispatch) => {
      return http.request('back.blacklist.create', {userName, forbidPlatformId})
        .then((data) => {
          //dispatch({type: CREATE, data: data.data});
          return data;
        });
    };
  },
  delete(uid) {
    return (dispatch) => {
      return http.request('back.blacklist.delete', {uid})
        .then((data) => {
          if (data.code === HttpCode.OK) {
            dispatch({type: DELETE, data: uid});
          }
          return data;
        });
    };
  },
  update(uid, forbidPlatformId) {
    return (dispatch) => {
      return http.request('back.blacklist.update', {uid, forbidPlatformId})
        .then((data) => {
          //dispatch({type: UPDATE, data: data.data});
          return data;
        });
    };
  },
});
export default reducer;