import React from 'react';
import dayjs from 'dayjs';
import Immutable from 'immutable';
import { connect } from 'react-redux';
// import history from 'utils/history';
import { withRouter } from 'react-router-dom';
import { Button, Input, Table, Form } from 'antd';

import FormItem from 'components/FormItem';

import reaction from 'reaction/index';
import { renderListControls } from 'utils';
import { AuthCodes, RoutePath } from 'utils/constants';
// import { historyPush } from 'utils/history';

import commonStyles from 'style/common.module.scss';

@connect(({ smsManagement, me }) => ({ smsManagement, me }))
@withRouter
class SmsManagement extends React.Component {
  state = {
    query: Immutable.Map({
      templateCode: '',
      templateName: '',
      templateDescription: '',
      alismsTemplateCode: '',
      page: 1,
      length: 10,
    }),
    currentRow: null,
  };

  getSmsModelList = () => {
    let query = this.state.query.toJS();
    reaction.smsManagement.getSmsModelList(query);
  };

  onQueryChange = (name, value) => {
    let { query } = this.state;
    query = query.set(name, value);
    this.setState({ query }, () => {});
  };

  handleTableChange = (pagination, filters, sorter) => {
    let { query } = this.state;
    query = query.set('page', pagination.current);
    query = query.set('length', pagination.pageSize);
    this.setState({ query }, () => {
      this.getSmsModelList();
    });
  };

  search = () => {
    let { query } = this.state;
    query = query.set('page', 1);
    this.setState({ query }, () => {
      this.getSmsModelList();
    });
  };

  goToViewSmsHistory = () => {
    this.props.history.push({
      pathname: `/${RoutePath.OPERATION_CENTER}/${RoutePath.SMS_MANAGEMENT}/${RoutePath.SMS_HISTORY}`,
    });
  };

  goToAddSmsModel = () => {
    this.props.history.push({
      pathname: `/${RoutePath.OPERATION_CENTER}/${RoutePath.SMS_MANAGEMENT}/${RoutePath.ADD}`,
    });
  };

  goToView = (p) => {
    this.props.history.push({
      pathname: `/${RoutePath.OPERATION_CENTER}/${RoutePath.SMS_MANAGEMENT}/${RoutePath.DETAIL}`,
      search: `?id=${p.id}`
    });
  };

  goToEdit = (p) => {
    this.props.history.push({
      pathname: `/${RoutePath.OPERATION_CENTER}/${RoutePath.SMS_MANAGEMENT}/${RoutePath.MODIFY}`,
      search: `?id=${p.id}`
    });
  };

  componentDidMount() {
    // let { roleAuthList } = this.props.me.roleInfo;
    // let smsModelListEnabled = roleAuthList.some((item) => { if (item.authCode.indexOf(AuthCodes.OPERATION_SMS_TEMPLATE_LIST) !== -1) return true; });
    // if (!smsModelListEnabled) {
    //   historyPush(RoutePath.INDEX);
    //   return null;
    // }
    this.getSmsModelList();
  }

  render() {
    let { roleAuthList } = this.props.me.roleInfo;
    let smsModelViewEnabled = roleAuthList.some((item) => {
      return item.authCode.indexOf(AuthCodes.OPERATION_SMS_TEMPLATE_GET) !== -1;
    });
    let smsModelUpdateEnabled = roleAuthList.some((item) => {
      return item.authCode.indexOf(AuthCodes.OPERATION_SMS_TEMPLATE_UPDATE) !== -1;
    });

    let { list } = this.props.smsManagement;
    let { query } = this.state;
    let columns = [
      {
        width: 180,
        title: '模板名称',
        dataIndex: 'templateName',
      },
      {
        width: 200,
        title: 'code',
        dataIndex: 'templateCode',
      },
      {
        width: 130,
        title: '服务商代码',
        dataIndex: 'alismsTemplateCode',
      },
      {
        title: '描述',
        dataIndex: 'templateDescription',
      },
      {
        width: 64,
        title: '状态',
        dataIndex: 'isValid',
        render: (text) => {
          return text ? '启用' : '禁用';
        },
      },
      {
        width: 115,
        title: '最后更新时间',
        dataIndex: 'lastModifiedTime',
        render: (text) => {
          if (!text) {
            return '';
          }
          return dayjs(text - 0).format('YYYY-MM-DD HH:mm:ss');
        },
      },
      {
        width: 175,
        title: '操作',
        key: 'hanlder',
        render: (row) => {
          return (
            <span>
              {smsModelViewEnabled ? (
                <Button
                  type="primary"
                  onClick={() => {
                    this.goToView(row);
                  }}
                >
                  查看
                </Button>
              ) : (
                ''
              )}
              {smsModelUpdateEnabled ? (
                <Button
                  style={{ marginLeft: 5, marginTop: 5 }}
                  type="primary"
                  onClick={() => {
                    this.goToEdit(row);
                  }}
                >
                  修改
                </Button>
              ) : (
                ''
              )}
            </span>
          );
        },
      },
    ];

    return (
      <div>
        <h4 style={{ overflow: 'hidden' }}>
          <span>短信管理</span>
        </h4>
        <div style={{ padding: '10px 0 30px 0' }}>
          <Button onClick={this.goToViewSmsHistory} type="primary">
            短信发送历史
          </Button>
          <Button
            style={{ marginLeft: 10 }}
            onClick={this.goToAddSmsModel}
            type="primary"
          >
            新增短信模板
          </Button>
        </div>
        <Form layout="inline">
          {
            renderListControls([
              <FormItem label="模板名称">
                <Input
                  value={query.toJS().templateName}
                  onChange={(e) => {
                    this.onQueryChange('templateName', e.target.value);
                  }}
                  // style={{ width: '250px', marginLeft: '15px', marginTop: 10 }}
                  // addonBefore="模板名称"
                  defaultValue=""
                  placeholder="请输入模板名称"
                />
              </FormItem>,
              <FormItem label="code">
                <Input
                  value={query.toJS().templateCode}
                  onChange={(e) => {
                    this.onQueryChange('templateCode', e.target.value);
                  }}
                  // style={{ width: '250px', marginLeft: '15px', marginTop: 10 }}
                  // addonBefore="code"
                  defaultValue=""
                  placeholder="请输入code"
                />
              </FormItem>,
              <FormItem label="服务商代码">
                <Input
                  value={query.toJS().alismsTemplateCode}
                  onChange={(e) => {
                    this.onQueryChange('alismsTemplateCode', e.target.value);
                  }}
                  // style={{ width: '250px', marginLeft: '15px', marginTop: 10 }}
                  // addonBefore="服务商代码"
                  defaultValue=""
                  placeholder="请输入服务商代码"
                />
              </FormItem>,
              <FormItem label="描述">
                <Input
                  value={query.toJS().templateDescription}
                  onChange={(e) => {
                    this.onQueryChange('templateDescription', e.target.value);
                  }}
                  // style={{ width: '250px', marginLeft: '15px', marginTop: 10 }}
                  // addonBefore="描述"
                  defaultValue=""
                  placeholder="请输入描述"
                />
              </FormItem>,
              {
                colProps: {
                  xxl: 24,
                  xl: 16,
                  md: 24,
                  lg: 24,
                },
                children: (
                  <FormItem align="right">
                    <Button
                      type="primary"
                      // style={{ marginLeft: '15px', marginTop: 10 }}
                      onClick={this.search}
                      icon="search"
                    >
                      查询
                    </Button>
                  </FormItem>
                )
              }
            ])
          }
        </Form>
        <div style={{ padding: '20px 0 0 0' }}>
          {
            <Table
              className={commonStyles.row}
              rowKey="id"
              columns={columns}
              dataSource={list ? list.result : []}
              pagination={
                list
                  ? {
                      current: list.pagerInfo.page,
                      total: list.pagerInfo.total,
                      pageSizeOptions: ['10', '20', '50'],
                      showSizeChanger: true,
                      showQuickJumper: true,
                      showTotal: (total) => `共${total}条`,
                    }
                  : false
              }
              onChange={this.handleTableChange}
            />
          }
        </div>
      </div>
    );
  }
}

export default SmsManagement;
