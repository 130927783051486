import React, { Component } from 'react';
import { Upload, Icon, message } from 'antd';
import { ViewerContainer } from 'components/ImageViewer';

import * as env from 'config/env';

/**
 * 文件上传
 */
export default class FileUpload extends Component {
  state = {
    showBigImg: false,
    bigImgUrl: '',
    imgWidth: 520,
    uploading: false,
    fileList: []
  };

  componentDidUpdate(prevProps) {
    const { fileList } = this.props;
    if (fileList.length !== prevProps.fileList.length) {
      this.setState({ fileList: fileList });
    }
  }

  beforeUpload = (onlyOne, file, fileList) => {
    const { beforeUpload } = this.props;
    if (file.size > 10240000) {
      message.error('文件大小不能超过10MB!');
      fileList.splice(fileList.indexOf(file), 1);
      return false;
    }
    if (!/^image\//.test(file.type)) {
      message.error('只能上传图片文件!');
      fileList.splice(fileList.indexOf(file), 1);
      return false;
    }
    this.setState({ uploading: true });
    beforeUpload && beforeUpload();
    return true;
  };

  onUploadChange = (onlyOne, file, fileList) => {
    const { uploaded } = this.props;

    if (file.response && file.response.code === 0) {
      if (onlyOne && fileList.length > 1) {
        fileList.shift();
      }
    } else if (file.response && file.response.code !== 0) {
      fileList.splice(fileList.indexOf(file), 1);
      message.error(file.response.msg || '上传失败!');
    }

    let result = null;
    if (
      fileList &&
      fileList[0] &&
      fileList[0].response &&
      fileList[0].response.data.url
    ) {
      const { uid, name, status, thumbUrl, response } = fileList[0];
      result = { uid, name, status, thumbUrl, response };
    }

    uploaded && uploaded(result);
    this.setState({ fileList, uploading: false });
  };

  onPreview = file => {
    let url = file.thumbUrl;
    this.setState({ showBigImg: true, bigImgUrl: url });
  };
  closeBigImgModal = () => {
    this.setState({ showBigImg: false });
  };

  handlePreviewFile = (file) => {
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        // 重写图片预览
        resolve(reader.result);
      };
    });
  }

  render() {
    const { showBigImg, uploading, fileList } = this.state;
    const { className } = this.props;

    return (
      <React.Fragment>
        <Upload
          name='file'
          accept='image/jpg,image/jpeg,image/png'
          action={env.getUploadApi('/v1/cert/upload')}
          beforeUpload={(file, fileList) => {
            return this.beforeUpload(true, file, fileList);
          }}
          onChange={({ file, fileList }) => {
            this.onUploadChange(true, file, fileList);
          }}
          onPreview={file => {
            this.onPreview(file);
          }}
          listType='picture-card'
          fileList={fileList}
          className={className}
          previewFile={this.handlePreviewFile}
        >
          <div>
            <Icon type={uploading ? 'loading' : 'plus'} />
            <div className='ant-upload-text'>点击上传</div>
          </div>
        </Upload>
        <ViewerContainer imageUrl={this.state.bigImgUrl} visible={showBigImg} onClose={this.closeBigImgModal} />
      </React.Fragment>
    );
  }
}
