import React from 'react';
import dayjs from 'dayjs';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { Table, Button, Input, DatePicker, Select, Form } from 'antd';

import FormItem from 'components/FormItem';

import reaction from 'reaction';
import { getUniqTableRowKey, renderListControls } from 'utils';
import { platformCodes, platformList, RoutePath } from 'utils/constants';

import commonStyles from 'style/common.module.scss';

const { RangePicker } = DatePicker;
const { Option } = Select;
const location = window.location;
let dateFormat = 'YYYY/MM/DD';

const shouldHiddenPlatformList = [ platformCodes.oa];
const platformListOptions = Object.values(platformList).filter(
  (el) => shouldHiddenPlatformList.indexOf(el.value) === -1
);

function resolveIdentityListByPlatform(platformList = [], identityList = []) {
  return identityList.filter(el => {
    return platformList.some(p => p.value === el.platformCode);
  });
}


@connect(({ memberCenter }) => ({ memberCenter }))
class IdentifyList extends React.Component {
  state = {
    query: Immutable.Map({
      userNo: '',
      name: '',
      phone: '',
      identity: '',
      isInternalUser: false,
      registerTime: [],
      page: 1,
      length: 10,
    }),
  };

  getIdentifyList = () => {
    let {
      userNo,
      name,
      phone,
      identity,
      isInternalUser,
      registerTime,
      proxyType,
      page,
      length,
    } = this.state.query.toJS();
    let params = {
      userNo,
      name,
      phone,
      identity,
      isInternalUser,
      page,
      length,
    };
    if (registerTime[0]) {
      params.startTime = registerTime[0].startOf('day').valueOf();
    }
    if (registerTime[1]) {
      params.endTime = registerTime[1].endOf('day').valueOf();
    }
    reaction.memberCenter.getIdentifyList(params);
  };

  goToView = (record) => {
    // historyPush(`/${RoutePath.MEMBERCENTER}/${RoutePath.IDENTITY_LIST}/${RoutePath.VIEW_IDENTITY}`, {userId: record.userId});
    window.open(
      `//${location.host}/#/${RoutePath.MEMBERCENTER}/${RoutePath.IDENTITY_LIST}/${RoutePath.VIEW_IDENTITY}?userId=${record.userId}`
    );
  };

  goToEdit = (record) => {
    // historyPush(`/${RoutePath.MEMBERCENTER}/${RoutePath.IDENTITY_LIST}/${RoutePath.EDIT_IDENTITY}`, {userId: record.userId});
    window.open(
      `//${location.host}/#/${RoutePath.MEMBERCENTER}/${RoutePath.IDENTITY_LIST}/${RoutePath.EDIT_IDENTITY}?userId=${record.userId}`
    );
  };

  onQueryChange = (name, value) => {
    let { query } = this.state;
    query = query.set(name, value);
    this.setState({ query }, () => {});
  };

  onPressEnter = () => {
    this.doSearch();
  };

  getIdentityDict = () => {
    reaction.memberCenter.getIdentifyDictList();
  };

  handleTableChange = (pagination, filters, sorter) => {
    let { query } = this.state;
    query = query.set('page', pagination.current);
    query = query.set('length', pagination.pageSize);
    this.setState({ query }, () => {
      this.getIdentifyList();
    });
  };

  doSearch = (e) => {
    let { query } = this.state;
    query = query.set('page', 1);
    this.setState({ query }, () => {
      this.getIdentifyList();
    });
    e && e.preventDefault();
  };

  clear = () => {
    let { query } = this.state;
    query = query.set('userNo', '');
    query = query.set('username', '');
    query = query.set('phone', '');
    query = query.set('active', '');
    query = query.set('registerTime', []);
    query = query.set('name', '');

    this.setState({ query }, () => {
      // ......
    });
  };

  componentDidMount() {
    this.getIdentifyList();
    this.getIdentityDict();
  }

  getColumns() {
    return [
      {
        title: '序号',
        key: 'number',
        // width: 60,
        render: (text, record, index) => {
          return (
            (this.state.query.toJS().page - 1) *
              this.state.query.toJS().length +
            index + 1
          );
        },
      },
      {
        title: '会员编号',
        dataIndex: 'userNo',
      },
      {
        title: '用户名',
        dataIndex: 'username',
      },
      {
        title: '手机号码',
        dataIndex: 'phone',
      },
      {
        title: '主体性质',
        // width: 130,
        dataIndex: 'certType',
        render: (v) => {
          if (v === '1') {
            return '企业';
          } else {
            return '司机';
          }
        },
      },
      {
        title: '主体名称',
        dataIndex: 'name',
        width: 180,
      },
      {
        title: '已有身份',
        dataIndex: 'existIdentity',
        // width: 200,
        
        render: (arr) => {
          if (!arr) {
            return '';
          } else {
            return arr.join(',');
          }
        },
      },
      {
        title: '更新时间',
        dataIndex: 'updateTime',
        width: 160,
        render: (text) => {
          if (!text) {
            return '';
          }
          return dayjs(text - 0).format('YYYY-MM-DD HH:mm:ss');
        },
      },
      {
        title: '操作',
        key: 'handler',
        width: 200,
        render: (text, record) => (
          <span>
            <Button
              onClick={() => {
                this.goToView(record);
              }}
            >
              查看
            </Button>
            <Button
              style={{ marginLeft: 10 }}
              onClick={() => {
                this.goToEdit(record);
              }}
            >
              身份管理
            </Button>
          </span>
        ),
      },
    ];
  }
  render() {
    let list = this.props.memberCenter.identityList;
    let queryValue = this.state.query.toJS();
    let identityDictList = this.props.memberCenter.identityDictList;
    const identityList = resolveIdentityListByPlatform(platformListOptions, identityDictList);

    return (
      <div>
        <div>
          <h2 className={commonStyles.row}>身份列表</h2>
        </div>
        <Form layout="inline" className="form-inline" labelCol={{ xl: 7, sm: 12 }} wrapperCol={{ xl: 17, sm: 12 }}>
          {
            renderListControls([
              {
                children: (
                  <FormItem label="会员编号">
                    <Input
                      onPressEnter={this.onPressEnter}
                      value={queryValue.userNo}
                      onChange={(e) => {
                        this.onQueryChange('userNo', e.target.value);
                      }}
                      placeholder="会员编号"
                    />
                  </FormItem>
                )
              },
              {
                children: (
                  <FormItem label="主体名称">
                    <Input
                      onPressEnter={this.onPressEnter}
                      value={queryValue.name}
                      onChange={(e) => {
                        this.onQueryChange('name', e.target.value);
                      }}
                      placeholder="主体名称"
                    />
                  </FormItem>
                )
              },
              <FormItem label="手机号码">
                <Input
                  onPressEnter={this.onPressEnter}
                  value={queryValue.phone}
                  onChange={(e) => {
                    this.onQueryChange('phone', e.target.value);
                  }}
                  placeholder="手机号码"
                />
              </FormItem>,
              <FormItem label="身份">
                <Select
                  showSearch
                  placeholder="选择身份"
                  value={queryValue.identity}
                  onChange={(v) => {
                    this.onQueryChange('identity', v);
                  }}
                >
                  <Option value="">全部</Option>
                  {
                    identityList.map((item) => {
                      return <Option value={item.code} key={item.code}>{item.name}</Option>;
                    })
                  }
                </Select>
              </FormItem>,
              <FormItem label="内部账户">
                <Select
                  showSearch
                  placeholder="内部账户"
                  value={queryValue.isInternalUser === null ? null : +queryValue.isInternalUser}
                  onChange={(v) => {
                    this.onQueryChange('isInternalUser', v === null ? null : !!v);
                  }}
                >
                  <Option value={null}>全部</Option>
                  <Option value={1}>是</Option>
                  <Option value={0}>否</Option>
                </Select>
              </FormItem>,
              <FormItem label="更新时间">
                <RangePicker
                  value={queryValue.registerTime}
                  format={dateFormat}
                  onChange={(value) => {
                    this.onQueryChange('registerTime', value);
                  }}
                />
              </FormItem>,
              {
                full: true,
                colProps: {
                  xxl: 12,
                  xl: 24,
                  md: 24,
                  lg: 24,
                },
                children: (
                  <FormItem align="right">
                    <Button
                      type="primary"
                      onClick={this.doSearch}
                      icon="search"
                    >
                      查询
                    </Button>
                    <Button
                      style={{ margin: '0 0 0 5px' }}
                      type="primary"
                      onClick={this.clear}
                      icon="redo"
                    >
                      清空
                    </Button>
                  </FormItem>
                )
              }
            ], { justify: 'start' })
          }
        </Form>
        {list && (
          <Table
            className={commonStyles.row}
            rowKey={getUniqTableRowKey('userId', list.pagerInfo)}
            columns={this.getColumns()}
            dataSource={list.result}
            pagination={{
              current: list.pagerInfo.page,
              total: list.pagerInfo.total,
              pageSizeOptions: ['10', '20', '50'],
              showSizeChanger: true,
              showQuickJumper: true,
              showTotal: (total) => `共${total}条`,
            }}
            onChange={this.handleTableChange}
          />
        )}-*
      </div>
    );
  }
}

export default IdentifyList;
