import React from 'react';
import { message } from 'antd';
import reaction from 'reaction';
import { HttpCode } from 'utils/constants';
import { historyPush } from 'utils/history';
import cookie from 'utils/cookie';

import styles from './index.module.scss';

/**
 * 用户登录
 */
export default class Login extends React.Component {
  componentDidMount() {
    if (uamsdk.loggedIn() || cookie.getCookie('uam_sso_server_token')) {
      historyPush('/');
    } else {
      uamsdk.createLoginIframe('loginIframeWrapper', {}, (res) => {
        if (res.code === HttpCode.OK) {
          reaction.me.initialData().then((_data) => {
            if (_data.code === HttpCode.OK) {
              historyPush('/');
            } else {
              message.error('系统错误');
            }
          });
        } else {
          message.error('系统错误');
        }
      });
    }
  }

  login = () => {
    if (!uamsdk.loggedIn() && !cookie.getCookie('uam_sso_server_token')) {
      uamsdk.login((data) => {
        // console.log('uamsdk.login()', data); // eslint-disable-line
        if (!data.error) {
          reaction.me.initialData().then((_data) => {
            if (_data.code === HttpCode.OK) {
              historyPush('/');
            }
          });
        }
      });
    }
  };

  render() {
    return (
      <div
        className={styles.form}
        id="loginIframeWrapper"
        style={{ height: 430 }}
      >
        {/*<h2 className={commonStyles.row} style={{textAlign: 'center'}}>您需要登录后才能继续操作</h2>*/}
        {/*<Button type="primary" onClick={this.login}>立即登录</Button>*/}
      </div>
    );
  }
}
