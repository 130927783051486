import { uamsdk } from 'utils';
var protocol = location.protocol;
var hostname = location.hostname;
export var DEBUG = process.env.NODE_ENV !== 'production';
export var RELEASE = process.env.NODE_ENV === 'production'; // export const isLocal = hostname === 'localhost';
export var PROJECT_ID = 'uam';
export var env = hostname.match(/test/) ? 'test' : 'dev';
var usedHostname = DEBUG ? "".concat(PROJECT_ID, ".").concat(env, ".zlgx.com") : hostname;
export var SSO_URL = "//".concat(usedHostname.replace(PROJECT_ID, 'uam-sso'), "/");
export var ip = usedHostname;
export var port = 80;
export function getHost() { return "".concat(protocol, "//").concat(usedHostname); }
export function getRestApi(method) {
    if (method.indexOf('//') !== -1) {
        return method;
    }
    return "".concat(protocol, "//").concat(ip, "/api/").concat(method.split('.').join('/')); // method: role.list:
}
export function getUploadApi(path) {
    return "".concat(protocol, "//").concat(ip, "/api").concat(path, "?token=").concat(uamsdk.getToken()); // method: role.list:
}
export function getCaptchaUrl() { return "".concat(protocol, "//").concat(ip, "/api/kaptcha.jpg"); }
