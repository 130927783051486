import React from 'react';
import dayjs from 'dayjs';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import {
  Button,
  Input,
  Select,
  // DatePicker,
  Radio,
  message,
  Form,
  Upload,
  Icon,
  // Checkbox,
  Modal,
  AutoComplete,
  Table,
  Tooltip
} from 'antd';

import MagnifyEnableImg from 'components/MagnifyEnableImg';

import DatePickAndCheckboxFormItem from 'components/DatePickAndCheckboxFormItem';
import Tree from 'components/Tree';
// import VirtualTree from 'components/Tree/VirtualTree';

import reaction from 'reaction';
import * as env from 'config/env';
import { RoutePath } from 'utils/constants';
import { historyPush } from 'utils/history';
import { REJECT_REASON_MAX_LENGTH } from 'config';
import { certCompletionStatusCode } from 'config/constant';
import { validHasCenpurCert, resolveUrlQuery, formatInviteSteelValue, getUniqTableRowKey, checkIsSupplier, checkIsPurchase, getFilePreviewUrl, getPreviewImageWidth } from 'utils';
// import TreeTransfer from 'components/TreeTransfer';


import commonStyles from 'style/common.module.scss';
import unuploadImg from 'assets/unupload.png';

const AOption = AutoComplete.Option;
const FormItem = Form.Item;
const RadioGroup = Radio.Group;
const { TextArea } = Input;
const Option = Select.Option;

@Form.create()
@connect(({ memberCenter, common, me, frontUser }) => ({ memberCenter, common, me, frontUser }))
class CheckQualificationDetail extends React.Component {
  state = {
    province: [],
    city: [],
    district: [],
    showBigImg: false,
    bigImgUrl: '',
    imgWidth: 520,
    expirationTime: '',
    legalPersonIdentityCardFrontUrl: [],
    legalPersonIdentityCardBackUrl: [],
    agentIdentityCardFrontUrl: [],
    agentIdentityCardBackUrl: [],
    query: Immutable.Map({
      page: 1,
      length: 50
    }),
    showRefuseModal: false,
    reason: '',
    supplyCategory: [], // 供应品类
    purchaserCategory: [], // 采购品类
    treeData: []
  };
  closeBigImgModal = () => {
    this.setState({
      showBigImg: false
    });
  };

  getAreaDict = () => {
    return reaction.memberCenter.getAreaDict().then((res) => {
      if (res.code === 0) {
        this.setState(
          {
            province: res.data.filter((item) => item.type === '2')
          }
        );
      }
    });
  };

  getQualificationInfo = () => {
    let userId = resolveUrlQuery(this.props.location.search).userId;
    return reaction.memberCenter.getQualificationInfo({ userId }).then((res) => {
      let data = res.data || {};
      let { auditAdditionalCert, additionalCert, cert, completionStatus } = data;
      if (res.code === 0) {
        this.setState({
          completionStatus,
          legalPersonIdentityCardFrontUrl: auditAdditionalCert &&
          auditAdditionalCert.legalPersonIdentityCardFrontUrl &&
          auditAdditionalCert.legalPersonIdentityCardFrontUrl.url && [
            {
              uid: 'legalPersonIdentityCardFrontUrl',
              fileKey:
                auditAdditionalCert.legalPersonIdentityCardFrontUrl &&
                auditAdditionalCert.legalPersonIdentityCardFrontUrl.fileKey,
              name: '',
              status: 'done',
              thumbUrl:
                auditAdditionalCert.legalPersonIdentityCardFrontUrl &&
                auditAdditionalCert.legalPersonIdentityCardFrontUrl.url
            }
          ],
          legalPersonIdentityCardBackUrl: auditAdditionalCert &&
          auditAdditionalCert.legalPersonIdentityCardBackUrl &&
          auditAdditionalCert.legalPersonIdentityCardBackUrl.url && [
            {
              uid: 'legalPersonIdentityCardBackUrl',
              fileKey:
                auditAdditionalCert.legalPersonIdentityCardBackUrl &&
                auditAdditionalCert.legalPersonIdentityCardBackUrl.fileKey,
              name: '',
              status: 'done',
              thumbUrl:
                auditAdditionalCert.legalPersonIdentityCardBackUrl &&
                auditAdditionalCert.legalPersonIdentityCardBackUrl.url
            }
          ],
          agentIdentityCardFrontUrl: auditAdditionalCert &&
          auditAdditionalCert.agentIdentityCardFrontUrl &&
          auditAdditionalCert.agentIdentityCardFrontUrl.url && [
            {
              uid: 'agentIdentityCardFrontUrl',
              name: '',
              status: 'done',
              thumbUrl: auditAdditionalCert.agentIdentityCardFrontUrl.url,
              fileKey: auditAdditionalCert.agentIdentityCardFrontUrl.fileKey
            }
          ],
          agentIdentityCardBackUrl: auditAdditionalCert &&
          auditAdditionalCert.agentIdentityCardBackUrl &&
          auditAdditionalCert.agentIdentityCardBackUrl.url && [
            {
              uid: 'agentIdentityCardBackUrl',
              name: '',
              status: 'done',
              thumbUrl: auditAdditionalCert.agentIdentityCardBackUrl.url,
              fileKey: auditAdditionalCert.agentIdentityCardBackUrl.fileKey
            }
          ],
          city: this.props.memberCenter.areaDict.filter((item) => item.parentId === cert.baseInfo.provinceCode),
          district: this.props.memberCenter.areaDict.filter((item) => item.parentId === cert.baseInfo.cityCode),
          supplyCategory: additionalCert && additionalCert.supplyCategory,
          purchaserCategory: additionalCert && additionalCert.purchaserCategory
        });
      }
      return data;
    });
  };

  getModifyHistoryList = () => {
    let { page, length } = this.state.query.toJS();
    reaction.memberCenter.getOperationHistory({
      userId: resolveUrlQuery(this.props.location.search).userId,
      page,
      length,
      operationType: resolveUrlQuery(this.props.location.search).operationType
    });
  };

  handleTableChange = (pagination, filters, sorter) => {
    let { query } = this.state;
    query = query.set('page', pagination.current);
    query = query.set('length', pagination.pageSize);
    this.setState({ query }, () => {
      this.getModifyHistoryList();
    });
  };

  back = () => {
    historyPush(`/${RoutePath.MEMBERCENTER}/${RoutePath.CHECK_QUALIFICATION_LIST}`);
  };

  toComplete = (e) => {
    e && e.preventDefault();
    let values = this.props.form.getFieldsValue();

    // this.props.form.validateFields((err, values) => {
    values.userId = resolveUrlQuery(this.props.location.search).userId;
    values.auditAdditionalCert.legalPersonIdentityCardFrontUrl = values.auditAdditionalCert
      .legalPersonIdentityCardFrontUrl &&
    values.auditAdditionalCert.legalPersonIdentityCardFrontUrl.fileList &&
    values.auditAdditionalCert.legalPersonIdentityCardFrontUrl.fileList[0] &&
    values.auditAdditionalCert.legalPersonIdentityCardFrontUrl.fileList[0].fileKey && {
      fileKey: values.auditAdditionalCert.legalPersonIdentityCardFrontUrl.fileList[0].fileKey
    };
    values.auditAdditionalCert.legalPersonIdentityCardBackUrl = values.auditAdditionalCert
      .legalPersonIdentityCardBackUrl &&
    values.auditAdditionalCert.legalPersonIdentityCardBackUrl.fileList &&
    values.auditAdditionalCert.legalPersonIdentityCardBackUrl.fileList[0] &&
    values.auditAdditionalCert.legalPersonIdentityCardBackUrl.fileList[0].fileKey && {
      fileKey: values.auditAdditionalCert.legalPersonIdentityCardBackUrl.fileList[0].fileKey
    };
    values.auditAdditionalCert.agentIdentityCardFrontUrl = values.auditAdditionalCert.agentIdentityCardFrontUrl &&
    values.auditAdditionalCert.agentIdentityCardFrontUrl.fileList &&
    values.auditAdditionalCert.agentIdentityCardFrontUrl.fileList[0] &&
    values.auditAdditionalCert.agentIdentityCardFrontUrl.fileList[0].fileKey && {
      fileKey: values.auditAdditionalCert.agentIdentityCardFrontUrl.fileList[0].fileKey
    };
    values.auditAdditionalCert.agentIdentityCardBackUrl = values.auditAdditionalCert.agentIdentityCardBackUrl &&
    values.auditAdditionalCert.agentIdentityCardBackUrl.fileList &&
    values.auditAdditionalCert.agentIdentityCardBackUrl.fileList[0] &&
    values.auditAdditionalCert.agentIdentityCardBackUrl.fileList[0].fileKey && {
      fileKey: values.auditAdditionalCert.agentIdentityCardBackUrl.fileList[0].fileKey
    };
    values.auditAdditionalCert.businessLicenseExpirationTime =
      values.auditAdditionalCert.businessLicenseExpirationTime.expirationTime ? dayjs(
        values.auditAdditionalCert.businessLicenseExpirationTime.expirationTime
      ).valueOf() :
      null;
    values.auditAdditionalCert.registeredCapital = values.auditAdditionalCert.registeredCapital * 10000;
    values.additionalCert = {};
    values.additionalCert.supplyCategory = this.state.supplyCategory;
    values.additionalCert.purchaserCategory = this.state.purchaserCategory;
    for (let key in values.auditAdditionalCert) {
      if (!values.auditAdditionalCert[key]) {
        delete values.auditAdditionalCert[key];
      }
      if (
        values.auditAdditionalCert[key] &&
        values.auditAdditionalCert[key].fileKey === null &&
        values.auditAdditionalCert[key].url === null
      ) {
        delete values.auditAdditionalCert[key];
      }
    }
    if (JSON.stringify(values.auditAdditionalCert) === '{}') {
      values.auditAdditionalCert = null;
    }
    reaction.qualification.toComplementQualification(values).then((res) => {
      if (res.code === 0) {
        message.success('操作成功!');
        historyPush(`/${RoutePath.MEMBERCENTER}/${RoutePath.CHECK_QUALIFICATION_LIST}`);
      }
    });
    // });
  };

  pass = (e) => {
    e && e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (err) {
        return ;
      }
      values.userId = resolveUrlQuery(this.props.location.search).userId;
      // values.auditAdditionalCert.businessLicenseExpirationTime = values.auditAdditionalCert.businessLicenseExpirationTime ? dayjs(values.auditAdditionalCert.businessLicenseExpirationTime).valueOf() : null;
      values.auditAdditionalCert.legalPersonIdentityCardFrontUrl = values.auditAdditionalCert
        .legalPersonIdentityCardFrontUrl &&
      values.auditAdditionalCert.legalPersonIdentityCardFrontUrl.fileList &&
      values.auditAdditionalCert.legalPersonIdentityCardFrontUrl.fileList[0] &&
      values.auditAdditionalCert.legalPersonIdentityCardFrontUrl.fileList[0].fileKey && {
        fileKey: values.auditAdditionalCert.legalPersonIdentityCardFrontUrl.fileList[0].fileKey
      };
      values.auditAdditionalCert.legalPersonIdentityCardBackUrl = values.auditAdditionalCert
        .legalPersonIdentityCardBackUrl &&
      values.auditAdditionalCert.legalPersonIdentityCardBackUrl.fileList &&
      values.auditAdditionalCert.legalPersonIdentityCardBackUrl.fileList[0] &&
      values.auditAdditionalCert.legalPersonIdentityCardBackUrl.fileList[0].fileKey && {
        fileKey: values.auditAdditionalCert.legalPersonIdentityCardBackUrl.fileList[0].fileKey
      };
      values.auditAdditionalCert.agentIdentityCardFrontUrl = values.auditAdditionalCert.agentIdentityCardFrontUrl &&
      values.auditAdditionalCert.agentIdentityCardFrontUrl.fileList &&
      values.auditAdditionalCert.agentIdentityCardFrontUrl.fileList[0] &&
      values.auditAdditionalCert.agentIdentityCardFrontUrl.fileList[0].fileKey && {
        fileKey: values.auditAdditionalCert.agentIdentityCardFrontUrl.fileList[0].fileKey
      };
      values.auditAdditionalCert.agentIdentityCardBackUrl = values.auditAdditionalCert.agentIdentityCardBackUrl &&
      values.auditAdditionalCert.agentIdentityCardBackUrl.fileList &&
      values.auditAdditionalCert.agentIdentityCardBackUrl.fileList[0] &&
      values.auditAdditionalCert.agentIdentityCardBackUrl.fileList[0].fileKey && {
        fileKey: values.auditAdditionalCert.agentIdentityCardBackUrl.fileList[0].fileKey
      };
      values.auditAdditionalCert.businessLicenseExpirationTime =
        values.auditAdditionalCert.businessLicenseExpirationTime.expirationTime ? dayjs(
          values.auditAdditionalCert.businessLicenseExpirationTime.expirationTime
        ).valueOf() :
        null;
      values.auditAdditionalCert.registeredCapital = values.auditAdditionalCert.registeredCapital * 10000;
      values.additionalCert = {
        supplyCategory: this.state.supplyCategory,
        purchaserCategory: this.state.purchaserCategory
      };
      for (let key in values.auditAdditionalCert) {
        if (!values.auditAdditionalCert[key]) {
          delete values.auditAdditionalCert[key];
        }
        if (
          values.auditAdditionalCert[key] &&
          values.auditAdditionalCert[key].fileKey === null &&
          values.auditAdditionalCert[key].url === null
        ) {
          delete values.auditAdditionalCert[key];
        }
      }
      reaction.qualification.makeQualificationOk(values).then((res) => {
        if (res.code === 0) {
          message.success('审核成功');
          historyPush(`/${RoutePath.MEMBERCENTER}/${RoutePath.CHECK_QUALIFICATION_LIST}`);
        }
      });
    });
  };
  showRefuseModal = () => {
    this.setState({
      showRefuseModal: true
    });
  };
  refuse = (e) => {
    e && e.preventDefault();
    if (!this.state.reason) {
      message.warning('请输入审核意见再提交!');
      return;
    }
    let userId = resolveUrlQuery(this.props.location.search).userId;
    reaction.qualification
      .refuseQualification({
        userId: userId,
        reason: this.state.reason
      })
      .then((res) => {
        if (res.code === 0) {
          message.success('审核拒绝成功');
          historyPush(`/${RoutePath.MEMBERCENTER}/${RoutePath.CHECK_QUALIFICATION_LIST}`);
        }
      });
  };

  cancelRefuse = () => {
    this.setState({
      showRefuseModal: false,
      reason: ''
    });
  };

  beforeUpload = (onlyOne, file, fileList) => {
    if (file.size > 10240000) {
      message.error('文件大小不能超过10MB!');
      fileList.splice(fileList.indexOf(file), 1);
      return false;
    }
    if (!(/^image\//.test(file.type))) {
      message.error('只能上传图片文件!');
      fileList.splice(fileList.indexOf(file), 1);
      return false;
    }
    return true;
  };

  getFileData = (e) => {
    return (
      e && {
        fileKey: e.fileList[0].response && e.fileList[0].response.data.url
      }
    );
  };

  onUploadChange = (onlyOne, data, key) => {
    if (data.file.response && data.file.response.code === 0) {
      if (onlyOne && data.fileList.length > 1) {
        data.fileList.shift();
      }
    } else if (data.file.response && data.file.response.code !== 0) {
      data.fileList.splice(data.fileList.indexOf(data.file), 1);
      message.error(data.file.response.msg || '上传失败!');
    }
    data.fileList = data.fileList.map((item) => {
      if (item.response) {
        item.fileKey = item.response.data.url;
      }
      return item;
    });
    this.setState({
      [key]: data.fileList
    });
  };

  onPreview = (file) => {
    let url = getFilePreviewUrl(file);
    this.setState(
      {
        showBigImg: true,
        bigImgUrl: url
      },
      () => {
        getPreviewImageWidth(url)
          .then(imgWidth => {
            this.setState({
              imgWidth
            });
          })
      }
    );
  };

  onRemove = (key, { file, fileList }) => {};

  onExpirationTimeChange = (date, dateStr) => {
    this.setState({
      expirationTime: date
    });
  };

  getCompanyCharacter = (v) => {
    reaction.memberCenter.getCompanyCharacter(v);
  };

  getBusinessCharacter = () => {
    reaction.memberCenter.getBusinessCharacter();
  };

  handleTreeTransferChange = ({ targetKeys }) => {
    console.log('get keys:', targetKeys);
    this.setState({
      supplyCategory: targetKeys
    });
  };
  fixTree = (tree) => {
    tree.forEach((item) => {
      item.key = (item.key || item.id || item.code || item.value) + '';
      item.title = item.title || item.name;
      if (item.children) {
        this.fixTree(item.children);
      }
    });
  };
  getTreeData = () => {
    return reaction.common.getSupplyCategory().then((res) => {
      this.fixTree(res.data);
      this.setState({
        treeData: res.data
      });
      return res;
    });
  };

  handleDepartmentChange = (_, { props }) => {
    this.fetchDepartmentTeamList(props.id);
    this.setFieldsValue('team', '');
  }

  fetchDepartmentTeamList(id) {
    return reaction.frontUser.getDepartmentTeamList({
      parentId: id,
    });
  }

  hasSelectDepartment() {
    return !!this.props.form.getFieldValue('department');
  }

  setFieldsValue(name, value) {
    this.props.form.setFieldsValue({
      [name]: value,
    })
  }

  async componentDidMount () {
    await this.getTreeData();
    await this.getAreaDict();
    await this.getBusinessCharacter();
    await this.getModifyHistoryList();
    const certInfo = await this.getQualificationInfo() || {};
    certInfo.departmentId && this.fetchDepartmentTeamList(certInfo.departmentId);
  }
  getColumns() {
    return [
      {
        width: '112px',
        title: '时间',
        dataIndex: 'updateTime',
        render: (text) => {
          if (!text) {
            return '';
          }
          return dayjs(text - 0).format('YYYY-MM-DD HH:mm:ss');
        }
      },
      {
        width: 140,
        title: '操作人',
        dataIndex: 'operator'
      },
      {
        title: '内容',
        dataIndex: 'updateContent',
        render: (text) => {
          return <pre style={{ maxWidth: '1000px' }}>{text}</pre>;
        }
      }
    ];
  }

  render () {
    const { getFieldDecorator } = this.props.form;
    let { invitednterprises } = this.props.common;

    const { departments = [], manufactorTypeList } = this.props.me;
    const { teamList = [], teamListLoading } = this.props.frontUser;

    let { province, city, district } = this.state;

    let { companyCharacter, businessCharacter, qualificationDetailInfo } = this.props.memberCenter;
    let { treeData } = this.state;
    let formItemLayout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 20 }
    };
    let {
      legalPersonIdentityCardFrontUrl,
      legalPersonIdentityCardBackUrl,
      agentIdentityCardFrontUrl,
      agentIdentityCardBackUrl
    } = this.state;
    const uploadButton = (
      <div>
        <Icon
          type={

              this.state.loading ? 'loading' :
              'plus'
          }
        />
        <div className='ant-upload-text'>点击上传</div>
      </div>
    );

    let modifyHistoryList = this.props.memberCenter.operationHistoryList;
    const hasCenpurCert = validHasCenpurCert(qualificationDetailInfo.identity || []);
    
    return (
      <div>
        <h2 className={commonStyles.row}>企业资质信息审核</h2>
        <div style={{ overflow: 'hidden' }}>
          <div style={{ float: 'left', width: 100 }}>账户信息</div>
          <div style={{ marginLeft: 100 }}>
            <Form>
              <FormItem {...formItemLayout} label="会员编号">
              <Input disabled={true} value={qualificationDetailInfo.user.userNo} style={{ width: 250 }} />
              </FormItem>
              <FormItem {...formItemLayout} label="用户名">
                <Input disabled={true} value={qualificationDetailInfo.user.username} style={{ width: 250 }} />
              </FormItem>
              <FormItem {...formItemLayout} label="安全手机">
                <Input disabled={true} value={qualificationDetailInfo.user.phone} style={{ width: 250 }} />
              </FormItem>
              <FormItem {...formItemLayout} label='责任部门'>
                {getFieldDecorator('department', {
                  rules: [ { required: false, message: '请选择责任部门' } ],
                  initialValue: qualificationDetailInfo.department || ''
                })(
                  <Select style={{ width: 250 }} onChange={this.handleDepartmentChange}>
                    {departments &&
                      departments.map((item) => {
                        return (
                          <Option key={item.code} value={item.code || ''} id={item.id}>
                            {item.name}
                          </Option>
                        );
                      })}
                  </Select>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label='小组'>
                {getFieldDecorator('team', {
                  validateTrigger: [ 'onBlur', 'onChange' ],
                  initialValue: qualificationDetailInfo.team || ''
                })(
                  <Select style={{ width: 250 }} loading={teamListLoading} disabled={!this.hasSelectDepartment()}>
                    {teamList.map((item) => {
                      return (
                        <Option key={item.code} value={item.code || ''}>
                          {item.name}
                        </Option>
                      );
                    })}
                  </Select>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label='业务员'>
                {getFieldDecorator('salesman', {
                  validateTrigger: [ 'onBlur', 'onChange' ],
                  rules: [
                    {
                      required: false,
                      whitespace: true,
                      message: '请输入业务员'
                    }
                  ],
                  initialValue: qualificationDetailInfo.salesman
                })(<Input maxLength={5} style={{ width: 250 }} placeholder='请输入业务员' />)}
              </FormItem>
            </Form>
          </div>
        </div>
        <div style={{ overflow: 'hidden' }}>
          <div style={{ float: 'left', width: 100 }}>基础信息</div>
          <div style={{ marginLeft: 100 }}>
            <div style={{ marginBottom: 30 }}>
              <label
                style={{ width: '16.6666666%', textAlign: 'right', display: 'inline-block', padding: '0 10px 0 0' }}
              >
                公司名称:
              </label>
              <Input
                disabled={true}
                value={qualificationDetailInfo.cert.baseInfo.companyNameZh}
                style={{ width: 250 }}
              />
            </div>
            <div style={{ marginBottom: 30 }}>
              <label
                style={{ width: '16.6666666%', textAlign: 'right', display: 'inline-block', padding: '0 10px 0 0' }}
              >
                公司英文名:
              </label>
              <Input
                disabled={true}
                value={qualificationDetailInfo.cert.baseInfo.companyNameEn}
                style={{ width: 250 }}
              />
            </div>
            <div style={{ marginBottom: 30 }}>
              <label
                style={{ width: '16.6666666%', textAlign: 'right', display: 'inline-block', padding: '0 10px 0 0' }}
              >
                公司简称:
              </label>
              <Input
                disabled={true}
                value={qualificationDetailInfo.cert.baseInfo.companyAbbreviation}
                style={{ width: 250 }}
              />
            </div>
            <div style={{ marginBottom: 30 }}>
              <label
                style={{ width: '16.6666666%', textAlign: 'right', display: 'inline-block', padding: '0 10px 0 0' }}
              >
                公司曾用名:
              </label>
              <Input
                disabled={true}
                value={qualificationDetailInfo.cert.baseInfo.usedCompanyName}
                style={{ width: 250 }}
              />
            </div>
            <div
              style={{
                textAlign: 'right',
                width: '16.6666%',
                marginBottom: 30,
                display: 'inline-block',
                padding: '0 10px 0 0'
              }}
            >
              <label>所在地:</label>
            </div>
            <div style={{ marginBottom: 30, display: 'inline-block' }}>
              <Select style={{ width: 100 }} disabled={true} value={qualificationDetailInfo.cert.baseInfo.provinceCode}>
                {province.map((item) => {
                  return (
                    <Option key={item.id} value={item.id}>
                      {item.name}
                    </Option>
                  );
                })}
              </Select>
            </div>
            <div style={{ marginBottom: 30, display: 'inline-block' }}>
              <Select style={{ width: 100 }} disabled={true} value={qualificationDetailInfo.cert.baseInfo.cityCode}>
                {city.map((item) => {
                  return (
                    <Option key={item.id} value={item.id}>
                      {item.name}
                    </Option>
                  );
                })}
              </Select>
            </div>
            <div style={{ marginBottom: 30, display: 'inline-block' }}>
              <Select style={{ width: 100 }} disabled={true} value={qualificationDetailInfo.cert.baseInfo.districtCode}>
                {district.map((item) => {
                  return (
                    <Option key={item.id} value={item.id}>
                      {item.name}
                    </Option>
                  );
                })}
              </Select>
            </div>
            <div style={{ marginBottom: 30, display: 'inline-block' }}>
              <Input
                disabled={true}
                style={{ width: 250 }}
                value={qualificationDetailInfo.cert.baseInfo.address}
                placeholder='街道地址'
              />
            </div>
            <div style={{ marginBottom: 30 }}>
              <label
                style={{ width: '16.6666666%', textAlign: 'right', display: 'inline-block', padding: '0 10px 0 0' }}
              >
                公司注册时间:
              </label>
              <Input
                disabled={true}
                value={

                    qualificationDetailInfo.cert.baseInfo.registerTime ? dayjs(
                      qualificationDetailInfo.cert.baseInfo.registerTime
                    ).format('YYYY-MM-DD HH:mm:ss') :
                    ''
                }
                style={{ width: 250 }}
              />
            </div>
            <div style={{ marginBottom: 30 }}>
              <label
                style={{ width: '16.6666666%', textAlign: 'right', display: 'inline-block', padding: '0 10px 0 0' }}
              >
                企业网站:
              </label>
              <Input disabled={true} value={qualificationDetailInfo.cert.baseInfo.website} style={{ width: 250 }} />
            </div>
            <div style={{ marginBottom: 30 }}>
              <label
                style={{ width: '16.6666666%', textAlign: 'right', display: 'inline-block', padding: '0 10px 0 0' }}
              >
                受邀钢厂:
              </label>
              {/* 因为这里本来就是不可选择的，所以 */}
              <Select
                style={{ width: 250 }}
                disabled={true}
                value={formatInviteSteelValue(qualificationDetailInfo.cert.baseInfo.invitedSteel) || undefined}
                placeholder="自己感兴趣，无受邀钢厂"
              >
                {invitednterprises.map((item) => {
                  return (
                    <Option key={item.code} value={item.code}>
                      {item.name}
                    </Option>
                  );
                })}
              </Select>
            </div>
            <div style={{ marginBottom: 30 }}>
              <label
                style={{ width: '16.6666666%', textAlign: 'right', display: 'inline-block', padding: '0 10px 0 0' }}
              >
                认证目的:
              </label>
              <Input
                maxLength={50}
                disabled={true}
                value={qualificationDetailInfo.cert.baseInfo.authPurpose}
                style={{ width: 250 }}
              />
            </div>
          </div>
        </div>
        <div style={{ overflow: 'hidden' }}>
          <div style={{ float: 'left', width: 100 }}>联系方式</div>
          <div style={{ marginLeft: 100 }}>
            <div style={{ marginBottom: 30 }}>
              <label
                style={{ width: '16.6666666%', textAlign: 'right', display: 'inline-block', padding: '0 10px 0 0' }}
              >
                联系人:
              </label>
              <Input
                disabled={true}
                value={qualificationDetailInfo.cert.contactInfo.contactPerson}
                style={{ width: 250 }}
              />
            </div>
            <div style={{ marginBottom: 30 }}>
              <label
                style={{ width: '16.6666666%', textAlign: 'right', display: 'inline-block', padding: '0 10px 0 0' }}
              >
                联系电话:
              </label>
              <Input disabled={true} value={qualificationDetailInfo.cert.contactInfo.phone} style={{ width: 250 }} />
            </div>
            <div style={{ marginBottom: 30 }}>
              <label
                style={{ width: '16.6666666%', textAlign: 'right', display: 'inline-block', padding: '0 10px 0 0' }}
              >
                企业电话:
              </label>
              <Input
                disabled={true}
                value={qualificationDetailInfo.cert.baseInfo.enterprisePhone}
                style={{ width: 250 }}
              />
            </div>
            <div style={{ marginBottom: 30 }}>
              <label
                style={{ width: '16.6666666%', textAlign: 'right', display: 'inline-block', padding: '0 10px 0 0' }}
              >
                企业传真:
              </label>
              <Input
                disabled={true}
                value={qualificationDetailInfo.cert.baseInfo.corporateFax}
                style={{ width: 250 }}
              />
            </div>
            <div style={{ marginBottom: 30 }}>
              <label
                style={{ width: '16.6666666%', textAlign: 'right', display: 'inline-block', padding: '0 10px 0 0' }}
              >
                邮编:
              </label>
              <Input disabled={true} value={qualificationDetailInfo.cert.baseInfo.zipCode} style={{ width: 250 }} />
            </div>
            <div style={{ marginBottom: 30 }}>
              <label
                style={{ width: '16.6666666%', textAlign: 'right', display: 'inline-block', padding: '0 10px 0 0' }}
              >
                QQ:
              </label>
              <Input disabled={true} value={qualificationDetailInfo.cert.contactInfo.qq} style={{ width: 250 }} />
            </div>
          </div>
        </div>
        <div style={{ overflow: 'hidden' }}>
          <div style={{ float: 'left', width: 100 }}>证件附件</div>
          <div style={{ marginLeft: 155 }}>
            <div style={{ width: '250px', textAlign: 'right', margin: '0 0 30px 30px', display: 'inline-block' }}>
              <label>营业执照:</label>
              {
                qualificationDetailInfo.cert.attachment &&
                qualificationDetailInfo.cert.attachment.businessLicenceUrl &&
                qualificationDetailInfo.cert.attachment.businessLicenceUrl.url ? <MagnifyEnableImg
                  imgUrl={qualificationDetailInfo.cert.attachment.businessLicenceUrl.url}
                /> :
                <img style={{ width: 80, height: 80 }} src={unuploadImg} alt='' />}
            </div>
            <div style={{ width: '250px', textAlign: 'right', margin: '0 0 30px 30px', display: 'inline-block' }}>
              <label>组织机构代码:</label>
              {
                qualificationDetailInfo.cert.attachment &&
                qualificationDetailInfo.cert.attachment.organizationCodeUrl &&
                qualificationDetailInfo.cert.attachment.organizationCodeUrl.url ? <MagnifyEnableImg
                  imgUrl={qualificationDetailInfo.cert.attachment.organizationCodeUrl.url}
                /> :
                <img style={{ width: 80, height: 80 }} src={unuploadImg} alt='' />}
            </div>
            <div style={{ width: '250px', textAlign: 'right', margin: '0 0 30px 30px', display: 'inline-block' }}>
              <label>税务登记证:</label>
              {
                qualificationDetailInfo.cert.attachment &&
                qualificationDetailInfo.cert.attachment.taxRegistryUrl &&
                qualificationDetailInfo.cert.attachment.taxRegistryUrl.url ? <MagnifyEnableImg
                  imgUrl={qualificationDetailInfo.cert.attachment.taxRegistryUrl.url}
                /> :
                <img style={{ width: 80, height: 80 }} src={unuploadImg} alt='' />}
            </div>
            {
              hasCenpurCert ? (
                <div style={{ width: '250px', textAlign: 'right', margin: '0 0 30px 30px', display: 'inline-block' }}>
                  <label>开户许可证:</label>
                  {
                    qualificationDetailInfo.cert.attachment &&
                    qualificationDetailInfo.cert.attachment.accountLicenseUrl &&
                    qualificationDetailInfo.cert.attachment.accountLicenseUrl.url ? <MagnifyEnableImg
                      imgUrl={qualificationDetailInfo.cert.attachment.accountLicenseUrl.url}
                    /> :
                    <img style={{ width: 80, height: 80 }} src={unuploadImg} alt='' />}
                </div>
              ) : null
            }
            <div style={{ marginBottom: 30 }}>
              <label
                style={{ width: '16.6666666%', textAlign: 'right', display: 'inline-block', padding: '0 10px 0 0' }}
              >
                委托书:
              </label>
              <RadioGroup value={qualificationDetailInfo.cert.proxyType} disabled={true}>
                <Radio value={'1'}>企业授权委托书</Radio>
                <Radio value={'2'}>业务担保授权书</Radio>
                <Radio value={'3'}>无委托书，自愿不进行交易</Radio>
              </RadioGroup>
            </div>
            {qualificationDetailInfo.cert.proxyType !== '3' && (
              <div style={{ marginBottom: 30 }}>
                <label
                  style={{ width: '16.6666666%', textAlign: 'right', display: 'inline-block', padding: '0 10px 0 0' }}
                >
                  委托书附件:
                </label>
                {
                  qualificationDetailInfo.cert &&
                  qualificationDetailInfo.cert.proxy ? qualificationDetailInfo.cert.proxy.map((item) => {
                    return item.url ? (
                      <MagnifyEnableImg style={{ marginLeft: 15 }} imgUrl={item.url} />
                    ) : '';
                  }) :
                  <img style={{ width: 80, height: 80 }} src={unuploadImg} alt='' />}
              </div>
            )}
          </div>
        </div>
        {hasCenpurCert && checkIsSupplier(qualificationDetailInfo.identity) && (
          <div style={{ overflow: 'hidden' }}>
            <div style={{ float: 'left', width: 100 }}>供应品类</div>
            <div style={{ marginLeft: 260 }}>
              <Tree
                treeData={treeData}
                checkedKeys={(qualificationDetailInfo.additionalCert && qualificationDetailInfo.additionalCert.supplyCategory) ||
                []}
                checkable
                disabled
                height={380}
              />
            </div>
          </div>
        )}
        {
          hasCenpurCert && checkIsPurchase(qualificationDetailInfo.identity) && (
            <div style={{ marginLeft: 100, padding: '15px 0' }}>
            <FormItem {...formItemLayout} label="厂家类型：">
                <Select value={qualificationDetailInfo?.additionalCert?.manufactorType} disabled>
                  {
                    manufactorTypeList.map(el => {
                      return (
                        <Option key={el.code} value={el.code}>{el.name}</Option>
                      );
                    })
                  }
                </Select>
              </FormItem>
            </div>
          )
        }
        {hasCenpurCert && checkIsPurchase(qualificationDetailInfo.identity) && (
          <div style={{ overflow: 'hidden' }}>
            <div style={{ float: 'left', width: 100 }}>采购品类</div>
            <div style={{ marginLeft: 160 }}>
              <Tree
                disabled
                checkedKeys={
                  (qualificationDetailInfo.additionalCert &&
                    qualificationDetailInfo.additionalCert.purchaserCategory) ||
                  []
                }
                treeData={treeData}
                checkable
                height={380}
              />
            </div>
          </div>
        )}

        <div style={{ overflow: 'hidden' }}>
          <div style={{ float: 'left', width: 100 }}>其他信息</div>
          <div style={{ marginLeft: 100 }}>
            <Form>
              <FormItem {...formItemLayout} label="外贸资质">
                <RadioGroup
                  value={
                    qualificationDetailInfo.additionalCert && qualificationDetailInfo.additionalCert.hasForeignTradeCert
                  }
                  disabled={true}
                >
                  <Radio value={true}>具备</Radio>
                  <Radio value={false}>不具备</Radio>
                </RadioGroup>
              </FormItem>
              {
              (qualificationDetailInfo.additionalCert?.hasForeignTradeCert) ? (
                <React.Fragment>
                  <FormItem {...formItemLayout} label="对外贸易经营者备案登记表附件">
                    {
                      qualificationDetailInfo.additionalCert?.foreignTradeRegistrationUrl?.url ? (
                        <MagnifyEnableImg
                          style={{ marginLeft: 15 }}
                          imgUrl={qualificationDetailInfo.additionalCert?.foreignTradeRegistrationUrl?.url}
                        />
                      ) : (
                        <img style={{ width: 80, height: 80 }} src={unuploadImg} alt='' />
                      )
                    }
                  </FormItem>
                  <FormItem {...formItemLayout} label="进出口企业代码号">
                    {qualificationDetailInfo.additionalCert?.foreignTradeEnterpriseCode}
                  </FormItem>
                </React.Fragment>
              ) : null
            }
            </Form>
          </div>
        </div>
        <div style={{ overflow: 'hidden' }}>
          <div style={{ float: 'left', width: 100 }}>审核补录信息</div>
          <div style={{ marginLeft: 100 }}>
            <Form>
              <FormItem {...formItemLayout} label='经营性质：'>
                {getFieldDecorator('auditAdditionalCert.proprietaryDealer', {
                  rules: [
                    {
                      required: true,
                      whitespace: true,
                      message: '请选择经营性质'
                    }
                  ],
                  initialValue:
                    (qualificationDetailInfo.auditAdditionalCert &&
                      qualificationDetailInfo.auditAdditionalCert.proprietaryDealer) ||
                    'not_proprietary_dealer'
                })(
                  <Select style={{ width: 250 }}>
                    {businessCharacter.map((item) => {
                      return <Option value={item.code}>{item.name}</Option>;
                    })}
                  </Select>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label='公司性质：'>
                {getFieldDecorator('auditAdditionalCert.companyCharacter', {
                  validateTrigger: [ 'onBlur', 'onChange' ],
                  rules: [
                    {
                      required: true,
                      whitespace: true,
                      message: '请输入公司性质'
                    }
                  ],
                  initialValue: qualificationDetailInfo.auditAdditionalCert.companyCharacter
                })(
                  <AutoComplete
                    onChange={(v) => {
                      this.getCompanyCharacter(v);
                    }}
                    style={{ width: 250 }}
                    placeholder='请输入公司性质'
                  >
                    {companyCharacter.map((item) => {
                      return (
                        <AOption key={item.code} value={item.name}>
                          {item.name}
                        </AOption>
                      );
                    })}
                  </AutoComplete>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label='注册资本(万元)：'>
                {getFieldDecorator('auditAdditionalCert.registeredCapital', {
                  validateTrigger: [ 'onBlur', 'onChange' ],
                  rules: [
                    {
                      required: true,
                      whitespace: true,
                      pattern: /^\d+(\.(?:\d+))?$/,
                      message: '请输入注册资本'
                    }
                  ],
                  initialValue:
                    qualificationDetailInfo.auditAdditionalCert &&
                    qualificationDetailInfo.auditAdditionalCert.registeredCapital &&
                    qualificationDetailInfo.auditAdditionalCert.registeredCapital / 10000
                })(<Input style={{ width: 250 }} placeholder='请输入注册资本' />)}
              </FormItem>
              <FormItem {...formItemLayout} label='公司经营范围：'>
                {getFieldDecorator('auditAdditionalCert.companyBusinessScope', {
                  validateTrigger: [ 'onBlur', 'onChange' ],
                  rules: [
                    {
                      required: true,
                      whitespace: true,
                      message: '请输入公司经营范围'
                    }
                  ],
                  initialValue: qualificationDetailInfo.auditAdditionalCert.companyBusinessScope
                })(<TextArea rows={4} maxLength={500} style={{ width: 250 }} placeholder='请输入公司经营范围' />)}
              </FormItem>
              <FormItem {...formItemLayout} label='法人姓名：'>
                {getFieldDecorator('auditAdditionalCert.legalPersonName', {
                  validateTrigger: [ 'onBlur', 'onChange' ],
                  rules: [
                    {
                      required: true,
                      whitespace: true,
                      message: '请输入法人姓名'
                    }
                  ],
                  initialValue: qualificationDetailInfo.auditAdditionalCert.legalPersonName
                })(<Input style={{ width: 250 }} placeholder='请输入法人姓名' />)}
              </FormItem>
              <FormItem {...formItemLayout} label='法人身份证附件正面：'>
                {getFieldDecorator('auditAdditionalCert.legalPersonIdentityCardFrontUrl', {
                  rules: [
                    {
                      required: false,
                      message: '请上传法人身份证附件正面'
                    }
                  ],
                  initialValue: {
                    fileList: legalPersonIdentityCardFrontUrl
                  }
                })(
                  <Upload
                    name='file'
                    accept='image/jpg,image/jpeg,image/png'
                    fileList={legalPersonIdentityCardFrontUrl}
                    action={env.getUploadApi('/v1/cert/upload')}
                    beforeUpload={(file, fileList) => {
                      return this.beforeUpload(true, file, fileList);
                    }}
                    onChange={(file) => {
                      this.onUploadChange(true, file, 'legalPersonIdentityCardFrontUrl');
                    }}
                    onRemove={(file, fileList) => {
                      this.onRemove('legalPersonIdentityCardFrontUrl', file, fileList);
                    }}
                    onPreview={(file) => {
                      this.onPreview(file);
                    }}
                    listType='picture-card'
                  >
                    {uploadButton}
                  </Upload>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label='法人身份证附件背面：'>
                {getFieldDecorator('auditAdditionalCert.legalPersonIdentityCardBackUrl', {
                  rules: [
                    {
                      required: false,
                      message: '请上传法人身份证附件背面'
                    }
                  ],
                  initialValue: {
                    fileList: legalPersonIdentityCardBackUrl
                  }
                })(
                  <Upload
                    name='file'
                    accept='image/jpg,image/jpeg,image/png'
                    fileList={legalPersonIdentityCardBackUrl}
                    action={env.getUploadApi('/v1/cert/upload')}
                    beforeUpload={(file, fileList) => {
                      return this.beforeUpload(true, file, fileList);
                    }}
                    onChange={(file) => {
                      this.onUploadChange(true, file, 'legalPersonIdentityCardBackUrl');
                    }}
                    onRemove={(file, fileList) => {
                      this.onRemove('legalPersonIdentityCardBackUrl', file, fileList);
                    }}
                    onPreview={(file) => {
                      this.onPreview(file);
                    }}
                    listType='picture-card'
                  >
                    {uploadButton}
                  </Upload>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label='营业执照期限：'>
                {getFieldDecorator('auditAdditionalCert.businessLicenseExpirationTime', {
                  rules: [
                    {
                      required: true,
                      message: '请选择营业执照期限',
                      validator: (rule, value, callback) => {
                        if (!value.expirationTime) {
                          callback('请选择营业执照期限!');
                        } else {
                          callback();
                        }
                      }
                    }
                  ],
                  initialValue: {
                    expirationTime:
                      qualificationDetailInfo.auditAdditionalCert.businessLicenseExpirationTime ? dayjs(
                        qualificationDetailInfo.auditAdditionalCert.businessLicenseExpirationTime
                      ) :
                      ''
                  }
                })(<DatePickAndCheckboxFormItem />)}
              </FormItem>
              <FormItem {...formItemLayout} label='社会统一信用代码：'>
                {getFieldDecorator('auditAdditionalCert.unifiedSocialCreditCode', {
                  validateTrigger: [ 'onBlur', 'onChange' ],
                  rules: [
                    {
                      required: true,
                      whitespace: true,
                      message: '请输入社会统一信用代码'
                    }
                  ],
                  initialValue: qualificationDetailInfo.auditAdditionalCert.unifiedSocialCreditCode
                })(<Input style={{ width: 250 }} placeholder='请输入社会统一信用代码' />)}
              </FormItem>
              <FormItem {...formItemLayout} label='委托人姓名：'>
                {getFieldDecorator('auditAdditionalCert.agentName', {
                  validateTrigger: [ 'onBlur', 'onChange' ],
                  rules: [
                    {
                      required: true,
                      whitespace: true,
                      message: '请输入委托人姓名'
                    }
                  ],
                  initialValue: qualificationDetailInfo.auditAdditionalCert.agentName
                })(<Input style={{ width: 250 }} placeholder='请输入委托人姓名' />)}
              </FormItem>
              <FormItem {...formItemLayout} label='开户许可证核准号：'>
                {getFieldDecorator('auditAdditionalCert.accountLicenseNo', {
                  rules: [
                    {
                      required: hasCenpurCert,
                      message: '请输入开户许可证核准号'
                    }
                  ],
                  initialValue: qualificationDetailInfo.auditAdditionalCert.accountLicenseNo || null
                })(<Input style={{ width: 250 }} placeholder='请输入开户许可证核准号' />)}
              </FormItem>
              <FormItem {...formItemLayout} label='开户行：'>
                {getFieldDecorator('auditAdditionalCert.openBank', {
                  rules: [
                    {
                      required: hasCenpurCert,
                      message: '请输入开户行'
                    }
                  ],
                  initialValue:
                    qualificationDetailInfo.auditAdditionalCert && qualificationDetailInfo.auditAdditionalCert.openBank
                })(<Input style={{ width: 250 }} placeholder='请输入开户行' />)}
              </FormItem>
              <FormItem {...formItemLayout} label='户名：'>
                {getFieldDecorator('auditAdditionalCert.accountName', {
                  rules: [
                    {
                      required: hasCenpurCert,
                      message: '请输入户名'
                    }
                  ],
                  initialValue:
                    qualificationDetailInfo.auditAdditionalCert &&
                    qualificationDetailInfo.auditAdditionalCert.accountName
                })(<Input style={{ width: 250 }} placeholder='请输入户名' />)}
              </FormItem>
              <FormItem {...formItemLayout} label='账号：'>
                {getFieldDecorator('auditAdditionalCert.accountNo', {
                  rules: [
                    {
                      required: hasCenpurCert,
                      pattern: /^\d{1,50}$/,
                      message: '请输入正确格式的账号'
                    }
                  ],
                  initialValue:
                    qualificationDetailInfo.auditAdditionalCert && qualificationDetailInfo.auditAdditionalCert.accountNo
                })(<Input style={{ width: 250 }} placeholder='请输入账号' />)}
              </FormItem>

              <FormItem {...formItemLayout} label='保证金：'>
                {getFieldDecorator('auditAdditionalCert.bond', {
                  rules: [
                    {
                      required: false,
                      pattern: /^\d+\.?(?:\d+)$/,
                      message: '请输入保证金'
                    }
                  ],
                  initialValue:
                    qualificationDetailInfo.auditAdditionalCert && qualificationDetailInfo.auditAdditionalCert.bond
                })(<Input style={{ width: 250 }} placeholder='请输入保证金' />)}
              </FormItem>
              <FormItem {...formItemLayout} label='委托人电话：'>
                {getFieldDecorator('auditAdditionalCert.agentPhone', {
                  rules: [
                    {
                      required: false,
                      pattern: /^1[\d]{10}$/,
                      message: '请输入委托人电话'
                    }
                  ],
                  initialValue: qualificationDetailInfo.auditAdditionalCert.agentPhone
                })(<Input style={{ width: 250 }} placeholder='请输入委托人电话' />)}
              </FormItem>
              <FormItem {...formItemLayout} label='委托人身份证附件正面：'>
                {getFieldDecorator('auditAdditionalCert.agentIdentityCardFrontUrl', {
                  rules: [
                    {
                      required: false,
                      message: '请上传委托人身份证附件正面'
                    }
                  ],
                  initialValue: {
                    fileList: agentIdentityCardFrontUrl
                  }
                })(
                  <Upload
                    name='file'
                    accept='image/jpg,image/jpeg,image/png'
                    fileList={agentIdentityCardFrontUrl}
                    action={env.getUploadApi('/v1/cert/upload')}
                    beforeUpload={(file, fileList) => {
                      return this.beforeUpload(true, file, fileList);
                    }}
                    onChange={(file) => {
                      this.onUploadChange(true, file, 'agentIdentityCardFrontUrl');
                    }}
                    onRemove={(file, fileList) => {
                      this.onRemove('agentIdentityCardFrontUrl', file, fileList);
                    }}
                    onPreview={(file) => {
                      this.onPreview(file);
                    }}
                    listType='picture-card'
                  >
                    {uploadButton}
                  </Upload>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label='委托人身份证附件背面：'>
                {getFieldDecorator('auditAdditionalCert.agentIdentityCardBackUrl', {
                  rules: [
                    {
                      required: false,
                      message: '请上传委托人身份证附件背面'
                    }
                  ],
                  initialValue: {
                    fileList: agentIdentityCardBackUrl
                  }
                })(
                  <Upload
                    name='file'
                    accept='image/jpg,image/jpeg,image/png'
                    fileList={agentIdentityCardBackUrl}
                    action={env.getUploadApi('/v1/cert/upload')}
                    beforeUpload={(file, fileList) => {
                      return this.beforeUpload(true, file, fileList);
                    }}
                    onChange={(file) => {
                      this.onUploadChange(true, file, 'agentIdentityCardBackUrl');
                    }}
                    onRemove={(file, fileList) => {
                      this.onRemove('agentIdentityCardBackUrl', file, fileList);
                    }}
                    onPreview={(file) => {
                      this.onPreview(file);
                    }}
                    listType='picture-card'
                  >
                    {uploadButton}
                  </Upload>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label='是否开通资金账户：'>
                {getFieldDecorator('addFundsAccount', {
                  rules: [
                    {
                      required: true,
                      message: '是否开通资金账户'
                    }
                  ],
                  initialValue: qualificationDetailInfo.addFundsAccount
                })(
                  <RadioGroup value={qualificationDetailInfo.addFundsAccount}>
                    <Radio value={true}>开通</Radio>
                    <Radio value={false}>不开通</Radio>
                  </RadioGroup>
                )}
              </FormItem>
            </Form>
          </div>
        </div>
        <div style={{ padding: '10px 0 50px 150px' }}>
          <Button onClick={this.back}>返回</Button>
          {
            qualificationDetailInfo.completionStatus !== certCompletionStatusCode.COMPLETED ? (
              <Button style={{ marginLeft: 10 }} onClick={this.toComplete}>
                待补全
              </Button>
            ) : (
              <Tooltip title="已经标记为待补全，请勿重复操作" trigger="hover">
                <Button style={{ marginLeft: 10 }} disabled>
                  待补全
                </Button>
              </Tooltip>
            )
          }
          <Button style={{ marginLeft: 10 }} onClick={this.showRefuseModal}>
            审核拒绝
          </Button>
          <Button style={{ marginLeft: 10 }} onClick={this.pass}>
            审核通过
          </Button>
        </div>

        <div style={{ padding: '0 0 30px 0' }}>
          {modifyHistoryList && (
            <Table
              className={commonStyles.row}
              rowKey={getUniqTableRowKey('operator', modifyHistoryList.pagerInfo)}
              columns={this.getColumns()}
              pagination={{ current: modifyHistoryList.pagerInfo.page, total: modifyHistoryList.pagerInfo.total }}
              onChange={this.handleTableChange}
              dataSource={modifyHistoryList.result}
            />
          )}
        </div>

        <Modal
          title='查看大图'
          width={this.state.imgWidth + 48}
          visible={this.state.showBigImg}
          onOk={this.closeBigImgModal}
          onCancel={this.closeBigImgModal}
        >
          <img src={this.state.bigImgUrl} alt='' />
        </Modal>

        <Modal
          title='提示'
          width={400}
          visible={this.state.showRefuseModal}
          onOk={this.refuse}
          onCancel={this.cancelRefuse}
        >
          <h4>请备注拒绝原因:</h4>
          <TextArea
            value={this.state.reason}
            rows={5}
            onChange={(e) => {
              this.setState({ reason: e.target.value });
            }}
            maxLength={REJECT_REASON_MAX_LENGTH}
            placeholder={`不限类型，${REJECT_REASON_MAX_LENGTH}字以内`}
          />
        </Modal>
      </div>
    );
  }
}

export default CheckQualificationDetail;