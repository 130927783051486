import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Table, Button, Input, Popconfirm, message, Tooltip, Form } from 'antd';
// import { Link } from 'react-router';
import reaction from 'reaction';
import history from 'utils/history';

// import FormItem from 'antd/lib/form/FormItem';
import FormItem from 'components/FormItem';

import { transformParamsToUrl, renderListControls } from 'utils';
import { RoutePath, HttpCode, AuthCodes } from 'utils/constants';

import commonStyles from 'style/common.module.scss';

const getColumns = (context) => {
  const { role: { list }, me: { roleInfo: { roleAuthList } } } = context.props;

  let deleteEnbaled = roleAuthList.some((item) => {
    return item.authCode.indexOf(AuthCodes.SYSTEM_ROLE_DELETE);
  });
  let editEnbaled = roleAuthList.some((item) => {
    return item.authCode.indexOf(AuthCodes.SYSTEM_ROLE_UPDATE) !== -1;
  });

  return [
    {
      title: '序号',
      width: 80,
      fixed: 'left',
      key: 'number',
      render: (_, _1, index) => {
        return (list.pagerInfo.page - 1) * context.state.length + index + 1
      },
    },
    {
      title: '角色名称',
      dataIndex: 'roleName',
      render: (text) => {
        if (text && text.length > 10) {
          return (
            <Tooltip title={text}>
              <span style={{ cursor: 'pointer' }}>{text}</span>
            </Tooltip>
          );
        }
        return text;
      },
    },
    {
      title: '角色人数',
      key: 'usedCount',
      dataIndex: 'usedCount',
      render: val => val === null || val === void 0 ? '--' : val
    },
    {
      title: '角色成员',
      key: 'usedNames',
      dataIndex: 'usedNames',
      render: usedNames => {
        const names = usedNames || [];
        const text = typeof names === 'string' ? names : names.join('，');

        if (text && text.length > 20) {
          return (
            <Tooltip title={text}>
              <span style={{ cursor: 'pointer' }}>{text.substr(0, 20)}...</span>
            </Tooltip>
          );
        }
        return text || '--';
      }
    },
    {
      title: '角色说明',
      dataIndex: 'description',
      render: (text, record) => {
        if (text && text.length > 20) {
          return (
            <Tooltip title={text}>
              <span style={{ cursor: 'pointer' }}>{text.substr(0, 20)}...</span>
            </Tooltip>
          );
        }
        return text;
      },
    },
    {
      title: '操作',
      width: 200,
      fixed: 'right',
      key: 'handler',
      render: (_, record) => (
        <Button.Group>
          <Button
            icon="edit"
            onClick={context.goEdit.bind(context, record)}
            disabled={!editEnbaled}
          >
            修改
          </Button>
          {deleteEnbaled ? (
            <Popconfirm
              title="确定要删除？"
              onConfirm={context.doDelete.bind(context, record)}
              okText="确定"
              cancelText="取消"
            >
              <Button icon="delete" type="danger">
                删除
              </Button>
            </Popconfirm>
          ) : (
            <Button icon="delete" type="danger" disabled>
              删除
            </Button>
          )}
        </Button.Group>
      ),
    },
  ];
}

/**
 * 角色管理
 */
@connect(({ role, me }) => ({ role, me }))
@withRouter
class Role extends React.Component {
  state = {
    currentPage: 1,
    length: 10,
    params: {
      roleName: '',
      realName: '',
    },
  };

  componentDidMount() {
    let { roleAuthList } = this.props.me.roleInfo;
    let listEnbaled = roleAuthList.some((item) => {
      return item.authCode.indexOf(AuthCodes.SYSTEM_ROLE_LIST) !== -1;
    });
    let roleEnbaled = roleAuthList.some((item) => {
      return (item.authCode.indexOf(AuthCodes.SYSTEM_ROLE) !== -1);
    });
    listEnbaled && reaction.role.getList();
    !roleEnbaled && history.push('/');
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.setState({
      currentPage: pagination.current,
      length: pagination.pageSize,
    });
    this.getRoleList(pagination.current, pagination.pageSize, this.state.params);
  };

  handleSizeChange = (currentPage, length) => {
    this.setState({
      currentPage,
      length,
    });
  }

  handleQueryChange = (name, value) => {
    this.setState(preState => {
      return {
        ...preState,
        params: {
          ...preState.params,
          [name]: value,
        },
      };
    });
  }

  handleSearch = (e) => {
    const { length, params } = this.state;
    e.preventDefault();
    this.setState({
      currentPage: 1,
    })
    this.getRoleList(1, length, params);
  }

  getRoleList = (currentPage, length, params) => {
    reaction.role.getList(currentPage, length, params);
  };

  goEdit = (record, e) => {
    this.props.history.push({
      pathname: `/${RoutePath.SYSTEM}/${RoutePath.ROLE}/${RoutePath.MODIFY}`,
      search: transformParamsToUrl({ roleId: record.roleId }),
    });
  };

  doAdd = (e) => {
    this.props.history.push(`/${RoutePath.SYSTEM}/${RoutePath.ROLE}/${RoutePath.ADD}`);
  };

  doDelete = (record, e) => {
    reaction.role.delete(record.roleId).then((data) => {
      if (data.code === HttpCode.OK) {
        message.success('删除成功！');
        reaction.common.getRoleDict(); // 刷新角色字典
        let { currentPage, length, params } = this.state;
        // 如果删除的是当前页最后一条数据，那么删除后重新请求的页码应该是上一页的页码
        if (length === 1 && currentPage > 1) {
          currentPage -= 1;
        }
        this.setState({
          currentPage,
        })
        this.getRoleList(currentPage, length, params);
      }
    });
  };

  render() {
    let { roleAuthList } = this.props.me.roleInfo;
    let addEnbaled = roleAuthList.some((item) => {
      return item.authCode.indexOf(AuthCodes.SYSTEM_ROLE_ADD) !== -1;
    });

    const { list } = this.props.role;
    const columns = getColumns(this);

    return (
      <div className="user">
        <h2 className={commonStyles.row}>
          <span>角色管理</span>
        </h2>
        <Form layout="inline" onSubmit={this.handleSearch}>
          {
            renderListControls([
              <FormItem
                label="角色名称"
              >
                <Input
                  // style={{ width: 165 }}
                  value={this.state.params.roleName}
                  onChange={(e) => {
                    this.handleQueryChange('roleName', e.target.value);
                  }}
                  placeholder="角色名称"
                />
              </FormItem>,
              <FormItem
                label="角色成员"
              >
                <Input
                  // style={{ width: 165 }}
                  value={this.state.params.realName}
                  onChange={(e) => {
                    this.handleQueryChange('realName', e.target.value);
                  }}
                  placeholder="角色成员"
                />
              </FormItem>,
              {
                // colProps: {
                //   xxl: 12,
                //   lg: 24,
                //   md: 24,
                // },
                children: (
                  <FormItem push={20}>
                    <Button type="primary" icon="search" htmlType="submit">查询</Button>
                    <Button type="primary" style={{ marginLeft: 5 }} icon="plus" onClick={this.doAdd} disabled={!addEnbaled}>新增</Button>
                  </FormItem>
                )
              }
            ])
          }
        </Form>
        {/* <div style={{ marginTop: 10 }}>
          <FormItem>
            <Button icon="plus" onClick={this.doAdd} disabled={!addEnbaled}>新增</Button>
          </FormItem>
        </div> */}
        {list && (
          <Table
            className={commonStyles.row}
            rowKey="roleId"
            columns={columns}
            dataSource={list.result}
            pagination={{
              current: list.pagerInfo.page,
              total: list.pagerInfo.total,
              pageSizeOptions: ['10', '20', '50'],
              showSizeChanger: true,
              showQuickJumper: true,
              showTotal: (total) => `共${total}条`,
              pageSize: this.state.length,
              onShowSizeChange: this.handleSizeChange,
            }}
            onChange={this.handleTableChange}
          />
        )}
      </div>
    );
  }
}

export default Role;
