import createUID from 'utils/createUID';
import http from 'utils/http';

const GET_LIST = createUID();
const GET_REGISTER_ACCOUNT_NUMBER_INFO = createUID();
const GET_DETAIL = createUID();
const GET_USER_CHANGE_HISTORY = createUID();
const UPDATE_USER_INFO = createUID();
const GET_TEAM_LIST_LOADING = 'departmentTeam/loading';
const GET_TEAM_LIST = 'departmentTeam/list';

const initialState = {
  list: {
    pagerInfo: { page: 1, total: 0 },
    result: []
  },
  registerAccountNumberInfo: {
    totalUsers: 0,
    todayUsers: 0
  },
  detail: {
    userNo: '',
    username: '',
    phone: '',
    email: '',
    registerSource: '',
    registerIp: '',
    registerTime: '',
    isInternalUser: false,
    active: true,
    disableReason: '',
  },
  changeHistory: {
    pagerInfo: { page: 1, total: 0 },
    result: []
  },
  teamList: [],
  teamListLoading: false,
};

function reducer (state = initialState, action) {
  switch (action.type) {
    case GET_LIST:
      return Object.assign({}, state, { list: action.data });
    case GET_REGISTER_ACCOUNT_NUMBER_INFO:
      return Object.assign({}, state, { registerAccountNumberInfo: action.data });
    case GET_DETAIL:
      return Object.assign({}, state, { detail: action.data });
    case GET_USER_CHANGE_HISTORY:
      return Object.assign({}, state, { changeHistory: action.data });
    case GET_TEAM_LIST_LOADING:
      return Object.assign({}, state, { teamListLoading: action.data })
    case GET_TEAM_LIST:
      return Object.assign({}, state, { teamList: action.data });
    default:
      return state;
  }
}

Object.assign(reducer, {
  getList (data, showLoading) {
    const { userNo, username, phone, isActive, parentOrSelf, operator, signatureStatus, appDomain, department, salesman, userType, hasFundsAccount, registerPlatformIdList, registerTimeStart, registerTimeEnd, updateBeginTime, updateEndTime, isInternalUser, page = 1, length = 10 } = data;
    return (dispatch) => {
      return http.request('v1.user.list', {
        queryCondition: { userNo, username, phone, isActive, parentOrSelf, operator, signatureStatus, appDomain, department, salesman, userType, hasFundsAccount, registerPlatformIdList, registerTimeStart, registerTimeEnd, updateBeginTime, updateEndTime, isInternalUser },
        pagerCondition: { page, length }
      }, showLoading).then((data) => {
        dispatch({ type: GET_LIST, data: data.data });
        return data;
      });
    };
  },
  getRegisterAccountNumber () {
    return (dispatch) => {
      return http.request('v1.user.getTodayRegisterInfo').then((data) => {
        if (data.code === 0) dispatch({ type: GET_REGISTER_ACCOUNT_NUMBER_INFO, data: data.data });
        return data;
      });
    };
  },
  getDetail (uid) {
    return (dispatch) => {
      return http.request('v1.user.getUser', { userId: uid })
        .then((data) => {
          if (data.code === 0) dispatch({ type: GET_DETAIL, data: data.data });
          return data;
        });
    };
  },
  resetPassword ({ userId }) {
    return (dispatch) => {
      return http.request('v1.user.resetPassword', { userId });
    };
  },
  updateUserStatus (param) {
    return (dispatch) => {
      return http.request('v1.user.updateUserStatus', param);
    };
  },
  updateSomeUsersStatus (param) {
    return (dispatch) => {
      return http.request('v1.user.batchUpdateUserStatus', param);
    };
  },
  getUserChangeHistory (userId, operationType, page = 1, length = 10) {
    return (dispatch) => {
      return http.request('v1.common.listOperationHistory', {
        queryCondition: { userId, operationType },
        pagerCondition: { page, length }
      }).then((data) => {
        dispatch({ type: GET_USER_CHANGE_HISTORY, data: data.data });
        return data;
      });
    };
  },
  updateUserInfo ({ userId, username, phone, email, isInternalUser, isActive, appDomain, department, team, salesman, disableReason }) {
    return (dispatch) => {
      const data = { userId, username, phone, email, isInternalUser, isActive, appDomain, department, team, salesman };
      if (disableReason) {
        data.disableReason = disableReason;
      }
      return http.request('v1.user.updateUser', data);
    };
  },
  createUser (params) {
    return (dispatch) => {
      return http.request('v1.user.addUser', params);
    };
  },
  exportUsers (params) {
    return (dispatch) => {
      return http.request('v1.user.export', params)
        .then((data) => {
          return data;
        });
    };
  },
  updatePhone (params) {
    return (dispatch) => {
      return http.request('v1.user.updatePhoneV2', params)
        .then((data) => {
          return data;
        });
    };
  },
  getDepartmentTeamList (params, showLoading = false) {
    return (dispatch) => {
      dispatch({
        type: GET_TEAM_LIST_LOADING,
        data: true,
      });
      return http.request('v1.user.listDepartmentTeam', params, showLoading)
        .then(res => {
          if (res.code === 0) {
            dispatch({
              type: GET_TEAM_LIST,
              data: res.data,
            });
          }
          dispatch({
            type: GET_TEAM_LIST_LOADING,
            data: false,
          });
        });
    }
  },
  clearDepartmenetTeamList() {
    return dispatch => {
      dispatch({
        type: GET_LIST,
        data: [],
      });
    }
  }
});
export default reducer;
