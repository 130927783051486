import React from 'react';
import {connect} from 'react-redux';
import {RoutePath, AuthCodes} from 'utils/constants';
import {historyPush} from 'utils/history';
import authAndRedirect from 'utils/authAndRedirect';

/**
 * 系统管理
 */
@connect(({common, me, menu}) => ({common, me, menu}))
class System extends React.Component {
  componentDidMount() {
    authAndRedirect(this);
    // let {pathname} = this.props.location;
    // let {roleAuthList} = this.props.me.roleInfo;
    // let userEnbaled = roleAuthList.some((item) => {
    //   if (item.authCode.indexOf(AuthCodes.SYSTEM_USER) !== -1) return true;
    // });
    // let roleEnbaled = roleAuthList.some((item) => {
    //   if (item.authCode.indexOf(AuthCodes.SYSTEM_ROLE) !== -1) return true;
    // });
    // let logFrontendEnbaled = roleAuthList.some((item) => {
    //   if (item.authCode.indexOf(AuthCodes.SYSTEM_LOG_FRONTEND) !== -1) return true;
    // });
    // let logBackendEnbaled = roleAuthList.some((item) => {
    //   if (item.authCode.indexOf(AuthCodes.SYSTEM_LOG_BACKEND) !== -1) return true;
    // });
    // let authManagementEnabled = roleAuthList.some((item) => {
    //   if (item.authCode.indexOf(AuthCodes.SYSTEM_AUTH) !== -1) return true;
    // });
    // let dataDictEnabled = roleAuthList.some((item) => {
    //   if (item.authCode.indexOf(AuthCodes.SYSTEM_DATA_DICT) !== -1) return true;
    // });
    // if (userEnbaled) {
    //   historyPush(`/${RoutePath.SYSTEM}/${RoutePath.USER}`);
    // } else if (roleEnbaled) {
    //   historyPush(`/${RoutePath.SYSTEM}/${RoutePath.ROLE}`);
    // } else if (logFrontendEnbaled) {
    //   historyPush(`/${RoutePath.SYSTEM}/${RoutePath.LOG}/${RoutePath.FRONTEND}`);
    // } else if (logBackendEnbaled) {
    //   historyPush(`/${RoutePath.SYSTEM}/${RoutePath.LOG}/${RoutePath.BACKEND}`);
    // } else if (authManagementEnabled) {
    //   historyPush(`/${RoutePath.SYSTEM}/${RoutePath.AUTH}`);
    // } else if (dataDictEnabled) {
    //   historyPush(`/${RoutePath.SYSTEM}/${RoutePath.DICT}/${RoutePath.DICT_LIST}`);
    // } else {
    //   historyPush(RoutePath.INDEX);
    // }
  }
  
  render() {
    return null;
  }
}

export default System;