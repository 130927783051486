import createUID from 'utils/createUID';
import http from 'utils/http';

const LIST = createUID();
const DETAIL = createUID();
const DELETE = createUID();
const initialState = {
  list: {
    pagerInfo: { page: 1, total: 0 },
    result: [],
  },
  detail: {},
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case LIST:
      return Object.assign({}, state, { list: action.data });
    case DETAIL:
      return Object.assign({}, state, { detail: action.data });
    case DELETE: {
      let userId = action.data;
      return Object.assign({}, state, {
        list: Object.assign({}, state.list, {
          result: state.list.result.filter((data) => {
            return data.userId !== userId;
          }),
        }),
      });
    }
    default:
      return state;
  }
}
Object.assign(reducer, {
  getList(userName = '', page = 1, length = 10) {
    return (dispatch) => {
      return http
        .request('v1.system.listUser', { userName, page, length })
        .then((data) => {
          dispatch({ type: LIST, data: data.data });
          return data;
        });
    };
  },
  getUserInfoByPhone(phone) {
    return (dispatch) => {
      return http.request('v1.system.queryUserByPhone', { phone }, false);
        // .then(data => data);
    }
  },
  getDetail(userId) {
    return (dispatch) => {
      return http
        .request('v1.system.queryUserDetail', { userId })
        .then((data) => {
          dispatch({ type: DETAIL, data: data.data });
          return data;
        });
    };
  },
  create(data) {
    return (dispatch) => {
      return http
        .request('v1.system.addUser', data)
        .then((data) => {
          //dispatch({type: CREATE, data: data.data});
          return data;
        });
    };
  },
  delete(userId) {
    return (dispatch) => {
      return http.request('v1.system.deleteUser', { userId }).then((data) => {
        dispatch({ type: DELETE, data: userId });
        return data;
      });
    };
  },
  update(userId, role) {
    return (dispatch) => {
      return http
        .request('v1.system.updateUser', { userId, role })
        .then((data) => {
          //dispatch({type: DELETE, data: data.data});
          return data;
        });
    };
  },
});
export default reducer;
