import React from 'react';
import { DatePicker, Button, Card, Table, Popconfirm, Form, Input, message } from 'antd';
import EditableCell from 'components/EditableCell';
import reaction from 'reaction';
// import dayjs from 'dayjs';

const { TextArea } = Input;
const { WeekPicker } = DatePicker;
const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={ form }>
    <tr {...props} />
  </EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);

let departments = [{
  key: 0,
  name: '物流部', 
  planFinishAmount: ''
}];

export default class UploadOfflineData extends React.Component {
  state = {
    weekTimeMills: '',
    trade: {
      steelMills: [{
        key: 0,
        companyId: '',
        unPlatformWeight: '',
        unPlatformAmount: ''
      }],
      tradeAreas: []
    },
    logistics: {
      modes: [{
        key: 0,
        selfSell: '自营',
        unPlatformWeight: '',
        unPlatformAmount: ''
      }, {
        key: 1,
        selfSell: '撮合',
        unPlatformWeight: '',
        unPlatformAmount: ''
      }],
      marks: '',
      extra: ''
    },
    cenpur: {
      depts: [{
        key: 0,
        name: '',
        selfSell: '',
        unPlatformWeight: '',
        unPlatformAmount: ''
      }, {
        key: 1,
        name: '',
        selfSell: '',
        unPlatformWeight: '',
        unPlatformAmount: ''
      }],
      marks: ''
    },
    // SteelMillsRowCount: 1,
    // cenpurDataCount: 2,
    tradeModeDict: [{
      key: 0,
      name: '自营',
      id: 'true',
      selfSell: true
    }, {
      key: 1,
      name: '撮合',
      id: 'false',
      selfSell: false
    }],
    companyDict: [],
    departmentDict: [],
    areaDict: [],
    detailData: {}
  };
  
  getDepartmentDict = () => {
    reaction.report.getDepartmentDict('').then(data => {
      this.setState({
        companyDict: data.data
      });
    });
  };
  
  getAreaAndDepartment = () => {
    let { trade } = this.state;
    reaction.report.getAreaAndDepartment().then(data => {
      this.setState({
        areaDict: data.data.tradeAreas,
        trade: {
          steelMills: trade.steelMills,
          tradeAreas: data.data.tradeAreas.map((item, i) => {
            return {
              key: i,
              area: item.name + '区域',
              areaId: item.id,
              planFinishAmount: ''
            };
          }),
          marks: trade.marks
        },
        departmentDict: data.data.cenpurDepts
      });
    });
  };
  
  getDetailData = (time) => {
    return reaction.report.getDetailData(time).then(res => {
      this.setState({
        detailData: res.data,
        // SteelMillsRowCount: res.data.trade && res.data.trade.steelMills ? res.data.trade.steelMills.length : 0,
        // cenpurDataCount: res.data.cenpur && res.data.cenpur.depts ? res.data.cenpur.depts.length : 0,
        trade: {
          marks: res.data.trade && res.data.trade.marks,
          steelMills: res.data.trade && res.data.trade.steelMills ? res.data.trade.steelMills.map((item, i) => {
            return {
              key: Math.random(),
              ...item,
              unPlatformAmount: (item.unPlatformAmount / 10000).toFixed(2)
            };
          }) : [],
          tradeAreas: this.state.areaDict.map((item, i) => {
            let data = null;
            if (res.data.trade && res.data.trade.tradeAreas) {
              data = res.data.trade.tradeAreas.filter((it, i) => it.areaId === item.id)[0];
            }
            return {
              area: item.name + '区域',
              areaId: item.id,
              key: Math.random() + '',
              planFinishAmount: data ? (data.planFinishAmount / 10000).toFixed(2) : ''
            };
          })
        },
        logistics: {
          modes: this.state.logistics.modes.map((item, i) => {
            let data = null;
            if (res.data.logistics && res.data.logistics.modes) {
              data = res.data.logistics.modes.filter(it => {
                if (it.selfSell) {
                  return item.selfSell === true || item.selfSell === '自营';
                }
                return item.selfSell === false || item.selfSell === '撮合';
              })[0];
            }
            return {
              key: Math.random() + '',
              selfSell: item.selfSell,
              unPlatformWeight: data ? data.unPlatformWeight : '',
              unPlatformAmount: data ? (data.unPlatformAmount / 10000).toFixed(2) : ''
            };
          }),
          marks: (res.data.logistics && res.data.logistics.marks) || '',
          extra: (res.data.logistics && res.data.logistics.extra) || ''
        },
        cenpur: {
          depts: res.data.cenpur && res.data.cenpur.depts ? res.data.cenpur.depts.map((item, i) => {
            return {
              key: Math.random(),
              ...item,
              unPlatformAmount: (item.unPlatformAmount / 10000).toFixed(2),
              selfSell: item.selfSell ? 'true' : 'false'
            };
          }) : [],
          marks: (res.data.cenpur && res.data.cenpur.marks) || ''
        }
      });
    });
  };
  
  handleWeekChange = (v) => {
    let time = v.startOf('week').valueOf();
    this.getDetailData(time);
    this.setState({
      weekTimeMills: time
    });
  };
  
  handleTradeMarksChange = (v) => {
    let { trade } = this.state;
    this.setState({
      trade: {
        ...trade,
        marks: v
      }
    });
  };
  
  handleLogisticsMarksChange = (v) => {
    let { logistics } = this.state;
    this.setState({
      logistics: {
        ...logistics,
        marks: v
      }
    });
  };
  
  handleDelete = (key) => {
    const trade = Object.assign({}, this.state.trade);
    this.setState({ trade: {
        steelMills: trade.steelMills.filter(item => item.key !== key),
        tradeAreas: trade.tradeAreas,
        marks: trade.marks
      }
    });
  };
  
  handleDeleteCenpurData = (key) => {
    const cenpur = Object.assign({}, this.state.cenpur);
    this.setState({ cenpur: {
        depts: cenpur.depts.filter(item => item.key !== key),
        marks: cenpur.marks
      }
    });
  };
  
  handleAddSteelMillsRow = () => {
    const { trade } = this.state;
    const newData = {
      key: Math.random() + '',
      companyId: '',
      unPlatformWeight: '',
      unPlatformAmount: ''
    };
    this.setState({
      trade: {
        steelMills: [...(trade.steelMills), newData],
        tradeAreas: trade.tradeAreas,
        marks: trade.marks
      }
    });
  };
  
  handleAddCenpurDataRow = () => {
    const { cenpur } = this.state;
    const newData = {
      key: Math.random() + '',
      name: '',
      selfSell: '',
      unPlatformWeight: '',
      unPlatformAmount: ''
    };
    this.setState({
      cenpur: {
        depts: [...cenpur.depts, newData],
        marks: cenpur.marks
      }
    });
  };
  
  handleSave = (field, row) => {
    let { trade, logistics, cenpur } = this.state;
    let newData, index, item;
    switch (field) {
      case 'trade.steelMills':
        newData = [...this.state.trade.steelMills];
        index = newData.findIndex(item => row.key === item.key);
        item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        this.setState({
          trade: {
            steelMills: newData,
            tradeAreas: [...trade.tradeAreas],
            marks: trade.marks
          }
        });
        break;
      case 'trade.tradeAreas':
        newData = [...this.state.trade.tradeAreas];
        index = newData.findIndex(item => row.key === item.key);
        item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        this.setState({
          trade: {
            steelMills: [...trade.steelMills],
            tradeAreas: newData,
            marks: trade.marks
          }
        });
        break;
      case 'logistics':
        newData = [...this.state.logistics.modes];
        index = newData.findIndex(item => row.key === item.key);
        item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        this.setState({
          logistics: {
            modes: newData,
            marks: logistics.marks
          }
        });
        break;
      case 'logistics.departments':
        this.setState({
          logistics: {
            modes: [...this.state.logistics],
            marks: this.state.logistics.marks,
            extra:  row.planFinishAmount
          }
        });
        break;
      case 'cenpur':
        newData = [...this.state.cenpur.depts];
        index = newData.findIndex(item => row.key === item.key);
        item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        this.setState({
          cenpur: {
            depts: newData,
            marks: cenpur.marks
          }
        });
        break;
      default:
        
    }
  };
  
  back = () => {
  
  };
  
  submit = () => {
    let { weekTimeMills, trade, logistics, cenpur } = this.state;
    let data = {
      weekTimeMills,
      trade: {
        steelMills: trade.steelMills.map(item => {
          return {
            ...item,
            unPlatformAmount: item.unPlatformAmount * 10000
          };
        }),
        tradeAreas: trade.tradeAreas.map(item => {
          return {
            ...item,
            planFinishAmount: item.planFinishAmount * 10000,
            areaId: item.areaId
          };
        }),
        marks: trade.marks
      },
      logistics: {
        modes: logistics.modes.map(item => {
          return {
            ...item,
            unPlatformAmount: item.unPlatformAmount * 10000,
            selfSell: item.selfSell === '自营'
          };
        }),
        marks: logistics.marks,
        extra: logistics.extra
      },
      cenpur: {
        depts: cenpur.depts.map(item => {
          return {
            selfSell: item.selfSell === 'true',
            ...item,
            unPlatformAmount: item.unPlatformAmount * 10000
          };
        }),
        marks: ''
      }
    };
    if (!data.weekTimeMills) {
      message.error('请选择期间后再提交!');
      return;
    }

    reaction.report.uploadWeeklyData(data).then(res => {
      if (res.code === 0) {
        message.success('上传成功!');
      }
    });
  };
  
  componentDidMount () {
    this.getDepartmentDict();
    this.getAreaAndDepartment();
  }
  
  render () {
    let { trade, logistics, cenpur, companyDict, tradeModeDict, departmentDict } = this.state;
    departments = [{
      ...departments[0],
      key: Math.random() + '',
      planFinishAmount: logistics.extra
    }];
    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell,
      },
    };
    let columns = [{
      title: '钢厂',
      dataIndex: 'companyId',
      width: '30%',
      editable: true,
      onCell: record => ({
        record,
        type: 'inputSelect',
        options: companyDict,
        editable: true,
        dataIndex: 'companyId',
        selectedValues: [...trade.steelMills.map(item => item.companyId)],
        required: true,
        pattern: null,
        message: '请选择钢厂',
        title: '钢厂',
        handleSave: row => this.handleSave('trade.steelMills', row)
      })
    }, {
      title: '非平台交易量（吨）',
      dataIndex: 'unPlatformWeight',
      width: '30%',
      editable: true,
      onCell: record => ({
        record,
        editable: true,
        dataIndex: 'unPlatformWeight',
        required: true,
        pattern: /^\d+(\.\d{1,2})?$/,
        message: '请输入非平台交易量（吨）(最多两位小数)',
        title: '非平台交易量（吨）',
        handleSave: row => this.handleSave('trade.steelMills', row)
      })
    }, {
      title: '非平台交易额（万元）',
      dataIndex: 'unPlatformAmount',
      width: '30%',
      editable: true,
      onCell: record => ({
        record,
        editable: true,
        dataIndex: 'unPlatformAmount',
        required: true,
        pattern: /^\d+(\.\d{1,2})?$/,
        message: '请输入非平台交易额（万元）(最多两位小数)',
        title: '非平台交易额（万元）',
        handleSave: row => this.handleSave('trade.steelMills', row)
      })
    }, {
      title: '操作',
      width: '10%',
      dataIndex: 'operation',
      render: (text, record, i) => {
        return (
          this.state.trade.steelMills.length >= 1
            ? (
              <Popconfirm title="确定删除?" onConfirm={() => this.handleDelete(record.key)}>
                <a href="javascript:void(0)" ref="none">删除</a>
              </Popconfirm>
            ) : null
        );}
    }];
    let columns1 = [{
      title: '区域',
      dataIndex: 'area',
      width: '50%'
    }, {
      title: '计划完成交易额（万元）',
      dataIndex: 'planFinishAmount',
      width: '50%',
      editable: true,
      onCell: record => ({
        record,
        editable: true,
        dataIndex: 'planFinishAmount',
        required: true,
        pattern: /^\d+(\.\d{1,2})?$/,
        message: '请输入计划完成交易额（万元）(最多两位小数)',
        title: '计划完成交易额（万元）',
        handleSave: row => this.handleSave('trade.tradeAreas', row)
      })
    }];
    let columns1_1 = [{
      title: '部门',
      dataIndex: 'name',
      width: '50%'
    }, {
      title: '计划完成承运量（吨）',
      dataIndex: 'planFinishAmount',
      width: '50%',
      editable: true,
      onCell: record => ({
        record,
        editable: true,
        dataIndex: 'planFinishAmount',
        required: true,
        pattern: /^\d+(\.\d{1,2})?$/,
        message: '请输入计划完成承运量（吨）(最多两位小数)',
        title: '计划完成承运量（吨）',
        handleSave: row => this.handleSave('logistics.departments', row)
      })
    }];
    let columns2 = [{
      title: '交易模式',
      dataIndex: 'selfSell',
      width: '20%'
    }, {
      title: '非平台承运量（吨）',
      dataIndex: 'unPlatformWeight',
      width: '40%',
      editable: true,
      onCell: record => ({
        record,
        editable: true,
        dataIndex: 'unPlatformWeight',
        required: true,
        pattern: /^\d+(\.\d{1,2})?$/,
        message: '请输入非平台承运量（吨）(最多两位小数)',
        title: '非平台承运量（吨）',
        handleSave: row => this.handleSave('logistics', row)
      })
    }, {
      title: '非平台合同运费（万元）',
      dataIndex: 'unPlatformAmount',
      width: '40%',
      editable: true,
      onCell: record => ({
        record,
        editable: true,
        dataIndex: 'unPlatformAmount',
        required: true,
        pattern: /^\d+(\.\d{1,2})?$/,
        message: '请输入非平台合同运费（万元）(最多两位小数)',
        title: '非平台合同运费（万元）',
        handleSave: row => this.handleSave('logistics', row)
      })
    }];
    let columns3 = [{
      title: '部门',
      dataIndex: 'name',
      width: '15%',
      editable: true,
      onCell: record => ({
        record,
        type: 'inputSelect',
        options: departmentDict,
        editable: true,
        dataIndex: 'name',
        // selectedValues: [...cenpur.depts.map(item => item.name)],
        required: true,
        pattern: null,
        message: '请选择部门',
        title: '部门',
        handleSave: row => this.handleSave('cenpur', row)
      })
    }, {
      title: '交易模式',
      dataIndex: 'selfSell',
      width: '15%',
      editable: true,
      onCell: record => ({
        record,
        type: 'inputSelect',
        options: tradeModeDict,
        editable: true,
        dataIndex: 'selfSell',
        required: true,
        pattern: null,
        message: '请选择交易模式',
        title: '交易模式',
        handleSave: row => this.handleSave('cenpur', row)
      })
    }, {
      title: '非平台承运量（吨）',
      dataIndex: 'unPlatformWeight',
      width: '30%',
      editable: true,
      onCell: record => ({
        record,
        editable: true,
        dataIndex: 'unPlatformWeight',
        required: true,
        pattern: /^\d+(\.\d{1,2})?$/,
        message: '请输入非平台承运量（吨）(最多两位小数)',
        title: '非平台承运量（吨）',
        handleSave: row => this.handleSave('cenpur', row)
      })
    }, {
      title: '非平台合同运费（万元）',
      dataIndex: 'unPlatformAmount',
      width: '30%',
      editable: true,
      onCell: record => ({
        record,
        editable: true,
        dataIndex: 'unPlatformAmount',
        required: true,
        pattern: /^\d+(\.\d{1,2})?$/,
        message: '请输入非平台合同运费（万元）(最多两位小数)',
        title: '非平台合同运费（万元）',
        handleSave: row => this.handleSave('cenpur', row)
      })
    }, {
      title: '操作',
      width: '10%',
      dataIndex: 'operation',
      render: (text, record, i) => {
        return (
          this.state.cenpur.depts.length >= 1
            ? (
              <Popconfirm title="确定删除?" onConfirm={() => this.handleDeleteCenpurData(record.key)}>
                <a href="javascript:void(0)">删除</a>
              </Popconfirm>
            ) : null
        );}
    }];
    return (<div style={{width: 1200}}>
      <h3 style={{ overflow: 'hidden' }}>
        <span>周报线下数据上传</span>
      </h3>
      <Card style={{marginTop: 20}}>
        <span>期间： </span>
        <WeekPicker onChange={ this.handleWeekChange } placeholder="选择周" />
      </Card>
      <Card style={{marginTop: 20}} title="交易情况汇总-线下交易业务">
        <Table
          components={ components }
          rowClassName={() => 'editable-row'}
          bordered
          dataSource={ trade.steelMills }
          pagination={ false }
          columns={ columns }
        />
        {
          trade.steelMills.length < companyDict.length && <Button onClick={ this.handleAddSteelMillsRow } type="primary" style={{ marginBottom: 16, marginTop: 10 }}>增加一行</Button>
        }
      </Card>
      <Card style={{marginTop: 20}} title="营销部区域交易情况线下数据-交易平台">
        <Table
          components={ components }
          rowClassName={() => 'editable-row'}
          bordered
          dataSource={ trade.tradeAreas }
          pagination={ false }
          columns={ columns1 }
        />
        <h4 style={{padding: '15px 0 10px 0'}}>备注：</h4>
        <TextArea value={ trade.marks } onChange={ (e) => { this.handleTradeMarksChange(e.target.value); } } maxLength={ 200 } rows={ 4 }/>
      </Card>
      <Card style={{marginTop: 20}} title="物流计划完成承运量">
        <Table
          rowKey="key"
          components={ components }
          rowClassName={() => 'editable-row'}
          bordered
          dataSource={ departments }
          pagination={ false }
          columns={ columns1_1 }
        />
      </Card>
      <Card style={{marginTop: 20}} title="运输情况线下数据-物流平台">
        <Table
          components={ components }
          rowClassName={() => 'editable-row'}
          bordered
          dataSource={ logistics.modes }
          pagination={ false }
          columns={ columns2 }
        />
        <h4 style={{padding: '15px 0 10px 0'}}>备注：</h4>
        <TextArea value={ logistics.marks } onChange={ (e) => { this.handleLogisticsMarksChange(e.target.value); } } maxLength={ 200 } rows={ 4 }/>
      </Card>
      <Card style={{marginTop: 20}} title="交易情况线下数据-集采平台">
        <Table
          components={ components }
          rowClassName={() => 'editable-row'}
          bordered
          dataSource={ cenpur.depts }
          pagination={ false }
          columns={ columns3 }
        />
        {
          cenpur.depts.length < departmentDict.length * tradeModeDict.length
          &&
          <Button onClick={ this.handleAddCenpurDataRow } type="primary" style={{ marginBottom: 16, marginTop: 10 }}>增加一行</Button>
        }
      </Card>
      <div style={{padding: '50px 0 50px 20px'}}>
        <Button type="primary" onClick={ this.submit }>保存</Button>
      </div>
    </div>);
  }
}
