import React from 'react';
import { connect } from 'react-redux';
/**
 * 系统管理
 */
@connect(({ common, me }) => ({ common, me }))
class ReceiptOfBank extends React.Component {
  componentDidMount() {}
  render() {
    return (
      <div>
        <iframe
          title=""
          style={{ width: '100%', height: '500px', border: 'none' }}
          src="https://enterprise.bank.ecitic.com/corporbank/cb060400_reBill.do"
        ></iframe>
      </div>
    );
  }
}
export default ReceiptOfBank;