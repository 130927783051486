/**
 * Created by Administrator on 2017/1/20.
 */
// import { hashHistory } from 'react-router-dom';
import { createHashHistory } from 'history';

import resolvePathname from 'resolve-pathname';
import { transformParamsToUrl } from 'utils';

let history = createHashHistory({
  basename: '/',
});

export var historyPush = function() {
  let { pathname } = history.location;
  let basePath = pathname.endsWith('/') ? pathname : pathname + '/';

  let location;
  if (typeof arguments[0] == 'string') {
    // historyPush(pathname, query);
    location = { pathname: arguments[0], query: arguments[1] };
  } else if (typeof arguments[0] == 'object') {
    // historyPush({pathname, query});
    let { pathname, query } = arguments[0];
    location = { pathname, query };
  }
  history.push({
    pathname: resolvePathname(location.pathname, basePath),
    search: transformParamsToUrl(location.query),
  });
  /^\/\w+\/\w+$/.test(resolvePathname(location.pathname, basePath)) &&
    localStorage.setItem(
      'currentSubMenuKey',
      resolvePathname(location.pathname, basePath)
    );
};
export default history;
