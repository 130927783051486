import React from 'react';
import reaction from 'reaction/index';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import { Input, Button, Table } from 'antd';
import history from 'utils/history';
import { resolveUrlQuery } from 'utils';
import { RoutePath } from 'utils/constants';

import commonStyles from 'style/common.module.scss';

const { TextArea } = Input;

@connect(({smsManagement}) => ({ smsManagement }))
 class ViewSmsModel extends React.Component {
  state = {
    id: '',
    templateCode: '',
    templateContent: '',
    templateDescription: '',
    alismsTemplateCode: '',
    isValid: 1,
    createTime: '',
    lastModifiedTime: '',
    templateName: '',
    page: 1,
    length: 10
  };

  back = () => {
    history.push({
      pathname: `/${RoutePath.OPERATION_CENTER}/${RoutePath.SMS_MANAGEMENT}`
    });
  };

  getSmsModelInfo = () => {
    let id = resolveUrlQuery(this.props.location.search).id;

    reaction.smsManagement.getSmsModelInfo(id).then(res => {
      if (res.code === 0) {
        this.setState({
          id: res.data.id,
          templateCode: res.data.templateCode,
          templateContent: res.data.templateContent,
          templateDescription: res.data.templateDescription,
          alismsTemplateCode: res.data.alismsTemplateCode,
          isValid: res.data.isValid,
          createTime: res.data.createTime,
          lastModifiedTime: res.data.lastModifiedTime,
          templateName: res.data.templateName
        });
      }
    });
  };

  getLogList = () => {
    let id = resolveUrlQuery(this.props.location.search).id;
    let { page, length } = this.state;
    reaction.smsManagement.getLogList(id, page, length);
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.setState({ page: pagination.current }, () => {
      this.getLogList();
    });
  };

  auto_grow = props => {
    props.style.height = '5px';
    props.style.height = props.scrollHeight + 'px';
  };

  componentDidMount() {
    this.getSmsModelInfo();
    this.getLogList();
  }
  render() {
    let { logList } = this.props.smsManagement;
    let {
      templateName,
      templateCode,
      templateDescription,
      alismsTemplateCode,
      templateContent,
      isValid
    } = this.state;
    let columns = [
      {
        width: 140,
        title: '操作人',
        dataIndex: 'username'
      },
      {
        width: 112,
        title: '操作时间',
        dataIndex: 'eventTime',
        render: text => {
          if (!text) {
            return '';
          }
          return dayjs(text - 0).format('YYYY-MM-DD HH:mm:ss');
        }
      },
      {
        title: '操作内容',
        dataIndex: 'content'
      }
    ];
    return (
      <div>
        <h4>短信模板</h4>
        <div style={{ padding: 10 }}>
          <label style={{ display: 'inline-block', width: 100 }} htmlFor="">
            模板名称：
          </label>
          <Input
            disabled={true}
            value={templateName}
            style={{ width: 250 }}
            placeholder=""
          />
        </div>
        <div style={{ padding: 10 }}>
          <label style={{ display: 'inline-block', width: 100 }} htmlFor="">
            code：
          </label>
          <Input
            disabled={true}
            value={templateCode}
            style={{ width: 250 }}
            placeholder=""
          />
        </div>
        <div style={{ padding: 10 }}>
          <label style={{ display: 'inline-block', width: 100 }} htmlFor="">
            服务商代码：
          </label>
          <Input
            disabled={true}
            value={alismsTemplateCode}
            style={{ width: 250 }}
            placeholder=""
          />
        </div>
        <div style={{ padding: 10 }}>
          <label
            style={{ display: 'inline-block', width: 100, float: 'left' }}
            htmlFor=""
          >
            描述：
          </label>
          <TextArea
            disabled={true}
            value={templateDescription}
            style={{ width: 300, overflow: 'visible' }}
            rows={3}
          />
        </div>
        <div style={{ padding: 10 }}>
          <label
            style={{ display: 'inline-block', width: 100, float: 'left' }}
            htmlFor=""
          >
            内容：
          </label>
          <TextArea
            disabled={true}
            value={templateContent}
            style={{ width: 300 }}
            rows={3}
          />
        </div>
        <div style={{ padding: 10 }}>
          <label style={{ display: 'inline-block', width: 100 }} htmlFor="">
            状态：
          </label>
          <span>{isValid ? '启用' : '禁用'}</span>
        </div>
        <div style={{ padding: 10 }}>
          <Button onClick={this.back}>返回</Button>
        </div>
        <div style={{ padding: '20px 0 0 0' }}>
          {
            <Table
              className={commonStyles.row}
              rowKey="uid"
              columns={columns}
              dataSource={logList ? logList.result : []}
              onChange={this.handleTableChange}
              pagination={
                logList
                  ? {
                      current: logList.pagerInfo.page,
                      total: logList.pagerInfo.total
                    }
                  : false
              }
            />
          }
        </div>
      </div>
    );
  }
}

export default ViewSmsModel;