import React from 'react';
import { connect } from 'react-redux';
import { Button, Form, Input, message } from 'antd';

import UploadWithVisible from 'components/UploadWithVisible';

import * as env from 'config/env';
import { normFile } from 'utils';
import reaction from 'reaction';

import commonStyles from 'style/common.module.scss';
import styles from './styles.module.scss';

const FormItem = Form.Item;
let formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};

@Form.create()
@connect(({ memberCenter }) => ({
  memberCenter,
}))
class ModifyCompany extends React.Component {
  state = {
    companyList: [],
    fetching: false,
    isSubmitting: false,
  };

  fetchCompanyInfo = (companyName = '') => {
    this.setState({
      fetching: true,
    })
    return reaction.memberCenter.getMemberInfo(companyName)
      .then(res => {
        if (res.data) {
          this.props.form.setFieldsValue({
            userId: res.data.userId,
          });
        }
        this.setState({
          fetching: false,
        })
        return res;
      })
        .catch(() => {
          this.setState({
            fetching: false,
          })
        });

  }

  submitMemberInfo = (data) => {
    this.setState({
      isSubmitting: true,
    })
    reaction.memberCenter.modifyMemberInfo(data)
      .then(res => {
        if (res.code === 0) {
          message.success('修改成功');
          this.props.form.resetFields();
          reaction.memberCenter.clearMemberInfo();
        }
        this.setState({
          isSubmitting: false,
        });
      })
      .catch(() => {
        this.setState({
          isSubmitting: false,
        });
      });
  } 
  
  handleOldCompanyBlur = (event) => {
    const value = event.target.value;
    value && this.fetchCompanyInfo(value);
  }

  handleFetchCompanyInfo = () => {
    this.props.form.validateFields(['oldCompanyName'], async (errors, { oldCompanyName }) => {
      if (!errors) {
        this.fetchCompanyInfo(oldCompanyName);
      }
    })
  }


  handleSubmit = () => {
    this.props.form.validateFieldsAndScroll((errors, { certificates = [], ...values }) => {
      if (!errors) {
        this.submitMemberInfo({
          certificates: certificates?.length ? certificates.map(file => {
            const response = file.response;
            return response?.data?.url;
          }).join(',') : null,
          ...values,
        })
      }
    })
  }
  
  render () {
    const { form, memberCenter } = this.props;
    const { getFieldDecorator } = form;
    const { isSubmitting, fetching } = this.state;
    return (
      <div>
        <h2 className={commonStyles.row}>修改企业名称</h2>
        <Form layout="horizontal" {...formItemLayout} className={styles.wrapper}>
          {
            getFieldDecorator('userId')(
              <Input hidden />
            )
          }
          <FormItem label="原企业名称" required>
            {
              getFieldDecorator('oldCompanyName', {
                rules: [
                  {
                    required: true,
                    message: '请输入原企业名称',
                  },
                ]
              })(
                <Input
                  className={styles.input}
                  placeholder="请输入原企业名称"
                  maxLength={50}
                  onBlur={this.handleOldCompanyBlur}
                />
              )
            }
            <Button loading={fetching} onClick={this.handleFetchCompanyInfo} type="primary" className={styles['extra-btn']}>查询</Button>
          </FormItem>
          <FormItem label="企业编号">
            {memberCenter.memberInfo?.companyNo}
          </FormItem>
          <FormItem label="认证手机号码">
            {memberCenter.memberInfo?.companyPhone}
          </FormItem>
          <FormItem label="新企业名称">
            {
              getFieldDecorator('companyName', {
                // trigger: ['onChange', 'onBlur'],
                rules: [
                  {
                    required: true,
                    message: '请输入新企业名称',
                  },
                  {
                    validator: (_, value, callback) => {
                      const oldCompanyName = form.getFieldValue('oldCompanyName');
                      switch (true) {
                        case !value:
                          callback('请输入新企业名称');
                          break;

                        case oldCompanyName && oldCompanyName === value:
                          callback('您输入的新企业名称和原企业名称一样，请确认后再提交');
                          break;

                        default:
                          reaction.memberCenter.getMemberInfo(value, false)
                          .then(res => {
                            if (res.data) {
                              callback('该企业名称已存在，请重新输入');
                            } else {
                              callback();
                            }
                          })
                          .catch(res => {
                            callback()
                          });
                          break;
                      }
                    }
                  }
                ],
              })(
                <Input className={styles.input} placeholder="请输入新企业名称" maxLength={50} />
              )
            }
          </FormItem>
          <FormItem label="修改原因">
            {
              getFieldDecorator('modifyReason', {
                rules: [
                  {
                    required: true,
                    message: '请输入修改原因'
                  }
                ]
              })(
                <Input.TextArea className={styles['text-area']} rows={5} placeholder="请输入修改原因" maxLength={500} />
              )
            }
          </FormItem>
          <FormItem label="上传附件">
            {
              getFieldDecorator('certificates', {
                getValueFromEvent: normFile,
              })(
                <UploadWithVisible
                  name="file"
                  action={env.getUploadApi('/v1/cert/upload')}
                  listType="picture-card"
                >
                </UploadWithVisible>
              )
            }
          </FormItem>
          <FormItem label=" " colon={false}>
            <Button loading={isSubmitting} onClick={this.handleSubmit} type="primary" size="large" style={{ width: 200 }}>提交</Button>
          </FormItem>
        </Form>
      </div>
    )
  }
}

export default ModifyCompany;