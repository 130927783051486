import React from 'react';
import { connect } from 'react-redux';
import { Form, Select, Input, Button, message, TreeSelect } from 'antd';
import reaction from 'reaction';
import { HttpCode, RoutePath } from 'utils/constants';
import { historyPush } from 'utils/history';
import { parseMenu, mapTree, loopTree } from 'utils';

import VirtualTree from 'components/Tree/VirtualTree';

import commonStyles from 'style/common.module.scss';
const FormItem = Form.Item;
const Option = Select.Option;
/**
 * 用户角色新增
 */
@Form.create()
@connect(({ common, me }) => ({ common, me }))
class RoleAdd extends React.Component {
  componentDidMount() {
    reaction.common.getAuthDict();
  }
  getMenuId(authList = []) {
    const { authTree } = this.props.me;
    let idsCache1 = {};
    loopTree(authTree, (obj) => {
      idsCache1[obj.authCode] = obj;
    });
    let idsCache = {};
    authList.forEach((code) => {
      let obj = idsCache1[code];
      if (!obj.children) {
        // leaf
        let parentObj = idsCache1[obj.parentId];
        let indexObj = parentObj.children[0];
        if (indexObj) {
          idsCache[indexObj.authCode] = true;
        }
      }

      while (obj) {
        idsCache[obj.authCode] = true;
        obj = idsCache1[obj.parentId];
      }
    });

    authList = Object.keys(idsCache);
    return authList;
  }
  getLeafMenuId(authList) {
    const { authTree } = this.props.me;
    let idsCache1 = {};
    loopTree(authTree, (obj) => {
      idsCache1[obj.authCode] = obj;
    });
    let idsCache = {};
    authList.forEach((code) => {
      let obj = idsCache1[code];
      if (!obj.children) {
        // leaf
        idsCache[obj.authCode] = true;
        let parentObj = idsCache1[obj.parentId];
        let indexObj = parentObj.children[0];
        if (indexObj) {
          idsCache[indexObj.authCode] = true;
        }
      }
    });

    authList = Object.keys(idsCache);
    return authList;
  }
  getValueFromEvent = (value) => {
    return this.getLeafMenuId(value);
  };
  handleSelectChange = () => {};
  handleSubmit = (e) => {
    e && e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let { roleName, description, authCodeList } = values;
        // authList = this.getMenuId(authList);

        reaction.role
          .create(roleName, description, authCodeList || [])
          .then((data) => {
            if (data.code === HttpCode.OK) {
              message.success('添加成功！');
              reaction.common.getRoleDict(); // 刷新角色字典
              historyPush(`/${RoutePath.SYSTEM}/${RoutePath.ROLE}`);
            }
          });
      }
    });
  };

  handleGoBack = () => {
    historyPush(`/${RoutePath.SYSTEM}/${RoutePath.ROLE}`);
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { authDict } = this.props.common;
    // const { authTree } = this.props.me;

    // let {children:treeData} = mapTree(authTree, (item)=>({value: String(item.authCode), label: authDict[item.authCode], key: String(item.authCode)}));
    return (
      <div>
        <h2 className={commonStyles.row}>角色新增</h2>
        <Form onSubmit={this.handleSubmit} className={commonStyles.row}>
          <FormItem
            label="用户角色"
            labelCol={{ span: 8 }}
            wrapperCol={{ sm: 8, xs: 24 }}
          >
            {getFieldDecorator('roleName', {
              validateTrigger: ['onBlur', 'onChange'],
              rules: [
                {
                  required: true,
                  whitespace: true,
                  message: '请输入角色名称!',
                },
                {
                  type: 'string',
                  pattern: /^\S.{0,30}\S$/,
                  message: '角色名称长度为2到32位!',
                },
              ],
            })(<Input placeholder="角色名称" />)}
          </FormItem>
          <FormItem
            label="角色说明"
            labelCol={{ span: 8 }}
            wrapperCol={{ sm: 8, xs: 24 }}
          >
            {getFieldDecorator('description', {
              rules: [
                {
                  type: 'string',
                  max: 128,
                  message: '角色说明长度限制为128位以内!',
                },
              ],
            })(
              <Input
                type="textarea"
                placeholder="角色说明"
                autosize={{ minRows: 6 }}
              />
            )}
          </FormItem>
          <FormItem
            label="功能权限"
            labelCol={{ span: 8 }}
            wrapperCol={{ sm: 8, xs: 24 }}
          >
            {getFieldDecorator('authCodeList', {
              onChange: this.handleSelectChange,
            })(<VirtualTree virtual={{ itemHeight: 28, scrollHeight: 400 }} nodes={authDict} labelProps="fullName" valueProps="authCode" />)}
          </FormItem>
          <FormItem
            wrapperCol={{ sm: { span: 8, offset: 8 }, xs: { span: 8 } }}
          >
            <Button type="primary" htmlType="submit">
              确定
            </Button>
            <Button style={{ marginLeft: 40 }} onClick={this.handleGoBack}>返回</Button>
          </FormItem>
        </Form>
      </div>
    );
  }
}

export default RoleAdd;
