import React from 'react';
import {
  Form,
  Input,
  Button,
  Radio,
  message,
  Select,
} from 'antd';
import reaction from 'reaction';
import { connect } from 'react-redux';
import { historyPush } from 'utils/history';
import { RoutePath } from 'utils/constants';

let FormItem = Form.Item;
let RadioGroup = Radio.Group;
const Option = Select.Option;

/**
 * 新增注册账户
 */
@Form.create()
@connect(({ memberCenter, me, common, frontUser }) => ({ memberCenter, me, common, frontUser }))
class CreateRegisterAccount extends React.Component {
  state = {
    companyCharacter: [],
  };

  compareToNextPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && form.getFieldValue('confirmPassword')) {
      form.validateFields(['confirmPassword'], { force: true });
    }
    callback();
  };
  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue('password')) {
      callback('两次输入的密码不一致');
    } else {
      callback();
    }
  };

  clear = () => {
    ['username', 'phone', 'password', 'confirmPassword', 'email'].forEach(
      (key) =>
        this.props.form.setFieldsValue({
          [key]: '',
        })
    );
  };

  handleSubmit = (e) => {
    e && e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        for (let key in values) {
          if (values[key] === '') {
            values[key] = null;
          }
        }

        reaction.frontUser.createUser(values).then((res) => {
          if (res.code === 0) {
            message.success('新增用户成功!');
            this.clear();
            historyPush(`/${RoutePath.FRONT_USER}`);
          }
        });
      }
    });
  };
  getIdentityList = () => {
    reaction.memberCenter.getIdentifyDictList();
  };

  getCompanyCharacter = (v) => {
    reaction.memberCenter.getCompanyCharacter(v);
  };

  getBusinessCharacter = () => {
    reaction.memberCenter.getBusinessCharacter();
  };

  validateField = (field, { username, certName, phone }) => {
    this.validateIsUserExist({ username, certName, phone }).then((res) => {
      if (res.data) {
        this.props.form.setFields({
          [field]: {
            value: certName || phone || username,
            errors: [new Error('用户已经存在!')],
          },
        });
      }
      return res;
    });
  };

  validateIsUserExist = ({ username, certName, phone }) => {
    return reaction.memberCenter.validateIsUserExist({
      username,
      certName,
      phone,
    });
  };

  validateIsIdentityNoExist = ({ idCardNo }) => {
    return reaction.memberCenter.validateIsIdentityNoExist({
      idCardNo,
    });
  };

  handleDepartmentChange = (_, { props }) => {
    reaction.frontUser.getDepartmentTeamList({
      parentId: props.id,
    });
    this.setFieldsValue('team', '');
  }

  hasSelectDepartment() {
    return !!this.props.form.getFieldValue('department');
  }

  setFieldsValue(name, value) {
    this.props.form.setFieldsValue({
      [name]: value,
    })
  }

  componentDidMount() {
    this.getIdentityList();
    this.getBusinessCharacter();
  }
  getPopupContainer = (triggerNode) => {
    return document.getElementById('createRegisterAccount');
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    let { platforms = [] } = this.props.common;
    // let vehicleType = this.props.me.vehicleType;
    const { departments = [] } = this.props.me;
    const { teamList = [], teamListLoading } = this.props.frontUser;
    // let userCertInfo = this.state.oldAccountInfo;
    let formItemLayout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 20 },
    };

    return (
      <div id="createRegisterAccount" style={{ position: 'relative' }}>
        <Form onSubmit={this.handleSubmit} className="login-form">
          <FormItem {...formItemLayout} label="用户名">
            {getFieldDecorator('username', {
              validateTrigger: ['onBlur', 'onChange'],
              rules: [
                {
                  required: true,
                  message: '支持中文、字母、数字、"_"、"-"的组合，2至20个字符',
                  min: 4,
                  max: 20,
                  pattern: /^[-a-zA-Z_0-9\u4e00-\u9fa5]{2,20}$/,
                },
                {
                  required: false,
                  message: '不能为纯数字',
                  pattern: /[^\d]/,
                },
                {
                  required: true,
                  validator: (rule, value = '', callback) => {
                    this.validateIsUserExist({
                      username: value.trim(),
                    }).then((res) => {
                      if (res.data) {
                        callback(new Error('用户已存在!'));
                      } else {
                        callback();
                      }
                    });
                  },
                },
              ],
            })(
              <Input
                maxLength={20}
                style={{ width: 380 }}
                placeholder="用户名/登录名"
              />
            )}
          </FormItem>
          <FormItem {...formItemLayout} label="手机号">
            {getFieldDecorator('phone', {
              validateTrigger: ['onBlur', 'onChange'],
              rules: [
                {
                  required: true,
                  max: 11,
                  pattern: /^1[0-9]{10}$/,
                  validator: (rule, value, callback) => {
                    if (!value || !rule.pattern.test(value)) {
                      callback('手机号不符合规则');
                      return;
                    }
                    this.validateIsUserExist({ phone: value }).then((res) => {
                      if (res.data) {
                        callback(new Error('手机号已存在!'));
                      } else {
                        callback();
                      }
                    });
                  },
                },
              ],
            })(<Input style={{ width: 380 }} placeholder="手机号" />)}
          </FormItem>
          <FormItem
            {...formItemLayout}
            label="设置密码"
            extra={
              <span style={{ color: 'red', fontSize: 14 }}>
                系统默认初始密码：Zlgx888，可修改。
              </span>
            }
          >
            {getFieldDecorator('password', {
              validateTrigger: ['onBlur', 'onChange'],
              rules: [
                {
                  required: true,
                  message: '支持字母、数字的组合，6至20位字符，区分大小写',
                  max: 12,
                  pattern: /^(?:(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])).{6,12}$/,
                },
                { validator: this.compareToNextPassword },
              ],
              initialValue: 'Zlgx888',
            })(
              <Input
                style={{ width: 380 }}
                placeholder="支持字母、数字的组合，6至20位字符，区分大小写"
              />
            )}
          </FormItem>
          <FormItem {...formItemLayout} label="确认密码">
            {getFieldDecorator('confirmPassword', {
              validateTrigger: ['onBlur', 'onChange'],
              rules: [
                {
                  required: true,
                  message: '支持字母、数字的组合，6至20位字符，区分大小写',
                  max: 12,
                  pattern: /^(?:(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])).{6,12}$/,
                },
                { validator: this.compareToFirstPassword },
              ],
              initialValue: 'Zlgx888',
            })(
              <Input
                style={{ width: 380 }}
                placeholder="支持字母、数字的组合，6至20位字符，区分大小写"
              />
            )}
          </FormItem>
          <FormItem {...formItemLayout} label="邮箱地址">
            {getFieldDecorator('email', {
              rules: [
                {
                  required: false,
                  message: '请输入正确格式的邮箱',
                  max: 32,
                  pattern: /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+\.[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)?$/,
                },
              ],
            })(
              <Input maxLength={32} style={{ width: 380 }} placeholder="邮箱" />
            )}
          </FormItem>
          <FormItem {...formItemLayout} label="领域">
            {getFieldDecorator('appDomain', {
              rules: [{ required: true, message: '请选择领域' }],
              initialValue: 'common',
            })(
              <Select
                getPopupContainer={this.getPopupContainer}
                style={{ width: 380 }}
              >
                {platforms.map((item) => {
                  return (
                    <Option key={item.appDomain} value={item.appDomain}>
                      {item.appDomainName}
                    </Option>
                  );
                })}
              </Select>
            )}
          </FormItem>

          <FormItem {...formItemLayout} label="责任部门">
            {getFieldDecorator('department', {
              rules: [{ required: false, message: '请选择责任部门' }],
              initialValue: ''
            })(
              <Select
                getPopupContainer={this.getPopupContainer}
                style={{ width: 380 }}
                placeholder="请选择责任部门"
                onChange={this.handleDepartmentChange}
              >
                {departments.map((item) => {
                  return (
                    <Option key={item.code} value={item.code || ''} id={item.id}>
                      {item.name}
                    </Option>
                  );
                })}
              </Select>
            )}
          </FormItem>
          <FormItem {...formItemLayout} label="小组">
            {getFieldDecorator('team', {
              validateTrigger: ['onBlur', 'onChange'],
              normalize: value => value || '',
            })(
              <Select style={{ width: 380 }} placeholder="请选择小组" getPopupContainer={this.getPopupContainer} loading={teamListLoading} disabled={!this.hasSelectDepartment()}>
                {teamList.map((item) => {
                  return (
                    <Option key={item.code} value={item.code}>
                      {item.name}
                    </Option>
                  );
                })}
              </Select>
            )}
          </FormItem>
          <FormItem {...formItemLayout} label="业务员">
            {getFieldDecorator('salesman', {
              rules: [{ required: false, message: '最长5个字符', max: 5 }],
            })(
              <Input
                maxLength={5}
                style={{ width: 380 }}
                placeholder="请输入业务员"
              />
            )}
          </FormItem>
          <FormItem {...formItemLayout} label="内部账户">
            {getFieldDecorator('isInternalUser', {
              initialValue: false,
            })(
              <RadioGroup>
                <Radio value={true}>是</Radio>
                <Radio value={false}>否</Radio>
              </RadioGroup>
            )}
          </FormItem>
          <FormItem {...formItemLayout} label="用户状态">
            {getFieldDecorator('isActive', {
              initialValue: true,
            })(
              <RadioGroup>
                <Radio value={true}>正常</Radio>
                <Radio value={false}>禁用</Radio>
              </RadioGroup>
            )}
          </FormItem>
          <FormItem wrapperCol={{ span: 8, offset: 2 }}>
            <Button type="primary" htmlType="submit">
              提交
            </Button>
          </FormItem>
        </Form>
      </div>
    );
  }
}

export default CreateRegisterAccount;
