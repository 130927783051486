import React from 'react';
import { connect } from 'react-redux';
import reaction from 'reaction';
import { Table, Button, Radio, message, Checkbox, Tag, Input } from 'antd';
import { historyPush } from 'utils/history';
import { RoutePath, AuthCodes } from 'utils/constants';
import { resolveUrlQuery, checkHasDriverIdentity } from 'utils';
import MagnifyEnableImg from 'components/MagnifyEnableImg';
import unuploadImg from 'assets/unupload.png';
import dayjs from 'dayjs';

import commonStyles from 'style/common.module.scss';

const { TextArea } = Input;
@connect(({ qualification }) => ({ qualification }))
class CheckPersonalIdentityHistory extends React.Component {
  state = {};

  getCheckPersonalIdentityHistoryInfo = () => {
    let { userId, auditNo } = resolveUrlQuery(this.props.location.search);
    reaction.qualification.getCheckPersonalIdentityHistoryInfo(userId, auditNo);
  };

  back = () => {
    historyPush(`/${RoutePath.MEMBERCENTER}/${RoutePath.CHECK_IDENTIFY}`, {
      ...resolveUrlQuery(this.props.location.search),
      auditNo: undefined,
      userId: undefined,
    });
  };

  componentDidMount() {
    this.getCheckPersonalIdentityHistoryInfo();
  }

  render() {
    let personalIdentityInfo = this.props.qualification
      .checkPersonalIdentityHistoryInfo;
    let list = personalIdentityInfo.existIdentity;
    let { auditNo } = resolveUrlQuery(this.props.location.search);
    let columns = [
      {
        title: '序号',
        dataIndex: 'number',
      },
      {
        title: '平台',
        dataIndex: 'platform',
      },
      {
        title: '身份',
        dataIndex: 'identity',
      },
    ];
    const hasDriverIdentity = personalIdentityInfo.applyIdentity && checkHasDriverIdentity([personalIdentityInfo.applyIdentity.code])
    return (
      <div>
        <h2 className={commonStyles.row}>身份审核</h2>
        <div style={{ overflow: 'hidden' }}>
          <div style={{ float: 'left', width: 100 }}></div>
          <div style={{ marginLeft: 100 }}>
            <div style={{ marginBottom: 30 }}>
              <label
                style={{
                  width: '16.6666666%',
                  textAlign: 'right',
                  display: 'inline-block',
                }}
              >
                申请单号:
              </label>
              <span>{auditNo}</span>
            </div>
          </div>
        </div>
        <div style={{ overflow: 'hidden' }}>
          <div style={{ float: 'left', width: 100 }}>账户信息</div>
          <div style={{ marginLeft: 100 }}>
            <div style={{ marginBottom: 30 }}>
              <label
                style={{
                  width: '16.6666666%',
                  textAlign: 'right',
                  display: 'inline-block',
                }}
              >
                会员编号:
              </label>
              <span>{personalIdentityInfo.user.userNo}</span>
            </div>
            <div style={{ marginBottom: 30 }}>
              <label
                style={{
                  width: '16.6666666%',
                  textAlign: 'right',
                  display: 'inline-block',
                }}
              >
                用户名:
              </label>
              <span>{personalIdentityInfo.user.username}</span>
            </div>
            <div style={{ marginBottom: 30 }}>
              <label
                style={{
                  width: '16.6666666%',
                  textAlign: 'right',
                  display: 'inline-block',
                }}
              >
                安全手机:
              </label>
              <span>{personalIdentityInfo.user.phone}</span>
            </div>
          </div>
        </div>
        <div style={{ overflow: 'hidden' }}>
          <div style={{ float: 'left', width: 100, fontWeight: 'bold' }}>
            已有身份
          </div>
          <div style={{ marginLeft: 100 }}>
            <div style={{ marginBottom: 30 }}>
              {list && (
                <Table
                  className={commonStyles.row}
                  rowKey="number"
                  columns={columns}
                  pagination={false}
                  dataSource={list}
                />
              )}
            </div>
          </div>
        </div>
        <div style={{ overflow: 'hidden' }}>
          <div style={{ float: 'left', width: 100 }}>申请身份:</div>
          <div style={{ marginLeft: 100 }}>
            <div style={{ marginBottom: 30 }}>
              {/*<label style={{width: '16.6666666%', textAlign: 'right',display: 'inline-block'}}></label>*/}
              <Tag color="magenta">
                {personalIdentityInfo.applyIdentity
                  ? personalIdentityInfo.applyIdentity.name ||
                    personalIdentityInfo.applyIdentity
                  : null || '物流-托运'}
              </Tag>
            </div>
          </div>
        </div>
        <div style={{ overflow: 'hidden' }}>
          <div style={{ float: 'left', width: 100 }}>其他信息:</div>
          <div style={{ marginLeft: 100 }}>
            {/* <div style={{marginBottom: 30}}>
            <label style={{width: '16.6666666%', textAlign: 'right',display: 'inline-block'}}>车牌号:</label>
            <span>{ personalIdentityInfo.additionalCert && personalIdentityInfo.additionalCert.vehicleInfo && personalIdentityInfo.additionalCert.vehicleInfo.licensePlateNo }</span>
          </div>
          <div style={{marginBottom: 30}}>
            <label style={{width: '16.6666666%', textAlign: 'right',display: 'inline-block'}}>车辆类型:</label>
            <span>{ personalIdentityInfo.additionalCert && personalIdentityInfo.additionalCert.vehicleInfo && personalIdentityInfo.additionalCert.vehicleInfo.vehicleType }</span>
          </div>
          <div style={{marginBottom: 30}}>
            <label style={{width: '16.6666666%', textAlign: 'right',display: 'inline-block'}}>车辆规格:</label>
            <span>{ personalIdentityInfo.additionalCert && personalIdentityInfo.additionalCert.vehicleInfo && personalIdentityInfo.additionalCert.vehicleInfo.vehicleSpecification }</span>
          </div>
          <div style={{marginBottom: 30}}>
            <label style={{width: '16.6666666%', textAlign: 'right',display: 'inline-block'}}>标准载重:</label>
            <span>{ personalIdentityInfo.additionalCert && personalIdentityInfo.additionalCert.vehicleInfo && personalIdentityInfo.additionalCert.vehicleInfo.loadCapacity }</span>
          </div> */}
            <div style={{ marginBottom: 30 }}>
              <label
                style={{
                  width: '16.6666666%',
                  textAlign: 'right',
                  display: 'inline-block',
                }}
              >
                身份证正面:
              </label>
              {personalIdentityInfo.individualIdentityCard &&
              personalIdentityInfo.individualIdentityCard
                .identityCardFrontUrl &&
              personalIdentityInfo.individualIdentityCard.identityCardFrontUrl
                .url &&
              personalIdentityInfo.individualIdentityCard.identityCardFrontUrl
                .url ? (
                <MagnifyEnableImg
                  imgUrl={
                    personalIdentityInfo.individualIdentityCard
                      .identityCardFrontUrl.url
                  }
                />
              ) : (
                <img
                  style={{ width: 80, height: 80, marginLeft: 15 }}
                  src={unuploadImg}
                  alt=""
                />
              )}
            </div>
            <div style={{ marginBottom: 30 }}>
              <label
                style={{
                  width: '16.6666666%',
                  textAlign: 'right',
                  display: 'inline-block',
                }}
              >
                身份证背面:
              </label>
              {personalIdentityInfo.individualIdentityCard &&
              personalIdentityInfo.individualIdentityCard.identityCardBackUrl &&
              personalIdentityInfo.individualIdentityCard.identityCardBackUrl
                .url &&
              personalIdentityInfo.individualIdentityCard.identityCardBackUrl
                .url ? (
                <MagnifyEnableImg
                  imgUrl={
                    personalIdentityInfo.individualIdentityCard
                      .identityCardBackUrl.url
                  }
                />
              ) : (
                <img
                  style={{ width: 80, height: 80, marginLeft: 15 }}
                  src={unuploadImg}
                  alt=""
                />
              )}
            </div>
            
            {
              hasDriverIdentity ? (
                <React.Fragment>
                  <div style={{ marginBottom: 30 }}>
                    <label
                      style={{
                        width: '16.6666666%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      行驶证:
                    </label>
                    {personalIdentityInfo.additionalCert &&
                    personalIdentityInfo.additionalCert.vehicleInfo &&
                    personalIdentityInfo.additionalCert.vehicleInfo
                      .runningLicenseUrl &&
                    personalIdentityInfo.additionalCert.vehicleInfo.runningLicenseUrl
                      .url ? (
                      <MagnifyEnableImg
                        imgUrl={
                          personalIdentityInfo.additionalCert.vehicleInfo
                            .runningLicenseUrl.url
                        }
                      />
                    ) : (
                      <img
                        style={{ width: 80, height: 80 }}
                        src={unuploadImg}
                        alt=""
                      />
                    )}
                  </div>
                  <div style={{ marginBottom: 30 }}>
                    <label
                      style={{
                        width: '16.6666666%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      驾驶证:
                    </label>
                    {personalIdentityInfo.additionalCert &&
                    personalIdentityInfo.additionalCert.vehicleInfo &&
                    personalIdentityInfo.additionalCert.vehicleInfo
                      .drivingLicenseUrl &&
                    personalIdentityInfo.additionalCert.vehicleInfo.drivingLicenseUrl
                      .url ? (
                      <MagnifyEnableImg
                        imgUrl={
                          personalIdentityInfo.additionalCert.vehicleInfo
                            .drivingLicenseUrl.url
                        }
                      />
                    ) : (
                      <img
                        style={{ width: 80, height: 80 }}
                        src={unuploadImg}
                        alt=""
                      />
                    )}
                  </div>
                </React.Fragment>
              ) : null
            }
          </div>
        </div>
        <div style={{ overflow: 'hidden' }}>
          <div style={{ float: 'left', width: 100 }}>审核时间:</div>
          <div style={{ marginLeft: 100 }}>
            <div style={{ marginBottom: 30 }}>
              {/*<label style={{width: '16.6666666%', textAlign: 'right',display: 'inline-block'}}></label>*/}
              <span>
                {personalIdentityInfo.auditResult &&
                  personalIdentityInfo.auditResult.auditTime &&
                  dayjs(personalIdentityInfo.auditResult.auditTime).format(
                    'YYYY-MM-DD HH:mm:ss'
                  )}
              </span>
            </div>
          </div>
        </div>
        <div style={{ overflow: 'hidden' }}>
          <div style={{ float: 'left', width: 100 }}>审核结果:</div>
          <div style={{ marginLeft: 100 }}>
            <div style={{ marginBottom: 30 }}>
              {/*<label style={{width: '16.6666666%', textAlign: 'right',display: 'inline-block'}}></label>*/}
              <span>
                {personalIdentityInfo.auditResult &&
                personalIdentityInfo.auditResult.auditResult
                  ? '审核通过'
                  : '审核拒绝'}
              </span>
            </div>
          </div>
        </div>
        <div style={{ overflow: 'hidden' }}>
          <div style={{ float: 'left', width: 100 }}>审核人:</div>
          <div style={{ marginLeft: 100 }}>
            <div style={{ marginBottom: 30 }}>
              {/*<label style={{width: '16.6666666%', textAlign: 'right',display: 'inline-block'}}></label>*/}
              <span>
                {personalIdentityInfo.auditResult &&
                  personalIdentityInfo.auditResult.auditor}
              </span>
            </div>
          </div>
        </div>
        <div style={{ overflow: 'hidden' }}>
          <div style={{ float: 'left', width: 100 }}>审核意见:</div>
          <div style={{ marginLeft: 100 }}>
            {
              (personalIdentityInfo.auditResult && personalIdentityInfo.auditResult.auditOpinion) ? (
                <Tag color={(personalIdentityInfo.auditResult && personalIdentityInfo.auditResult.auditResult) ? 'green' : 'red'}>
                  {personalIdentityInfo.auditResult.auditOpinion}
                </Tag>
              ) : null
            }
          </div>
        </div>
        <div style={{ padding: '30px 0 20px 100px' }}>
          <Button onClick={this.back} style={{ marginLeft: 10 }}>
            返回
          </Button>
        </div>
      </div>
    );
  }
}

export default CheckPersonalIdentityHistory;
