import React from 'react';
import { connect } from 'react-redux';
import reaction from 'reaction';
import { Table, Button, Radio, message, Checkbox, Tag, Input } from 'antd';
import { historyPush } from 'utils/history';
import { resolveUrlQuery } from 'utils';
import { RoutePath, AuthCodes } from 'utils/constants';
import unuploadImg from 'assets/unupload.png';
import MagnifyEnableImg from 'components/MagnifyEnableImg';

import commonStyles from 'style/common.module.scss';
import { REJECT_REASON_MAX_LENGTH } from 'config';

const { TextArea } = Input;
@connect(({ qualification, me }) => ({ qualification, me }))
class CheckPersonalIdentity extends React.Component {
  state = {
    opinion: '',
  };

  getPersonalIdentityInfo = () => {
    let userId = resolveUrlQuery(this.props.location.search).userId;
    let auditNo = resolveUrlQuery(this.props.location.search).auditNo;
    reaction.qualification.getPersonalIdentityInfo({ userId, auditNo });
  };

  onOpinionChange = (v) => {
    this.setState({
      opinion: v,
    });
  };

  back = () => {
    historyPush(`/${RoutePath.MEMBERCENTER}/${RoutePath.CHECK_IDENTIFY}`, {
      ...resolveUrlQuery(this.props.location.search),
      auditNo: undefined,
      userId: undefined,
    });
  };

  getApplyIdentityName(personalIdentityInfo) {
    return (personalIdentityInfo &&
      personalIdentityInfo.applyIdentity &&
      personalIdentityInfo.applyIdentity.name) ||
      '物流-托运'
  }

  pass = () => {
    if (!this.state.opinion) {
      message.warning('请输入审核意见再提交!');
      return;
    }
    let userId = resolveUrlQuery(this.props.location.search).userId;
    let auditNo = resolveUrlQuery(this.props.location.search).auditNo;
    reaction.qualification
      .passPersonalIdentity({
        userId: userId,
        auditNo: auditNo,
        identity: this.getApplyIdentityName(this.props.qualification.personalIdentityInfo),
        auditResult: {
          auditOpinion: this.state.opinion,
          additionalIdentity: [],
          auditPass: true,
        },
      })
      .then((res) => {
        if (res.code === 0) {
          message.success('操作成功!');
          historyPush(
            `/${RoutePath.MEMBERCENTER}/${RoutePath.CHECK_IDENTIFY}`,
            {
              ...resolveUrlQuery(this.props.location.search),
              auditNo: undefined,
              userId: undefined,
            }
          );
        }
      });
  };

  refuse = () => {
    if (!this.state.opinion) {
      message.warning('请输入审核意见再提交!');
      return;
    }
    let userId = resolveUrlQuery(this.props.location.search).userId;
    let auditNo = resolveUrlQuery(this.props.location.search).auditNo;
    reaction.qualification
      .refusePersonalIdentity({
        userId: userId,
        auditNo: auditNo,
        identity: this.getApplyIdentityName(this.props.qualification.personalIdentityInfo),
        auditResult: {
          auditOpinion: this.state.opinion,
          additionalIdentity: [],
          auditPass: false,
        },
      })
      .then((res) => {
        if (res.code === 0) {
          message.success('审核拒绝成功');
          historyPush(
            `/${RoutePath.MEMBERCENTER}/${RoutePath.CHECK_IDENTIFY}`,
            {
              ...resolveUrlQuery(this.props.location.search),
              auditNo: undefined,
              userId: undefined,
            }
          );
        }
      });
  };

  componentDidMount() {
    this.getPersonalIdentityInfo();
  }

  render() {
    let personalIdentityInfo = this.props.qualification.personalIdentityInfo;
    let list = personalIdentityInfo.existIdentity;
    let vehicleType = this.props.me.vehicleType;
    let auditNo = resolveUrlQuery(this.props.location.search).auditNo;
    let columns = [
      {
        title: '序号',
        dataIndex: 'number',
      },
      {
        title: '平台',
        dataIndex: 'platform',
      },
      {
        title: '身份',
        dataIndex: 'identity',
      },
    ];
    return (
      <div>
        <h2 className={commonStyles.row}>身份审核</h2>
        <div style={{ overflow: 'hidden' }}>
          <div style={{ float: 'left', width: 100 }}></div>
          <div style={{ marginLeft: 100 }}>
            <div style={{ marginBottom: 30 }}>
              <label
                style={{
                  width: '16.6666666%',
                  textAlign: 'right',
                  display: 'inline-block',
                }}
              >
                申请单号:
              </label>
              <span>{auditNo}</span>
            </div>
          </div>
        </div>
        <div style={{ overflow: 'hidden' }}>
          <div style={{ float: 'left', width: 100 }}>账户信息</div>
          <div style={{ marginLeft: 100 }}>
            <div style={{ marginBottom: 30 }}>
              <label
                style={{
                  width: '16.6666666%',
                  textAlign: 'right',
                  display: 'inline-block',
                }}
              >
                会员编号:
              </label>
              <span>
                {personalIdentityInfo && personalIdentityInfo.user.userNo}
              </span>
            </div>
            <div style={{ marginBottom: 30 }}>
              <label
                style={{
                  width: '16.6666666%',
                  textAlign: 'right',
                  display: 'inline-block',
                }}
              >
                用户名:
              </label>
              <span>
                {personalIdentityInfo && personalIdentityInfo.user.username}
              </span>
            </div>
            <div style={{ marginBottom: 30 }}>
              <label
                style={{
                  width: '16.6666666%',
                  textAlign: 'right',
                  display: 'inline-block',
                }}
              >
                安全手机:
              </label>
              <span>
                {personalIdentityInfo && personalIdentityInfo.user.phone}
              </span>
            </div>
          </div>
        </div>
        <div style={{ overflow: 'hidden' }}>
          <div style={{ float: 'left', width: 100, fontWeight: 'bold' }}>
            已有身份
          </div>
          <div style={{ marginLeft: 100 }}>
            <div style={{ marginBottom: 30 }}>
              {list && (
                <Table
                  className={commonStyles.row}
                  rowKey="number"
                  columns={columns}
                  pagination={false}
                  dataSource={list}
                />
              )}
            </div>
          </div>
        </div>
        <div style={{ overflow: 'hidden' }}>
          <div style={{ float: 'left', width: 100 }}>申请身份:</div>
          <div style={{ marginLeft: 100 }}>
            <div style={{ marginBottom: 30 }}>
              <Tag color="magenta">
                {this.getApplyIdentityName(personalIdentityInfo)}
              </Tag>
            </div>
          </div>
        </div>
        <div style={{ overflow: 'hidden' }}>
          <div style={{ float: 'left', width: 100 }}>其他信息:</div>
          <div style={{ marginLeft: 100 }}>
            {/* <div style={{marginBottom: 30}}>
            <label style={{width: '16.6666666%', textAlign: 'right',display: 'inline-block'}}>车牌号:</label>
            <span>{ personalIdentityInfo && personalIdentityInfo.additionalCert && personalIdentityInfo.additionalCert.vehicleInfo.licensePlateNo }</span>
          </div>
          <div style={{marginBottom: 30}}>
            <label style={{width: '16.6666666%', textAlign: 'right',display: 'inline-block'}}>车辆类型:</label>
            <span>{
              personalIdentityInfo &&
              personalIdentityInfo.additionalCert &&
              personalIdentityInfo.additionalCert.vehicleInfo.vehicleType &&
              vehicleType.filter(item => item.code === personalIdentityInfo.additionalCert.vehicleInfo.vehicleType)[0] &&
              vehicleType.filter(item => item.code === personalIdentityInfo.additionalCert.vehicleInfo.vehicleType)[0].name }
            </span>
          </div>
          <div style={{marginBottom: 30}}>
            <label style={{width: '16.6666666%', textAlign: 'right',display: 'inline-block'}}>车辆规格:</label>
            <span>{ personalIdentityInfo && personalIdentityInfo.additionalCert && personalIdentityInfo.additionalCert.vehicleInfo.vehicleSpecification }</span>
          </div>
          <div style={{marginBottom: 30}}>
            <label style={{width: '16.6666666%', textAlign: 'right',display: 'inline-block'}}>标准载重:</label>
            <span>{ personalIdentityInfo && personalIdentityInfo.additionalCert && personalIdentityInfo.additionalCert.vehicleInfo.loadCapacity }</span>
          </div> */}
            <div style={{ marginBottom: 30 }}>
              <label
                style={{
                  width: '16.6666666%',
                  textAlign: 'right',
                  display: 'inline-block',
                }}
              >
                身份证正面:
              </label>
              {personalIdentityInfo.attachment &&
              personalIdentityInfo.attachment.identityCardFrontUrl &&
              personalIdentityInfo.attachment.identityCardFrontUrl.url &&
              personalIdentityInfo.attachment.identityCardFrontUrl.url ? (
                <MagnifyEnableImg
                  imgUrl={
                    personalIdentityInfo.attachment.identityCardFrontUrl.url
                  }
                />
              ) : (
                <img
                  style={{ width: 80, height: 80, marginLeft: 15 }}
                  src={unuploadImg}
                  alt=""
                />
              )}
            </div>
            <div style={{ marginBottom: 30 }}>
              <label
                style={{
                  width: '16.6666666%',
                  textAlign: 'right',
                  display: 'inline-block',
                }}
              >
                身份证背面:
              </label>
              {personalIdentityInfo.attachment &&
              personalIdentityInfo.attachment.identityCardBackUrl &&
              personalIdentityInfo.attachment.identityCardBackUrl.url &&
              personalIdentityInfo.attachment.identityCardBackUrl.url ? (
                <MagnifyEnableImg
                  imgUrl={
                    personalIdentityInfo.attachment.identityCardBackUrl.url
                  }
                />
              ) : (
                <img
                  style={{ width: 80, height: 80, marginLeft: 15 }}
                  src={unuploadImg}
                  alt=""
                />
              )}
            </div>
            <div style={{ marginBottom: 30 }}>
              <label
                style={{
                  width: '16.6666666%',
                  textAlign: 'right',
                  display: 'inline-block',
                }}
              >
                行驶证:
              </label>
              {personalIdentityInfo.additionalCert &&
              personalIdentityInfo.additionalCert.vehicleInfo &&
              personalIdentityInfo.additionalCert.vehicleInfo
                .runningLicenseUrl &&
              personalIdentityInfo.additionalCert.vehicleInfo.runningLicenseUrl
                .url ? (
                <MagnifyEnableImg
                  imgUrl={
                    personalIdentityInfo.additionalCert.vehicleInfo
                      .runningLicenseUrl.url
                  }
                />
              ) : (
                <img
                  style={{ width: 80, height: 80, marginLeft: 15 }}
                  src={unuploadImg}
                  alt=""
                />
              )}
            </div>
            <div style={{ marginBottom: 30 }}>
              <label
                style={{
                  width: '16.6666666%',
                  textAlign: 'right',
                  display: 'inline-block',
                }}
              >
                驾驶证:
              </label>
              {personalIdentityInfo.additionalCert &&
              personalIdentityInfo.additionalCert.vehicleInfo &&
              personalIdentityInfo.additionalCert.vehicleInfo
                .drivingLicenseUrl &&
              personalIdentityInfo.additionalCert.vehicleInfo.drivingLicenseUrl
                .url ? (
                <MagnifyEnableImg
                  imgUrl={
                    personalIdentityInfo.additionalCert.vehicleInfo
                      .drivingLicenseUrl.url
                  }
                />
              ) : (
                <img
                  style={{ width: 80, height: 80, marginLeft: 15 }}
                  src={unuploadImg}
                  alt=""
                />
              )}
            </div>
          </div>
        </div>
        <div style={{ overflow: 'hidden' }}>
          <div style={{ float: 'left', width: 100 }}>审核意见:</div>
          <div style={{ marginLeft: 100 }}>
            <TextArea
              style={{ width: 350 }}
              rows={4}
              maxLength={REJECT_REASON_MAX_LENGTH}
              placeholder={`${REJECT_REASON_MAX_LENGTH}位以内，不限字符类型`}
              onChange={(e) => {
                this.onOpinionChange(e.target.value);
              }}
            />
          </div>
        </div>
        <div style={{ padding: '30px 0 20px 100px' }}>
          <Button onClick={this.refuse}>审核拒绝</Button>
          <Button onClick={this.pass} style={{ marginLeft: 10 }}>
            审核通过
          </Button>
          <Button onClick={this.back} style={{ marginLeft: 10 }}>
            返回
          </Button>
        </div>
      </div>
    );
  }
}

export default CheckPersonalIdentity;
