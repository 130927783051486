import React from 'react';
import { connect } from 'react-redux';
import reaction from 'reaction/index';
import Immutable from 'immutable';
import dayjs from 'dayjs';
import commonStyles from 'style/common.module.scss';
import { Button, Input, message, Table } from 'antd';
import history from 'utils/history';
import { resolveUrlQuery } from 'utils';
import { RoutePath } from 'utils/constants';

const { TextArea } = Input;

@connect(({ DataDict }) => ({ DataDict }))
class ViewDataDict extends React.Component {
  state = {
    params: Immutable.Map({
      code: '',
      name: '',
      description: '',
    }),
    page: 1,
    length: 10,
    dictList: [],
  };

  getDictInfo = () => {
    const { id } = resolveUrlQuery(this.props.location.search);
    reaction.DataDict.getDictInfo(id).then((res) => {
      let { dictType, dictList } = this.props.DataDict;
      if (res.code === 0) {
        let { params } = this.state;
        params = params.set('code', dictType.code);
        params = params.set('name', dictType.name);
        params = params.set('description', dictType.description);
        this.setState({ params });
        this.setState({ dictList: dictList });
      }
    });
  };

  getLogList = () => {
    let id = resolveUrlQuery(this.props.location.search).id;
    let { page, length } = this.state;
    reaction.DataDict.getLogList(id, page, length);
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.setState({ page: pagination.current }, () => {
      this.getLogList();
    });
  };

  back = () => {
    history.push(
      `/${RoutePath.SYSTEM}/${RoutePath.DICT}/${RoutePath.DICT_LIST}`
    );
  };

  componentDidMount() {
    this.getDictInfo();
    this.getLogList();
  }

  render() {
    let { params, dictList } = this.state;
    let logList = this.props.DataDict.logList;
    let columns1 = [
      {
        title: '值名',
        dataIndex: 'name',
      },
      {
        title: '真实值',
        dataIndex: 'code',
      },
    ];

    let columns2 = [
      {
        width: 140,
        title: '操作人',
        dataIndex: 'username',
      },
      {
        width: 112,
        title: '操作时间',
        dataIndex: 'eventTime',
        render: (text) => {
          if (!text) {
            return '';
          }
          return dayjs(text - 0).format('YYYY-MM-DD HH:mm:ss');
        },
      },
      {
        title: '操作内容',
        dataIndex: 'content',
      },
    ];
    return (
      <div>
        <h3 style={{ overflow: 'hidden' }}>
          <span>查看数据字典</span>
        </h3>
        <div>
          <div style={{ padding: 10 }}>
            <span style={{ width: 50, display: 'inline-block' }}>类型：</span>
            <Input
              disabled={true}
              maxLength={32}
              value={params.toJS().name}
              style={{ width: '250px', marginLeft: '15px' }}
              placeholder="输入数据类型"
            />
          </div>
          <div style={{ padding: 10 }}>
            <span style={{ width: 50, display: 'inline-block' }}>code：</span>
            <Input
              disabled={true}
              maxLength={32}
              value={params.toJS().code}
              style={{ width: '250px', marginLeft: '15px' }}
              placeholder="输入code"
            />
          </div>
          <div style={{ padding: 10 }}>
            <span style={{ width: 50, display: 'inline-block', float: 'left' }}>
              描述：
            </span>
            <TextArea
              disabled={true}
              maxLength={120}
              value={params.toJS().description}
              rows={4}
              style={{ width: 250, marginLeft: 15 }}
            />
          </div>
          <div style={{ padding: 10 }}>
            <span style={{ width: 50, display: 'inline-block', float: 'left' }}>
              键值：
            </span>
            <div
              style={{ display: 'inline-block', width: 500, marginLeft: 15 }}
            >
              {dictList && (
                <Table
                  className={commonStyles.row}
                  rowKey="uid"
                  columns={columns1}
                  pagination={false}
                  dataSource={dictList}
                />
              )}
            </div>
          </div>
          <div style={{ padding: '20px 0 0 75px' }}>
            <Button onClick={this.back}>返回</Button>
          </div>
          <div style={{ padding: '20px 0 0 0' }}>
            {
              <Table
                className={commonStyles.row}
                rowKey="uid"
                columns={columns2}
                dataSource={logList ? logList.result : []}
                onChange={this.handleTableChange}
                pagination={
                  logList
                    ? {
                        current: logList.pagerInfo.page,
                        total: logList.pagerInfo.total,
                      }
                    : false
                }
              />
            }
          </div>
        </div>
      </div>
    );
  }
}

export default ViewDataDict;
