import React from 'react';
import { connect } from 'react-redux';
import reaction from 'reaction';
import Immutable from 'immutable';
import { Button, Input, message } from 'antd';
import DraggableList from 'components/DraggableList';
import history, { historyPush } from 'utils/history';
import { AuthCodes, RoutePath } from 'utils/constants';

import commonStyles from 'style/common.module.scss';

const { TextArea } = Input;

@connect(({ DataDict, me }) => ({ DataDict, me }))
class AddDataDict extends React.Component {
  state = {
    params: Immutable.Map({
      code: '',
      name: '',
      description: '',
    }),
    dictList: [],
  };

  addValue = () => {
    let { dictList } = this.state;
    dictList.push({ name: '', code: '', key: dictList.length });
    this.setState({ dictList }, () => {});
  };

  onParamsChange = (key, value) => {
    let { params } = this.state;
    params = params.set(key, value);
    this.setState({ params }, () => {});
  };

  deleteValue = (index) => {
    let { dictList } = this.state;
    dictList.splice(index, 1);
    this.setState({ dictList }, () => {});
  };

  onListChange = (sortIndexArr) => {
    let dictList = this.state.dictList;
    dictList = sortIndexArr.map((key, i) => {
      return dictList.filter((item, index) => {
        return item.key === key;
      })[0];
    });
    this.setState({ dictList });
  };

  onItemChange = (k, v, i) => {
    let { dictList } = this.state;
    dictList = [].concat(dictList);
    dictList[i][k] = v;
    this.setState(
      {
        dictList,
      },
      () => {}
    );
  };

  saveDictType = (typeParams) => {
    return reaction.DataDict.saveDictType(typeParams);
  };

  saveDict = (dictParams) => {
    return reaction.DataDict.saveDict(dictParams);
  };

  save = () => {
    let params = this.state.params.toJS();
    let typeParams = {
      name: params.name,
      code: params.code,
      description: params.description,
    };
    let dictParams = {
      dictList: this.state.dictList.map((item) => {
        item.type = params.code;
        return item;
      }),
    };
    this.saveDictType(typeParams).then((res) => {
      if (res.code === 0) {
        this.saveDict(dictParams).then((response) => {
          if (response.code === 0) {
            message.success('添加字典成功！');
            history.push(
              `/${RoutePath.SYSTEM}/${RoutePath.DICT}/${RoutePath.DICT_LIST}`
            );
          }
        });
      }
    });
  };

  check = () => {
    let { code, name } = this.state.params.toJS();
    let dictList = this.state.dictList;
    let isDataListOk = !dictList.some((item) => !item.name || !item.code);
    if (!isDataListOk) {
      message.error('值名和真实值不能为空!');
      return;
    }
    if (name === '') {
      message.error('请输入类型！');
      return;
    }
    if (code === '') {
      message.error('请输入code！');
      return;
    }
    let isKeyOrValueRepeat = false;
    outloop: for (let i = 0; i < dictList.length - 1; i++) {
      for (let j = i + 1; j < dictList.length; j++) {
        if (
          dictList[i].name === dictList[j].name ||
          dictList[i].code === dictList[j].code
        ) {
          isKeyOrValueRepeat = true;
          break outloop;
        }
      }
    }
    if (isKeyOrValueRepeat) {
      message.error('值名或真实值不能重复!');
      return;
    }
    this.save();
  };

  back = () => {
    history.push(
      `/${RoutePath.SYSTEM}/${RoutePath.DICT}/${RoutePath.DICT_LIST}`
    );
  };

  componentDidMount() {}

  render() {
    let { roleAuthList } = this.props.me.roleInfo;
    let addDictEnbaled = roleAuthList.some((item) => {
      return item.authCode.indexOf(AuthCodes.SYSTEM_DICT_TYPE_SAVE) !== -1;
    });
    if (!addDictEnbaled) {
      historyPush(RoutePath.INDEX);
      return;
    }

    let { params, dictList } = this.state;

    return (
      <div>
        <h3 style={{ overflow: 'hidden' }}>
          <span>新增数据字典</span>
        </h3>
        <div>
          <div style={{ padding: 10 }}>
            <span style={{ width: 50, display: 'inline-block' }}>类型：</span>
            <Input
              maxLength={10}
              value={params.toJS().name}
              onChange={(e) => {
                this.onParamsChange('name', e.target.value);
              }}
              style={{ width: '250px', marginLeft: '15px' }}
              defaultValue=""
              placeholder="请输入类型"
            />
          </div>
          <div style={{ padding: 10 }}>
            <span style={{ width: 50, display: 'inline-block' }}>code：</span>
            <Input
              maxLength={100}
              value={params.toJS().code}
              onChange={(e) => {
                this.onParamsChange('code', e.target.value);
              }}
              style={{ width: '250px', marginLeft: '15px' }}
              defaultValue=""
              placeholder="请输入code"
            />
          </div>
          <div style={{ padding: 10 }}>
            <span style={{ width: 50, display: 'inline-block', float: 'left' }}>
              描述：
            </span>
            <TextArea
              maxLength={50}
              rows={4}
              style={{ width: 250, marginLeft: 15 }}
              onChange={(e) => {
                this.onParamsChange('description', e.target.value);
              }}
              placeholder="用途、场景等描述，最多50个汉字。"
            />
          </div>
          <div style={{ padding: 10 }}>
            <span style={{ width: 50, display: 'inline-block' }}>键值：</span>
            <Button onClick={this.addValue} style={{ marginLeft: 15 }}>
              新增键值
            </Button>
          </div>
          <div style={{ width: 600, padding: '0 0 0 75px' }}>
            <div style={{ background: '#fafafa' }}>
              <span
                style={{
                  width: '33.333%',
                  display: 'inline-block',
                  height: '50px',
                  lineHeight: '50px',
                  textAlign: 'center',
                  color: 'rgba(0, 0, 0, 0.85)',
                }}
              >
                值名
              </span>
              <span
                style={{
                  width: '33.333%',
                  display: 'inline-block',
                  height: '50px',
                  lineHeight: '50px',
                  textAlign: 'center',
                  color: 'rgba(0, 0, 0, 0.85)',
                }}
              >
                真实值
              </span>
              <span
                style={{
                  width: '33.333%',
                  display: 'inline-block',
                  height: '50px',
                  lineHeight: '50px',
                  textAlign: 'center',
                  color: 'rgba(0, 0, 0, 0.85)',
                }}
              >
                操作
              </span>
            </div>
            <DraggableList
              list={dictList}
              onChange={this.onListChange}
              onItemChange={this.onItemChange}
            >
              {dictList.map((item, index) => {
                return (
                  <div key={item.key}>
                    <span
                      style={{
                        display: 'inline-block',
                        width: '33.333%',
                        padding: '5px 0 5px 0',
                        textAlign: 'center',
                      }}
                    >
                      <Input
                        id={'name'}
                        onMouseDown={(e) => {
                          e.stopPropagation();
                        }}
                        defaultValue={item.name}
                        readOnly={false}
                        onBlur={(e) => {
                          this.onItemChange(
                            'name',
                            e.target.value,
                            e.target.parentElement.parentElement.parentElement.getAttribute(
                              'index'
                            ) - 0
                          );
                        }}
                        maxLength={32}
                        style={{ width: 100 }}
                      />
                    </span>
                    <span
                      style={{
                        display: 'inline-block',
                        width: '33.333%',
                        padding: '5px 0 5px 0',
                        textAlign: 'center',
                      }}
                    >
                      <Input
                        id={'trueValue'}
                        onMouseDown={(e) => {
                          e.stopPropagation();
                        }}
                        defaultValue={item.code}
                        readOnly={false}
                        onBlur={(e) => {
                          this.onItemChange(
                            'code',
                            e.target.value,
                            e.target.parentElement.parentElement.parentElement.getAttribute(
                              'index'
                            ) - 0
                          );
                        }}
                        maxLength={32}
                        style={{ width: 100 }}
                      />
                    </span>
                    <span
                      style={{
                        display: 'inline-block',
                        width: '33.333%',
                        padding: '5px 0 5px 0',
                        textAlign: 'center',
                      }}
                    >
                      <Button
                        onClick={() => {
                          this.deleteValue(index);
                        }}
                      >
                        删除
                      </Button>
                    </span>
                  </div>
                );
              })}
            </DraggableList>
          </div>
          <div style={{ padding: '20px 0 0 75px' }}>
            <Button onClick={this.back}>返回</Button>
            <Button onClick={this.check} style={{ marginLeft: 10 }}>
              保存
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default AddDataDict;
