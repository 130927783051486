import React from 'react';
import commonStyles from 'style/common.module.scss';
import reaction from 'reaction';
import { historyPush } from 'utils/history';
import { Input, Button, message, Table, Select, Checkbox } from 'antd';
import { RoutePath } from 'utils/constants';

const Option = Select.Option;
const CheckboxGroup = Checkbox.Group;
let emailKey = 0;
export default class CompanyQualificationPeriodManagementEmailSet extends React.Component {
  state = {
    newEmail: '',
    emailList: [],
    notifyBeforeExpiration: '', // 通知范围
    notifyDays: [],
    emailContent: '',
  };

  getSetting = () => {
    reaction.memberCenter.getSetting().then((res) => {
      if (res.code === 0) {
        let {
          emailList,
          notifyBeforeExpiration,
          notifyDays,
          emailContent,
        } = res.data;
        this.setState({
          emailList: emailList.map((item) => {
            return {
              key: emailKey++,
              value: item,
            };
          }),
          notifyBeforeExpiration,
          notifyDays,
          emailContent,
        });
      }
    });
  };

  handleNewEmailChange = (v) => {
    this.setState({
      newEmail: v,
    });
  };

  handleCreate = () => {
    let { newEmail } = this.state;
    if (!(/^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/.test(newEmail))) {
      message.warn('请输入正确的邮箱格式!');
      return;
    }
    this.addNewEmail(newEmail);
  };

  addNewEmail = (email) => {
    let { emailList } = this.state;
    if (emailList.filter((item) => item.value === email.trim()).length >= 1) {
      message.warn('已添加该邮箱，不能重复添加!');
      return;
    }
    emailList.push({
      key: emailKey++,
      value: email.trim(),
    });
    this.setState(
      {
        emailList,
      },
      () => {
        this.setState({
          newEmail: '',
        });
      }
    );
  };

  handleDeleteRow = (record) => {
    let { emailList } = this.state;
    emailList = emailList.filter((item) => {
      return item.key !== record.key;
    });
    this.setState({ emailList });
  };

  handleScopeChange = (v) => {
    this.setState({
      notifyBeforeExpiration: v,
    });
  };

  handleNotifyDaysChange = (v) => {
    this.setState({
      notifyDays: v,
    });
  };

  handleSave = () => {
    let { emailList, notifyBeforeExpiration, notifyDays } = this.state;
    let params = {
      emailList: emailList.map((item) => item.value),
      notifyBeforeExpiration,
      notifyDays,
    };
    if (params.emailList.length === 0) {
      message.warn('请添加通知邮箱再提交!');
      return;
    }
    this.save(params);
  };

  save = (params) => {
    reaction.memberCenter.saveEmailSetting(params).then((res) => {
      if (res.code === 0) {
        message.success('保存成功!');
      }
      return res;
    });
  };

  back = () => {
    historyPush(
      `/${RoutePath.MEMBERCENTER}/${RoutePath.COMPANY_QUALIFICATION_PERIOD_MANAGEMENT}`
    );
  };

  componentDidMount() {
    this.getSetting();
  }

  render() {
    let {
      newEmail,
      notifyDays,
      emailList,
      notifyBeforeExpiration,
      emailContent,
    } = this.state;
    let columns = [
      {
        title: '邮箱',
        dataIndex: 'value',
      },
      {
        title: '操作',
        render: (text, record) => (
          <span>
            <Button
              onClick={() => {
                this.handleDeleteRow(record);
              }}
            >
              删除
            </Button>
          </span>
        ),
      },
    ];
    let columns1 = [
      {
        title: '最后期限',
        dataIndex: 'name',
      },
      {
        title: '数量',
        dataIndex: 'value',
      },
    ];
    let options = [
      {
        label: '星期一',
        value: 1,
      },
      {
        label: '星期二',
        value: 2,
      },
      {
        label: '星期三',
        value: 3,
      },
      {
        label: '星期四',
        value: 4,
      },
      {
        label: '星期五',
        value: 5,
      },
      {
        label: '星期六',
        value: 6,
      },
      {
        label: '星期日',
        value: 7,
      },
    ];
    let exampleList = [
      {
        name: '两月内',
        value: 12,
      },
      {
        name: '一月内',
        value: 14,
      },
      {
        name: '一周内',
        value: 3,
      },
      {
        name: '已到期',
        value: 0,
      },
    ];
    return (
      <div>
        <h2 className={commonStyles.row}>企业资质到期邮件通知设置</h2>
        <div style={{ padding: '30px 0 0 0', overflow: 'hidden' }}>
          <div style={{ width: 150, float: 'left' }}>新增通知邮箱</div>
          <div style={{ marginLeft: 150 }}>
            <Input
              style={{ width: 250 }}
              value={newEmail}
              onChange={(e) => {
                this.handleNewEmailChange(e.target.value);
              }}
            />
            <Button style={{ marginLeft: 15 }} onClick={this.handleCreate}>
              新增
            </Button>
          </div>
        </div>
        <div style={{ padding: '30px 0 0 0', overflow: 'hidden' }}>
          <div style={{ width: 150, float: 'left' }}>已添加邮箱</div>
          <div style={{ marginLeft: 150 }}>
            <Table
              style={{ width: 400 }}
              className={commonStyles.row}
              rowKey="userId"
              columns={columns}
              pagination={false}
              dataSource={emailList}
            />
          </div>
        </div>
        <div style={{ padding: '30px 0 0 0', overflow: 'hidden' }}>
          <div style={{ width: 150, float: 'left' }}>通知范围</div>
          <div style={{ marginLeft: 150 }}>
            <label>资质最后期限</label>
            <Select
              value={notifyBeforeExpiration}
              style={{ width: 150, marginLeft: 10 }}
              onChange={this.handleScopeChange}
            >
              <Option value={5184000000}>两月内</Option>
              <Option value={2592000000}>一月内</Option>
              <Option value={604800000}>一周内</Option>
              <Option value={-1}>已到期</Option>
            </Select>
          </div>
        </div>
        <div style={{ padding: '30px 0 0 0', overflow: 'hidden' }}>
          <div style={{ width: 150, float: 'left' }}>发送周期</div>
          <div style={{ marginLeft: 150 }}>
            <CheckboxGroup
              options={options}
              value={notifyDays}
              onChange={this.handleNotifyDaysChange}
            />
          </div>
        </div>
        <div style={{ padding: '30px 0 0 0', overflow: 'hidden' }}>
          <div style={{ width: 150, float: 'left' }}></div>
          <div style={{ marginLeft: 150 }}>发送时间： 早上9:30 - 10:00</div>
        </div>
        <div style={{ padding: '30px 0 0 0', overflow: 'hidden' }}>
          <div style={{ width: 150, float: 'left' }}>邮件格式预览</div>
          <div
            style={{ marginLeft: 150 }}
            dangerouslySetInnerHTML={{ __html: emailContent }}
          ></div>
          {/*<div style={{marginLeft: 150, padding: '20px', border: '1px solid #aaa'}} dangerouslySetInnerHTML={ emailContent }>*/}
          {/*<h4>资质审核管理员，您好，以下为即将到期的资质信息简报，请查阅处理。</h4>*/}
          {/*<Table*/}
          {/*style={{width: 400, marginTop: 20}}*/}
          {/*className={ commonStyles.row }*/}
          {/*rowKey="value"*/}
          {/*columns={ columns1 }*/}
          {/*pagination={ false }*/}
          {/*dataSource={ exampleList }/>*/}
          {/*<p style={{marginTop: 30}}>请尽快登录：<a href="javascript: void(0)">链接地址</a>处理.</p>*/}
          {/*</div>*/}
        </div>
        <div style={{ padding: '50px 0 0 200px' }}>
          <Button onClick={this.handleSave}>保存</Button>
          <Button style={{ marginLeft: 15 }} onClick={this.back}>
            返回
          </Button>
        </div>
      </div>
    );
  }
}
