/**
 * reducer 整个项目的数据
 */ import common from './common';
import menu from './menu';
import me from './me';
import frontUser from './frontUser';
import backendUser from './backendUser';
import application from './application';
import role from './role';
import blacklist from './blacklist';
import platform from './platform';
import log from './log';
import authManagement from './authManagement';
import accountList from './accountList';
import receiptList from './receiptList';
import ruleConfig from './ruleConfig';
import DataDict from './DataDict';
import smsManagement from './smsManagement';
import memberCenter from './memberCenter';
import vipCenter from './vipCenter';
import qualification from './qualification';
import screen from './screen';
import report from './report';
import smsBlackList from './smsBlackList';
import smsBlackListAccountManagement from './smsBlackListAccountManagement.js';
import groupSms from './groupSms.js';
export default { application: application,
    common: common, menu: menu,
    me: me,
    frontUser: frontUser,
    backendUser: backendUser,
    blacklist: blacklist,
    platform: platform,
    role: role,
    log: log,
    authManagement: authManagement,
    accountList: accountList,
    receiptList: receiptList,
    ruleConfig: ruleConfig,
    DataDict: DataDict,
    smsManagement: smsManagement,
    memberCenter: memberCenter,
    vipCenter: vipCenter,
    qualification: qualification,
    screen: screen,
    report: report,
    smsBlackList: smsBlackList,
    smsBlackListAccountManagement: smsBlackListAccountManagement, groupSms: groupSms // 消息群发
};
