/**
 * 资质的待补全状态
 */ export var certCompletionStatusCode;
(function (certCompletionStatusCode) { certCompletionStatusCode["COMPLETED"] = "1"; certCompletionStatusCode["UN_COMPLETE"] = "2"; })(certCompletionStatusCode || (certCompletionStatusCode = {}));
; /**
* 资质类型
*/
export var certTypes;
(function (certTypes) { certTypes["company"] = "1"; certTypes["person"] = "2"; })(certTypes || (certTypes = {}));
; /**
* 用户禁用状态
*/
export var userActiveStatus;
(function (userActiveStatus) { userActiveStatus["enable"] = "1"; userActiveStatus["disabled"] = "0"; })(userActiveStatus || (userActiveStatus = {}));
;
export var identityCodes = { fin: { agency: 'fin_agency', borrower: 'fin_borrower', institution: 'fin_institution' }, cenpur: { supplier: 'cenpur_supplier', purchaser: 'cenpur_purchaser' }, bid: { supplier: 'bid_supplier', purchaser: 'bid_purchaser' } }; /**
* 授权委托书模板下载地址
*/
export var authorCertTemplate = 'https://sso.zlgx.com/assets/files/企业操作人授权书模板.docx';
