import React from 'react';
import { connect } from 'react-redux';
import { Form, Select, Button, Input, message, Icon } from 'antd';
import reaction from 'reaction';
import { HttpCode, RoutePath } from 'utils/constants';
import { historyPush } from 'utils/history';
import { parseMenu, mapTree, loopTree, resolveUrlQuery } from 'utils';
// import FormTreeItem from 'components/FormTreeItem';
import Tree from 'components/Tree/VirtualTree';

import commonStyles from 'style/common.module.scss';
// import styles from './index.module.scss';

const FormItem = Form.Item;

/**
 * 用户角色修改
 */
@connect(({ common, role, me }) => ({ common, role, me }))
@Form.create()
class RoleModify extends React.Component {
  getMenuId(authList) {
    const { authTree } = this.props.me;
    let idsCache1 = {};
    loopTree(authTree, (obj) => {
      idsCache1[obj.authCode] = obj;
    });
    let idsCache = {};
    authList.forEach((code) => {
      let obj = idsCache1[code];
      if (!obj.children) {
        // leaf
        let parentObj = idsCache1[obj.parentId];
        let indexObj = parentObj.children[0];
        if (indexObj) {
          idsCache[indexObj.authCode] = true;
        }
      }

      while (obj) {
        idsCache[obj.authCode] = true;
        obj = idsCache1[obj.parentId];
      }
    });

    authList = Object.keys(idsCache);
    return authList;
  }
  getLeafMenuId(authList) {
    const { authTree } = this.props.me;
    let idsCache1 = {};
    loopTree(authTree, (obj) => {
      idsCache1[obj.authCode] = obj;
    });
    let idsCache = {};
    authList.forEach((code) => {
      let obj = idsCache1[code];
      if (!obj.children) {
        // leaf
        idsCache[obj.authCode] = true;
        let parentObj = idsCache1[obj.parentId];
        let indexObj = parentObj.children[0];
        if (indexObj) {
          idsCache[indexObj.authCode] = true;
        }
      }
    });

    authList = Object.keys(idsCache);
    return authList;
  }

  getValueFromEvent = (value) => {
    // return this.getLeafMenuId(value);
  };
  handleSelectChange = (selectedKeys) => {};
  // state = {};
  componentDidMount() {
    const { roleId } = resolveUrlQuery(this.props.location.search);
    reaction.common.getAuthDict().then((res) => {
      reaction.role.getDetail(roleId);
    });
  }

  handleSubmit = (e) => {
    e && e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let { roleName, description, authCodeList } = values;
        // authCodeList = this.getMenuId(authCodeList);
        const { roleId } = resolveUrlQuery(this.props.location.search);
        reaction.role
          .update(roleId, roleName, description, authCodeList)
          .then((data) => {
            if (data.code === HttpCode.OK) {
              message.success('修改成功！');
              reaction.me.initialData().then((data) => {
                if (data.code === 0) {
                  historyPush(`/${RoutePath.SYSTEM}/${RoutePath.ROLE}`);
                }
              });
              // window.location.reload();
            }
          });
      }
    });
  };

  handleGoBack = () => {
    historyPush(`/${RoutePath.SYSTEM}/${RoutePath.ROLE}`);
  }

  render() {
    const { authDict } = this.props.common;
    // const { authTree } = this.props.me;
    const { detail } = this.props.role;
    let { getFieldDecorator } = this.props.form;

    let authList = detail ? detail.authCodeList : [];
    return (
      !!detail && (
        <div>
          <h2 className={commonStyles.row}>角色修改</h2>
          <Form onSubmit={this.handleSubmit} className={commonStyles.row}>
            <FormItem
              label="用户角色"
              labelCol={{ span: 8 }}
              wrapperCol={{ sm: 8, xs: 24 }}
            >
              {getFieldDecorator('roleName', {
                validateTrigger: ['onBlur', 'onChange'],
                initialValue: detail.roleName,
                rules: [
                  {
                    required: true,
                    whitespace: true,
                    message: '请输入角色名称!',
                  },
                  {
                    type: 'string',
                    pattern: /^\S.{0,30}\S$/,
                    message: '角色名称长度为2到32位!',
                  },
                ],
              })(<Input placeholder="角色名称" />)}
            </FormItem>
            <FormItem
              label="角色说明"
              labelCol={{ span: 8 }}
              wrapperCol={{ sm: 8, xs: 24 }}
            >
              {getFieldDecorator('description', {
                initialValue: detail.description,
                rules: [
                  {
                    type: 'string',
                    max: 128,
                    message: '角色说明长度限制为128字以内!',
                  },
                ],
              })(
                <Input
                  type="textarea"
                  placeholder="角色说明"
                  autosize={{ minRows: 2 }}
                />
              )}
            </FormItem>
            <FormItem
              label="功能权限"
              labelCol={{ span: 8 }}
              wrapperCol={{ sm: 8, xs: 24 }}
            >
              {getFieldDecorator('authCodeList', {
                initialValue: authList,
              })(<Tree nodes={authDict} virtual={{ itemHeight: 28, scrollHeight: 400 }} labelProps="fullName" valueProps="authCode" />)}
            </FormItem>
            <FormItem
              wrapperCol={{ sm: { span: 8, offset: 8 }, xs: { span: 8 } }}
            >
              <Button type="primary" htmlType="submit">
                确定
              </Button>
              <Button style={{ marginLeft: 40 }} onClick={this.handleGoBack}><Icon type="caret" />返回</Button>
            </FormItem>
          </Form>
        </div>
      )
    );
  }
}

export default RoleModify;
