import React from 'react';
import { connect } from 'react-redux';
import reaction from 'reaction';
import dayjs from 'dayjs';
import Immutable from 'immutable';
import { Button, Input, message, Table, Modal } from 'antd';
import DraggableList from 'components/DraggableList';
import history from 'utils/history';
import { resolveUrlQuery } from 'utils';
import { RoutePath } from 'utils/constants';

import commonStyles from 'style/common.module.scss';

const { TextArea } = Input;
const confirm = Modal.confirm;

@connect(({ DataDict }) => ({ DataDict }))
class EditDataDict extends React.Component {
  state = {
    params: Immutable.Map({
      code: '',
      name: '',
      description: '',
    }),
    page: 1,
    length: 10,
    dictList: [],
    currentRow: null,
  };

  addValue = () => {
    let { dictList } = this.state;
    dictList.push({ name: '', code: '', key: dictList.length });
    this.setState({ dictList }, () => {});
  };

  showDeleteDialog = (row) => {
    let _this = this;
    this.setState({ currentRow: row });
    confirm({
      title: '确定删除该字典?',
      content: '删除后将不可恢复,请谨慎操作！',
      onOk() {
        _this.deleteValue();
      },
      onCancel() {},
      okText: '确定',
      cancelText: '取消',
    });
  };

  deleteValue = () => {
    let { dictList } = this.state;
    dictList = dictList.filter((item) => {
      return item.key !== this.state.currentRow.key;
    });
    if (this.state.currentRow.id) {
      reaction.DataDict.deleteDictKeyValue(this.state.currentRow.id).then(
        (res) => {
          if (res.code === 0) {
            message.success('删除字典成功!');
            this.setState({ dictList }, () => {});
          }
        }
      );
    } else {
      this.setState({ dictList }, () => {});
    }
  };

  getDictInfo = () => {
    const { id } = resolveUrlQuery(this.props.location.search);
    reaction.DataDict.getDictInfo(id).then((res) => {
      let { dictType, dictList } = this.props.DataDict;
      if (res.code === 0) {
        let { params } = this.state;
        params = params.set('code', dictType.code);
        params = params.set('name', dictType.name);
        params = params.set('description', dictType.description);
        this.setState({ params });
        this.setState({ dictList: dictList });
      }
    });
  };

  getLogLost = () => {
    let id = resolveUrlQuery(this.props.location.search).id;
    let { page, length } = this.state;
    reaction.DataDict.getLogList(resolveUrlQuery(this.props.location.search).id, page, length);
  };

  onListChange = (sortIndexArr) => {
    let dictList = this.state.dictList;
    dictList = sortIndexArr.map((key, i) => {
      return dictList.filter((item, index) => {
        return item.key === key;
      })[0];
    });
    this.setState({ dictList });
  };

  onItemChange = (k, v, i) => {
    let { dictList } = this.state;
    dictList = [].concat(dictList);
    dictList[i][k] = v;
    this.setState(
      {
        dictList,
      },
      () => {}
    );
  };

  onParamsChange = (key, value) => {
    let { params } = this.state;
    params = params.set(key, value);
    this.setState({ params }, () => {});
  };

  updateDictType = (params) => {
    return reaction.DataDict.updateDictType(params);
  };

  updateDictList = (params) => {
    return reaction.DataDict.updateDictList(params);
  };

  save = () => {
    let params = this.state.params.toJS();
    let dictTypeParams = {
      id: resolveUrlQuery(this.props.location.search).id,
      name: params.name,
      code: params.code,
      description: params.description,
    };
    let dictList = this.state.dictList.map((item) => {
      item.type = params.code;
      return item;
    });
    this.updateDictType(dictTypeParams).then((res) => {
      if (res.code === 0) {
        this.updateDictList({ dictList }).then((response) => {
          if (response.code === 0) {
            message.success('更新字典成功!');
            history.push(
              `/${RoutePath.SYSTEM}/${RoutePath.DICT}/${RoutePath.DICT_LIST}`
            );
          }
        });
      }
    });
  };

  back = () => {
    history.push(
      `/${RoutePath.SYSTEM}/${RoutePath.DICT}/${RoutePath.DICT_LIST}`
    );
  };

  check = () => {
    let { code, name } = this.state.params.toJS();
    let dictList = this.state.dictList;
    let isDataListOk = !dictList.some((item) => !item.name || !item.code);
    if (!isDataListOk) {
      message.error('值名和真实值不能为空!');
      return;
    }
    if (name === '') {
      message.error('请输入类型！');
      return;
    }
    if (code === '') {
      message.error('请输入code！');
      return;
    }
    let isKeyOrValueRepeat = false;
    outloop: for (let i = 0; i < dictList.length - 1; i++) {
      for (let j = i + 1; j < dictList.length; j++) {
        if (
          dictList[i].name === dictList[j].name ||
          dictList[i].code === dictList[j].code
        ) {
          isKeyOrValueRepeat = true;
          break outloop;
        }
      }
    }
    if (isKeyOrValueRepeat) {
      message.error('值名或真实值不能重复!');
      return;
    }
    this.save();
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.setState({ page: pagination.current }, () => {
      this.getLogList();
    });
  };

  componentDidMount() {
    this.getDictInfo();
    this.getLogLost();
  }

  render() {
    let { params, dictList } = this.state;
    dictList = dictList.map((item, i) => {
      item.key = i;
      return item;
    });
    let logList = this.props.DataDict.logList;
    let columns = [
      {
        width: 140,
        title: '操作人',
        dataIndex: 'username',
      },
      {
        width: 112,
        title: '操作时间',
        dataIndex: 'eventTime',
        render: (text) => {
          if (!text) {
            return '';
          }
          return dayjs(text - 0).format('YYYY-MM-DD HH:mm:ss');
        },
      },
      {
        title: '操作内容',
        dataIndex: 'content',
      },
    ];
    return (
      <div>
        <h3 style={{ overflow: 'hidden' }}>
          <span>编辑数据字典</span>
        </h3>
        <div>
          <div style={{ padding: 10 }}>
            <span style={{ width: 50, display: 'inline-block' }}>类型：</span>
            <Input
              maxLength={32}
              value={params.toJS().name}
              onChange={(e) => {
                this.onParamsChange('name', e.target.value);
              }}
              style={{ width: '250px', marginLeft: '15px' }}
              defaultValue=""
              placeholder="输入数据类型"
            />
          </div>
          <div style={{ padding: 10 }}>
            <span style={{ width: 50, display: 'inline-block' }}>code：</span>
            <Input
              maxLength={100}
              value={params.toJS().code}
              onChange={(e) => {
                this.onParamsChange('code', e.target.value);
              }}
              style={{ width: '250px', marginLeft: '15px' }}
              defaultValue=""
              placeholder="输入code"
            />
          </div>
          <div style={{ padding: 10 }}>
            <span style={{ width: 50, display: 'inline-block', float: 'left' }}>
              描述：
            </span>
            <TextArea
              value={params.toJS().description}
              maxLength={120}
              rows={4}
              style={{ width: 250, marginLeft: 15 }}
              onChange={(e) => {
                this.onParamsChange('description', e.target.value);
              }}
            />
          </div>
          <div style={{ padding: 10 }}>
            <span style={{ width: 50, display: 'inline-block' }}>键值：</span>
            <Button onClick={this.addValue} style={{ marginLeft: 15 }}>
              新增键值
            </Button>
          </div>
          <div style={{ width: 600, padding: '0 0 0 75px' }}>
            <div style={{ background: '#fafafa' }}>
              <span
                style={{
                  width: '33.333%',
                  display: 'inline-block',
                  height: '50px',
                  lineHeight: '50px',
                  textAlign: 'center',
                  color: 'rgba(0, 0, 0, 0.85)',
                }}
              >
                值名
              </span>
              <span
                style={{
                  width: '33.333%',
                  display: 'inline-block',
                  height: '50px',
                  lineHeight: '50px',
                  textAlign: 'center',
                  color: 'rgba(0, 0, 0, 0.85)',
                }}
              >
                真实值
              </span>
              <span
                style={{
                  width: '33.333%',
                  display: 'inline-block',
                  height: '50px',
                  lineHeight: '50px',
                  textAlign: 'center',
                  color: 'rgba(0, 0, 0, 0.85)',
                }}
              >
                操作
              </span>
            </div>
            <DraggableList list={dictList} onChange={this.onListChange}>
              {dictList.map((item, index) => {
                return (
                  <div key={item.key}>
                    <span
                      style={{
                        display: 'inline-block',
                        width: '33.333%',
                        padding: '5px 0 5px 0',
                        textAlign: 'center',
                      }}
                    >
                      <Input
                        id={'name'}
                        onMouseDown={(e) => {
                          e.stopPropagation();
                        }}
                        defaultValue={item.name}
                        readOnly={false}
                        onBlur={(e) => {
                          this.onItemChange(
                            'name',
                            e.target.value,
                            e.target.parentElement.parentElement.parentElement.getAttribute(
                              'index'
                            ) - 0
                          );
                        }}
                        maxLength={32}
                        style={{ width: 100 }}
                      />
                    </span>
                    <span
                      style={{
                        display: 'inline-block',
                        width: '33.333%',
                        padding: '5px 0 5px 0',
                        textAlign: 'center',
                      }}
                    >
                      <Input
                        id={'trueValue'}
                        onMouseDown={(e) => {
                          e.stopPropagation();
                        }}
                        defaultValue={item.code}
                        readOnly={false}
                        onBlur={(e) => {
                          this.onItemChange(
                            'code',
                            e.target.value,
                            e.target.parentElement.parentElement.parentElement.getAttribute(
                              'index'
                            ) - 0
                          );
                        }}
                        maxLength={32}
                        style={{ width: 100 }}
                      />
                    </span>
                    <span
                      style={{
                        display: 'inline-block',
                        width: '33.333%',
                        padding: '5px 0 5px 0',
                        textAlign: 'center',
                      }}
                    >
                      <Button
                        onClick={() => {
                          this.showDeleteDialog(item);
                        }}
                      >
                        删除
                      </Button>
                    </span>
                  </div>
                );
              })}
            </DraggableList>
          </div>
          <div style={{ padding: '20px 0 0 75px' }}>
            <Button onClick={this.back}>返回</Button>
            <Button onClick={this.check} style={{ marginLeft: 10 }}>
              保存
            </Button>
          </div>
          <div style={{ padding: '20px 0 0 0' }}>
            {
              <Table
                className={commonStyles.row}
                rowKey="uid"
                columns={columns}
                dataSource={logList ? logList.result : []}
                onChange={this.handleTableChange}
                pagination={
                  logList
                    ? {
                        current: logList.pagerInfo.page,
                        total: logList.pagerInfo.total,
                      }
                    : false
                }
              />
            }
          </div>
        </div>
      </div>
    );
  }
}

export default EditDataDict;
