import React from 'react';
import { connect } from 'react-redux';
import { Tabs, Modal } from 'antd';

import AuthenticateAccount from './createAuthenticateAccount';
import RegisterAccount from './createRegisterAccount';

const { TabPane } = Tabs;

/**
 * 新增会员
 */
@connect(({ common, me }) => ({ common, me }))
class CreateMember extends React.Component {
  state = {
    tabType: 'createRegisterAccount',
  };
  handleChange = v => {
    this.setState({
      tabType: v,
    });
  }
  render() {
    return (
      <Tabs type="card" onChange={this.handleChange} activeKey={this.state.tabType}>
        <TabPane tab="注册账户" key="createRegisterAccount">
          <RegisterAccount />
        </TabPane>
        <TabPane tab="认证账户" key="createAuthenticateAccount">
          <AuthenticateAccount />
        </TabPane>
      </Tabs>
    );
  }
}
export default CreateMember;
