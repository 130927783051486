import http from 'utils/http';
import createUID from 'utils/createUID';

const LIST = createUID();
const DETAIL = createUID();
const GET_FRONT_MENU_TREE = createUID();

const initialState = {
    list: {
        // pagination: {
        //     currentPage: 1,
        //     total: 0
        // },
        result: []
    },
    detail: {

    },

    frontMenuTreeData: []
};

function reducer(state = initialState, action) {
    switch (action.type) {
        case LIST:
            return Object.assign({}, state, { list: { result: action.data } });
        case DETAIL:
            return Object.assign({}, state, { detail: action.data });
        case GET_FRONT_MENU_TREE:
            return Object.assign({}, state, { frontMenuTreeData: action.data });
        default:
            return state;
    }
}

Object.assign(reducer, {
    getAuthList(params) {
        return (dispatch) => {
            return http.request('v1.system.listAuth', params).then((data) => {
                dispatch({ type: LIST, data: data.data });
                return data;
            });
        };
    },

    getDetail(authId) {
        return (dispatch) => {
            return http.request('v1.system.queryAuthDetail', { authId }).then((data) => {
                dispatch({ type: DETAIL, data: data.data });
                return data;
            });
        };
    },

    create(authCode, requestPath, displayName, fullName, parentId, platformId) {
        return (dispatch) => {
            return http.request('v1.system.addAuth', { authCode, requestPath, displayName, fullName, parentId, platformId });
        };
    },

    deleteAuth(authId) {
        return (dispatch) => {
            return http.request('v1.system.deleteAuth', { authId });
        };
    },

    update({ authId, authCode, requestPath, displayName, fullName, platformId, parentId }) {
        // console.log({ authId, authCode, requestPath, displayName, fullName })
        return (dispatch) => {
            return http.request('v1.system.updateAuth', { authId, authCode, requestPath, displayName, fullName, platformId, parentId });
        };
    },


    getFrontMenuTree (params) {
        return (dispatch) => {
            return http.request('v1.frontUser.treeMenu', params).then((data) => {
                dispatch({ type: GET_FRONT_MENU_TREE, data: data.data });
                return data;
            });
        };
    },
    getMenuCharacterCode (params) {
        return (dispatch) => {
            return http.request('v1.frontUser.menuCharacter', params).then((data) => {
                return data;
            });
        };
    },

    addFrontUserMenu (params) {
        return (dispatch) => {
            return http.request('v1.frontUser.addMenu', params);
        };
    },
    modifyFrontUserMenu (params) {
        return (dispatch) => {
            return http.request('v1.frontUser.updateMenu', params);
        };
    },
    deleteFrontUserMenu (id) {
        return (dispatch) => {
            return http.request('v1.frontUser.deleteMenu', {id: id});
        };
    }
});
export default reducer;