import React from 'react';
import { connect } from 'react-redux';
// import { Link } from 'react-router';
import { RoutePath, HttpCode, AuthCodes } from 'utils/constants';
import reaction from 'reaction';
import history from 'utils/history';
import { Table, Button, Input, Row, Col, Popconfirm, message } from 'antd';
import { withRouter } from 'react-router-dom';

import commonStyles from 'style/common.module.scss';
import { transformParamsToUrl } from 'utils';
/**
 * 平台管理
 */
@connect(({ platform, me }) => ({ platform, me }))
@withRouter
class PlatformList extends React.Component {
  state = {
    currentPage: 1,
    pageSize: 10,
  };
  componentDidMount() {
    let { roleAuthList } = this.props.me.roleInfo;
    let listEnbaled = roleAuthList.some((item) => {
      return item.authCode.indexOf(AuthCodes.PLATFORM_SYSTEM_LIST) !== -1;
    });
    listEnbaled && reaction.platform.getList();
  }
  handleTableChange = (pagination, filters, sorter) => {
    this.setState({
      currentPage: pagination.current,
    });
    // reaction.platform.getList(pagination.current);
  };
  goEdit = (record, e) => {
    this.props.history.push({
      pathname: `/${RoutePath.SYSTEM}/${RoutePath.PLATFORM}/${RoutePath.MODIFY}`,
      search: transformParamsToUrl({
        platformId: record.platformId,
        platformCode: record.platformCode,
        platformName: record.platformName,
        visitPath: record.visitPath,
        platformSecret: record.platformSecret,
        platformAbbreviation: record.platformAbbreviation,
      }),
    });
  };

  doAdd = (e) => {
    history.push(`/${RoutePath.SYSTEM}/${RoutePath.PLATFORM}/${RoutePath.ADD}`);
  };
  doDelete = (record, e) => {
    const { list } = this.props.platform;
    const length = list.length;
    reaction.platform.delete(record.platformId).then((data) => {
      if (data.code === HttpCode.OK) {
        message.success('删除成功！');
        reaction.common.getPlatformDict(); // 刷新平台字典
        reaction.platform.getList();
        // if (length === 1 && this.state.currentPage > 1) {
        //     reaction.platform.getList(this.state.currentPage - 1);
        // } else {
        //     reaction.platform.getList(this.state.currentPage);
        // }
      }
    });
  };
  render() {
    let { roleAuthList } = this.props.me.roleInfo;
    let addEnbaled = roleAuthList.some((item) => {
      return item.authCode.indexOf(AuthCodes.PLATFORM_SYSTEM_ADD) !== -1;
    });
    let deleteEnbaled = roleAuthList.some((item) => {
      return item.authCode.indexOf(AuthCodes.PLATFORM_SYSTEM_DELETE) !== -1;
    });
    let editEnbaled = roleAuthList.some((item) => {
      return item.authCode.indexOf(AuthCodes.PLATFORM_SYSTEM_UPDATE) !== -1;
    });
    let { list } = this.props.platform;
    list = list.map((item, index) => {
      item.no = index + 1;
      return item;
    });
    const columns = [
      {
        title: '序号',
        width: 80,
        dataIndex: 'no',
      },
      {
        title: '业务系统',
        dataIndex: 'platformName',
      },
      {
        title: '域名',
        dataIndex: 'visitPath',
      },
      {
        title: '操作',
        render: (text, record) => (
          <Button.Group>
            <Button
              icon="edit"
              onClick={this.goEdit.bind(this, record)}
              disabled={!editEnbaled}
            >
              修改
            </Button>
            {deleteEnbaled ? (
              <Popconfirm
                title="确定要删除？"
                onConfirm={this.doDelete.bind(this, record)}
                okText="确定"
                cancelText="取消"
              >
                <Button icon="delete" type="danger">
                  删除
                </Button>
              </Popconfirm>
            ) : (
              <Button icon="delete" type="danger" disabled>
                删除
              </Button>
            )}
          </Button.Group>
        ),
      },
    ];

    return (
      <div className="user">
        <h2 className={commonStyles.row}>业务系统管理</h2>
        <div className={commonStyles.row}>
          <Button type="primary" icon="plus" onClick={this.doAdd} disabled={!addEnbaled}>
            新增
          </Button>
        </div>
        {list && (
          <Table
            className={commonStyles.row}
            rowKey="platformId"
            columns={columns}
            dataSource={list}
            onChange={this.handleTableChange}
          />
        )}
      </div>
    );
  }
}

export default PlatformList;
