import React from 'react';
import reaction from 'reaction';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Table, Button, Input, Popconfirm, message, Form, Tooltip } from 'antd';

import FormItem from 'components/FormItem';

import history from 'utils/history';
import { renderListControls } from 'utils';
import { RoutePath, HttpCode, AuthCodes } from 'utils/constants';

import commonStyles from 'style/common.module.scss';
/**
 * 后台用户信息管理
 */
@connect(({ backendUser, common, me }) => ({ backendUser, common, me }))
@withRouter
class User extends React.Component {
  state = {
    keywords: '',
    currentPage: 1,
    length: 10,
  };
  componentDidMount() {
    let { roleAuthList } = this.props.me.roleInfo;
    let listEnbaled = roleAuthList.some((item) => {
      return item.authCode.indexOf(AuthCodes.SYSTEM_USER_LIST) !== -1;
    });
    let userEnbaled = roleAuthList.some((item) => {
      return item.authCode.indexOf(AuthCodes.SYSTEM_USER) !== -1;
    });
    listEnbaled && this.getUserList(1, 10, '');
    !userEnbaled && history.push('/');
  }
  handleTableChange = (pagination, filters, sorter) => {
    this.setState(
      {
        currentPage: pagination.current,
        length: pagination.pageSize,
      },
      () => {
        this.getUserList(pagination.current, pagination.pageSize, this.state.keywords);
      }
    );
  };
  getUserList = (currentPage, length) => {
    let { keywords } = this.state;
    reaction.backendUser.getList(keywords, currentPage, length);
  };
  doSearch = (e) => {
    e && e.preventDefault();
    this.setState(
      {
        currentPage: 1,
      },
      () => {
        this.getUserList(this.state.currentPage, this.state.length, this.state.keywords);
      }
    );
  };
  onChange = (e) => {
    this.setState({ keywords: e.target.value });
  };

  goEdit = (record, e) => {
    this.props.history.push({
      pathname: `/${RoutePath.SYSTEM}/${RoutePath.USER}/${RoutePath.MODIFY}`,
      search: `?userId=${record.uid}`,
    });
  };

  doAdd = (e) => {
    history.push(`/${RoutePath.SYSTEM}/${RoutePath.USER}/${RoutePath.ADD}`);
  };
  doDelete = (record, e) => {
    
    reaction.backendUser.delete(record.uid).then((data) => {
      if (data.code === HttpCode.OK) {
        message.success('删除成功！');
        let { currentPage, length, keywords } = this.state;
        // 如果删除的是当前页最后一条数据，那么删除后重新请求的页码应该是上一页的页码
        if (length === 1 && currentPage > 1) {
          currentPage -= 1;
        }
        this.setState({
          currentPage,
        })
        this.getUserList(currentPage, length, keywords);
      }
    });
  };

  onPressEnter = () => {
    this.doSearch();
  };

  getColumns(state, { deleteEnbaled, editEnbaled, roleDict, list }) {
    return [
      {
        title: '序号',
        width: 80,
        fixed: 'left',
        align: 'center',
        key: 'index',
        render: (text, record, index) =>
          (list.pagerInfo.page - 1) * state.length + index + 1,
      },
      {
        title: '用户名',
        dataIndex: 'username',
        align: 'center',
        render: (text, record) => {
          if (text && text.length > 15) {
            return (
              <Tooltip title={text}>
                <span style={{ cursor: 'pointer' }}>{text.substr(0, 15)}…</span>
              </Tooltip>
            );
          } else {
            return text;
          }
        },
      },
      {
        title: '姓名',
        dataIndex: 'realName',
        align: 'center',
        render: (text, record) => {
          if (text && text.length > 10) {
            return (
              <Tooltip title={text}>
                <span style={{ cursor: 'pointer' }}>{text.substr(0, 10)}…</span>
              </Tooltip>
            );
          }
          return text;
        },
      },
      {
        title: '角色名称',
        dataIndex: 'role',
        align: 'center',
        render: (role, record) => {
          let result = [];
          if (role) {
            role.forEach((item) => {
              result.push(roleDict[item]);
            });
          }
          result = result.join(',');
          if (result && result.length > 20) {
            return (
              <Tooltip title={result}>
                <span style={{ cursor: 'pointer' }}>
                  {result.substr(0, 20)}…
                </span>
              </Tooltip>
            );
          }
          return result;
        },
      },
      {
        title: '操作',
        width: 200,
        fixed: 'right',
        align: 'center',
        key: 'handler',
        render: (_, record) => (
          <Button.Group>
            <Button
              icon="edit"
              onClick={this.goEdit.bind(this, record)}
              disabled={!editEnbaled}
            >
              修改
            </Button>
            {deleteEnbaled ? (
              <Popconfirm
                title="确定要删除？"
                onConfirm={this.doDelete.bind(this, record)}
                okText="确定"
                cancelText="取消"
              >
                <Button icon="delete" type="danger">
                  删除
                </Button>
              </Popconfirm>
            ) : (
              <Button icon="delete" type="danger" disabled>
                删除
              </Button>
            )}
          </Button.Group>
        ),
      },
    ]
  }

  handleSizeChange = (currentPage, length) => {
    this.setState({
      currentPage,
      length,
    });
  }

  render() {
    let { roleAuthList } = this.props.me.roleInfo;
    let addEnbaled = roleAuthList.some((item) => {
      return item.authCode.indexOf(AuthCodes.SYSTEM_USER_ADD) !== -1;
    });
    let deleteEnbaled = roleAuthList.some((item) => {
      return item.authCode.indexOf(AuthCodes.SYSTEM_USER_DELETE) !== -1;
    });
    let editEnbaled = roleAuthList.some((item) => {
      return item.authCode.indexOf(AuthCodes.SYSTEM_USER_UPDATE) !== -1;
    });
    let listEnbaled = roleAuthList.some((item) => {
      return item.authCode.indexOf(AuthCodes.SYSTEM_USER_LIST) !== -1;
    });

    const { roleDict } = this.props.common;
    const { list } = this.props.backendUser;

    const columns = this.getColumns(this.state, {
      deleteEnbaled,
      editEnbaled,
      roleDict,
      list
    })

    if (!listEnbaled) {
      return <div className="user">暂无权限</div>;
    }

    return (
      <div className="user">
        <h2 className={commonStyles.row}>用户管理</h2>
        <Form onSubmit={this.doSearch}>
          {
            renderListControls([
              <FormItem label="用户名或姓名">
                <Input
                  placeholder="请输入用户名或者姓名查询"
                  // style={{ width: 300, float: 'left' }}
                  onPressEnter={this.onPressEnter}
                  onChange={this.onChange}
                />
              </FormItem>,
              {
                children: (
                  <FormItem push={20}>
                    <Button type="primary" icon="search" htmlType="submit">
                      查询
                    </Button>
                    <Button type="primary" style={{ marginLeft: 5 }} icon="plus" onClick={this.doAdd} disabled={!addEnbaled}>
                      新增
                    </Button>
                  </FormItem>
                )
              }
            ])
          }
        </Form>
        {list && (
          <Table
            className={commonStyles.row}
            rowKey="uid"
            columns={columns}
            dataSource={list.result}
            pagination={{
              current: list.pagerInfo.page,
              total: list.pagerInfo.total,
              pageSizeOptions: ['10', '20', '50'],
              showSizeChanger: true,
              showQuickJumper: true,
              showTotal: (total) => `共${total}条`,
              onShowSizeChange: this.handleSizeChange
            }}
            onChange={this.handleTableChange}
          />
        )}
      </div>
    );
  }
}

export default User;
