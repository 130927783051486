import createUID from 'utils/createUID';
import http from 'utils/http';
import { HttpCode } from 'utils/constants';
import reaction from 'reaction';
var INITIAL_DATA = createUID();
var GET_MENU_TREE = createUID();
var initialState = { userInfo: { userId: -1, lastLoginTime: -1 }, departments: [], roleInfo: { roleId: -1, roleName: '',
        roleAuthList: [] }, authTree: {}, vehicleType: [], signatureStatusDict: {}, vehicleEnergyType: [], drivingPermitTypeMap: [], licensePlateType: [], manufactorTypeList: [] };
;
var reducer = function reducer() { var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState; var action = arguments.length > 1 ? arguments[1] : undefined; switch (action.type) {
    case INITIAL_DATA: return Object.assign({}, state, action.data);
    case GET_MENU_TREE: return Object.assign({}, state, { menuTree: action.data });
    default: return state;
} };
Object.assign(reducer, { initialData: function initialData() {
        return function (dispatch) {
            return http.request('v1.common.initialData').then(function (data) {
                if (data.code === HttpCode.OK) {
                    var _data$data = data.data, userInfo = _data$data.userInfo, roleInfo = _data$data.roleInfo, userStatus = _data$data.commDict.userStatus, roleDict = _data$data.roleDict, platformDict = _data$data.platformDict, menuDict = _data$data.menuDict, eventType = _data$data.eventType, vehicleType = _data$data.vehicleType, platforms = _data$data.platforms, signatureStatusDict = _data$data.signatureStatusDict, vehicleEnergyType = _data$data.vehicleEnergyType, drivingPermitTypeMap = _data$data.drivingPermitTypeMap, licensePlateType = _data$data.licensePlateType, team = _data$data.team, invitednterprises = _data$data.invitednterprises, department = _data$data.department, manufactorType = _data$data.manufactorType;
                    dispatch({ type: INITIAL_DATA, data: { userInfo: userInfo, roleInfo: roleInfo, vehicleType: vehicleType, platforms: platforms, signatureStatusDict: signatureStatusDict, vehicleEnergyType: vehicleEnergyType, drivingPermitTypeMap: drivingPermitTypeMap, licensePlateType: licensePlateType, invitednterprises: invitednterprises, departments: department, manufactorTypeList: manufactorType } }); // 初始化本地字典
                    reaction.common.localInitialData({ userStatus: userStatus, roleDict: roleDict, platformDict: platformDict, menuDict: menuDict, eventType: eventType, platforms: platforms, team: team, invitednterprises: invitednterprises, departments: department, manufactorTypeList: manufactorType }); // reaction.me.getAuthTree();
                }
                return data;
            });
        };
    }, updateData: function updateData() {
        return function (dispatch) {
            return http.request('v1.common.initialData', null, false).then(function (data) {
                if (data.code === HttpCode.OK) {
                    var _data$data2 = data.data, userInfo = _data$data2.userInfo, roleInfo = _data$data2.roleInfo, userStatus = _data$data2.commDict.userStatus, roleDict = _data$data2.roleDict, platformDict = _data$data2.platformDict, menuDict = _data$data2.menuDict, team = _data$data2.team, invitednterprises = _data$data2.invitednterprises, department = _data$data2.department, platforms = _data$data2.platforms, manufactorType = _data$data2.manufactorType;
                    dispatch({ type: INITIAL_DATA, data: { userInfo: userInfo, roleInfo: roleInfo } }); // 初始化本地字典
                    reaction.common.localInitialData({ userStatus: userStatus, roleDict: roleDict, platformDict: platformDict, menuDict: menuDict, team: team, platforms: platforms, invitednterprises: invitednterprises, departments: department, manufactorTypeList: manufactorType });
                }
                return data;
            });
        };
    }, getAuthTree: function getAuthTree() { return function (dispatch) { return http.request('v1.system.listAuth').then(function (data) { if (data.code === HttpCode.OK) {
        dispatch({ type: GET_MENU_TREE, data: data.data });
    } return data; }); }; } });
var reducerInstance = reducer;
export default reducerInstance;
