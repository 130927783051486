import React from 'react';
import { connect } from 'react-redux';
import authAndRedirect from 'utils/authAndRedirect';
/**
 * 系统管理
 */
@connect(({ common, me, menu }) => ({ common, me, menu }))
class OperationCenter extends React.Component {
  componentDidMount() {
    authAndRedirect(this);
  }
  render() {
    return null;
  }
}

export default OperationCenter;
