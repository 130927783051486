import React from 'react';
import dayjs from 'dayjs';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { Input, DatePicker, Button, Table, Tabs, Select, Form } from 'antd';

import FormItem from 'components/FormItem';

import reaction from 'reaction';
import { resolveUrlQuery, renderListControls } from 'utils';

import commonStyles from 'style/common.module.scss';

const { RangePicker } = DatePicker;
const TabPane = Tabs.TabPane;

let dateFormat = 'YYYY/MM/DD';
let defaultDatetime = [];
let Option = Select.Option;

let fundsType = [
  // {
  //   code: 1,
  //   name: '充值'
  // },
  {
    code: 2,
    name: '货款',
  },
  {
    code: 3,
    name: '手续费',
  },
  {
    code: 4,
    name: '违约金',
  },
  // {
  //   code: 5,
  //   name: '提现'
  // },
  {
    code: 6,
    name: '补款',
  },
  {
    code: 7,
    name: '运费',
  },
  {
    code: 8,
    name: '司机运费',
  },
  {
    code: 9,
    name: '保证金',
  },
  {
    code: 15,
    name: '结算',
  },
  {
    code: 16,
    name: '结算退款',
  },
  // {
  //   code: 10,
  //   name: '出金账号认证金'
  // }
];

let columns = [
  {
    title: '流水单号',
    dataIndex: 'serialNo',
  },
  {
    title: '日期',
    dataIndex: 'bizTime',
    render: (text) => {
      if (!text) {
        return '';
      }
      return dayjs(text - 0).format('YYYY-MM-DD HH:mm:ss');
    },
  },
  {
    title: '费用类型',
    dataIndex: 'bizType',
  },
  {
    title: '金额（元）',
    dataIndex: 'money',
    render: (text, row) => {
      if (row.inOut.toString() === '1') {
        return '-' + text;
      } else {
        return '+' + text;
      }
    },
  },
  {
    title: '余额（元）',
    dataIndex: 'balance',
  },
  {
    title: '对方账户',
    dataIndex: 'oppAccountNo',
  },
  {
    title: '银行名称',
    dataIndex: 'oppBankName',
  },
  {
    title: '对方账户名',
    dataIndex: 'oppAccountName',
  },
  {
    title: '关联订单',
    dataIndex: 'bizNo',
    render: (text, row) => {
      return text.split(':::')[0];
    },
  },
  {
    title: '平台',
    dataIndex: 'platform',
  },
];

let columns2 = [
  {
    title: '账号',
    dataIndex: 'cardNo',
  },
  {
    title: '开户行',
    dataIndex: 'bankName',
  },
  {
    title: '支行',
    dataIndex: 'branchName',
  },
  {
    title: '户名',
    dataIndex: 'accountName',
  },
  {
    title: '更新时间',
    dataIndex: 'updateTime',
    render: (text) => {
      if (!text) {
        return '';
      }
      return dayjs(text - 0).format('YYYY-MM-DD HH:mm:ss');
    },
  },
  {
    title: '打款金额',
    dataIndex: 'testMoney',
  },
  {
    title: '打款时间',
    dataIndex: 'testTime',
    render: (text) => {
      if (!text) {
        return '';
      }
      return dayjs(text - 0).format('YYYY-MM-DD HH:mm:ss');
    },
  },
  {
    title: '认证通过时间',
    dataIndex: 'authTime',
    render: (text) => {
      if (!text) {
        return '';
      }
      return dayjs(text - 0).format('YYYY-MM-DD HH:mm:ss');
    },
  },
  {
    title: '状态',
    dataIndex: 'status',
  },
];

/**
 * 资金明细
 */
@connect(({ accountList, me }) => ({ accountList, me }))
class ManagementOfMemberFundsDetail extends React.Component {
  state = {
    query: Immutable.Map({
      bizType: '',
      accountNo: '',
      datetime: defaultDatetime,
      serialNo: '',
      oppAccountName: '',
      page: 1,
      length: 10,

      expenditureCardPage: 1,
      expenditureCardLength: 10,
    }),
  };
  onQueryChange = (name, value) => {
    let { query } = this.state;
    query = query.set(name, value);
    this.setState({ query }, () => {});
  };
  doSearch = () => {
    let { query } = this.state;
    query = query.set('page', 1);
    this.setState({ query }, () => {
      this.getFundsFlowList();
    });
  };
  getFundsFlowList = () => {
    let query = this.state.query.toJS();
    let { bizType, oppAccountName, serialNo, page, length } = query;
    let { accountNo } = resolveUrlQuery(this.props.location.search);
    let startTime = query.datetime[0]
      ? query.datetime[0].startOf('day').valueOf()
      : null;
    let endTime = query.datetime[0]
      ? query.datetime[1].endOf('day').valueOf()
      : null;
    let params = {
      accountNo,
      bizType,
      serialNo,
      oppAccountName,
      page,
      length,
    };
    if (startTime) {
      params.startTime = startTime;
    }
    if (endTime) {
      params.endTime = endTime;
    }
    reaction.accountList.getFundsFlowList(params);
  };
  getAccountDetailInfo = () => {
    const { accountNo } = resolveUrlQuery(this.props.location.search);
    reaction.accountList.getFundsAccountDetail({ accountNo });
  };

  handleTableChange = (pagination, filters, sorter) => {
    let { query } = this.state;
    query = query.set('page', pagination.current);
    query = query.set('length', pagination.pageSize);
    this.setState({ query }, () => {
      this.getFundsFlowList();
    });
  };

  exportAll = () => {
    const { accountNo } = resolveUrlQuery(this.props.location.search);
    reaction.accountList.exportAllDetail(accountNo);
  };

  clear = () => {
    let { query } = this.state;
    query = query.set('bizType', '');
    query = query.set('datetime', defaultDatetime);
    query = query.set('serialNo', '');
    query = query.set('oppAccountName', '');

    this.setState({ query }, () => {
      // ......
    });
  };

  onTabChange = (key) => {};

  componentDidMount() {
    this.getExpenditureCardList();
    this.getAccountDetailInfo();
    this.getFundsFlowList();
    this.getExpenditureCardNumber();
  }

  getExpenditureCardList = () => {
    const { accountNo } = resolveUrlQuery(this.props.location.search);
    reaction.accountList.getExpenditureCardList({ accountNo });
  };

  getExpenditureCardNumber = () => {
    const { accountNo } = resolveUrlQuery(this.props.location.search);
    reaction.accountList.getExpenditureCardNumber({ accountNo });
  };

  render() {
    let { query } = this.state;
    let {
      fundsFlow,
      fundsAccountDetail,
      expenditureCard,
      expenditureCardList,
    } = this.props.accountList;

    return (
      <div>
        <Tabs onChange={this.onTabChange} type="card">
          <TabPane tab="会员资金明细" key="1">
            <div style={{ padding: '20px', background: '#eee' }}>
              <h4>
                {fundsAccountDetail.accountName}({fundsAccountDetail.userNo})
              </h4>
              <label htmlFor="">开户时间: </label>
              <span>
                {dayjs(+fundsAccountDetail.createTime).format(
                  'YYYY-MM-DD HH:mm:ss'
                )}
              </span>
              <br />
              <label htmlFor="">结余总额: </label>
              <span>{fundsAccountDetail.balance}元</span>
              <br />
              <label htmlFor="">可用余额: </label>
              <span>{fundsAccountDetail.availableBalance}元</span>
              <br />
              <label htmlFor="">冻结: </label>
              <span>{fundsAccountDetail.freezeMoney}元</span>
              <br />
              <label htmlFor="">总支出: </label>
              <span>{fundsAccountDetail.credit}元</span>
              <br />
              <label htmlFor="">总收入: </label>
              <span>{fundsAccountDetail.debit}元</span>
              <br />
            </div>
            <Form layout="inline">
              {
                renderListControls([
                  <FormItem label="费用类型">
                    <Select
                      placeholder="资金类型"
                      defaultValue={''}
                      onChange={(value) => {
                        this.onQueryChange('bizType', value);
                      }}
                    >
                      <Option key={''} value={''}>
                        全部
                      </Option>
                      {fundsType.map((item) => {
                        return (
                          <Option key={item.code} value={item.code}>
                            {item.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </FormItem>,
                  <FormItem label="日期">
                    <RangePicker
                      value={query.toJS().datetime}
                      onChange={(value) => {
                        this.onQueryChange('datetime', value);
                      }}
                      format={dateFormat}
                    />
                  </FormItem>,
                  <FormItem label="流水单号">
                    <Input
                      value={query.toJS().serialNo}
                      onChange={(e) => {
                        this.onQueryChange('serialNo', e.target.value);
                      }}
                      placeholder="流水单号"
                    />
                  </FormItem>,
                  <FormItem label="对方账户名">
                    <Input
                      value={query.toJS().oppAccountName}
                      onChange={(e) => {
                        this.onQueryChange('oppAccountName', e.target.value);
                      }}
                      placeholder="对方账户名"
                    />
                  </FormItem>,
                  {
                    colProps: {
                      xxl: 24,
                      xl: 16,
                      lg: 24,
                      md: 24,
                    },
                    children: (
                      <FormItem align="right">
                        <Button
                          style={{ margin: '0 0 0 5px' }}
                          type="primary"
                          onClick={this.doSearch}
                        >
                          查询
                        </Button>
                        <Button
                          style={{ margin: '0 0 0 5px' }}
                          type="primary"
                          onClick={this.clear}
                        >
                          清空
                        </Button>
                        <Button style={{ margin: '0 0 0 5px' }} type="primary" onClick={this.exportAll}>导出所有</Button>
                      </FormItem>
                    )
                  }
                ])
              }
            </Form>
            {/* <div style={{ textAlign: 'right' }}>
              <Button onClick={this.exportAll}>导出所有</Button>
            </div> */}
            <div style={{ padding: '20px 0 0 0' }}>
              {fundsFlow && (
                <Table
                  className={commonStyles.row}
                  rowKey="uid"
                  columns={columns}
                  dataSource={fundsFlow.result}
                  pagination={{
                    current: fundsFlow.pagerInfo.page,
                    total: fundsFlow.pagerInfo.total,
                  }}
                  onChange={this.handleTableChange}
                />
              )}
            </div>
          </TabPane>
          <TabPane tab="出金银行卡" key="2">
            <div style={{ padding: '20px', background: '#eee' }}>
              <h4>
                {fundsAccountDetail.accountName}({fundsAccountDetail.userNo})
              </h4>
              <p>
                总计: 已添加出金银行卡{expenditureCard.withdrawAccountNum}张
              </p>
            </div>
            <div style={{ padding: '20px 0 0 0' }}>
              {expenditureCardList && (
                <Table
                  className={commonStyles.row}
                  rowKey="uid"
                  columns={columns2}
                  dataSource={expenditureCardList}
                />
              )}
            </div>
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

export default ManagementOfMemberFundsDetail;
