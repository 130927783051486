/**
 * Created by vincent on 2016/9/12.
 */ export var RoutePath = { INDEX: '/', LOGIN: 'login', FRONT_USER: 'frontUser', FRONT_USER_LIST: 'frontUserList', PERMISSION: 'permission', BLACKLIST: 'blacklist', PLATFORM: 'platform', SYSTEM: 'system', MEMBERCENTER: 'memberCenter', FUNDS: 'fundsManagement', USER: 'user', ROLE: 'role', AUTH: 'authManagement',
    LOG: 'log', FRONTEND: 'frontend', FRONTEND_DETAIL: 'frontendDetail', BACKEND: 'backend', DICT: 'dataDict', OPERATION_CENTER: 'operationCenter', SMS_MANAGEMENT: 'smsManagement', SMS_BLACK_LIST_MANAGEMENT: 'smsBlackListManagement', SMS_BLACK_LIST_ACCOUNT_MANAGEMENT: 'smsBlackListAccountManagement', OFFLINE_DATA_UPLOAD: 'uploadOfflineData', DICT_LIST: 'dataDictList', SMS_HISTORY: 'smsHistory', HISTORY_SMS_DETAIL: 'historySmsDetail', SMS_MODEL_DETAIL: 'smsModelDetail', OPERATION_SCREEN: 'screen', GROUP_SMS: 'groupSms', ADD: 'add', ADD_REGISTER_ACCOUNT: 'registerAccount', ADD_AUTHENTICATE_ACCOUNT: 'authenticateAccount', MODIFY: 'modify', DETAIL: 'detail', RECEIPT_LIST_PRINT: 'receiptListPrint', ACCOUNT_LIST: 'accountList', RECEIPT_LIST: 'receiptList', PRINT_PROGRESS: 'printProgress', BANK_RECEIPT: 'receiptOfBank', FUNDS_MEMBER_MANAGEMENT: 'managementOfMemberFunds',
    FUNDS_MEMBER_ACCOUNT_LIST: 'fundsMemberAccountList',
    FUNDS_MEMBER_ACCOUNT_DETAIL: 'detail',
    FUNDS_MEMBER_EXPENDITURE_CARD: 'expenditureCard',
    NEW_FUNDS_MEMBER_ACCOUNT_LIST: 'newFundsAccountList',
    RULE_CONFIG: 'ruleConfig',
    ADD_RULE_VAR: 'addRuleVar',
    MODIFY_RULE_VAR: 'modifyRule',
    QUALIFICATION_LIST: 'qualificationList',
    CHECK_QUALIFICATION_LIST: 'checkQualificationList',
    CHECK_QUALIFICATION: 'checkQualification',
    IDENTITY_LIST: 'identityList',
    CHECK_IDENTIFY: 'checkIdentity',
    CHECK_COMPANY_IDENTIFY: 'checkCompanyIdentity',
    CHECK_PERSONAL_IDENTIFY: 'checkPersonalIdentity',
    CHECK_COMPANY_IDENTIFY_HISTORY: 'checkCompanyIdentityHistory',
    CHECK_PERSONAL_IDENTIFY_HISTORY: 'checkPersonalIdentityHistory',
    MODIFY_QUALIFICATION: 'modifyQualification',
    COMPANY_QUALIFICATION_PERIOD_MANAGEMENT: 'companyQualificationPeriodManagement',
    EMAIL_SET: 'emailSet',
    MODIFY_PERSONAL_QUALIFICATION: 'modifyPersonalQualification',
    CHECK_QUALIFICATION_DETAIL: 'checkQualificationDetail',
    CHECK_PERSONAL_QUALIFICATION_DETAIL: 'checkPersonalQualification',
    QUALIFICATION_DETAIL: 'qualificationDetail',
    PERSONAL_QUALIFICATION_DETAIL: 'viewPersonalQualificationDetail',
    SUPPLEMENT_COMPANY_QUALIFICATION: 'complementCompanyQualification',
    SUPPLEMENT_PERSONAL_QUALIFICATION: 'complementPersonalQualification',
    VIEW_IDENTITY: 'viewIdentity',
    EDIT_IDENTITY: 'editIdentity',
    VIEW_PERSONAL_AUDIT_DETAIL: 'ViewPersonalAuditDetail',
    VIEW_COMPANY_AUDIT_DETAIL: 'ViewCompanyAuditDetail' // 查看企业资质审核详情
};
export var HttpCode = { OK: 0, AUTH_FAIL: 1000, PASSWORD_ERROR: 3302, ADMIN_USER_NOT_FOUND: 3103, NOT_LOGIN: 1004, NO_TOKEN: 1102, NO_TOKEN_1: 1002, INVALID_TOKEN: 3303, INVALID_TICKET: 3304, EXPORT_FAIL: 4444 // 导出失败
};
export var UserStatus = { ENABLED: 1, DISABLED: 0 };
export var AuthCodes = { MEMBER: 'user',
    PERMISSION: 'access',
    PLATFORM: 'system.platform',
    SYSTEM: 'system',
    FUNDS: 'funds',
    OPERATION: 'operation',
    MEMBERCENTER: 'member', SCREEN: 'screen', SYSTEM_DATA_DICT: 'system.dict',
    SYSTEM_DICT_SAVE: 'system.dict.save',
    SYSTEM_DICT_TYPE_UPDATE: 'system.dict.type.update',
    SYSTEM_DICT_TYPE_SAVE: 'system.dict.type.save',
    SYSTEM_DICT_DICT_AND_TYPE_GET: 'system.dict.dictAndType.get',
    SYSTEM_DICT_UPDATE: 'system.dict.update',
    SYSTEM_DICT_DELETE: 'system.dict.delete',
    SYSTEM_DICT_TYPE_DELETE: 'system.dict.type.delete',
    SYSTEM_DICT_LIST: 'system.dict.list',
    SYSTEM_DICT_TYPE_LIST: 'system.dict.type.list',
    SYSTEM_DICT_LOG_LIST: 'system.dict.log.list',
    SYSTEM_DICT_VIEW: 'system.dict.view',
    MEMBER_FRONT_USER: 'user.account.list',
    MEMBER_FRONT_USER_LIST: 'user.account.list',
    MEMBER_FRONT_USER_QUERY: 'account.frontUser.query',
    ADD_MEMBER: 'user.account.add',
    ADD_MEMBER_REGISTER: 'user.account.add',
    ADD_MEMBER_AUTHENTICATE: 'user.account.add',
    MEMBER_FRONT_USER_MANAGEMENT: 'user.account.management',
    USER_ACCOUNT_UPDATE: 'user.account.update',
    USER_ACCOUNT_STATUS_UPDATE: 'user.account.status.update',
    USER_PASSWORD_UPDATE: 'user.account.password.update',
    USER_USERNAME_UPDATE: 'user.account.username.update',
    USER_PHONE_UPDATE: 'user.account.phone.update',
    USER_EMAIL_UPDATE: 'user.account.email.update',
    USER_ACCOUNT_ADD: 'user.account.add',
    PERMISSION_BLACKLIST: 'access.blackList',
    PERMISSION_BLACKLIST_LIST: 'access.blackList.list',
    PERMISSION_BLACKLIST_ADD: 'access.blackList.add',
    PERMISSION_BLACKLIST_UPDATE: 'access.blackList.update',
    PERMISSION_BLACKLIST_DELETE: 'access.blackList.delete',
    PERMISSION_BLACKLIST_QUERY: 'access.blackList.query',
    PLATFORM_SYSTEM: 'system.platform',
    PLATFORM_SYSTEM_LIST: 'system.platform.list',
    PLATFORM_SYSTEM_ADD: 'system.platform.add',
    PLATFORM_SYSTEM_UPDATE: 'system.platform.update',
    PLATFORM_SYSTEM_DELETE: 'system.platform.delete',
    PLATFORM_SYSTEM_QUERY: 'system.platform.query',
    SYSTEM_USER: 'system.backUser',
    SYSTEM_USER_LIST: 'system.backUser.list',
    SYSTEM_USER_ADD: 'system.backUser.add',
    SYSTEM_USER_UPDATE: 'system.backUser.update',
    SYSTEM_USER_DELETE: 'system.backUser.delete',
    SYSTEM_USER_QUERY: 'system.backUser.query',
    SYSTEM_ROLE: 'system.role',
    SYSTEM_ROLE_LIST: 'system.role.list',
    SYSTEM_ROLE_ADD: 'system.role.add',
    SYSTEM_ROLE_UPDATE: 'system.role.update',
    SYSTEM_ROLE_DELETE: 'system.role.delete',
    SYSTEM_ROLE_QUERY: 'system.role.query',
    SYSTEM_LOG: 'system.log',
    SYSTEM_LOG_FRONTEND: 'system.frontLog',
    SYSTEM_LOG_FRONTEND_LIST: 'system.frontLog.list',
    SYSTEM_LOG_BACKEND: 'system.backLog',
    SYSTEM_LOG_BACKEND_LIST: 'system.backLog.list',
    SYSTEM_AUTH: 'system.auth',
    SYSTEM_AUTH_LIST: 'system.auth.list',
    SYSTEM_AUTH_ADD: 'system.auth.add',
    SYSTEM_AUTH_QUERY: 'system.auth.query',
    SYSTEM_AUTH_DELETE: 'system.auth.delete',
    SYSTEM_AUTH_UPDATE: 'system.auth.update',
    SYSTEM_FRONT_USER_MENU: 'system.frontUser.menu',
    FUNDS_RECEIPT: 'funds.receipt',
    FUNDS_ACCOUNT_LIST: 'funds.receipt.account.list',
    FUNDS_RECEIPT_LIST: 'funds.receipt.accountFlow.list',
    FUNDS_BANK_RECEIPT: 'funds.bank.receipt',
    FUNDS_MEMBER: 'funds.member',
    FUNDS_MEMBER_ACCOUNT_LIST: 'funds.member.account.list',
    FUNDS_MANAGEMENT_DETAIL: 'funds.member.account.query',
    FUNDS_MANAGEMENT_EXPENDITURE_CARDS: 'funds.member.withdrawBankCard.list',
    FUNDS_MANAGEMENT_CREATE_ACCOUNT: 'funds.member.account.add',
    FUNDS_RECORD: 'funds.member.accountFlow',
    FUNDS_RECORD_EXPENDITURE: 'funds.member.accountFlow.list',
    FUNDS_RECORD_INCOME: 'funds.member.accountFlow.list',
    FUNDS_FREEZE_AND_UNFREEZE: 'funds.member.accountFlow.list',
    FUND_RULE_CONFIG_LIST: 'funds.config.rule.list',
    FUND_RULE_CONFIG_ADD: 'funds.config.rule.add',
    FUND_RULE_CONFIG_UPDATE: 'funds.config.rule.update',
    OPERATION_SMS: 'operation.sms',
    OPERATION_BLACKLIST: 'operation.blacklist',
    OPERATION_OFFLINE: 'operation.offline',
    OPERATION_SMS_TEMPLATE_SAVE: 'operation.sms.template.save',
    OPERATION_SMS_TEMPLATE_UPDATE: 'operation.sms.template.update',
    OPERATION_SMS_TEMPLATE_GET: 'operation.sms.template.get',
    OPERATION_SMS_TEMPLATE_LIST: 'operation.sms.template.list',
    OPERATION_SMS_TEMPLATE_LOG_LIST: 'operation.sms.template.log.list',
    OPERATION_SMS_RECORD_LIST: 'operation.sms.record.list',
    OPERATION_SMS_RECORD_GET: 'operation.sms.record.get',
    OPERATION_OFFLINE_DATA_UPLOAD: 'operation.offline.data.uploadOfflineData',
    OPERATION_SMS_BLACKLIST_MANAGEMENT: 'operation.blacklist.smsBlackListManagement',
    OPERATION_SMS_ACCOUNT_BLACKLIST_MANAGEMENT: 'operation.blacklist.queryAccount',
    OPERATION_SMS_ADD_ACCOUNT_BLACKLIST: 'operation.blacklist.batchAddAccountBlacklist',
    OPERATION_SMS_ADD_PHONE_BLACKLIST: 'operation.blacklist.addPhoneBlacklist',
    OPERATION_SCREEN: 'operation.screen',
    OPERATION_GROUPSMS_LIST: 'operation.groupSms.list',
    OPERATION_GROUPSMS_CREATE: 'operation.groupSms.create',
    OPERATION_GROUPSMS_COPY: 'operation.groupSms.copy',
    OPERATION_GROUPSMS_VIEW: 'operation.groupSms.view',
    OPERATION_GROUPSMS_CLOSE: 'operation.groupSms.close',
    MEMBER_CENTER_COMPANY_MODIFY: 'member.center.company.modify',
    MEMBER_CENTER_QUALIFICATION_LIST: 'member.center.cert.list',
    MEMBER_CENTER_CHECK_QUALIFICATION_LIST: 'member.center.checkQualificationList',
    MEMBER_CENTER_IDENTITY_LIST: 'member.center.identityList',
    MEMBER_CENTER_IDENTITY_DETAIL: 'member.center.identity.getIdentity',
    MEMBER_CENTER_MODIFY_IDENTITY: 'member.center.identity.getIdentity',
    MEMBER_CENTER_CHECK_IDENTITY_LIST: 'member.center.identity.listIdentityCert',
    MEMBER_CENTER_CHECK_COMPANY_IDENTITY: 'member.center.identity.auditEnterpriseIdentity',
    MEMBER_CENTER_CHECK_PERSONAL_IDENTITY: 'member.center.identity.auditIndividualIdentity',
    MEMBER_CENTER_IDENTITY_MANAGEMENT: 'member.center.identity.management',
    MEMBER_CENTER_QUALIFICATION_PERIOD_MANAGEMENT: 'member.center.cert.companyQualificationPeriodManagement',
    MEMBER_CENTER_CERT_EMAIL_SET: 'member.center.cert.emailSet',
    MEMBER_CENTER_VIEW_COMPANY_QUALIFICATION_DETAIL: 'member.center.cert.getEnterpriseCert',
    MEMBER_CENTER_VIEW_PERSONAL_QUALIFICATION_DETAIL: 'member.center.cert.getIndividualCert',
    MEMBER_CENTER_MODIFY_PERSONAL_QUALIFICATION: 'member.center.cert.updateIndividualCert',
    MEMBER_CENTER_MODIFY_COMPANY_QUALIFICATION: 'member.center.cert.updateEnterepriseCert',
    MEMBER_CENTER_AUDIT_COMPANY_QUALIFICATION: 'member.center.cert.auditEnterepriseCert',
    MEMBER_CENTER_AUDIT_PERSONAL_QUALIFICATION: 'member.center.cert.auditPersonalCert',
    MEMBER_CENTER_COMPLEMENT_PERSONAL_QUALIFICATION: 'member.center.cert.complementIndividualCert',
    MEMBER_CENTER_COMPLEMENT_COMPANY_QUALIFICATION: 'member.center.cert.complementEnterpriseCert',
    BID_MEMBER_MENU: 'bid.member',
    BID_MEMBER_LIST: 'bid.member.list' // 招采会员
}; // 出金申请状态
export var withdrawStatus = { start: 1, progress: 2, success: 3, fail: 4 };
export var bidIdentityCodes = { purchaser: 'bid_purchaser', supplier: 'bid_supplier' };
export var cenpurIdentityCodes = { purchaser: 'cenpur_purchaser', supplier: 'cenpur_supplier' };
export var platformList = { trade: { label: '交易平台', value: 'trade', code: 'trade' }, logistics: { label: '物流平台', value: 'logistics', code: 'logistics' }, cenpur: { label: '大宗交易', value: 'cenpur', code: 'cenpur' }, wms: { label: '仓储平台', value: 'wms', code: 'wms' }, bid: { label: '招标采购', value: 'bid', code: 'bid' }, fin: { label: '金融平台', value: 'fin', code: 'fin' }, equipment: { label: '设备管理', value: 'equipment', code: 'equipment' }, fpop: { label: '五部一管', value: 'fpop', code: 'fpop' } };
export var platformCodes = { bid: platformList.bid.code, oa: 'oa', cenpur: platformList.cenpur.code };
