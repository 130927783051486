import React from 'react';
import ReactDOM from 'react-dom';
import reaction from 'reaction/index';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { Input, Button, Radio, message } from 'antd';
import history from 'utils/history';
import setCursorPosition from 'utils/setCursorPosition';
import { RoutePath } from 'utils/constants';

const { TextArea } = Input;
const RadioGroup = Radio.Group;

@connect(({ smsManagement }) => ({ smsManagement }))
class AddSmsModel extends React.Component {
  state = {
    params: Immutable.Map({
      templateCode: '',
      templateContent: '',
      templateDescription: '',
      alismsTemplateCode: '',
      isValid: 1,
      createTime: '',
      lastModifiedTime: '',
      templateName: '',
    }),
    cursorPosition: 0,
  };

  onParamsChange = (name, value) => {
    let { params } = this.state;
    params = params.set(name, value);
    this.setState({ params }, () => {});
  };

  setCursorPosition = (position) => {
    this.setState({
      cursorPosition: position,
    });
  };

  back = () => {
    history.push({
      pathname: `/${RoutePath.OPERATION_CENTER}/${RoutePath.SMS_MANAGEMENT}`,
    });
  };

  save = () => {
    let params = this.state.params.toJS();

    if (
      !params.templateCode ||
      !params.templateContent ||
      !params.templateDescription ||
      !params.alismsTemplateCode ||
      !params.templateName
    ) {
      message.warning('请填写完整的模板信息再提交!');
      return;
    }

    reaction.smsManagement.addSmsModel(params).then((res) => {
      if (res.code === 0) {
        message.success('新增短信模板成功!');
        history.push({
          pathname: `/${RoutePath.OPERATION_CENTER}/${RoutePath.SMS_MANAGEMENT}`,
        });
      }
    });
  };

  insertVar = () => {
    let params = this.state.params;
    let templateContent = this.state.params.toJS().templateContent;
    let position = this.state.cursorPosition - 1;
    if (this.state.cursorPosition !== 0) {
      templateContent = templateContent.split('');
      templateContent[position] = templateContent[position] + '${}';
      templateContent = templateContent.join('');
      params = params.set('templateContent', templateContent);
    } else {
      templateContent = '${}' + templateContent;
      params = params.set('templateContent', templateContent);
    }

    this.setState(
      {
        params,
      },
      () => {
        setCursorPosition(
          ReactDOM.findDOMNode(this.refs.content),
          position + 3
        );
      }
    );
  };

  componentDidMount() {}

  render() {
    let params = this.state.params.toJS();

    return (
      <div>
        <h4>短信模板</h4>
        <div style={{ padding: 10 }}>
          <label style={{ display: 'inline-block', width: 100 }} htmlFor="">
            模板名称：
          </label>
          <Input
            maxLength={30}
            style={{ width: 250 }}
            value={params.templateName}
            onChange={(e) => {
              this.onParamsChange('templateName', e.target.value);
            }}
            placeholder="请输入模板名称"
          />
        </div>
        <div style={{ padding: 10 }}>
          <label style={{ display: 'inline-block', width: 100 }} htmlFor="">
            code：
          </label>
          <Input
            maxLength={100}
            style={{ width: 250 }}
            value={params.templateCode}
            onChange={(e) => {
              this.onParamsChange('templateCode', e.target.value);
            }}
            placeholder="请输入code"
          />
        </div>
        <div style={{ padding: 10 }}>
          <label style={{ display: 'inline-block', width: 100 }} htmlFor="">
            服务商代码：
          </label>
          <Input
            maxLength={20}
            style={{ width: 250 }}
            value={params.alismsTemplateCode}
            onChange={(e) => {
              this.onParamsChange('alismsTemplateCode', e.target.value);
            }}
            placeholder="请输入服务商代码"
          />
        </div>
        <div style={{ padding: 10 }}>
          <label
            style={{ display: 'inline-block', width: 100, float: 'left' }}
            htmlFor=""
          >
            描述：
          </label>
          <TextArea
            value={params.templateDescription}
            onChange={(e) => {
              this.onParamsChange('templateDescription', e.target.value);
            }}
            rows={4}
            maxLength={100}
            style={{ width: 300 }}
            placeholder="用途，场景等描述，最多100个汉字。"
          />
        </div>
        <div style={{ padding: 10 }}>
          <label style={{ display: 'inline-block', width: 100 }} htmlFor="">
            内容：
          </label>
          <Button onClick={this.insertVar}>插入变量</Button>
          <span style={{ fontSize: 12, color: '#888', marginLeft: 10 }}>
            变量格式为： ${'{变量名}'}，且变量真实值不得超过20字符。
          </span>
          <div>
            <TextArea
              ref="content"
              value={params.templateContent}
              onClick={(e) => {
                this.setCursorPosition(e.target.selectionEnd);
              }}
              onChange={(e) => {
                this.setCursorPosition(e.target.selectionEnd);
                this.onParamsChange('templateContent', e.target.value);
              }}
              maxLength={200}
              rows={4}
              style={{ width: 300, marginLeft: 100, marginTop: 10 }}
              placeholder="短信模板内容，单条短信70汉字以内，含变量真实值长度。为避免短信过长导致分条发送，请保存之前确认字数。"
            />
          </div>
        </div>
        <div style={{ padding: 10 }}>
          <label style={{ display: 'inline-block', width: 100 }} htmlFor="">
            状态：
          </label>
          <RadioGroup
            onChange={(e) => {
              this.onParamsChange('isValid', e.target.value);
            }}
            value={params.isValid}
          >
            <Radio value={1}>启用</Radio>
            <Radio value={0}>禁用</Radio>
          </RadioGroup>
        </div>
        <div style={{ padding: 10 }}>
          <Button onClick={this.back}>返回</Button>
          <Button onClick={this.save} style={{ marginLeft: '10px' }}>
            保存
          </Button>
        </div>
      </div>
    );
  }
}

export default AddSmsModel;
