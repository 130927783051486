import React from 'react';
import {
  Tree,
  Button,
  Modal,
  Icon,
  Select,
  Input,
  Form,
  Radio,
  message,
} from 'antd';
import { connect } from 'react-redux';
import reaction from 'reaction';
import { localPlatformCode } from 'config';

import commonStyles from 'style/common.module.scss';

// import ExpenditureRecords from '../../FundsManagement/TransactionRecords/ExpenditureRecords';

const { TreeNode } = Tree;
const { Option } = Select;
const { TextArea } = Input;

/**
 * 角色管理
 */
@connect(({ system, me, common, authManagement }) => ({
  system,
  me,
  common,
  authManagement,
}))
@Form.create()
class BizMenuManagement extends React.Component {
  state = {
    platformCode: 'common',
    currentMenu: {},
    characterCode: [],
    expandedKeys: [],
    addTopMenuModalVisible: false,
    addModalVisible: false,
    editModalVisible: false,
    deleteModalVisible: false,
  };
  getTreeMenuData = () => {
    let params = {
      platformCode: this.state.platformCode,
    };
    return reaction.authManagement.getFrontMenuTree(params);
  };
  getCharacterDict = () => {
    let params = {
      platformCode: this.state.platformCode,
    };
    return reaction.common.getCharacterDict(params);
  };
  handleBtnWrapperMouseLeave = (e) => {
    let target = e.currentTarget;
    let btnWrapper = target.getElementsByClassName('btn-wrapper')[0];
    btnWrapper.style.display = 'none';
  };
  handleBtnWrapperMouseEnter = (e) => {
    let target = e.currentTarget;
    let btnWrapper = target.getElementsByClassName('btn-wrapper')[0];
    btnWrapper.style.display = 'inline-block';
  };
  handleAdd = (d) => {
    this.setState({
      currentMenu: d,
      expandedKeys: [
        d.children && d.children.length
          ? d.id.toString()
          : (d.parentId || d.id).toString(),
      ],
      addModalVisible: true,
    });
  };
  handleEdit = (d) => {
    let characterDict = this.props.common.characterDict;
    reaction.authManagement.getMenuCharacterCode({ id: d.id }).then((res) => {
      let characterCode = res.data.characterCode;
      characterCode = characterCode.filter((code, index) => {
        return (
          characterDict.some((item) => item.characterCode === code) &&
          characterCode.indexOf(code) === index
        );
      });
      this.setState(
        {
          characterCode: characterCode,
        },
        () => {
          d.characterCode = this.state.characterCode;
          this.setState({
            currentMenu: d,
            expandedKeys: [
              d.children && d.children.length
                ? d.id.toString()
                : (d.parentId || d.id).toString(),
            ],
            editModalVisible: true,
          });
        }
      );
    });
  };
  handleDelete = (d) => {
    this.setState({
      currentMenu: d,
      deleteModalVisible: true,
    });
  };

  addMenu = () => {
    let currentMenu = this.state.currentMenu;
    let params = {
      parentId: currentMenu.id,
      platformCode: this.state.platformCode,
    };
    this.props.form.validateFields(
      [
        'newMenu.menuCode',
        'newMenu.menuName',
        'newMenu.visitPath',
        'newMenu.remarks',
        'newMenu.valid',
        'newMenu.characterCode',
      ],
      (err, values) => {
        if (!err) {
          reaction.authManagement
            .addFrontUserMenu({ ...params, ...values.newMenu })
            .then((res) => {
              if (res.code === 0) {
                this.getTreeMenuData().then((res) => {
                  // this.setState({
                  //   expandedKeys: [currentMenu.menuCode]
                  // });
                });
                this.cancelAdd();
                message.success('新增菜单成功');
              }
            });
        }
      }
    );
  };
  cancelAdd = () => {
    this.props.form.resetFields();
    this.setState({
      addModalVisible: false,
    });
  };

  modifyMenu = () => {
    let currentMenu = this.state.currentMenu;
    let params = {
      parentId: currentMenu.parentId,
      platformCode: this.state.platformCode,
      id: currentMenu.id,
    };
    this.props.form.validateFields(
      [
        'menuCode',
        'menuName',
        'visitPath',
        'remarks',
        'valid',
        'characterCode',
      ],
      (err, values) => {
        if (!err) {
          reaction.authManagement
            .modifyFrontUserMenu({ ...params, ...values })
            .then((res) => {
              if (res.code === 0) {
                this.getTreeMenuData().then((res) => {});
                this.cancelEdit();
                message.success('修改菜单成功');
              }
            });
        }
      }
    );
  };
  cancelEdit = () => {
    this.props.form.resetFields();
    this.setState({
      editModalVisible: false,
    });
  };

  deleteMenu = () => {
    reaction.authManagement
      .deleteFrontUserMenu(this.state.currentMenu.id)
      .then((res) => {
        if (res.code === 0) {
          this.getTreeMenuData();
          this.cancelDelete();
          message.success('删除菜单成功');
        }
      });
  };
  cancelDelete = () => {
    this.setState({
      deleteModalVisible: false,
    });
  };
  composeTree = (data) => {
    return data.map((d) => {
      if (!d.children || !d.children.length) {
        return (
          <TreeNode
            disabled={!d.valid}
            title={
              <div
                onMouseLeave={this.handleBtnWrapperMouseLeave}
                onMouseEnter={this.handleBtnWrapperMouseEnter}
              >
                <span>{d.menuName}</span>
                <span className="btn-wrapper" style={{ display: 'none' }}>
                  <Icon
                    style={{ marginLeft: 20 }}
                    title="新增"
                    type="file-add"
                    onClick={() => {
                      this.handleAdd(d);
                    }}
                  />
                  <Icon
                    style={{ marginLeft: 5 }}
                    title="修改"
                    type="edit"
                    onClick={() => {
                      this.handleEdit(d);
                    }}
                  />
                  <Icon
                    style={{ marginLeft: 5 }}
                    title="删除"
                    type="delete"
                    onClick={() => {
                      this.handleDelete(d);
                    }}
                  />
                </span>
              </div>
            }
            key={d.id.toString()}
          ></TreeNode>
        );
      } else {
        return (
          <TreeNode
            disabled={!d.valid}
            title={
              <div
                onMouseLeave={this.handleBtnWrapperMouseLeave}
                onMouseEnter={this.handleBtnWrapperMouseEnter}
              >
                <span>{d.menuName}</span>
                <span className="btn-wrapper" style={{ display: 'none' }}>
                  <Icon
                    style={{ marginLeft: 20 }}
                    title="新增"
                    type="file-add"
                    onClick={() => {
                      this.handleAdd(d);
                    }}
                  />
                  <Icon
                    style={{ marginLeft: 5 }}
                    title="修改"
                    type="edit"
                    onClick={() => {
                      this.handleEdit(d);
                    }}
                  />
                  <Icon
                    style={{ marginLeft: 5 }}
                    title="删除"
                    type="delete"
                    onClick={() => {
                      this.handleDelete(d);
                    }}
                  />
                </span>
              </div>
            }
            key={d.id.toString()}
          >
            {this.composeTree(d.children)}
          </TreeNode>
        );
      }
    });
  };

  handlePlatformChange = (v) => {
    this.setState(
      {
        platformCode: v,
      },
      () => {
        this.getTreeMenuData();
        this.getCharacterDict();
      }
    );
  };

  handleAddTopMenu = () => {
    this.setState({
      addTopMenuModalVisible: true,
    });
  };

  addTopMenu = () => {
    // let currentMenu = this.state.currentMenu;
    let params = {
      parentId: null,
      platformCode: this.state.platformCode,
    };
    this.props.form.validateFields(
      [
        'newTopMenu.menuCode',
        'newTopMenu.menuName',
        'newTopMenu.visitPath',
        'newTopMenu.remarks',
        'newTopMenu.valid',
        'newTopMenu.characterCode',
      ],
      (err, values) => {
        if (!err) {
          reaction.authManagement
            .addFrontUserMenu({ ...params, ...values.newTopMenu })
            .then((res) => {
              if (res.code === 0) {
                this.getTreeMenuData();
                this.cancelAddTopMenu();
                message.success('新增菜单成功');
              }
            });
        }
      }
    );
  };

  cancelAddTopMenu = () => {
    this.props.form.resetFields();
    this.setState({
      addTopMenuModalVisible: false,
    });
  };

  componentDidMount() {
    this.getCharacterDict();
    this.getTreeMenuData();
  }

  render() {
    let platforms = this.props.common.platforms;
    let characterDict = this.props.common.characterDict;
    let treeData = this.props.authManagement.frontMenuTreeData;
    let currentMenu = this.state.currentMenu;
    const { getFieldDecorator } = this.props.form;
    let expandedKeys = this.state.expandedKeys;
    const formItemLayout = {
      labelCol: {
        span: 6,
      },
      wrapperCol: {
        span: 18,
      },
    };

    return (
      <div>
        <h2 className={commonStyles.row}>前台菜单管理</h2>
        <div style={{ padding: '20px 0 30px 50px' }}>
          <label>平台: </label>
          <Select
            value={this.state.platformCode}
            style={{ width: 200 }}
            onChange={this.handlePlatformChange}
          >
            {platforms.filter(p => p.platformCode !== localPlatformCode).map((p) => {
              return (
                <Option key={p.platformCode} value={p.platformCode}>
                  {p.platformName}
                </Option>
              );
            })}
          </Select>
        </div>
        <div style={{ padding: '0 0 10px 50px' }}>
          <Button icon="plus" onClick={this.handleAddTopMenu}>
            新增最外层菜单
          </Button>
        </div>
        <div style={{ padding: '0 0 0 50px' }}>
          {treeData && treeData.length ? (
            <Tree
              defaultExpandedKeys={expandedKeys}
              key={Math.random().toString()}
              defaultExpandParent
              showLine
            >
              {this.composeTree(treeData)}
            </Tree>
          ) : null}
        </div>

        <Modal
          title="新增最外层菜单"
          visible={this.state.addTopMenuModalVisible}
          onOk={this.addTopMenu}
          onCancel={this.cancelAddTopMenu}
        >
          <Form {...formItemLayout}>
            <Form.Item label="菜单code">
              {getFieldDecorator('newTopMenu.menuCode', {
                rules: [
                  {
                    required: true,
                    message: '请输入菜单code',
                  },
                  {
                    pattern: /[a-zA-Z0-9_]{1,50}/,
                    message: '请输入1-50个字符(只能包含数字、字母、下划线)',
                  },
                ],
                initialValue: '',
              })(<Input maxLength={50} />)}
            </Form.Item>
            <Form.Item label="菜单名称">
              {getFieldDecorator('newTopMenu.menuName', {
                rules: [
                  {
                    required: true,
                    message: '请输入菜单名称',
                  },
                ],
                initialValue: '',
              })(<Input maxLength={50} />)}
            </Form.Item>
            <Form.Item label="身份特征">
              {getFieldDecorator('newTopMenu.characterCode', {
                rules: [
                  {
                    required: false,
                    message: '请选择',
                  },
                ],
                initialValue: [],
              })(
                <Select mode="multiple">
                  {characterDict.map((c) => {
                    return (
                      <Option key={c.characterCode} value={c.characterCode}>
                        {c.characterName}
                      </Option>
                    );
                  })}
                </Select>
              )}
            </Form.Item>
            <Form.Item label="访问路径">
              {getFieldDecorator('newTopMenu.visitPath', {
                rules: [
                  {
                    required: false,
                    message: '请输入访问路径',
                  },
                ],
                initialValue: '',
              })(<Input maxLength={100} />)}
            </Form.Item>
            <Form.Item label="备注">
              {getFieldDecorator('newTopMenu.remarks', {
                rules: [
                  {
                    required: false,
                    message: '请输入备注',
                  },
                ],
                initialValue: '',
              })(<TextArea rows={3} maxLength={120} />)}
            </Form.Item>
            <Form.Item label="是否生效">
              {getFieldDecorator('newTopMenu.valid', {
                rules: [
                  {
                    required: false,
                  },
                ],
                initialValue: true,
              })(
                <Radio.Group>
                  <Radio value={true}>启用</Radio>
                  <Radio value={false}>禁用</Radio>
                </Radio.Group>
              )}
            </Form.Item>
          </Form>
        </Modal>

        <Modal
          title="新增菜单"
          visible={this.state.addModalVisible}
          onOk={this.addMenu}
          onCancel={this.cancelAdd}
        >
          <Form {...formItemLayout}>
            <Form.Item label="菜单code">
              {getFieldDecorator('newMenu.menuCode', {
                rules: [
                  {
                    required: true,
                    message: '请输入菜单code',
                  },
                  {
                    pattern: /[a-zA-Z0-9_]{1,50}/,
                    message: '请输入1-50个字符(只能包含数字、字母、下划线)',
                  },
                ],
                initialValue: '',
              })(<Input maxLength={50} />)}
            </Form.Item>
            <Form.Item label="菜单名称">
              {getFieldDecorator('newMenu.menuName', {
                rules: [
                  {
                    required: true,
                    message: '请输入菜单名称',
                  },
                ],
                initialValue: '',
              })(<Input maxLength={50} />)}
            </Form.Item>
            <Form.Item label="身份特征">
              {getFieldDecorator('newMenu.characterCode', {
                rules: [
                  {
                    required: false,
                    message: '请选择',
                  },
                ],
                initialValue: [],
              })(
                <Select mode="multiple">
                  {characterDict.map((c) => {
                    return (
                      <Option key={c.characterCode} value={c.characterCode}>
                        {c.characterName}
                      </Option>
                    );
                  })}
                </Select>
              )}
            </Form.Item>
            <Form.Item label="访问路径">
              {getFieldDecorator('newMenu.visitPath', {
                rules: [
                  {
                    required: false,
                    message: '请输入访问路径',
                  },
                ],
                initialValue: '',
              })(<Input maxLength={100} />)}
            </Form.Item>
            <Form.Item label="备注">
              {getFieldDecorator('newMenu.remarks', {
                rules: [
                  {
                    required: false,
                    message: '请输入备注',
                  },
                ],
                initialValue: '',
              })(<TextArea rows={3} maxLength={120} />)}
            </Form.Item>
            <Form.Item label="是否生效">
              {getFieldDecorator('newMenu.valid', {
                rules: [
                  {
                    required: false,
                    message: '请输入备注',
                  },
                ],
                initialValue: currentMenu.valid,
              })(
                <Radio.Group disabled={!currentMenu.valid}>
                  <Radio value={true}>启用</Radio>
                  <Radio value={false}>禁用</Radio>
                </Radio.Group>
              )}
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          title="修改菜单"
          visible={this.state.editModalVisible}
          onOk={this.modifyMenu}
          onCancel={this.cancelEdit}
        >
          <Form {...formItemLayout}>
            <Form.Item label="菜单code">
              {getFieldDecorator('menuCode', {
                rules: [
                  {
                    required: true,
                    message: '请输入菜单code',
                  },
                  {
                    pattern: /[a-zA-Z0-9_]{1,50}/,
                    message: '请输入1-50个字符(只能包含数字、字母、下划线)',
                  },
                ],
                initialValue: currentMenu.menuCode,
              })(<Input maxLength={50} />)}
            </Form.Item>
            <Form.Item label="菜单名称">
              {getFieldDecorator('menuName', {
                rules: [
                  {
                    required: true,
                    message: '请输入菜单名称',
                  },
                ],
                initialValue: currentMenu.menuName,
              })(<Input maxLength={50} />)}
            </Form.Item>
            <Form.Item label="身份特征">
              {getFieldDecorator('characterCode', {
                rules: [
                  {
                    required: false,
                    message: '请选择',
                  },
                ],
                initialValue: currentMenu.characterCode || [],
              })(
                <Select mode="multiple">
                  {characterDict.map((c) => {
                    return (
                      <Option key={c.characterCode} value={c.characterCode}>
                        {c.characterName}
                      </Option>
                    );
                  })}
                </Select>
              )}
            </Form.Item>
            <Form.Item label="访问路径">
              {getFieldDecorator('visitPath', {
                rules: [
                  {
                    required: false,
                    message: '请输入访问路径',
                  },
                ],
                initialValue: currentMenu.visitPath,
              })(<Input maxLength={100} />)}
            </Form.Item>
            <Form.Item label="备注">
              {getFieldDecorator('remarks', {
                rules: [
                  {
                    required: false,
                    message: '请输入备注',
                  },
                ],
                initialValue: currentMenu.remarks,
              })(<TextArea rows={3} maxLength={120} />)}
            </Form.Item>
            <Form.Item label="是否生效">
              {getFieldDecorator('valid', {
                rules: [
                  {
                    required: false,
                    message: '请输入备注',
                  },
                ],
                initialValue: currentMenu.valid,
              })(
                <Radio.Group>
                  <Radio value={true}>启用</Radio>
                  <Radio value={false}>禁用</Radio>
                </Radio.Group>
              )}
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          title="删除菜单"
          visible={this.state.deleteModalVisible}
          onOk={this.deleteMenu}
          onCancel={this.cancelDelete}
        >
          {currentMenu.children && currentMenu.children.length ? (
            <p>确定删除该菜单及其子菜单吗?</p>
          ) : (
            <p>确定删除该菜单吗?</p>
          )}
        </Modal>
      </div>
    );
  }
}

export default BizMenuManagement;
