import React from 'react';
import { DatePicker, Checkbox } from 'antd';
import dayjs from 'dayjs';
// const CheckboxGroup = Checkbox.Group;

const { RangePicker } = DatePicker;
const infiniteTimestamp = 4102358400000;

export default class DatePickAndCheckboxFormItem extends React.Component {
  static getDerivedStateFromProps(nextProps) {
    // Should be a controlled component.
    if ('value' in nextProps) {
      return {
        ...(nextProps.value || {}),
      };
    }
    return null;
  }
  
  constructor(props) {
    super(props);
    const value = props.value;
    this.state = {
      expirationTime: value ? dayjs(value) : null,
      isRangePicker: props.mode === 'multiple',
    };
  }
  
  triggerChange = ({ expirationTime, startTime }) => {
    // Should provide an event to pass value to Form.
    const onChange = this.props.onChange;
    if (!onChange) return ;
    if (this.state.isRangePicker) {
      onChange([ startTime, expirationTime ]);
    } else {
      onChange({ expirationTime });
    }
  };
  
  onExpirationTimeChange = (expirationTime) => {
    this.triggerChange({ expirationTime });
  };
  handleRangeChange = (value) => {
    this.triggerChange({
      startTime: value[0],
      expirationTime: value[1]
    });
  }
  handleStartTimeChange = (value) => {
    this.triggerChange({
      startTime: value,
      expirationTime: dayjs(this.props.value[1]),
    });
  }
  
  onForeverChange = (e) => {
    let expirationTime = e.target.checked ? dayjs(e.target.value) : null;
    this.triggerChange({ expirationTime, startTime: null });
  };
  componentDidMount () {
  
  }
  
  render () {
    const { isRangePicker } = this.state;
    const props = this.props;
    const value = props.value || (isRangePicker ? [] : { expirationTime: null });

    const rangePickerValue = [ value[0] ? dayjs(value[0]) : null, value[1] ? dayjs(value[1]) : null ];
    const singlePickerValue = value.expirationTime ? dayjs(value.expirationTime) : null;
    const isInfinite = isRangePicker ? +rangePickerValue[1] === infiniteTimestamp : +singlePickerValue === infiniteTimestamp;
    return (
      <span>
        {
          isRangePicker ? isInfinite ? (
            <React.Fragment>
              <DatePicker
                value={rangePickerValue[0]}
                placeholder="请选择"
                onChange={this.handleStartTimeChange}
              />
              <span style={{ paddingLeft: 10 }}> - </span>
            </React.Fragment>
          ) : (
            <RangePicker value={rangePickerValue} onChange={this.handleRangeChange} />
          ) : (
            <DatePicker
              disabledDate={ (current) => current && current < dayjs().endOf('day')}
              value={ singlePickerValue }
              onChange={ this.onExpirationTimeChange }
              disabled={ Boolean(isInfinite || props.disabled) }
              placeholder="请选择"
            />
          )
        }
        {
          this.props.showForever !== false && (
            <Checkbox
              onChange={ this.onForeverChange }
              disabled={ props.disabled }
              checked={ isInfinite }
              value={ infiniteTimestamp }
              style={{marginLeft: 10}}
            >
            长期
          </Checkbox>
          )
        }
      </span>
    );
  }
}

DatePickAndCheckboxFormItem.defaultProps = {
  mode: 'single',
};