import React from 'react';
import { connect } from 'react-redux';
import reaction from 'reaction';
import { resolveUrlQuery } from 'utils';
import { RoutePath } from 'utils/constants';
import dayjs from 'dayjs';
import Immutable from 'immutable';
import FormItem from 'components/FormItem';
import ConditionFieldItem, { ConditionFieldGroup } from 'components/Condition/FieldItem';
import {
  Table,
  Button,
  Input,
  Select,
  DatePicker,
  Tabs,
  Popconfirm,
  Form,
} from 'antd';
import { certTypeOptions, certAuditResultOptions } from 'config';
import { certCompletionStatusCode, certTypes } from 'config/constant';
import { renderListControls } from 'utils';

import commonStyles from 'style/common.module.scss';

const TabPane = Tabs.TabPane;
const { RangePicker } = DatePicker;
const Option = Select.Option;
const location = window.location;
let dateFormat = 'YYYY/MM/DD';

const tabKeys = {
  unCheck: 'toAudit',
  unComplete: 'toComplete',
  history: 'auditHistory',
};

@connect(({ memberCenter }) => ({ memberCenter }))
class CheckQualificationList extends React.Component {
  state = {
    query: Immutable.Map({
      userNo: '',
      certSubject: '',
      phone: '',
      certType: '',
      toAudit: true,
      toComplete: false,
      time: [],
      page: 1,
      length: 10,
      loading: false,
      auditor: '',
      auditResult: '',
      vehiclePlateNo: '',
      auditTime: [],
    }),
    tabKey: tabKeys.unCheck,
    userIdList1: [],
    userIdList2: [],
    userIdList3: [],
    certIdList: [],
  };

  getCheckQualificationNumber = () => {
    reaction.memberCenter.getCheckQualificationNumber();
  };

  getCheckQualificationList = () => {
    let { query } = this.state;
    let {
      userNo,
      certSubject,
      phone,
      certType,
      time,
      toAudit,
      toComplete,
      page,
      length,
      vehiclePlateNo
    } = query.toJS();
    let params = {
      userNo,
      certSubject,
      phone,
      certType,
      toAudit,
      toComplete,
      page,
      length,
      vehiclePlateNo
    };
    if (time[0]) {
      params.startTime = time[0].startOf('day').valueOf();
    }
    if (time[1]) {
      params.endTime = time[1].endOf('day').valueOf();
    }

    query = query.set('loading', true);
    this.setState({ query });
    return reaction.memberCenter.getCheckQualificationList(params)
      .then(res => {
        query = query.set('loading', false);
        this.setState({ query });
        return res;
      });
  };

  getAuditHistoryList = () => {
    let { query } = this.state;
    let { userNo, certSubject, phone, certType, time, page, length, auditor, auditResult, auditTime, vehiclePlateNo } = query.toJS();
    let params = {
      pagerCondition: {
        page,
        length,
      },
      queryCondition: {
        userNo,
        certSubject,
        phone,
        certType,
        auditResult,
        auditor,
        vehiclePlateNo
      },
    };
    if (time[0]) {
      params.queryCondition.applyTimeBegin = time[0].startOf('day').valueOf();
    }
    if (time[1]) {
      params.queryCondition.applyTimeEnd = time[1].endOf('day').valueOf();
    }
    if (auditTime[0]) {
      params.queryCondition.auditBeginTime = auditTime[0].startOf('day').valueOf();
    }
    if (auditTime[1]) {
      params.queryCondition.auditEndTime = auditTime[1].endOf('day').valueOf();
    }

    query = query.set('loading', true);
    this.setState({ query });
    return reaction.memberCenter.getAuditHistoryList(params)
      .then(res => {
        query = query.set('loading', false);
        this.setState({ query });
        return res;
      });
  };

  goToCheck = (record) => {
    if (record.certType === '1') {
      window.open(
        `//${location.host}/#/${RoutePath.MEMBERCENTER}/${RoutePath.CHECK_QUALIFICATION_LIST}/${RoutePath.CHECK_QUALIFICATION_DETAIL}?userId=${record.userId}&operationType=update_enterprise_cert`
      );
    } else {
      window.open(
        `//${location.host}/#/${RoutePath.MEMBERCENTER}/${RoutePath.CHECK_QUALIFICATION_LIST}/${RoutePath.CHECK_PERSONAL_QUALIFICATION_DETAIL}?userId=${record.userId}&operationType=update_individual_cert`
      );
    }
  };

  goToSupplement = (record) => {
    if (record.certType === '1') {
      window.open(
        `//${location.host}/#/${RoutePath.MEMBERCENTER}/${RoutePath.CHECK_QUALIFICATION_LIST}/${RoutePath.SUPPLEMENT_COMPANY_QUALIFICATION}?userId=${record.userId}&operationType=update_enterprise_cert`
      );
    } else {
      window.open(
        `//${location.host}/#/${RoutePath.MEMBERCENTER}/${RoutePath.CHECK_QUALIFICATION_LIST}/${RoutePath.SUPPLEMENT_PERSONAL_QUALIFICATION}?userId=${record.userId}&operationType=update_individual_cert`
      );
    }
  };

  onQueryChange = (name, value) => {
    let { query } = this.state;
    query = query.set(name, value);
    this.setState({ query }, () => { });
  };

  doSearch = (e) => {
    let { query, tabKey } = this.state;
    query = query.set('page', 1);
    this.setState({ query }, () => {
      if (tabKey === tabKeys.history) {
        this.getAuditHistoryList().then((res) => {
          if (res.code === 0) {
            this.setState({
              userIdList1: [],
              userIdList2: [],
              userIdList3: [],
              query,
            });
          }
        });
      } else {
        this.getCheckQualificationList().then((res) => {
          if (res.code === 0) {
            this.setState({
              userIdList1: [],
              userIdList2: [],
              userIdList3: [],
              query,
            });
          }
        });
      }
    });
    e && e.preventDefault();
  };

  handleTableChange = (pagination) => {
    let { query, tabKey } = this.state;
    query = query.set('page', pagination.current);
    query = query.set('length', pagination.pageSize);
    this.setState({ query }, () => {
      if (tabKey === tabKeys.history) {
        this.getAuditHistoryList();
      } else {
        this.getCheckQualificationList();
      }
    });
  };

  clear = () => {
    let { query } = this.state;
    query = query.set('userNo', '');
    query = query.set('certSubject', '');
    query = query.set('phone', '');
    query = query.set('time', []);
    query = query.set('certType', '');
    query = query.set('auditor', '');
    query = query.set('auditResult', '');
    query = query.set('auditTime', []);
    query = query.set('vehiclePlateNo', '');

    this.setState({ query });
  };

  exportItems = () => {
    let { tabKey } = this.state;
    let {
      userNo,
      certSubject,
      phone,
      certType,
      time,
      toAudit,
      toComplete,
      page,
      length,
      auditor,
      auditResult,
      auditTime,
      vehiclePlateNo
    } = this.state.query.toJS();
    if (tabKey === tabKeys.history) {
      let params = {
        userNo,
        certSubject,
        phone,
        certType,
        auditor,
        auditResult,
        vehiclePlateNo
      };
      if (time[0]) {
        params.applyTimeBegin = time[0].startOf('day').valueOf();
      }
      if (time[1]) {
        params.applyTimeEnd = time[1].endOf('day').valueOf();
      }

      if (auditTime[0]) {
        params.auditBeginTime = auditTime[0].startOf('day').valueOf();
      }
      if (auditTime[1]) {
        params.auditEndTime = auditTime[1].endOf('day').valueOf();
      }

      if (!this.state.userIdList3 || this.state.userIdList3.length === 0) {
        params.exportAll = true;
      } else {
        params.exportAll = false;
        params.userIdList = this.state.userIdList3;
        params.certIdList = this.state.certIdList;
      }
      reaction.memberCenter.exportAuditHistoryItems(params).then((res) => {
        let fileName = '资质审核列表-审核历史';
        this.download(res.data.url, fileName);
      });
    } else {
      let params = {
        userNo,
        certSubject,
        phone,
        certType,
        toAudit,
        toComplete,
        page,
        length,
        vehiclePlateNo,
      };
      if (time[0]) {
        params.startTime = time[0].startOf('day').valueOf();
      }
      if (time[1]) {
        params.endTime = time[1].endOf('day').valueOf();
      }
      if (toAudit) {
        if (!this.state.userIdList1 || this.state.userIdList1.length === 0) {
          params.exportAll = true;
        } else {
          params.exportAll = false;
        }
      } else {
        if (!this.state.userIdList2 || this.state.userIdList2.length === 0) {
          params.exportAll = true;
        } else {
          params.exportAll = false;
        }
      }
      params.userIdList = toAudit
        ? this.state.userIdList1
        : this.state.userIdList2;

      reaction.memberCenter
        .exportCheckQualificationItems(params)
        .then((res) => {
          if (res.code === 0) {
            let fileName = toAudit
              ? '资质审核列表-待审核'
              : '资质审核列表-待补全';
            this.download(res.data.url, fileName);
          }
        });
    }
  };
  download = (fileKey, fileName) => {
    // reaction.memberCenter.download(fileKey, fileName);
    location.href = `/api/v1/common/download?token=${uamsdk.getToken()}&fileKey=${fileKey}&fileName=${encodeURIComponent(
      fileName
    )}`;
  };

  onTabChange = (key) => {
    let { query } = this.state;
    if (key === tabKeys.unComplete) {
      query = query.set('toComplete', true);
      query = query.set('toAudit', false);
    } else if (key === 'toAudit') {
      query = query.set('toComplete', false);
      query = query.set('toAudit', true);
    } else {
      query = query.set('toComplete', false);
      query = query.set('toAudit', false);
    }
    query = query.set('page', 1);
    this.setState({ query, tabKey: key }, () => {
      let tabKey = this.state.tabKey;
      if (tabKey === tabKeys.history) {
        this.getAuditHistoryList();
      } else {
        this.getCheckQualificationList();
      }
    });
  };

  goToViewAuditHistory = (record) => {
    if (record.certType === '1') {
      window.open(
        `//${location.host}/#/${RoutePath.MEMBERCENTER}/${RoutePath.CHECK_QUALIFICATION_LIST}/${RoutePath.VIEW_COMPANY_AUDIT_DETAIL}?userId=${record.userId}&operationType=update_enterprise_cert&certId=${record.certId}`
      );
      // historyPush(`/${RoutePath.MEMBERCENTER}/${RoutePath.CHECK_QUALIFICATION_LIST}/${RoutePath.SUPPLEMENT_COMPANY_QUALIFICATION}`, {userId: record.userId, operationType: 'update_enterprise_cert'});
    } else {
      window.open(
        `//${location.host}/#/${RoutePath.MEMBERCENTER}/${RoutePath.CHECK_QUALIFICATION_LIST}/${RoutePath.VIEW_PERSONAL_AUDIT_DETAIL}?userId=${record.userId}&operationType=update_individual_cert&certId=${record.certId}`
      );
      // historyPush(`/${RoutePath.MEMBERCENTER}/${RoutePath.CHECK_QUALIFICATION_LIST}/${RoutePath.SUPPLEMENT_PERSONAL_QUALIFICATION}`, {userId: record.userId, operationType: 'update_individual_cert'});
    }
  };

  onPressEnter = () => {
    this.doSearch();
  };

  componentDidMount() {
    let tabType = resolveUrlQuery(this.props.location.search).tabType;
    let { query } = this.state;
    if (tabType) {
      if (tabType === tabKeys.unCheck) {
        query = query.set('toComplete', false);
        query = query.set('toAudit', true);
        this.setState({ query, tabKey: tabKeys.unCheck }, () => {
          this.getCheckQualificationList();
        });
      } else if (tabType === tabKeys.unComplete) {
        query = query.set('toComplete', true);
        query = query.set('toAudit', false);
        this.setState({ query, tabKey: tabKeys.unComplete }, () => {
          this.getCheckQualificationList();
        });
      } else {
        query = query.set('toComplete', false);
        query = query.set('toAudit', false);
        this.setState({ query, tabKey: tabKeys.history }, () => {
          this.getAuditHistoryList();
        });
      }
    } else {
      this.getCheckQualificationList();
    }
    this.getCheckQualificationNumber();
  }
  updateUserStatus(userId, certType) {
    if (certType === certTypes.company) {
      return reaction.qualification.toComplementQualification({ userId });
    }
    return reaction.qualification.toComplementPersonalQualification({ userId });
  }
  async handleUpdateStatus(record) {
    const { userId, certType } = record;
    await this.updateUserStatus(userId, certType);
    this.doSearch();
  };
  getColumns(toComplete, toAudit) {
    return [
      {
        title: '会员编号',
        dataIndex: 'userNo',
      },
      {
        title: '用户名',
        dataIndex: 'username',
      },
      {
        title: '安全手机号',
        dataIndex: 'phone',
      },
      {
        title: '主体性质',
        dataIndex: 'certType',
        // width: 120,
        render: (v) => {
          if (v === certTypes.company) {
            return '企业';
          } else {
            return '司机';
          }
        },
      },
      {
        title: '主体名称',
        dataIndex: 'certSubject',
        ellipsis: true,
        // width: 180,
      },
      {
        title: '发起时间',
        dataIndex: 'applyTime',
        width: 180,
        render: (text) => {
          if (!text) {
            return '';
          }
          return dayjs(text - 0).format('YYYY-MM-DD HH:mm:ss');
        },
      },
      {
        title: '业务担保',
        dataIndex: 'proxyType',
        // width: 80,
        render: (text) => {
          if (text === '业务担保委托书') {
            return '是';
          } else if (text === '企业委托授权书') {
            return '否';
          }
          return '未上传';
        },
      },
      {
        title: '操作',
        align: 'center',
        width: 200,
        render: (text, record) => (
          <span>
            {toAudit && (
              <>
                <Button
                  onClick={() => {
                    this.goToCheck(record);
                  }}
                >
                  审核
                </Button>
                <span style={{ paddingLeft: 10 }}>
                  {
                    record.completionStatus === certCompletionStatusCode.COMPLETED ? (
                      <Button
                        disabled
                        type="primary"
                      >
                        待补全
                      </Button>
                    ) : (
                      <Popconfirm
                        title="确定设置为待补全状态吗？"
                        placement="topRight"
                        onConfirm={() => {
                          this.handleUpdateStatus(record)
                        }}
                      >
                        <Button
                          type="primary"
                        >
                          待补全
                        </Button>
                      </Popconfirm>
                    )
                  }
                </span>
              </>
            )}
            {toComplete && (
              <Button
                // onClick={() => {
                //   this.goToSupplement(record);
                // }}
                onClick={() => {
                  this.goToCheck(record);
                }}
              >
                管理
              </Button>
            )}
          </span>
        ),
      },
    ]
  }
  getColumns1() {
    return [
      {
        title: '会员编号',
        dataIndex: 'userNo',
      },
      {
        title: '用户名',
        dataIndex: 'username',
      },
      {
        title: '安全手机号',
        dataIndex: 'phone',
      },
      {
        title: '主体性质',
        dataIndex: 'certType',
        render: (v) => {
          if (v === certTypes.company) {
            return '企业';
          } else {
            return '司机';
          }
        },
      },
      {
        title: '主体名称',
        width: 200,
        dataIndex: 'certSubject',
        ellipsis: true,
      },
      {
        title: '发起时间',
        dataIndex: 'applyTime',
        render: (text) => {
          if (!text) {
            return '';
          }
          return dayjs(text - 0).format('YYYY-MM-DD HH:mm:ss');
        },
      },
      {
        title: '更新时间',
        dataIndex: 'updateTime',
        render: (text) => {
          if (!text) {
            return '';
          }
          return dayjs(text - 0).format('YYYY-MM-DD HH:mm:ss');
        },
      },
      {
        title: '审核时间',
        dataIndex: 'auditTime',
        render: (text) => {
          if (!text) {
            return '';
          }
          return dayjs(text - 0).format('YYYY-MM-DD HH:mm:ss');
        },
      },
      {
        title: '审核人',
        dataIndex: 'auditor',
      },
      {
        title: '审核结果',
        dataIndex: 'auditStatus',
      },
      {
        title: '操作',
        render: (text, record) => (
          <span>
            <Button
              onClick={() => {
                this.goToViewAuditHistory(record);
              }}
            >
              查看
            </Button>
          </span>
        ),
      },
    ]
  }
  render() {
    const { tabKey } = this.state;
    let list = this.props.memberCenter.checkQualificationList;
    let checkHistoryList = this.props.memberCenter.checkHistoryList;
    let checkQualificationNumber = this.props.memberCenter
      .checkQualificationNumber;
    let queryValue = this.state.query.toJS();
    const { toAudit, toComplete, loading } = queryValue;
    const rowSelection1 = {
      selectedRowKeys: this.state.userIdList1,
      onChange: (selectedRowKeys) => {
        this.setState({
          userIdList1: selectedRowKeys,
        });
      },
      getCheckboxProps: (record) => ({
        disabled: false, // Column configuration not to be checked
        name: record.name,
      }),
    };
    const rowSelection2 = {
      selectedRowKeys: this.state.userIdList2,
      onChange: (selectedRowKeys) => {
        this.setState({
          userIdList2: selectedRowKeys,
        });
      },
      getCheckboxProps: (record) => ({
        disabled: false, // Column configuration not to be checked
        name: record.name,
      }),
    };
    const rowSelection3 = {
      selectedRowKeys: this.state.certIdList,
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({
          userIdList3: selectedRows.map((item) => item.userId),
          certIdList: selectedRowKeys,
        });
      },
      getCheckboxProps: (record) => ({
        disabled: false, // Column configuration not to be checked
        name: record.name,
      }),
    };

    const showHistoryItem = tabKey === tabKeys.history;

    return (
      <div>
        <div>
          <h2 className={commonStyles.row}>资质审核</h2>
          <p>
            目前已有资质会员
            <span style={{ color: 'red' }}>
              {checkQualificationNumber.certNumber}
            </span>
            位， 待审核
            <span style={{ color: 'red' }}>
              {checkQualificationNumber.toAuditNumber}
            </span>
            位， 待补全
            <span style={{ color: 'red' }}>
              {checkQualificationNumber.toCompleteNumber}
            </span>
            位。
          </p>
        </div>
        <Form layout="inline" className="form-inline" labelCol={{ xl: 7, sm: 12 }} wrapperCol={{ xl: 17, sm: 12 }}>
          {
            renderListControls([
              {
                children: (
                  <FormItem label="会员编号">
                    <Input
                      onPressEnter={this.onPressEnter}
                      value={queryValue.userNo}
                      onChange={(e) => {
                        this.onQueryChange('userNo', e.target.value);
                      }}
                      placeholder="会员编号"
                    />
                  </FormItem>
                )
              },
              {
                children: (
                  <FormItem label="主体名称">
                    <Input
                      onPressEnter={this.onPressEnter}
                      value={queryValue.certSubject}
                      onChange={(e) => {
                        this.onQueryChange('certSubject', e.target.value);
                      }}
                      placeholder="主体名称"
                    />
                  </FormItem>
                )
              },
              {
                children: (
                  <FormItem label="手机号码">
                    <Input
                      onPressEnter={this.onPressEnter}
                      value={queryValue.phone}
                      onChange={(e) => {
                        this.onQueryChange('phone', e.target.value);
                      }}
                      placeholder="手机号码"
                    />
                  </FormItem>
                )
              },
              {
                children: (
                  <FormItem label="主体性质">
                    <Select
                      showSearch
                      placeholder="主体性质"
                      optionFilterProp="children"
                      value={queryValue.certType}
                      onChange={(value) => {
                        this.onQueryChange('certType', value);
                      }}
                    >
                      {
                        certTypeOptions.map(item => {
                          return (
                            <Option value={item.value} key={item.label}>{item.label}</Option>
                          );
                        })
                      }
                    </Select>
                  </FormItem>
                )
              },
              {
                children: (
                  <FormItem label="发起时间">
                    <RangePicker
                      value={queryValue.time}
                      format={dateFormat}
                      onChange={(value) => {
                        this.onQueryChange('time', value);
                      }}
                    />
                  </FormItem>
                )
              },
              {
                visible: showHistoryItem,
                children: (
                  <FormItem label="审核时间">
                    <RangePicker
                      value={queryValue.auditTime}
                      format={dateFormat}
                      onChange={(value) => {
                        this.onQueryChange('auditTime', value);
                      }}
                    />
                  </FormItem>
                )
              },
              {
                visible: showHistoryItem,
                children: (
                  <FormItem label="审核人">
                    <Input
                      onPressEnter={this.onPressEnter}
                      value={queryValue.auditor}
                      onChange={(e) => {
                        this.onQueryChange('auditor', e.target.value);
                      }}
                      placeholder="审核人"
                    />
                  </FormItem>
                )
              },
              {
                visible: showHistoryItem,
                children: (
                  <FormItem label="审核结果">
                    <Select
                      showSearch
                      placeholder="审核结果"
                      optionFilterProp="children"
                      value={queryValue.auditResult}
                      onChange={(value) => {
                        this.onQueryChange('auditResult', value);
                      }}
                    >
                      {
                        certAuditResultOptions.map(item => {
                          return (
                            <Option value={item.value} key={item.label}>{item.label}</Option>
                          );
                        })
                      }
                    </Select>
                  </FormItem>
                )
              },
              {
                children: (
                  <FormItem label="车牌号">
                    <Input
                      onPressEnter={this.onPressEnter}
                      value={queryValue.vehiclePlateNo}
                      onChange={(e) => {
                        this.onQueryChange('vehiclePlateNo', e.target.value);
                      }}
                      placeholder="车牌号"
                    />
                  </FormItem>
                )
              },
              {
                full: true,
                colProps: showHistoryItem ? {
                  xxl: 18,
                  md: 12,
                  xl: 24,
                } : {
                  xxl: 12,
                  xl: 24,
                  md: 24,
                  lg: 24,
                },
                children: (
                  <FormItem align="right">
                    <Button
                      type="primary"
                      onClick={this.doSearch}
                      icon="search"
                    >
                      查询
                    </Button>
                    <Button
                      style={{ margin: '0 0 0 5px' }}
                      type="primary"
                      onClick={this.clear}
                      icon="redo"
                    >
                      清空
                    </Button>
                    <Button
                      style={{ margin: '0 0 0 5px' }}
                      type="primary"
                      onClick={this.exportItems}
                      icon="export"
                    >
                      按筛选条件导出
                    </Button>
                  </FormItem>
                )
              }
            ], { justify: 'start' })
          }
        </Form>
        <div className={commonStyles.row}>
          <Tabs activeKey={tabKey} type="card" onChange={this.onTabChange}>
            <TabPane tab="待审核" key={tabKeys.unCheck}>
              {list && (
                <Table
                  className={commonStyles.row}
                  rowKey="userId"
                  rowSelection={rowSelection1}
                  columns={this.getColumns(toComplete, toAudit)}
                  loading={loading}
                  dataSource={list.result}
                  pagination={{
                    current: list.pagerInfo.page,
                    total: list.pagerInfo.total,
                    pageSizeOptions: ['10', '20', '50'],
                    showSizeChanger: true,
                    showQuickJumper: true,
                    showTotal: (total) => `共${total}条`,
                  }}
                  onChange={this.handleTableChange}
                />
              )}
            </TabPane>
            <TabPane tab="待补全" key={tabKeys.unComplete}>
              {list && (
                <Table
                  className={commonStyles.row}
                  rowKey="userId"
                  rowSelection={rowSelection2}
                  columns={this.getColumns(toComplete, toAudit)}
                  loading={loading}
                  dataSource={list.result}
                  pagination={{
                    current: list.pagerInfo.page,
                    total: list.pagerInfo.total,
                    pageSizeOptions: ['10', '20', '50'],
                    showSizeChanger: true,
                    showQuickJumper: true,
                    showTotal: (total) => `共${total}条`,
                  }}
                  onChange={this.handleTableChange}
                />
              )}
            </TabPane>
            <TabPane tab="审核历史" key={tabKeys.history}>
              {checkHistoryList && (
                <Table
                  className={commonStyles.row}
                  rowKey="certId"
                  rowSelection={rowSelection3}
                  columns={this.getColumns1()}
                  loading={loading}
                  dataSource={checkHistoryList.result}
                  pagination={{
                    current: checkHistoryList.pagerInfo.page,
                    total: checkHistoryList.pagerInfo.total,
                    pageSizeOptions: ['10', '20', '50'],
                    showSizeChanger: true,
                    showQuickJumper: true,
                    showTotal: (total) => `共${total}条`,
                  }}
                  onChange={this.handleTableChange}
                />
              )}
            </TabPane>
          </Tabs>
        </div>
      </div>
    );
  }
}
export default CheckQualificationList;
