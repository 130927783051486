import React, { createRef } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Input, Button, Radio, Modal, Table, message, Select } from 'antd';
import reaction from 'reaction';
import dayjs from 'dayjs';
import { AuthCodes, RoutePath } from 'utils/constants';
import { resolveUrlQuery, getUniqTableRowKey } from 'utils';

import { Row, Module } from './detailModule';

import commonStyles from 'style/common.module.scss';
import styles from './style.module.scss';
import { REJECT_REASON_MAX_LENGTH } from 'config';
 
let RadioGroup = Radio.Group;
let Option = Select.Option;
let TextArea = Input.TextArea;

/**
 * 用户详情
 */
@connect(({ frontUser, common, me }) => ({ frontUser, common, me }))
@withRouter
class FrontUserDetail extends React.Component {
  state = {
    editMode: false,
    hasValid: false,
    showDisableReasonError: false,

    disableUsername: true,
    disablePhone: true,
    disableEmail: true,
    disableAppDomain: true,
    disableResponsibilityDepartment: true,
    disableSalesman: true,
    disableTeam: true,
    visibleModal: false,
    userInfo: {},
    username: '',
    phone: '',
    email: '',
    appDomain: '',
    department: '',
    salesman: '',
    team: '',
    hasFundsAccount: false,
    isSignature: false,
    isInternalUser: false,
    isActive: true,
    disableReason: '',

    page: 1,
    length: 10,
  };
  disableReasonRef = createRef();
  async componentDidMount() {
    await this.getUserChangeHistory();
    const certInfo = await this.getUserDetailInfo() || {};
    certInfo.departmentId && this.fetchDepartment(certInfo.departmentId);
  }

  getDepartmentDict = () => {
    reaction.common.getDepartmentDict();
  };

  getUserDetailInfo = () => {
    const { userId } = resolveUrlQuery(this.props.location.search);
    return reaction.frontUser.getDetail(userId).then((res) => {
      this.setState({
        userInfo: res.data,
        username: res.data.username,
        phone: res.data.phone,
        email: res.data.email,
        userType: res.data.userType,
        parentOrSelf: res.data.parentOrSelf,
        hasFundsAccount: res.data.hasFundsAccount,
        appDomain: res.data.appDomain,
        department: res.data.department,
        salesman: res.data.salesman,
        isSignature: res.data.isSignature,
        isInternalUser: res.data.isInternalUser,
        isActive: res.data.isActive,
        team: res.data.team,
      });
      return res.data || {};
    });
  };
  back = () => {
    this.props.history.push(`/${RoutePath.FRONT_USER}/${RoutePath.FRONT_USER_LIST}`);
  };
  getUserChangeHistory = () => {
    const { userId, operationType = 'update_user' } = resolveUrlQuery(this.props.location.search);
    let { page, length } = this.state;
    reaction.frontUser.getUserChangeHistory(
      userId,
      operationType,
      page,
      length
    );
  };

  validateDisableReason() {
    const { isActive, disableReason, userInfo } = this.state;
    // 必须是原来是启用状态，才才必填
    const isInValid = userInfo.isActive ? !isActive && !disableReason : false;
    this.setState({
      showDisableReasonError: isInValid,
      hasValid: true,
    });
    return isInValid;
  }

  createSubmitUserInfo = () => {
    const { userId } = resolveUrlQuery(this.props.location.search);
    const {
      username,
      phone,
      email,
      isInternalUser,
      isActive,
      appDomain,
      department,
      team,
      salesman,
      disableReason,
      userInfo
    } = this.state;
    let params = {
      userId,
      username,
      phone,
      email,
      isInternalUser,
      isActive,
      appDomain,
      department,
      team,
      salesman,
    };

    if (!username || /^\s+$/.test(username)) {
      message.warning('用户名不能为空!');
      return;
    }
    if (username && !/^[-a-zA-Z_0-9\u4e00-\u9fa5]{2,20}$/.test(username)) {
      message.warning('请输入正确格式的用户名!');
      return;
    }
    if (username && !/[^\d+]/.test(username)) {
      message.warning('请输入正确格式的用户名!');
      return;
    }
    if (this.state.phone && !/^1[0-9]{10}$/.test(this.state.phone)) {
      message.warning('请输入正确格式的手机号!');
      return;
    }
    if (
      this.state.email &&
      !/^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/.test(
        this.state.email
      )
    ) {
      message.warning('请输入正确格式的邮箱!');
      return;
    }
    if (userInfo.isActive && this.validateDisableReason()) {
      message.warning('请输入禁用原因!');
      return ;
    }
    // 必须初始值是启用状态，修改为禁用状态才需要传递禁用原因
    if (userInfo.isActive && !isActive) {
      params.disableReason = disableReason
    }
    if (!this.state.username) {
      params.username = null;
    } else if (this.state.username === this.state.userInfo.username) {
      delete params.username;
    }
    if (!this.state.team) {
      params.team = '';
    } else if (this.state.team === this.state.userInfo.team) {
      delete params.team;
    }

    if (!this.state.phone) {
      params.phone = null;
    } else if (this.state.phone === this.state.userInfo.phone) {
      delete params.phone;
    }
    if (!this.state.email) {
      params.email = null;
    } else if (this.state.email === this.state.userInfo.email) {
      delete params.email;
    }
    if (this.state.isInternalUser === this.state.userInfo.isInternalUser) {
      delete params.isInternalUser;
    }
    if (this.state.isActive === this.state.userInfo.isActive) {
      delete params.isActive;
    }
    if (this.state.appDomain === this.state.userInfo.appDomain) {
      delete params.appDomain;
    }
    if (this.state.department === this.state.userInfo.department) {
      delete params.department;
    }
    if (this.state.salesman === this.state.userInfo.salesman) {
      delete params.salesman;
    }
    if (params.phone) {
      reaction.frontUser.updatePhone({
        userId: params.userId,
        phone: params.phone,
      });
    }
    return params;
  };

  onChange = (key, value, callback) => {
    this.setState({
      [key]: value,
      editMode: true,
    }, callback);
  };

  handleDisableReasonChange = (event) => {
    const disableReason = event.target.value;
    this.setState(({ isActive, hasValid }) => {
      return {
        disableReason,
        showDisableReasonError: hasValid ? !isActive && !disableReason : false
      };
    })
  }

  handleChangeStatus = (item) => {
    const isActive = item.target.value;
    this.onChange('isActive', isActive, () => {      
      if (this.state.userInfo.isActive && !isActive) {
        this.setState({
          disableReason: '',
        });
        this.disableReasonRef.current.focus();
      }
    });
  }

  handleChangeDepartment = (value, { props }) => {
    this.onChange('department', value);
    this.onChange('team', '');
    this.clearTeamList();
    this.fetchDepartment(props.id);
  }
  fetchDepartment(parentId) {
    return reaction.frontUser.getDepartmentTeamList({
      parentId,
    });
  }

  clearTeamList() {
    reaction.frontUser.clearDepartmenetTeamList();
  }

  showModal = () => {
    this.setState({
      visibleModal: true,
    });
  };

  handleCancel = () => {
    this.setState({ visibleModal: false });
  };

  changeFieldEditMode = (disableKey, isDisabled, field) => {
    const { userInfo } = this.state;
    const newState = {
      [disableKey]: isDisabled,
    };

    if (disableKey === 'disableResponsibilityDepartment' && isDisabled) {
      this.resetTeamField(userInfo.team);
    }

    if (disableKey === 'disableTeam' && !isDisabled) {
      newState.disableResponsibilityDepartment = false;
    }
    if (field) {
      newState[field] = userInfo[field];
    }
    this.setState(newState);
  };
  resetTeamField(defaultValue) {
    this.clearTeamList();
    this.setState({
      team: defaultValue,
      disableTeam: true,
    });
  }
  handleTableChange = (pagination) => {
    this.setState(
      {
        page: pagination.current,
        length: pagination.pageSize,
      },
      () => {
        this.getUserChangeHistory();
      }
    );
  };
  saveChange = () => {
    const params = this.createSubmitUserInfo();
    if (!params) return ;
    Modal.confirm({
      title: '确定立即提交吗？',
      type: 'warring',
      onOk: () => {
        return reaction.frontUser.updateUserInfo(params).then((res) => {
          if (res.code === 0) {
            this.setState({
              editMode: false,
              disableUsername: true,
              disablePhone: true,
              disableEmail: true,
              disableAppDomain: true,
              disableResponsibilityDepartment: true,
            });
            message.success('修改成功!');

            this.back();
          }
        });
      }
    })
  };

  resetPassword = () => {
    const { userId } = resolveUrlQuery(this.props.location.search);
    reaction.frontUser.resetPassword({ userId }).then((res) => {
      if (res.code === 0) {
        message.success('重置密码成功!');
        this.back();
      }
      // this.handleCancel();
    });
  };

  validateIsUserExist = ({ username, certName, phone }) => {
    this.state.userInfo.phone !== phone &&
      reaction.memberCenter
        .validateIsUserExist({
          username,
          certName,
          phone,
        })
        .then((res) => {
          if (res.data) {
            message.error('手机号已注册，请重新输入!');
          }
        });
  };

  getColumns() {
    return [
      {
        width: 112,
        title: '时间',
        dataIndex: 'updateTime',
        render: (text) => {
          if (text) {
            return dayjs(text - 0).format('YYYY-MM-DD HH:mm:ss');
          }
          return '';
        },
      },
      {
        width: 140,
        title: '操作人',
        dataIndex: 'operator',
      },
      {
        title: '修改内容',
        dataIndex: 'updateContent',
        render: (text) => {
          return <pre style={{ maxWidth: '1000px' }}>{text}</pre>;
        },
      },
    ]
  }
  render() {
    const { detail, changeHistory, teamList = [], teamListLoading } = this.props.frontUser;
    
    let appDomains = this.props.common.platforms;
    let { departments = [] } = this.props.me;
    let {
      visibleModal,
      disableUsername,
      disableSalesman,
      disablePhone,
      disableTeam,
      disableEmail,
      disableAppDomain,
      disableResponsibilityDepartment,
      department,
      team,
      salesman,
      appDomain,
      editMode,
      username,
      phone,
      email,
      isInternalUser,
      isActive,
      userInfo,
      hasFundsAccount,
      isSignature,
      disableReason,
      showDisableReasonError
    } = this.state;
    let { roleAuthList } = this.props.me.roleInfo;
    let enableUpdatePassword = roleAuthList.some((item) => {
      return item.authCode === AuthCodes.USER_PASSWORD_UPDATE;
    });
    return (
      !!detail && (
        <div>
          <h2 className={commonStyles.row}>账户信息管理</h2>
          <div>
            <Module title="基本信息">
              <Row title="会员编号">
                {detail.userNo}
              </Row>
              <Row title="用户名">
                <Input
                  maxLength={20}
                  style={{ width: '200px' }}
                  onChange={(e) => {
                    this.onChange('username', e.target.value);
                  }}
                  value={username}
                  disabled={disableUsername}
                />
                {disableUsername ? (
                  <Button
                    style={{ marginLeft: '10px' }}
                    onClick={() => {
                      this.changeFieldEditMode('disableUsername', false)
                    }}
                  >
                    编辑
                  </Button>
                ) : (
                  <Button
                    style={{ marginLeft: '10px' }}
                    onClick={() => {
                      this.changeFieldEditMode(
                        'disableUsername',
                        true,
                        'username',
                      )
                    }}
                  >
                    取消
                  </Button>
                )}
              </Row>
              <Row title="手机号">
                <Input
                  style={{ width: '200px' }}
                  maxLength={11}
                  onChange={(e) => {
                    this.onChange('phone', e.target.value);
                    this.validateIsUserExist({ phone: e.target.value });
                  }}
                  value={phone}
                  disabled={disablePhone}
                />
                {disablePhone ? (
                  <Button
                    style={{ marginLeft: '10px' }}
                    onClick={() => {
                      this.changeFieldEditMode('disablePhone', false)
                    }}
                  >
                    编辑
                  </Button>
                ) : (
                  <Button
                    style={{ marginLeft: '10px' }}
                    onClick={() => {
                      this.changeFieldEditMode(
                        'disablePhone',
                        true,
                        'phone',
                      )
                    }}
                  >
                    取消
                  </Button>
                )}
              </Row>
              <Row title="邮箱">
                <Input
                  style={{ width: '200px' }}
                  maxLength={32}
                  onChange={(e) => {
                    this.onChange('email', e.target.value);
                  }}
                  value={email}
                  disabled={disableEmail}
                />
                {disableEmail ? (
                  <Button
                    style={{ marginLeft: '10px' }}
                    onClick={this.changeFieldEditMode.bind(this, 'disableEmail', false)}
                  >
                    编辑
                  </Button>
                ) : (
                  <Button
                    style={{ marginLeft: '10px' }}
                    onClick={() => {
                      this.changeFieldEditMode(
                        'disableEmail',
                        true,
                        'email',
                      )
                    }}
                  >
                    取消
                  </Button>
                )}
              </Row>
            </Module>
            <Module title="注册信息">
              <Row title="注册应用">
                {detail.registerPlatformName}
              </Row>
              <Row title="领域">
                <Select
                  showSearch
                  style={{ width: '130px' }}
                  placeholder="请选择"
                  optionFilterProp="children"
                  disabled={disableAppDomain}
                  value={appDomain}
                  onChange={(value) => {
                    this.onChange('appDomain', value);
                  }}
                >
                  {appDomains.map((item) => {
                    return (
                      <Option key={item.appDomain} value={item.appDomain}>
                        {item.appDomainName}
                      </Option>
                    );
                  })}
                </Select>
                {disableAppDomain ? (
                  <Button
                    style={{ marginLeft: '10px' }}
                    onClick={() => {
                      this.changeFieldEditMode('disableAppDomain', false)
                    }}
                  >
                    编辑
                  </Button>
                ) : (
                  <Button
                    style={{ marginLeft: '10px' }}
                    onClick={() => {
                      this.changeFieldEditMode(
                        'disableAppDomain',
                        true,
                        'appDomain',
                      )
                    }}
                  >
                    取消
                  </Button>
                )}
              </Row>
              <Row title="注册IP">
                {detail.regIp}
              </Row>
              <Row title="注册时间">
                {dayjs(detail.registerTime - 0).format('YYYY-MM-DD HH:mm:ss')}
              </Row>
            </Module>
            <Module title="账户类型">
              <Row title="账户类型">{detail.userType}</Row>
              <Row title="姓名">{detail.subUserName}</Row>
              <Row title="归属主体">{detail.parentOrSelf || '尚未认证'}</Row>
            </Module>
            <Module title="其他属性">
              <Row title="是否开通资金账户" block>
                <RadioGroup
                  disabled={true}
                  onChange={(item) => {
                    this.onChange('hasFundsAccount', item.target.value);
                  }}
                  value={!!hasFundsAccount}
                >
                  <Radio value={true}>是</Radio>
                  <Radio value={false}>否</Radio>
                </RadioGroup>
              </Row>
              <Row title="是否制章" block>
                <RadioGroup
                  disabled={true}
                  onChange={(item) => {
                    this.onChange('isSignature', item.target.value);
                  }}
                  value={!!isSignature}
                >
                  <Radio value={true}>是</Radio>
                  <Radio value={false}>否</Radio>
                </RadioGroup>
              </Row>
              <Row title="内部账户" block>
                <RadioGroup
                  onChange={(item) => {
                    this.onChange('isInternalUser', item.target.value);
                  }}
                  value={!!isInternalUser}
                >
                  <Radio value={true}>是</Radio>
                  <Radio value={false}>否</Radio>
                </RadioGroup>
              </Row>
              <Row title="状态" block>
                <RadioGroup
                  onChange={this.handleChangeStatus}
                  value={!!isActive}
                >
                  <Radio value={true}>启用</Radio>
                  <Radio value={false}>禁用</Radio>
                </RadioGroup>
              </Row>
              {
                (!isActive && userInfo.isActive) ? (
                  <Row title="禁用原因" block={userInfo.isActive}>
                   <div className={styles['text-area']}>
                      <TextArea
                        placeholder="禁用原因"
                        rows={3}
                        maxLength={REJECT_REASON_MAX_LENGTH}
                        className={showDisableReasonError ? styles['text-area--error'] : ''}
                        value={disableReason}
                        onChange={this.handleDisableReasonChange}
                        ref={this.disableReasonRef}
                      />
                    </div>
                  </Row>
                ) : null
              }
            </Module>
            {
              (!userInfo.isActive) ? (
                <Row title="禁用原因" noIndent>
                  {userInfo.disableReason}
                </Row>
              ) : null
            }
            <Row title="责任部门" noIndent>
              <Select
                showSearch
                style={{ width: '200px' }}
                placeholder="请选择"
                optionFilterProp="children"
                disabled={disableResponsibilityDepartment}
                value={department}
                onChange={this.handleChangeDepartment}
              >
                {departments.map((item) => {
                  return (
                    <Option key={item.code} value={item.code || ''} id={item.id}>
                      {item.name}
                    </Option>
                  );
                })}
              </Select>
              {disableResponsibilityDepartment ? (
                <Button
                  style={{ marginLeft: '10px' }}
                  onClick={() => {
                    this.changeFieldEditMode(
                      'disableResponsibilityDepartment',
                      false
                    )
                  }}
                >
                  编辑
                </Button>
              ) : (
                <Button
                  style={{ marginLeft: '10px' }}
                  onClick={() => {
                    this.changeFieldEditMode(
                      'disableResponsibilityDepartment',
                      true,
                      'department',
                    )
                  }}
                >
                  取消
                </Button>
              )}
            </Row>
            <Row title="小组" noIndent>
              <Select
                style={{ width: 200 }}
                disabled={disableTeam}
                value={team}
                loading={teamListLoading}
                onChange={(value) => {
                  this.onChange('team', value);
                }}
              >
                {teamList.map((item) => {
                  return (
                    <Option key={item.code} value={item.code || ''}>
                      {item.name}
                    </Option>
                  );
                })}
              </Select>
              {disableTeam ? (
                <Button
                  style={{ marginLeft: '10px' }}
                  onClick={() => {
                    this.changeFieldEditMode('disableTeam', false)
                  }}
                >
                  编辑
                </Button>
              ) : (
                <Button
                  style={{ marginLeft: '10px' }}
                  onClick={() => {
                    this.changeFieldEditMode(
                      'disableTeam',
                      true,
                      'team',
                    )
                  }}
                >
                  取消
                </Button>
              )}
            </Row>
            <Row title="业务员" noIndent>
              <Input
                maxLength={5}
                style={{ width: '200px' }}
                onChange={(e) => {
                  this.onChange('salesman', e.target.value);
                }}
                value={salesman}
                disabled={disableSalesman}
              />
              {disableSalesman ? (
                <Button
                  style={{ marginLeft: '10px' }}
                  onClick={() => this.changeFieldEditMode('disableSalesman', false)}
                >
                  编辑
                </Button>
              ) : (
                <Button
                  style={{ marginLeft: '10px' }}
                  onClick={() => {
                    this.changeFieldEditMode(
                      'disableSalesman',
                      true,
                      'salesman',
                    )
                  }}
                >
                  取消
                </Button>
              )}
            </Row>
            <div style={{ padding: '30px 0 0 0' }}>
              {enableUpdatePassword ? (
                <Button onClick={this.showModal}>重置密码</Button>
              ) : (
                ''
              )}
              {editMode ? (
                <Button
                  style={{ marginLeft: '10px' }}
                  onClick={this.saveChange}
                >
                  保存
                </Button>
              ) : (
                ''
              )}
            </div>
          </div>

          <Modal
            visible={visibleModal}
            title="重置密码"
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            footer={[
              <Button key="back" onClick={this.handleCancel}>
                取消
              </Button>,
              <Button key="submit" type="primary" onClick={this.resetPassword}>
                重置
              </Button>,
            ]}
          >
            <p>重置密码: {this.state.username}</p>
            <p>
              注意: 重置密码之后，系统将随机生成新密码,
              并发送短信至对方手机号码; 原密码将失效，是否继续？
            </p>
          </Modal>

          <h2 className={commonStyles.row}>操作记录</h2>
          <Table
            columns={this.getColumns()}
            onChange={this.handleTableChange}
            rowKey={getUniqTableRowKey('operator', changeHistory.pagerInfo)}
            dataSource={changeHistory.result}
            pagination={{
              current: changeHistory.pagerInfo.page,
              total: changeHistory.pagerInfo.total,
            }}
          />
        </div>
      )
    );
  }
}

export default FrontUserDetail;
