import React from 'react';
import { connect } from 'react-redux';
import Immutable from 'immutable';
import dayjs from 'dayjs';
import { Button, Select, Modal, Table, message } from 'antd';
import reaction from 'reaction';
import { RoutePath, platformList, platformCodes } from 'utils/constants';
import { historyPush } from 'utils/history';
import { resolveUrlQuery } from 'utils';

import commonStyles from 'style/common.module.scss';

const Option = Select.Option;

// 暂时取消屏蔽招标和集采平台的操作
const shouldHiddenPlatformList = [ platformCodes.oa];
const platformListOptions = Object.values(platformList).filter(
  (el) => shouldHiddenPlatformList.indexOf(el.value) === -1
);

// 由于有些平台身份不能直接添加，故后台返回的身份列表不是所有身份，所以需要对平台列表做一下筛选
function resolvePlatformListByIdentity(platformList = [], identityList = []) {
  return platformList.filter(platform => {
    return identityList.some(identity => {
      return identity.platformCode.indexOf(platform.code) === 0;
    });
  });
}

@connect(({ memberCenter, qualification }) => ({ memberCenter, qualification }))
class MemberCenterEditIdentity extends React.Component {
  state = {
    platformList: [],
    logList: {
      pagerInfo: {
        page: 1,
        total: 0,
      },
      result: [],
    },
    query: Immutable.Map({
      identityList: [],
      platform: '',
      identity: '',
      modalVisible: false,
      page: 1,
      length: 10,
    }),
  };

  getIdentifyInfo = () => {
    let userId = resolveUrlQuery(this.props.location.search).userId;
    reaction.qualification.getIdentityInfo({ userId });
  };

  getIdentityDict = () => {
    reaction.memberCenter.getIdentifyDictList();
  };

  getLogList = () => {
    let {
      page,
      length,
      operationType = 'update_identity',
    } = this.state.query.toJS();
    let userId = resolveUrlQuery(this.props.location.search).userId;
    reaction.memberCenter
      .getIdentifyLogList({
        userId,
        page,
        length,
        operationType,
      })
      .then((res) => {
        if (res.code === 0) {
          this.setState({
            logList: res.data,
          });
        }
      });
  };

  popupModal = () => {
    let { query } = this.state;
    query = query.set('modalVisible', true);
    this.setState({ query });
  };

  handlePlatformChange = (v) => {
    console.log(v);
    let { query } = this.state;
    query = query.set('platform', v);
    this.setState({ query });
  };

  handleIdentityChange = (v) => {
    let { query } = this.state;
    query = query.set('identity', v);
    this.setState({ query });
  };

  handleOk = () => {
    let { query } = this.state;
    let { identityList, identity, platform } = query.toJS();

    if (!identity) {
      message.warn('请选择身份后再提交!');
      return;
    }
    let userId = resolveUrlQuery(this.props.location.search).userId;
    identityList = [...identityList, identity];
    query = query.set('identityList', identityList);
    this.setState({ query });
    this.closeModal();
    reaction.memberCenter
      .addIdentity({ identity, platform, userId })
      .then((res) => {
        if (res.code === 0) {
          this.getIdentifyInfo();
          message.success('新增身份成功!');
        }
      });
  };

  closeModal = () => {
    let { query } = this.state;
    query = query.set('modalVisible', false);
    query = query.set('platform', '');
    query = query.set('identity', '');
    this.setState({ query });
  };

  handleCancel = () => {
    this.closeModal();
  };

  handleAddIdentityBtnClick = () => {
    this.popupModal();
  };

  back = () => {
    historyPush(`/${RoutePath.MEMBERCENTER}/${RoutePath.IDENTITY_LIST}`);
  };

  handleTableChange = (pagination, filters, sorter) => {
    let { query } = this.state;
    query = query.set('page', pagination.current);
    query = query.set('length', pagination.pageSize);
    this.setState({ query }, () => {
      this.getLogList();
    });
  };
  componentDidMount() {
    this.getIdentifyInfo();
    this.getIdentityDict();
    this.getLogList();
  }

  render() {
    const { identifyInfo } = this.props.qualification;
    const list = identifyInfo.existIdentity;
    // let { identityDictList } = this.props.memberCenter;
    const { modalVisible, platform, identity } = this.state.query.toJS();
    const logList = this.state.logList;
    // 需要过滤掉已隐藏平台的身份列表
    const identityList = identifyInfo.identityDict.filter(
      (item) =>
        item.code.indexOf(platform) === 0 &&
        shouldHiddenPlatformList.indexOf(item.platformCode) === -1
    );
    const platformList = resolvePlatformListByIdentity(platformListOptions, identifyInfo.identityDict);

    const columns = [
      {
        title: '序号',
        dataIndex: 'number',
      },
      {
        title: '平台',
        dataIndex: 'platform',
      },
      {
        title: '身份',
        dataIndex: 'identity',
      },
      {
        title: '开通时间',
        dataIndex: 'createTime',
        render: (text) => {
          if (!text) {
            return '';
          }
          return dayjs(text - 0).format('YYYY-MM-DD HH:mm:ss');
        },
      },
    ];
    const columns1 = [
      {
        width: 112,
        title: '时间',
        dataIndex: 'updateTime',
        render: (text) => {
          if (!text) {
            return '';
          }
          return dayjs(text - 0).format('YYYY-MM-DD HH:mm:ss');
        },
      },
      {
        title: '操作人',
        width: 140,
        dataIndex: 'operator',
      },
      {
        title: '修改内容',
        dataIndex: 'updateContent',
        render: (text) => {
          return <pre style={{ maxWidth: '1000px' }}>{text}</pre>;
        },
      },
    ];

    return (
      <div>
        <div>
          <h2 className={commonStyles.row}>身份管理</h2>
        </div>
        <div style={{ display: 'flex', padding: '30px 0 0 0' }}>
          <h3 style={{ width: 150 }}>账户信息</h3>
          <div style={{ flexGrow: 1 }}>
            <div style={{ padding: '10px 0 0 0' }}>
              <label htmlFor="">会员编号: </label>
              <span>{identifyInfo.user.userNo}</span>
            </div>
            <div style={{ padding: '10px 0 0 0' }}>
              <label htmlFor="">用户名: </label>
              <span>{identifyInfo.user.username}</span>
            </div>
            <div style={{ padding: '10px 0 0 0' }}>
              <label htmlFor="">安全手机: </label>
              <span>{identifyInfo.user.phone}</span>
            </div>
          </div>
        </div>
        <div style={{ display: 'flex', padding: '30px 0 0 0' }}>
          <h3 style={{ width: 150 }}>资质信息</h3>
          <div style={{ flexGrow: 1 }}>
            <div style={{ padding: '10px 0 0 0' }}>
              <label htmlFor="">主体性质: </label>
              <span>
                {identifyInfo.certInfo.certType &&
                +identifyInfo.certInfo.certType === 1
                  ? '企业'
                  : '司机'}
              </span>
            </div>
            <div style={{ padding: '10px 0 0 0' }}>
              <label htmlFor="">主体名称: </label>
              <span>{identifyInfo.certInfo.certSubject}</span>
            </div>
          </div>
        </div>
        <div style={{ display: 'flex', padding: '30px 0 0 0' }}>
          <h3 style={{ width: 150 }}>已有身份</h3>
          <div style={{ flexGrow: 1 }}>
            <div>
              <Button type="primary" onClick={this.handleAddIdentityBtnClick}>
                新增身份
              </Button>
            </div>
            <div>
              <Table
                style={{ marginTop: 15 }}
                className={commonStyles.row}
                rowKey={(record, index) => {
                  return `${record.identity}__${index}__${record.number}`
                }}
                columns={columns}
                dataSource={list}
                pagination={false}
              />
            </div>
          </div>
        </div>

        <div style={{ padding: '80px 0 0 0' }}>
          <Button onClick={this.back}>返回</Button>
        </div>

        <div style={{ display: 'flex', padding: '50px 0 0 0' }}>
          <h3 style={{ width: 150 }}>操作日志</h3>
          <div style={{ flexGrow: 1 }}>
            {logList && (
              <Table
                className={commonStyles.row}
                rowKey={(record, index) => {
                  return `${record.operator}__${index}__${logList.pagerInfo.page}`
                }}
                columns={columns1}
                pagination={{
                  current: logList.pagerInfo.page,
                  total: logList.pagerInfo.total,
                }}
                onChange={this.handleTableChange}
                dataSource={logList.result}
              />
            )}
          </div>
        </div>

        <Modal
          title="新增身份"
          visible={modalVisible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <div style={{ padding: '20px 0 0 0' }}>
            <label style={{ display: 'inline-block', width: 45 }} htmlFor="">
              平台
            </label>
            <Select
              onChange={this.handlePlatformChange}
              style={{ width: 165 }}
              value={platform}
              placeholder="请选择平台"
            >
              <Option value="">全部</Option>
              {platformList.map((platform) => {
                return (
                  <Option value={platform.value} key={platform.value}>
                    {platform.label}
                  </Option>
                );
              })}
            </Select>
          </div>
          <div style={{ padding: '20px 0 0 0' }}>
            <label style={{ display: 'inline-block', width: 45 }} htmlFor="">
              身份
            </label>
            <Select
              onChange={this.handleIdentityChange}
              style={{ width: 165 }}
              value={identity}
              placeholder="请选择身份"
            >
              {identityList.map((item) => {
                return <Option value={item.code} key={item.code}>{item.name}</Option>;
              })}
            </Select>
          </div>
        </Modal>
      </div>
    );
  }
}

export default MemberCenterEditIdentity;