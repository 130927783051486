import React from 'react';
import { connect } from 'react-redux';
import { Button, Table, Input, message } from 'antd';
import reaction from 'reaction';
import Immutable from 'immutable';
// import { AuthCodes } from 'utils/constants';

import commonStyles from 'style/common.module.scss';

/**
 * 新增资金账户
 */
@connect(({ accountList, me }) => ({ accountList, me }))
class NewFundsAccountList extends React.Component {
  componentDidMount() {
    this.getNewFundsAccountList();
  }

  componentWillUnmount() {
    reaction.accountList.clearNewFundsAccountList();
  }

  state = {
    query: Immutable.Map({
      name: '',
      page: 1,
      length: 10,
    }),
  };

  doSearch = () => {
    let { query } = this.state;
    query = query.set('page', 1);
    this.setState({ query }, () => {
      this.getNewFundsAccountList();
    });
  };

  getNewFundsAccountList = () => {
    reaction.accountList.getNewFundsAccountList({ ...this.state.query.toJS() });
  };

  openFundsAccount(record) {
    reaction.accountList
      .openFundsAccount({
        companyName: record.companyName,
        username: record.username,
        accountNo: record.accountNo,
        userId: record.userId,
      })
      .then((res) => {
        if (res.code === 0) {
          message.success('开通资金账户成功!');
          this.getNewFundsAccountList();
        }
      });
  }

  onQueryChange = (name, value) => {
    let { query } = this.state;
    query = query.set(name, value);
    this.setState({ query }, () => {});
  };
  handleTableChange = (pagination, filters, sorter) => {
    let { query } = this.state;
    query = query.set('page', pagination.current);
    query = query.set('length', pagination.pageSize);
    this.setState({ query }, () => {
      this.getNewFundsAccountList();
    });
  };

  onPressEnter = () => {
    this.doSearch();
  };

  render() {
    let _this = this;
    let { newFundsAccountList } = this.props.accountList;
    // let { roleAuthList } = this.props.me.roleInfo;
    // let enableOpenAccount = roleAuthList.some((item) => {
    //   return item.authCode === AuthCodes.FUNDS_MANAGEMENT_CREATE_ACCOUNT;
    // });
    let { query } = this.state;
    let columns = [
      {
        title: '公司名',
        dataIndex: 'companyName',
      },
      {
        title: '用户名',
        dataIndex: 'username',
      },
      {
        title: '银行账号',
        dataIndex: 'accountNo',
      },
      {
        title: '结算银行',
        dataIndex: 'settleBank',
      },
      {
        title: '操作',
        dataIndex: '',
        render: function(text, record, index) {
          return (
            <div>
              {record.canAddAccount ? (
                <Button
                  type="primary"
                  onClick={() => {
                    _this.openFundsAccount(record);
                  }}
                >
                  开通资金账户
                </Button>
              ) : (
                ''
              )}
            </div>
          );
        },
      },
    ];

    return (
      <div>
        <div style={{ padding: '20px' }}>
          <span>
            {/* <label htmlFor="">搜索： </label> */}
            <Input
              style={{ width: 165 }}
              value={query.toJS().name}
              onPressEnter={this.onPressEnter}
              onChange={(e) => {
                this.onQueryChange('name', e.target.value);
              }}
              placeholder="请输入公司名/用户名"
            />
          </span>
          <span style={{ marginLeft: '20px' }}>
            <Button icon="search" type="primary" onClick={this.doSearch}>查询</Button>
          </span>
        </div>
        <div style={{ padding: '20px 0 0 0' }}>
          {newFundsAccountList && (
            <Table
              className={commonStyles.row}
              rowKey="uid"
              columns={columns}
              dataSource={newFundsAccountList.result}
              pagination={{
                current: newFundsAccountList.pagerInfo.page,
                total: newFundsAccountList.pagerInfo.total,
                pageSizeOptions: ['10', '20', '50'],
                showSizeChanger: true,
                showQuickJumper: true,
                showTotal: (total) => `共${total}条`,
              }}
              onChange={this.handleTableChange}
            />
          )}
        </div>
      </div>
    );
  }
}

export default NewFundsAccountList;
