import createUID from 'utils/createUID';
import http from 'utils/http';

const LOADING = createUID();

const initialState = {
  loadingVisible: false
};

function reducer (state = initialState, action) {
  switch (action.type) {
    case LOADING:
      return Object.assign({}, state, action.data);
    default:
      return state;
  }
}
Object.assign(reducer, {
  loading (loadingVisible) {
    return { type: LOADING, data: { loadingVisible } };
  }
});
export default reducer;