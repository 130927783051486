import { Form, Radio } from 'antd';
import dayjs from 'dayjs';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import RadioGroup from 'antd/lib/radio/group';
import ImageViewer from 'components/ImageViewer';

const FormItem = Form.Item;

@connect(({ memberCenter, me }) => ({ memberCenter, me }))
class PersonDetail extends Component {
  transportEnum = (options, value) => {
    if (!value) return '';

    let option = options.find((item) => item.code === value);
    if (option) {
      return option.name;
    }
    return '';
  };

  transportDate = (date) => {
    if (!date) {
      return '';
    }
    return dayjs(date).format('YYYY年MM月DD日');
  };

  transportDateRange = (startDate, endDate) => {
    if (!startDate) {
      return '';
    }
    return `${this.transportDate(startDate)} ~ ${this.transportDate(endDate)}`;
  };
  parseDateYYYYMM = (date) => {
    if (date) {
      let strArr = date.split('')
      strArr.splice(4, 0, '年')
      return `${strArr.join('')}月`
    }
  }

  render() {
    const { me, info, hasDriverIdentity = false, qualificationDetailInfo = {} } = this.props;
    const getFieldDecorator = this.props.form?.getFieldDecorator ?? null;
    const vehicleType = me.vehicleType;
    const drivingPermitTypeMap = me.drivingPermitTypeMap;
    const vehicleEnergyType = me.vehicleEnergyType;
    const licensePlateType = me.licensePlateType;

    let formItemLayout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 20 },
    };
    return (
      <div className="person-info">
        <div style={{ overflow: 'hidden' }}>
          <div style={{ float: 'left', width: 100 }}>司机信息</div>
          <div style={{ marginLeft: 100 }}>
            <Form>
              <FormItem {...formItemLayout} label="身份证人像面">
                <ImageViewer imageUrl={info.identityCardFrontUrl?.url}>
                  <img src={info.identityCardFrontUrl?.url} alt="身份证人像面" className="person-driver-image" />
                </ImageViewer>
              </FormItem>
              <FormItem {...formItemLayout} label="姓名">
                {/* 由于审核详情页面和审核历史详情页面的姓名字段不一致，一个是username一个是name所以需要做这个判断 */}
                {info.name || info.username}
              </FormItem>
              <FormItem {...formItemLayout} label="身份证号">
                {info.identityCardNo}
              </FormItem>
              <FormItem {...formItemLayout} label="身份证国徽面">
                <ImageViewer imageUrl={info.identityCardBackUrl?.url}>
                  <img src={info.identityCardBackUrl?.url} alt="身份证国徽面" className="person-driver-image" />
                </ImageViewer>
              </FormItem>
              <FormItem {...formItemLayout} label="身份证有效期">
                {this.transportDateRange(
                  info.idCardValidTime,
                  info.idCardExpirationTime
                )}
              </FormItem>
              {
                hasDriverIdentity ? (
                  <React.Fragment>
                    <FormItem {...formItemLayout} label="驾驶证主页照片">
                      {
                        info.drivingLicenseUrl?.url && (
                          <ImageViewer imageUrl={info.drivingLicenseUrl?.url}>
                            <img src={info.drivingLicenseUrl?.url} alt="驾驶证主页照片" className="person-driver-image" />
                          </ImageViewer>
                        )
                      }
                    </FormItem>
                    <FormItem {...formItemLayout} label="准驾车型">
                      {this.transportEnum(
                        drivingPermitTypeMap,
                        info.drivingPermitType
                      )}
                    </FormItem>
                    <FormItem {...formItemLayout} label="发证机关">
                      {info.issuingDrivingOrganizations}
                    </FormItem>
                    <FormItem {...formItemLayout} label="驾驶证有效期">
                      {this.transportDateRange(
                        info.drivingLicenseValidTime,
                        info.drivingLicenseExpirationTime
                      )}
                    </FormItem>
                    <FormItem {...formItemLayout} label="驾驶证副页照片">
                      {
                        info.drivingLicenseSubPage?.url && (
                          <ImageViewer imageUrl={info.drivingLicenseSubPage?.url}>
                            <img src={info.drivingLicenseSubPage?.url} alt="驾驶证副页照片" className="person-driver-image" />
                          </ImageViewer>
                        )
                      }
                    </FormItem>
                    <FormItem {...formItemLayout} label="档案编号">
                      {info.drivingLicenseNo}
                    </FormItem>
                  </React.Fragment>
                ) : null
              }
            </Form>
          </div>
        </div>

        {/* 屏蔽车辆信息 */}
        {/* {
          hasDriverIdentity ? (
            <React.Fragment>
              <div style={{ overflow: 'hidden' }}>
                <div style={{ float: 'left', width: 100 }}>车辆信息</div>
                <div style={{ marginLeft: 100 }}>
                  <Form>
                    <FormItem {...formItemLayout} label="行驶证主页照片">
                      {
                        info.runningLicenseUrl?.url && (
                          <ImageViewer imageUrl={info.runningLicenseUrl?.url}>
                            <img src={info.runningLicenseUrl?.url} alt="行驶证主页照片" className="person-driver-image" />
                          </ImageViewer>
                        )
                      }
                    </FormItem>
                    <FormItem {...formItemLayout} label="车牌号">
                      {info.licensePlateNo}
                    </FormItem>
                    <FormItem {...formItemLayout} label="所有人">
                      {info.vehicleHolder}
                    </FormItem>
                    <FormItem {...formItemLayout} label="车辆类型">
                      {this.transportEnum(vehicleType, info.vehicleType)}
                    </FormItem>
                    <FormItem {...formItemLayout} label="使用性质">
                      {info.runningUseCharacter}
                    </FormItem>
                    <FormItem {...formItemLayout} label="车辆识别代号">
                      {info.vehicleIdentificationNumber}
                    </FormItem>
                    <FormItem {...formItemLayout} label="注册日期">
                      {this.transportDate(info.runningRegisterDate)}
                    </FormItem>
                    <FormItem {...formItemLayout} label="发证日期">
                      {this.transportDate(info.runningIssueDate)}
                    </FormItem>
                    <FormItem {...formItemLayout} label="牌照类型">
                      {this.transportEnum(
                        licensePlateType,
                        info.licensePlateTypeCode
                      )}
                    </FormItem>
                    <FormItem {...formItemLayout} label="发证机关">
                      {info.issuingRunningOrganizitions}
                    </FormItem>
                    <FormItem {...formItemLayout} label="行驶证副页照片">
                      {
                        info.runningLicenseSubPage?.url && (
                          <ImageViewer imageUrl={info.runningLicenseSubPage?.url}>
                            <img src={info.runningLicenseSubPage?.url} alt="行驶证副页照片" className="person-driver-image" />
                          </ImageViewer>
                        )
                      }
                    </FormItem>
                    <FormItem {...formItemLayout} label="档案编号">
                      {info.runningLicenseNo}
                    </FormItem>
                    <FormItem {...formItemLayout} label="外廓尺寸">
                      {info.vehicleSpecification}
                    </FormItem>
                    <FormItem {...formItemLayout} label="整备质量">
                      {info.ownWeight ? `${info.ownWeight}kg` : ''}
                    </FormItem>
                    <FormItem {...formItemLayout} label="核载质量">
                      {info.loadCapacity ? `${info.loadCapacity}kg` : ''}
                    </FormItem>
                    <FormItem {...formItemLayout} label="车辆能源类型">
                      {this.transportEnum(vehicleEnergyType, info.vehicleEnergyType)}
                    </FormItem>
                    <FormItem {...formItemLayout} label="检验有效期照片">
                      {
                        info.runningLicenseValidityUrl && (
                          <ImageViewer imageUrl={info.runningLicenseValidityUrl}>
                            <img src={info.runningLicenseValidityUrl} alt="检验有效期照片" className="person-driver-image" />
                          </ImageViewer>
                        )
                      }
                    </FormItem>
                    <FormItem {...formItemLayout} label="检验有效期">
                      {this.parseDateYYYYMM(info.runningLicenseValidityData)}
                    </FormItem>
                  </Form>
                </div>
              </div>

              <div style={{ overflow: 'hidden' }}>
                <div style={{ float: 'left', width: 100 }}>挂车信息</div>
                <div style={{ marginLeft: 100 }}>
                  <Form>
                    <FormItem {...formItemLayout} label="行驶证主页照片">
                      {
                        info.semiDrivingLicenseUrl && (
                          <ImageViewer imageUrl={info.semiDrivingLicenseUrl}>
                            <img src={info.semiDrivingLicenseUrl} alt="行驶证主页照片" className="person-driver-image" />
                          </ImageViewer>
                        )
                      }
                    </FormItem>
                    <FormItem {...formItemLayout} label="车牌号">
                      {info.semiCarNo}
                    </FormItem>
                    <FormItem {...formItemLayout} label="所有人">
                      {info.semiVehicleHolder}
                    </FormItem>
                    <FormItem {...formItemLayout} label="行驶证副页照片">
                      {
                        info.semiDrivingLicenseSubPageUrl && (
                          <ImageViewer imageUrl={info.semiDrivingLicenseSubPageUrl}>
                            <img src={info.semiDrivingLicenseSubPageUrl} alt="行驶证副页照片" className="person-driver-image" />
                          </ImageViewer>
                        )
                      }
                    </FormItem>

                    <FormItem {...formItemLayout} label="检验有效期照片">
                      {
                        info.semiDrivingLicenseValidityUrl && (
                          <ImageViewer imageUrl={info.semiDrivingLicenseValidityUrl}>
                            <img src={info.semiDrivingLicenseValidityUrl} alt="检验有效期照片" className="person-driver-image" />
                          </ImageViewer>
                        )
                      }
                    </FormItem>
                    <FormItem {...formItemLayout} label="检验有效期">
                      {this.parseDateYYYYMM(info.semiDrivingLicenseValidityData)}
                    </FormItem>
                  </Form>
                </div>
              </div>

              <div style={{ overflow: 'hidden' }}>
                <div style={{ float: 'left', width: 100 }}>证件信息</div>
                <div style={{ marginLeft: 100 }}>
                  <Form>
                    <FormItem {...formItemLayout} label="道路运输经营许可证照片">
                      {
                        info.transportBusinessLicenseUrl?.url && (
                          <ImageViewer imageUrl={info.transportBusinessLicenseUrl?.url}>
                            <img src={info.transportBusinessLicenseUrl?.url} alt="道路运输经营许可证照片" className="person-driver-image" />
                          </ImageViewer>
                        )
                      }
                    </FormItem>
                    <FormItem {...formItemLayout} label="道路运输经营许可证号">
                      {info.transportBusinessLicenseNo}
                    </FormItem>
                    <FormItem {...formItemLayout} label="道路运输从业资格证照片">
                      {
                        info.transportBusinessQualificationUrl?.url && (
                          <ImageViewer imageUrl={info.transportBusinessQualificationUrl?.url}>
                            <img src={info.transportBusinessQualificationUrl?.url} alt="道路运输从业资格证照片" className="person-driver-image" />
                          </ImageViewer>
                        )
                      }
                    </FormItem>
                    <FormItem {...formItemLayout} label="道路运输从业资格证号">
                      <React.Fragment>
                        {info.qualificationCertificateNo}
                        <div style={{ color: '#169BD5' }}>
                          (总质量4.5吨及以上普通货运车辆)
                        </div>
                      </React.Fragment>
                    </FormItem>
                    <FormItem {...formItemLayout} label="备注">
                      {info.remark}
                    </FormItem>
                    {
                      getFieldDecorator ? (
                        <FormItem {...formItemLayout} label='是否开通资金账户：'>
                          {getFieldDecorator('addFundsAccount', {
                            rules: [
                              {
                                required: true,
                                message: '是否开通资金账户'
                              }
                            ],
                            initialValue: qualificationDetailInfo.addFundsAccount === null ? true : qualificationDetailInfo.addFundsAccount,
                          })(
                            <RadioGroup>
                              <Radio value={true}>开通</Radio>
                              <Radio value={false}>不开通</Radio>
                            </RadioGroup>
                          )}
                        </FormItem>
                      ) : null
                    }
                  </Form>
                </div>
              </div>
            </React.Fragment>
          ) : null
        } */}
      </div>
    );
  }
}

export default PersonDetail;
