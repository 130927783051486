import createUID from 'utils/createUID';
import http from 'utils/http';
import { AuthCodes } from 'utils/constants';
import caiwuguanli from '../assets/caiwuguanli.svg';
import huiyuan from '../assets/huiyuan.svg';
import shangyeyunying from '../assets/shangyeyunying.svg';
import wodemianban from '../assets/wodemianban.svg';
import xitongguanli from '../assets/xitongguanli.svg';
import yonghuzhongxin_f from '../assets/yonghuzhongxin_f.svg';

const LOAD = createUID();
// const UPDATE = createUID();
// const OPERATE = createUID();

// TODO:记住，所有新加的页面，都需要在此地方新增对应的配置，
// 否则会有坑，因为权限校验有用到这里面的配置！！！

const initialState = [
  {
    auth_code: AuthCodes.MEMBER,
    path: '/frontUser',
    label: '用户中心',
    icon: yonghuzhongxin_f,
    children: [{
      auth_code: AuthCodes.MEMBER_FRONT_USER_LIST,
      path: '/frontUserList',
      label: '账户列表'
    }, {
      auth_code: AuthCodes.ADD_MEMBER_REGISTER,
      path: '/registerAccount',
      label: '新增账户'
    }]
  }, {
    auth_code: AuthCodes.SYSTEM,
    path: '/system',
    label: '系统管理',
    icon: xitongguanli,
    children: [{
      auth_code: AuthCodes.SYSTEM_USER_LIST,
      path: '/user',
      label: '后台用户管理'
    }, {
      auth_code: AuthCodes.SYSTEM_ROLE_LIST,
      path: '/role',
      label: '角色权限'
    }, {
      auth_code: AuthCodes.SYSTEM_LOG_FRONTEND,
      path: '/frontend',
      label: '前台日志'
    }, {
      auth_code: AuthCodes.SYSTEM_LOG_BACKEND,
      path: '/backend',
      label: '后台日志'
    }, {
      auth_code: AuthCodes.SYSTEM_AUTH,
      path: '/authManagement',
      label: '授权管理'
    }, {
      auth_code: AuthCodes.PLATFORM,
      path: '/platform',
      label: '平台管理',
      children: [{
        auth_code: AuthCodes.PLATFORM_SYSTEM_LIST,
        path: '',
        label: '业务系统维护'
      }]
    }, {
      auth_code: AuthCodes.SYSTEM_DATA_DICT,
      path: '/dataDict',
      label: '数据字典',
      children: [{
        auth_code: AuthCodes.SYSTEM_DICT_TYPE_LIST,
        path: '/dataDictList',
        label: '字典列表'
      }, {
        auth_code: AuthCodes.SYSTEM_DICT_TYPE_SAVE,
        path: '/add',
        label: '新增字典'
      }]
    }, {
      auth_code: AuthCodes.SYSTEM_FRONT_USER_MENU,
      path: '/frontMenuManagement',
      label: '前台菜单管理'
    }]
  }, {
    auth_code: AuthCodes.MEMBERCENTER,
    path: '/memberCenter',
    label: '会员中心',
    icon: huiyuan,
    subLabel: '会员中心',
    children: [{
      auth_code: AuthCodes.MEMBER_CENTER_QUALIFICATION_LIST,
      path: '/qualificationList',
      label: '资质列表'
    }, {
      auth_code: AuthCodes.MEMBER_CENTER_CHECK_QUALIFICATION_LIST,
      path: '/checkQualificationList',
      label: '资质审核'
    }, {
      auth_code: AuthCodes.MEMBER_CENTER_IDENTITY_LIST,
      path: '/identityList',
      label: '身份列表'
    }, {
      auth_code: AuthCodes.MEMBER_CENTER_CHECK_IDENTITY_LIST,
      path: '/checkIdentity',
      label: '身份审核'
    }, {
      auth_code: AuthCodes.MODIFY_QUALIFICATION,
      path: '/modifyQualification',
      label: '资质修改'
    }, {
      auth_code: AuthCodes.MEMBER_CENTER_QUALIFICATION_PERIOD_MANAGEMENT,
      path: '/companyQualificationPeriodManagement',
      label: '企业资质到期管理'
    }, {
      auth_code: AuthCodes.MEMBER_CENTER_COMPANY_MODIFY,
      path: '/modifyCompany',
      label: '修改企业名称',
    }]
  }, {
    auth_code: AuthCodes.BID_MEMBER_MENU,
    path: '/vipCenter',
    label: '招采会员',
    icon: huiyuan,
    subLabel: '招采会员',
    children: [{
      auth_code: AuthCodes.BID_MEMBER_LIST,
      path: '/zcVipList',
      label: '会员列表',
    }]
  },
  {
    auth_code: AuthCodes.FUNDS,
    path: '/fundsManagement',
    label: '财务管理',
    subLabel: '财务管理',  // 这里加了个subLabel用来区分header部分menu的label与nav部分menu的label,因为存在上下label不一样的情况,不能共用label
    icon: caiwuguanli,
    children: [{
      auth_code: AuthCodes.FUNDS_RECEIPT,
      path: '/receiptListPrint',
      label: '中信银行回单打印',
      children: [{
        auth_code: AuthCodes.FUNDS_ACCOUNT_LIST,
        path: '/accountList',
        label: '账户列表'
      }, {
        auth_code: AuthCodes.FUNDS_RECEIPT_LIST,
        path: '/printProgress',
        label: '交易回单打印流程'
      }]
    }, {
      auth_code: AuthCodes.FUNDS_MEMBER,
      path: '/managementOfMemberFunds',
      label: '会员资金管理',
      children: [{
        auth_code: AuthCodes.FUNDS_MEMBER_ACCOUNT_LIST,
        path: '/fundsMemberAccountList',
        label: '会员资金账户列表'
      }, {
        auth_code: AuthCodes.FUNDS_MANAGEMENT_CREATE_ACCOUNT,
        path: '/newFundsAccountList',
        label: '新增资金账户'
      }]
    }, {
      auth_code: AuthCodes.FUNDS_RECORD,
      path: '/transactionRecords',
      label: '出入金记录',
      children: [{
        auth_code: AuthCodes.FUNDS_RECORD_EXPENDITURE,
        path: '/expenditureRecords',
        label: '出金记录'
      }, {
        auth_code: AuthCodes.FUNDS_RECORD_INCOME,
        path: '/incomeRecords',
        label: '入金记录'
      }]
    }, {
      auth_code: AuthCodes.FUNDS_FREEZE_AND_UNFREEZE,
      path: '/freezeAndUnfreeze',
      label: '冻结解冻'
    }, {
      auth_code: AuthCodes.FUND_RULE_CONFIG_LIST,
      path: '/ruleConfig',
      label: '规则配置'
    }]
  }, {
    auth_code: AuthCodes.OPERATION,
    path: '/operationCenter',
    label: '运营中心',
    icon: shangyeyunying,
    children: [{
      auth_code: AuthCodes.OPERATION_SMS,
      path: '/smsManagement',
      label: '短信管理'
    }, {
      auth_code: AuthCodes.OPERATION_SMS,
      path: '/groupSms',
      label: '短信群发'
    }, {
      auth_code: AuthCodes.OPERATION_OFFLINE_DATA_UPLOAD,
      path: '/uploadOfflineData',
      label: '线下数据上传'
    }, {
      auth_code: AuthCodes.OPERATION_SMS_BLACKLIST_MANAGEMENT,
      path: '/smsBlackListManagement',
      label: '消息黑名单管理'
    }, {
      auth_code: AuthCodes.OPERATION_SCREEN,
      path: '/screen',
      label: '大屏数据'
    }]
  }
];


function reducer(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}

Object.assign(reducer, {
  getAuthTree() {
    return (dispatch) => {
      return http.request('back.auth.getAuthTree');
    };
  },
});
export default reducer;
