import _createForOfIteratorHelper from "/home/gitlab-runner/builds/bcbe11de/0/commonservice/common-uam-html5/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper";
import { RoutePath } from './constants';
import { historyPush } from './history'; /**
 * 对各个不实际渲染内容的索引页进行子菜单(子页面)的权限验证
 * 根据auth_code依次对子页面进行权限验证，某一个页面权限验证通过则跳转到该页面，结束后续验证
 * 如果所有的子页面验证之后都没有访问权限，则跳转到我的面板页
 * @param context
 */
export default function auth(context) {
    var menuList = context.props.menu;
    var pathArr = context.props.location.pathname.replace(/^\//, '').split('/');
    var topPath = '/' + pathArr[0];
    var roleAuthList = context.props.me.roleInfo.roleAuthList;
    var _resMenu = null;
    var _menuList = menuList; // 找到顶级路径对应的菜单配置项
    for (var j = 0; j < _menuList.length; j++) {
        if (_menuList[j].path === topPath) {
            _resMenu = _menuList[j];
            break;
        }
    }
    if (!_resMenu || !_resMenu.children) {
        historyPush(RoutePath.INDEX);
    } // 获取子菜单
    var childMenus = _resMenu.children;
    var authMenuIndex = 0; // 对各个子菜单进行权限验证
    var _iterator = _createForOfIteratorHelper(childMenus), _step;
    try {
        var _loop = function _loop() {
            var childMenu = _step.value;
            authMenuIndex++;
            var enable = roleAuthList.some(function (item) {
                return !childMenu.auth_code || item.authCode === childMenu.auth_code;
            });
            if (enable) {
                historyPush("".concat(_resMenu.path).concat(childMenu.path));
                return "break";
            }
        };
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var _ret = _loop();
            if (_ret === "break")
                break;
        } // 老代码，
        // for (var k = 0; k < childMenus.length; k++) {
        //   let enable = roleAuthList.some((item)=>{
        //     // 目前部分页面的授权code定义不完善，如果没有定义code则默认允许访问该页面
        //     if (!childMenus[k].auth_code || item.authCode === childMenus[k].auth_code) return true;
        //   });
        //   if (enable) {
        //     historyPush(`${_resMenu.path}${childMenus[k].path}`);
        //     break;
        //   }
        // }
        // 所有的子菜单都没有权限，跳转到我的面板页
    }
    catch (err) {
        _iterator.e(err);
    }
    finally {
        _iterator.f();
    }
    if (authMenuIndex === childMenus.length) {
        historyPush(RoutePath.INDEX);
    }
}
