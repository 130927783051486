import dayjs from 'dayjs';
import React from 'react';
import { connect } from 'react-redux';
import { Table, Button, Input, Tooltip, Form, DatePicker } from 'antd';

import FormItem from 'components/FormItem';
import ConditionFieldItem, { ConditionFieldGroup } from 'components/Condition/FieldItem';

import reaction from 'reaction';
import { getUniqTableRowKey, renderListControls } from 'utils';

import commonStyles from 'style/common.module.scss';

let dateFormat = 'YYYY/MM/DD';
const { RangePicker } = DatePicker;

/**
 * 后台日志
 */
@connect(({ log }) => ({ log }))
class LogBackend extends React.Component {
  state = {
    username: '',
    name: '',
    eventName: '',
    logTimeBegin: '',
    logTimeEnd: '',
  };

  componentDidMount () {
    this.doSearch();
  }

  handleTableChange = (pagination) => {
    const { current, pageSize } = pagination;
    this.doSearch({
      page: current,
      length: pageSize,
      ...this.state,
    });
  };
  handleSearch = (e) => {
    e.preventDefault();
    this.doSearch({ ...this.state, page: 1, length: 10 });
  };
  doSearch (params) {
    reaction.log.getBackendList({ page: 1, length: 10, ...(params || this.state) });
  }
  handleChange = (e) => {
    const fieldName = e.target.name;
    const fieldValue = e.target.value;
    this.saveFieldValue(fieldName, fieldValue);
  }
  saveFieldValue (name, value) {
    this.setState({
      [name]: value,
    });
  }

  render () {
    const columns = [
      {
        title: '用户名',
        dataIndex: 'userName',
        width: 120,
      },
      {
        title: '姓名',
        dataIndex: 'realName',
        width: 130,
      },
      {
        title: 'IP',
        dataIndex: 'ip',
      },
      {
        title: '事件',
        dataIndex: 'eventName',
      },
      {
        title: '事件内容',
        dataIndex: 'content',
        render: (text, record) => {
          if (text && text.length > 40) {
            return (
              <Tooltip title={text}>
                <span style={{ cursor: 'pointer' }}>
                  {text.substr(0, 40)}…
                </span>
              </Tooltip>
            );
          } else {
            return text;
          }
        },
      },
      {
        title: '时间',
        dataIndex: 'createTime',
        render: (text, record) => {
          if (!text) {
            return '';
          }
          return dayjs(text - 0).format('YYYY-MM-DD HH:mm:ss');
        },
      },
    ];
    const { backendList: list } = this.props.log;

    return (
      <div className="user">
        <h2 className={commonStyles.row}> 后台日志 </h2>
        <Form onSubmit={this.handleSearch} layout="inline">
          {
            renderListControls([
              <FormItem label="用户名">
                <Input
                  placeholder="请输入用户名"
                  name="username"
                  onChange={this.handleChange}
                />
              </FormItem>,
              <FormItem label="姓名">
                <Input
                  placeholder="请输入姓名"
                  name="name"
                  onChange={this.handleChange}
                />
              </FormItem>,
              <FormItem
                label="事件"
              >
                <Input
                  placeholder="请输入事件"
                  name="eventName"
                  onChange={this.handleChange}
                />
              </FormItem>,
              <FormItem
                label="时间"
              >
                <RangePicker
                  onChange={(value) => {
                    const [startDate, endDate] = value || [];

                    this.saveFieldValue('logTimeBegin', startDate ? startDate.startOf('day').valueOf() : null);
                    this.saveFieldValue('logTimeEnd', endDate ? endDate.endOf('day').valueOf() : null);
                  }}
                  format={dateFormat}
                />
              </FormItem>,
              {
                colProps: {
                  xxl: 24,
                  xl: 16,
                  lg: 24,
                  md: 24,
                },
                children: (
                  <FormItem align="right">
                    <Button type="primary" icon="search" htmlType="submit">
                      查询
                    </Button>
                  </FormItem>
                )
              }
            ])
          }
        </Form>
        {list && (
          <Table
            className={commonStyles.row}
            rowKey={getUniqTableRowKey('userId', list.pagerInfo)}
            columns={columns}
            dataSource={list.result}
            pagination={{
              current: list.pagerInfo.page,
              total: list.pagerInfo.total,
              pageSizeOptions: ['10', '20', '50'],
              showSizeChanger: true,
              showQuickJumper: true,
              showTotal: (total) => `共${total}条`,
            }}
            onChange={this.handleTableChange}
          />
        )}
      </div>
    );
  }
}

export default LogBackend;
