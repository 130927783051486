import React from 'react';
import { connect } from 'react-redux';
import reaction from 'reaction';
import dayjs from 'dayjs';
import {
  Table,
  Button,
  Radio,
  message,
  Checkbox,
  Tag,
  Input,
  Tree,
} from 'antd';
import { historyPush } from 'utils/history';
import { resolveUrlQuery, validHasPurchase, validHasSupplier } from 'utils';
import { RoutePath, AuthCodes } from 'utils/constants';
import unuploadImg from 'assets/unupload.png';
import MagnifyEnableImg from 'components/MagnifyEnableImg';

import commonStyles from 'style/common.module.scss';
import { REJECT_REASON_MAX_LENGTH } from 'config';

const { TextArea } = Input;
@connect(({ qualification }) => ({ qualification }))
class CheckCompanyIdentityHistory extends React.Component {
  state = {
    treeData: [],
  };
  fixTree = (tree) => {
    tree.forEach((item) => {
      item.key = (item.key || item.id || item.code || item.value) + '';
      item.title = item.title || item.name;
      if (item.children) {
        this.fixTree(item.children);
      }
    });
  };
  getTreeData = () => {
    return reaction.common.getSupplyCategory().then((res) => {
      this.fixTree(res.data);
      this.setState({
        treeData: res.data,
      });
      return res;
    });
  };
  getCheckCompanyIdentityHistoryInfo = () => {
    let userId = resolveUrlQuery(this.props.location.search).userId;
    let auditNo = resolveUrlQuery(this.props.location.search).auditNo;
    reaction.qualification.getCheckCompanyIdentityHistoryInfo({
      userId,
      auditNo,
    });
  };

  back = () => {
    historyPush(`/${RoutePath.MEMBERCENTER}/${RoutePath.CHECK_IDENTIFY}`, {
      ...resolveUrlQuery(this.props.location.search),
      auditNo: undefined,
      userId: undefined,
    });
  };

  componentDidMount() {
    this.getCheckCompanyIdentityHistoryInfo();
    this.getTreeData();
  }

  render() {
    let companyIdentityInfo = this.props.qualification
      .checkCompanyIdentityHistoryInfo;
    let list = companyIdentityInfo.existIdentity;
    let { treeData } = this.state;
    let columns = [
      {
        title: '序号',
        dataIndex: 'number',
      },
      {
        title: '平台',
        dataIndex: 'platform',
      },
      {
        title: '身份',
        dataIndex: 'identity',
      },
    ];
    return (
      <div>
        <h2 className={commonStyles.row}>身份审核</h2>
        <div style={{ overflow: 'hidden' }}>
          <div style={{ float: 'left', width: 100 }}></div>
          <div style={{ marginLeft: 100 }}>
            <div style={{ marginBottom: 30 }}>
              <label
                style={{
                  padding: '0 10px 0 0',
                  width: '16.6666666%',
                  textAlign: 'right',
                  display: 'inline-block',
                }}
              >
                申请单号:
              </label>
              <span>{resolveUrlQuery(this.props.location.search).auditNo}</span>
            </div>
          </div>
        </div>
        <div style={{ overflow: 'hidden' }}>
          <div style={{ float: 'left', width: 100 }}>账户信息</div>
          <div style={{ marginLeft: 100 }}>
            <div style={{ marginBottom: 30 }}>
              <label
                style={{
                  padding: '0 10px 0 0',
                  width: '16.6666666%',
                  textAlign: 'right',
                  display: 'inline-block',
                }}
              >
                会员编号:
              </label>
              <span>{companyIdentityInfo.user.userNo}</span>
            </div>
            <div style={{ marginBottom: 30 }}>
              <label
                style={{
                  padding: '0 10px 0 0',
                  width: '16.6666666%',
                  textAlign: 'right',
                  display: 'inline-block',
                }}
              >
                用户名:
              </label>
              <span>{companyIdentityInfo.user.username}</span>
            </div>
            <div style={{ marginBottom: 30 }}>
              <label
                style={{
                  padding: '0 10px 0 0',
                  width: '16.6666666%',
                  textAlign: 'right',
                  display: 'inline-block',
                }}
              >
                安全手机:
              </label>
              <span>{companyIdentityInfo.user.phone}</span>
            </div>
          </div>
        </div>
        <div style={{ overflow: 'hidden' }}>
          <div style={{ float: 'left', width: 100, fontWeight: 'bold' }}>
            已有身份
          </div>
          <div style={{ marginLeft: 100 }}>
            <div style={{ marginBottom: 30 }}>
              {list && (
                <Table
                  style={{ width: 350 }}
                  className={commonStyles.row}
                  rowKey="number"
                  columns={columns}
                  pagination={false}
                  dataSource={list}
                />
              )}
            </div>
          </div>
        </div>
        <div style={{ overflow: 'hidden' }}>
          <div style={{ float: 'left', width: 100 }}>申请身份:</div>
          <div style={{ marginLeft: 100 }}>
            <div style={{ marginBottom: 30 }}>
              {/*<label style={{width: '16.6666666%', textAlign: 'right',display: 'inline-block'}}></label>*/}
              {companyIdentityInfo.applyIdentity && (
                <Tag color="magenta">
                  {companyIdentityInfo.applyIdentity.name}
                </Tag>
              )}
            </div>
          </div>
        </div>
        { validHasSupplier(companyIdentityInfo.applyIdentity.code) ||
          (validHasPurchase(companyIdentityInfo.applyIdentity.code) && (
            <div style={{ overflow: 'hidden' }}>
              <div style={{ float: 'left', width: 100 }}>
                {validHasSupplier(companyIdentityInfo.applyIdentity.code)
                  ? '供应品类：'
                  : '采购品类：'}
              </div>
              <div style={{ marginLeft: 100 }}>
                <div style={{ marginBottom: 30 }}>
                  <Tree
                    checkable
                    disabled
                    checkedKeys={
                      validHasSupplier(companyIdentityInfo.applyIdentity.code)
                        ? companyIdentityInfo.additionalCert.supplyCategory
                        : companyIdentityInfo.additionalCert.purchaserCategory
                    }
                    treeData={treeData}
                  />
                </div>
              </div>
            </div>
          ))}
        <div style={{ overflow: 'hidden' }}>
          <div style={{ float: 'left', width: 100 }}>其他信息:</div>
          <div style={{ marginLeft: 100 }}>
            <div style={{ marginBottom: 30 }}>
              <label
                style={{
                  padding: '0 10px 0 0',
                  width: '16.6666666%',
                  textAlign: 'right',
                  display: 'inline-block',
                }}
              >
                外贸资质:
              </label>
              <span>
                {companyIdentityInfo.additionalCert &&
                companyIdentityInfo.additionalCert.hasForeignTradeCert
                  ? '具备'
                  : '不具备'}
              </span>
            </div>
            <div style={{ marginBottom: 30 }}>
              <label
                style={{
                  padding: '0 10px 0 0',
                  width: '16.6666666%',
                  textAlign: 'right',
                  display: 'inline-block',
                }}
              >
                对外贸易经营者备案登记表附件:
              </label>
              {companyIdentityInfo.additionalCert &&
              companyIdentityInfo.additionalCert.foreignTradeRegistrationUrl &&
              companyIdentityInfo.additionalCert.foreignTradeRegistrationUrl
                .url ? (
                <MagnifyEnableImg
                  style={{ marginLeft: 15 }}
                  imgUrl={
                    companyIdentityInfo.additionalCert
                      .foreignTradeRegistrationUrl.url
                  }
                />
              ) : (
                <img
                  style={{ width: 80, height: 80, marginLeft: 15 }}
                  src={unuploadImg}
                  alt=""
                />
              )}
            </div>
            <div style={{ marginBottom: 30 }}>
              <label
                style={{
                  padding: '0 10px 0 0',
                  width: '16.6666666%',
                  textAlign: 'right',
                  display: 'inline-block',
                }}
              >
                进出口企业代码号:
              </label>
              <span>
                {companyIdentityInfo.additionalCert &&
                  companyIdentityInfo.additionalCert.foreignTradeEnterpriseCode}
              </span>
            </div>
          </div>
        </div>
        <div style={{ overflow: 'hidden' }}>
          <div style={{ float: 'left', width: 100 }}>审核时间:</div>
          <div style={{ marginLeft: 100 }}>
            <div style={{ marginBottom: 30 }}>
              {/*<label style={{width: '16.6666666%', textAlign: 'right',display: 'inline-block'}}></label>*/}
              <span>
                {companyIdentityInfo.auditResult &&
                companyIdentityInfo.auditResult.auditTime
                  ? dayjs(companyIdentityInfo.auditResult.auditTime).format(
                      'YYYY-MM-DD HH:mm:ss'
                    )
                  : ''}
              </span>
            </div>
          </div>
        </div>
        <div style={{ overflow: 'hidden' }}>
          <div style={{ float: 'left', width: 100 }}>审核结果:</div>
          <div style={{ marginLeft: 100 }}>
            <div style={{ marginBottom: 30 }}>
              {/*<label style={{width: '16.6666666%', textAlign: 'right',display: 'inline-block'}}></label>*/}
              <span>
                {companyIdentityInfo.auditResult &&
                companyIdentityInfo.auditResult &&
                companyIdentityInfo.auditResult.auditResult
                  ? '审核通过'
                  : '审核拒绝'}
              </span>
            </div>
          </div>
        </div>
        <div style={{ overflow: 'hidden' }}>
          <div style={{ float: 'left', width: 100 }}>审核人:</div>
          <div style={{ marginLeft: 100 }}>
            <div style={{ marginBottom: 30 }}>
              {/*<label style={{width: '16.6666666%', textAlign: 'right',display: 'inline-block'}}></label>*/}
              <span>
                {companyIdentityInfo.auditResult &&
                  companyIdentityInfo.auditResult &&
                  companyIdentityInfo.auditResult.auditor}
              </span>
            </div>
          </div>
        </div>
        <div style={{ overflow: 'hidden' }}>
          <div style={{ float: 'left', width: 100 }}>审核意见:</div>
          <div style={{ marginLeft: 100 }}>
            <div style={{ marginBottom: 30 }}>
              {/*<label style={{width: '16.6666666%', textAlign: 'right',display: 'inline-block'}}></label>*/}
              <Checkbox checked={true} disabled={true}>
                凡集采，交易平台用户，在开通身份时，系统将自动授予“物流托运方”身份。
              </Checkbox>
            </div>
            <TextArea
              disabled={true}
              style={{ width: 350 }}
              rows={4}
              value={
                companyIdentityInfo.auditResult &&
                companyIdentityInfo.auditResult.auditOpinion
              }
              maxLength={REJECT_REASON_MAX_LENGTH}
              placeholder={`${REJECT_REASON_MAX_LENGTH}位以内，不限字符类型`}
            />
          </div>
        </div>
        <div style={{ padding: '30px 0 20px 100px' }}>
          <Button onClick={this.back} style={{ marginLeft: 10 }}>
            返回
          </Button>
        </div>
      </div>
    );
  }
}

export default CheckCompanyIdentityHistory;
