import React from 'react';
import commonStyles from 'style/common.module.scss';
import {Button, Input} from 'antd';
import getHashCode from '../../utils/getHashCode';

export default class Draggable extends React.Component {
  syncState = {
    startX: 0,
    startY: 0,
    itemHeight: 50,
    itemWidth: 0,
    margin: 5,
    dragElement: null,
    mouseoverElement: null,
    placeholderElement: null,
    startTop: 0,
    startLeft: 0,
    isDrag: false,
    emptyX: 0,
    emptyY: 0,
    emptyIndex: 0,
    mouseOverIndex: 0,
    items: [],
    shouldUpdate: false
  };
  
  mouseDown = (e) => {
    e.persist();
    // e && e.preventDefault();
    this.syncState.isDrag = true;
    let target = e.currentTarget;
    target.classList.add('current-draggable-item');
    this.syncState.items = [].slice.call(this.refs.wrapper.getElementsByClassName('draggable-item'));
    this.syncState.dragElement = target;
    this.syncState.emptyY = this.syncState.startTop = getComputedStyle(target).top;
    this.syncState.emptyX = this.syncState.startLeft = getComputedStyle(target).left;
    this.syncState.emptyIndex = +target.getAttribute('index');
    this.syncState.startX = e.clientX;
    this.syncState.startY = e.clientY;
    this.syncState.placeholderElement.style.display = 'block';
    this.syncState.placeholderElement.style.opacity = 1;
    this.syncState.placeholderElement.style.top = this.syncState.startTop;
  
    
    let onMouseMove = (e) => {
      e && e.preventDefault();
      if (!this.syncState.isDrag) {
        return;
      }
      let offsetTop = e.clientY - this.syncState.startY;
      let offsetLeft = e.clientX - this.syncState.startX;
      let top = parseInt(this.syncState.startTop) + offsetTop;
      let left = parseInt(this.syncState.startLeft) + offsetLeft;
      this.syncState.dragElement.style.top = top + 'px';
      this.syncState.dragElement.style.left = left + 'px';
      this.syncState.mouseOverIndex = parseInt((top + this.syncState.itemHeight / 2) / this.syncState.itemHeight);
      if (this.syncState.mouseOverIndex !== this.syncState.emptyIndex && this.syncState.mouseOverIndex < this.syncState.items.length && left < this.syncState.itemWidth / 2 && left > (-this.syncState.itemWidth) / 2) {
        this.syncState.mouseOverElement && this.syncState.mouseOverElement.classList.remove('draggable-hover');
        this.syncState.mouseOverElement = this.syncState.items.filter(item => +item.getAttribute('index') === this.syncState.mouseOverIndex)[0];
        this.syncState.mouseOverElement && this.syncState.mouseOverElement.classList.add('draggable-hover');
      } else {
        this.syncState.mouseOverElement && this.syncState.mouseOverElement.classList.remove('draggable-hover');
        this.syncState.mouseOverElement = null;
      }
    };
    document.body.addEventListener('mousemove', onMouseMove);
    
    let onMouseUp = () => {
      document.body.removeEventListener('mouseup', onMouseUp);
      document.body.removeEventListener('mousemove', onMouseMove);
      // e && e.preventDefault();
      this.syncState.isDrag = false;
      this.syncState.placeholderElement.style.display = 'none';
      this.syncState.placeholderElement.style.opacity = 0;
      if (this.syncState.mouseOverElement) {
        let _index = this.syncState.dragElement.getAttribute('index');
        // let _data = this.syncState.dragElement.getAttribute('data');
        this.syncState.dragElement.setAttribute('index', this.syncState.mouseOverIndex);
        // this.syncState.dragElement.setAttribute('data', this.syncState.mouseOverElement.getAttribute('data'));
        this.syncState.mouseOverElement.setAttribute('index', _index);
        // this.syncState.mouseOverElement.setAttribute('data', _data);
        this.syncState.mouseOverElement.style.top = this.syncState.emptyIndex * this.syncState.itemHeight + 'px';
        this.syncState.mouseOverElement.classList.remove('draggable-hover');
        this.syncState.emptyX = 0;
        this.syncState.emptyY = this.syncState.mouseOverIndex * this.syncState.itemHeight;
        this.syncState.dragElement.style.top = parseFloat(this.syncState.emptyY) + 'px';
        this.syncState.dragElement.style.left = '0px';
        
        this.onChange(this.syncState.items.sort((a, b) => {
          return a.getAttribute('index') - b.getAttribute('index');
        }).map((item) => {
          return JSON.parse(item.getAttribute('data'));
        }));
        
      } else {
        this.syncState.dragElement.style.left = '0px';
        this.syncState.dragElement.style.top = parseFloat(this.syncState.emptyY) + 'px';
      }
      target.classList.remove('current-draggable-item');
      this.syncState.mouseOverElement = null;
    };
    document.body.addEventListener('mouseup', onMouseUp);
  };
  
  onChange = (p) => {
    let onChange = this.props.onChange;
    onChange(p);
  };
  
  shouldComponentUpdate (nextProps, nextState) {
    if (nextProps.children.length !== this.props.children.length) {
      this.syncState.shouldUpdate = true;
      return true;
    } else {
      return false;
    }
  }
  
  componentDidMount() {
    this.syncState.sortArr = this.props.children.map((item, index) => index);
    this.syncState.placeholderElement = this.refs.placeholder;
    this.syncState.itemWidth = parseFloat(getComputedStyle(this.refs.wrapper).width);
  }
  
  render() {
    let { list } = this.props;
    let { shouldUpdate } = this.syncState;
    
    return (<div ref="wrapper" style={{position: 'relative',width: '100%',height: list.length * 50 - 5,border: '1px solid #eee'}}>
      <div ref="placeholder" className="placeholder" style={{display: 'none',zIndex: 9,width: '100%',height: 45,position: 'absolute',top:0, left: 0, backgroundColor: '#efefef',opacity: 0}}></div>
      {
        this.props.children.map((child, index) => {
          return (<div className={ commonStyles.dragableRow + ' draggable-item' } style={{top: index * 50, left: 0}} index={ index } data={ child.key } key={ shouldUpdate ? child.key + '_' + Math.random() : child.key } onMouseDown={ this.mouseDown }>
            { child }
          </div>);
        })
      }
    </div>);
  }
}