import React from 'react';
import { connect } from 'react-redux';
import { Input, DatePicker, Button, Table, Select, Form } from 'antd';
import dayjs from 'dayjs';
import reaction from 'reaction';
import Immutable from 'immutable';
import FormItem from 'components/FormItem';

import { formatStringSymbol, renderListControls } from 'utils';
import commonStyles from 'style/common.module.scss';

const { RangePicker } = DatePicker;
let dateFormat = 'YYYY/MM/DD';
let defaultDatetime = [];

/**
 * 入金记录
 */
@connect(({ accountList, me, common }) => ({ accountList, me, common }))
class IncomeRecords extends React.Component {
  state = {
    query: Immutable.Map({
      oppAccountName: '',
      serialNo: '',
      datetime: defaultDatetime,
      platformCode: '',
      page: 1,
      length: 10,
    }),
  };
  onQueryChange = (name, value) => {
    let { query } = this.state;
    query = query.set(name, value);
    this.setState({ query }, () => { });
  };
  doSearch = () => {
    let { query } = this.state;
    query = query.set('page', 1);
    this.setState({ query }, () => {
      this.getIncomeRecordsList();
    });
  };

  getIncomeRecordsList = () => {
    let query = this.state.query.toJS();
    let { oppAccountName, serialNo, datetime, platformCode, page, length } = query;
    let startTime = datetime[0] ? datetime[0].startOf('day').valueOf() : null;
    let endTime = datetime[1] ? datetime[1].endOf('day').valueOf() : null;
    let condition = {
      oppAccountName,
      serialNo,
      startTime,
      endTime,
      platformCode,
      page,
      length,
    };
    if (condition.startTime === null) {
      delete condition.startTime;
    }
    if (condition.endTime === null) {
      delete condition.endTime;
    }
    reaction.accountList.getIncomeRecordsList(condition);
  };

  handleTableChange = (pagination, filters, sorter) => {
    let { query } = this.state;
    query = query.set('page', pagination.current);
    query = query.set('length', pagination.pageSize);
    query = query.set('oppAccountName', formatStringSymbol(query.get('oppAccountName')))
    this.setState({ query }, () => {
      this.getIncomeRecordsList();
    });
  };

  getTotalIncome = () => {
    reaction.accountList.getTotalIncome({
      fundsType: 3,
    });
  };

  clear = () => {
    let { query } = this.state;
    query = query.set('oppAccountName', '');
    query = query.set('datetime', defaultDatetime);
    query = query.set('serialNo', '');
    query = query.set('platformCode', '');

    this.setState({ query }, () => {
      // ......
    });
  };

  onPressEnter = () => {
    this.doSearch();
  };

  componentDidMount () {
    this.getIncomeRecordsList();
    this.getTotalIncome();
  }
  render () {
    let { incomeRecords, totalIncome } = this.props.accountList;
    let { platformDict } = this.props.common;
    let platforms = [];
    for (let key in platformDict) {
      platforms.push({ name: platformDict[key], code: key });
    }
    let { query } = this.state;
    let columns = [
      {
        title: '单号',
        dataIndex: 'serialNo',
      },
      {
        title: '日期',
        dataIndex: 'bizTime',
        render: (text) => {
          if (!text) {
            return '';
          }
          return dayjs(text - 0).format('YYYY-MM-DD HH:mm:ss');
        },
      },
      {
        title: '付款账户名',
        dataIndex: 'oppAccountName',
      },
      {
        title: '账户',
        dataIndex: 'oppAccountNo',
      },
      {
        title: '金额（元）',
        dataIndex: 'money',
      },
      {
        title: '余额（元）',
        dataIndex: 'balance',
      },
      {
        title: '收款账户名',
        dataIndex: 'accountName',
      },
      {
        title: '收款账号',
        dataIndex: 'accountNo',
      },
      {
        title: '入金银行',
        dataIndex: 'oppBankName',
      },
      {
        title: '平台',
        dataIndex: 'platform',
      },
      {
        title: '备注',
        dataIndex: 'memo',
      },
    ];

    return (
      <div>
        <Form layout="inline">
          {
            renderListControls([
              <FormItem label="付款账户名">
                <Input
                  onPressEnter={this.onPressEnter}
                  value={query.toJS().oppAccountName}
                  onChange={(e) => {
                    this.onQueryChange('oppAccountName', e.target.value);
                  }}
                  placeholder="付款账户名"
                />
              </FormItem>,
              <FormItem label="日期">
                <RangePicker
                  value={query.toJS().datetime}
                  onChange={(value) => {
                    this.onQueryChange('datetime', value);
                  }}
                  format={dateFormat}
                />
              </FormItem>,
              <FormItem label="单号">
                <Input
                  onPressEnter={this.onPressEnter}
                  value={query.toJS().serialNo}
                  onChange={(e) => {
                    this.onQueryChange('serialNo', e.target.value);
                  }}
                  placeholder="单号"
                />
              </FormItem>,
              <FormItem label="平台">
                <Select
                  showSearch
                  placeholder="选择平台"
                  optionFilterProp="children"
                  value={query.toJS().platformCode}
                  onChange={(value) => {
                    this.onQueryChange('platformCode', value);
                  }}
                >
                  <Select.Option value="">全部</Select.Option>
                  {platforms.map((item) => {
                    return (
                      <Select.Option value={item.code} key={item.code}>
                        {item.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </FormItem>,
              {
                colProps: {
                  xxl: 24,
                  xl: 16,
                  lg: 24,
                  md: 24,
                },
                children: (
                  <FormItem align="right">
                    <Button
                      type="primary"
                      onClick={this.doSearch}
                      icon="search"
                    >
                      查询
                      </Button>
                    <Button
                      style={{ margin: '0 0 0 5px' }}
                      type="primary"
                      onClick={this.clear}
                      icon="redo"
                    >
                      清空
                      </Button>
                  </FormItem>
                )
              }
            ])
          }
        </Form>
        <div style={{ padding: '20px 0 0 0' }}>
          <span>总计: 入金{totalIncome.total}元</span>
        </div>
        <div style={{ padding: '20px 0 0 0' }}>
          {incomeRecords ? (
            <Table
              className={commonStyles.row}
              rowKey={r => `${r.accountNo}__${r.cardNo}_${r.serialNo}_${r.withdrawAccountNo}`}
              columns={columns}
              dataSource={incomeRecords.result}
              pagination={{
                current: incomeRecords.pagerInfo.page,
                total: incomeRecords.pagerInfo.total,
                pageSizeOptions: ['10', '20', '50'],
                showSizeChanger: true,
                showQuickJumper: true,
                showTotal: (total) => `共${total}条`,
              }}
              onChange={this.handleTableChange}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

export default IncomeRecords;
