import React from 'react';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import Immutable from 'immutable';
import reaction from 'reaction';
import { Button, Input, message, Form, Table, Modal, Select, Radio } from 'antd';
import PersonDetail from 'modules/PersonDetail';

import { historyPush } from 'utils/history';
import { RoutePath } from 'utils/constants';
import { resolveUrlQuery, checkHasDriverIdentity, getUniqTableRowKey } from 'utils';
import { driverIdentityCode, REJECT_REASON_MAX_LENGTH } from 'config';

import commonStyles from 'style/common.module.scss';
import RadioGroup from 'antd/lib/radio/group';

const FormItem = Form.Item;
const { Option } = Select;
const { TextArea } = Input;

/**
 * Review: 资质审核 - 司机 - 待审核
 */
@Form.create()
@connect(({ memberCenter, qualification, me, common, frontUser }) => ({
  memberCenter,
  qualification,
  me,
  common,
  frontUser
}))
class CheckPersonalQualificationDetail extends React.Component {
  state = {
    query: Immutable.Map({
      page: 1,
      length: 50
    }),
    showRefuseModal: false,
    reason: '',

    info: null
  };

  getQualificationInfo = () => {
    let userId = resolveUrlQuery(this.props.location.search).userId;
    return reaction.qualification
      .getPersonalQualificationInfo({ userId })
      .then(res => {
        if (res.code !== 0) return;

        // 统一扁平化数据 保持统一
        let {
          additionalCert,
          attachment,
          baseInfo
        } = res.data;

        additionalCert = additionalCert || {};
        const { vehicleInfo } = additionalCert;

        this.setState({ info: { ...vehicleInfo, ...attachment, ...baseInfo } });
        return res.data;
      });
  };

  getOperationHistory = () => {
    let { page, length } = this.state.query.toJS();
    reaction.qualification.getPersonalQualificationOperationHistory({
      userId: resolveUrlQuery(this.props.location.search).userId,
      page,
      length,
      operationType: resolveUrlQuery(this.props.location.search).operationType
    });
  };

  back = () => {
    historyPush(
      `/${RoutePath.MEMBERCENTER}/${RoutePath.CHECK_QUALIFICATION_LIST}`
    );
  };

  // toComplete = e => {
  //   let userId = resolveUrlQuery(this.props.location.search).userId;
  //   e && e.preventDefault();
  //   reaction.qualification
  //     .toComplementPersonalQualification({ userId })
  //     .then(res => {
  //       if (res.code === 0) {
  //         message.success('操作成功!');
  //         historyPush(
  //           `/${RoutePath.MEMBERCENTER}/${RoutePath.CHECK_QUALIFICATION_LIST}`
  //         );
  //       }
  //     });
  // };

  // pass = e => {
  //   let userId = resolveUrlQuery(this.props.location.search).userId;
  //   e && e.preventDefault();
  //   reaction.qualification
  //     .makePersonalQualificationOk({
  //       userId,
  //       department: this.props.form.getFieldValue('department'),
  //       salesman: this.props.form.getFieldValue('salesman'),
  //       team: this.props.form.getFieldValue('team'),
  //       addFundsAccount: this.props.form.getFieldValue('addFundsAccount'),
  //     })
  //     .then(res => {
  //       if (res.code === 0) {
  //         message.success('审核成功');
  //         historyPush(
  //           `/${RoutePath.MEMBERCENTER}/${RoutePath.CHECK_QUALIFICATION_LIST}`
  //         );
  //       }
  //     });
  // };

  // showRefuseModal = () => {
  //   this.setState({
  //     showRefuseModal: true
  //   });
  // };

  refuse = e => {
    let userId = resolveUrlQuery(this.props.location.search).userId;
    let reason = this.state.reason;
    if (!reason) {
      message.warning('请输入审核意见再提交!');
      return;
    }
    e && e.preventDefault();
    reaction.qualification
      .refusePersonalQualification({ userId, reason })
      .then(res => {
        if (res.code === 0) {
          message.success('审核拒绝成功');
          historyPush(
            `/${RoutePath.MEMBERCENTER}/${RoutePath.CHECK_QUALIFICATION_LIST}`
          );
        }
      });
  };

  cancelRefuse = () => {
    this.setState({
      showRefuseModal: false,
      reason: ''
    });
  };

  handleDepartmentChange = (_, { props }) => {
    this.fetchDepartmentTeamList(props.id);
    this.setFieldsValue('team', '');
  }

  fetchDepartmentTeamList(id) {
    return reaction.frontUser.getDepartmentTeamList({
      parentId: id,
    });
  }

  hasSelectDepartment() {
    return !!this.props.form.getFieldValue('department');
  }

  setFieldsValue(name, value) {
    this.props.form.setFieldsValue({
      [name]: value,
    })
  }

  async componentDidMount() {
    await this.getOperationHistory();
    const certInfo = await this.getQualificationInfo() || {};
    certInfo.departmentId && this.fetchDepartmentTeamList(certInfo.departmentId);
  }
  getColumns() {
    return [
      {
        width: 112,
        label: '时间',
        dataIndex: 'updateTime',
        render: text => {
          if (!text) {
            return '';
          }
          return dayjs(text - 0).format('YYYY-MM-DD HH:mm:ss');
        }
      },
      {
        width: 140,
        label: '操作人',
        dataIndex: 'operator'
      },
      {
        label: '修改内容',
        dataIndex: 'updateContent',
        render: text => {
          return <pre style={{ maxWidth: '1000px' }}>{text}</pre>;
        }
      }
    ];
  }
  render() {
    const { info } = this.state;
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const { departments = [] } = this.props.me;
    const { teamList = [], teamListLoading } = this.props.frontUser;

    let qualificationDetailInfo = this.props.qualification.personalQualificationDetailInfo;
    let list = this.props.qualification.personalQualificationOperationHistory;
    let formItemLayout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 20 }
    };

    return (
      <div className="person-info">
        <h2 className={commonStyles.row}>司机资质信息审核</h2>
        <div style={{ overflow: 'hidden' }}>
          <div style={{ float: 'left', width: 100 }}>账户信息</div>
          <div style={{ marginLeft: 100 }}>
            <div style={{ marginBottom: 20 }}>
              <label
                className="form-item-label-item"
              >
                会员编号：
              </label>
              <span>{qualificationDetailInfo.user.userNo}</span>
            </div>
            <div style={{ marginBottom: 20 }}>
              <label
                className="form-item-label-item"
              >
                用户名：
              </label>
              <span>{qualificationDetailInfo.user.username}</span>
            </div>
            <div style={{ marginBottom: 20 }}>
              <label
                className="form-item-label-item"
              >
                安全手机：
              </label>
              <span>{qualificationDetailInfo.user.phone}</span>
            </div>
            <Form>
              <FormItem {...formItemLayout} label='责任部门'>
                {getFieldDecorator('department', {
                  rules: [{ required: false, message: '请选择责任部门' }],
                  initialValue: qualificationDetailInfo.department || ''
                })(
                  <Select style={{ width: 250 }} onChange={this.handleDepartmentChange}>
                    {departments &&
                      departments.map(item => {
                        return (
                          <Option key={item.code} value={item.code || ''} id={item.id}>
                            {item.name}
                          </Option>
                        );
                      })}
                  </Select>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label='小组'>
                {getFieldDecorator('team', {
                  validateTrigger: ['onBlur', 'onChange'],
                  rules: [
                    {
                      required: false,
                      whitespace: true,
                      message: '请输入小组'
                    }
                  ],
                  initialValue: qualificationDetailInfo.team || ''
                })(
                  <Select style={{ width: 250 }} loading={teamListLoading} disabled={!this.hasSelectDepartment()}>
                    {teamList.map((item) => {
                      return (<Option key={item.code} value={item.code || ''}>{item.name}</Option>);
                    })}
                  </Select>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label='业务员'>
                {getFieldDecorator('salesman', {
                  validateTrigger: ['onBlur', 'onChange'],
                  rules: [
                    {
                      required: false,
                      whitespace: true,
                      message: '请输入业务员'
                    }
                  ],
                  initialValue: qualificationDetailInfo.salesman || ''
                })(
                  <Input
                    maxLength={5}
                    style={{ width: 250 }}
                    placeholder='请输入业务员'
                  />
                )}
              </FormItem>
            </Form>
          </div>
        </div>

        {info && <PersonDetail info={info} hasDriverIdentity={checkHasDriverIdentity(qualificationDetailInfo.identity)} form={form} qualificationDetailInfo={qualificationDetailInfo} />}

        {/* <div style={{ padding: '10px 0 50px 150px' }}>
          <Button onClick={this.back}>返回</Button>
          <Button style={{ marginLeft: 10 }} onClick={this.toComplete}>
            待补全
          </Button>
          <Button style={{ marginLeft: 10 }} onClick={this.showRefuseModal}>
            审核拒绝
          </Button>
          <Button style={{ marginLeft: 10 }} onClick={this.pass}>
            审核通过
          </Button>
        </div> */}

        <div style={{ padding: '0 0 50px 0' }}>
          {list && (
            <Table
              className={commonStyles.row}
              rowKey={getUniqTableRowKey('operator', list.pagerInfo)}
              columns={this.getColumns()}
              dataSource={list.result}
            />
          )}
        </div>

        <Modal
          title='提示'
          width={400}
          visible={this.state.showRefuseModal}
          onOk={this.refuse}
          onCancel={this.cancelRefuse}
        >
          <h4>请备注拒绝原因:</h4>
          <TextArea
            value={this.state.reason}
            rows={5}
            onChange={e => {
              this.setState({ reason: e.target.value });
            }}
            maxLength={REJECT_REASON_MAX_LENGTH}
            placeholder={`不限类型，${REJECT_REASON_MAX_LENGTH}字以内`}
          />
        </Modal>
      </div>
    );
  }
}

export default CheckPersonalQualificationDetail;