import React from 'react';
import { connect } from 'react-redux';
import reaction from 'reaction';
import { withRouter } from 'react-router-dom';
import { Spin } from 'antd';
import { shouldPathLogin, shouldPathRedirect, transformParamsToUrl } from 'utils';
import { RoutePath } from 'utils/constants';
import cookie from 'utils/cookie';

import routes from 'routes';

@connect(({ application, me, menu }) => ({ application, me, menu }))
@withRouter
class App extends React.Component {
  componentDidMount() {
    this.authAndRedirect();
    // localstorage或者是根域名下的uam_sso_server_token有值，则初始化
    if (uamsdk.loggedIn() || cookie.getCookie('uam_sso_server_token')) {
      reaction.me.initialData();
    }
    this.validateLocation();

    // this.timer = setInterval(()=>{
    //     if(uamsdk.loggedIn()){
    //         reaction.me.updateData();
    //     }
    // }, 10000); // 每隔10秒刷新一下我的信息
  }

  validateLocation() {
    let { me, location, history } = this.props;
    if (!uamsdk.loggedIn() && !cookie.getCookie('uam_sso_server_token')) {
      if (shouldPathLogin(location.pathname)) {
        if (location.pathname !== `/${RoutePath.LOGIN}`) {
          if (shouldPathRedirect(location.pathname)) {
            history.push({
              pathname: `/${RoutePath.LOGIN}`,
              search: transformParamsToUrl({
                redirect: location.pathname
              })
            });
          } else {
            history.push(`/${RoutePath.LOGIN}`);
          }
        }
      }
    }
  }

  /*
   * 验证是否拥有该路径的权限，没有权限则跳转到司机面板页
   * */
  authAndRedirect() {
    let { roleAuthList } = this.props.me.roleInfo;
    // let menu = this.props.menu;
    let _routes = routes.childRoutes[0].childRoutes;
    let pathArr = this.props.location.pathname.replace(/^\//, '').split('/');
    let _resRoute = null;
    let _routeList = _routes;
    pathArr.forEach((item) => {
      _routeList.forEach((routeItem) => {
        if (routeItem.path === item) {
          _resRoute = routeItem;
          _routeList = _resRoute.childRoutes || [];
        }
      });
    });
    if (!_resRoute) {
      return;
    }
    let enable = roleAuthList.some((item) => {
      // 目前部分页面的授权code定义不完善，如果没有定义code则默认允许访问该页面
      return !_resRoute.auth_code || item.authCode === _resRoute.auth_code;
    });
    if (!enable) {
      this.props.history.push(RoutePath.INDEX);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    this.authAndRedirect();
    this.validateLocation();
  }

  componentWillUnmount() {
    clearInterval(this.timer);
    this.timer = void 0;
  }

  render() {
    let { application, children } = this.props;
    // console.log(this.props.children)
    return <Spin spinning={application.loadingVisible}>{children}</Spin>;
  }
}

export default App;
