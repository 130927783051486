import dayjs from 'dayjs';
import React from 'react';
import reaction from 'reaction';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import locale from 'antd/lib/date-picker/locale/zh_CN';

import {
  Table,
  Button,
  Input,
  Select,
  DatePicker,
  Form,
  // message,
  // Popconfirm,
} from 'antd';

import FormItem from 'components/FormItem'

import { RoutePath, AuthCodes } from 'utils/constants';
import { transformParamsToUrl, getUniqTableRowKey, renderListControls } from 'utils';

import commonStyles from 'style/common.module.scss';

const { Option } = Select;
const { RangePicker } = DatePicker;
const dateFormat = 'YYYY/MM/DD';

@connect(({ groupSms, me }) => ({ groupSms, me }))
@withRouter
class GroupSmsList extends React.Component {
  state = {
    statusDict: [],
    query: Immutable.Map({
      topic: '',
      createTime: [],
      createUser: '',
      firstSendTime: [],
      topicStatus: '',
      page: 1,
      length: 10,
    }),
  };
  componentDidMount() {
    this.getGroupSmsList();
    this.getGroupStatusDict();
  }

  getGroupStatusDict = () => {
    reaction.groupSms.getGroupStatusDict().then((data) => {
      this.setState({
        statusDict: data.data,
      });
    });
  };

  handleTableChange = (pagination, filters, sorter) => {
    let { query } = this.state;
    query = query.set('page', pagination.current);
    query = query.set('length', pagination.pageSize);
    this.setState({ query }, () => {
      this.getGroupSmsList();
    });
  };

  onQueryChange = (name, value) => {
    let { query } = this.state;
    query = query.set(name, value);
    this.setState({ query }, () => {});
  };

  doSearch = (e) => {
    let { query } = this.state;
    query = query.set('page', 1);
    this.setState({ query }, () => {
      this.getGroupSmsList();
    });
    e && e.preventDefault();
  };

  onPressEnter = () => {
    this.doSearch();
  };

  getGroupSmsList = () => {
    let {
      topic,
      createTime,
      createUser,
      firstSendTime,
      topicStatus,
      page,
      length,
    } = this.state.query.toJS();
    let params = {
      topic,
      createTime,
      createUser,
      firstSendTime,
      topicStatus,
      page,
      length,
    };
    if (createTime[0]) {
      params.createBeginTime = createTime[0].startOf('day').valueOf();
      params.createEndTime = createTime[1].endOf('day').valueOf();
    }

    if (firstSendTime[0]) {
      params.fireBeginTime = firstSendTime[0].startOf('day').valueOf();
      params.fireEndTime = firstSendTime[1].endOf('day').valueOf();
    }

    return reaction.groupSms.getGroupSmsList(params);
  };

  handleCopy = (record) => {
    this.props.history.push({
      pathname: `/${RoutePath.OPERATION_CENTER}/${RoutePath.GROUP_SMS}/${RoutePath.ADD}`,
      search: transformParamsToUrl({ groupId: record.groupId })
    });
  };
  handleView = (record) => {
    this.props.history.push({
      pathname: `/${RoutePath.OPERATION_CENTER}/${RoutePath.GROUP_SMS}/${RoutePath.DETAIL}`,
      search: transformParamsToUrl({ groupId: record.groupId })
    });
  };
  handleClose = (record) => {
    reaction.groupSms.closeGroupSms(record.groupId).then((res) => {
      if (res.code === 0) {
        this.getGroupSmsList();
      }
    });
  };
  handleAddGroupSms = () => {
    this.props.history.push(
      `/${RoutePath.OPERATION_CENTER}/${RoutePath.GROUP_SMS}/${RoutePath.ADD}`
    );
  };

  render() {
    let { list } = this.props.groupSms;
    let { roleInfo } = this.props.me;
    let queryValue = this.state.query.toJS();
    let { statusDict } = this.state;
    let roleAuthList = roleInfo.roleAuthList;
    let enableCopy = roleAuthList.some(
      (item) => item.authCode === AuthCodes.OPERATION_GROUPSMS_COPY
    );
    let enableView = roleAuthList.some(
      (item) => item.authCode === AuthCodes.OPERATION_GROUPSMS_VIEW
    );
    let enableClose = roleAuthList.some(
      (item) => item.authCode === AuthCodes.OPERATION_GROUPSMS_CLOSE
    );
    let enableCreate = roleAuthList.some(
      (item) => item.authCode === AuthCodes.OPERATION_GROUPSMS_CREATE
    );
    let columns = [
      {
        title: '批次号',
        dataIndex: 'serialNo',
      },
      {
        title: '主题',
        dataIndex: 'topic',
      },
      {
        title: '创建时间',
        dataIndex: 'createTime',
        render(text) {
          if (text) {
            return dayjs(text).format('YYYY-MM-DD HH:mm:ss');
          }
          return '';
        },
      },
      {
        title: '创建人',
        dataIndex: 'createUser',
      },
      {
        title: '首发时间',
        dataIndex: 'fireTime',
        render(text) {
          if (text) {
            return dayjs(text).format('YYYY-MM-DD HH:mm:ss');
          }
          return '';
        },
      },
      {
        title: '发送人数',
        dataIndex: 'receiverNum',
      },
      {
        title: '失败数',
        dataIndex: 'failNum',
      },
      {
        title: '状态',
        dataIndex: 'topicStatus',
      },
      {
        title: '操作',
        key: 'handler',
        render: (text, record) => (
          <div>
            <Button
              disabled={!enableCopy}
              style={{ marginLeft: 8 }}
              type="primary"
              onClick={() => {
                this.handleCopy(record);
              }}
            >
              复制
            </Button>
            <Button
              disabled={!enableView}
              style={{ marginLeft: 8 }}
              type="primary"
              onClick={() => {
                this.handleView(record);
              }}
            >
              查看
            </Button>
            {['待审核', '未处理'].indexOf(record.topicStatus) !== -1 && (
              <Button
                disabled={!enableClose}
                style={{ marginLeft: 8 }}
                type="primary"
                onClick={() => {
                  this.handleClose(record);
                }}
              >
                关闭
              </Button>
            )}
          </div>
        ),
      },
    ];
    return (
      <div style={{ padding: '0 0 50px 0' }}>
        <h4 style={{ padding: '10px 0 20px 0', overflow: 'hidden' }}>
          <span>运营中心 / 短信群发</span>
        </h4>
        <Form layout="inline">
          {
            renderListControls([
              <FormItem label="主题">
                <Input
                  onPressEnter={this.onPressEnter}
                  value={queryValue.topic}
                  onChange={(e) => {
                    this.onQueryChange('topic', e.target.value);
                  }}
                  placeholder="请输入主题"
                />
              </FormItem>,
              <FormItem label="创建时间">
                <RangePicker
                  value={queryValue.createTime}
                  format={dateFormat}
                  locale={locale}
                  onChange={(value) => {
                    this.onQueryChange('createTime', value);
                  }}
                />
              </FormItem>,
              <FormItem label="创建人">
                <Input
                  onPressEnter={this.onPressEnter}
                  value={queryValue.createUser}
                  onChange={(e) => {
                    this.onQueryChange('createUser', e.target.value);
                  }}
                  placeholder="请输入创建人"
                />
              </FormItem>,
              <FormItem label="首发时间">
                <RangePicker
                  value={queryValue.firstSendTime}
                  format={dateFormat}
                  locale={locale}
                  onChange={(value) => {
                    this.onQueryChange('firstSendTime', value);
                  }}
                />
              </FormItem>,
              <FormItem label="状态">
                <Select
                  showSearch
                  placeholder="请选择"
                  value={queryValue.topicStatus}
                  onChange={(v) => {
                    this.onQueryChange('topicStatus', v);
                  }}
                >
                  <Option value="">全部</Option>
                  {statusDict.map((item) => {
                    return <Option value={item.code} key={item.code}>{item.name}</Option>;
                  })}
                </Select>
              </FormItem>,
              {
                colProps: {
                  xxl: 18,
                },
                children: (
                  <FormItem align="right">
                    <Button
                      type="primary"
                      onClick={this.doSearch}
                      icon="search"
                    >
                      查询
                    </Button>
                    <Button
                      disabled={!enableCreate}
                      style={{ marginLeft: 10 }}
                      icon="plus"
                      type="primary"
                      onClick={this.handleAddGroupSms}
                    >
                      新增群发任务
                    </Button>
                  </FormItem>
                )
              }
            ])
          }
        </Form>
        {list && (
          <Table
            className={commonStyles.row}
            rowKey={getUniqTableRowKey('groupId', list.pagerInfo)}
            columns={columns}
            dataSource={list.result}
            pagination={{
              current: list.pagerInfo.page,
              total: list.pagerInfo.total,
              pageSizeOptions: ['10', '20', '50'],
              showSizeChanger: true,
              showQuickJumper: true,
              showTotal: (total) => `共${total}条`,
            }}
            onChange={this.handleTableChange}
          />
        )}
      </div>
    );
  }
}

export default GroupSmsList;
