import React from 'react';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import Immutable from 'immutable';
import reaction from 'reaction';
import {
  Table,
  Button,
  Input,
  Select,
  DatePicker,
  Modal,
  Radio,
  message,
  Form,
  Upload,
  Icon,
  AutoComplete,
} from 'antd';
import commonStyles from 'style/common.module.scss';
import { historyPush } from 'utils/history';
import { RoutePath } from 'utils/constants';
import * as env from 'config/env';
import { resolveUrlQuery, formatInviteSteelValue, formatStringSymbol, getUniqTableRowKey, fixFormValues, checkIsPurchase, checkIsSupplier, getFilePreviewUrl, getPreviewImageWidth } from 'utils';
import DatePickAndCheckboxFormItem from 'components/DatePickAndCheckboxFormItem';
import TreeTransfer from 'components/TreeTransfer';
import PageLoading from 'components/PageLoading';

const AOption = AutoComplete.Option;
const FormItem = Form.Item;
const RadioGroup = Radio.Group;
const { TextArea } = Input;
const Option = Select.Option;

@Form.create()
@connect(({ memberCenter, common, frontUser, me }) => ({ memberCenter, common, frontUser, me }))
class ModifyQualification extends React.Component {
  state = {
    query: Immutable.Map({
      page: 1,
      length: 10,
    }),
    showBigImg: false,
    bigImgUrl: '',
    imgWidth: 520,
    showProxy: true,
    expirationTime: '',
    businessLicenceUrl: [],
    organizationCodeUrl: [],
    taxRegistryUrl: [],
    accountLicenseUrl: [],

    province: [],
    city: [],
    district: [],

    proxy: [],
    foreignTradeRegistrationUrl: [],
    legalPersonIdentityCardFrontUrl: [],
    legalPersonIdentityCardBackUrl: [],
    agentIdentityCardFrontUrl: [],
    agentIdentityCardBackUrl: [],

    pageLoading: false,
    purchaserRenderPromise: () => {},
    supplyRenderPromise: () => {},
  };
  getAreaDict = () => {
    return reaction.memberCenter.getAreaDict(false).then((res) => {
      if (res.code === 0) {
        this.setState({
          province: res.data.filter((item) => item.type === '2'),
        });
      }
    });
  };

  onProvinceChange = (v) => {
    this.setState({
      city: this.props.memberCenter.areaDict.filter(
        (item) => item.parentId === v
      ),
    });
    this.props.form.setFieldsValue({
      'cert.baseInfo.districtCode': '',
      'baseInfo.districtCode': '',
      'cert.baseInfo.cityCode': '',
      'baseInfo.cityCode': '',
    });
  };
  onCityChange = (v) => {
    this.setState({
      district: this.props.memberCenter.areaDict.filter(
        (item) => item.parentId === v
      ),
    });
    this.props.form.setFieldsValue({
      'cert.baseInfo.districtCode': '',
      'baseInfo.districtCode': '',
    });
  };
  onDistrictChange = (v) => {};

  getQualificationInfo = () => {
    let userId = resolveUrlQuery(this.props.location.search).userId;
    return reaction.memberCenter.getQualificationInfo({ userId }, false).then((res) => {
      if (res.code === 0) {
        if (res.data.cert && res.data.cert.proxyType === '3') {
          this.setState({
            showProxy: false,
          });
        }
        this.setState({
            businessLicenceUrl: res.data.cert.attachment &&
              res.data.cert.attachment.businessLicenceUrl &&
              res.data.cert.attachment.businessLicenceUrl.url && [
                {
                  uid: 'businessLicenceUrl',
                  name: '',
                  status: 'done',
                  thumbUrl: res.data.cert.attachment.businessLicenceUrl.url,
                  fileKey: res.data.cert.attachment.businessLicenceUrl.fileKey,
                },
              ],
            organizationCodeUrl: res.data.cert.attachment &&
              res.data.cert.attachment.organizationCodeUrl &&
              res.data.cert.attachment.organizationCodeUrl.url && [
                {
                  uid: 'organizationCodeUrl',
                  name: '',
                  status: 'done',
                  thumbUrl: res.data.cert.attachment.organizationCodeUrl.url,
                  fileKey: res.data.cert.attachment.organizationCodeUrl.fileKey,
                },
              ],
            taxRegistryUrl: res.data.cert.attachment &&
              res.data.cert.attachment.taxRegistryUrl &&
              res.data.cert.attachment.taxRegistryUrl.url && [
                {
                  uid: 'taxRegistryUrl',
                  name: '',
                  status: 'done',
                  thumbUrl: res.data.cert.attachment.taxRegistryUrl.url,
                  fileKey: res.data.cert.attachment.taxRegistryUrl.fileKey,
                },
              ],
            accountLicenseUrl: res.data.cert.attachment &&
              res.data.cert.attachment.accountLicenseUrl &&
              res.data.cert.attachment.accountLicenseUrl.url && [
                {
                  uid: 'accountLicenseUrl',
                  name: '',
                  status: 'done',
                  thumbUrl: res.data.cert.attachment.accountLicenseUrl.url,
                  fileKey: res.data.cert.attachment.accountLicenseUrl.fileKey,
                },
              ],
            proxy:
              res.data.cert.proxy &&
              res.data.cert.proxy
                .map((item, index) => {
                  return {
                    uid: 'proxy' + index,
                    name: '',
                    status: 'done',
                    thumbUrl: item.url,
                    fileKey: item.fileKey,
                  };
                })
                .filter((item) => item.thumbUrl && item.fileKey),
            foreignTradeRegistrationUrl: res.data.additionalCert &&
              res.data.additionalCert.foreignTradeRegistrationUrl &&
              res.data.additionalCert.foreignTradeRegistrationUrl.url && [
                {
                  uid: 'foreignTradeRegistrationUrl',
                  name: '',
                  status: 'done',
                  thumbUrl:
                    res.data.additionalCert.foreignTradeRegistrationUrl.url,
                  fileKey:
                    res.data.additionalCert.foreignTradeRegistrationUrl.fileKey,
                },
              ],
            legalPersonIdentityCardFrontUrl: res.data.auditAdditionalCert &&
              res.data.auditAdditionalCert.legalPersonIdentityCardFrontUrl &&
              res.data.auditAdditionalCert.legalPersonIdentityCardFrontUrl
                .url && [
                {
                  uid: 'legalPersonIdentityCardFrontUrl',
                  name: '',
                  status: 'done',
                  thumbUrl:
                    res.data.auditAdditionalCert.legalPersonIdentityCardFrontUrl
                      .url,
                  fileKey:
                    res.data.auditAdditionalCert.legalPersonIdentityCardFrontUrl
                      .fileKey,
                },
              ],
            legalPersonIdentityCardBackUrl: res.data.auditAdditionalCert &&
              res.data.auditAdditionalCert.legalPersonIdentityCardBackUrl &&
              res.data.auditAdditionalCert.legalPersonIdentityCardBackUrl
                .url && [
                {
                  uid: 'legalPersonIdentityCardBackUrl',
                  name: '',
                  status: 'done',
                  thumbUrl:
                    res.data.auditAdditionalCert.legalPersonIdentityCardBackUrl
                      .url,
                  fileKey:
                    res.data.auditAdditionalCert.legalPersonIdentityCardBackUrl
                      .fileKey,
                },
              ],
            agentIdentityCardFrontUrl: res.data.auditAdditionalCert &&
              res.data.auditAdditionalCert.agentIdentityCardFrontUrl &&
              res.data.auditAdditionalCert.agentIdentityCardFrontUrl.url && [
                {
                  uid: 'agentIdentityCardFrontUrl',
                  name: '',
                  status: 'done',
                  thumbUrl:
                    res.data.auditAdditionalCert.agentIdentityCardFrontUrl.url,
                  fileKey:
                    res.data.auditAdditionalCert.agentIdentityCardFrontUrl
                      .fileKey,
                },
              ],
            agentIdentityCardBackUrl: res.data.auditAdditionalCert &&
              res.data.auditAdditionalCert.agentIdentityCardBackUrl &&
              res.data.auditAdditionalCert.agentIdentityCardBackUrl.url && [
                {
                  uid: 'agentIdentityCardBackUrl',
                  name: '',
                  status: 'done',
                  thumbUrl:
                    res.data.auditAdditionalCert.agentIdentityCardBackUrl.url,
                  fileKey:
                    res.data.auditAdditionalCert.agentIdentityCardBackUrl
                      .fileKey,
                },
              ],
            city: this.props.memberCenter.areaDict.filter(
              (item) => item.parentId === res.data.cert.baseInfo.provinceCode
            ),
            district: this.props.memberCenter.areaDict.filter(
              (item) => item.parentId === res.data.cert.baseInfo.cityCode
        )}
        );
      }
      return res.data;
    });
  };
  
  handleSubmit = (e) => {
    e && e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        values.userId = resolveUrlQuery(this.props.location.search).userId;
        values.department = values.department || '';
        values.salesman = values.salesman || null;
        values.auditAdditionalCert.businessLicenseExpirationTime = values
          .auditAdditionalCert.businessLicenseExpirationTime.expirationTime
          ? dayjs(
              values.auditAdditionalCert.businessLicenseExpirationTime
                .expirationTime
            ).valueOf()
          : null;
        // values.cert.baseInfo.companyNameZh =
        //   values.cert.baseInfo.companyNameZh || null;
        // values.cert.baseInfo.companyNameEn =
        //   values.cert.baseInfo.companyNameEn || null;
      //   values.cert.baseInfo.companyAbbreviation =
      //   values.cert.baseInfo.companyAbbreviation || null; // 公司简称
      // values.cert.baseInfo.usedCompanyName =
      //   values.cert.baseInfo.usedCompanyName || null; // 曾用名
        // 不知道他之前为什么要用null？直接空字符串不行？
        values.cert.baseInfo.companyNameZh = formatStringSymbol(values.cert.baseInfo.companyNameZh);
        values.cert.baseInfo.companyNameEn = formatStringSymbol(values.cert.baseInfo.companyNameEn);
        values.cert.baseInfo.usedCompanyName = formatStringSymbol(values.cert.baseInfo.usedCompanyName);
        values.cert.baseInfo.companyAbbreviation = formatStringSymbol(values.cert.baseInfo.companyAbbreviation);

        values.cert.contactInfo.qq = values.cert.contactInfo.qq || null;
        values.auditAdditionalCert.agentPhone =
          values.auditAdditionalCert.agentPhone || null;
        values.additionalCert.foreignTradeEnterpriseCode =
          values.additionalCert.foreignTradeEnterpriseCode || null;
        values.cert.attachment.businessLicenceUrl = values.cert.attachment
          .businessLicenceUrl &&
          values.cert.attachment.businessLicenceUrl.fileList &&
          values.cert.attachment.businessLicenceUrl.fileList[0] && {
            fileKey:
              values.cert.attachment.businessLicenceUrl.fileList[0].fileKey,
          };
        values.cert.attachment.organizationCodeUrl = values.cert.attachment
          .organizationCodeUrl &&
          values.cert.attachment.organizationCodeUrl.fileList &&
          values.cert.attachment.organizationCodeUrl.fileList[0] && {
            fileKey:
              values.cert.attachment.organizationCodeUrl.fileList[0].fileKey,
          };
        values.cert.attachment.taxRegistryUrl = values.cert.attachment
          .taxRegistryUrl &&
          values.cert.attachment.taxRegistryUrl.fileList &&
          values.cert.attachment.taxRegistryUrl.fileList[0] && {
            fileKey: values.cert.attachment.taxRegistryUrl.fileList[0].fileKey,
          };
        values.cert.attachment.accountLicenseUrl = values.cert.attachment
          .accountLicenseUrl &&
          values.cert.attachment.accountLicenseUrl.fileList &&
          values.cert.attachment.accountLicenseUrl.fileList[0] && {
            fileKey:
              values.cert.attachment.accountLicenseUrl.fileList[0].fileKey,
          };
        values.cert.proxy =
          values.cert.proxy &&
          values.cert.proxy.fileList &&
          values.cert.proxy.fileList.map((item) => {
            return { fileKey: item.fileKey, url: item.url };
          });
        values.additionalCert.foreignTradeRegistrationUrl = values
          .additionalCert.foreignTradeRegistrationUrl &&
          values.additionalCert.foreignTradeRegistrationUrl &&
          values.additionalCert.foreignTradeRegistrationUrl.fileList &&
          values.additionalCert.foreignTradeRegistrationUrl.fileList[0] && {
            fileKey:
              values.additionalCert.foreignTradeRegistrationUrl.fileList[0]
                .fileKey,
          };
        values.auditAdditionalCert.legalPersonIdentityCardFrontUrl = values
          .auditAdditionalCert.legalPersonIdentityCardFrontUrl &&
          values.auditAdditionalCert.legalPersonIdentityCardFrontUrl.fileList &&
          values.auditAdditionalCert.legalPersonIdentityCardFrontUrl
            .fileList[0] && {
            fileKey:
              values.auditAdditionalCert.legalPersonIdentityCardFrontUrl
                .fileList[0].fileKey,
          };
        values.auditAdditionalCert.legalPersonIdentityCardBackUrl = values
          .auditAdditionalCert.legalPersonIdentityCardBackUrl &&
          values.auditAdditionalCert.legalPersonIdentityCardBackUrl.fileList &&
          values.auditAdditionalCert.legalPersonIdentityCardBackUrl
            .fileList[0] && {
            fileKey:
              values.auditAdditionalCert.legalPersonIdentityCardBackUrl
                .fileList[0].fileKey,
          };
        values.auditAdditionalCert.agentIdentityCardFrontUrl = values
          .auditAdditionalCert.agentIdentityCardFrontUrl &&
          values.auditAdditionalCert.agentIdentityCardFrontUrl.fileList &&
          values.auditAdditionalCert.agentIdentityCardFrontUrl.fileList[0] && {
            fileKey:
              values.auditAdditionalCert.agentIdentityCardFrontUrl.fileList[0]
                .fileKey,
          };
        values.auditAdditionalCert.agentIdentityCardBackUrl = values
          .auditAdditionalCert.agentIdentityCardBackUrl &&
          values.auditAdditionalCert.agentIdentityCardBackUrl.fileList &&
          values.auditAdditionalCert.agentIdentityCardBackUrl.fileList[0] && {
            fileKey:
              values.auditAdditionalCert.agentIdentityCardBackUrl.fileList[0]
                .fileKey,
          };
        values.auditAdditionalCert.registeredCapital =
          values.auditAdditionalCert.registeredCapital * 10000;

        // 新增字段

        values.cert.baseInfo.registerTime = values.cert.baseInfo.registerTime
          ? dayjs(values.cert.baseInfo.registerTime).valueOf()
          : null; // 注册时间
        values.cert.baseInfo.website = values.cert.baseInfo.website || null; // 企业网站
        values.cert.baseInfo.enterprisePhone =
          values.cert.baseInfo.enterprisePhone || null; // 企业电话
        values.cert.baseInfo.corporateFax =
          values.cert.baseInfo.corporateFax || null; // 企业传真
        values.cert.baseInfo.zipCode = values.cert.baseInfo.zipCode || null; // 邮编
        values.cert.baseInfo.invitedSteel = formatInviteSteelValue(values.cert.baseInfo.invitedSteel); // 避免和后台返回的列表冲突
        values.additionalCert.supplyCategory = values.additionalCert
          .supplyCategory
          ? values.additionalCert.supplyCategory.targetKeys || []
          : []; // 供应品类
        values.additionalCert.purchaserCategory = values.additionalCert
          .purchaserCategory
          ? values.additionalCert.purchaserCategory.targetKeys || []
          : []; // 采购品类
        values.auditAdditionalCert.registeredCapital =
          values.auditAdditionalCert.registeredCapital || null; // 注册资本
        values.auditAdditionalCert.openBank =
          values.auditAdditionalCert.openBank || null; // 开户行
        values.auditAdditionalCert.accountName =
          values.auditAdditionalCert.accountName || null; // 户名
        values.auditAdditionalCert.accountNo =
          values.auditAdditionalCert.accountNo || null; // 账号
        values.auditAdditionalCert.bond =
          values.auditAdditionalCert.bond || null; // 保证金
        values = fixFormValues(values);
        reaction.qualification
          .modifyCompanyQualification(values)
          .then((res) => {
            if (res.code === 0) {
              message.success('修改成功');
              this.back();
            }
          });
      }
    });
  };
  closeBigImgModal = () => {
    this.setState({
      showBigImg: false,
    });
  };
  beforeUpload = (onlyOne, file, fileList) => {
    if (file.size > 10240000) {
      message.error('文件大小不能超过10MB!');
      fileList.splice(fileList.indexOf(file), 1);
      return false;
    }
    if (!(/^image\//.test(file.type))) {
      message.error('只能上传图片文件!');
      fileList.splice(fileList.indexOf(file), 1);
      return false;
    }
    return true;
  };

  onUploadChange = (onlyOne, data, key) => {
    if (data.file.response && data.file.response.code === 0) {
      if (onlyOne && data.fileList.length > 1) {
        data.fileList.shift();
      }
    } else if (data.file.response && data.file.response.code !== 0) {
      data.fileList.splice(data.fileList.indexOf(data.file), 1);
      message.error(data.file.response.msg || '上传失败!');
    }
    data.fileList = data.fileList.map((item) => {
      if (item.response) {
        item.fileKey = item.response.data.url;
      }
      return item;
    });
    this.setState({
      [key]: data.fileList,
    });
  };

  onRemove = (key, { file, fileList }) => {};

  onPreview = (file) => {
    let url = getFilePreviewUrl(file);
    this.setState(
      {
        showBigImg: true,
        bigImgUrl: url,
      },
      () => {
        getPreviewImageWidth(url)
          .then(imgWidth => {
            this.setState({
              imgWidth
            });
          })
      }
    );
  };

  onProxyTypeChange = (e) => {
    if (e.target.value === '3') {
      this.setState({
        showProxy: false,
      });
    } else {
      this.setState({
        showProxy: true,
      });
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    let { query } = this.state;
    query = query.set('page', pagination.current);
    query = query.set('length', pagination.pageSize);
    this.setState({ query }, () => {
      this.getModifyHistoryList();
    });
  };

  getModifyHistoryList = () => {
    let { page, length } = this.state.query.toJS();
    return reaction.memberCenter.getOperationHistory({
      userId: resolveUrlQuery(this.props.location.search).userId,
      page,
      length,
      operationType: resolveUrlQuery(this.props.location.search).operationType,
    }, false);
  };

  back = () => {
    let backPath = resolveUrlQuery(this.props.location.search).backPath;
    if (backPath) {
      historyPush(`${decodeURIComponent(backPath)}`);
    } else {
      historyPush(`/${RoutePath.MEMBERCENTER}/${RoutePath.QUALIFICATION_LIST}`);
    }
  };

  validateFields = (field) => {
    this.props.form.validateFields(field);
  };

  getCompanyCharacter = (v) => {
    return reaction.memberCenter.getCompanyCharacter(v);
  };

  getBusinessCharacter = () => {
    return reaction.memberCenter.getBusinessCharacter({}, false);
  };

  getDepartmentDict = () => {
    return reaction.common.getDepartmentDict();
  };

  handleDepartmentChange = (_, { props }) => {
    this.fetchDepartmentTeamList(props.id);
    this.setFieldsValue('team', '');
  }

  fetchDepartmentTeamList(id) {
    if (!id) {
      return ;
    }
    return reaction.frontUser.getDepartmentTeamList({
      parentId: id,
    }, false);
  }

  hasSelectDepartment() {
    return !!this.props.form.getFieldValue('department');
  }

  setFieldsValue(name, value) {
    this.props.form.setFieldsValue({
      [name]: value,
    })
  }
  purchaserRenderState(identity) {
    if (!this.validateHasPurchaserCategory(identity)) {
      return false;
    }
    return new Promise(resolve => {
      this.setState({
        purchaserRenderPromise: resolve,
      });
    })
  }
  supplyCategoryRenderState(identity) {
    if (!this.validateHasSupplyCategory(identity)) {
      return false;
    }
    return new Promise(resolve => {
      this.setState({
        supplyRenderPromise: resolve,
      });
    })
  }

  validateHasPurchaserCategory(identity) {
    return checkIsPurchase(identity);
  }

  validateHasSupplyCategory(identity = []) {
    return checkIsSupplier(identity);
  };

  async componentDidMount() {
    this.setState({
      pageLoading: true,
    });
    await this.getModifyHistoryList();
    await this.getAreaDict();
    const certInfo = await this.getQualificationInfo() || {};
    await this.fetchDepartmentTeamList(certInfo.departmentId);
    await this.getBusinessCharacter();
    
    this.setState({
      pageLoading: false,
    });
  }

  getColumns() {
    return [
      {
        width: 112,
        title: '时间',
        dataIndex: 'updateTime',
        render: (text) => {
          if (!text) {
            return '';
          }
          return dayjs(text - 0).format('YYYY-MM-DD HH:mm:ss');
        },
      },
      {
        width: 140,
        title: '操作人',
        dataIndex: 'operator',
      },
      {
        title: '内容',
        dataIndex: 'updateContent',
        render: (text) => {
          return <pre style={{ maxWidth: '1000px' }}>{text.trim()}</pre>;
        },
      },
    ]
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    let { invitednterprises } = this.props.common;
    const { departments = [], manufactorTypeList } = this.props.me;
    const { teamList = [], teamListLoading } = this.props.frontUser;

    let {
      companyCharacter,
      businessCharacter,
      qualificationDetailInfo,
    } = this.props.memberCenter;
    let {
      businessLicenceUrl,
      organizationCodeUrl,
      taxRegistryUrl,
      accountLicenseUrl,
      proxy,
      foreignTradeRegistrationUrl,
      legalPersonIdentityCardFrontUrl,
      legalPersonIdentityCardBackUrl,
      agentIdentityCardFrontUrl,
      agentIdentityCardBackUrl,
    } = this.state;
    let formItemLayout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 20 },
    };
    const uploadButton = (
      <div>
        <Icon type={this.state.loading ? 'loading' : 'plus'} />
        <div className="ant-upload-text">点击上传</div>
      </div>
    );
    let modifyHistoryList = this.props.memberCenter.operationHistoryList;
    let { province, city, district, showProxy } = this.state;

    const registerTime = qualificationDetailInfo.cert?.baseInfo?.registerTime??null;
    const businessLicenseExpirationTime = qualificationDetailInfo.auditAdditionalCert?.businessLicenseExpirationTime??null;

    return (
      <PageLoading loading={this.state.pageLoading}>
        <h2 className={commonStyles.row}>资质信息修改</h2>
        <div style={{ overflow: 'hidden' }}>
          <div style={{ float: 'left', width: 100 }}>账户信息</div>
          <div style={{ marginLeft: 100 }}>
            <div style={{ marginBottom: 30 }}>
              <label
                style={{
                  padding: '0 10px 0 0',
                  width: '16.6666666%',
                  textAlign: 'right',
                  display: 'inline-block',
                }}
              >
                会员编号:
              </label>
              <Input
                disabled={true}
                value={qualificationDetailInfo.user.userNo}
                style={{ width: 250 }}
              />
            </div>
            <div style={{ marginBottom: 30 }}>
              <label
                style={{
                  padding: '0 10px 0 0',
                  width: '16.6666666%',
                  textAlign: 'right',
                  display: 'inline-block',
                }}
              >
                用户名:
              </label>
              <Input
                disabled={true}
                value={qualificationDetailInfo.user.username}
                style={{ width: 250 }}
              />
            </div>
            <div style={{ marginBottom: 30 }}>
              <label
                style={{
                  padding: '0 10px 0 0',
                  width: '16.6666666%',
                  textAlign: 'right',
                  display: 'inline-block',
                }}
              >
                安全手机:
              </label>
              <Input
                disabled={true}
                value={qualificationDetailInfo.user.phone}
                style={{ width: 250 }}
              />
            </div>
            <Form>
              <FormItem {...formItemLayout} label="责任部门">
                {getFieldDecorator('department', {
                  rules: [{ required: false, message: '请选择责任部门' }],
                  initialValue: qualificationDetailInfo.department || '',
                })(
                  <Select style={{ width: 250 }} onChange={this.handleDepartmentChange}>
                    {departments &&
                      departments.map((item) => {
                        return (
                          <Option key={item.code} value={item.code || ''} id={item.id}>
                            {item.name}
                          </Option>
                        );
                      })}
                  </Select>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="小组">
                {getFieldDecorator('team', {
                  validateTrigger: ['onBlur', 'onChange'],
                  initialValue: qualificationDetailInfo.team || '',
                })(
                  <Select style={{ width: 250 }} disabled={!this.hasSelectDepartment()} loading={teamListLoading}>
                    {teamList.map((item) => {
                      return (
                        <Option key={item.code} value={item.code || ''}>
                          {item.name}
                        </Option>
                      );
                    })}
                  </Select>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="业务员">
                {getFieldDecorator('salesman', {
                  validateTrigger: ['onBlur', 'onChange'],
                  rules: [
                    {
                      required: false,
                      whitespace: true,
                      message: '请输入业务员',
                    },
                  ],
                  initialValue: qualificationDetailInfo.salesman || '',
                })(
                  <Input
                    maxLength={5}
                    style={{ width: 250 }}
                    placeholder="请输入业务员"
                  />
                )}
              </FormItem>
            </Form>
          </div>
        </div>
        <div style={{ overflow: 'hidden' }}>
          <div style={{ float: 'left', width: 100 }}>基础信息</div>
          <div style={{ marginLeft: 100 }}>
            <div style={{ marginBottom: 30 }}>
              <label
                style={{
                  padding: '0 10px 0 0',
                  width: '16.6666666%',
                  textAlign: 'right',
                  display: 'inline-block',
                }}
              >
                公司名称:
              </label>
              <Input
                disabled={true}
                value={qualificationDetailInfo.cert.baseInfo.companyNameZh}
                style={{ width: 250 }}
              />
            </div>
            <Form>
              <FormItem {...formItemLayout} label="公司英文名：">
                {getFieldDecorator('cert.baseInfo.companyNameEn', {
                  rules: [
                    {
                      required: false,
                      message: '请输入公司名称',
                    },
                  ],
                  initialValue:
                    qualificationDetailInfo.cert.baseInfo.companyNameEn || null,
                })(
                  <Input style={{ width: 250 }} placeholder="请输入公司名称" />
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="公司简称：">
                {getFieldDecorator('cert.baseInfo.companyAbbreviation', {
                  rules: [
                    {
                      required: false,
                      message: '请输入公司简称',
                    },
                  ],
                  initialValue:
                    qualificationDetailInfo.cert.baseInfo.companyAbbreviation ||
                    null,
                })(
                  <Input style={{ width: 250 }} placeholder="请输入公司简称" />
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="公司曾用名：">
                {getFieldDecorator('cert.baseInfo.usedCompanyName', {
                  rules: [
                    {
                      required: false,
                      message: '请输入公司曾用名',
                    },
                  ],
                  initialValue:
                    qualificationDetailInfo.cert.baseInfo.usedCompanyName ||
                    null,
                })(
                  <Input
                    style={{ width: 250 }}
                    placeholder="请输入公司曾用名"
                  />
                )}
              </FormItem>
              <FormItem
                labelCol={{ span: 16 }}
                wrapperCol={{ span: 8 }}
                style={{ width: '25%', display: 'inline-block' }}
                label="所在地："
              >
                {getFieldDecorator('cert.baseInfo.provinceCode', {
                  validateTrigger: ['onBlur', 'onChange'],
                  rules: [
                    {
                      required: true,
                      message: '请选择省',
                    },
                  ],
                  initialValue:
                    qualificationDetailInfo.cert.baseInfo.provinceCode,
                })(
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={this.onProvinceChange}
                  >
                    {province.map((item) => {
                      return (
                        <Option key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      );
                    })}
                  </Select>
                )}
              </FormItem>
              <FormItem
                labelCol={{ span: 1 }}
                wrapperCol={{ span: 23 }}
                style={{ width: '10%', display: 'inline-block', marginLeft: 5 }}
                label=""
              >
                {getFieldDecorator('cert.baseInfo.cityCode', {
                  validateTrigger: ['onBlur', 'onChange'],
                  rules: [
                    {
                      required: true,
                      message: '请选择市',
                    },
                  ],
                  initialValue: qualificationDetailInfo.cert.baseInfo.cityCode,
                })(
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={this.onCityChange}
                  >
                    {city.map((item) => {
                      return (
                        <Option key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      );
                    })}
                  </Select>
                )}
              </FormItem>
              <FormItem
                labelCol={{ span: 1 }}
                wrapperCol={{ span: 23 }}
                style={{ width: '10%', display: 'inline-block' }}
                label=""
              >
                {getFieldDecorator('cert.baseInfo.districtCode', {
                  validateTrigger: ['onBlur', 'onChange'],
                  rules: [
                    {
                      required: true,
                      message: '请选择区/县',
                    },
                  ],
                  initialValue:
                    qualificationDetailInfo.cert.baseInfo.districtCode,
                })(
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={this.onDistrictChange}
                  >
                    {district.map((item) => {
                      return (
                        <Option key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      );
                    })}
                  </Select>
                )}
              </FormItem>
              <FormItem
                labelCol={{ span: 1 }}
                wrapperCol={{ span: 23 }}
                style={{ width: '20%', display: 'inline-block' }}
                label=""
              >
                {getFieldDecorator('cert.baseInfo.address', {
                  validateTrigger: ['onBlur', 'onChange'],
                  rules: [
                    {
                      required: true,
                      whitespace: true,
                      message: '请选择输入详细地址',
                    },
                  ],
                  initialValue: qualificationDetailInfo.cert.baseInfo.address,
                })(<Input style={{ width: 250 }} placeholder="详细街道地址" />)}
              </FormItem>
              <FormItem {...formItemLayout} label="公司注册时间">
                {getFieldDecorator('cert.baseInfo.registerTime', {
                  rules: [
                    {
                      required: true,
                      message: '请选择公司注册时间',
                    },
                  ],
                  initialValue: registerTime ? dayjs(registerTime) : null,
                })(<DatePicker onChange={() => {}} style={{ width: 250 }} />)}
              </FormItem>
              <FormItem {...formItemLayout} label="公司网站">
                {getFieldDecorator('cert.baseInfo.website', {
                  validateTrigger: ['onBlur', 'onChange'],
                  rules: [
                    {
                      required: false,
                      whitespace: true,
                      message: '请输入公司网站',
                    },
                  ],
                  initialValue: qualificationDetailInfo.cert.baseInfo.website,
                })(<Input style={{ width: 250 }} placeholder="公司网站" />)}
              </FormItem>
              <FormItem {...formItemLayout} label="受邀钢厂">
                {getFieldDecorator('cert.baseInfo.invitedSteel', {
                  validateTrigger: ['onBlur', 'onChange'],
                  initialValue: invitednterprises.length ? formatInviteSteelValue(qualificationDetailInfo.cert.baseInfo.invitedSteel, true) : '暂无数据',
                  rules: [
                    {
                      required: true,
                      message: '请选择受邀钢厂',
                    },
                  ],
                })(
                  <Select style={{ width: 250 }} placeholder="请选择受邀钢厂" disabled={!invitednterprises.length}>
                    {invitednterprises.map((item) => {
                      return (
                        <Option
                          key={item.code || 'none'}
                          value={item.code || 'none'}
                        >
                          {item.name}
                        </Option>
                      );
                    })}
                  </Select>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="认证目的">
                {getFieldDecorator('cert.baseInfo.authPurpose', {
                  validateTrigger: ['onBlur', 'onChange'],
                  rules: [
                    {
                      whitespace: true,
                    },
                  ],
                  initialValue:
                    qualificationDetailInfo.cert.baseInfo.authPurpose,
                })(
                  <Input
                    maxLength={50}
                    style={{ width: 250 }}
                    placeholder="比如参与交易、招投标等"
                  />
                )}
              </FormItem>
            </Form>
          </div>
        </div>
        <div style={{ overflow: 'hidden' }}>
          <div style={{ float: 'left', width: 100 }}>联系方式</div>
          <div style={{ marginLeft: 100 }}>
            <Form>
              <FormItem {...formItemLayout} label="联系人：">
                {getFieldDecorator('cert.contactInfo.contactPerson', {
                  validateTrigger: ['onBlur', 'onChange'],
                  rules: [
                    {
                      required: true,
                      whitespace: true,
                      message: '请输入联系人',
                    },
                  ],
                  initialValue:
                    qualificationDetailInfo.cert.contactInfo.contactPerson,
                })(<Input style={{ width: 250 }} placeholder="输入联系人" />)}
              </FormItem>
              <FormItem {...formItemLayout} label="联系电话：">
                {getFieldDecorator('cert.contactInfo.phone', {
                  validateTrigger: ['onBlur', 'onChange'],
                  rules: [
                    {
                      required: true,
                      whitespace: true,
                      pattern: /^1\d{10}$/,
                      message: '请输入联系电话',
                    },
                  ],
                  initialValue: qualificationDetailInfo.cert.contactInfo.phone,
                })(<Input style={{ width: 250 }} placeholder="输入联系电话" />)}
              </FormItem>
              <FormItem {...formItemLayout} label="企业电话：">
                {getFieldDecorator('cert.baseInfo.enterprisePhone', {
                  validateTrigger: ['onBlur', 'onChange'],
                  rules: [
                    {
                      required: false,
                      whitespace: true,
                      message: '请输入企业电话',
                    },
                  ],
                  initialValue:
                    qualificationDetailInfo.cert.baseInfo.enterprisePhone,
                })(<Input style={{ width: 250 }} placeholder="输入企业电话" />)}
              </FormItem>
              <FormItem {...formItemLayout} label="企业传真：">
                {getFieldDecorator('cert.baseInfo.corporateFax', {
                  validateTrigger: ['onBlur', 'onChange'],
                  rules: [
                    {
                      required: false,
                      whitespace: true,
                      message: '请输入企业传真',
                    },
                  ],
                  initialValue:
                    qualificationDetailInfo.cert.baseInfo.corporateFax,
                })(
                  <Input
                    style={{ width: 250 }}
                    maxLength={20}
                    placeholder="请输入企业传真"
                  />
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="邮编：">
                {getFieldDecorator('cert.baseInfo.zipCode', {
                  validateTrigger: ['onBlur', 'onChange'],
                  rules: [
                    {
                      required: false,
                      whitespace: true,
                      pattern: /^\d{6}$/,
                      message: '请输入邮编',
                    },
                  ],
                  initialValue: qualificationDetailInfo.cert.baseInfo.zipCode,
                })(<Input style={{ width: 250 }} placeholder="输入邮编" />)}
              </FormItem>
              <FormItem {...formItemLayout} label="QQ：">
                {getFieldDecorator('cert.contactInfo.qq', {
                  rules: [
                    {
                      required: false,
                      message: '请输入QQ',
                    },
                  ],
                  initialValue:
                    qualificationDetailInfo.cert.contactInfo.qq || null,
                })(<Input style={{ width: 250 }} placeholder="输入QQ" />)}
              </FormItem>
            </Form>
          </div>
        </div>
        <div style={{ overflow: 'hidden' }}>
          <div style={{ float: 'left', width: 100 }}>证件附件</div>
          <div style={{ marginLeft: 100, position: 'relative' }}>
            <Form style={{ overflow: 'hidden' }}>
              <FormItem {...formItemLayout} label="营业执照(三证合一)：">
                {getFieldDecorator('cert.attachment.businessLicenceUrl', {
                  rules: [
                    {
                      required: true,
                      message: '请上传营业执照',
                      validator: (rule, value, callback) => {
                        if (!value.fileList || !value.fileList.length) {
                          callback('请上传营业执照');
                        }
                        callback();
                      },
                    },
                  ],
                  initialValue: {
                    fileList: businessLicenceUrl,
                  },
                })(
                  <Upload
                    name="file"
                    accept="image/jpg,image/jpeg,image/png"
                    action={env.getUploadApi('/v1/cert/upload')}
                    fileList={businessLicenceUrl}
                    listType="picture-card"
                    beforeUpload={(file, fileList) => {
                      return this.beforeUpload(true, file, fileList);
                    }}
                    onChange={(data) => {
                      this.onUploadChange(true, data, 'businessLicenceUrl');
                    }}
                    onPreview={(file) => {
                      this.onPreview(file);
                    }}
                    onRemove={(file, fileList) => {
                      this.onRemove('businessLicenceUrl', file, fileList);
                    }}
                  >
                    {uploadButton}
                  </Upload>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="组织机构代码：">
                {getFieldDecorator('cert.attachment.organizationCodeUrl', {
                  rules: [
                    {
                      required: false,
                      message: '请上传组织机构代码',
                    },
                  ],
                  initialValue: {
                    fileList: organizationCodeUrl,
                  },
                })(
                  <Upload
                    name="file"
                    accept="image/jpg,image/jpeg,image/png"
                    fileList={organizationCodeUrl}
                    action={env.getUploadApi('/v1/cert/upload')}
                    listType="picture-card"
                    beforeUpload={(file, fileList) => {
                      return this.beforeUpload(true, file, fileList);
                    }}
                    onChange={(data) => {
                      this.onUploadChange(true, data, 'organizationCodeUrl');
                    }}
                    onPreview={(file) => {
                      this.onPreview(file);
                    }}
                    onRemove={(file, fileList) => {
                      this.onRemove('organizationCodeUrl', file, fileList);
                    }}
                  >
                    {uploadButton}
                  </Upload>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="税务登记证：">
                {getFieldDecorator('cert.attachment.taxRegistryUrl', {
                  rules: [
                    {
                      required: false,
                      message: '请上传税务登记证',
                    },
                  ],
                  initialValue: {
                    fileList: taxRegistryUrl,
                  },
                })(
                  <Upload
                    name="file"
                    accept="image/jpg,image/jpeg,image/png"
                    fileList={taxRegistryUrl}
                    action={env.getUploadApi('/v1/cert/upload')}
                    listType="picture-card"
                    beforeUpload={(file, fileList) => {
                      return this.beforeUpload(true, file, fileList);
                    }}
                    onChange={(data) => {
                      this.onUploadChange(true, data, 'taxRegistryUrl');
                    }}
                    onPreview={(file) => {
                      this.onPreview(file);
                    }}
                    onRemove={(file, fileList) => {
                      this.onRemove('taxRegistryUrl', file, fileList);
                    }}
                  >
                    {uploadButton}
                  </Upload>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="开户许可证：">
                {getFieldDecorator('cert.attachment.accountLicenseUrl', {
                  rules: [
                    {
                      required: false,
                      message: '请上传开户许可证',
                    },
                  ],
                  initialValue: {
                    fileList: accountLicenseUrl,
                  },
                })(
                  <Upload
                    name="file"
                    accept="image/jpg,image/jpeg,image/png"
                    fileList={accountLicenseUrl}
                    action={env.getUploadApi('/v1/cert/upload')}
                    listType="picture-card"
                    beforeUpload={(file, fileList) => {
                      return this.beforeUpload(true, file, fileList);
                    }}
                    onChange={(file) => {
                      this.onUploadChange(true, file, 'accountLicenseUrl');
                    }}
                    onPreview={(file) => {
                      this.onPreview(file);
                    }}
                    onRemove={(file, fileList) => {
                      this.onRemove('accountLicenseUrl', file, fileList);
                    }}
                  >
                    {uploadButton}
                  </Upload>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="委托书：">
                {getFieldDecorator('cert.proxyType', {
                  rules: [
                    {
                      required: true,
                      message: '请选择委托书类型',
                    },
                  ],
                  initialValue: qualificationDetailInfo.cert.proxyType,
                })(
                  <RadioGroup onChange={this.onProxyTypeChange}>
                    <Radio value={'1'}>企业授权委托书</Radio>
                    <Radio value={'2'}>业务担保授权书</Radio>
                    <Radio value={'3'}>无委托书，自愿不进行交易</Radio>
                  </RadioGroup>
                )}
              </FormItem>
              {showProxy && (
                <FormItem {...formItemLayout} label="委托书附件：">
                  {getFieldDecorator('cert.proxy', {
                    rules: [
                      {
                        required: true,
                        message: '请上传委托书附件',
                        validator: (rule, value, callback) => {
                          if (!value.fileList || !value.fileList.length) {
                            callback('请上传委托书附件');
                          }
                          callback();
                        },
                      },
                    ],
                    initialValue: {
                      fileList: proxy,
                    },
                  })(
                    <Upload
                      name="file"
                      accept="image/jpg,image/jpeg,image/png"
                      fileList={proxy}
                      action={env.getUploadApi('/v1/cert/upload')}
                      listType="picture-card"
                      beforeUpload={(file, fileList) => {
                        return this.beforeUpload(true, file, fileList);
                      }}
                      onChange={(file) => {
                        this.onUploadChange(false, file, 'proxy');
                      }}
                      onPreview={(file) => {
                        this.onPreview(file);
                      }}
                      onRemove={(file, fileList) => {
                        this.onRemove('proxy', file, fileList);
                      }}
                    >
                      {uploadButton}
                    </Upload>
                  )}
                </FormItem>
              )}
              { this.validateHasSupplyCategory(qualificationDetailInfo.identity??[]) && (
                  <FormItem {...formItemLayout} label="供应品类">
                    {getFieldDecorator('additionalCert.supplyCategory', {
                      rules: [
                        {
                          required: true,
                          message: '请选择供应品类',
                          validator: (rule, value, callback) => {
                            if (
                              rule.required &&
                              value.targetKeys &&
                              !value.targetKeys.length
                            ) {
                              callback('请选择供应品类');
                            } else {
                              callback();
                            }
                          },
                        },
                      ],
                      initialValue: {
                        targetKeys: qualificationDetailInfo?.additionalCert?.supplyCategory??[],
                      },
                    })(
                      <TreeTransfer
                        expandParentKeys={qualificationDetailInfo?.additionalCert?.supplyFullCategory??[]}
                        onRendered={this.state.supplyRenderPromise}
                      />
                    )}
                  </FormItem>
                )}
                {
                  this.validateHasSupplyCategory(qualificationDetailInfo.identity??[]) && (
                    <FormItem {...formItemLayout} label="厂家类型：">
                      {
                        getFieldDecorator('additionalCert.manufactorType', {
                          initialValue: qualificationDetailInfo.additionalCert?.manufactorType,
                          rules: [
                            {
                              required: true,
                              message: '请选择厂家类型',
                            }
                          ],
                        })(
                          <Select style={{ width: 250 }}>
                            {
                              manufactorTypeList.map(el => {
                                return (
                                  <Option key={el.code} value={el.code}>{el.name}</Option>
                                );
                              })
                            }
                          </Select>
                        )
                      }
                    </FormItem>
                  )
                }
                {this.validateHasPurchaserCategory(qualificationDetailInfo.identity??[]) && (
                  <FormItem {...formItemLayout} label="采购品类">
                    {getFieldDecorator('additionalCert.purchaserCategory', {
                      rules: [
                        {
                          required: true,
                          message: '请选择采购品类',
                          validator: (rule, value, callback) => {
                            if (
                              rule.required &&
                              value.targetKeys &&
                              !value.targetKeys.length
                            ) {
                              callback('请选择采购品类');
                            } else {
                              callback();
                            }
                          },
                        },
                      ],
                      initialValue: {
                        targetKeys: qualificationDetailInfo?.additionalCert?.purchaserCategory??[],
                      },
                    })(
                      <TreeTransfer
                        expandParentKeys={qualificationDetailInfo?.additionalCert?.purchaserFullCategory??[]}
                        onRendered={this.state.purchaserRenderPromise}
                      />
                    )}
                  </FormItem>
                )}
            </Form>
          </div>
        </div>
        <div style={{ overflow: 'hidden' }}>
          <div style={{ float: 'left', width: 100 }}>其他信息</div>
          <div style={{ marginLeft: 100 }}>
            <Form>
              <FormItem {...formItemLayout} label="外贸资质：">
                {getFieldDecorator('additionalCert.hasForeignTradeCert', {
                  rules: [
                    {
                      required: false,
                      message: '请选择是否具有外贸资质',
                    },
                  ],
                  initialValue:
                    qualificationDetailInfo.additionalCert &&
                    qualificationDetailInfo.additionalCert.hasForeignTradeCert,
                })(
                  <RadioGroup>
                    <Radio value={true}>具备</Radio>
                    <Radio value={false}>不具备</Radio>
                  </RadioGroup>
                )}
              </FormItem>
              <FormItem
                {...formItemLayout}
                label="对外贸易经营者备案登记表附件："
              >
                {getFieldDecorator(
                  'additionalCert.foreignTradeRegistrationUrl',
                  {
                    rules: [
                      {
                        required: false,
                        message: '请上传对外贸易经营者备案登记表附件',
                      },
                    ],
                    initialValue: {
                      fileList: foreignTradeRegistrationUrl,
                    },
                  }
                )(
                  <Upload
                    name="file"
                    accept="image/jpg,image/jpeg,image/png"
                    fileList={foreignTradeRegistrationUrl}
                    action={env.getUploadApi('/v1/cert/upload')}
                    listType="picture-card"
                    beforeUpload={(file, fileList) => {
                      return this.beforeUpload(true, file, fileList);
                    }}
                    onChange={(file) => {
                      this.onUploadChange(
                        true,
                        file,
                        'foreignTradeRegistrationUrl'
                      );
                    }}
                    onPreview={(file) => {
                      this.onPreview(file);
                    }}
                    onRemove={(file, fileList) => {
                      this.onRemove(
                        'foreignTradeRegistrationUrl',
                        file,
                        fileList
                      );
                    }}
                  >
                    {uploadButton}
                  </Upload>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="进出口企业代码号：">
                {getFieldDecorator(
                  'additionalCert.foreignTradeEnterpriseCode',
                  {
                    rules: [
                      {
                        required: false,
                        message: '请输入进出口企业代码号',
                      },
                    ],
                    initialValue:
                      qualificationDetailInfo.additionalCert &&
                      qualificationDetailInfo.additionalCert
                        .foreignTradeEnterpriseCode,
                  }
                )(
                  <Input
                    style={{ width: 250 }}
                    placeholder="请输入进出口企业代码号"
                  />
                )}
              </FormItem>
            </Form>
          </div>
        </div>
        <div style={{ overflow: 'hidden' }}>
          <div style={{ float: 'left', width: 100 }}>审核补录信息</div>
          <div style={{ marginLeft: 100 }}>
            <Form>
              <FormItem {...formItemLayout} label="经营性质：">
                {getFieldDecorator('auditAdditionalCert.proprietaryDealer', {
                  rules: [
                    {
                      required: true,
                      whitespace: true,
                      message: '请选择经营性质',
                    },
                  ],
                  initialValue:
                    (qualificationDetailInfo.auditAdditionalCert &&
                      qualificationDetailInfo.auditAdditionalCert
                        .proprietaryDealer) ||
                    'not_proprietary_dealer',
                })(
                  <Select style={{ width: 250 }}>
                    {businessCharacter.map((item) => {
                      return <Option key={item.code} value={item.code}>{item.name}</Option>;
                    })}
                  </Select>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="公司性质：">
                {getFieldDecorator('auditAdditionalCert.companyCharacter', {
                  validateTrigger: ['onBlur', 'onChange'],
                  rules: [
                    {
                      required: true,
                      whitespace: true,
                      message: '请输入公司性质',
                    },
                  ],
                  initialValue:
                    qualificationDetailInfo.auditAdditionalCert &&
                    qualificationDetailInfo.auditAdditionalCert
                      .companyCharacter,
                })(
                  <AutoComplete
                    onChange={(v) => {
                      this.getCompanyCharacter(v);
                    }}
                    style={{ width: 250 }}
                    placeholder="请输入公司性质"
                  >
                    {companyCharacter.map((item) => {
                      return (
                        <AOption key={item.code} value={item.name}>
                          {item.name}
                        </AOption>
                      );
                    })}
                  </AutoComplete>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="注册资本(万元)：">
                {getFieldDecorator('auditAdditionalCert.registeredCapital', {
                  validateTrigger: ['onBlur', 'onChange'],
                  rules: [
                    {
                      required: true,
                      whitespace: true,
                      pattern: /^\d+(\.(?:\d+))?$/,
                      message: '请输入注册资本',
                    },
                  ],
                  initialValue:
                    qualificationDetailInfo.auditAdditionalCert &&
                    qualificationDetailInfo.auditAdditionalCert
                      .registeredCapital &&
                    qualificationDetailInfo.auditAdditionalCert
                      .registeredCapital / 10000,
                })(
                  <Input style={{ width: 250 }} placeholder="请输入注册资本" />
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="公司经营范围：">
                {getFieldDecorator('auditAdditionalCert.companyBusinessScope', {
                  validateTrigger: ['onBlur', 'onChange'],
                  rules: [
                    {
                      required: true,
                      whitespace: true,
                      message: '请输入公司经营范围',
                    },
                  ],
                  initialValue:
                    qualificationDetailInfo.auditAdditionalCert &&
                    qualificationDetailInfo.auditAdditionalCert
                      .companyBusinessScope,
                })(
                  <TextArea
                    rows={4}
                    maxLength={500}
                    style={{ width: 250 }}
                    placeholder="请输入公司经营范围"
                  />
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="法人姓名：">
                {getFieldDecorator('auditAdditionalCert.legalPersonName', {
                  validateTrigger: ['onBlur', 'onChange'],
                  rules: [
                    {
                      required: true,
                      whitespace: true,
                      message: '请输入法人姓名',
                    },
                  ],
                  initialValue:
                    qualificationDetailInfo.auditAdditionalCert &&
                    qualificationDetailInfo.auditAdditionalCert.legalPersonName,
                })(
                  <Input style={{ width: 250 }} placeholder="请输入法人姓名" />
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="法人身份证附件正面：">
                {getFieldDecorator(
                  'auditAdditionalCert.legalPersonIdentityCardFrontUrl',
                  {
                    rules: [
                      {
                        required: false,
                        message: '请上传法人身份证附件正面',
                      },
                    ],
                    initialValue: {
                      fileList: legalPersonIdentityCardFrontUrl,
                    },
                  }
                )(
                  <Upload
                    name="file"
                    accept="image/jpg,image/jpeg,image/png"
                    fileList={legalPersonIdentityCardFrontUrl}
                    action={env.getUploadApi('/v1/cert/upload')}
                    listType="picture-card"
                    beforeUpload={(file, fileList) => {
                      return this.beforeUpload(true, file, fileList);
                    }}
                    onChange={(file) => {
                      this.onUploadChange(
                        true,
                        file,
                        'legalPersonIdentityCardFrontUrl'
                      );
                    }}
                    onPreview={(file) => {
                      this.onPreview(file);
                    }}
                    onRemove={(file, fileList) => {
                      this.onRemove(
                        'legalPersonIdentityCardFrontUrl',
                        file,
                        fileList
                      );
                    }}
                  >
                    {uploadButton}
                  </Upload>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="法人身份证附件背面：">
                {getFieldDecorator(
                  'auditAdditionalCert.legalPersonIdentityCardBackUrl',
                  {
                    rules: [
                      {
                        required: false,
                        message: '请上传法人身份证附件背面',
                      },
                    ],
                    initialValue: {
                      fileList: legalPersonIdentityCardBackUrl,
                    },
                  }
                )(
                  <Upload
                    name="file"
                    accept="image/jpg,image/jpeg,image/png"
                    fileList={legalPersonIdentityCardBackUrl}
                    action={env.getUploadApi('/v1/cert/upload')}
                    listType="picture-card"
                    beforeUpload={(file, fileList) => {
                      return this.beforeUpload(true, file, fileList);
                    }}
                    onChange={(file) => {
                      this.onUploadChange(
                        true,
                        file,
                        'legalPersonIdentityCardBackUrl'
                      );
                    }}
                    onPreview={(file) => {
                      this.onPreview(file);
                    }}
                    onRemove={(file, fileList) => {
                      this.onRemove(
                        'legalPersonIdentityCardBackUrl',
                        file,
                        fileList
                      );
                    }}
                  >
                    {uploadButton}
                  </Upload>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="营业执照期限：">
                {getFieldDecorator(
                  'auditAdditionalCert.businessLicenseExpirationTime',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请选择营业执照期限',
                        validator: (rule, value, callback) => {
                          if (!value.expirationTime) {
                            callback('请选择营业执照期限!');
                          } else {
                            callback();
                          }
                        },
                      },
                    ],
                    initialValue: {
                      expirationTime: businessLicenseExpirationTime ? dayjs(businessLicenseExpirationTime) : null,
                    },
                  }
                )(<DatePickAndCheckboxFormItem />)}
              </FormItem>
              <FormItem {...formItemLayout} label="社会统一信用代码：">
                {getFieldDecorator(
                  'auditAdditionalCert.unifiedSocialCreditCode',
                  {
                    validateTrigger: ['onBlur', 'onChange'],
                    rules: [
                      {
                        required: true,
                        whitespace: true,
                        message: '请输入社会统一信用代码',
                      },
                    ],
                    initialValue: qualificationDetailInfo.auditAdditionalCert?.unifiedSocialCreditCode,
                  }
                )(
                  <Input
                    style={{ width: 250 }}
                    placeholder="请输入社会统一信用代码"
                  />
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="委托人姓名：">
                {getFieldDecorator('auditAdditionalCert.agentName', {
                  validateTrigger: ['onBlur', 'onChange'],
                  rules: [
                    {
                      required: true,
                      whitespace: true,
                      message: '请输入委托人姓名',
                    },
                  ],
                  initialValue: qualificationDetailInfo.auditAdditionalCert.agentName,
                })(
                  <Input
                    style={{ width: 250 }}
                    placeholder="请输入委托人姓名"
                  />
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="开户许可证核准号：">
                {getFieldDecorator('auditAdditionalCert.accountLicenseNo', {
                  rules: [
                    {
                      required: false,
                      message: '请输入开户许可证核准号',
                    },
                  ],
                  initialValue: qualificationDetailInfo.auditAdditionalCert?.accountLicenseNo,
                })(
                  <Input
                    style={{ width: 250 }}
                    placeholder="请输入开户许可证核准号"
                  />
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="开户行：">
                {getFieldDecorator('auditAdditionalCert.openBank', {
                  rules: [
                    {
                      required: false,
                      message: '请输入开户行',
                    },
                  ],
                  initialValue: qualificationDetailInfo.auditAdditionalCert?.openBank,
                })(<Input style={{ width: 250 }} placeholder="请输入开户行" />)}
              </FormItem>
              <FormItem {...formItemLayout} label="户名：">
                {getFieldDecorator('auditAdditionalCert.accountName', {
                  rules: [
                    {
                      required: false,
                      message: '请输入户名',
                    },
                  ],
                  initialValue: qualificationDetailInfo.auditAdditionalCert?.accountName,
                })(<Input style={{ width: 250 }} placeholder="请输入户名" />)}
              </FormItem>
              <FormItem {...formItemLayout} label="账号：">
                {getFieldDecorator('auditAdditionalCert.accountNo', {
                  rules: [
                    {
                      required: false,
                      pattern: /^\d{1,50}$/,
                      message: '请输入正确格式的账号',
                    },
                  ],
                  initialValue: qualificationDetailInfo.auditAdditionalCert?.accountNo,
                })(<Input style={{ width: 250 }} placeholder="请输入账号" />)}
              </FormItem>

              <FormItem {...formItemLayout} label="保证金：">
                {getFieldDecorator('auditAdditionalCert.bond', {
                  rules: [
                    {
                      required: false,
                      pattern: /^\d+\.?(?:\d+)$/,
                      message: '请输入保证金',
                    },
                  ],
                  initialValue: qualificationDetailInfo.auditAdditionalCert?.bond,
                })(<Input style={{ width: 250 }} placeholder="请输入保证金" />)}
              </FormItem>
              <FormItem {...formItemLayout} label="委托人电话：">
                {getFieldDecorator('auditAdditionalCert.agentPhone', {
                  rules: [
                    {
                      required: false,
                      pattern: /^1[\d]{10}$/,
                      message: '请输入委托人电话',
                    },
                  ],
                  initialValue: qualificationDetailInfo.auditAdditionalCert?.agentPhone,
                })(
                  <Input
                    style={{ width: 250 }}
                    placeholder="请输入委托人电话"
                  />
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="委托人身份证正面：">
                {getFieldDecorator(
                  'auditAdditionalCert.agentIdentityCardFrontUrl',
                  {
                    rules: [
                      {
                        required: false,
                        message: '请上传委托人身份证正面',
                      },
                    ],
                    initialValue: {
                      fileList: agentIdentityCardFrontUrl,
                    },
                  }
                )(
                  <Upload
                    name="file"
                    accept="image/jpg,image/jpeg,image/png"
                    fileList={agentIdentityCardFrontUrl}
                    action={env.getUploadApi('/v1/cert/upload')}
                    listType="picture-card"
                    beforeUpload={(file, fileList) => {
                      return this.beforeUpload(true, file, fileList);
                    }}
                    onChange={(file) => {
                      this.onUploadChange(
                        true,
                        file,
                        'agentIdentityCardFrontUrl'
                      );
                    }}
                    onPreview={(file) => {
                      this.onPreview(file);
                    }}
                    onRemove={(file, fileList) => {
                      this.onRemove(
                        'agentIdentityCardFrontUrl',
                        file,
                        fileList
                      );
                    }}
                  >
                    {uploadButton}
                  </Upload>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="委托人身份证背面：">
                {getFieldDecorator(
                  'auditAdditionalCert.agentIdentityCardBackUrl',
                  {
                    rules: [
                      {
                        required: false,
                        message: '请上传委托人身份证背面',
                      },
                    ],
                    initialValue: {
                      fileList: agentIdentityCardBackUrl,
                    },
                  }
                )(
                  <Upload
                    name="file"
                    accept="image/jpg,image/jpeg,image/png"
                    fileList={agentIdentityCardBackUrl}
                    action={env.getUploadApi('/v1/cert/upload')}
                    listType="picture-card"
                    beforeUpload={(file, fileList) => {
                      return this.beforeUpload(true, file, fileList);
                    }}
                    onChange={(file) => {
                      this.onUploadChange(
                        true,
                        file,
                        'agentIdentityCardBackUrl'
                      );
                    }}
                    onPreview={(file) => {
                      this.onPreview(file);
                    }}
                    onRemove={(file, fileList) => {
                      this.onRemove('agentIdentityCardBackUrl', file, fileList);
                    }}
                  >
                    {uploadButton}
                  </Upload>
                )}
              </FormItem>
            </Form>
          </div>
        </div>
        <div style={{ padding: '10px 0 50px 150px' }}>
          <Button onClick={this.handleSubmit}>保存</Button>
          <Button onClick={this.back} style={{ marginLeft: 10 }}>
            返回
          </Button>
        </div>
        <div style={{ padding: '0 0 30px 0' }}>
          {modifyHistoryList && (
            <Table
              className={commonStyles.row}
              rowKey={getUniqTableRowKey('operator', modifyHistoryList.pagerInfo)}
              columns={this.getColumns()}
              pagination={{
                current: modifyHistoryList.pagerInfo.page,
                total: modifyHistoryList.pagerInfo.total,
              }}
              onChange={this.handleTableChange}
              dataSource={modifyHistoryList.result}
            />
          )}
        </div>

        <Modal
          title="查看大图"
          width={this.state.imgWidth + 48}
          visible={this.state.showBigImg}
          onOk={this.closeBigImgModal}
          onCancel={this.closeBigImgModal}
        >
          <img src={this.state.bigImgUrl} alt="" />
        </Modal>
      </PageLoading>
    );
  }
}

export default ModifyQualification;
