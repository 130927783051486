import React from 'react';
import { Table } from 'antd';

export default class WrapperTable extends React.Component {
  componentDidMount () {

  }

  render () {
    let { columns, onTableChange, onChange, data, current, pageSize, onShowSizeChange, rowKey } = this.props;
    let paginationProps = {
      pageSizeOptions: ['10', '20', '50'],
      size: 'large',
      pageSize: pageSize,
      total: data.info ? data.info.total : data.pagerInfo.total,
      current: data.info ? data.info.current : data.pagerInfo.page,
      showSizeChanger: true,
      showQuickJumper: true,
      onChange,
      showTotal: (total) => `共${total}条`,
      onShowSizeChange: onShowSizeChange,
    };

    let dataSource = data && data.result && data.result.map((item) => {
      return item;
    });
    return (
      <div>
        <Table
          bordered
          columns={columns}
          dataSource={dataSource}
          pagination={paginationProps}
          onChange={onTableChange}
          rowKey={rowKey}
        />
      </div>
    );
  }
}
