import React from 'react';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import reaction from 'reaction';
import { Button, Table } from 'antd';
import { historyPush } from 'utils/history';
import { resolveUrlQuery } from 'utils';
import { RoutePath, AuthCodes } from 'utils/constants';

import commonStyles from 'style/common.module.scss';

@connect(({ qualification }) => ({ qualification }))
class ViewIdentify extends React.Component {
  state = {};

  getIdentifyInfo = () => {
    let userId = resolveUrlQuery(this.props.location.search).userId;
    let auditNo = resolveUrlQuery(this.props.location.search).auditNo;
    reaction.qualification.getIdentityInfo({ userId, auditNo });
  };

  back = () => {
    historyPush(`/${RoutePath.MEMBERCENTER}/${RoutePath.IDENTITY_LIST}`);
  };

  componentDidMount() {
    this.getIdentifyInfo();
  }

  render() {
    let identifyInfo = this.props.qualification.identifyInfo;
    let list = identifyInfo.existIdentity;
    let columns = [
      {
        title: '序号',
        dataIndex: 'number',
      },
      {
        title: '平台',
        dataIndex: 'platform',
      },
      {
        title: '身份',
        dataIndex: 'identity',
      },
      {
        title: '开通时间',
        dataIndex: 'createTime',
        render: (text) => {
          if (!text) {
            return '';
          }
          return dayjs(text - 0).format('YYYY-MM-DD HH:mm:ss');
        },
      },
    ];
    return (
      <div>
        <h2 className={commonStyles.row}>身份查看</h2>
        <div style={{ overflow: 'hidden' }}>
          <div style={{ float: 'left', width: 100, fontWeight: 'bold' }}>
            账户信息
          </div>
          <div style={{ marginLeft: 100 }}>
            <div style={{ marginBottom: 30 }}>
              <label
                style={{
                  padding: '0 10px 0 0',
                  width: '16.6666666%',
                  textAlign: 'right',
                  display: 'inline-block',
                }}
              >
                会员编号:
              </label>
              <span>{identifyInfo.user.userNo}</span>
            </div>
            <div style={{ marginBottom: 30 }}>
              <label
                style={{
                  padding: '0 10px 0 0',
                  width: '16.6666666%',
                  textAlign: 'right',
                  display: 'inline-block',
                }}
              >
                用户名:
              </label>
              <span>{identifyInfo.user.username}</span>
            </div>
            <div style={{ marginBottom: 30 }}>
              <label
                style={{
                  padding: '0 10px 0 0',
                  width: '16.6666666%',
                  textAlign: 'right',
                  display: 'inline-block',
                }}
              >
                安全手机:
              </label>
              <span>{identifyInfo.user.phone}</span>
            </div>
          </div>
        </div>
        <div style={{ overflow: 'hidden' }}>
          <div style={{ float: 'left', width: 100, fontWeight: 'bold' }}>
            资质信息
          </div>
          <div style={{ marginLeft: 100 }}>
            <div style={{ marginBottom: 30 }}>
              <label
                style={{
                  padding: '0 10px 0 0',
                  width: '16.6666666%',
                  textAlign: 'right',
                  display: 'inline-block',
                }}
              >
                主体性质:
              </label>
              <span>
                {identifyInfo.certInfo.certType &&
                +identifyInfo.certInfo.certType === 1
                  ? '企业'
                  : '司机'}
              </span>
            </div>
            <div style={{ marginBottom: 30 }}>
              <label
                style={{
                  padding: '0 10px 0 0',
                  width: '16.6666666%',
                  textAlign: 'right',
                  display: 'inline-block',
                }}
              >
                主体名称:
              </label>
              <span>{identifyInfo.certInfo.certSubject}</span>
            </div>
          </div>
        </div>
        <div style={{ overflow: 'hidden' }}>
          <div style={{ float: 'left', width: 100, fontWeight: 'bold' }}>
            已有身份
          </div>
          <div style={{ marginLeft: 100 }}>
            {list && (
              <Table
                className={commonStyles.row}
                rowKey="number"
                columns={columns}
                pagination={false}
                dataSource={list}
              />
            )}
          </div>
        </div>
        <div style={{ padding: '10px 0 50px 150px' }}>
          <Button onClick={this.back}>返回</Button>
        </div>
      </div>
    );
  }
}

export default ViewIdentify;
