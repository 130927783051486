import _objectSpread from "/home/gitlab-runner/builds/bcbe11de/0/commonservice/common-uam-html5/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import _slicedToArray from "/home/gitlab-runner/builds/bcbe11de/0/commonservice/common-uam-html5/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import { useRef, useState, useCallback, useEffect } from 'react';
import { getFilePreviewUrl, getPreviewImageWidth } from 'utils'; // import { RcFile } from 'antd/es/upload';
function resolveAddressList(areaDict, parentId) { return areaDict.filter(function (e) { return e.parentId === parentId; }); } /**
 * 省市区切换
 */
export function useAddress(_ref) { var _ref$areaDict = _ref.areaDict, areaDict = _ref$areaDict === void 0 ? [] : _ref$areaDict, _ref$initialValue = _ref.initialValue, initialValue = _ref$initialValue === void 0 ? {} : _ref$initialValue, onChange = _ref.onChange; var dict = useRef(areaDict); var handlers = useRef({ onChange: onChange }); var _useState = useState([]), _useState2 = _slicedToArray(_useState, 1), provinceList = _useState2[0]; var _useState3 = useState([]), _useState4 = _slicedToArray(_useState3, 2), cityList = _useState4[0], saveCityList = _useState4[1]; var _useState5 = useState([]), _useState6 = _slicedToArray(_useState5, 2), districtList = _useState6[0], saveDistrictList = _useState6[1]; var _useState7 = useState(initialValue), _useState8 = _slicedToArray(_useState7, 2), state = _useState8[0], setState = _useState8[1]; var triggerChange = useCallback(function (values, type, value) { handlers.current.onChange && handlers.current.onChange(values, { type: type, value: value }); }, [handlers]); var updateDistrictList = useCallback(function (parentId) { var _districtList$0$id, _districtList$; var isInitFirstSelect = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false; var districtList = resolveAddressList(dict.current, parentId); var districtId = (_districtList$0$id = (_districtList$ = districtList[0]) === null || _districtList$ === void 0 ? void 0 : _districtList$.id) !== null && _districtList$0$id !== void 0 ? _districtList$0$id : null; saveDistrictList(districtList); if (districtId !== null && isInitFirstSelect) {
    var _values = _objectSpread(_objectSpread({}, state), {}, { districtId: districtId });
    setState(_values);
    triggerChange(_values, 'district', districtId);
} }, [state, triggerChange]); var updateCityList = useCallback(function (parentId) { var _cityList$0$id, _cityList$; var isInitFirstSelect = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false; var cityList = resolveAddressList(dict.current, parentId); var cityId = (_cityList$0$id = (_cityList$ = cityList[0]) === null || _cityList$ === void 0 ? void 0 : _cityList$.id) !== null && _cityList$0$id !== void 0 ? _cityList$0$id : null; saveCityList(cityList); if (cityId !== null && isInitFirstSelect) {
    var _values2 = _objectSpread(_objectSpread({}, state), {}, { cityId: cityId });
    setState(_values2);
    updateDistrictList(resolveAddressList(dict.current, cityId), true);
    triggerChange(_values2, 'city', cityId);
} }, [updateDistrictList, state, triggerChange]); var handleProvinceChange = useCallback(function (provinceId) { var values = _objectSpread(_objectSpread({}, state), {}, { provinceId: provinceId }); setState(values); updateCityList(provinceId, true); triggerChange(values, 'province', provinceId); }, [updateCityList, state, triggerChange]); var handleCityChange = useCallback(function (cityId) { updateDistrictList(cityId); var values = _objectSpread(_objectSpread({}, state), {}, { cityId: cityId }); setState(values); triggerChange(values, 'city', cityId); }, [updateDistrictList, state, triggerChange]); var handleDistrictChange = useCallback(function (districtId) { setState(_objectSpread(_objectSpread({}, state), {}, { districtId: districtId })); var values = _objectSpread(_objectSpread({}, state), {}, { districtId: districtId }); triggerChange(values, 'district', districtId); }, [state, triggerChange]); return { provinceList: provinceList, cityList: cityList, districtList: districtList, handleProvinceChange: handleProvinceChange, handleCityChange: handleCityChange, handleDistrictChange: handleDistrictChange }; } /**
* 获取图片宽度
* @param url
*/
export function useImageWidth(url) { var _useState9 = useState(0), _useState10 = _slicedToArray(_useState9, 2), imageWidth = _useState10[0], setImageWidth = _useState10[1]; useEffect(function () { getPreviewImageWidth(url).then(function (imgWidth) { setImageWidth(~~Math.min(imgWidth, window.innerWidth * 0.75)); }); }, [url]); return imageWidth; } /**
* 图片预览
*/
export function usePreview() { var _useState11 = useState(false), _useState12 = _slicedToArray(_useState11, 2), visible = _useState12[0], toggle = _useState12[1]; var _useState13 = useState(''), _useState14 = _slicedToArray(_useState13, 2), imageUrl = _useState14[0], setImageUrl = _useState14[1]; var imageWidth = useImageWidth(imageUrl); var handleShowPreview = useCallback(function (file) { getFilePreviewUrl(file); toggle(true); setImageUrl(getFilePreviewUrl(file)); }, []); var handleClosePreview = useCallback(function () { toggle(false); }, []); return { visible: visible, imageUrl: imageUrl, imageWidth: imageWidth, handleShowPreview: handleShowPreview, handleClosePreview: handleClosePreview }; }
