import React, { Component } from 'react';
import { Form, Input, Button, Select, Checkbox, DatePicker, Radio } from 'antd';
import classnames from 'classnames';
import reaction from 'reaction';
import { connect } from 'react-redux';
import DatePickAndCheckboxFormItem from 'components/DatePickAndCheckboxFormItem';
import FileUpload from 'components/FileUpload';
import { checkHasDriverIdentity } from 'utils';
import './styles.scss';

const FormItem = Form.Item;
const Option = Select.Option;
const CheckboxGroup = Checkbox.Group;
const RadioGroup = Radio.Group;
// const RangePicker = DatePicker.RangePicker;

const defaultValues = {
  additionalCert: {
    vehicleInfo: {
      licensePlateNo: null,   //车牌号
      semiCarNo: null,        //挂车车牌号
      vehicleType: null,
      vehicleSpecification: null,
      loadCapacity: null,
      runningLicenseUrl: { url: null, fileKey: null },
      runningLicenseExpirationTime: null,
      drivingLicenseUrl: { url: null, fileKey: null },
      transportBusinessQualificationUrl: { url: null, fileKey: null },
      transportBusinessLicenseUrl: { url: null, fileKey: null },
      semiDrivingLicenseUrl: { url: null, fileKey: null },            //挂车行驶证主页
      runningUseCharacter: null,
      vehicleIdentificationNumber: null,
      issuingRunningOrganizitions: null,
      drivingLicenseNo: null,
      drivingPermitType: null,
      issuingDrivingOrganizations: null,
      drivingLicenseValidTime: null,
      drivingLicenseExpirationTime: null,
      qualificationCertificateNo: null,
      licensePlateTypeCode: null,
      ownWeight: null,
      vehicleEnergyType: null,
      runningLicenseNo: null,
      transportBusinessLicenseNo: null,
      vehicleHolder: null,    // 车辆所有人
      semiVehicleHolder: null,  // 挂车所有人
      remark: null
    }
  },
  attachment: {
    identityCardFrontUrl: [],
    identityCardBackUrl: []
  },
  baseInfo: {
    username: null,
    identityCardNo: null,
    idCardValidTime: null,
    idCardExpirationTime: null,
    provinceCode: null,
    cityCode: null,
    districtCode: null,
    address: null,
    qq: null
  },
  runningRegisterDate: null,
  runningIssueDate: null,
  runningLicenseValidityData: null,
  semiDrivingLicenseValidityData: null,
  idCardTime: [null, null],
  drivingLicenseTime: [null, null],
  identityCardFrontUrl: null,
  identityCardBackUrl: null,
  drivingLicenseUrl: null,
  runningLicenseUrl: null,
  transportBusinessLicenseUrl: null,
  transportBusinessQualificationUrl: null,
  semiDrivingLicenseUrl: null,
};
const { MonthPicker } = DatePicker

@Form.create({
  // mapPropsToFields: (props = {}) => {
  //   const { initialValue } = props;
  //   return Form.createFormField(initialValue);
  // }
})
@connect(({ memberCenter, me }) => ({ memberCenter, me }))
class PersonEditor extends Component {
  state = {
    loadingCount: 0
  };

  componentDidMount() {
    if (!this.props.isEdit) {
      this.getIdentityList();
    }
    if (this.props.initialValue) {
      this.updateFormValue(this.props.initialValue);
    }
  }

  updateFormValue(values) {
    values && this.props.form.setFieldsValue(values)
  }

  componentDidUpdate(prevProps) {
    if (this.props.initialValue && !prevProps.initialValue) {
      this.updateFormValue(this.props.initialValue)
    }
  }

  getIdentityList = async () => {
    reaction.memberCenter.getIdentifyDictList().then(({ code, data }) => {
      if (code === 0) {
        const identityList = data
          .filter(item => item.certType === '2')
          .map(item => item.code);

        this.props.form.setFieldsValue({
          ...defaultValues,
          // identityList: identityList.length ? [identityList[0]] : []
        });
      }
    });
  };

  validateIsIdentityNoExist = ({ idCardNo }) => {
    return reaction.memberCenter.validateIsIdentityNoExist({
      idCardNo
    });
  };

  getDefalutFiles = key => {
    const value = this.props.form.getFieldValue(key);
    return value ? [value] : [];
  };

  getImageFileKey = file => {
    if (!file) return { fileKey: '' };
    return { fileKey: file.response.data.url };
  };

  parseFormValues = values => {
    let {
      runningRegisterDate,
      runningIssueDate,
      drivingLicenseTime,
      idCardTime,
      identityCardFrontUrl,
      identityCardBackUrl,
      drivingLicenseUrl,
      drivingLicenseSubPage,
      runningLicenseUrl,
      runningLicenseSubPage,
      runningLicenseValidityData,
      runningLicenseValidityUrl,
      semiDrivingLicenseValidityData,
      semiDrivingLicenseValidityUrl,
      semiDrivingLicenseUrl,
      semiDrivingLicenseSubPageUrl,
      transportBusinessLicenseUrl,
      transportBusinessQualificationUrl,
      ...params
    } = values;

    /* 日期转换为时间戳 */

    // 身份证起止日期
    if (idCardTime) {
      params.baseInfo.idCardValidTime = idCardTime[0]
        ? idCardTime[0].valueOf()
        : null;
      params.baseInfo.idCardExpirationTime = idCardTime[1]
        ? idCardTime[1].valueOf()
        : null;
    }

    params.attachment = {};
    // 获取图片fileKey
    params.attachment.identityCardFrontUrl = this.getImageFileKey(
      identityCardFrontUrl
    );
    params.attachment.identityCardBackUrl = this.getImageFileKey(
      identityCardBackUrl
    );

    if (!this.validHasDriverCert()) {
      return params;
    }

    // 驾驶证起止日期
    if (drivingLicenseTime) {
      params.additionalCert.vehicleInfo.drivingLicenseValidTime = drivingLicenseTime[0]
        ? drivingLicenseTime[0].valueOf()
        : null;
      params.additionalCert.vehicleInfo.drivingLicenseExpirationTime = drivingLicenseTime[1]
        ? drivingLicenseTime[1].valueOf()
        : null;
    }
    if (runningRegisterDate) {
      params.additionalCert.vehicleInfo.runningRegisterDate = runningRegisterDate.valueOf();
    }
    if (runningIssueDate) {
      params.additionalCert.vehicleInfo.runningIssueDate = runningIssueDate.valueOf();
    }
    if (runningLicenseValidityData) {
      params.additionalCert.vehicleInfo.runningLicenseValidityData = runningLicenseValidityData.format('YYYYMM');
    }
    if (semiDrivingLicenseValidityData) {
      params.additionalCert.vehicleInfo.semiDrivingLicenseValidityData = semiDrivingLicenseValidityData.format('YYYYMM');
    }
    params.additionalCert.vehicleInfo.drivingLicenseUrl = this.getImageFileKey(
      drivingLicenseUrl
    );
    params.additionalCert.vehicleInfo.drivingLicenseSubPage = this.getImageFileKey(
      drivingLicenseSubPage
    );
    params.additionalCert.vehicleInfo.runningLicenseUrl = this.getImageFileKey(
      runningLicenseUrl
    );
    params.additionalCert.vehicleInfo.runningLicenseSubPage = this.getImageFileKey(
      runningLicenseSubPage
    );
    params.additionalCert.vehicleInfo.transportBusinessLicenseUrl = this.getImageFileKey(
      transportBusinessLicenseUrl
    );
    params.additionalCert.vehicleInfo.transportBusinessQualificationUrl = this.getImageFileKey(
      transportBusinessQualificationUrl
    );
    params.additionalCert.vehicleInfo.runningLicenseValidityUrl = runningLicenseValidityUrl?.response?.data?.url ?? '';
    params.additionalCert.vehicleInfo.semiDrivingLicenseValidityUrl = semiDrivingLicenseValidityUrl?.response?.data?.url ?? '';
    params.additionalCert.vehicleInfo.semiDrivingLicenseUrl = semiDrivingLicenseUrl?.response?.data?.url ?? '';
    params.additionalCert.vehicleInfo.semiDrivingLicenseSubPageUrl = semiDrivingLicenseSubPageUrl?.response?.data?.url ?? '';

    return params;
  };

  validateForm = callback => {
    this.props.form.validateFields((err, values) => {
      let params = this.parseFormValues(values);
      if (!err) {
        callback && callback(params, true);
      } else {
        callback && callback(params, false);
      }
    });
  };

  /**
   * 
   * @param {String} name 字段中文名
   * @param {{ min: Number; max: Number; }} config 
   */
  handleNumberValidateRules = (name, config) => {
    return [
      {
        pattern: /^\d*$/,
        message: '只能输入整数',
      },
      {
        validator: (_, value, callback) => {
          if (value && (value < config.min || value > config.max)) {
            callback(`请输入${name}质量(${config.min}-${config.max})kg`);
          } else {
            callback();
          }
        }
      }
    ];
  }

  getPopupContainer = triggerNode => {
    // 为何为null？
    return document.getElementById('popupContainer');
  };
  validHasDriverCert() {
    const { form, isEdit, initialValue } = this.props;
    let identityList = [];
    if (isEdit && initialValue) {
      identityList = initialValue.identityList || [];
    } else {
      identityList = form.getFieldValue('identityList');
    }

    return checkHasDriverIdentity(identityList);
  }
  render() {
    const { form, me, memberCenter, operates = [], isEdit, showFundsPicker = false } = this.props;
    const { getFieldDecorator, setFieldsValue, getFieldValue } = form;

    const vehicleType = me.vehicleType || [];
    const drivingPermitTypeMap = me.drivingPermitTypeMap || [];
    const vehicleEnergyType = me.vehicleEnergyType || [];
    const licensePlateType = me.licensePlateType || [];

    const identityDictList = memberCenter.identityDictList || [];

    const { loadingCount } = this.state;

    const formItemLayout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 20 }
    };
    // let formItemSplitLayout = {
    //   labelCol: { span: 10 },
    //   wrapperCol: { span: 8 }
    // };
    const personCertDict = identityDictList.filter(
      item => item.certType === '2'
    );

    return (
      <div id="popupContainer" className={classnames('person-info')}>
        {!isEdit && (
          <div style={{ overflow: 'hidden' }}>
            <div style={{ float: 'left', width: 100 }}>开通身份</div>
            <div style={{ marginLeft: 100 }}>
              <Form>
                <div className="form-item-wrapper">
                  <FormItem {...formItemLayout} colon={false}>
                    {getFieldDecorator('identityList', {
                      initialValue: [],
                      rules: [
                        {
                          required: true,
                          message: '至少选择一种身份'
                        }
                      ]
                    })(
                      <CheckboxGroup>
                        {personCertDict.map((item, i) => {
                          return (
                            <Checkbox
                              disabled={i === 0 && true}
                              value={item.code} key={item.code}>
                              {item.name}
                            </Checkbox>
                          );
                        })}
                      </CheckboxGroup>
                    )}
                  </FormItem>
                </div>
              </Form>
            </div>
          </div>
        )}
        <div style={{ overflow: 'hidden' }}>
          <div style={{ float: 'left', width: 100 }}>司机信息</div>
          <div style={{ marginLeft: 100 }}>
            <Form>
              <div>
                <FormItem {...formItemLayout} label="身份证人像面" className="person-info-uploader">
                  {getFieldDecorator('identityCardFrontUrl', {
                    rules: [
                      {
                        required: true,
                        message: '请上传身份证人像面'
                      }
                    ]
                  })(
                    <FileUpload
                      className={classnames('driver-info')}
                      beforeUpload={() =>
                        this.setState({ loadingCount: loadingCount + 1 })
                      }
                      uploaded={result => {
                        setFieldsValue({ identityCardFrontUrl: result });
                        this.setState({ loadingCount: loadingCount - 1 });
                      }}
                      fileList={this.getDefalutFiles('identityCardFrontUrl')}
                    />
                  )}
                </FormItem>
              </div>
              <FormItem {...formItemLayout} label="姓名">
                {getFieldDecorator('baseInfo.username', {
                  validateTrigger: ['onBlur', 'onChange'],
                  rules: [
                    {
                      required: true,
                      pattern: /^[\u4e00-\u9fa5]{2,20}$/,
                      message: '请输入姓名'
                    }
                  ]
                })(<Input style={{ width: 350 }} placeholder="请输入姓名" />)}
              </FormItem>
              <FormItem {...formItemLayout} label="身份证号">
                {getFieldDecorator('baseInfo.identityCardNo', {
                  validateTrigger: ['onBlur', 'onChange'],
                  rules: [
                    {
                      required: true,
                      pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
                      validator: (rule, value, callback) => {
                        if (!value || !rule.pattern.test(value)) {
                          callback('请输入正确的身份证号');
                          return;
                        }
                        if (
                          !isEdit ||
                          value !==
                          this.props.initialValue.baseInfo.identityCardNo
                        ) {
                          this.validateIsIdentityNoExist({
                            idCardNo: value
                          }).then(res => {
                            if (res.data) {
                              callback(new Error('身份证已存在!'));
                            } else {
                              callback();
                            }
                          });
                        } else {
                          callback();
                        }
                      }
                    }
                  ]
                })(
                  <Input style={{ width: 350 }} placeholder="请输入身份证号" />
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="身份证国徽面" className="person-info-uploader">
                {getFieldDecorator('identityCardBackUrl', {
                  rules: [
                    {
                      required: true,
                      message: '请上传身份证国徽面'
                    }
                  ]
                })(
                  <FileUpload
                    className={classnames('driver-info')}
                    beforeUpload={() => {
                      this.setState({ loadingCount: loadingCount + 1 });
                    }}
                    uploaded={result => {
                      setFieldsValue({ identityCardBackUrl: result });
                      this.setState({ loadingCount: loadingCount - 1 });
                    }}
                    fileList={this.getDefalutFiles('identityCardBackUrl')}
                  />
                )}
              </FormItem>

              <FormItem {...formItemLayout} label="身份证有效期">
                {getFieldDecorator(
                  'idCardTime',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请选择身份证有效期',
                        validator: (rule, values, callback) => {
                          if (
                            rule.required &&
                            values &&
                            values.some(item => !item)
                          ) {
                            callback('请选择身份证有效期');
                          } else {
                            callback();
                          }
                        }
                      }
                    ]
                  }
                )(<DatePickAndCheckboxFormItem mode="multiple" />)}
              </FormItem>
              {this.validHasDriverCert() ? (
                <>
                  <FormItem {...formItemLayout} label="驾驶证主页照片">
                    {getFieldDecorator('drivingLicenseUrl', {
                      rules: [
                        // {
                        //   required: true,
                        //   message: '请上传驾驶证主页照片',
                        // },
                      ]
                    })(
                      <FileUpload
                        className={classnames('driver-info')}
                        beforeUpload={() =>
                          this.setState({ loadingCount: loadingCount + 1 })
                        }
                        uploaded={result => {
                          setFieldsValue({ drivingLicenseUrl: result });
                          this.setState({ loadingCount: loadingCount - 1 });
                        }}
                        fileList={this.getDefalutFiles('drivingLicenseUrl')}
                      />
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="准驾车型">
                    {getFieldDecorator(
                      'additionalCert.vehicleInfo.drivingPermitType'
                    )(
                      <Select
                        showSearch
                        placeholder="请选择"
                        style={{ width: 350 }}
                        getPopupContainer={this.getPopupContainer}
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {drivingPermitTypeMap.map(item => (
                          <Option key={item.code} value={item.code}>
                            {item.name}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="发证机关">
                    {getFieldDecorator(
                      'additionalCert.vehicleInfo.issuingDrivingOrganizations'
                    )(
                      <Input
                        style={{ width: 350 }}
                        placeholder="请输入发证机关"
                      />
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="驾驶证有效期">
                    {getFieldDecorator(
                      // drivingLicenseValidTime drivingLicenseExpirationTime
                      'drivingLicenseTime',
                      {
                        rules: [
                          {
                            // required: true,
                            message: '请选择驾驶证起始有效期',
                            validator: (rule, values, callback) => {
                              if (
                                rule.required &&
                                values &&
                                values.some(item => !item)
                              ) {
                                callback('请选择驾驶证起始有效期');
                              } else {
                                callback();
                              }
                            }
                          }
                        ]
                      }
                    )(<DatePickAndCheckboxFormItem mode="multiple" />)}
                  </FormItem>
                  <FormItem {...formItemLayout} label="驾驶证副页照片">
                    {getFieldDecorator('drivingLicenseSubPage')(
                      <FileUpload
                        className={classnames('driver-info')}
                        beforeUpload={() =>
                          this.setState({ loadingCount: loadingCount + 1 })
                        }
                        uploaded={result => {
                          setFieldsValue({ drivingLicenseSubPage: result });
                          this.setState({ loadingCount: loadingCount - 1 });
                        }}
                        fileList={this.getDefalutFiles('drivingLicenseSubPage')}
                      />
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="档案编号">
                    {getFieldDecorator(
                      'additionalCert.vehicleInfo.drivingLicenseNo'
                    )(
                      <Input
                        style={{ width: 350 }}
                        placeholder="请输入档案编号"
                      />
                    )}
                  </FormItem>
                </>
              ) : null}
            </Form>
          </div>
        </div>

        {this.validHasDriverCert() ? (
          <>
            <div style={{ overflow: 'hidden' }}>
              <div style={{ float: 'left', width: 100 }}>车辆信息</div>
              <div style={{ marginLeft: 100 }}>
                <Form>
                  <div style={{ marginBottom: -20 }}>
                    <FormItem {...formItemLayout} label="行驶证主页照片" className="person-info-uploader">
                      {getFieldDecorator('runningLicenseUrl')(
                        <FileUpload
                          className={classnames('driver-info')}
                          beforeUpload={() =>
                            this.setState({ loadingCount: loadingCount + 1 })
                          }
                          uploaded={result => {
                            setFieldsValue({ runningLicenseUrl: result });
                            this.setState({ loadingCount: loadingCount - 1 });
                          }}
                          fileList={this.getDefalutFiles('runningLicenseUrl')}
                        />
                      )}
                    </FormItem>
                  </div>

                  <FormItem {...formItemLayout} label="车牌号">
                    {getFieldDecorator(
                      'additionalCert.vehicleInfo.licensePlateNo',
                      {
                        rules: [
                          {
                            // required: true,
                            pattern: /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领][A-Z]{1,2}[A-Z0-9]{4}[A-Z0-9挂学警港澳]$/,
                            message: '请输入正确的车牌号'
                          }
                        ]
                      }
                    )(
                      <Input
                        style={{ width: 350 }}
                        placeholder="请输入车牌号"
                      />
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="所有人">
                    {getFieldDecorator('additionalCert.vehicleInfo.vehicleHolder')(
                      <Input
                        style={{ width: 350 }}
                        placeholder="请输入所有人"
                      />
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="车辆类型">
                    {getFieldDecorator('additionalCert.vehicleInfo.vehicleType')(
                      <Select
                        style={{ width: 350 }}
                        getPopupContainer={this.getPopupContainer}
                      >
                        {vehicleType.map(item => {
                          return (
                            <Option key={item.code} value={item.code}>
                              {item.name}
                            </Option>
                          );
                        })}
                      </Select>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="使用性质">
                    {getFieldDecorator('additionalCert.vehicleInfo.runningUseCharacter')(
                      <Input
                        style={{ width: 350 }}
                        placeholder="请输入使用性质"
                      />
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="车辆识别代号">
                    {getFieldDecorator('additionalCert.vehicleInfo.vehicleIdentificationNumber')(
                      <Input
                        style={{ width: 350 }}
                        placeholder="请输入车辆识别代号"
                      />
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="注册日期">
                    {getFieldDecorator('runningRegisterDate')(<DatePicker />)}
                  </FormItem>
                  <FormItem {...formItemLayout} label="发证日期">
                    {getFieldDecorator('runningIssueDate')(<DatePicker />)}
                  </FormItem>
                  <FormItem {...formItemLayout} label="牌照类型">
                    {getFieldDecorator(
                      'additionalCert.vehicleInfo.licensePlateTypeCode',
                      {
                        rules: [
                          {
                            // required: true,
                            message: '请选择牌照类型'
                          }
                        ]
                      }
                    )(
                      <Select
                        style={{ width: 350 }}
                        getPopupContainer={this.getPopupContainer}
                      >
                        {licensePlateType.map(item => {
                          return (
                            <Option key={item.code} value={item.code}>
                              {item.name}
                            </Option>
                          );
                        })}
                      </Select>
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="发证机关">
                    {getFieldDecorator('additionalCert.vehicleInfo.issuingRunningOrganizitions')(
                      <Input
                        style={{ width: 350 }}
                        placeholder="请输入发证机关"
                      />
                    )}
                  </FormItem>

                  <FormItem {...formItemLayout} label="行驶证副页照片" className="person-info-uploader">
                    {getFieldDecorator('runningLicenseSubPage')(
                      <FileUpload
                        className="driver-info"
                        beforeUpload={() =>
                          this.setState({ loadingCount: loadingCount + 1 })
                        }
                        uploaded={result => {
                          setFieldsValue({ runningLicenseSubPage: result });
                          this.setState({ loadingCount: loadingCount - 1 });
                        }}
                        fileList={this.getDefalutFiles('runningLicenseSubPage')}
                      />
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="档案编号">
                    {getFieldDecorator('additionalCert.vehicleInfo.runningLicenseNo')(
                      <Input
                        style={{ width: 350 }}
                        placeholder="请输入档案编号"
                      />
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="外廓尺寸">
                    {getFieldDecorator('additionalCert.vehicleInfo.vehicleSpecification')(
                      <Input
                        style={{ width: 350 }}
                        placeholder="请输入外廓尺寸"
                      />
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="整备质量">
                    {getFieldDecorator('additionalCert.vehicleInfo.ownWeight', {
                      validateTrigger: ['onBlur'],
                      validateFirst: true,
                      rules: this.handleNumberValidateRules('整备', {
                        min: 1,
                        max: 999999
                      }),
                    })(
                      <Input
                        style={{ width: 350 }}
                        maxLength={8}
                        placeholder="请输入整备质量"
                        addonAfter="kg"
                      />
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="核载质量">
                    {getFieldDecorator('additionalCert.vehicleInfo.loadCapacity', {
                      validateTrigger: ['onBlur'],
                      validateFirst: true,
                      rules: this.handleNumberValidateRules('核载', {
                        min: 1,
                        max: 999999
                      }),
                    }
                    )(
                      <Input
                        style={{ width: 350 }}
                        maxLength={8}
                        placeholder="请输入核载质量"
                        addonAfter="kg"
                      />
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="车辆能源类型">
                    {getFieldDecorator(
                      'additionalCert.vehicleInfo.vehicleEnergyType',
                      {
                        rules: [
                          {
                            // required: true,
                            message: '请选择车辆能源类型'
                          }
                        ]
                      }
                    )(
                      <Select
                        style={{ width: 350 }}
                        getPopupContainer={this.getPopupContainer}
                      >
                        {vehicleEnergyType.map(item => (
                          <Option key={item.code} value={item.code}>
                            {item.name}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </FormItem>

                  <FormItem {...formItemLayout} label="车辆检验有效期照片" className="person-info-uploader">
                    {getFieldDecorator('runningLicenseValidityUrl')(
                      <FileUpload
                        className="driver-info"
                        beforeUpload={() =>
                          this.setState({ loadingCount: loadingCount + 1 })
                        }
                        uploaded={result => {
                          setFieldsValue({ runningLicenseValidityUrl: result });
                          this.setState({ loadingCount: loadingCount - 1 });
                        }}
                        fileList={this.getDefalutFiles('runningLicenseValidityUrl')}
                      />
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="车辆检验有效期">
                    {getFieldDecorator('runningLicenseValidityData')(<MonthPicker />)}
                  </FormItem>

                </Form>
              </div>
            </div>

            <div style={{ overflow: 'hidden' }}>
              <div style={{ float: 'left', width: 100 }}>挂车信息</div>
              <div style={{ marginLeft: 100 }}>
                <Form>
                  <div style={{ marginBottom: -20 }}>
                    <FormItem {...formItemLayout} label="行驶证主页照片" className="person-info-uploader">
                      {getFieldDecorator('semiDrivingLicenseUrl')(
                        <FileUpload
                          className={classnames('driver-info')}
                          beforeUpload={() =>
                            this.setState({ loadingCount: loadingCount + 1 })
                          }
                          uploaded={result => {
                            console.log('result', result);
                            setFieldsValue({
                              semiDrivingLicenseUrl: result
                            });
                            this.setState({ loadingCount: loadingCount - 1 });
                          }}
                          fileList={this.getDefalutFiles('semiDrivingLicenseUrl')}
                        />
                      )}
                    </FormItem>
                  </div>
                  <FormItem {...formItemLayout} label="车牌号">
                    {getFieldDecorator(
                      'additionalCert.vehicleInfo.semiCarNo',
                      // {
                      //   rules: [
                      //     {
                      //       // required: true,
                      //       pattern: /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领][A-Z]{1,2}[A-Z0-9]{4}[A-Z0-9挂学警港澳]$/,
                      //       message: '请输入正确的车牌号'
                      //     }
                      //   ]
                      // }
                    )(
                      <Input
                        style={{ width: 350 }}
                        placeholder="请输入车牌号"
                      />
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="所有人">
                    {getFieldDecorator('additionalCert.vehicleInfo.semiVehicleHolder')(
                      <Input
                        style={{ width: 350 }}
                        placeholder="请输入所有人"
                      />
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="行驶证副页照片" className="person-info-uploader">
                    {getFieldDecorator('semiDrivingLicenseSubPageUrl')(
                      <FileUpload
                        className="driver-info"
                        beforeUpload={() =>
                          this.setState({ loadingCount: loadingCount + 1 })
                        }
                        uploaded={result => {
                          setFieldsValue({ semiDrivingLicenseSubPageUrl: result });
                          this.setState({ loadingCount: loadingCount - 1 });
                        }}
                        fileList={this.getDefalutFiles('semiDrivingLicenseSubPageUrl')}
                      />
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="车辆检验有效期照片" className="person-info-uploader">
                    {getFieldDecorator('semiDrivingLicenseValidityUrl')(
                      <FileUpload
                        className="driver-info"
                        beforeUpload={() =>
                          this.setState({ loadingCount: loadingCount + 1 })
                        }
                        uploaded={result => {
                          setFieldsValue({ semiDrivingLicenseValidityUrl: result });
                          this.setState({ loadingCount: loadingCount - 1 });
                        }}
                        fileList={this.getDefalutFiles('semiDrivingLicenseValidityUrl')}
                      />
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="车辆检验有效期">
                    {getFieldDecorator('semiDrivingLicenseValidityData')(<MonthPicker />)}
                  </FormItem>
                </Form>
              </div>
            </div>

            <div style={{ overflow: 'hidden' }}>
              <div style={{ float: 'left', width: 100 }}>证件信息</div>
              <div style={{ marginLeft: 100 }}>
                <Form>
                  <FormItem {...formItemLayout} label="道路运输经营许可证照片" className="person-info-uploader">
                    {getFieldDecorator('transportBusinessLicenseUrl', {
                      rules: [
                        {
                          required: false,
                          message: '请上传道路运输经营许可证照片'
                        }
                      ]
                    })(
                      <FileUpload
                        className="driver-info"
                        beforeUpload={() =>
                          this.setState({ loadingCount: loadingCount + 1 })
                        }
                        uploaded={result => {
                          setFieldsValue({
                            transportBusinessLicenseUrl: result
                          });
                          this.setState({ loadingCount: loadingCount - 1 });
                        }}
                        fileList={this.getDefalutFiles(
                          'transportBusinessLicenseUrl'
                        )}
                      />
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="道路运输经营许可证号">
                    {getFieldDecorator(
                      'additionalCert.vehicleInfo.transportBusinessLicenseNo',
                      {
                        rules: [
                          {
                            required: false,
                            message: '请输入道路运输经营许可证号'
                          }
                        ]
                      }
                    )(
                      <Input
                        style={{ width: 350 }}
                        placeholder="请输入道路运输经营许可证号"
                      />
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="道路运输从业资格证照片" className="person-info-uploader">
                    {getFieldDecorator('transportBusinessQualificationUrl', {
                      rules: [
                        {
                          required: false,
                          message: '请上传道路运输从业资格证照片'
                        }
                      ]
                    })(
                      <FileUpload
                        className="driver-info"
                        beforeUpload={() =>
                          this.setState({ loadingCount: loadingCount + 1 })
                        }
                        uploaded={result => {
                          setFieldsValue({
                            transportBusinessQualificationUrl: result
                          });
                          this.setState({ loadingCount: loadingCount - 1 });
                        }}
                        fileList={this.getDefalutFiles(
                          'transportBusinessQualificationUrl'
                        )}
                      />
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label="道路运输从业资格证号">
                    {getFieldDecorator(
                      'additionalCert.vehicleInfo.qualificationCertificateNo',
                      {
                        rules: [
                          {
                            required: false,
                            message: '请输入道路运输从业资格证号'
                          }
                        ]
                      }
                    )(
                      <React.Fragment>
                        <Input
                          style={{ width: 350 }}
                          placeholder="请输入道路运输从业资格证号"
                          onChange={e => {
                            setFieldsValue({
                              'additionalCert.vehicleInfo.qualificationCertificateNo':
                                e.target.value
                            });
                          }}
                          value={getFieldValue(
                            'additionalCert.vehicleInfo.qualificationCertificateNo'
                          )}
                        />
                        <div style={{ color: '#169BD5' }}>
                          (总质量4.5吨及以上普通货运车辆)
                        </div>
                      </React.Fragment>
                    )}
                  </FormItem>

                  <FormItem {...formItemLayout} label="备注">
                    {getFieldDecorator('additionalCert.vehicleInfo.remark', {
                      rules: [
                        {
                          required: false,
                          message: '请输入备注'
                        }
                      ]
                    })(
                      <Input style={{ width: 350 }} placeholder="请输入备注" />
                    )}
                  </FormItem>

                  {
                    this.validHasDriverCert() && showFundsPicker ? (
                      <FormItem {...formItemLayout} label="是否开通资金账户：">
                        {getFieldDecorator('addFundsAccount', {
                          initialValue: true,
                          rules: [
                            {
                              required: true,
                              message: '请选择是否开通资金账户',
                            },
                          ],
                        })(
                          <RadioGroup
                            name="addFundsAccount"
                            options={[
                              { label: '开通', value: true },
                              { label: '不开通', value: false },
                            ]}
                          ></RadioGroup>
                        )}
                      </FormItem>
                    ) : null
                  }
                </Form>
              </div>
            </div>
          </>
        ) : null}
        <div style={{ padding: '0 0 50px 150px' }}>
          {operates.map((item, i) => (
            <Button
              key={i}
              type={item.btnType}
              disabled={item.validate ? loadingCount > 0 : false}
              onClick={() => {
                item.validate ? this.validateForm(item.func) : item.func();
              }}
              style={{ marginRight: 10 }}
            >
              {item.text}
            </Button>
          ))}
        </div>
      </div>
    );
  }
}

export default PersonEditor;
