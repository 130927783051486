// import fetch from 'isomorphic-fetch';
// import qs from 'qs';
import reqwest from 'reqwest';
import cookie from './cookie';
var uamsdk = window.uamsdk;
var noop = function noop() { };
var http = { $urlGetter: noop, $tokenGetter: noop, $tokenIsValid: noop, $authHook: noop, $errorHook: noop, $loadingHook: noop, $callbacks: [], url: function url(urlGetter) { http.$urlGetter = urlGetter; return http; }, addCallback: function addCallback(callback) { http.$callbacks.push(callback); return http; }, token: function token(tokenGetter) { http.$tokenGetter = tokenGetter; return http; }, tokenIsValid: function tokenIsValid(_tokenIsValid) { http.$tokenIsValid = _tokenIsValid; return http; }, authHook: function authHook(_authHook) { http.$authHook = _authHook; return http; }, errorHook: function errorHook(_errorHook) { http.$errorHook = _errorHook; return http; }, loadingHook: function loadingHook(_loadingHook) { http.$loadingHook = _loadingHook; return http; }, request: function request(method, params) {
        var showLoading = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
        showLoading && http.$loadingHook(true);
        var url = http.$urlGetter(method);
        var o = { url: url, type: 'json', method: 'post', contentType: 'application/json', crossOrigin: true,
            data: JSON.stringify({ params: params,
                token: cookie.getCookie('uam_sso_server_token') || null }) };
        return reqwest(o).catch(function (error) {
            http.$errorHook(method, params, error); // 错误处理
        }) // .then((data, error)=>{
            //     if(!http.$tokenIsValid(data)){
            //         http.$authHook(data);
            //     }
            //     return data;
            // })
            .then(function (data) { var error = true; for (var i = 0; i < http.$callbacks.length; i++) {
            var callback = http.$callbacks[i];
            if (callback(data)) {
                error = false;
                break;
            }
        } return data; }).always(function () { showLoading && http.$loadingHook(false); });
    }, get: function get(method, params) {
        var showLoading = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
        showLoading && http.$loadingHook(true);
        var url = http.$urlGetter(method);
        var querys = [];
        if (params && params.toString() === '[object Object]') {
            for (var key in params) {
                querys.push(key + '=' + params[key]);
            }
        }
        var query = '?' + querys.join('&');
        url += query;
        var o = { url: url, type: 'json', method: 'get', contentType: 'application/json', crossOrigin: true };
        return reqwest(o).catch(function (error) {
            http.$errorHook(method, params, error); // 错误处理
        }) // .then((data, error)=>{
            //     if(!http.$tokenIsValid(data)){
            //         http.$authHook(data);
            //     }
            //     return data;
            // })
            .then(function (data) { var error = true; for (var i = 0; i < http.$callbacks.length; i++) {
            var callback = http.$callbacks[i];
            if (callback(data)) {
                error = false;
                break;
            }
        } return data; }).always(function () { showLoading && http.$loadingHook(false); });
    }, downloadFile: function downloadFile(method) { var url = method; if (method.indexOf('?') !== -1) {
        url = method + '&token=' + uamsdk.getToken();
    }
    else {
        url = method + '?token=' + uamsdk.getToken();
    } window.location = http.$urlGetter(url); }, getJSON: function getJSON(url) {
        var showLoading = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
        showLoading && http.$loadingHook(true);
        return fetch(url, {
            // @ts-ignore
            type: 'json', method: 'get', crossOrigin: true // credentials: 'same-origin',
        }) // @ts-ignore
            .always(function () { showLoading && http.$loadingHook(false); });
    }, /** 可带参数导出 */ downloadFile2: function downloadFile2(url, params) {
        var method = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'POST';
        var o = { credentials: 'same-origin', method: method, headers: { 'Content-Type': 'application/json;charset=UTF-8;', 'Cache-Control': 'no-cache', Pragma: 'no-cache' }, mode: 'cors', body: JSON.stringify({ params: params, token: uamsdk.getToken() }) }; //@ts-ignore
        return fetch(url, o).then(function (response) { if (response.status >= 200 && response.status < 300) {
            return response.blob().then(function (resp) { return Promise.resolve({ data: resp, code: 0 }); });
        }
        else {
            return Promise.resolve({ data: null, code: response.status, message: 'error' });
        } });
    } };
export default http;
