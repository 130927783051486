import http from 'utils/http';
import createUID from 'utils/createUID';

const LIST = createUID();

const initialState = {
  list: {
    pagerInfo: {
      page: 1,
      total: 0
    },
    result: []
  }
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case LIST:
      return Object.assign({}, state, { list: action.data });
    default:
      return state;
  }
}

Object.assign(reducer, {
  getAccountList({ page, length, username, userId, certSubject, phone }) {
    return dispatch => {
      return http
        .request('v1.smsAccountBlacklist.queryAccount', {
          pagerCondition: { page, length },
          queryCondition: {
            username, userId, certSubject, phone
          }
        })
        .then(data => {
          dispatch({ type: LIST, data: data.data });
          return data;
        });
    };
  },
  confirmAddNewAccountToBlackList (userId) {
    return dispatch => {
      return http
        .request('v1.smsAccountBlacklist.addAccountBlacklist', { userId }).then(data => {
          return data;
        });
    };
  },
  confirmMultipleAddToBlackList (userIdList) {
    return dispatch => {
      return http
        .request('v1.smsAccountBlacklist.batchAddAccountBlacklist', {
          userIdList
        }).then(data => {
          return data;
        });
    };
  }
});

export default reducer;
