import React from 'react';
import reaction from 'reaction/index';
import { connect } from 'react-redux';
import { Button } from 'antd';
import dayjs from 'dayjs';
import history from 'utils/history';
import { resolveUrlQuery } from 'utils';
import { RoutePath } from 'utils/constants';
import ReactJson from 'react-json-view';

@connect((smsManagement) => ({ smsManagement }))
class ViewHistorySmsDetail extends React.Component {
  state = {
    serialNo: '',
    phone: '',
    sendTime: '',
    content: '',
    templateCode: '',
    smsPlatform: '',
    bizNo: '',
    sendStatus: '',
    sendResponse: '{}',
  };

  getHistorySmsInfo = () => {
    let { id } = resolveUrlQuery(this.props.location.search);

    reaction.smsManagement.getHistorySmsInfo(id).then((res) => {
      if (res.code === 0) {
        this.setState({
          serialNo: res.data.serialNo,
          phone: res.data.phone,
          sendTime: dayjs(res.data.sendTime).format('YYYY-MM-DD HH:mm:ss'),
          content: res.data.content,
          templateCode: res.data.templateCode,
          smsPlatform: res.data.smsPlatform,
          bizNo: res.data.bizNo,
          sendStatus: res.data.sendStatus,
          sendResponse: res.data.sendResponse,
        });
      }
    });
  };

  back = () => {
    history.push({
      pathname: `/${RoutePath.OPERATION_CENTER}/${RoutePath.SMS_MANAGEMENT}/${RoutePath.SMS_HISTORY}`,
    });
  };

  componentDidMount() {
    this.getHistorySmsInfo();
  }
  render() {
    let {
      serialNo,
      phone,
      sendTime,
      content,
      templateCode,
      smsPlatform,
      bizNo,
      sendStatus,
      sendResponse,
    } = this.state;

    return (
      <div>
        <h4>
          <span>短信详情</span>
        </h4>
        <div>
          <div style={{ padding: 10 }}>
            <span>流水：</span>
            <span>{serialNo}</span>
          </div>
          <div style={{ padding: 10 }}>
            <span>手机号：</span>
            <span>{phone}</span>
          </div>
          <div style={{ padding: 10 }}>
            <span>发送时间：</span>
            <span>{sendTime}</span>
          </div>
          <div style={{ padding: 10 }}>
            <span>短信内容：</span>
            <span>{content}</span>
          </div>
          <div style={{ padding: 10 }}>
            <span>code：</span>
            <span>{templateCode}</span>
          </div>
          <div style={{ padding: 10 }}>
            <span>短信平台：</span>
            <span>{smsPlatform}</span>
          </div>
          <div style={{ padding: 10 }}>
            <span>业务号：</span>
            <span>{bizNo}</span>
          </div>
          <div style={{ padding: 10 }}>
            <span>是否成功：</span>
            <span>{sendStatus}</span>
          </div>
          <div style={{ padding: 10 }}>
            <span>服务商响应内容：</span>
            <ReactJson
              src={JSON.parse(sendResponse)}
              enableClipboard={false}
              displayObjectSize={false}
              displayDataTypes={false}
            />
          </div>
        </div>
        <div style={{ padding: 10 }}>
          <Button onClick={this.back}>返回</Button>
        </div>
      </div>
    );
  }
}

export default ViewHistorySmsDetail;
