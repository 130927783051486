import React from 'react';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { Input, Button, Table, Form } from 'antd';

import FormItem from 'components/FormItem';

import reaction from 'reaction';
import { RoutePath } from 'utils/constants';
import { historyPush } from 'utils/history';
import { renderListControls } from 'utils';

import commonStyles from 'style/common.module.scss';

/**
 * 规则配置
 */
@connect(({ ruleConfig, me }) => ({ ruleConfig, me }))
class RuleConfig extends React.Component {
  state = {
    query: Immutable.Map({
      configName: '',
    }),
  };

  onQueryChange = (name, value) => {
    let { query } = this.state;
    query = query.set(name, value);
    this.setState({ query }, () => {});
  };

  doSearch = () => {
    this.getRuleList();
  };

  getRuleList = () => {
    let { configName } = this.state.query.toJS();
    reaction.ruleConfig.getRuleList(configName);
  };

  goToAddRuleVar = () => {
    historyPush(`/${RoutePath.FUNDS}/${RoutePath.ADD_RULE_VAR}`);
  };

  goToModifyRuleName = (row) => {
    historyPush(`/${RoutePath.FUNDS}/${RoutePath.MODIFY_RULE_VAR}`, {
      id: row.id,
      ruleName: row.ruleName,
      ruleValue: row.ruleValue,
      valueType: row.valueType,
      lastOperator: row.lastOperator,
      ruleCode: row.ruleCode,
    });
  };

  componentDidMount() {
    this.getRuleList();
  }

  render() {
    let { ruleList } = this.props.ruleConfig;
    let { query } = this.state;
    let columns = [
      {
        title: 'ID',
        dataIndex: 'id',
      },
      {
        title: '规则名称',
        dataIndex: 'ruleName',
      },
      {
        title: '规则值',
        dataIndex: 'ruleValue',
      },
      {
        title: '类型',
        dataIndex: 'valueType',
        render: (text) => {
          if (parseInt(text) === 1) {
            return '数字';
          }
          return '不限';
        },
      },
      {
        title: '最后操作人',
        dataIndex: 'lastOperator',
      },
      {
        title: '操作',
        render: (text) => {
          return (
            <Button
              style={{ margin: '0 0 0 5px' }}
              type="primary"
              onClick={() => {
                this.goToModifyRuleName(text);
              }}
            >
              修改
            </Button>
          );
        },
      },
    ];
    return (
      <div>
        <Form layout="inline">
          {
            renderListControls([
              <FormItem label="规则名">
                <Input
                  value={query.toJS().configName}
                  onChange={(e) => {
                    this.onQueryChange('configName', e.target.value);
                  }}
                  placeholder="请输入规则名"
                />
              </FormItem>,
              <FormItem push={20}>
                <Button
                  type="primary"
                  onClick={this.doSearch}
                  icon="search"
                >
                  查询
                </Button>
                <Button
                  style={{ margin: '0 0 0 5px' }}
                  type="primary"
                  onClick={this.goToAddRuleVar}
                  icon="plus"
                >
                  新增规则变量
                </Button>
              </FormItem>
            ])
          }
        </Form>
        <div style={{ padding: '20px 0 0 0' }}>
          {ruleList && (
            <Table
              className={commonStyles.row}
              rowKey="uid"
              columns={columns}
              dataSource={ruleList}
              onChange={this.handleTableChange}
            />
          )}
        </div>
      </div>
    );
  }
}

export default RuleConfig;