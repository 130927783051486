import React from 'react';
import dayjs from 'dayjs';
import { resolveUrlQuery } from 'utils';

/**
 * 前台日志详情
 */
class FrontendDetail extends React.Component {
  render() {
    let {
      uid,
      eventTimestamp,
      eventType,
      consumerIp,
      remark,
      providerApplicationName,
      providerIp,
      consumerApplicationName,
      requestParam,
      responseBody,
    } = resolveUrlQuery(this.props.location.search);
    return (
      <div>
        <h4>日志详情: </h4>
        <div style={{ padding: '10px 0 0 10px' }}>
          <span>用户id: </span>
          <span>{uid}</span>
        </div>
        <div style={{ padding: '10px 0 0 10px' }}>
          <span>事件时间: </span>
          <span>{dayjs(+eventTimestamp).format('YYYY-MM-DD HH:mm:ss')}</span>
        </div>
        <div style={{ padding: '10px 0 0 10px' }}>
          <span>事件类型: </span>
          <span>{eventType}</span>
        </div>
        <div style={{ padding: '10px 0 0 10px' }}>
          <span>消费者ip: </span>
          <span>{consumerIp}</span>
        </div>
        <div style={{ padding: '10px 0 0 10px' }}>
          <span>备注: </span>
          <span>{remark}</span>
        </div>
        <div style={{ padding: '10px 0 0 10px' }}>
          <span>提供者应用名: </span>
          <span>{providerApplicationName}</span>
        </div>
        <div style={{ padding: '10px 0 0 10px' }}>
          <span>提供者ip: </span>
          <span>{providerIp}</span>
        </div>
        <div style={{ padding: '10px 0 0 10px' }}>
          <span>消费者者应用名: </span>
          <span>{consumerApplicationName}</span>
        </div>
        <div style={{ padding: '10px 0 0 10px' }}>
          <span>请求参数: </span>
          <span>{requestParam}</span>
        </div>
        <div style={{ padding: '10px 0 0 10px' }}>
          <span>响应参数: </span>
          <span>{responseBody}</span>
        </div>
      </div>
    );
  }
}
export default FrontendDetail;
