import createUID from 'utils/createUID';
import http from 'utils/http';
import { HttpCode } from 'utils/constants';

const GET_LIST = createUID();
const GET_DETAIL = createUID();
const DELETE = createUID();
const GET_FUNDS_ACCOUNT_LIST = createUID(); // 获取资金账户列表
const GET_NEW_FUNDS_ACCOUNT_LIST = createUID(); // 获取新增资金账户列表
const CLEAR_NEW_FUNDS_ACCOUNT_LIST = createUID(); // 清空新增资金账户列表
const GET_TOTAL_ACCOUNT_INFO = createUID(); // 获取所有资金账户信息
const EXPORT_ALL_FUNDS_ACCOUNT = createUID(); // 导出所有资金账户
const GET_FUNDS_FLOW = createUID(); // 获取资金流水
const GET_FUNDS_ACCOUNT_DETAIL = createUID(); // 获取资金账户详情
const GET_EXPENDITURE_CARD_NUMBER = createUID(); // 获取出金卡数据量
const GET_EXPENDITURE_RECORDS = createUID(); // 获取出金记录
const GET_EXPENDITURE_CARDS_LIST = createUID(); // 获取出卡列表
const GET_TOTAL_EXPENSE = createUID(); // 总出金数
const GET_INCOME_RECORDS = createUID(); // 获取入金记录
const GET_TOTAL_INCOME = createUID(); // 总入金数
const GET_FREEZE_AND_UNFREEZE_LIST = createUID(); // 获取冻结解冻记录

const initialState = {
  list: {
    pagerInfo: { current: 1, total: 0 },
    result: []
  },
  fundsAccountList: {
    // info: {current: 1, total: 0},
    pagerInfo: { current: 1, total: 0 },
    result: []
  },
  newFundsAccountList: {
    pagerInfo: { current: 1, total: 0 },
    result: []
  },
  totalAccountInfo: {
    totalAccount: '',
    totalBalance: '',
    totalAvailableBalance: '',
    totalFreezeMoney: ''
  },
  fundsAccountDetail: {
    accountName: '', // 账户名
    userNo: '', // 用户编号
    createTime: '', // 开户时间
    balance: '', // 余额
    availableBalance: '', // 可用余额
    freezeMoney: '', // 冻结
    debit: '', // 总支出
    credit: '' // 总收入
  },
  fundsFlow: {
    // info: {current: 1, total: 0},
    pagerInfo: { current: 1, total: 0 },
    result: []
  },
  expenditureCard: {
    withdrawAccountNum: '',
    accountName: '',
    userNo: ''
  },
  expenditureCardList: [],
  expenditureRecords: {
    // info: {current: 1, total: 0},
    pagerInfo: { current: 1, total: 0 },
    result: []
  },
  totalExpense: {
    total: ''
  },
  incomeRecords: {
    // info: {current: 1, total: 0},
    pagerInfo: { current: 1, total: 0 },
    result: []
  },
  totalIncome: {
    total: ''
  },
  freezeAndUnfreeze: {
    pagerInfo: { current: 1, total: 0 },
    // info: {current: 1, total: 0},
    result: []
  }
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_LIST:
      return Object.assign({}, state, { list: action.data });
    case GET_FUNDS_ACCOUNT_LIST:
      return Object.assign({}, state, { fundsAccountList: action.data });
    case GET_NEW_FUNDS_ACCOUNT_LIST:
      return Object.assign({}, state, { newFundsAccountList: action.data });
    case CLEAR_NEW_FUNDS_ACCOUNT_LIST:
      return Object.assign({}, state, { newFundsAccountList: action.data });
    case GET_TOTAL_ACCOUNT_INFO:
      return Object.assign({}, state, { totalAccountInfo: action.data });
    case GET_FUNDS_FLOW:
      return Object.assign({}, state, { fundsFlow: action.data });
    case GET_FUNDS_ACCOUNT_DETAIL:
      return Object.assign({}, state, { fundsAccountDetail: action.data });
    case GET_EXPENDITURE_CARD_NUMBER:
      return Object.assign({}, state, { expenditureCard: action.data });
    case GET_EXPENDITURE_RECORDS:
      return Object.assign({}, state, { expenditureRecords: action.data });
    case GET_EXPENDITURE_CARDS_LIST:
      return Object.assign({}, state, { expenditureCardList: action.data });
    case GET_TOTAL_EXPENSE:
      return Object.assign({}, state, { totalExpense: action.data });
    case GET_INCOME_RECORDS:
      return Object.assign({}, state, { incomeRecords: action.data });
    case GET_TOTAL_INCOME:
      return Object.assign({}, state, { totalIncome: action.data });
    case GET_FREEZE_AND_UNFREEZE_LIST:
      return Object.assign({}, state, { freezeAndUnfreeze: action.data });
    case GET_DETAIL:
      return Object.assign({}, state, { detail: action.data });
    case DELETE: {
      let result = state.list.result.filter((data) => data.uid !== action.data);
      return Object.assign({}, state, { list: { ...state.list, result } });
    }
    default:
      return state;
  }
}

Object.assign(reducer, {
  getAccountList({ userLoginName = '', accountName = 1, accountNo = 10, settleBank, minTotalMoney, maxTotalMoney, page = 1, length = 10 }) {
    return (dispatch) => {
      return http.request('v1.funds.listReceiptAccount', {
        pagerCondition: { page, length },
        queryCondition: { userLoginName, accountNo, accountName, settleBank, minTotalMoney, maxTotalMoney }
      }).then((data) => {
        if (data.code === 0) dispatch({ type: GET_LIST, data: data.data });
        return data;
      });
    };
  },

  getFundsAccountList({ phoneNo = '', accountName = 1, accountNo = 10, bankName = '', minAvailableBalance = '', maxAvailableBalance = '', minFreezeMoney, maxFreezeMoney, sortBy, asc, page = 1, length = 10, createTimeStart = '', createTimeEnd = '' }) {
    return (dispatch) => {
      return http.request('v1.funds.list', {
        queryCondition: { phoneNo, accountName, bankName, accountNo, minAvailableBalance, maxAvailableBalance, minFreezeMoney, maxFreezeMoney, sortBy, asc, createTimeStart, createTimeEnd },
        pagerCondition: { page, length }
      }).then((data) => {
        if (data.code === 0) dispatch({ type: GET_FUNDS_ACCOUNT_LIST, data: data.data });
        return data;
      });
    };
  },
  /**
   * 更新账户余额
   * @param {*}
   */
  updateAccountBalance({ accountNo, userId }) {
    return http.request('v1.funds.updateBalanceByAccountNo', { accountNo, userId });
  },
  /**
   * 更新出金状态
   * @param {*}
   */
  updateAccountOutExpenditureStatus({ accountNo }) {
    return http.request('v3.account.getUpToDateBalance', {
      accountNo,
      userId
    });
  },

  getTotalAccountInfo() {
    return (dispatch) => {
      return http.request('v1.funds.sumAllAccount', {}).then((data) => {
        if (data.code === 0) dispatch({ type: GET_TOTAL_ACCOUNT_INFO, data: data.data });
        return data;
      });
    };
  },

  getFundsFlowList({ accountNo = '', bizType = '', serialNo = '', startTime = '', endTime = '', oppAccountName = '', page = 1, length = 10 }) {
    return (dispatch) => {
      return http.request('v1.funds.flow', {
        queryCondition: { accountNo, bizType, serialNo, startTime, endTime, oppAccountName },
        pagerCondition: { page, length }
      }).then((data) => {
        if (data.code === 0) dispatch({ type: GET_FUNDS_FLOW, data: data.data });
        return data;
      });
    };
  },

  getFundsAccountDetail({ accountNo = '' }) {
    return (dispatch) => {
      return http.request('v1.funds.accountDetail', {
        accountNo: accountNo
      }).then((data) => {
        if (data.code === 0) dispatch({ type: GET_FUNDS_ACCOUNT_DETAIL, data: data.data });
        return data;
      });
    };
  },
  exportAll() {
    return (dispatch) => {
      // return http.request('v2.funds.downloadAccounts', {}, true, 'get').then((data) => {
      //
      // });
      return http.downloadFile('v1.funds.downloadAccounts');
    };
  },
  exportAllDetail(accountNo) {
    return (dispatch) => {
      // return http.request('v2.funds.downloadFlow', {}, true, 'get').then((data) => {
      //
      // });
      return http.downloadFile('v1.funds.downloadFlow?accountNo=' + accountNo);
    };
  },
  getExpenditureCardList({ accountNo }) {
    return (dispatch) => {
      return http.request('v1.funds.listWithdrawAccount', {
        accountNo: accountNo
      }).then((data) => {
        if (data.code === 0) dispatch({ type: GET_EXPENDITURE_CARDS_LIST, data: data.data });
        return data;
      });
    };
  },

  getExpenditureCardNumber({ accountNo }) {
    return (dispatch) => {
      return http.request('v1.funds.countWithdrawAccount', {
        accountNo: accountNo
      }).then((data) => {
        if (data.code === 0) dispatch({ type: GET_EXPENDITURE_CARD_NUMBER, data: data.data });
        return data;
      });
    };
  },

  getNewFundsAccountList({ name = '', page = 1, length = 10 }) {
    return (dispatch) => {
      return http.request('v1.funds.listAuthUser', {
        queryCondition: { name },
        pagerCondition: { page, length }
      }).then((data) => {
        if (data.code === 0) dispatch({ type: GET_NEW_FUNDS_ACCOUNT_LIST, data: data.data });
        return data;
      });
    };
  },
  clearNewFundsAccountList() {
    return (dispatch) => {
      dispatch({
        type: CLEAR_NEW_FUNDS_ACCOUNT_LIST, data: {
          pagerInfo: { current: 1, total: 0 },
          result: []
        }
      });
    };
  },

  openFundsAccount({ companyName, username, accountNo, userId }) {
    return (dispatch) => {
      return http.request('v1.funds.addAccount', { companyName, username, accountNo, userId });
    };
  },

  getExpenditureRecordsList({ accountName, accountNo, minMoney, maxMoney, bankName, minCreateTime, maxCreateTime, platformCode, page, length }) {
    return (dispatch) => {
      return http.request('v1.funds.withdrawFlow', {
        pagerCondition: {
          page,
          length
        },
        queryCondition: {
          accountName, accountNo, minMoney, maxMoney, bankName, minCreateTime, maxCreateTime, platformCode
        }
      }).then((data) => {
        if (data.code === 0) dispatch({ type: GET_EXPENDITURE_RECORDS, data: data.data });
        return data;
      });
    };
  },

  getIncomeRecordsList({ page, length, ...queryCondition }) {
    return (dispatch) => {
      return http.request('v1.funds.topUpFlow', {
        pagerCondition: {
          page,
          length
        },
        queryCondition
      }).then((data) => {
        if (data.code === 0) dispatch({ type: GET_INCOME_RECORDS, data: data.data });
        return data;
      });
    };
  },

  getTotalIncome({ fundsType }) {
    return (dispatch) => {
      return http.request('v1.funds.sumFlow', { fundsType }).then((data) => {
        if (data.code === 0) dispatch({ type: GET_TOTAL_INCOME, data: data.data });
        return data;
      });
    };
  },

  getTotalExpense({ fundsType }) {
    return (dispatch) => {
      return http.request('v1.funds.sumFlow', { fundsType }).then((data) => {
        if (data.code === 0) dispatch({ type: GET_TOTAL_EXPENSE, data: data.data });
        return data;
      });
    };
  },

  getFreezeAndUnfreezeList({ bizType, serialNo, startTime, endTime, platformCode, bizNo, page, length }) {
    return (dispatch) => {
      return http.request('v1.funds.freezeFlow', {
        pagerCondition: {
          page,
          length
        },
        queryCondition: {
          bizType, serialNo, startTime, endTime, platformCode, bizNo
        }
      }).then((data) => {
        if (data.code === 0) dispatch({ type: GET_FREEZE_AND_UNFREEZE_LIST, data: data.data });
        return data;
      });
    };
  }
});
export default reducer;
