import React from 'react';
import { connect } from 'react-redux';
import { RoutePath, AuthCodes } from 'utils/constants';
import { historyPush } from 'utils/history';

/**
 * 系统管理
 */
@connect(({ common, me }) => ({ common, me }))
class ReceiptOfCITICBank extends React.Component {
  componentDidMount() {
    let { roleAuthList } = this.props.me.roleInfo;
    let accountListEnabled = roleAuthList.some((item) => {
      return item.authCode.indexOf(AuthCodes.FUNDS_ACCOUNT_LIST) !== -1;
    });
    let receiptListEnabled = roleAuthList.some((item) => {
      return item.authCode.indexOf(AuthCodes.FUNDS_RECEIPT_LIST) !== -1;
    });
    let backReceiptEnabled = roleAuthList.some((item) => {
      return item.authCode.indexOf(AuthCodes.FUNDS_BANK_RECEIPT) !== -1;
    });

    if (accountListEnabled) {
      historyPush(
        `/${RoutePath.FUNDS}/${RoutePath.RECEIPT_LIST_PRINT}/${RoutePath.ACCOUNT_LIST}`
      );
    } else if (receiptListEnabled) {
      historyPush(
        `/${RoutePath.FUNDS}/${RoutePath.RECEIPT_LIST_PRINT}/${RoutePath.RECEIPT_LIST}`
      );
    } else if (backReceiptEnabled) {
      historyPush(
        `/${RoutePath.FUNDS}/${RoutePath.RECEIPT_LIST_PRINT}/${RoutePath.BANK_RECEIPT}`
      );
    } else {
      historyPush(RoutePath.INDEX);
    }
  }
  render() {
    return null;
  }
}
export default ReceiptOfCITICBank;
