import createUID from 'utils/createUID';
import http from 'utils/http';
import { HttpCode } from 'utils/constants';
import dayjs from 'dayjs';

const GET_IDENTIFY_INFO = createUID();
const GET_COMPANY_IDENTIFY_INFO = createUID();
const GET_PERSONAL_IDENTIFY_INFO = createUID();
const GET_CHECK_COMPANY_IDENTIFY_HISTORY_INFO = createUID();
const GET_CHECK_PERSONAL_IDENTIFY_HISTORY_INFO = createUID();
const GET_PERSONAL_QUALIFICATION_INFO = createUID();
const GET_PERSONAL_QUALIFICATION_OPERRATION_HISTORY = createUID();
const GET_PERSONAL_AUDIT_HISTORY_DETAIL = createUID();

const initialState = {
  identifyInfo: {
    user: {
      userNo: '',
      username: '',
      phone: ''
    },
    certInfo: {
      certType: '',
      certSubject: ''
    },
    existIdentity: [],
    identityDict: []
  },
  companyIdentityInfo: {
    user: {
      userNo: '',
      username: '',
      phone: ''
    },
    additionalCert: {
      hasForeignTradeCert: '',
      foreignTradeRegistrationUrl: '',
      foreignTradeEnterpriseCode: ''
    },
    existIdentity: [],
    applyIdentity: {
      name: '',
      code: ''
    },
    identityCertNo: ''
  },
  personalIdentityInfo: {
    user: {
      userNo: '',
      username: '',
      phone: ''
    },
    additionalCert: {
      vehicleInfo: {
        licensePlateNo: '',
        vehicleType: '',
        vehicleSpecification: '',
        loadCapacity: '',
        runningLicenseUrl: '',
        drivingLicenseUrl: ''
      }
    },
    existIdentity: [],
    applyIdentity: '',
    identityCertNo: ''
  },
  checkCompanyIdentityHistoryInfo: {
    user: {
      userNo: '',
      username: '',
      phone: ''
    },
    additionalCert: {
      hasForeignTradeCert: '',
      foreignTradeRegistrationUrl: '',
      foreignTradeEnterpriseCode: ''
    },
    existIdentity: [],
    applyIdentity: '',
    identityCertNo: '',
    auditResult: {
      auditOpinion: '',
      auditResult: '',
      auditTime: '',
      auditor: ''
    }
  },
  checkPersonalIdentityHistoryInfo: {
    user: {
      userNo: '',
      username: '',
      phone: ''
    },
    additionalCert: {
      vehicleInfo: {
        licensePlateNo: '',
        vehicleType: '',
        vehicleSpecification: '',
        loadCapacity: '',
        runningLicenseUrl: '',
        drivingLicenseUrl: ''
      }
    },
    existIdentity: [],
    applyIdentity: '',
    identityCertNo: '',
    auditResult: {
      auditOpinion: '',
      auditResult: '',
      auditTime: '',
      auditor: ''
    }
  },
  personalQualificationDetailInfo: {
    user: {
      userNo: '',
      username: '',
      phone: ''
    },
    baseInfo: {
      username: '',
      identityCardNo: '',
      provinceCode: '',
      address: '',
      qq: '',
      cityCode: '',
      districtCode: ''
    },
    attachment: {
      identityCardFrontUrl: {
        fileKey: '',
        url: ''
      },
      identityCardBackUrl: {
        fileKey: '',
        url: ''
      }
    },
    additionalCert: {
      vehicleInfo: {
        licensePlateNo: null,
        vehicleType: null,
        vehicleSpecification: null,
        loadCapacity: null,
        runningLicenseUrl: { url: null, fileKey: null },
        runningLicenseExpirationTime: null,
        drivingLicenseUrl: { url: null, fileKey: null },
        transportBusinessQualificationUrl: { url: null, fileKey: null },
        transportBusinessLicenseUrl: { url: null, fileKey: null },
        runningUseCharacter: null,
        vehicleIdentificationNumber: null,
        issuingRunningOrganizitions: null,
        drivingLicenseNo: null,
        drivingPermitType: null,
        issuingDrivingOrganizations: null,
        drivingLicenseValidTime: null,
        drivingLicenseExpirationTime: null,
        qualificationCertificateNo: null,
        licensePlateTypeCode: null,
        ownWeight: null,
        vehicleEnergyType: null,
        runningLicenseNo: null,
        transportBusinessLicenseNo: null,
        vehicleHolder: null,
        remark: null
      }
    },
    department: ''
  },
  personalAuditHistoryDetail: {
    userNo: '',
    username: '',
    phone: '',
    name: '',
    salesman: '',
    idCardNo: '',
    idCardExpirationTime: '',
    address: '',
    qq: '',
    location: '',
    idCardFront: '',
    idCardBack: '',
    vehiclePlateNo: '',
    vehicleType: '',
    licensePlateNo: '',
    vehicleSpecification: '',
    loadCapacity: '',
    runningLicense: '',
    drivingLicense: '',
    runningLicenseExpirationTime: '',
    drivingLicenseExpirationTime: '',
    department: '',
    auditResult: '',
    auditOpinion: ''
  },
  personalQualificationOperationHistory: {
    pagerInfo: {
      page: 1,
      total: 0
    },
    result: []
  }
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_IDENTIFY_INFO:
      return Object.assign({}, state, { identifyInfo: action.data });
    case GET_COMPANY_IDENTIFY_INFO:
      return Object.assign({}, state, { companyIdentityInfo: action.data });
    case GET_PERSONAL_IDENTIFY_INFO:
      return Object.assign({}, state, { personalIdentityInfo: action.data });
    case GET_CHECK_COMPANY_IDENTIFY_HISTORY_INFO:
      return Object.assign({}, state, {
        checkCompanyIdentityHistoryInfo: action.data
      });
    case GET_CHECK_PERSONAL_IDENTIFY_HISTORY_INFO:
      return Object.assign({}, state, {
        checkPersonalIdentityHistoryInfo: action.data
      });
    case GET_PERSONAL_QUALIFICATION_INFO:
      return Object.assign({}, state, {
        personalQualificationDetailInfo: action.data
      });
    case GET_PERSONAL_QUALIFICATION_OPERRATION_HISTORY:
      return Object.assign({}, state, {
        personalQualificationOperationHistory: action.data
      });
    case GET_PERSONAL_AUDIT_HISTORY_DETAIL:
      return Object.assign({}, state, {
        personalAuditHistoryDetail: action.data
      });
    default:
      return state;
  }
}

Object.assign(reducer, {
  modifyCompanyQualification(params) {
    return dispatch => {
      return http
        .request('v1.cert.updateEnterepriseCert', params)
        .then(data => {
          return data;
        });
    };
  },
  complementCompanyQualification(params) {
    return dispatch => {
      return http.request('v1.cert.completeCert', params).then(data => {
        return data;
      });
    };
  },
  modifyPersonalQualification(params) {
    return dispatch => {
      return http.request('v1.cert.updateIndividualCert', params);
    };
  },
  supplementCompanyQualification(params) {
    return dispatch => {
      return http.request('v1.cert.completeCert', params);
    };
  },
  makeQualificationOk(params) {
    return dispatch => {
      return http.request('v1.cert.audit', params).then(data => {
        return data;
      });
    };
  },
  refuseQualification(params) {
    return dispatch => {
      return http.request('v1.cert.reject', params).then(data => {
        return data;
      });
    };
  },
  makePersonalQualificationOk({ userId, department, salesman, team, addFundsAccount }) {
    return dispatch => {
      return http
        .request('v1.cert.auditIndividual', { userId, department, salesman, team, addFundsAccount })
        .then(data => {
          return data;
        });
    };
  },
  makePersonalQualificationOkOfComplement(params) {
    return dispatch => {
      return http.request('v1.cert.auditIndividual', params).then(data => {
        return data;
      });
    };
  },
  refusePersonalQualification({ userId, reason }) {
    return dispatch => {
      return http.request('v1.cert.reject', { userId, reason }).then(data => {
        return data;
      });
    };
  },
  toComplementQualification(params) {
    return dispatch => {
      return http.request('v1.cert.toCompleteCert', params).then(data => {
        return data;
      });
    };
  },
  toComplementPersonalQualification({ userId }) {
    return dispatch => {
      return http
        .request('v1.cert.completeIndividualCert', { userId })
        .then(data => {
          return data;
        });
    };
  },
  supplementPersonalQualification(params) {
    return dispatch => {
      return http
        .request('v1.cert.completeAuditIndividualCert', params)
        .then(data => {
          return data;
        });
    };
  },
  getIdentityInfo({ userId }) {
    return dispatch => {
      return http.request('v1.cert.getIdentity', { userId }).then(data => {
        if (data.code === HttpCode.OK) {
          dispatch({ type: GET_IDENTIFY_INFO, data: data.data });
        }
        return data;
      });
    };
  },
  getCompanyIdentityInfo({ userId, auditNo }) {
    return dispatch => {
      return http
        .request('v1.cert.getToAuditEnterpriseIdentity', { userId, auditNo })
        .then(data => {
          if (data.code === HttpCode.OK) {
            dispatch({ type: GET_COMPANY_IDENTIFY_INFO, data: data.data });
          }
          return data;
        });
    };
  },
  getPersonalIdentityInfo({ userId, auditNo }) {
    return dispatch => {
      return http
        .request('v1.cert.getToAuditIndividualIdentity', { userId, auditNo })
        .then(data => {
          if (data.code === HttpCode.OK) {
            dispatch({ type: GET_PERSONAL_IDENTIFY_INFO, data: data.data });
          }
          return data;
        });
    };
  },
  getPersonalQualificationInfo({ userId }) {
    return dispatch => {
      return http
        .request('v1.cert.getIndividualCert', { userId })
        .then(data => {
          if (data.code === HttpCode.OK) {
            dispatch({
              type: GET_PERSONAL_QUALIFICATION_INFO,
              data: data.data
            });
          }
          return data;
        });
    };
  },
  getPersonalAuditHistoryInfo({ userId, certId }) {
    return dispatch => {
      return http
        .request('v1.cert.individualCertHistory', { userId, certId })
        .then(data => {
          if (data.code === HttpCode.OK) {
            dispatch({
              type: GET_PERSONAL_AUDIT_HISTORY_DETAIL,
              data: data.data
            });
          }
          return data;
        });
    };
  },
  getCheckCompanyIdentityHistoryInfo({ userId, auditNo }) {
    return dispatch => {
      return http
        .request('v1.cert.getEnterpriseIdentity', { userId, auditNo })
        .then(data => {
          if (data.code === HttpCode.OK) {
            dispatch({
              type: GET_CHECK_COMPANY_IDENTIFY_HISTORY_INFO,
              data: data.data
            });
          }
          return data;
        });
    };
  },
  getCheckPersonalIdentityHistoryInfo(userId, auditNo) {
    return dispatch => {
      return http
        .request('v1.cert.getIndividualIdentity', { userId, auditNo })
        .then(data => {
          if (data.code === HttpCode.OK) {
            dispatch({
              type: GET_CHECK_PERSONAL_IDENTIFY_HISTORY_INFO,
              data: data.data
            });
          }
          return data;
        });
    };
  },
  getPersonalQualificationOperationHistory({
    userId,
    operationType,
    page,
    length
  }) {
    return dispatch => {
      return http
        .request('v1.common.listOperationHistory', {
          queryCondition: {
            userId,
            operationType
          },
          pagerCondition: {
            page,
            length
          }
        })
        .then(data => {
          if (data.code === HttpCode.OK) {
            dispatch({
              type: GET_PERSONAL_QUALIFICATION_OPERRATION_HISTORY,
              data: data.data
            });
          }
          return data;
        });
    };
  },
  passCompanyIdentity(params) {
    return dispatch => {
      return http
        .request('v1.cert.auditEnterpriseIdentity', params)
        .then(data => {
          return data;
        });
    };
  },
  refuseCompanyIdentity(params) {
    return dispatch => {
      return http
        .request('v1.cert.auditEnterpriseIdentity', params)
        .then(data => {
          return data;
        });
    };
  },
  passPersonalIdentity(params) {
    return dispatch => {
      return http
        .request('v1.cert.auditIndividualIdentity', params)
        .then(data => {
          return data;
        });
    };
  },
  refusePersonalIdentity(params) {
    return dispatch => {
      return http
        .request('v1.cert.auditIndividualIdentity', params)
        .then(data => {
          return data;
        });
    };
  }
});

export default reducer;
