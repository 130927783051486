import http from 'utils/http';
import createUID from 'utils/createUID';

const LIST = createUID();
const ENTERPRISE_LIST = createUID();

const initialState = {
  list: {
    pagerInfo: {
      page: 1,
      total: 0
    },
    result: []
  },
  enterpriseList: []
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case LIST:
      return Object.assign({}, state, {list: action.data});
    case ENTERPRISE_LIST:
      return Object.assign({}, state, {enterpriseList: action.data});
    default:
      return state;
  }
}

Object.assign(reducer, {
  getGroupStatusDict() {
    return dispatch => {
      return http.request('v1.groupMessaging.topicStatus', {}).then(data => {
        return data;
      });
    };
  }, 
  getTypeOfSend() {
    return dispatch => {
      return http.request('v1.groupMessaging.fireModeDict', {}).then(data => {
        return data;
      });
    };
  }, 
  getGroupSmsList({page, length, topic, createBeginTime, createEndTime, createUser, fireBeginTime, fireEndTime, topicStatus}) {
    return dispatch => {
      return http.request('v1.groupMessaging.listGroupMessaging', {
        pagerCondition: {page, length},
        queryCondition: {topic, createBeginTime, createEndTime, createUser, fireBeginTime, fireEndTime, topicStatus}
      }).then(data => {
        dispatch({type: LIST, data: data.data});
        return data;
      });
    };
  },
  getCompanyAccountList(enterpriseUsername) {
    return dispatch => {
      return http.request('v1.groupMessaging.searchEnterpriseUser', {enterpriseUsername}, false).then(data => {
        dispatch({type: ENTERPRISE_LIST, data: data.data});
        return data;
      });
    };
  },
  getGroupSmsInfo(groupId) {
    return dispatch => {
      return http.request('v1.groupMessaging.getGroupMessaging', {groupId}).then(data => {
          return data;
        });
    };
  },
  searchAccountByPhone(phone) {
    return dispatch => {
      return http.request('v1.groupMessaging.searchEnterpriseUser', {phone}).then(data => {
          return data;
        });
    };
  },
  createNewGroupSms(params) {
    return dispatch => {
      return http.request('v1.groupMessaging.addGroupMessaging', params).then(data => {
          return data;
        });
    };
  },
  closeGroupSms(groupId) {
    return dispatch => {
      return http.request('v1.groupMessaging.close', {groupId}).then(data => {
          return data;
        });
    };
  }
});

export default reducer;
