import React from 'react';
import { connect } from 'react-redux';
import { Input, DatePicker, Button, Table, Select, Form } from 'antd';
import dayjs from 'dayjs';
import reaction from 'reaction';
import Immutable from 'immutable';
import FormItem from 'components/FormItem';
import { renderListControls } from 'utils';

import commonStyles from 'style/common.module.scss';

const { RangePicker } = DatePicker;
let dateFormat = 'YYYY/MM/DD';
let defaultDatetime = [];

/**
 * 入金记录
 */
@connect(({ accountList, me, common }) => ({ accountList, me, common }))
class FreezeAndUnfreeze extends React.Component {
  state = {
    query: Immutable.Map({
      bizType: '',
      datetime: defaultDatetime,
      serialNo: '',
      platformCode: '',
      bizNo: '',
      page: 1,
      length: 10,
    }),
  };
  onQueryChange = (name, value) => {
    let { query } = this.state;
    query = query.set(name, value);
    this.setState({ query }, () => { });
  };
  doSearch = () => {
    let { query } = this.state;
    query = query.set('page', 1);
    this.setState({ query }, () => {
      this.getFreezeAndUnfreezeList();
    });
  };

  getFreezeAndUnfreezeList = () => {
    let query = this.state.query.toJS();
    let {
      bizType,
      platformCode,
      serialNo,
      bizNo,
      datetime,
      page,
      length,
    } = query;
    let startTime = datetime[0] ? datetime[0].startOf('day').valueOf() : null;
    let endTime = datetime[1] ? datetime[1].endOf('day').valueOf() : null;
    let condition = {
      bizType,
      serialNo,
      startTime,
      endTime,
      platformCode,
      bizNo,
      page,
      length,
    };
    if (condition.startTime === null) {
      delete condition.startTime;
    }
    if (condition.endTime === null) {
      delete condition.endTime;
    }
    reaction.accountList.getFreezeAndUnfreezeList(condition);
  };

  handleTableChange = (pagination, filters, sorter) => {
    let { query } = this.state;
    query = query.set('page', pagination.current);
    query = query.set('length', pagination.pageSize);
    this.setState({ query }, () => {
      this.getFreezeAndUnfreezeList();
    });
  };

  clear = () => {
    let { query } = this.state;
    query = query.set('bizType', '');
    query = query.set('datetime', defaultDatetime);
    query = query.set('serialNo', '');
    query = query.set('platformCode', '');
    query = query.set('bizNo', '');

    this.setState({ query }, () => {
      // ......
    });
  };

  onPressEnter = () => {
    this.doSearch();
  };

  componentDidMount () {
    this.getFreezeAndUnfreezeList();
  }
  render () {
    let { freezeAndUnfreeze } = this.props.accountList;
    let { platformDict } = this.props.common;
    let platforms = [];
    for (let key in platformDict) {
      platforms.push({ name: platformDict[key], code: key });
    }
    let { query } = this.state;
    let columns = [
      {
        title: '流水单号',
        dataIndex: 'serialNo',
      },
      {
        title: '日期',
        dataIndex: 'bizTime',
        render: (text) => {
          if (!text) {
            return '';
          }
          return dayjs(text - 0).format('YYYY-MM-DD HH:mm:ss');
        },
      },
      {
        title: '金额',
        dataIndex: 'money',
      },
      {
        title: '费用类型',
        dataIndex: 'bizType',
      },
      {
        title: '处理方式',
        dataIndex: 'fundsType',
      },
      {
        title: '平台',
        dataIndex: 'platform',
      },
      {
        title: '订单号',
        dataIndex: 'bizNo',
      },
    ];

    let fundsType = [
      {
        code: 1,
        name: '充值',
      },
      {
        code: 2,
        name: '货款',
      },
      {
        code: 3,
        name: '手续费',
      },
      {
        code: 4,
        name: '违约金',
      },
      {
        code: 5,
        name: '提现',
      },
      {
        code: 6,
        name: '补款',
      },
      {
        code: 7,
        name: '运费',
      },
      {
        code: 8,
        name: '司机运费',
      },
      {
        code: 9,
        name: '保证金',
      },
      {
        code: 10,
        name: '出金账号认证金',
      },
    ];

    return (
      <div>
        <Form layout="inline">
          {
            renderListControls([
              <FormItem label="流水单号">
                <Input
                  onPressEnter={this.onPressEnter}
                  value={query.toJS().serialNo}
                  onChange={(e) => {
                    this.onQueryChange('serialNo', e.target.value);
                  }}
                  placeholder="请输入流水单号"
                />
              </FormItem>,
              <FormItem label="日期">
                <RangePicker
                  value={query.toJS().datetime}
                  onChange={(value) => {
                    this.onQueryChange('datetime', value);
                  }}
                  format={dateFormat}
                />
              </FormItem>,
              <FormItem label="费用类型">
                <Select
                  showSearch
                  placeholder="选择费用类型"
                  optionFilterProp="children"
                  value={query.toJS().bizType}
                  onChange={(value) => {
                    this.onQueryChange('bizType', value);
                  }}
                >
                  <Select.Option value="">全部</Select.Option>
                  {fundsType.map((item) => {
                    return (
                      <Select.Option value={item.code} key={item.code}>
                        {item.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </FormItem>,
              <FormItem label="平台">
                <Select
                  showSearch
                  placeholder="选择平台"
                  optionFilterProp="children"
                  value={query.toJS().platformCode}
                  onChange={(value) => {
                    this.onQueryChange('platformCode', value);
                  }}
                >
                  <Select.Option value="">全部</Select.Option>
                  {platforms.map((item) => {
                    return (
                      <Select.Option value={item.code}>{item.name}</Select.Option>
                    );
                  })}
                </Select>
              </FormItem>,
              <FormItem label="订单号">
                <Input
                  onPressEnter={this.onPressEnter}
                  value={query.toJS().bizNo}
                  onChange={(e) => {
                    this.onQueryChange('bizNo', e.target.value);
                  }}
                  placeholder="请输入订单号"
                />
              </FormItem>,
              {
                colProps: {
                  xxl: 18,
                  xl: 8,
                  lg: 12,
                  md: 12,
                },
                children: (
                  <FormItem align="right">
                    <Button
                      type="primary"
                      onClick={this.doSearch}
                      icon="search"
                    >
                      查询
                      </Button>
                    <Button
                      style={{ margin: '0 0 0 5px' }}
                      type="primary"
                      onClick={this.clear}
                      icon="redo"
                    >
                      清空
                    </Button>
                  </FormItem>
                )
              }
            ])
          }
        </Form>
        <div style={{ padding: '20px 0 0 0' }}>
          {freezeAndUnfreeze && (
            <Table
              className={commonStyles.row}
              rowKey="uid"
              columns={columns}
              dataSource={freezeAndUnfreeze.result}
              pagination={{
                current: freezeAndUnfreeze.pagerInfo.page,
                total: freezeAndUnfreeze.pagerInfo.total,
                pageSizeOptions: ['10', '20', '50'],
                showSizeChanger: true,
                showQuickJumper: true,
                showTotal: (total) => `共${total}条`,
              }}
              onChange={this.handleTableChange}
            />
          )}
        </div>
      </div>
    );
  }
}

export default FreezeAndUnfreeze;
