import React from 'react';
import { Link } from 'react-router-dom';
/**
 * 404
 */
export default class Error extends React.Component {
  render() {
    return (
      <div
        style={{
          margin: 'auto',
          width: 400,
          height: 200,
          marginTop: 100,
          textAlign: 'center',
        }}
      >
        <div style={{ fontSize: 30 }}>没有找到这个页面</div>
        <Link to="/">返回首页</Link>
      </div>
    );
  }
}
