import React from 'react';
import dayjs from 'dayjs';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { Table, Button, Input, DatePicker, Select, Tooltip, Form } from 'antd';

import FormItem from 'components/FormItem';
import ConditionFieldItem from 'components/Condition/FieldItem';

import reaction from 'reaction';
import { getUniqTableRowKey, renderListControls } from 'utils';
import { historyPush } from 'utils/history';
import { RoutePath } from 'utils/constants';

import commonStyles from 'style/common.module.scss';

let defaultDatetime = [];
let Option = Select.Option;
let dateFormat = 'YYYY/MM/DD';
const { RangePicker } = DatePicker;

const columns = [
  // {
  //   title: '用户ID',
  //   width: 150,
  //   fixed: 'left',
  //   dataIndex: 'userId',
  // },
  {
    title: '手机号码',
    width: 120,
    dataIndex: 'phone',
  },
  {
    title: '平台',
    width: 120,
    dataIndex: 'platformCode',
  },
  {
    title: 'IP',
    dataIndex: 'ip',
  },
  {
    title: '事件',
    width: 120,
    dataIndex: 'eventType',
  },
  {
    title: '事件内容',
    dataIndex: 'content',
    render: (text, record) => {
      if (text && text.length > 40) {
        return (
          <Tooltip title={text}>
            <span style={{ cursor: 'pointer' }}>
              {text.substr(0, 40)}…
            </span>
          </Tooltip>
        );
      } else {
        return text;
      }
    },
  },
  {
    title: '时间',
    width: 240,
    dataIndex: 'logTime',
    render: (text, record) => dayjs(+text).format('YYYY-MM-DD HH:mm:ss'),
  },
  {
    title: '是否成功',
    width: 100,
    align: 'center',
    dataIndex: 'actionStatus',
  },
];

/**
 * 前台日志
 */
@connect(({ log, common, me }) => ({ log, common, me }))
class LogFrontend extends React.Component {
  state = {
    query: Immutable.Map({
      eventType: '',
      platform: '',
      phone: '',
      userId: '',
      datetime: defaultDatetime,
      page: 1,
      length: 10,
    }),
  };

  componentDidMount () {
    this.getFrontLogList();
  }

  getFrontLogList = () => {
    let query = this.state.query.toJS();
    let params = {
      userId: query.userId,
      eventType: query.eventType,
      platformCode: query.platform,
      phone: query.phone,
      length: query.length,
      page: query.page,
    };
    if (query.datetime[0]) {
      params.logTimeBegin = query.datetime[0].startOf('day').valueOf();
    }
    if (query.datetime[1]) {
      params.logTimeEnd = query.datetime[1].endOf('day').valueOf();
    }
    reaction.log.getFrontList(params);
  };

  handleTableChange = (pagination, filters, sorter) => {
    // reaction.log.getFrontList(this.state.keywords, pagination.current);
    let { query } = this.state;
    query = query
      .set('page', pagination.current)
      .set('length', pagination.pageSize);
    this.setState({ query }, () => {
      this.getFrontLogList();
    });
  };
  doSearch = (e) => {
    let { query } = this.state;
    query = query.set('page', 1);
    this.setState({ query }, () => {
      this.getFrontLogList();
    });
    e && e.preventDefault();
  };
  onChange = (e) => {
    this.setState({ keywords: e.target.value });
  };

  onQueryChange (name, value) {
    let { query } = this.state;
    query = query.set(name, value);
    this.setState({ query }, () => { });
  }

  onDetail = (record) => {
    historyPush(
      `/${RoutePath.SYSTEM}/${RoutePath.FRONTEND}/${RoutePath.FRONTEND_DETAIL}`,
      {
        userId: record.userId,
        eventTimestamp: record.eventTimestamp,
        eventType: record.eventType,
        consumerIp: record.consumerIp,
        remark: record.remark,
        providerApplicationName: record.providerApplicationName,
        providerIp: record.providerIp,
        consumerApplicationName: record.consumerApplicationName,
        requestParam: record.requestParam,
        responseBody: record.responseBody,
      }
    );
  };

  handleSearch = (e) => {
    e.preventDefault();
    this.doSearch();
  }

  render () {
    let { query } = this.state;
    let { eventType, platformDict } = this.props.common;
    let platforms = [];
    for (let key in platformDict) {
      platforms.push({ name: platformDict[key], code: key });
    }
    let eventTypes = [];
    for (let key in eventType) {
      eventTypes.push({
        code: key,
        name: eventType[key],
      });
    }

    const { frontendList: list } = this.props.log;

    return (
      <div className="user">
        <h2 className={commonStyles.row}>前台日志</h2>
          <Form onSubmit={this.handleSearch} layout="inline">
            {
              renderListControls([
                {
                  children: (
                    <FormItem label="手机号">
                      <Input
                        maxLength={11}
                        value={query.toJS().phone}
                        onChange={(e) => {
                          this.onQueryChange('phone', e.target.value);
                        }}
                        placeholder="手机号"
                      />
                    </FormItem>
                  )
                },
                {
                  children: (
                    <FormItem label="平台">
                      <Select
                        placeholder="平台"
                        defaultValue=""
                        onChange={(value) => {
                          this.onQueryChange('platform', value);
                        }}
                      >
                        <Option key="all" value="">
                          全部
                        </Option>
                        {platforms.map((item) => {
                          return (
                            <Option key={item.name} value={item.code}>
                              {item.name}
                            </Option>
                          );
                        })}
                      </Select>
                    </FormItem>
                  )
                },
                {
                  children: (
                    <FormItem label="事件类型">
                      <Select
                        placeholder="事件类型"
                        defaultValue=""
                        onChange={(value) => {
                          this.onQueryChange('eventType', value);
                        }}
                      >
                        <Option key="all" value="">
                          全部
                        </Option>
                        {eventTypes.map((item) => {
                          return (
                            <Option key={item.code} value={item.code}>
                              {item.name}
                            </Option>
                          );
                        })}
                      </Select>
                    </FormItem>
                  )
                },
                {
                  children: (
                    <FormItem label="日期">
                      <RangePicker
                        // value={query.toJS().datetime}
                        onChange={(value) => {
                          this.onQueryChange('datetime', value);
                        }}
                        format={dateFormat}
                      />
                    </FormItem>
                  )
                },
                {
                  colProps: {
                    xxl: 24,
                    xl: 16,
                    lg: 24,
                    md: 24,
                  },
                  children: (
                    <FormItem align="right">
                      <Button type="primary" icon="search" htmlType="submit">
                        查询
                      </Button>
                    </FormItem>
                  )
                }
              ])
            }
          </Form>
        {list && (
          <Table
            className={commonStyles.row}
            rowKey={getUniqTableRowKey('userId', list.pagerInfo)}
            columns={columns}
            dataSource={list.result}
            pagination={{
              current: list.pagerInfo.page,
              total: list.pagerInfo.total,
              pageSizeOptions: ['10', '20', '50'],
              showSizeChanger: true,
              showQuickJumper: true,
              showTotal: (total) => `共${total}条`,
            }}
            onChange={this.handleTableChange}
          />
        )}
      </div>
    );
  }
}

export default LogFrontend;
