import React from 'react';
import ImageViewer from 'components/ImageViewer';

import styles from './style.module.scss';

export default function MagnifyEnableImg({ imgUrl }) {
  
  return (
    <ImageViewer imageUrl={imgUrl} className={styles.viewer}>
      <img
        src={imgUrl}
        alt="缩略图"
        width={80}
        height={80}
      />
    </ImageViewer>
  );
}
