import React from 'react';
import { connect } from 'react-redux';
import reaction from 'reaction';
import {
  Table,
  Button,
  Radio,
  message,
  Checkbox,
  Tag,
  Input,
  Tree,
  Select,
} from 'antd';
import { historyPush } from 'utils/history';
import { RoutePath, cenpurIdentityCodes } from 'utils/constants';
import { resolveUrlQuery, validHasPurchase, validHasSupplier } from 'utils';
import classnames from 'classnames';

import unuploadImg from 'assets/unupload.png';
import MagnifyEnableImg from 'components/MagnifyEnableImg';

import commonStyles from 'style/common.module.scss';
import styles from './style.module.scss';
import { REJECT_REASON_MAX_LENGTH } from 'config';

const { TextArea } = Input;
const cenpurIdentityCodeList = [
  cenpurIdentityCodes.purchaser,
  cenpurIdentityCodes.supplier,
];

@connect(({ qualification, me }) => ({ qualification, me }))
class CheckCompanyIdentity extends React.Component {
  state = {
    opinion: '',
    treeData: [],
  };

  getCompanyIdentityInfo = () => {
    let userId = resolveUrlQuery(this.props.location.search).userId;
    let auditNo = resolveUrlQuery(this.props.location.search).auditNo;
    return reaction.qualification.getCompanyIdentityInfo({ userId, auditNo });
  };

  onOpinionChange = (v) => {
    this.setState({
      opinion: v,
    });
  };

  back = () => {
    historyPush(`/${RoutePath.MEMBERCENTER}/${RoutePath.CHECK_IDENTIFY}`, {
      ...resolveUrlQuery(this.props.location.search),
      auditNo: undefined,
      userId: undefined,
    });
  };

  getApplyIdentityName(companyIdentityInfo) {
    return (companyIdentityInfo.applyIdentity &&
      companyIdentityInfo.applyIdentity.name) ||
      '物流-托运'
  }

  pass = () => {
    if (!this.state.opinion) {
      message.warning('请输入审核意见再提交!');
      return;
    }
    let userId = resolveUrlQuery(this.props.location.search).userId;
    let auditNo = resolveUrlQuery(this.props.location.search).auditNo;
    reaction.qualification
      .passCompanyIdentity({
        userId: userId,
        auditNo: auditNo,
        identity: this.getApplyIdentityName(this.props.qualification.companyIdentityInfo),
        auditResult: {
          auditOpinion: this.state.opinion,
          additionalIdentity: null, // 后端要求暂时写死为null或者空数组或者["logistics_shipper"]
          auditPass: true,
        },
      })
      .then((res) => {
        if (res.code === 0) {
          message.success('操作成功!');
          historyPush(
            `/${RoutePath.MEMBERCENTER}/${RoutePath.CHECK_IDENTIFY}`,
            {
              ...resolveUrlQuery(this.props.location.search),
              auditNo: undefined,
              userId: undefined,
            }
          );
        }
      });
  };

  refuse = () => {
    if (!this.state.opinion) {
      message.warning('请输入审核意见再提交!');
      return;
    }
    let userId = resolveUrlQuery(this.props.location.search).userId;
    let auditNo = resolveUrlQuery(this.props.location.search).auditNo;
    reaction.qualification
      .refuseCompanyIdentity({
        userId: userId,
        auditNo: auditNo,
        identity: this.getApplyIdentityName(this.props.qualification.companyIdentityInfo),
        auditResult: {
          auditOpinion: this.state.opinion,
          additionalIdentity: [],
          auditPass: false,
        },
      })
      .then((res) => {
        if (res.code === 0) {
          message.success('审核拒绝成功');
          historyPush(
            `/${RoutePath.MEMBERCENTER}/${RoutePath.CHECK_IDENTIFY}`,
            {
              ...resolveUrlQuery(this.props.location.search),
              auditNo: undefined,
              userId: undefined,
            }
          );
        }
      });
  };
  fixTree = (tree) => {
    tree.forEach((item) => {
      item.key = (item.key || item.id || item.code || item.value) + '';
      item.title = item.title || item.name;
      if (item.children) {
        this.fixTree(item.children);
      }
    });
  };
  getTreeData = () => {
    return reaction.common.getSupplyCategory().then((res) => {
      this.fixTree(res.data);
      this.setState({
        treeData: res.data,
      });
      return res;
    });
  };

  componentDidMount() {
    this.getCompanyIdentityInfo().then((res) => {
      this.getTreeData();
    });
  }

  render() {
    const { manufactorTypeList = [] } = this.props.me;
    let companyIdentityInfo = this.props.qualification.companyIdentityInfo;
    let list = companyIdentityInfo.existIdentity;
    const applyIdentity = companyIdentityInfo.applyIdentity;

    const bankInfoResponseVO = companyIdentityInfo.bankInfoResponseVO;

    let { treeData } = this.state;
    let columns = [
      {
        title: '序号',
        dataIndex: 'number',
      },
      {
        title: '平台',
        dataIndex: 'platform',
      },
      {
        title: '身份',
        dataIndex: 'identity',
      },
    ];
    return (
      <div>
        <h2 className={commonStyles.row}>身份审核</h2>
        <div className={styles.module}>
          <div className={styles.module__title}></div>
          <div className={styles.module__wrapper}>
            <div className={styles.module__item}>
              <label className={styles['module__item--label']}>申请单号:</label>
              <span>{resolveUrlQuery(this.props.location.search).auditNo}</span>
            </div>
          </div>
        </div>
        <div className={styles.module}>
          <div className={styles.module__title}>账户信息</div>
          <div className={styles.module__wrapper}>
            <div className={styles.module__item}>
              <label className={styles['module__item--label']}>会员编号:</label>
              <span>
                {companyIdentityInfo.user && companyIdentityInfo.user.userNo}
              </span>
            </div>
            <div className={styles.module__item}>
              <label className={styles['module__item--label']}>用户名:</label>
              <span>
                {companyIdentityInfo.user && companyIdentityInfo.user.username}
              </span>
            </div>
            <div className={styles.module__item}>
              <label className={styles['module__item--label']}>安全手机:</label>
              <span>
                {companyIdentityInfo.user && companyIdentityInfo.user.phone}
              </span>
            </div>
          </div>
        </div>
        <div className={styles.module}>
          <div
            className={classnames(
              styles.module__title,
              styles['module__title--bold']
            )}
          >
            已有身份
          </div>
          <div className={styles.module__wrapper}>
            <div className={styles.module__item}>
              {list && (
                <Table
                  className={commonStyles.row}
                  rowKey="number"
                  columns={columns}
                  pagination={false}
                  dataSource={list}
                />
              )}
            </div>
          </div>
        </div>
        <div className={styles.module}>
          <div className={styles.module__title}>申请身份:</div>
          <div className={styles.module__wrapper}>
            <div className={styles.module__item}>
              {/*<label style={{width: '16.6666666%', textAlign: 'right',display: 'inline-block'}}></label>*/}
              <Tag color="magenta">
                {this.getApplyIdentityName(companyIdentityInfo)}
              </Tag>
            </div>
          </div>
        </div>
        {(validHasSupplier(companyIdentityInfo.applyIdentity.code) && validHasPurchase(companyIdentityInfo.applyIdentity.code)) && (
          <div className={styles.module}>
            <div className={styles.module__title}>
              {validHasSupplier(companyIdentityInfo.applyIdentity.code)
                ? '供应品类：'
                : '采购品类：'}
            </div>
            <div className={styles.module__wrapper}>
              <div className={styles.module__item}>
                {treeData && treeData.length ? (
                  <Tree
                    checkable
                    disabled
                    checkedKeys={
                      companyIdentityInfo.additionalCert &&
                      validHasSupplier(companyIdentityInfo.applyIdentity.code) ? companyIdentityInfo.additionalCert.supplyCategory
                        : companyIdentityInfo.additionalCert.purchaserCategory
                    }
                    treeData={treeData}
                  />
                ) : null}
              </div>
            </div>
          </div>
        )}
        { validHasPurchase(applyIdentity.code) || validHasSupplier(applyIdentity.code) ? (
          <div className={styles.module}>
            <div className={styles.module__title}>开户信息:</div>
            <div className={styles.module__wrapper}>
              <div className={styles['module__item']}>
                <label className={styles['module__item--label']}>
                  开户许可证:
                </label>
                <div className={styles['module__item--content']}>
                  {bankInfoResponseVO.accountLicenseUrl ? (
                    <div className={styles['module__item--img']}>
                      <img
                        alt=""
                        src={bankInfoResponseVO.accountLicenseUrl.url}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
              <div className={styles['module__item']}>
                <label className={styles['module__item--label']}>开户行:</label>
                <div className={styles['module__item--content']}>
                  {bankInfoResponseVO.openBank}
                </div>
              </div>
              <div className={styles['module__item']}>
                <label className={styles['module__item--label']}>户名:</label>
                <div className={styles['module__item--content']}>
                  {bankInfoResponseVO.accountName}
                </div>
              </div>
              <div className={styles['module__item']}>
                <label className={styles['module__item--label']}>帐号:</label>
                <div className={styles['module__item--content']}>
                  {bankInfoResponseVO.accountNo}
                </div>
              </div>
            </div>
          </div>
        ) : null}
        <div className={styles.module}>
          <div className={styles.module__title}>其他信息:</div>
          <div className={styles.module__wrapper}>
            <div className={styles.module__item}>
              <label className={styles['module__item--label']}>外贸资质:</label>
              <span>
                {companyIdentityInfo.additionalCert &&
                companyIdentityInfo.additionalCert.hasForeignTradeCert
                  ? '具备'
                  : '不具备'}
              </span>
            </div>
            {companyIdentityInfo.additionalCert &&
            companyIdentityInfo.additionalCert.hasForeignTradeCert ? (
              <React.Fragment>
                <div className={styles.module__item}>
                  <label className={styles['module__item--label']}>
                    对外贸易经营者备案登记表附件:
                  </label>
                  {companyIdentityInfo.additionalCert &&
                  companyIdentityInfo.additionalCert
                    .foreignTradeRegistrationUrl &&
                  companyIdentityInfo.additionalCert.foreignTradeRegistrationUrl
                    .url ? (
                    <MagnifyEnableImg
                      style={{ marginLeft: 15 }}
                      imgUrl={
                        companyIdentityInfo.additionalCert
                          .foreignTradeRegistrationUrl.url
                      }
                    />
                  ) : (
                    <img
                      style={{ width: 80, height: 80, marginLeft: 15 }}
                      src={unuploadImg}
                      alt=""
                    />
                  )}
                </div>
                <div className={styles.module__item}>
                  <label className={styles['module__item--label']}>
                    进出口企业代码号:
                  </label>
                  <span>
                    {companyIdentityInfo.additionalCert &&
                      companyIdentityInfo.additionalCert
                        .foreignTradeEnterpriseCode}
                  </span>
                </div>
              </React.Fragment>
            ) : null}
          </div>
        </div>

        <div className={styles.module}>
          <div className={styles.module__title}>审核意见:</div>
          <div className={styles.module__wrapper}>
            <div style={{ marginBottom: 30 }}>
              {/*<label style={{width: '16.6666666%', textAlign: 'right',display: 'inline-block'}}></label>*/}
              {companyIdentityInfo.applyIdentity.code.indexOf('logistics') ===
                -1 &&
                !list.some((item) => item.identity === '物流-托运方') && (
                  <Checkbox checked={true} disabled={true}>
                    凡集采，交易平台用户，在开通身份时，系统将自动授予“物流托运方”身份。
                  </Checkbox>
                )}
            </div>
            <TextArea
              style={{ width: 350 }}
              rows={4}
              maxLength={REJECT_REASON_MAX_LENGTH}
              placeholder={`${REJECT_REASON_MAX_LENGTH}位以内，不限字符类型`}
              onChange={(e) => {
                this.onOpinionChange(e.target.value);
              }}
            />
          </div>
        </div>
        <div style={{ padding: '30px 0 20px 100px' }}>
          <Button onClick={this.refuse}>审核拒绝</Button>
          <Button onClick={this.pass} style={{ marginLeft: 10 }}>
            审核通过
          </Button>
          <Button onClick={this.back} style={{ marginLeft: 10 }}>
            返回
          </Button>
        </div>
      </div>
    );
  }
}

export default CheckCompanyIdentity;