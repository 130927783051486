import React from 'react';
import reaction from 'reaction';
import { connect } from 'react-redux';
import { historyPush } from 'utils/history';
import { resolveUrlQuery } from 'utils';
import { RoutePath, AuthCodes } from 'utils/constants';
import dayjs from 'dayjs';
import Immutable from 'immutable';
import {
  Table,
  Button,
  Input,
  Select,
  DatePicker,
  Switch,
  // Drawer,
  // message,
  // Upload,
  // Icon,
  // AutoComplete,
} from 'antd';
import locale from 'antd/lib/date-picker/locale/zh_CN';

// import commonStyles from 'style/common.module.scss';

// import env from 'utils/env';

const { Option } = Select;
const { TextArea, Search } = Input;
// const AOption = AutoComplete.Option;
const dateFormat = 'YYYY-MM-DD HH:mm:ss';

@connect(({ groupSms }) => ({ groupSms }))
class GroupSmsDetail extends React.Component {
  state = {
    typeOfSend: [],
    groupSmsInfo: Immutable.Map({
      fireMode: 'fixed_time',
      topic: '',
      closed: false,
      createUser: '',
      messageContent: '',
      messageTemplate: '',
      fireExpression: dayjs(),
      phoneList: [],
    }),
  };

  componentDidMount() {
    this.getGroupSmsInfo();
    this.getTypeOfSend();
  }

  getTypeOfSend = () => {
    reaction.groupSms.getTypeOfSend().then((data) => {
      data.code === 0 && this.setState({ typeOfSend: data.data });
    });
  };

  getGroupSmsInfo = () => {
    let { groupId } = resolveUrlQuery(this.props.location.search);
    let { groupSmsInfo } = this.state;
    if (!groupId) return;
    return reaction.groupSms.getGroupSmsInfo(groupId).then((res) => {
      groupSmsInfo = groupSmsInfo.set('topic', res.data.topic);
      groupSmsInfo = groupSmsInfo.set('closed', res.data.closed);
      groupSmsInfo = groupSmsInfo.set('createUser', res.data.createUser);
      groupSmsInfo = groupSmsInfo.set('fireMode', res.data.fireMode);
      groupSmsInfo = groupSmsInfo.set(
        'fireExpression',
        dayjs(+res.data.fireExpression)
      );
      groupSmsInfo = groupSmsInfo.set(
        'messageContent',
        res.data.messageContent
      );
      groupSmsInfo = groupSmsInfo.set(
        'messageTemplate',
        res.data.messageTemplate
      );
      groupSmsInfo = groupSmsInfo.set('phoneList', res.data.phoneList);
      this.setState({ groupSmsInfo });
    });
  };

  back = () => {
    historyPush(`/${RoutePath.OPERATION_CENTER}/${RoutePath.GROUP_SMS}`);
  };

  render() {
    let { typeOfSend } = this.state;
    let info = this.state.groupSmsInfo.toJS();
    let { phoneList } = info;
    let { me } = this.props;

    let columns1 = [
      {
        title: '号码',
        dataIndex: 'phone',
      },
      {
        title: '企业',
        dataIndex: 'username',
      },
    ];
    return (
      <div style={{ padding: '0 0 50px 0' }}>
        <h4 style={{ padding: '10px 0 20px 0' }}>
          <span>运营中心 / 查看群发</span>
        </h4>
        <div style={{ display: 'flex' }}>
          <div
            style={{
              width: '50%',
              boxSizing: 'border-box',
              padding: '0 20px 0',
            }}
          >
            <div style={{ padding: '20px 0 0' }}>
              <label style={{ display: 'inline-block', width: 70 }}>
                主题：
              </label>
              <Input
                disabled={true}
                style={{ width: 165 }}
                value={info.topic}
                placeholder="请输入主题"
              />
            </div>
            <div style={{ padding: '20px 0 0' }}>
              <label style={{ display: 'inline-block', width: 70 }}>
                状态:
              </label>
              <Switch
                disabled={true}
                checked={!info.closed}
                checkedChildren="正常"
                unCheckedChildren="关闭"
              />
            </div>
            <div style={{ padding: '20px 0 0' }}>
              <label style={{ display: 'inline-block', width: 70 }}>
                发送方式：
              </label>
              <Select
                showSearch
                style={{ width: 120 }}
                placeholder="请选择"
                disabled={true}
                value={info.fireMode}
              >
                {typeOfSend &&
                  typeOfSend.map((item) => {
                    if (item.code === 'cyclic') {
                      return null;
                    }
                    return <Option value={item.code} key={item.code}>{item.name}</Option>;
                  })}
              </Select>
              <label> 定于 </label>
              <DatePicker
                format={dateFormat}
                locale={locale}
                disabled={true}
                value={info.fireExpression}
              />
              <span> 发送</span>
            </div>
            <div style={{ padding: '20px 0 0', display: 'flex' }}>
              <label
                style={{ display: 'inline-block', width: 70, flexShrink: 0 }}
              >
                短信内容:
              </label>
              <TextArea
                style={{ flexGrow: 1 }}
                rows={6}
                disabled={true}
                value={info.messageContent}
              />
            </div>
          </div>
          <div
            style={{
              width: '50%',
              boxSizing: 'border-box',
              borderLeft: '1px dashed #dddddd',
              padding: '0 20px 0',
            }}
          >
            <div style={{ padding: '20px 0 0', display: 'flex' }}>
              <label
                style={{ display: 'inline-block', width: 70, flexShrink: 0 }}
              >
                发送名单:
              </label>
            </div>
            <div style={{ padding: '20px 0 0', display: 'flex' }}>
              <div
                style={{
                  marginLeft: 70,
                  maxHeight: 400,
                  overflow: 'auto',
                  width: '100%',
                }}
              >
                <Table
                  size="small"
                  columns={columns1}
                  dataSource={phoneList}
                  pagination={false}
                  rowKey={(r) => `${r.username}_${r.phone}`}
                />
              </div>
            </div>
            <div style={{ padding: '20px 0 0', display: 'flex' }}>
              <p style={{ marginLeft: 70 }}>总计: {phoneList.length}个号码</p>
            </div>
          </div>
        </div>

        <div
          style={{
            padding: '20px 100px 20px 100px',
            margin: '30px',
            overflow: 'hidden',
            borderTop: '1px dashed #dddddd',
          }}
        >
          <span>创建人: {info.createUser}</span>
          <Button
            style={{ float: 'right', marginRight: 20 }}
            onClick={this.back}
          >
            返回
          </Button>
        </div>
      </div>
    );
  }
}

export default GroupSmsDetail;
