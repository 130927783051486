import http from 'utils/http';
import createUID from 'utils/createUID';

const LIST = createUID();
const DICT_LIST = createUID();
const DICT_TYPE = createUID();
const LOG_LIST = createUID();

const initialState = {
  list: {
    pagerInfo: {
      page: 1,
      total: 0
    },
    result: [
      {
        id: 1
      },
      {
        id: 2
      }
    ]
  },
  logList: {
    pagerInfo: {
      page: 1,
      total: 0
    },
    result: []
  },
  dictList: [],
  dictType: {}
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case LIST:
      return Object.assign({}, state, { list: action.data });
    case DICT_LIST:
      return Object.assign({}, state, { dictList: action.data });
    case DICT_TYPE:
      return Object.assign({}, state, { dictType: action.data });
    case LOG_LIST:
      return Object.assign({}, state, { logList: action.data });
    default:
      return state;
  }
}

Object.assign(reducer, {
  getDataDictList({ page, length, description, code, name }) {
    return dispatch => {
      return http
        .request('v1.system.dict.listDictType', {
          pagerCondition: { page, length },
          queryCondition: { description, code, name }
        })
        .then(data => {
          dispatch({ type: LIST, data: data.data });
          return data;
        });
    };
  },

  saveDictType({ name, code, description }) {
    return dispatch => {
      return http
        .request('v1.system.dict.saveDictType', { name, code, description })
        .then(data => {
          return data;
        });
    };
  },
  saveDict({ dictList }) {
    return dispatch => {
      return http
        .request('v1.system.dict.saveDict', { dictList })
        .then(data => {
          return data;
        });
    };
  },
  add({ type, code, remarks, values }) {
    return dispatch => {
      return http.request('', { type, code, remarks, values }).then(data => {
        return data;
      });
    };
  },
  deleteDict(id) {
    return dispatch => {
      return http
        .request('v1.system.dict.deleteDictType', { id })
        .then(data => {
          return data;
        });
    };
  },
  deleteDictKeyValue(id) {
    return dispatch => {
      return http.request('v1.system.dict.deleteDict', { id }).then(data => {
        return data;
      });
    };
  },
  getDictInfo(id) {
    return dispatch => {
      return http
        .request('v1.system.dict.getDictAndDictType', { id })
        .then(data => {
          dispatch({ type: DICT_LIST, data: data.data.dictList });
          dispatch({ type: DICT_TYPE, data: data.data.dictType });
          return data;
        });
    };
  },
  getLogList(id, page, length) {
    return dispatch => {
      return http
        .request('v1.system.dict.listDictLog', {
          pagerCondition: { page, length },
          queryCondition: { id }
        })
        .then(data => {
          dispatch({ type: LOG_LIST, data: data.data });
          return data;
        });
    };
  },
  updateDictType({ id, name, code, description }) {
    return dispatch => {
      return http
        .request('v1.system.dict.updateDictType', {
          id,
          name,
          code,
          description
        })
        .then(data => {
          return data;
        });
    };
  },
  updateDictList({ dictList }) {
    return dispatch => {
      return http
        .request('v1.system.dict.updateDict', { dictList })
        .then(data => {
          return data;
        });
    };
  }
});

export default reducer;
