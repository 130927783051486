import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Menu, Layout, Popconfirm } from 'antd';

import cookie from 'utils/cookie';
import { getRootDomain } from 'utils';
// import { platformManagementList } from 'config';

// import logo_blue from 'assets/logo_blue.png';

import styles from './index.module.scss';

let root_domain = getRootDomain();

const SubMenu = Menu.SubMenu;
// const MenuItemGroup = Menu.ItemGroup;
const { Header, Content, Sider } = Layout;

@withRouter
@connect(({ menu, me, common }) => ({ menu, me, common }))
class Container extends React.Component {
  state = {
    collapsed: false,
    keys: [],
    openKeys: [],
  };

  // handleMenuClick = (event) => {
  //   this.props.router.push(event.key);
  // };

  handleSubMenuClick = (event) => {
    this.props.history.push(event.key);
    localStorage.setItem('currentSubMenuKey', event.key);
  };

  // getPathname() {
  //   let {routes} = this.props;
  //   return routes.map((route, index) => index <= 2 && route.path || '').join('');
  // }

  // getAllPathname() {
  //   let {location} = this.props;
  //   return location.pathname;
  // }

  // filterMenus() {
  //   let {menu, routes} = this.props;
  //   let pathname = routes.map((route, index) => index <= 2 && route.path || '').join('');
  //   let menu2;
  //   menu.forEach((data) => {
  //     if (data.path === pathname) {
  //       menu2 = data;
  //     }
  //   });
  //   return menu2;
  // }

  menuFactory(data, parentPath = '', notContainSubMenu) {
    let key = parentPath + '' + data.path;
    let enabled = this.isEnabled(data);
    if (data.children) {
      let childrenEnabled = false;
      let children = data.children.map((data, index) => {
        let menuChildren = this.menuFactory(data, key);
        if (menuChildren) childrenEnabled = true;
        return menuChildren;
      });
      if (notContainSubMenu) {
        return (enabled || childrenEnabled) && children;
      } else {
        return (
          (enabled || childrenEnabled) && (
            <SubMenu
              title={
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  {data.icon ? <img style={{ width: 15, height: 15, marginRight: 8 }} src={data.icon} alt="" /> : ''}
                  {data.subLabel || data.label}
                </span>
              }
              key={key}>
              {children}
            </SubMenu>
          )
        );
      }
    } else {
      return (
        enabled && (
          <Menu.Item key={key} disabled={data.disabled}>
            {data.label}
          </Menu.Item>
        )
      );
    }
  }

  menuOpenKeys(data, parentPath = '') {
    if (data.children) {
      let key = parentPath + '' + data.path;
      let keys = [key];
      data.children.forEach((data, index) => {
        let childrenKeys = this.menuOpenKeys(data, key);
        if (childrenKeys) {
          keys = keys.concat(childrenKeys);
        }
      });
      return keys;
    } else {
      return null;
    }
  }

  onCollapse = (collapsed) => {
    this.setState({
      collapsed,
    });
  };
  onLogout = (e) => {
    uamsdk.removeToken();
    cookie.setCookie('uam_sso_server_token', '', -1, '/', root_domain);
    cookie.setCookie('JSESSIONID', '', -1);
    uamsdk.logout(() => {
      this.props.history.push('/login');
    });
  };

  menuFactory2(data, parentPath = '') {
    let key = parentPath + '' + data.path;
    if (data.children) {
      let keyArr = [key];
      data.children.forEach((data, index) => {
        let childrenKeyArr = this.menuFactory2(data, key);
        keyArr = keyArr.concat(childrenKeyArr);
      });
      return keyArr;
    } else {
      return [key];
    }
  }

  isEnabled(menu) {
    let enabled = this.props.me.roleInfo
      ? this.props.me.roleInfo.roleAuthList.some((item, index) => {
          if (menu.children && menu.children.length && item.authCode.indexOf(menu.auth_code.split('.')[0] + '.' + menu.auth_code.split('.')[1]) !== -1) {
            return true;
          }
          if ((!menu.children || !menu.children.length) && item.authCode.indexOf(menu.auth_code) !== -1) {
            return true;
          }
        })
      : false;
    if (enabled) return true;
    if (menu.children) {
      return menu.children.some((child) => this.isEnabled(child));
    }
    return enabled;
  }

  // genPlatformListLink() {
  //   const hostname = location.hostname;
  //   const template = getHost();
  //   const isProduction = hostname.indexOf('zlgx.com') !== -1;

  //   return platformManagementList.map(el => {
  //     return this.genPlatformLinkByEnv(template, el, isProduction);
  //   });
  // }

  // genPlatformLinkByEnv(template = '', { platformName, platformCode, platformLinkPath }, isProd = false) {
  //   const code = platformCode.split('|');
  //   return {
  //     name: platformName,
  //     link: `${template.replace('uam', code.length > 1 ? isProd ? code[1] : code[0] : platformCode)}${platformLinkPath}`,
  //     code,
  //   };
  // }

  // getSelectedPath = () => {
  //   let path = this.props.location.pathname.match(/^\/[a-zA-Z]+(\/[a-zA-Z]+)?/)[0];
  //   return path;
  //   // let key = this.state.keys.filter(item => item === path)[0];
  //   // if (key) {
  //   //   return key;
  //   // } else {
  //   //   return path;
  //   // }
  // };

  onOpenChange = (openKeys) => {
    const latestOpenKey = openKeys.find((key) => this.state.openKeys.indexOf(key) === -1);
    if (this.props.menu.map((item) => item.path).indexOf(latestOpenKey) === -1) {
      this.setState({ openKeys });
    } else {
      this.setState({
        openKeys: latestOpenKey ? [latestOpenKey] : [],
      });
    }
  };

  syncOpenKeys = () => {
    this.setState({
      openKeys: ['/' + this.props.location.pathname.replace(/^\//, '').split('/')[0]],
    });
  };

  componentDidMount() {
    this.syncOpenKeys();
  }
  componentDidUpdate() {
    setTimeout(() => {
      document.getElementById('rightContentLayout') && (document.getElementById('rightContentLayout').scrollTop = 0);
    }, 20);
  }

  render() {
    let { menu, me, location, children } = this.props;
    let selectedKey = location.pathname;
    let currentSubMenuKey = localStorage.getItem('currentSubMenuKey');
    if (currentSubMenuKey) {
      let subMenuPath1 = currentSubMenuKey.replace(/^\//, '').split('/')[1];
      let subMenuPath2 = location.pathname.replace(/^\//, '').split('/')[1];
      if (subMenuPath1 === subMenuPath2) {
        selectedKey = localStorage.getItem('currentSubMenuKey');
      }
    }
    // const platformList = this.genPlatformListLink();
    if (location.pathname === '/login') {
      return children;
    }
    let headerLogo = JSON.parse(localStorage.getItem('uam[WEBINFO]'))?.data?.init?.headerLogo ?? '';
    let platformList = JSON.parse(localStorage.getItem('uam[WEBINFO]'))?.data?.topBar ?? [];
    return (
      <Layout className={styles.layout}>
        <Header
          theme="light"
          style={{
            color: '#fff',
            position: 'relative',
            height: 50,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
          <h3
            style={{
              textAlign: 'center',
              lineHeight: '40px',
              color: '#fff',
              padding: '0 0 0 50px',
              margin: 0,
            }}>
            <img src={headerLogo} style={{ width: 118, height: 30, marginRight: 50 }} alt="" />
            {platformList.map((platform) => {
              return (
                <a className={styles['platform-item']} target="_blank" href={platform.url} key={platform.url}>
                  {platform.name}
                </a>
              );
            })}
          </h3>
          <div>
            <span style={{ margin: '0 10px' }}>您好，{me.userInfo.userName}</span>
            {/* <a target="_self" href={`${env.SSO_URL}#/updatePassword`}>
              密码管理
            </a> */}
            <Popconfirm title="确定要退出吗?" onConfirm={this.onLogout} okText="确定退出" cancelText="取消" placement="bottomRight">
              <span
                style={{
                  cursor: 'pointer',
                  margin: '0 10px',
                  padding: '4px 0',
                  border: 0,
                }}>
                退出
              </span>
            </Popconfirm>
          </div>
        </Header>
        {/*<Header>*/}
        {/*<Menu*/}
        {/*mode="horizontal"*/}
        {/*theme="dark"*/}
        {/*onClick={this.handleMenuClick}*/}
        {/*selectedKeys={[this.getPathname()]}*/}
        {/*>*/}
        {/*<Menu.Item key='/'>我的主页</Menu.Item>*/}
        {/*{*/}
        {/*menu.map((data)=>{*/}
        {/*return this.isEnabled(data) && <Menu.Item key={data.path} disabled={data.disabled}>{data.label}</Menu.Item>;*/}
        {/*})*/}
        {/*}*/}
        {/*</Menu>*/}
        {/*</Header>*/}
        <Layout
          style={{
            position: 'absolute',
            top: 50,
            right: 0,
            bottom: 0,
            left: 0,
          }}>
          {
            <Sider
              theme="light"
              width={250}
              style={{
                overflow: 'auto',
                height: '100vh',
                position: 'fixed',
                left: 0,
              }}
              collapsed={this.state.collapsed}
              onCollapse={this.onCollapse}>
              <Menu
                theme="light"
                onClick={this.handleSubMenuClick}
                mode={this.state.collapsed ? 'vertical' : 'inline'}
                selectedKeys={[selectedKey]}
                openKeys={this.state.openKeys}
                onOpenChange={this.onOpenChange}
                style={{ height: '100%' }}>
                <Menu.Item key="/">我的面板</Menu.Item>
                {
                  menu.map((item) => {
                    return this.menuFactory(item, '');
                  })
                  // this.menuFactory(menuSub, '', true)
                }
              </Menu>
            </Sider>
          }
          <Layout id={'rightContentLayout'} style={{ padding: 12, background: '#f2f2f2', marginLeft: 250 }}>
            <Content
              style={{
                background: '#fff',
                padding: 12,
                minWidth: 750,
                overflow: 'auto',
              }}>
              {children}
            </Content>
          </Layout>
        </Layout>
      </Layout>
    );
  }
}

export default Container;
