import http from 'utils/http';
import createUID from 'utils/createUID';

const LIST = createUID();
const SMS_LIST = createUID();
const LOG_LIST = createUID();

const initialState = {
  list: {
    pagerInfo: {
      page: 1,
      total: 0
    },
    result: []
  },
  smsHistoryList: {
    pagerInfo: {
      page: 1,
      total: 0
    },
    result: []
  },
  logList: {
    pagerInfo: {
      page: 1,
      total: 0
    },
    result: []
  }
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case LIST:
      return Object.assign({}, state, { list: action.data });
    case SMS_LIST:
      return Object.assign({}, state, { smsHistoryList: action.data });
    case LOG_LIST:
      return Object.assign({}, state, { logList: action.data });
    default:
      return state;
  }
}

Object.assign(reducer, {
  getSmsModelList({
    page,
    length,
    templateCode,
    templateName,
    templateDescription,
    alismsTemplateCode
  }) {
    return dispatch => {
      return http
        .request('v1.system.sms.listSmsTemplate', {
          pagerCondition: { page, length },
          queryCondition: {
            templateCode,
            templateName,
            templateDescription,
            alismsTemplateCode
          }
        })
        .then(data => {
          dispatch({ type: LIST, data: data.data });
          return data;
        });
    };
  },
  addSmsModel({
    templateCode,
    templateContent,
    templateDescription,
    alismsTemplateCode,
    isValid,
    templateName
  }) {
    return dispatch => {
      return http
        .request('v1.system.sms.saveSmsTemplate', {
          templateCode,
          templateContent,
          templateDescription,
          alismsTemplateCode,
          isValid,
          templateName
        })
        .then(data => {
          return data;
        });
    };
  },
  deleteDict(id) {
    return dispatch => {
      return http.request('', { id }).then(data => {
        return data;
      });
    };
  },
  updateSmsModel({
    id,
    templateCode,
    templateContent,
    templateDescription,
    alismsTemplateCode,
    isValid,
    templateName
  }) {
    return dispatch => {
      return http
        .request('v1.system.sms.updateSmsTemplate', {
          id,
          templateCode,
          templateContent,
          templateDescription,
          alismsTemplateCode,
          isValid,
          templateName
        })
        .then(data => {
          return data;
        });
    };
  },
  getSmsModelInfo(smsTemplateId) {
    return dispatch => {
      return http
        .request('v1.system.sms.getSmsTemplate', { smsTemplateId })
        .then(data => {
          return data;
        });
    };
  },
  getLogList(smsTemplateId, page, length) {
    return dispatch => {
      return http
        .request('v1.system.sms.listSmsTemplateLog', {
          pagerCondition: { page, length },
          queryCondition: { smsTemplateId }
        })
        .then(data => {
          dispatch({ type: LOG_LIST, data: data.data });
          return data;
        });
    };
  },
  getSmsHistoryList({
    page,
    length,
    phone,
    content,
    bizNo,
    startTime,
    endTime,
    smsPlatformCode,
    sendStatus
  }) {
    return dispatch => {
      return http
        .request('v1.system.sms.listSmsSendRecord', {
          pagerCondition: { page, length },
          queryCondition: {
            phone,
            content,
            bizNo,
            startTime,
            endTime,
            smsPlatformCode,
            sendStatus
          }
        })
        .then(data => {
          dispatch({ type: SMS_LIST, data: data.data });
          return data;
        });
    };
  },
  getHistorySmsInfo(id) {
    return dispatch => {
      return http
        .request('v1.system.sms.getSmsSendRecord', { id })
        .then(data => {
          return data;
        });
    };
  }
});

export default reducer;
