function setCookie(cname, cvalue, exdays) {
    var path = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '/';
    var domain = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : '';
    var expires;
    var d = new Date();
    if (exdays) {
        d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
        expires = 'expires=' + d.toUTCString();
        domain && (document.cookie = cname + '=' + cvalue + '; ' + expires + ';path=' + path + ';sameSite=lax;domain=' + domain);
        domain || (document.cookie = cname + '=' + cvalue + '; ' + expires + ';path=' + path);
    }
    else {
        domain && (document.cookie = cname + '=' + cvalue + ';path=' + path + ';sameSite=lax;domain=' + domain);
        domain || (document.cookie = cname + '=' + cvalue + ';sameSite=lax;path=' + path);
    } // exdays && (document.cookie = cname + '=' + cvalue + '; ' + expires + ';path=' + path);
    // !exdays && (document.cookie = cname + '=' + cvalue + ';path=' + path);
}
function getCookie(c_name) { var path = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : ''; path = path || '/'; c_name = path === '/' ? c_name : c_name + path.replace('/', '_'); var value = ''; if (document.cookie.length > 0) {
    var arr = document.cookie.split(';');
    for (var i = 0; i < arr.length; i++) {
        if (arr[i].split('=')[0].trim() === c_name) {
            value = arr[i].split('=')[1];
        }
    }
} return value; }
export default { setCookie: setCookie, getCookie: getCookie };
