import React from 'react';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import Immutable from 'immutable';
import reaction from 'reaction';
import { Table, Button, Select } from 'antd';
import { historyPush } from 'utils/history';
import { RoutePath } from 'utils/constants';
import { resolveUrlQuery, checkHasDriverIdentity } from 'utils';
import PersonDetail from 'modules/PersonDetail';

import commonStyles from 'style/common.module.scss';

const { Option } = Select;
// Review: 资质列表 - 司机 - 详情

@connect(({ qualification, memberCenter, me, common }) => ({
  qualification,
  memberCenter,
  me,
  common,
}))
class ViewPersonalQualificationDetail extends React.Component {
  state = {
    province: [],
    city: [],
    district: [],
    query: Immutable.Map({
      page: 1,
      length: 10,
    }),
    info: null,
  };

  getPersonalQualificationInfo = () => {
    let userId = resolveUrlQuery(this.props.location.search).userId;
    reaction.qualification
      .getPersonalQualificationInfo({ userId })
      .then((res) => {
        if (res.code !== 0) return;

        // 统一扁平化数据 保持统一
        const {
          additionalCert: { vehicleInfo },
          attachment,
          baseInfo,
        } = res.data;

        this.setState({ info: { ...vehicleInfo, ...attachment, ...baseInfo } });
      });
  };

  getOperationHistory = () => {
    let { page, length } = this.state.query.toJS();
    reaction.qualification.getPersonalQualificationOperationHistory({
      userId: resolveUrlQuery(this.props.location.search).userId,
      page,
      length,
      operationType: resolveUrlQuery(this.props.location.search).operationType,
    });
  };
  getDepartmentDict = () => {
    reaction.common.getDepartmentDict();
  };

  back = () => {
    historyPush(`/${RoutePath.MEMBERCENTER}/${RoutePath.QUALIFICATION_LIST}`);
  };

  componentDidMount() {
    this.getOperationHistory();
    this.getDepartmentDict();
    this.getPersonalQualificationInfo();
  }

  render() {
    const { info } = this.state;
    let { departments } = this.props.common;
    let qualificationDetailInfo = this.props.qualification
      .personalQualificationDetailInfo;

    let columns = [
      {
        width: 112,
        title: '时间',
        dataIndex: 'updateTime',
        render: (text) => {
          if (!text) {
            return '';
          }
          return dayjs(text - 0).format('YYYY-MM-DD HH:mm:ss');
        },
      },
      {
        width: 140,
        title: '操作人',
        dataIndex: 'operator',
      },
      {
        title: '修改内容',
        dataIndex: 'updateContent',
        render: (text) => {
          return <pre style={{ maxWidth: '1000px' }}>{text}</pre>;
        },
      },
    ];
    let list = this.props.qualification.personalQualificationOperationHistory;
    const hasDriverIdentity = checkHasDriverIdentity(qualificationDetailInfo.identity)
    return (
      <div className="person-info">
        <h2 className={commonStyles.row}>司机资质信息</h2>
        <div style={{ overflow: 'hidden' }}>
          <div style={{ float: 'left', width: 100 }}>账户信息</div>
          <div style={{ marginLeft: 100 }}>
            <div style={{ marginBottom: 10 }}>
              <label
                className="form-item-label-item"
              >
                会员编号：
              </label>
              <span
                style={{
                  width: 250,
                  lineHeight: '32px',
                  display: 'inline-block',
                }}
              >
                {qualificationDetailInfo.user.userNo}
              </span>
            </div>
            <div style={{ marginBottom: 10 }}>
              <label
                className="form-item-label-item"
              >
                用户名：
              </label>
              <span
                style={{
                  width: 250,
                  lineHeight: '32px',
                  display: 'inline-block',
                }}
              >
                {qualificationDetailInfo.user.username}
              </span>
            </div>
            <div style={{ marginBottom: 10 }}>
              <label
                className="form-item-label-item"
              >
                安全手机：
              </label>
              <span
                style={{
                  width: 250,
                  lineHeight: '32px',
                  display: 'inline-block',
                }}
              >
                {qualificationDetailInfo.user.phone}
              </span>
            </div>
            <div style={{ marginBottom: 10 }}>
              <label
                className="form-item-label-item"
              >
                责任部门：
              </label>
              <Select
                disabled
                value={qualificationDetailInfo.department || ''}
                style={{ width: 250 }}
              >
                {departments &&
                  departments.map((item) => {
                    return (
                      <Option key={item.code || ''} value={item.code}>
                        {item.name}
                      </Option>
                    );
                  })}
              </Select>
            </div>
            <div style={{ marginBottom: 10 }}>
              <label
                className="form-item-label-item"
              >
                业务员：
              </label>
              <span
                style={{
                  width: 250,
                  lineHeight: '32px',
                  display: 'inline-block',
                }}
              >
                {qualificationDetailInfo.salesman}
              </span>
            </div>
            <div style={{ marginBottom: 10 }}>
              <label
                className="form-item-label-item"
              >
                小组：
              </label>
              <span
                style={{
                  width: 250,
                  lineHeight: '32px',
                  display: 'inline-block',
                }}
              >
                {qualificationDetailInfo.team}
              </span>
            </div>
          </div>
        </div>

        {info && <PersonDetail info={info} hasDriverIdentity={hasDriverIdentity} />}

        <div style={{ padding: '10px 0 50px 150px' }}>
          <Button onClick={this.back} style={{ marginLeft: 10 }}>
            返回
          </Button>
        </div>

        <div style={{ padding: '0 0 50px 0' }}>
          {list && (
            <Table
              className={commonStyles.row}
              rowKey="updateTime"
              columns={columns}
              dataSource={list.result}
            />
          )}
        </div>
      </div>
    );
  }
}

export default ViewPersonalQualificationDetail;
