import React from 'react';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import Immutable from 'immutable';
import reaction from 'reaction';
import {Table, Button, Input, Select, DatePicker, Modal, Radio, message, Form, Upload, Icon } from 'antd';
import {historyPush} from 'utils/history';
import { resolveUrlQuery, formatInviteSteelText } from 'utils';
import {RoutePath, AuthCodes} from 'utils/constants';
import MagnifyEnableImg from 'components/MagnifyEnableImg';

import unuploadImg from 'assets/unupload.png';

import commonStyles from 'style/common.module.scss';

const FormItem = Form.Item;
const RadioGroup = Radio.Group;
const Option = Select.Option;

const { TextArea } = Input;

@Form.create()
@connect(({ memberCenter, common }) => ({ memberCenter, common }))
class ViewCompanyAuditDetail extends React.Component {
  state = {
    query: Immutable.Map({
      page: 1,
      length: 10
    }),
    bigImgUrl: '',
    showBigImg: false
  };
  closeBigImgModal = () => {
    this.setState({
      showBigImg: false
    });
  };
  onPreview = (e) => {
    this.setState({
      showBigImg: true,
      bigImgUrl: e.target.getAttribute('src')
    });
  };
  getAreaDict = () => {
    return reaction.memberCenter.getAreaDict();
  };
  
  getCompanyAuditHistoryDetail = () => {
    const { userId, certId } = resolveUrlQuery(this.props.location.search);
    reaction.memberCenter.getCompanyAuditHistoryDetail({userId, certId});
  };
  
  handleTableChange = (pagination, filters, sorter) => {
    let { query } = this.state;
    query = query.set('page', pagination.current);
    query = query.set('length', pagination.pageSize);
    this.setState({query}, () => {
      this.getModifyHistoryList();
    });
  };
  
  getModifyHistoryList = () => {
    let { page, length } = this.state.query.toJS();
    const { userId, operationType } = resolveUrlQuery(this.props.location.search);
    reaction.memberCenter.getOperationHistory({
      userId,
      page,
      length,
      operationType
    });
  };
  
  back = () => {
    let backPath = resolveUrlQuery(this.props.location.search).backPath;
    if (backPath) {
      historyPush(`${decodeURIComponent(backPath)}`);
    } else {
      historyPush(`/${RoutePath.MEMBERCENTER}/${RoutePath.CHECK_QUALIFICATION_LIST}`);
    }
  };
  
  getBusinessCharacter = () => {
    reaction.memberCenter.getBusinessCharacter();
  };
  
  getDepartmentDict = () => {
    reaction.common.getDepartmentDict();
  };
  
  componentDidMount () {
    this.getDepartmentDict();
    this.getModifyHistoryList();
    this.getAreaDict();
    this.getBusinessCharacter();
    this.getCompanyAuditHistoryDetail();
  }
  
  render () {
    const { getFieldDecorator } = this.props.form;
    let { departments } = this.props.common;
    let qualificationDetailInfo = this.props.memberCenter.companyAuditHistoryDetail;
    let modifyHistoryList = this.props.memberCenter.operationHistoryList;
    let businessCharacter = this.props.memberCenter.businessCharacter;
    let formItemLayout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 20 },
    };
    let columns = [{
      width: 112,
      title: '时间',
      dataIndex: 'updateTime',
      render: text => {
        if (!text) {
          return '';
        }
        return dayjs(text - 0).format('YYYY-MM-DD HH:mm:ss');
      }
    }, {
      width: 140,
      title: '操作人',
      dataIndex: 'operator'
    }, {
      title: '内容',
      dataIndex: 'updateContent',
      render: (text) => {
        return (<pre style={{maxWidth: '1000px'}}>
          { text.trim() }
        </pre>);
      }
    }];
    const hasCenpur = qualificationDetailInfo.purchaserCategory || qualificationDetailInfo.supplyCategory;

    return (<div>
      <h2 className={commonStyles.row}>审核历史</h2>
      <div style={{overflow: 'hidden'}}>
        <div style={{float: 'left', width: 100}}>账户信息</div>
        <div style={{marginLeft: 100}}>
          <div style={{marginBottom: 30}}>
            <label style={{padding: '0 10px 0 0', width: '16.6666666%', textAlign: 'right',display: 'inline-block'}}>会员编号:</label>
            <span style={{width: 250, lineHeight: '32px', display: 'inline-block'}}>{ qualificationDetailInfo.userNo }</span>
          </div>
          <div style={{marginBottom: 30}}>
            <label style={{padding: '0 10px 0 0', width: '16.6666666%', textAlign: 'right',display: 'inline-block'}}>用户名:</label>
            <span style={{width: 250, lineHeight: '32px', display: 'inline-block'}}>{ qualificationDetailInfo.username }</span>
          </div>
          <div style={{marginBottom: 30}}>
            <label style={{padding: '0 10px 0 0', width: '16.6666666%', textAlign: 'right',display: 'inline-block'}}>安全手机:</label>
            <span style={{width: 250, lineHeight: '32px', display: 'inline-block'}}>{ qualificationDetailInfo.phone }</span>
          </div>
          <Form>
            <FormItem {...formItemLayout} label="责任部门">
              <Select disabled={true} value={ qualificationDetailInfo.department || '' } style={{width: 250}}>
                {departments && departments.map((item) => {
                  return (<Option key={ item.code } value={ item.code || ''}>{ item.name }</Option>);
                })}
              </Select>
            </FormItem>
          </Form>
          <div style={{marginBottom: 30}}>
            <label style={{padding: '0 10px 0 0', width: '16.6666666%', textAlign: 'right',display: 'inline-block'}}>业务员:</label>
            <span style={{width: 250, lineHeight: '32px', display: 'inline-block'}}>{ qualificationDetailInfo.salesman }</span>
          </div>
        </div>
      </div>
      <div style={{overflow: 'hidden'}}>
        <div style={{float: 'left', width: 100}}>基础信息</div>
        <div style={{marginLeft: 100}}>
          <div style={{marginBottom: 30}}>
            <label style={{padding: '0 10px 0 0', width: '16.6666666%', textAlign: 'right',display: 'inline-block'}}>公司名称:</label>
            <span style={{width: 250, lineHeight: '32px', display: 'inline-block'}}>{ qualificationDetailInfo.companyName }</span>
          </div>
          <Form>
            <FormItem {...formItemLayout} label="公司英文名：">
              <span style={{width: 250, lineHeight: '32px', display: 'inline-block'}}>{ qualificationDetailInfo.companyNameEn }</span>
            </FormItem>
            <FormItem labelCol={{span: 16}} wrapperCol={{span: 8}} style={{width: '25%', display: 'inline-block'}} label="所在地：">
              <span style={{width: 450, lineHeight: '32px', display: 'inline-block'}}>{ qualificationDetailInfo.location }</span>
            </FormItem>
            <FormItem {...formItemLayout} label="受邀钢厂：">
              <span style={{width: 250, lineHeight: '32px', display: 'inline-block'}}>{ formatInviteSteelText(qualificationDetailInfo.invitedSteel) }</span>
            </FormItem>
            <FormItem labelCol={{span: 16}} wrapperCol={{span: 8}} style={{width: '25%', display: 'inline-block'}} label="认证目的：">
              <div style={{ lineHeight: '32px', width: 450, marginTop: 4 }}>{ qualificationDetailInfo.authPurpose }</div>
            </FormItem>
          </Form>
        </div>
      </div>
      <div style={{overflow: 'hidden'}}>
        <div style={{float: 'left', width: 100}}>联系方式</div>
        <div style={{marginLeft: 100}}>
          <Form>
            <FormItem {...formItemLayout} label="联系人：">
              <span style={{width: 250, lineHeight: '32px', display: 'inline-block'}}>{ qualificationDetailInfo.contactPerson }</span>
            </FormItem>
            <FormItem {...formItemLayout} label="联系电话：">
              <span style={{width: 250, lineHeight: '32px', display: 'inline-block'}}>{ qualificationDetailInfo.contactPhone }</span>
            </FormItem>
            <FormItem {...formItemLayout} label="QQ：">
              <span style={{width: 250, lineHeight: '32px', display: 'inline-block'}}>{ qualificationDetailInfo.qq }</span>
            </FormItem>
          </Form>
        </div>
      </div>
      <div style={{overflow: 'hidden'}}>
        <div style={{float: 'left', width: 100}}>证件附件</div>
        <div style={{marginLeft: 100}}>
          <div style={{marginBottom: 30, display: 'inline-block', width: '33.3333%'}}>
            <label style={{padding: '0 10px 0 0', width: '45%', textAlign: 'right',display: 'inline-block'}}>营业执照:</label>
            { qualificationDetailInfo.bizLicense ?
              <MagnifyEnableImg imgUrl={ qualificationDetailInfo.bizLicense } /> :
              <img style={{width: 80, height: 80}} src={ unuploadImg } alt=""/>
            }
          </div>
          <div style={{marginBottom: 30, display: 'inline-block', width: '33.3333%'}}>
            <label style={{padding: '0 10px 0 0', width: '45%', textAlign: 'right',display: 'inline-block'}}>组织机构代码:</label>
            { (qualificationDetailInfo.orgCode) ?
              <MagnifyEnableImg imgUrl={ qualificationDetailInfo.orgCode } /> :
              <img style={{width: 80, height: 80}} src={ unuploadImg } alt=""/>
            }
          </div>
          <div style={{marginBottom: 30, display: 'inline-block', width: '33.3333%'}}>
            <label style={{padding: '0 10px 0 0', width: '45%', textAlign: 'right',display: 'inline-block'}}>税务登记证:</label>
            { (qualificationDetailInfo.taxReg) ?
              <MagnifyEnableImg imgUrl={ qualificationDetailInfo.taxReg } /> :
              <img style={{width: 80, height: 80}} src={ unuploadImg } alt=""/>
            }
          </div>
          {
            hasCenpur ? (
              <div style={{marginBottom: 30}}>
                <label style={{padding: '0 10px 0 0', width: '16.6666666%', textAlign: 'right',display: 'inline-block'}}>开户许可证:</label>
                { (qualificationDetailInfo.accountLicense) ?
                  <MagnifyEnableImg imgUrl={ qualificationDetailInfo.accountLicense } /> :
                  <img style={{width: 80, height: 80}} src={ unuploadImg } alt=""/>
                }
              </div>
            ) : null
          }
          <div style={{marginBottom: 30}}>
            <label style={{padding: '0 10px 0 0', width: '16.6666666%', textAlign: 'right',display: 'inline-block'}}>委托书:</label>
            <RadioGroup disabled={ true } value={ qualificationDetailInfo.proxyType }>
              <Radio value={'1'}>企业授权委托书</Radio>
              <Radio value={'2'}>业务担保授权书</Radio>
              <Radio value={'3'}>无委托书，自愿不进行交易</Radio>
            </RadioGroup>
          </div>
          { qualificationDetailInfo.proxyType !== '3' &&
          <div style={{marginBottom: 30}}>
            <label style={{width: '16.6666666%', textAlign: 'right',display: 'inline-block'}}>委托书附件:</label>
            { (qualificationDetailInfo.proxy) ? qualificationDetailInfo.proxy.map((item) => {
              return <MagnifyEnableImg style={{marginLeft: 15}} imgUrl={ item } />;
            }) : <img style={{width: 80, height: 80}} src={ unuploadImg } alt=""/> }
          </div>
          }
        </div>
      </div>
      <div style={{overflow: 'hidden'}}>
        <div style={{float: 'left', width: 100}}>其他信息</div>
        <div style={{marginLeft: 100}}>
          <Form>
            <FormItem {...formItemLayout} label="外贸资质：">
              <RadioGroup disabled={ true } value={!!qualificationDetailInfo.hasForeignTrade}>
                <Radio value={true}>具备</Radio>
                <Radio value={false}>不具备</Radio>
              </RadioGroup>
            </FormItem>
            {
              (qualificationDetailInfo.hasForeignTrade) ? (
                <React.Fragment>
                  <div style={{marginBottom: 30}}>
                    <label style={{width: '16.6666666%', textAlign: 'right',display: 'inline-block'}}>对外贸易经营者备案登记表附件:</label>
                    { (qualificationDetailInfo.foreignTradeRegistration) ?
                      <MagnifyEnableImg style={{marginLeft: 15}} imgUrl={ qualificationDetailInfo.foreignTradeRegistration } /> :
                      <img style={{width: 80, height: 80, marginLeft: 15}} src={ unuploadImg } alt=""/>
                    }
                  </div>
                  <div style={{marginBottom: 30}}>
                    <label style={{width: '16.6666666%', textAlign: 'right',display: 'inline-block'}}>进出口企业代码号:</label>
                    <span style={{width: 250, lineHeight: '32px', display: 'inline-block'}}>{ qualificationDetailInfo.foreignTradeEnterpriseCode }</span>
                  </div>
                </React.Fragment>
              ) : null
            }
          </Form>
        </div>
      </div>
      <div style={{overflow: 'hidden'}}>
        <div style={{float: 'left', width: 100}}>审核补录信息</div>
        <div style={{marginLeft: 100}}>
          <Form>
            <FormItem {...formItemLayout} label="经营性质：">
              {getFieldDecorator('proprietaryDealer', {
                rules: [{
                  required: false,
                  message: '请选择经营性质'
                }],
                initialValue: qualificationDetailInfo.proprietaryDealer
              })(
                <Select style={{width: 250}} disabled={ true }>
                  { businessCharacter.map((item) => {
                    return (<Option value={ item.code }>{ item.name }</Option>);
                  }) }
                </Select>
              )}
            </FormItem>
            <FormItem {...formItemLayout} label="公司性质：">
              <span style={{width: 250, lineHeight: '32px', display: 'inline-block'}}>{ qualificationDetailInfo.compCharacter }</span>
            </FormItem>
            <FormItem {...formItemLayout} label="公司经营范围：">
              <TextArea value={qualificationDetailInfo.compBizCope} rows={ 4 } disabled={ true } style={{width: 250}} placeholder=""/>
            </FormItem>
            <FormItem {...formItemLayout} label="法人姓名：">
              <span style={{width: 250, lineHeight: '32px', display: 'inline-block'}}>{ qualificationDetailInfo.legalPerson }</span>
            </FormItem>
            <div style={{marginBottom: 30}}>
              <label style={{width: '16.6666666%', textAlign: 'right',display: 'inline-block'}}>法人身份证附件:</label>
              { (qualificationDetailInfo.legalPersonIdFront) ?
                <MagnifyEnableImg imgUrl={ qualificationDetailInfo.legalPersonIdFront } /> :
                <img style={{width: 80, height: 80}} src={ unuploadImg } alt=""/>
              }
              { (qualificationDetailInfo.legalPersonIdBack) ?
                <MagnifyEnableImg imgUrl={ qualificationDetailInfo.legalPersonIdBack } /> :
                <img style={{width: 80, height: 80, marginLeft: 10}} src={ unuploadImg } alt=""/>
              }
            </div>
            <div style={{marginBottom: 30}}>
              <label style={{width: '16.6666666%', textAlign: 'right',display: 'inline-block'}}>营业执照期限:</label>
              <span>{ qualificationDetailInfo.licenseExpiration && dayjs(qualificationDetailInfo.licenseExpiration).format('YYYY-MM-DD') }</span>
            </div>
            <FormItem {...formItemLayout} label="社会统一信用代码：">
              <span style={{width: 250, lineHeight: '32px', display: 'inline-block'}}>{ qualificationDetailInfo.unifiedSocialCreditCode }</span>
            </FormItem>
            <FormItem {...formItemLayout} label="委托人姓名：">
              <span style={{width: 250, lineHeight: '32px', display: 'inline-block'}}>{ qualificationDetailInfo.agentName }</span>
            </FormItem>
            {
              hasCenpur ? (
                <React.Fragment>
                  <FormItem {...formItemLayout} label="开户许可证核准号：">
                    <span style={{width: 250, lineHeight: '32px', display: 'inline-block'}}>{ qualificationDetailInfo.accountLicenseNo }</span>
                  </FormItem>
                  <FormItem {...formItemLayout} label="开户行：">
                    <span style={{width: 250, lineHeight: '32px', display: 'inline-block'}}>{ qualificationDetailInfo.openBank }</span>
                  </FormItem>
                  <FormItem {...formItemLayout} label="户名：">
                    <span style={{width: 250, lineHeight: '32px', display: 'inline-block'}}>{ qualificationDetailInfo.accountName }</span>
                  </FormItem>
                  <FormItem {...formItemLayout} label="帐号：">
                    <span style={{width: 250, lineHeight: '32px', display: 'inline-block'}}>{ qualificationDetailInfo.accountNo }</span>
                  </FormItem>
                </React.Fragment>
              ) : null
            }
            <FormItem {...formItemLayout} label="委托人电话：">
              <span style={{width: 250, lineHeight: '32px', display: 'inline-block'}}>{ qualificationDetailInfo.agentPhone }</span>
            </FormItem>
            <div style={{marginBottom: 30, overflow: 'hidden'}}>
              <label style={{width: '16.66666666%', textAlign: 'right', display: 'inline-block', float: 'left'}}>委托人身份证附件：</label>
              { (qualificationDetailInfo.agentIdFront) ?
                <MagnifyEnableImg imgUrl={ qualificationDetailInfo.agentIdFront } /> :
                <img style={{width: 80, height: 80}} src={ unuploadImg } alt=""/>
              }
              { (qualificationDetailInfo.agentIdBack) ?
                <MagnifyEnableImg imgUrl={ qualificationDetailInfo.agentIdBack } /> :
                <img style={{width: 80, height: 80, marginLeft: 10}} src={ unuploadImg } alt=""/>
              }
            </div>
          </Form>
        </div>
      </div>
      <div style={{overflow: 'hidden'}}>
        <div style={{float: 'left', width: 100}}>审核信息</div>
        <div style={{marginLeft: 100}}>
          <div style={{marginBottom: 30}}>
            <label style={{padding: '0 10px 0 0', width: '16.6666666%', textAlign: 'right',display: 'inline-block'}}>审核结果:</label>
            <span style={{width: 250, lineHeight: '32px', display: 'inline-block'}}>{ qualificationDetailInfo.auditResult }</span>
          </div>
          <div style={{marginBottom: 30}}>
            <label style={{padding: '0 10px 0 0', width: '16.6666666%', textAlign: 'right',display: 'inline-block'}}>审核意见:</label>
            <span style={{width: 250, lineHeight: '32px', display: 'inline-block'}}>{ qualificationDetailInfo.auditOpinion }</span>
          </div>
        </div>
      </div>
      <div style={{padding: '10px 0 50px 150px'}}>
        <Button onClick={ this.back } style={{marginLeft: 10}}>返回</Button>
      </div>
      
      <div style={{padding: '0 0 30px 0'}}>
        { modifyHistoryList && <Table
          className={commonStyles.row}
          rowKey="uid"
          columns={ columns }
          pagination={{ current: modifyHistoryList.pagerInfo.page, total: modifyHistoryList.pagerInfo.total }}
          onChange={ this.handleTableChange }
          dataSource={ modifyHistoryList.result }/>
        }
      </div>
      
      <Modal
        title="查看大图"
        width={ this.state.imgWidth + 48 }
        visible={ this.state.showBigImg }
        onOk={ this.closeBigImgModal }
        onCancel={ this.closeBigImgModal }>
        <img src={ this.state.bigImgUrl } alt=""/>
      </Modal>
    </div>);
  }
}

export default ViewCompanyAuditDetail;