import createUID from 'utils/createUID';
import http from 'utils/http';

const LIST_PRICE_TREND = createUID();

const initialState = {
  priceTrendList: []
    // pagerInfo: {page: 1, total: 0},
    // result: []
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case LIST_PRICE_TREND:
      return Object.assign({}, state, {priceTrendList: action.data});
    default:
      return state;
  }
}

Object.assign(reducer, {
  //加载所有的价格
  loadPriceTrend() {
    return dispatch => {
      return http
        .request('v1.screen.listPriceTrend')
        .then(resp => {
           dispatch({ type: LIST_PRICE_TREND, data: resp.data === null ? [] : resp.data});
          return resp;
        });
    };
  },
  
  exportData () {
    location.href = `/api/v1/screen/exportPriceTrend?token=${uamsdk.getToken()}`;
  },

  //添加一条价格
  addPriceTrendItem({date,twistedSteel,structuralSteel,mediumPlate,rollingPlate,elevatedSteel} ){
    return dispatch => {
      return http.request('v1.screen.addPriceTrendItem',{date,twistedSteel,structuralSteel,mediumPlate,rollingPlate,elevatedSteel});
    };
  },

  //删除一条价格，以日期删除
  removePriceTrendItem(id){
    return dispatch => {
      return http.request('v1.screen.deletePriceTrendItem',id);
    };
  }
});
export default reducer;
