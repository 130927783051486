import React from 'react';
import Immutable from 'immutable';
// import { Link } from 'react-router';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Table, Button, Popconfirm, message, Input, Form } from 'antd';

import FormItem from 'components/FormItem';

import reaction from 'reaction';
// import history from 'utils/history';
import deepLoop from 'utils/deepLoop';
import { RoutePath, HttpCode, AuthCodes } from 'utils/constants';
import { transformParamsToUrl, getUniqTableRowKey, renderListControls } from 'utils';

import commonStyles from 'style/common.module.scss';

/**
 * 授权管理
 */
@connect(({ authManagement, me }) => ({ authManagement, me }))
@withRouter
class AuthManagementList extends React.Component {
  state = {
    query: Immutable.Map({
      authId: '',
      authCode: '',
      fullName: '',
      displayName: '',
      requestPath: '',
    }),
    currentPage: 1,
    pageSize: 10,
  };
  componentDidMount() {
    this.getAuthList();
  }
  getAuthList = () => {
    let { roleAuthList } = this.props.me.roleInfo;
    let listEnbaled = roleAuthList.some((item) => {
      return item.authCode.indexOf(AuthCodes.SYSTEM_AUTH_LIST) !== -1;
    });
    let params = {
      ...this.state.query.toJS(),
    };
    listEnbaled && reaction.authManagement.getAuthList(params);
  };
  handleTableChange = (pagination, filters, sorter) => {
    this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
  };

  search = () => {
    let { query } = this.state;
    this.setState({
      currentPage: 1,
    });
    this.setState({ query }, () => {
      this.getAuthList();
    });
  };

  onQueryChange = (name, value) => {
    let { query } = this.state;
    query = query.set(name, value);
    this.setState({ query }, () => {
      // this.getDictList();
    });
  };

  goEdit = (record, e) => {
    this.props.history.push({
      pathname: `/${RoutePath.SYSTEM}/${RoutePath.AUTH}/${RoutePath.MODIFY}`,
      search: transformParamsToUrl({ authId: record.authId }),
    });
  };

  doAdd = (e) => {
    this.props.history.push(`/${RoutePath.SYSTEM}/${RoutePath.AUTH}/${RoutePath.ADD}`);
  };
  doDelete = (record, e) => {
    reaction.authManagement.deleteAuth(record.authId).then((data) => {
      if (data.code === HttpCode.OK) {
        message.success('删除成功！');
        // reaction.common.getMenuDict(); // 刷新角色字典
        this.getAuthList();
      }
    });
  };

  getColumns({ editEnbaled, deleteEnbaled }) {
    return [
      {
        title: '序号',
        width: 80,
        fixed: 'left',
        key: 'number',
        render: (_, __, index) => {
          return (this.state.currentPage - 1) * 10 + index + 1;
        },
      },
      // {
      //   title: 'ID',
      //   width: '10%',
      //   dataIndex: 'authId',
      // },
      {
        title: '授权码',
        width: '10%',
        dataIndex: 'authCode',
      },
      {
        title: '权限名',
        dataIndex: 'fullName',
      },
      {
        title: '展示名',
        dataIndex: 'displayName',
      },
      {
        title: '权限地址',
        dataIndex: 'requestPath',
      },
      {
        title: '操作',
        width: '20%',
        key: 'handler',
        render: (text, record) => (
          <Button.Group>
            <Button
              icon="edit"
              onClick={this.goEdit.bind(this, record)}
              disabled={!editEnbaled}
            >
              修改
            </Button>
            {deleteEnbaled ? (
              <Popconfirm
                title="确定要删除？"
                onConfirm={this.doDelete.bind(this, record)}
                okText="确定"
                cancelText="取消"
              >
                <Button icon="delete" type="danger">
                  删除
                </Button>
              </Popconfirm>
            ) : (
              <Button icon="delete" type="danger" disabled>
                删除
              </Button>
            )}
          </Button.Group>
        ),
      },
    ]
  }
  render() {
    let { roleAuthList } = this.props.me.roleInfo;
    let addEnbaled = roleAuthList.some((item) => {
      return (item.authCode.indexOf(AuthCodes.SYSTEM_AUTH_ADD) !== -1);;
    });
    let deleteEnbaled = roleAuthList.some((item) => {
      return (item.authCode.indexOf(AuthCodes.SYSTEM_AUTH_DELETE) !== -1);
    });
    let editEnbaled = roleAuthList.some((item) => {
      return (item.authCode.indexOf(AuthCodes.SYSTEM_AUTH_UPDATE) !== -1);
    });
    const { list } = this.props.authManagement;
    let { query, currentPage } = this.state;
    const columns = this.getColumns({ editEnbaled, deleteEnbaled })

    list &&
      deepLoop.deepLoop(list.result, function(item) {
        item.key = item.authCode;
      });
    return (
      <div className="user">
        <h2 className={commonStyles.row}>授权管理</h2>
        {/* <div className={commonStyles.row}>
          <Button icon="plus" onClick={this.doAdd} disabled={!addEnbaled}>
            新增
          </Button>
        </div> */}
        <Form layout="inline" className="form-inline">
          {
            renderListControls([
              // <FormItem label="ID">
              //   <Input
              //     value={query.toJS().authId}
              //     onPressEnter={this.onPressEnter}
              //     onChange={(e) => {
              //       this.onQueryChange('authId', e.target.value);
              //     }}
              //     // style={{ width: '250px', margin: '10px 0 0 10px' }}
              //     // addonBefore="ID"
              //     defaultValue=""
              //     placeholder="输入ID"
              //   />
              // </FormItem>,
              <FormItem label="授权码">
                <Input
                  value={query.toJS().authCode}
                  onPressEnter={this.onPressEnter}
                  onChange={(e) => {
                    this.onQueryChange('authCode', e.target.value);
                  }}
                  // style={{ width: '250px', margin: '10px 0 0 10px' }}
                  // addonBefore="授权码"
                  defaultValue=""
                  placeholder="输入授权码"
                />
              </FormItem>,
              <FormItem label="权限名">
                <Input
                  value={query.toJS().fullName}
                  onPressEnter={this.onPressEnter}
                  onChange={(e) => {
                    this.onQueryChange('fullName', e.target.value);
                  }}
                  // style={{ width: '250px', margin: '10px 0 0 10px' }}
                  // addonBefore="权限名"
                  defaultValue=""
                  placeholder="输入权限名"
                />
              </FormItem>,
              <FormItem label="展示名">
                <Input
                  value={query.toJS().displayName}
                  onPressEnter={this.onPressEnter}
                  onChange={(e) => {
                    this.onQueryChange('displayName', e.target.value);
                  }}
                  // style={{ width: '250px', margin: '10px 0 0 10px' }}
                  // addonBefore="展示名"
                  defaultValue=""
                  placeholder="输入展示名"
                />
              </FormItem>,
              <FormItem label="权限地址">
                <Input
                  value={query.toJS().requestPath}
                  onPressEnter={this.onPressEnter}
                  onChange={(e) => {
                    this.onQueryChange('requestPath', e.target.value);
                  }}
                  // style={{ width: '250px', margin: '10px 0 0 10px' }}
                  // addonBefore="权限地址"
                  defaultValue=""
                  placeholder="输入权限地址"
                />
              </FormItem>,
              {
                colProps: {
                  xxl: 24,
                  xl: 16,
                  md: 24,
                  lg: 24,
                },
                children: (
                  <FormItem align="right">
                    <Button icon="search" type="primary" onClick={this.search}>
                      查询
                    </Button>
                    <Button style={{ margin: '0 0 0 5px' }} type="primary" icon="plus" onClick={this.doAdd} disabled={!addEnbaled}>
                      新增
                    </Button>
                  </FormItem>
                )
              }
            ])
          }
        </Form>
        <div style={{ padding: '10px 0' }}>
          {list && (
            <Table
              rowKey={getUniqTableRowKey('authId')}
              columns={columns}
              dataSource={list.result}
              pagination={{ current: currentPage, showQuickJumper: true, showTotal: (total) => `共${total}条`, }}
              onChange={this.handleTableChange}
            />
          )}
        </div>
      </div>
    );
  }
}

export default AuthManagementList;
