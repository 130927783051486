import React from 'react';
import { connect } from 'react-redux';
import {
  Form,
  Select,
  Input,
  Button,
  message,
  Row,
  Col,
  Alert,
  Modal,
} from 'antd';
import reaction from 'reaction';
import { HttpCode, UserStatus } from 'utils/constants';
import { Link, withRouter } from 'react-router-dom';

import commonStyles from 'style/common.module.scss';

const FormItem = Form.Item;
const Option = Select.Option;
/**
 * 后台用户新增
 */
@Form.create()
@connect(({ common }) => ({ common }))
@withRouter
class SystemUserAdd extends React.Component {
  state = {
    userType: 'new',
  };
  handleSubmit = (e) => {
    e && e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        // let {
        //   phone,
        //   ...data
        // } = values;

        Modal.confirm({
          title: '提示',
          content: '确定提交用户信息吗？',
          onOk: () => {
            reaction.backendUser
            .create(values)
            .then((data) => {
              if (data.code === HttpCode.OK) {
                message.success('添加成功！');
                this.handleGoBack();
              }
            });
          }
        })
      }
    });
  };
  handleAutoCompleteChange = (value) => {
    let index = value.indexOf('@');
    let emailName = index === -1 ? value : value.substring(0, index);

    if (emailName.trim() !== '') {
      this.setState({
        options: [`${emailName}@test.zlgx.com`, `${emailName}@zlgx.com`],
      });
    } else {
      this.setState({
        options: null,
      });
    }
  };
  handleGetUserInfoByPhone = (ev) => {
    const phone = ev.target.value;

    if (!/^1\d{10}$/.test(phone)) {
      return ;
    }
    reaction.backendUser.getUserInfoByPhone(phone)
      .then(res => {
        if (res.code === 0 && res.data) {
          const { realName, userUuid, userName } = res.data;
          this.props.form.setFieldsValue({
            realName,
            userUuid,
            userName,
          });
        } else {
          this.props.form.setFieldsValue({
            realName: '',
          });
        }
      })
  };
  handleValidateMobile = (rule, value, callback) => {
    if (!value) {
      callback();
    }
    if (/^1\d{10}$/.test(value)) {
      reaction.backendUser.getUserInfoByPhone(value)
      .then(res => {
        if(res.code === 0 && res.data) {
          callback();
        } else if (res.code !== 0) {
          callback(res.msg)
        } else {
          callback('用户不存在');
        }
      })
    } else {
      callback('手机号格式不正确');
    }
  }
  resetForm = () => {
    this.props.form.resetFields();
  };
  handleGoBack = () => {
    this.props.history.push('/system/user');
  };
  render() {
    const { common: { roleDict }, form: { getFieldDecorator } } = this.props;

    return (
      <div className={commonStyles.page}>
        <div className={commonStyles['page__header']}>
          <h2 className={commonStyles.row}>用户新增</h2>
          <Alert
            className={commonStyles['page__header--tips']}
            type="info"
            description={(
              <>
                注:若当前角色权限不足，可前往<Link to="/system/role">角色管理&gt;&gt;</Link>新增或修改角色权限。
              </>
            )}
          />
        </div>
        <Form onSubmit={this.handleSubmit} className={commonStyles.row}>
          {getFieldDecorator('userUuid')(
            <Input hidden />
          )}
          {getFieldDecorator('userName')(
            <Input hidden />
          )}
          {getFieldDecorator('realName')(
            <Input hidden />
          )}
          <FormItem
            label="手机号"
            labelCol={{ span: 8 }}
            wrapperCol={{ sm: 8, xs: 24 }}
          >
            {getFieldDecorator('phone', {
              rules: [
                {
                  required: true,
                  message: '请输入手机号'
                },
                {
                  validator: this.handleValidateMobile,
                }
              ],
            })(<Input tabIndex="1" maxLength={11} placeholder="手机号" onBlur={this.handleGetUserInfoByPhone} />)}
          </FormItem>
          <FormItem
            label="姓名"
            labelCol={{ span: 8 }}
            wrapperCol={{ sm: 8, xs: 24 }}
          >
            {getFieldDecorator('realName', {
              validateTrigger: ['onBlur', 'onChange'],
              rules: [
                // { required: true, whitespace: true, message: '请输入姓名!' },
                {
                  type: 'string',
                  pattern: /^\S.{0,28}\S$/,
                  message: '姓名长度为2到30位!',
                },
              ],
            })(<Input className={commonStyles['input--text']} readOnly />)}
          </FormItem>
          <FormItem
            label="用户角色"
            labelCol={{ span: 8 }}
            wrapperCol={{ sm: 8, xs: 24 }}
          >
            {getFieldDecorator('role', {
              rules: [{ required: true, message: '请选择用户角色!' }],
              onChange: this.handleSelectChange,
            })(
              <Select tabIndex="2" mode="multiple" placeholder="用户角色" showSearch optionFilterProp="children">
                {roleDict &&
                  Object.keys(roleDict).map((id) => {
                    return <Option key={`${id}`}>{roleDict[id]}</Option>;
                  })}
              </Select>
            )}
          </FormItem>
          <FormItem
            wrapperCol={{ sm: { span: 8, offset: 8 }, xs: { span: 8 } }}
          >
            <Row>
              <Col span={8}>
                <Button onClick={this.resetForm}>重置</Button>
              </Col>
              <Col span={8}>
                <Button type="primary" htmlType="submit">
                  确定
                </Button>
              </Col>
              <Col span={8}>
                <Button onClick={this.handleGoBack}>返回</Button>
              </Col>
            </Row>
          </FormItem>
        </Form>
      </div>
    );
  }
}

export default SystemUserAdd;
