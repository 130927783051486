import http from 'utils/http';
import createUID from 'utils/createUID';

const LIST = createUID();

const initialState = {

};

let host = 'https://report-data.zlgx.com';
if (location.hostname.indexOf('dev') !== -1 || location.hostname.indexOf('test') !== -1 || location.hostname.indexOf('localhost') !== -1) {
  host = 'http://report-data.dev.zlgx.com';
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case LIST:
      return Object.assign({}, state, { list: action.data });
    default:
      return state;
  }
}

Object.assign(reducer, {
  uploadWeeklyData (params) {
    return dispatch => {
      return http
        .request(`${host}/v1/api/week-data/upload`, params)
        .then(data => {
          return data;
        });
    };
  },
  getDepartmentDict (companyName) {
    return dispatch => {
      return http
        .request(`${host}/v1/api/company/query-trade-steel-mills`, {
          companyName
        }).then(data => {
          return data;
        });
    };
  },
  getAreaAndDepartment () {
    return dispatch => {
      return http
        .get(`${host}/v1/api/area-dept`).then(data => {
          return data;
        });
    };
  },
  getDetailData (time) {
    return dispatch => {
      return http
        .get(`${host}/v1/api/week-data/detail`, {
          'week-time': time
        }).then(data => {
          return data;
        });
    };
  }
});

export default reducer;
