import React from 'react';
import { connect } from 'react-redux';
import { RoutePath, AuthCodes } from 'utils/constants';
import { historyPush } from 'utils/history';
/**
 * 系统管理
 */
@connect(({ common, me }) => ({ common, me }))
class ManagementOfMemberFunds extends React.Component {
  componentDidMount() {
    let { roleAuthList } = this.props.me.roleInfo;
    let memberAccountListEnabled = roleAuthList.some((item) => {
      return item.authCode.indexOf(AuthCodes.FUNDS_MEMBER_ACCOUNT_LIST) !== -1;
    });
    let detailEnabled = roleAuthList.some((item) => {
      return item.authCode.indexOf(AuthCodes.FUNDS_MANAGEMENT_DETAIL) !== -1;
    });
    let expenditureEnabled = roleAuthList.some((item) => {
      return item.authCode.indexOf(AuthCodes.FUNDS_MANAGEMENT_EXPENDITURE_CARDS) !== -1;
    });
    let newAccountEnabled = roleAuthList.some((item) => {
      return item.authCode.indexOf(AuthCodes.FUNDS_MANAGEMENT_CREATE_ACCOUNT) !== -1;
    });
    if (memberAccountListEnabled) {
      historyPush(
        `/${RoutePath.FUNDS}/${RoutePath.FUNDS_MEMBER_MANAGEMENT}/${RoutePath.FUNDS_MEMBER_ACCOUNT_LIST}`
      );
    } else if (detailEnabled) {
      historyPush(
        `/${RoutePath.FUNDS}/${RoutePath.FUNDS_MEMBER_MANAGEMENT}/${RoutePath.FUNDS_MEMBER_ACCOUNT_DETAIL}`
      );
    } else if (expenditureEnabled) {
      historyPush(
        `/${RoutePath.FUNDS}/${RoutePath.FUNDS_MEMBER_MANAGEMENT}/${RoutePath.FUNDS_MEMBER_EXPENDITURE_CARD}`
      );
    } else if (newAccountEnabled) {
      historyPush(
        `/${RoutePath.FUNDS}/${RoutePath.FUNDS_MEMBER_MANAGEMENT}/${RoutePath.NEW_FUNDS_MEMBER_ACCOUNT_LIST}`
      );
    } else {
      historyPush(RoutePath.INDEX);
    }
  }
  render() {
    return null;
  }
}
export default ManagementOfMemberFunds;
