import React from 'react';
import { connect } from 'react-redux';
import { Form, Row, Col, Input, Button, Select, message } from 'antd';
import reaction from 'reaction';
import { historyPush } from 'utils/history';
import { HttpCode, RoutePath } from 'utils/constants';
import { resolveUrlQuery } from 'utils';

import commonStyles from 'style/common.module.scss';

const FormItem = Form.Item;
const Option = Select.Option;
/**
 * 黑名单修改
 */
@connect(({ blacklist, common }) => ({ blacklist, common }))
@Form.create()
class BlacklistUserModify extends React.Component {
  componentDidMount() {
    const { uid } = resolveUrlQuery(this.props.location.search);
    reaction.blacklist.getDetail(uid);
  }
  handleSubmit = (e) => {
    e && e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let { forbidPlatformId } = values;
        const { uid } = resolveUrlQuery(this.props.location.search);
        reaction.blacklist.update(uid, forbidPlatformId).then((data) => {
          if (data.code === HttpCode.OK) {
            message.success('修改成功！');
            historyPush(`/${RoutePath.PERMISSION}`);
          }
        });
      }
    });
  };
  render() {
    const { platformDict } = this.props.common;
    const { getFieldDecorator } = this.props.form;
    const { detail } = this.props.blacklist;

    return (
      !!detail && (
        <div>
          <h2 className={commonStyles.row}>黑名单修改</h2>
          <Form onSubmit={this.handleSubmit} className={commonStyles.row}>
            <FormItem
              label="用户名"
              labelCol={{ span: 8 }}
              wrapperCol={{ sm: 8, xs: 24 }}
            >
              {detail.userName}
            </FormItem>
            <FormItem
              label="手机号"
              labelCol={{ span: 8 }}
              wrapperCol={{ sm: 8, xs: 24 }}
            >
              {detail.phone}
            </FormItem>
            <FormItem
              label="邮箱"
              labelCol={{ span: 8 }}
              wrapperCol={{ sm: 8, xs: 24 }}
            >
              {detail.email}
            </FormItem>
            <FormItem
              label="不可操作业务系统"
              labelCol={{ span: 8 }}
              wrapperCol={{ sm: 8, xs: 24 }}
            >
              {getFieldDecorator('forbidPlatformId', {
                validateTrigger: ['onBlur', 'onChange'],
                initialValue: detail.forbidPlatform.split(','),
                rules: [{ required: true, message: '请选择不可操作业务系统!' }],
                onChange: this.handleSelectChange,
              })(
                <Select multiple placeholder="不可操作业务系统">
                  {platformDict &&
                    Object.keys(platformDict).map((id) => {
                      return <Option key={`${id}`}>{platformDict[id]}</Option>;
                    })}
                </Select>
              )}
            </FormItem>
            <FormItem
              wrapperCol={{ sm: { span: 8, offset: 8 }, xs: { span: 8 } }}
            >
              <Button type="primary" htmlType="submit">
                确定
              </Button>
            </FormItem>
          </Form>
        </div>
      )
    );
  }
}

export default BlacklistUserModify;
