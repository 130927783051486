import React from 'react';
import { connect } from 'react-redux';
import reaction from 'reaction/index';
import Immutable from 'immutable';
import { Button, Input, Table, Modal, message, Form } from 'antd';

import FormItem from 'components/FormItem';

import { AuthCodes, RoutePath } from 'utils/constants';
import dayjs from 'dayjs';
import { historyPush } from 'utils/history';
import { withRouter } from 'react-router-dom';
import history from 'utils/history';
import { transformParamsToUrl, renderListControls } from 'utils';

import commonStyles from 'style/common.module.scss';
const confirm = Modal.confirm;

@connect(({ DataDict, me }) => ({ DataDict, me }))
@withRouter
class DataDictList extends React.Component {
  state = {
    query: Immutable.Map({
      description: '',
      code: '',
      name: '',
      page: 1,
      length: 10,
    }),
    currentRow: null,
  };

  getDictList = () => {
    let query = this.state.query.toJS();
    reaction.DataDict.getDataDictList(query);
  };

  onQueryChange = (name, value) => {
    let { query } = this.state;
    query = query.set(name, value);
    this.setState({ query }, () => {
      // this.getDictList();
    });
  };

  handleTableChange = (pagination, filters, sorter) => {
    let { query } = this.state;
    query = query.set('page', pagination.current);
    query = query.set('length', pagination.pageSize);
    this.setState({ query }, () => {
      this.getDictList();
    });
  };

  search = () => {
    let { query } = this.state;
    query = query.set('page', 1);
    this.setState({ query }, () => {
      this.getDictList();
    });
  };

  goToView = (p) => {
    this.props.history.push({
      pathname: `/${RoutePath.SYSTEM}/${RoutePath.DICT}/${RoutePath.DETAIL}`,
      search: transformParamsToUrl({
        id: p.id,
      }),
    });
  };

  goToAddDict = () => {
    this.props.history.push(`/${RoutePath.SYSTEM}/${RoutePath.DICT}/${RoutePath.ADD}`);
  };

  goToEdit = (p) => {
    this.props.history.push({
      pathname: `/${RoutePath.SYSTEM}/${RoutePath.DICT}/${RoutePath.MODIFY}`,
      search: transformParamsToUrl({
        id: p.id,
      }),
    });
  };

  showDeleteDialog = (row) => {
    let _this = this;
    this.setState({ currentRow: row });
    confirm({
      title: '确定删除该字典?',
      content: '删除后将不可恢复,请谨慎操作！',
      onOk() {
        _this.deleteDict();
      },
      onCancel() {},
      okText: '确定',
      cancelText: '取消',
    });
  };

  deleteDict = () => {
    let currentRow = this.state.currentRow;
    reaction.DataDict.deleteDict(currentRow.id).then((res) => {
      if (res.code === 0) {
        message.success('删除成功！');
        this.getDictList();
      }
    });
  };

  onPressEnter = () => {
    this.search();
  };

  componentDidMount() {
    this.getDictList();
  }

  render() {
    let { roleAuthList } = this.props.me.roleInfo;
    let dataDictListEnabled = roleAuthList.some((item) => {
      if (item.authCode.indexOf(AuthCodes.SYSTEM_DICT_TYPE_LIST) !== -1)
        return true;
    });
    let dataDictViewEnabled = roleAuthList.some((item) => {
      if (item.authCode.indexOf(AuthCodes.SYSTEM_DICT_DICT_AND_TYPE_GET) !== -1)
        return true;
    });
    let dataDictUpdateEnabled = roleAuthList.some((item) => {
      if (item.authCode.indexOf(AuthCodes.SYSTEM_DICT_TYPE_UPDATE) !== -1)
        return true;
    });
    let dataDictDeleteEnabled = roleAuthList.some((item) => {
      if (item.authCode.indexOf(AuthCodes.SYSTEM_DICT_TYPE_DELETE) !== -1)
        return true;
    });
    if (!dataDictListEnabled) {
      historyPush(RoutePath.INDEX);
      return;
    }

    let { list } = this.props.DataDict;
    let { query } = this.state;
    let columns = [
      {
        title: '序号',
        dataIndex: 'serialNo',
        render: (text, record, index) =>
          (list.pagerInfo.page - 1) * this.state.query.toJS().length +
          index +
          1,
      },
      {
        title: '类型',
        dataIndex: 'name',
      },
      {
        title: 'code',
        dataIndex: 'code',
      },
      {
        title: '描述',
        dataIndex: 'description',
      },
      {
        title: '创建时间',
        dataIndex: 'createTime',
        render: (text) => {
          if (!text) {
            return '';
          }
          return dayjs(text - 0).format('YYYY-MM-DD HH:mm:ss');
        },
      },
      {
        title: '最后更新时间',
        dataIndex: 'lastModifiedTime',
        render: (text) => {
          if (!text) {
            return '';
          }
          return dayjs(text - 0).format('YYYY-MM-DD HH:mm:ss');
        },
      },
      {
        width: 250,
        title: '操作',
        key: 'handler',
        render: (row) => {
          return (
            <div>
              {dataDictViewEnabled ? (
                <Button
                  type="primary"
                  onClick={() => {
                    this.goToView(row);
                  }}
                >
                  查看
                </Button>
              ) : (
                ''
              )}
              {dataDictUpdateEnabled ? (
                <Button
                  style={{ marginLeft: 10 }}
                  type="primary"
                  onClick={() => {
                    this.goToEdit(row);
                  }}
                >
                  编辑
                </Button>
              ) : (
                ''
              )}
              {dataDictDeleteEnabled ? (
                <Button
                  style={{ marginLeft: 10 }}
                  type="danger"
                  onClick={() => {
                    this.showDeleteDialog(row);
                  }}
                >
                  删除
                </Button>
              ) : (
                ''
              )}
            </div>
          );
        },
      },
    ];

    return (
      <div>
        <h4 style={{ overflow: 'hidden' }}>
          <span>数据字典</span>
        </h4>
        <Form layout="inline">
          {
            renderListControls([
              <FormItem label="类型">
                <Input
                  value={query.toJS().name}
                  onPressEnter={this.onPressEnter}
                  onChange={(e) => {
                    this.onQueryChange('name', e.target.value);
                  }}
                  // style={{ width: '250px', marginLeft: '15px' }}
                  // addonBefore="类型"
                  defaultValue=""
                  placeholder="输入数据类型"
                />
              </FormItem>,
              <FormItem label="code">
                <Input
                  value={query.toJS().code}
                  onPressEnter={this.onPressEnter}
                  onChange={(e) => {
                    this.onQueryChange('code', e.target.value);
                  }}
                  // style={{ width: '250px', marginLeft: '15px' }}
                  // addonBefore="code"
                  defaultValue=""
                  placeholder="输入code"
                />
              </FormItem>,
              <FormItem label="描述">
                <Input
                  value={query.toJS().description}
                  onPressEnter={this.onPressEnter}
                  onChange={(e) => {
                    this.onQueryChange('description', e.target.value);
                  }}
                  // style={{ width: '250px', marginLeft: '15px' }}
                  // addonBefore="描述"
                  defaultValue=""
                  placeholder="输入描述"
                />
              </FormItem>,
              {
                colProps: {
                  xl: 24,
                },
                children: (
                  <FormItem align="right">
                    <Button icon="search" type="primary" onClick={this.search}>
                      查询
                    </Button>
                    <Button style={{ marginLeft: 5 }} type="primary" icon="plus" onClick={this.goToAddDict}>
                      创建新字典
                    </Button>
                  </FormItem>
                )
              }
            ])
          }
        </Form>
        <div style={{ padding: '20px 0 0 0' }}>
          {
            <Table
              className={commonStyles.row}
              rowKey="id"
              columns={columns}
              dataSource={list ? list.result : []}
              pagination={
                list
                  ? {
                      current: list.pagerInfo.page,
                      total: list.pagerInfo.total,
                      pageSizeOptions: ['10', '20', '50'],
                      showSizeChanger: true,
                      showQuickJumper: true,
                      showTotal: (total) => `共${total}条`,
                    }
                  : false
              }
              onChange={this.handleTableChange}
            />
          }
        </div>
      </div>
    );
  }
}
export default DataDictList;
