import React from 'react';

import styles from './style.module.scss';

import step1 from 'assets/steps/step_print_1.png';
import step2 from 'assets/steps/step_print_2.png';
import step3 from 'assets/steps/step_print_3.png';
import step4 from 'assets/steps/step_print_4.png';
import step5 from 'assets/steps/step_print_5.png';
import step6 from 'assets/steps/step_print_6.png';

export default class PrintProgress extends React.Component {
  render () {
    return (
      <div className={styles.steps}>
        <div className={styles.steps__item}>
          <h4 className={styles.steps__text}>
            <span className={styles.steps__number}>步骤1.</span>打开财务管理 &gt; 中信银行回单打印 &gt; 账户列表，查找需要打印的企业名称，点击【查看交易回单】，进入交易回单详情页面；
          </h4>
          <img src={step1} alt="" className={styles.steps__image} />
        </div>
        <div className={styles.steps__item}>
          <h4 className={styles.steps__text}>
            <span className={styles.steps__number}>步骤2.</span>进入交易回单详情页面后，查找需要打印的回单，点击【去打印】，进入回单打印页面，选择附属账户电子回单，点击访问链接地址<a href="https://enterprise.bank.ecitic.com/corporbank/cb060400_reBill.do" target="__blank">（https://enterprise.bank.ecitic.com/corporbank/cb060400_reBill.do）</a>，进入中信银行网上银行电子回单打印页面；
          </h4>
          <img src={step2} alt="" className={styles.steps__image} />
          <img src={step3} alt="" className={styles.steps__image} />
        </div>
        <div className={styles.steps__item}>
          <h4 className={styles.steps__text}>
            <span className={styles.steps__number}>步骤3.</span>进入行电子回单打印页面后，复制企业附属账号到资金分簿编号输入框，复制打印校验码到打印校验码输入框，输入验证码，点击【提交】，即可打印或者复制电子回单；
          </h4>
          <img src={step4} alt="" className={styles.steps__image} />
          <img src={step5} alt="" className={styles.steps__image} />
          <img src={step6} alt="" className={styles.steps__image} />
        </div>
      </div>
    );
  }
}